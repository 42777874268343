import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { uomState, Action } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";

const initialState: uomState = {
    isLoading: false,
    uom: null,
    successMessage: "",
    errorMessage: ""
};

const uomSlice = createSlice({
    name: "uom",
    initialState,
    reducers: {
        getAlluomLoading: (state) => {
            state.isLoading = true;
        },
        getAlluomSuccess: (state, action: PayloadAction<Action>) => {
            state.isLoading = false;
            state.uom = action.payload;
        },
        getAlluomFail: (state) => {
            state.isLoading = false;
        }
    }
});

const { actions, reducer } = uomSlice;
export const { getAlluomLoading, getAlluomSuccess, getAlluomFail } = actions;

export const getAlluom = (): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getAlluomLoading());

    try {
        const response = await API.get("uoms/get?length=100",{
            headers: {
                Authorization: `Bearer ${userToken}`
              }
        });
        if ([200, 201].includes(response.status)) {
            dispatch(getAlluomSuccess(response.data.data));
        }
    } catch (error) {
        dispatch(getAlluomFail());
    }
};

export default reducer;
