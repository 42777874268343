import React, { useEffect } from "react";
import blueCircle from "assests/icons/Ellipse 48.svg";
import lightBluCircle from "assests/icons/Ellipse 49.svg";
import redArrowDown from "assests/icons/Vector (13).svg";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { getSingleVendor } from "store/slice/BuyerSlice/VendorSlice/VendorSlice";
import "./styles.scss";
import DoughnutChart from "components/Charts/DonutChart";
import { useParams } from "react-router-dom";
import { getRequisition } from "store/slice/RequisitionSlice/RequisitionSlice";
import RejectOnboarding from "components/ModalBuyer/RejectOnboarding";
import { approveItem, rejectItem } from "store/slice/BuyerSlice/ApprovalSlice/ApprovalSlice";
import { useHistory, useLocation } from "react-router-dom";
import { toastPopUp } from "utils/utils";

const ApproveRequisition = () => {
  const history = useHistory();
  const location = useLocation();
  const [rejectModal, setRejectModal] = React.useState<boolean>(false);
  const [invoiceStat, setInvoiceStat] = React.useState("");
  const chartData = [
    { value: 1048, name: "Budget" },
    { value: 735, name: "Commitment" }
  ];
  const dispatch = useDispatch<AppDispatch>();
  let { id }: any = useParams();
  const { singleRequisition, loading } = useSelector((state: RootState) => state.requisition) as any;
  const data = singleRequisition;
  console.log(data);
  // const reqData = singleRequisition && singleRequisition[0];
  // console.log(reqData)

  useEffect(() => {
    dispatch(getRequisition(id));
  }, []);

  useEffect(() => {
    if (data) {
      switch (data?.requisition_status) {
        case "-1":
          setInvoiceStat("Pending Approval");
          break;
        case "-2":
          setInvoiceStat("Unconfirmed");
          break;
        case "2":
          setInvoiceStat("RFP Generated");
          break;
        case "1":
          setInvoiceStat("Approved");
          break;
        case "3":
          setInvoiceStat("Completed");
          break;
        default:
          break;
      }
    }
  }, [data]);
  const color = ["#A6CEE3", "#1F78B4"];

  const approve = () => {
    toastPopUp(
      () => {
        dispatch(
          approveItem(
            {
              approval_queue_id: location.state,
              note: ""
            },
            () => history.push("/buyer/approvals")
          )
        );
      },
      "Approve",
      "Cancel",
      "Approve Requisition",
      "",
      "#737a91"
    );
  };

  const reject = (vals) => {
    // if (window.confirm("Confirm Approve")) {
    dispatch(
      rejectItem(
        {
          approval_queue_id: location.state,
          note: vals
        },
        () => history.push("/buyer/approvals")
      )
    );
    // }
  };

  const closeReject = () => {
    setRejectModal(false);
  };

  return (
    <div className="approve-requisition">
      {/* <h5 className='mb-5 page-title'>{singleRequisition?.requisition_description}</h5>
      <div className="row mb-4">
        <div className="col-md-8 col-lg-8 col-xs-12">
          <DoughnutChart data={data} name="donut-1" color={color} />
        </div>
        <div className="col-md col-lg col-xs-12">
          <p>Budget Details</p>
          <div className="d-flex ">
            <img src={blueCircle} alt="" />
            <p>
              Budget: <span>120000</span>
            </p>
          </div>
          <div className="d-flex ">
            <img src={blueCircle} alt="" />
            <p>
              Budget: <span>120000</span>
            </p>
          </div>
          <div className="d-flex ">
            <img src={blueCircle} alt="" />
            <p>
              Budget: <span>120000</span>
            </p>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-8">
          <div className="row mb-4">
            <div className="col-md col-lg col-xs-12">
              <p className="page-title">Requisition</p>
              <p>{singleRequisition?.requisition_type}</p>
            </div>
            <div className="col-md col-lg col-xs-12">
              <p className="page-title">Expected delivery date</p>
              <p>{singleRequisition?.delivery_date}</p>
            </div>
            <div className="col-md col-lg col-xs-12">
              <p className="page-title">Requester</p>
              <p>{singleRequisition?.delivery_date}</p>
            </div>
          </div>
          <div className="row d-block" style={{ paddingLeft: "inherit" }}>
            <p className="page-title">Desription</p>
            <p>{singleRequisition?.requisition_description}</p>
          </div>
        </div>
        <div className="col-md">
          <textarea rows={4} className="text-area mt-4" placeholder="Enter description" name="note" ref={register({ required: true })} />
          <p>
            To: <span className="page-title">Requisitioner</span>
          </p>
        </div>
      </div> */}
      {rejectModal && <RejectOnboarding closeModal={closeReject} reject={reject} />}
      <div className="d-flex justify-content-between align-items-baseline mb-5">
        <h5 className="mb-5 page-title">{data?.requisition_description}</h5>
        <div className="d-flex justify-content-center align-items-center mb-3" style={{ columnGap: "2rem" }}>
          <button
            type="button"
            // disabled={checkFiles?.includes("-1")}
            style={{
              padding: "8px 30px",
              background: " #F85A5A",
              borderRadius: "10px",
              color: "white"
            }}
            onClick={() => setRejectModal(true)}
          >
            {loading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Reject"}
          </button>
          <button
            type="submit"
            // disabled={checkFiles?.includes("-1") || vendor === ""}
            style={{
              padding: "8px 30px",
              background: "#00B0A5",
              borderRadius: "10px",
              color: "white"
            }}
            onClick={approve}
          >
            {loading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Approve"}
          </button>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-md-6 col-lg-6 col-xs-12">
          <div className="row">
            <div className="col-md col-lg col-xs-12">
              <div className="mb-3">
                <p className="page-title">Created On:</p>
                <p>{data?.created_at}</p>
                {/* <p>{data?.created_at}</p> */}
              </div>
              <div className="mb-3">
                <p className="page-title">Legal Entity:</p>
                <p>{data?.company_description}</p>
                {/* <p>{data?.department[0]?.company_description}</p> */}
              </div>
              {/* <div className="mb-3">
                <p className="page-title">Purchase Group:</p>
                <p>Test</p>
              </div> */}
              <div className="mb-3">
                <p className="page-title">Delivery Location</p>
                <p>{data?.location_description}</p>
                {/* <p>{data?.requisitions?.location_description}</p> */}
              </div>
              <div className="mb-3">
                <p className="page-title">Purchasing Group</p>
                <p>{data?.purchasing_group_code}</p>
                {/* <p>{data?.requisitions?.location_description}</p> */}
              </div>
            </div>

            <div className="col-md col-lg col-xs-12">
              <div className="mb-3">
                <p className="page-title">Requester:</p>
                <p>
                  {data?.requester?.lastname} {data?.requester?.othernames}
                </p>
                {/* <p>{data?.requisitions?.delivery_date}</p> */}
              </div>
              <div className="mb-3">
                <p className="page-title">Expected Delivery Date:</p>

                <p>{data?.delivery_date}</p>
              </div>
              <div className="mb-3">
                <p className="page-title mb-2">Status</p>
                {/* <p>{data?.requisitions?.location_description}</p> */}
                <span
                  style={{
                    padding: "8px 16px",
                    background: data?.requisition_status === "-1" || data?.requisition_status === "-2" ? "rgba(245, 150, 37, 0.15)" : "rgba(0, 176, 7, 0.15)",
                    borderRadius: "10px",
                    color: data?.requisition_status === "-1" || data?.requisition_status === "-2" ? "rgba(245, 150, 37, 1)" : "#387C57"
                  }}
                >
                  {invoiceStat}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-6 col-xs-12">
          <DoughnutChart data={chartData} color={color} name="" />
          {/* <div className="d-block pad">
            <h6 style={{ color: "black", fontWeight: "bold" }} className="mb-2">
              Budget Details
            </h6>
            <div className="d-flex justify-content-between mb-3">
              <div className="d-flex" style={{ columnGap: "0.5rem" }}>
                <img src={blueCircle} alt="" />
                <p>
                  Budget: <span className="text-dark">N120, 000</span>
                </p>
              </div>
              <div className="d-flex" style={{ columnGap: "0.5rem" }}>
                <img src={lightBluCircle} alt="" />
                <p>
                  Price: <span className="text-dark">N120, 000</span>
                </p>
              </div>
            </div>
            <div className="d-flex" style={{ columnGap: "0.5rem" }}>
              <p>
                Budget Impact:{" "}
                <span className="text-danger">
                  <img src={redArrowDown} style={{ width: "0.7rem" }} alt="" />
                  16.7%
                </span>
              </p>
            </div>
          </div> */}
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-10">
          <table>
            <thead>
              <tr>
                <th>item</th>
                <th>Mat. No</th>
                <th>Mat. Group</th>
                <th>Product Description</th>
                <th>Quantity</th>
                <th>UoM</th>
                <th>Net Amount</th>
                <th>Budget Impact</th>
              </tr>
            </thead>
            <tbody>
              {data?.materials?.map((mat, i) => (
                <tr key={i} className="border-container mb-3">
                  <td className="first-td">{i + 1}</td>
                  <td>{mat?.material_code}</td>
                  <td>{mat?.material_category_description}</td>
                  <td>{mat?.material_description}</td>
                  <td>{mat?.quantity_required}</td>
                  <td>{mat?.uom_code}</td>
                  <td>{mat?.estimated_unit_price}</td>
                  {/* <td className="last-td">{mat?.total_amount}</td> */}
                  <td className="last-td" style={{color:'red'}}>
                    <img src={redArrowDown} style={{ width: "0.7rem" }} alt="" />
                    24%
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ApproveRequisition;
