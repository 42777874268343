import React, { useCallback, useState } from "react";
import "./styles.scss";
import TabSmall from "components/TabSmall";
import ProductCategory from "./components/ProductCateorgy";
import ProductData from "./components/ProductData";

const MaterialData = () => {
    const menus = [
        { name: "Product Category", id: "cat" },
        { name: "Product Data", id: "data" }
    ];
    //State
    const [state, setstate] = useState(menus[0].id);

    const selectMenu = useCallback((name) => {
        setstate(name);
    }, []);

    return (
        <div id="settings-material-data">
            <div className="container">
                <TabSmall menus={menus} onSelect={selectMenu} classes="mb-3" />

                {state === "cat" && <ProductCategory />}
                {state === "data" && <ProductData />}
            </div>
        </div>
    );
};

export default MaterialData;
