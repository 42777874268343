import React from "react";
import "./styles.scss";
import DropdownMenu from "components/DropdownMenu";

export const usersColumn = [
    {
        name: "First Name",
        index: "firstName",
        id: 1
    },
    {
        name: "Last Name",
        index: "lastName",
        id: 2
    },
    {
        name: " Phone Number",
        index: "phoneNo",
        id: 3
    },
    {
        name: "Email",
        index: "email",
        id: 4
    },
    {
        name: "Status",
        index: "status",
        id: 5
    },
    {
        name: "Role",
        index: "role",
        id: 6
    },
    {
        name: "Actions",
        id: 7,
        render: ({
            toggleDropdown,
            userId,
            tableID,
            view,
            edit,
            deleteUserId,
            status,
            activateUserID,
            deactivateUserID
        }) => {
            const settings = [
                {
                    name: "View",
                    style: "activate_tag",
                    select: view
                },
                {
                    name: `${status === "Pending" ? "Activate" : "Deactivate"}`,
                    style: `${status === "Pending" ? "activate_tag" : "disable_tag"}`,
                    select: status === "Pending" ? activateUserID : deactivateUserID
                },

                {
                    name: "Edit",
                    style: "activate_tag",
                    select: edit
                },
                {
                    name: "Delete",
                    style: "delete_tag",
                    select: deleteUserId
                }
            ];
            return (
                <span>
                    <DropdownMenu options={settings} boxClasses="dropdown-style">
                        <p style={{ fontSize: "2rem" }} className="cursor-pointer" id={userId}>
                            ...
                        </p>
                    </DropdownMenu>
                </span>
            );
        }
    }
];
