/* eslint-disable react-hooks/exhaustive-deps */
import StatsCard from "components/StatsCard";
import Icon from "assests/icons/statsCard/Vector (4).png";
import SearchBar from "../../../../components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { reportCol, reportData } from "./data";
import "./styles.scss";
import React, { useEffect, useState } from "react";
import { AppDispatch, RootState } from "store/store";
import { useDispatch, useSelector } from "react-redux";
import useSearch from "hooks/useSearch";
import CreateOrEditReport from "components/ModalBuyer/CreateOrEditReport";
import Pagination from "components/Pagination";
import { getAllReport } from "store/slice/BuyerSlice/ReportSlice/RepotSlice";
import { Pie, Doughnut } from "react-chartjs-2";
import lineImg from "../../../../assests/icons/line-chart.png";
import dateImg from "../../../../assests/icons/calendar.png";
import SelectDropdown from "components/SelectDropdown";
import { Controller, useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import API from "utils/httpsRequest";
import { useHistory } from "react-router";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import { kFormatter } from "utils/utils";
import moment from "moment";
import { getCompanySummary, getDeptSummary } from "store/slice/SummarySlice/SummarySlice";

const SpendReport = () => {
  const [singleReport, setSingleReport] = useState<any>(null);
  const { user } = useSelector((state: RootState) => state.user);
  const { token } = user;
  const dispatch = useDispatch<AppDispatch>();
  const { reports, loading } = useSelector((state: RootState) => state.report);
  const { locations } = useSelector((state: RootState) => state.location);
  const [modal, setModal] = useState<boolean>(false);
  const [locationSel, setLocationSel] = React.useState("");
  const [selLocationVal, setSelLocationVal] = React.useState("");
  const [DepartmentSel, setDepartmentSel] = React.useState("");
  const [selDepartmentVal, setSelDepartmentVal] = React.useState("");
  const [amountSel, setAmountSel] = React.useState("");
  const [selAmountVal, setSelAmountVal] = React.useState("");
  const [companySel, setCompanySel] = React.useState("");
  const [selCompanyVal, setSelCompanyVal] = React.useState("");
  const [catSel, setCatSel] = React.useState("");
  const [selCatVal, setSelCatVal] = React.useState("");
  const [company, setCompany] = useState(user.company_id ? user.company_id : "10002");
  const [companyName, setCompanyName] = useState(user.company_description ? user.company_description : "GZ Industries Ltd - Nigeria");
  const [location, setLocation] = useState("");
  const [locationName, setLocationName] = useState("");
  const [dept, setDept] = useState("");
  const [deptName, setDeptName] = useState("");
  const [cat, setCat] = useState("");
  const [catName, setCatName] = useState("");
  const [sumDept, setSumDept] = useState(0);
  const [sumItem, setSumItem] = useState(0);
  const [sumMoney, setSumMoney] = useState(0);
  const [sumIso, setSumIso] = useState(null);
  const [firstFive, setFirstFive] = useState<any>([]);

  // const [defaultLegal, setDefaultLegal] = useState([{

  //     label: "GZ Industries Ltd - Nigeria",
  //     value: "10002"

  // }]);
  const [newLocationOption, setNewLocationOption] = useState([]);
  const [newCompanyOption, setNewCompanyOption] = useState([
    {
      label: "GZ Industries Ltd - Nigeria",
      value: "10002"
    }
  ]);

  const [newDepartmentOption, setNewDepartmentOption] = useState([]);
  const [seen, setSeen] = useState(true);

  // from slice

  const { companySummary, isLodingCompany, deptSummary, isLoadingDept } = useSelector((state: RootState) => state.summaries);

  // setting summary place
  useEffect(() => {
    companySummary && companySummary.data.length ? setSumDept(companySummary.data[0].total_departments) : setSumDept(0);
    companySummary && companySummary.data.length ? setSumItem(companySummary.data[0].total_purchase_order_items) : setSumItem(0);
    companySummary && companySummary.data.length ? setSumIso(companySummary.data[0].currency_iso) : setSumIso(null);
    companySummary && companySummary.data.length ? setSumMoney(companySummary.data[0].total_purchase_order_value) : setSumMoney(0);
  }, [companySummary]);

  // setting pie chat data

  const [labelList, setLabelList] = useState<string[]>([]);
  // const [onceSeen, setOnceSeen] = useState(true);
  const [pointScore, setPointScore] = useState<number[]>([]);
  let onceSeen = true;

  useEffect(() => {
    const filt =
      deptSummary &&
      [...deptSummary?.data]
        ?.sort((a, b) => {
          return Number(b.total_purchase_order_value) - Number(a.total_purchase_order_value);
        })
        ?.filter((val, i) => i <= 4)
        ?.filter((item) => {
          return item;
        });

    if (filt) {
      if (filt.length) {
        setFirstFive(filt);
        onceSeen = true;
        if (onceSeen) {
          let testArray: string[] = [];
          let testScore: number[] = [];
          // item.department_description.split(" ")[0]
          filt.forEach((item) => testArray.push(item.department_description ? item.department_description : ""));
          filt.forEach((item) => testScore.push(item.total_purchase_order_value));
          setLabelList([...testArray]);
          setPointScore([...testScore]);

          onceSeen = false;
        }
      } else {
        setLabelList([]);
        setPointScore([]);
      }
    }
  }, [deptSummary]);

  const [dateRange, setDateRange] = useState([
    {
      // ate_from=&date_to=
      startDate: new Date(2022, 0, 1),
      endDate: new Date(),
      key: "selection"
    }
  ]);

  console.log(dateRange, "kiol");

  const [newDate, setNewDate] = useState([{}]);

  useEffect(() => {
    setNewDate([
      {
        startDate: dateRange[0].startDate,
        endDate: dateRange[0].endDate
      }
    ]);
  }, [dateRange]);

  // get summary for company
  useEffect(() => {
    // /purchase_orders/summary_by_company?company_id
  }, [company]);

  const [showRange, setShowRange] = useState(false);

  const handleRangeShow = () => {
    setShowRange(!showRange);
  };

  const history = useHistory();

  const closeModal = () => {
    setModal((prev) => !prev);
    setSingleReport(null);
  };

  const handleInputChangeLocation = (value) => {
    setLocationSel(value);
  };

  const onChangeLocation = (value) => {
    setLocation(value.value);
    setLocationName(value.label);
    setSelLocationVal(value);
  };

  const handleInputChangeDepartment = (value) => {
    setDepartmentSel(value);
  };

  const onChangeDepartment = (value) => {
    setDept(value.value);
    setDeptName(value.label);
    setSelDepartmentVal(value);
  };

  const handleInputChangeAmount = (value) => {
    setAmountSel(value);
  };

  const handleInputChangeCat = (value) => {
    setCatSel(value);
  };

  const onChangeCat = (value) => {
    setCat(value.value);
    setCatName(value.label);
    setSelCatVal(value);
  };

  const onChangeAmount = (value) => {
    setSelAmountVal(value);
  };
  const handleInputChangeCompany = (value) => {
    setCompanySel(value);
  };

  const onChangeCompany = (value) => {
    setCompany(value.value);
    setCompanyName(value.label);
    // setInitailId(value.value)
    setSelCompanyVal(value);
    LocationOptions({ companyId: value.value });
    DepartmentOptions({ companyId: value.value });
  };

  useEffect(() => {
    LocationOptions({ companyId: company });
    DepartmentOptions({ companyId: company });
  }, []);

  const LocationOptions = ({ companyId = company }) => {
    return API.get(`/locations/get?search=${locationSel}&company_id=${companyId}&length=1000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      let guy =
        res.data &&
        res.data.data.map((item) => ({
          label: item.location_description,
          value: item.id
        }));

      setNewLocationOption(guy);
      return guy;
    });
  };

  const removeFilter = (nameFilt) => {
    if (nameFilt === "date") {
      setDateRange([
        {
          // ate_from=&date_to=
          startDate: new Date(2022, 0, 1),
          endDate: new Date(),
          key: "selection"
        }
      ]);
    }
    if (nameFilt === "legal") {
      setCompany("");
      setCompanyName("");
      setSelCompanyVal("");
    }
    if (nameFilt === "location") {
      setLocation("");
      setLocationName("");
      setSelLocationVal("");
    }
    if (nameFilt === "dept") {
      setDept("");
      setDeptName("");
      setSelDepartmentVal("");
    }
    if (nameFilt === "cat") {
      setCat("");
      setCatName("");
      setSelCatVal("");
    }
  };

  // const companiesOptions = () => {
  //   return API.get(`/companies/get?search=${companySel}`, {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       "Content-Type": "application/json"
  //     }
  //   }).then((res) => {
  //     return res.data.data.map((item) => ({
  //       label: item.company_description,
  //       value: item.id
  //     let guy = res.data.data.map((item) => ({
  //       label: item.location_description,
  //       value: item.id
  //     }));

  //     setNewLocationOption(guy);
  //     return guy;
  //     }));
  //   });
  // };

  const companiesOptions = () => {
    return API.get(`/companies/get?search=${companySel}&length=1000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      let guy =
        res.data &&
        res.data.data.map((item) => ({
          label: item.company_description,
          value: item.id
        }));

      setNewCompanyOption(guy);
      return guy;
    });
  };

  const DepartmentOptions = ({ companyId = company }) => {
    return API.get(`/departments/get?search=${DepartmentSel}&company_id=${companyId}&length=1000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      let guy =
        res.data &&
        res.data.data.map((item) => ({
          label: item.department_description,
          value: item.id
        }));
      setNewDepartmentOption(guy);
      return guy;
    });
  };

  const materialCategoryOptions = () => {
    return API.get(`/material_categories/get?search=${catSel}&length=1000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: item.material_category_description,
        value: item.id
      }));
    });
  };

  const { register, control, handleSubmit, errors, setValue } = useForm<FormData>({
    mode: "onChange"
  });

  //Hook
  //   const { search, inputRef, handleChange, searched } = useSearch(getAllReport, perPage);

  //   chart info

  const pieData = {
    labels: labelList.length && labelList,

    datasets: [
      {
        label: "Purchase Order By Department Chart",
        weight: 1,
        backgroundColor: ["#05445E", "#00B01C", "#A6CEE3", "#189AB4", "#75E6DA", "#05445E"],
        data: pointScore.length && pointScore
      }
    ]
  };

  // get company Summary
  useEffect(() => {
    dispatch(
      getCompanySummary({
        company_id: company,
        location_id: location,
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-D"),
        department_id: dept,
        material_category_id: cat,
        length: 1000,
        currency_id: ""
      })
    );
  }, [company, location, dept, newDate, cat]);

  // get department Summary
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  //Show Number for PerPage Request
  const handleNewPage = (pageNo: number, page: number = 1) => {
    setPerPage(pageNo);
    setPage(page);
    dispatch(
      getDeptSummary({
        company_id: company,
        location_id: location,
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-D"),
        department_id: dept,
        material_category_id: cat,
        length: perPage,
        page: pageNo,
        currency_id: "",
        vendor: "",
        purchase: ""
      })
    );
  };

  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, perPage: number) => {
      setPage(page);
      dispatch(
        getDeptSummary({
          company_id: company,
          location_id: location,
          date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
          date_to: moment(dateRange[0].endDate).format("YYYY-MM-D"),
          department_id: dept,
          material_category_id: cat,
          length: perPage,
          page: page,
          currency_id: "",
          vendor: "",
          purchase: ""
        })
      );
    },
    [company, location, dept, newDate, cat]
  );

  const paginate = React.useCallback(
    (pageNo: number, perPage: number) => {
      setPage(pageNo);
      dispatch(
        getDeptSummary({
          company_id: company,
          location_id: location,
          date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
          date_to: moment(dateRange[0].endDate).format("YYYY-MM-D"),
          department_id: dept,
          material_category_id: cat,
          length: perPage,
          page: pageNo,
          currency_id: "",
          vendor: "",
          purchase: ""
        })
      );
    },
    [company, location, dept, newDate, cat]
  );

  useEffect(() => {
    dispatch(
      getDeptSummary({
        company_id: company,
        location_id: location,
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-D"),
        department_id: dept,
        material_category_id: cat,
        length: perPage,
        page: page,
        currency_id: "",
        vendor: "",
        purchase: ""
      })
    );
  }, [company, location, dept, newDate, cat]);

  //   const deleteCompany = (name: string, id: string) => {
  //     const payload = { id: id };

  //     toastPopUp(
  //       () => {
  //         dispatch(
  //           deleteCompanyData(payload, () => {
  //             dispatch(getAllReport(page,perPage));
  //           })
  //         );
  //       },
  //       "Delete",
  //       "Cancel",
  //       "Delete the entity",
  //       name,
  //       "red"
  //     );
  //   };

  // const editCompanyData = (id: string, desc: string, code: string,country:string) => {
  //     setSingleCompanyData({ id, desc, code, country });
  //     setModal((prev) => !prev);
  //   };

  const reportList =
    deptSummary &&
    deptSummary?.data?.map((company) => {
      return {
        id: company.department_id,
        department_code: company.department_code,
        department_description: company.department_description,
        total_purchase_order_items: company.total_purchase_order_items,
        total_purchase_orders: company.total_purchase_orders,
        total_value: kFormatter(company.total_purchase_order_value, "")
        // edit: () => editCompanyData(company.id, company.company_description, company.company_code, company.country_id),
        // deleteEntity: () => deleteCompany(company.location_description, company.id)
      };
    });

  const handleView = (e, item) => {
    history.push(`/buyer/report/inner?deptId=${item.id}&deptName=${item.department_description}&company=${company}`);
  };

  //Location List
  const locationOption =
    locations &&
    locations.data.map((_data: { id: string; material_category_description: string }) => {
      return {
        value: _data?.id,
        optionName: _data?.material_category_description
      };
    });

  return (
    <div id="report">
      {modal && <CreateOrEditReport closeModal={closeModal} />}

      <div className="container">
        <div className="filterContainer">
          <div className="titleSide">
            <div className="left">
              <img src={lineImg} alt="line icon" />
              <p>Purchase Order Report</p>
            </div>
          </div>

          <div className="filters">
            <div className="dateFilter each">
              <div onClick={handleRangeShow} className="dateBox">
                <p className="date-range">
                  {` ${moment(dateRange[0].startDate).format("MM/DD/YYYY")} - ${moment(dateRange[0].endDate).format("MM/DD/YYYY")}`}

                  {/* moment(dateRange[0].startDate).format('MM/DD/YYYY') */}
                </p>
                <img src={dateImg} alt="date icon" />
              </div>

              {showRange && (
                <div className="rangeDiv">
                  <DateRangePicker
                    onChange={(item) => setDateRange([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={dateRange}
                    direction="horizontal"
                    preventSnapRefocus={true}
                    calendarFocus="backwards"
                  />

                  <div onClick={handleRangeShow} className="applyBtn">
                    <button type="button">Apply</button>
                  </div>
                </div>
              )}
            </div>
            <div className="CompanyFilter each">
              <Controller
                control={control}
                defaultValue
                boxClasses="mt-3"
                name="company_id"
                render={({ ref }) => (
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    placeholder="Legal Entity"
                    value={selCompanyVal}
                    loadOptions={companiesOptions}
                    onInputChange={handleInputChangeCompany}
                    onChange={onChangeCompany}
                  />
                )}
              />
            </div>
            {/* ={newCompanyOption} */}
            <div className="locationFilter each">
              <Controller
                control={control}
                defaultValue
                boxClasses="mt-3"
                name="company_id"
                render={({ ref }) => (
                  <AsyncSelect
                    cacheOptions
                    defaultOptions={newLocationOption}
                    placeholder="Location"
                    value={selLocationVal}
                    loadOptions={LocationOptions}
                    onInputChange={handleInputChangeLocation}
                    onChange={onChangeLocation}
                  />
                )}
              />
            </div>
            <div className="departmentFilter each">
              <Controller
                control={control}
                defaultValue
                boxClasses="mt-3"
                name="company_id"
                render={({ ref }) => (
                  <AsyncSelect
                    cacheOptions
                    defaultOptions={newDepartmentOption}
                    placeholder="Department"
                    value={selDepartmentVal}
                    loadOptions={DepartmentOptions}
                    onInputChange={handleInputChangeDepartment}
                    onChange={onChangeDepartment}
                  />
                )}
              />
            </div>
            {/* <div className="supplierFilter each">
              <Controller
                control={control}
                defaultValue
                boxClasses="mt-3"
                name="company_id"
                render={({ ref }) => (
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    placeholder="Supplier"
                    value={selLocationVal}
                    loadOptions={LocationOptions}
                    onInputChange={handleInputChangeLocation}
                    onChange={onChangeLocation}
                  />
                )}
              />
            </div> */}
            <div className="groupFilter each">
              <Controller
                control={control}
                defaultValue
                boxClasses="mt-3"
                name="material_category_id"
                render={({ ref }) => (
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    placeholder="Material Category"
                    value={selCatVal}
                    loadOptions={materialCategoryOptions}
                    onInputChange={handleInputChangeCat}
                    onChange={onChangeCat}
                  />
                )}
              />
            </div>
          </div>

          <div className="showing">
            <p className="text">Showing:</p>
            <div className="coverDisplay">
              <div className="displayDate">
                <p>{` ${moment(dateRange[0].startDate).format("MM/DD/YYYY")} - ${moment(dateRange[0].endDate).format("MM/DD/YYYY")}`}</p>
                <p
                  onClick={() => {
                    removeFilter("date");
                  }}
                  className="cancel comp"
                >
                  x
                </p>
              </div>
              {companyName && (
                <div className="displayDate">
                  <p className="Company">{companyName}</p>
                  {/* <p onClick={()=>{removeFilter("legal")}} className="cancel comp">x</p> */}
                </div>
              )}
              {locationName && (
                <div className="displayDate">
                  <p className="location">{locationName}</p>
                  <p
                    onClick={() => {
                      removeFilter("location");
                    }}
                    className="cancel location"
                  >
                    x
                  </p>
                </div>
              )}

              {deptName && (
                <div className="displayDate">
                  <p className="dept">{deptName}</p>
                  <p
                    onClick={() => {
                      removeFilter("dept");
                    }}
                    className="cancel dept"
                  >
                    x
                  </p>
                </div>
              )}

              {catName && (
                <div className="displayDate">
                  <p className="cat">{catName}</p>
                  <p
                    onClick={() => {
                      removeFilter("cat");
                    }}
                    className="cancel cat"
                  >
                    x
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="summary">
            <p className="topic">Orders</p>
            <div className="summaryCover">
              <div className="spent">
                <div className="moneySide">
                  {/* <p className="currency">$</p> */}
                  <p className="amountMoney">{kFormatter(sumMoney, "")} </p>
                  <p className="currencyType">{sumIso}</p>
                </div>
                <p className="text">Total Spend</p>
              </div>

              <div className="spent mid">
                <div className="deptSide">
                  <p className="out">{kFormatter(sumDept, "")}</p>
                  {/* <p className="total">/14</p> */}
                </div>
                <p className="text">Total Departments Used</p>
              </div>

              <div className="spent">
                <p className="purchase">{kFormatter(sumItem, "")}</p>
                <p className="text">Total Items Purchased</p>
              </div>
            </div>
          </div>
        </div>

        <div className="chatBox">
          {/* <div className="title">Monthly Spend Report</div> */}
          <div className="chartFlex">
            {
              labelList.length && pointScore.length ? (
                <div className="pieChart">
                  <Doughnut
                    data={pieData}
                    options={{
                      title: {
                        display: false,
                        text: "",
                        fontSize: 10
                      },
                      cutout: 100,
                      maintainAspectRatio: false,
                      legend: {
                        display: true,
                        position: "right"
                      }
                    }}
                  />
                </div>
              ) : null
              //  <p className="dataless"  >No Data</p>
            }

            <div className="budgetDetails">
              {/* <p className="topic">Budget Details</p> */}
              {labelList.length && pointScore.length ? (
                <div className="allDetails">
                  {firstFive.length &&
                    firstFive.map((item, i) => {
                      return (
                        <div key={item.department_id} className="eachList ">
                          <div className={`circle box${i}`}></div>
                          <p>
                            {item.department_description} - {kFormatter(item.total_purchase_order_value, "")}
                          </p>
                        </div>
                      );
                    })}
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="document mt-5">
          <div className="mt-3">
            <DataTable columns={reportCol} data={reportList} onClick={handleView} loading={isLoadingDept} />
            {deptSummary?.count > 10 ? (
              <Pagination page={page} lastPage={deptSummary?.pagination?.lastPage} paginate={paginate} total={deptSummary?.pagination?.total} handlePageChange={handlePageChange} perPage={perPage} />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpendReport;
