import React, { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootState } from "store/store";

import useSearch from "hooks/useSearch";
import { getWayBill } from "store/slice/SupplierSlices/Waybill/WayBillSlice";

import { getInvoiceSummary } from "store/slice/BuyerSlice/Invoice/InvoiceSlice";
import StatsCard from "components/StatsCard";

import file from "../../../assests/icons/supplier/file.svg";
import accordionImg from "../../../../assests/icons/supplier/accordion.svg";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { getStatement } from "store/slice/BuyerSlice/VendorStatementSlice/VendorStatementSlice";
import { formatCurrency, moneyFormatter } from "utils/utils";
import { eachProposalCol } from "../data";
import { getEachBankPropsal } from "store/slice/BuyerSlice/PostedInvoiceSlice/PostedInvoiceSlice";
import { sendApproval } from "store/slice/BuyerSlice/PostedInvoiceSlice/PostedInvoiceSlice";
import Button from "components/Button";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "./styles.scss";
import UploadProposal from "components/ModalBuyer/UploadProposal";
import { CSVLink } from "react-csv";

interface IUserMenuProps {}

const ProposalComponent = ({ item, setRecentProposalId, setRecentId, closeModal, status }) => {
  //Redux Hook
  const dispatch = useDispatch<AppDispatch>();
  //State
  const [active, setActive] = useState(true);
  const [display, setDisplay] = useState(false);
  console.log(item);
  // data={

  // }
  const FIDTNGLAData = {
    data: item
      ? item.bank_items.map((each) => {
          return {
            bank_code: each.payee_bank_code,
            account_number: each.payee_bank_account,
            name: each.vendor_description,
            amount: formatCurrency(each.total_invoiced_value),
            story: each.reference
          };
        })
      : [],
    headers: [
      { label: "Beneficiary Bank Code", key: "bank_code" },
      { label: "Beneficiary Account Number", key: "account_number" },
      { label: "Beneficiary Name", key: "name" },
      { label: "Amount", key: "amount" },
      { label: "Narration", key: "story" }
    ],
    filename: "table.csv"
  };
  const FIRNNGLAData = {
    data: item
      ? item.bank_items.map((each) => {
          return {
            beneficiary_code: each.payee_bank_code,
            name: each.vendor_description,
            account_number: each.payee_bank_account,
            amount: formatCurrency(each.total_invoiced_value),
            debit_ref: each.reference,
            credit_ref: each.reference,

            email: each.email
          };
        })
      : [],
    headers: [
      { label: "Beneficiary Code", key: "beneficiary_code" },
      { label: "Beneficiary Name", key: "name" },
      { label: "Beneficiary Account Number", key: "account_number" },
      { label: "Amount", key: "amount" },
      { label: "Debit Reference", key: "debit_ref" },
      { label: "Credit Reference", key: "credit_ref" },
      { label: "Beneficiari Email", key: "email" }
    ],
    filename: "table.csv"
  };
  const SCBLNGLAData = {
    data: item
      ? item.bank_items.map((each) => {
          return {
            name: each.vendor_description,
            account_number: each.payee_bank_account,
            amount: formatCurrency(each.total_invoiced_value),
            date: each.payment_date,
            story: each.reference,
            debit_account: each.paying_account_number,
            banck_code: each.payee_bank_code,
            branch_code: each.payee_branch_code
          };
        })
      : [],
    headers: [
      { label: "Beneficiary Name", key: "name" },
      { label: "Beneficiary Account Number", key: "account_number" },
      { label: "Amount", key: "amount" },
      { label: "Payment Date", key: "date" },
      { label: "Details", key: "story" },
      { label: "Debit Account", key: "debit_account" },
      { label: "Bank Code", key: "bank_code" },
      { label: "Branch Code", key: "branch_code" }
    ],

    filename: "table.csv"
  };
  const SBICNGLXData = {
    data: item
      ? item.bank_items.map((each) => {
          return {
            name: each.vendor_description,

            account_number: each.payee_bank_account,
            sort: each.sort_code,
            amount: formatCurrency(each.total_invoiced_value),
            story: each.reference
          };
        })
      : [],
    headers: [
      { label: "Beneficiary Name", key: "name" },
      { label: "Account Number", key: "account_number" },
      { label: "Sort Code", key: "sort" },
      { label: "Amount", key: "amount" },
      { label: "Narration", key: "story" }
    ],
    filename: "table.csv"
  };

  return (
    <>
      <div className="mt-3 bank2">
        <div style={{ marginTop: "0px" }} className="accordion">
          <div className={`contentBx ${active ? "active" : ""}`}>
            <div className="label">
              <div className="text">
                <div style={{ display: "flex", alignItems: "center" }} className="left">
                  <p style={{ marginRight: "5px" }} className="open">
                    {item.house_bank_name}
                  </p>
                  <div
                    onClick={() => {
                      setDisplay(true);
                    }}
                    className="coverme"
                  >
                    {/* <ReactHTMLTableToExcel
                      id="test-table-xls-button"
                      className="download-table-xls-button btn btn-success beside"
                      table={`table-to-xls${item.id}`}
                      filename="table"
                      sheet="tablexls"
                      buttonText="Export Data"
                    /> */}
                    {item.house_bank_key === "FIDTNGLA" && <CSVLink {...FIDTNGLAData}>Export</CSVLink>}
                    {item.house_bank_key === "FIRNNGLA" && <CSVLink {...FIRNNGLAData}>Export</CSVLink>}
                    {item.house_bank_key === "SCBLNGLA" && <CSVLink {...SCBLNGLAData}>Export</CSVLink>}
                    {item.house_bank_key === "SBICNGLX" && <CSVLink {...SBICNGLXData}>Export</CSVLink>}
                  </div>
                  {display && status === "-2" && (
                    <button
                      style={{ padding: "5px 12px", borderRadius: "5px" }}
                      onClick={() => {
                        setRecentId(item.paying_bank_id);
                        setRecentProposalId(item.proposal_id);
                        closeModal();
                      }}
                      className="btn-primary"
                    >
                      Upload
                    </button>
                  )}
                </div>

                <p className="amount">
                  Total Amount: <span>{`${formatCurrency(item.total_amount)}`}</span>
                </p>
              </div>
              <img
                onClick={() => {
                  setActive(!active);
                }}
                src={accordionImg}
                alt="accordion"
              />
            </div>

            <div style={{ display: "none" }} className="excelTable">
              <table id={`table-to-xls${item.id}`}>
                {item.house_bank_key === "FIDTNGLA" ? (
                  <tbody>
                    <tr className="table-header">
                      <th>Beneficiary Bank Code</th>
                      <th>Beneficiary Account Number</th>
                      <th>Beneficiary Name</th>
                      <th>Amount</th>
                      <th>Narration</th>
                    </tr>
                    {item.bank_items.map((bank_item) => {
                      return (
                        <tr className={`table-data-row`}>
                          <td>{`'${bank_item.payee_bank_code}`}</td>
                          <td>{`'${bank_item.payee_bank_account}`}</td>
                          <td>{bank_item.vendor_description}</td>
                          <td>{formatCurrency(bank_item.total_invoiced_value)}</td>
                          <td>{bank_item.reference}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : item.house_bank_key === "FIRNNGLA" ? (
                  <tbody>
                    <tr className="table-header">
                      <th>Beneficiary Code</th>

                      <th>Beneficiary Name</th>
                      <th>Beneficiary Account Number</th>
                      <th>Beneficiary Bank Code</th>
                      <th>Transfer Amount</th>
                      <th>Debit Reference</th>
                      <th>Credit Reference</th>

                      <th>Beneficiary Email ID</th>
                    </tr>
                    {item.bank_items.map((bank_item) => {
                      return (
                        <tr className={`table-data-row`}>
                          <td>{bank_item.vendor_code}</td>
                          <td>{bank_item.vendor_description}</td>
                          <td>{`'${bank_item.payee_bank_account}`}</td>
                          <td>{`'${bank_item.payee_bank_code}`}</td>
                          <td>{formatCurrency(bank_item.total_invoiced_value)}</td>
                          <td>{bank_item.reference}</td>
                          <td>{bank_item.reference}</td>
                          <td>{bank_item.email}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : item.house_bank_key === "SCBLNGLA" ? (
                  <tbody>
                    <tr className="table-header">
                      <th>Beneficiary Name</th>
                      <th> Account Number</th>
                      <th>Payment Amount</th>
                      <th>Payment Date</th>
                      <th>Details</th>
                      <th>Debit Account</th>
                      <th>Bank Code</th>
                      <th>Branch Code</th>
                    </tr>
                    {item.bank_items.map((bank_item) => {
                      return (
                        <tr className={`table-data-row`}>
                          <td>{bank_item.vendor_description}</td>
                          <td>{`'${bank_item.payee_bank_account}`}</td>
                          <td>{formatCurrency(bank_item.total_invoiced_value)}</td>
                          <td>{bank_item.payment_date}</td>

                          <td>{bank_item.reference}</td>
                          <td>{bank_item.paying_account_number}</td>
                          <td>{`'${bank_item.payee_bank_code}`}</td>
                          <td>{bank_item.payee_branch_code}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : item.house_bank_key === "SBICNGLX" ? (
                  <tbody>
                    <tr className="table-header">
                      <th>Beneficiary Name</th>
                      <th> Account Number</th>
                      <th>Sort Code</th>
                      <th>Amount Paid</th>
                      <th>Narration</th>
                    </tr>
                    {item.bank_items.map((bank_item) => {
                      return (
                        <tr className={`table-data-row`}>
                          <td>{bank_item.vendor_description}</td>
                          <td>{`'${bank_item.payee_bank_account}`}</td>
                          <td>{bank_item.sort_code}</td>
                          <td>{formatCurrency(bank_item.total_invoiced_value)}</td>

                          <td>{bank_item.reference}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : null}
              </table>
            </div>

            <div className="content">
              <div className="data-table">
                <table id="">
                  <tbody>
                    <tr className="table-header">
                      <th>Supplier Name</th>
                      <th>SWIFT/BIC Code</th>
                      <th>Bank</th>
                      <th>Bank Code</th>
                      <th>Branch Code</th>
                      <th>Amount</th>
                      <th>Account Number</th>

                      <th>Paying House Bank</th>
                      <th>Paying Account Number</th>

                      <th>Payment Date</th>
                      <th>Reference</th>
                      <th>Supplier's Email</th>
                    </tr>
                    {item.bank_items.map((bank_item) => {
                      return (
                        <tr className={`table-data-row`}>
                          <td>{bank_item.vendor_description}</td>
                          <td>{bank_item.swift_code}</td>
                          <td>{bank_item.payee_bank}</td>
                          <td>
                            {/* <input type="text" /> */}
                            {bank_item.payee_bank_code}
                          </td>
                          <td>
                            {/* <input type="text" /> */}
                            {bank_item.payee_branch_code}
                          </td>
                          <td>{formatCurrency(bank_item.total_invoiced_value)}</td>
                          <td>{bank_item.payee_bank_account}</td>
                          <td>{item.house_bank_key}</td>
                          <td>{bank_item.paying_account_number}</td>

                          <td></td>
                          <td>{bank_item.reference}</td>
                          <td>{bank_item.email}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProposalComponent;
