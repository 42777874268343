import Accordion from "components/Accordion";
import useAccordion from "hooks/useAccordion";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { ReactComponent as DeleteIcon } from "assests/icons/Vector (3) copy 2.svg";
import "./styles.scss";
import { useEffect, useState } from "react";
import {
  addInvoiceToPayment,
  deletePaymentProposal,
  fetchDebitLines,
  getAllDebitLines,
  getEachBankPropsal,
  getPaymentRef,
  getSinglePaymentProprosals,
  removeDebitLine,
  removeInvoiceFromPayment,
  sendPaymentProposal
} from "store/slice/BuyerSlice/PostedInvoiceSlice/PostedInvoiceSlice";
import { useHistory, useLocation, useParams } from "react-router";
import { formatCurrency, toastPopUp } from "utils/utils";
import RejectOnboarding from "components/ModalBuyer/RejectOnboarding";
import DebitLines from "components/ModalBuyer/DebitLine";
import { approveItem, rejectItem } from "store/slice/BuyerSlice/ApprovalSlice/ApprovalSlice";
import accordionImg from "../../../../assests/icons/supplier/accordion.svg";

const BankApproval = () => {
  interface Provider {
    open: boolean;
    ids: object;
  }
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  let { id }: any = useParams();
  const { singlePaymentProposals } = useSelector((state: RootState) => state.postedInvoice);
  const { eachBankProposal, loading, sendLoading, paymentRef } = useSelector((state: RootState) => state.postedInvoice);
  let data = eachBankProposal?.data[0];

  // state
  const [data_, setData_] = useState<object[]>([]);
  const [active, setActive] = useState(true);
  const [rejectModal, setRejectModal] = useState<boolean>(false);
  const [modal, setModal] = useState<Provider>({
    open: false,
    ids: {}
  });
  const [notes, setNotes] = useState<string>("");

  useEffect(() => {
    dispatch(getEachBankPropsal({ id: id }));
  }, []);

  useEffect(() => {
    setData_(dataArr);
    dispatch(getPaymentRef(data?.proposal_id));
  }, [data]);

  // type isSelected = {
  //   action: string;
  // };

  const { getAmount, checkAccordion, handleCheckbox, handlerAccordion, index, headerChecked, getSelected, isSelected } = useAccordion(data_);

  const dataArr: object[] = data?.proposal_items?.map((item) => ({
    id: item.id,
    name: item.vendor_description,
    vendor_code: item.vendor_code,
    proposal_amt: Number(item?.amount),
    invoice: item.posted_invoices.map((item) => ({
      id: item.proposal_id,
      amount: item.gross_amount,
      date: item.posting_date,
      invoice_number: item.invoice_code,
      wht: "",
      invoice_id: item.invoice_id,
      currency: item.currency_iso,
      purchase_order_code: item.purchase_order_code,
      invoice_code: item.invoice_code,
      vendor_id: item.vendor_id,
      proposal_items_id: item.id,
      invoice_document_url: item.invoice_document_url
    })),
    debitLines: item.debit_lines_added.map((item) => ({
      id: item.id,
      amount: item.amount,
      date: item.payment_date,
      invoice_number: item.document_number,
      wht: "",
      invoice_id: item.invoice_id,
      currency: item.currency_iso,
      invoice_code: item.invoice_code,
      vendor_id: item.vendor_id,
      proposal_items_id: item.id
    })),
    invoice_added: item.invoices_added.map(({ invoice_code }) => invoice_code),
    amount: item?.invoices_added?.reduce((arr, curr) => arr + Number(curr.gross_amount), 0) - item?.debit_lines_added?.reduce((arr, curr) => arr + Number(curr.amount), 0)
  }));

  function getItemStatus(status) {
    if (status === "-2") return "Draft";
    if (status === "-1") return "Pending Approval";
    if (status === "1") return "Approved";
    if (status === "2") return "Paid";
    if (status === "3") return "Parked";
    if (status === "4") return "Posted";
  }

  function getBtnClass(status) {
    if (status === "-3") return "reject";
    if (status === "-2") return "draft";
    if (status === "-1") return "draft";
    if (status === "1") return "approved";
    if (status === "2") return "posted";
    if (status === "3") return "posted";
    if (status === "4") return "posted";
  }

  const approveBtnClicked = () => {
    toastPopUp(
      () => {
        dispatch(sendPaymentProposal({ proposal_id: id }, () => dispatch(getSinglePaymentProprosals(id))));
      },
      "Confirm",
      "Cancel",
      "Confirm Payment Proposal?",
      "",
      "green"
    );
  };
  const deleteBtnClicked = () => {
    toastPopUp(
      () => {
        dispatch(deletePaymentProposal({ proposal_id: id }, () => history.push("/buyer/treasury/proposal-queue")));
      },
      "Confirm",
      "Cancel",
      "Delete Payment Proposal?",
      "",
      "red"
    );
  };

  // const openModal = (e, { invoice }, i) => {
  //   e.stopPropagation();
  //   setModal({
  //     open: true,
  //     ids: { vendor_id: invoice[0].vendor_id, proposal_items_id: invoice[0].proposal_items_id, index: i }
  //   });
  // };

  const openModal = (e, data, i) => {
    e.stopPropagation();
    setModal({
      open: true,
      ids: { vendor_id: data.invoice[0].vendor_id, proposal_items_id: data.id, index: i }
    });
  };

  const handleCheckboxDebit = (invoice_debit_id, proposal_item_id) => {
    dispatch(removeDebitLine({ proposal_item_id, invoice_debit_id }, () => dispatch(getSinglePaymentProprosals(id))));
  };

  const approve = () => {
    toastPopUp(
      () => {
        dispatch(
          approveItem(
            {
              approval_queue_id: location.state,
              note: ""
            },
            () => history.push("/buyer/approvals")
          )
        );
      },
      "Approve",
      "Cancel",
      "Approve Proposal?",
      "",
      "#737a91"
    );
  };

  const reject = () => {
    // if (window.confirm("Confirm Approve")) {
    toastPopUp(
      () => {
        dispatch(
          rejectItem(
            {
              approval_queue_id: location.state,
              note: notes
            },
            () => history.push("/buyer/approvals")
          )
        );
      },
      "Reject",
      "Cancel",
      "Reject Proposal?",
      "",
      "red"
    );
    // }
  };

  const closeReject = () => {
    setRejectModal(false);
  };

  const handleChange = ({ target: { value } }) => {
    setNotes(value);
  };

  // console.log()
  return (
    <div id="proposal-approval">
      {/* {rejectModal && <RejectOnboarding closeModal={closeReject} reject={reject} />} */}
      <div className="row mb-5">
        <div className="col-6  col-xs-12">
          <div className="row">
            <div className="col-4 mb-4">
              <h6>Run ID</h6>
              <p>{data?.id}</p>
            </div>
            <div className="col-4">
              <h6>Run Date</h6>
              <p>{data?.run_date}</p>
            </div>
            {/* <div className="col-4">
              <h6>Proposal Amt.</h6>
              <p>{formatCurrency(data?.total_amount)}</p>
            </div> */}
            <div className="col-4">
              <h6>Status</h6>
              <button className={`status mt-2 ${getBtnClass(data?.bank_proposal_status)}`}>{getItemStatus(data?.bank_proposal_status)}</button>
            </div>
            {data?.proposal_status === "-3" && (
              <div className="col-4">
                <h6>Rejection Date</h6>
                <p>{data?.run_date}</p>
              </div>
            )}
          </div>
        </div>
        <div className="col-6 col-xs-12">
          <div className="d-flex justify-content-end align-items-center mb-3" style={{ columnGap: "2rem" }}>
            <button
              type="button"
              // disabled={checkFiles?.includes("-1")}
              style={{
                padding: "8px 30px",
                background: " #F85A5A",
                borderRadius: "10px",
                color: "white"
              }}
              onClick={reject}
            >
              {loading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Reject"}
            </button>
            <button
              type="submit"
              // disabled={checkFiles?.includes("-1") || vendor === ""}
              style={{
                padding: "8px 30px",
                background: "#00B0A5",
                borderRadius: "10px",
                color: "white"
              }}
              onClick={approve}
            >
              {loading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Approve"}
            </button>
          </div>
        </div>
      </div>
      <hr />
      <div className="d-flex " style={{ columnGap: "1rem" }}>
        <h6>
          Total amount to be paid: <span style={{ color: "#737A91" }}>{formatCurrency(data?.total_amount)}</span>
        </h6>
        {/* <h6>
          Currency: <span style={{ color: "#737A91" }}>{data?.currency_iso}</span>
        </h6> */}
      </div>
      <section className="statement">
        <div className="container">
          <div className="mt-1 row">{/* <StatsCard statsNumber={0} text="Vendor/Open Items" classes="stats-icon-orange" image={file} /> */}</div>
          <div className="document mt-2">
            {/* <h3 className="title font-weight-bold">Transaction History</h3> */}
            {/* <div className="d-flex mt-3">
            <SearchBar placeholder="Search Transacation History" />
            <Dropdown perPage={10} action={(num) => setPageNumber(num)} />
          </div> */}
            {eachBankProposal &&
              eachBankProposal.data[0].per_bank_items.map((item) => {
                return (
                  <div className="mt-3 bank2">
                    <div className="accordion">
                      <div className={`contentBx ${active ? "active" : ""}`}>
                        <div
                          onClick={() => {
                            setActive(!active);
                          }}
                          className="label"
                        >
                          <div className="text">
                            <p className="open">{item.house_bank_name}</p>
                            <a className="text-body font-weight-bold" target="_blank" rel="noreferrer" href={`${paymentRef?.data[0]?.payment_file_ref}`}>
                              View Payment Reference
                            </a>
                            <p className="amount">
                              Total Amount: <span>{`${formatCurrency(item.total_amount)}`}</span>
                            </p>
                          </div>
                          <img src={accordionImg} alt="accordion" />
                        </div>
                        <div className="content">
                          <div className="data-table">
                            <table>
                              <tbody>
                                <tr className="table-header">
                                  <th>Supplier Name</th>
                                  <th>SWIFT/BIC Code</th>
                                  <th>Bank</th>
                                  <th>Bank Code</th>
                                  <th>Branch Code</th>
                                  <th>Amount</th>
                                  <th>Account Number</th>

                                  <th>Paying House Bank</th>
                                  <th>Paying Account Number</th>

                                  <th>Payment Date</th>
                                  <th>Reference</th>
                                  <th>Supplier's Email</th>
                                </tr>
                                {item.bank_items.map((bank_item) => {
                                  return (
                                    <tr className={`table-data-row`}>
                                      <td>{bank_item.vendor_description}</td>
                                      <td>{bank_item.swift_code}</td>
                                      <td>{bank_item.payee_bank}</td>
                                      <td>
                                        {/* <input type="text" /> */}
                                        {bank_item.payee_bank_code}
                                      </td>
                                      <td>
                                        {/* <input type="text" /> */}
                                        {bank_item.payee_branch_code}
                                      </td>
                                      <td>{formatCurrency(bank_item.total_invoiced_value)}</td>
                                      <td>{bank_item.payee_bank_account}</td>
                                      <td>{item.house_bank_key}</td>
                                      <td>{bank_item.paying_account_number}</td>

                                      <td>{bank_item.payee_bank_code}</td>
                                      <td>{bank_item.reference}</td>
                                      <td>{bank_item.email}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
      <p className="mt-5">Bank Proposal Comment</p>
      <textarea className=" notes" onChange={handleChange} rows={5} value={notes} placeholder="Type message here..."></textarea>
    </div>
  );
};

export default BankApproval;
