import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "store/store";
import { CostElementState } from "./interface";
import { toastr } from "react-redux-toastr";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState: CostElementState = {
  loading: false,
  summary: {},
  listCostElement: null,
  costElement: null
};

const CostElementSlice = createSlice({
  name: "CostElement",
  initialState,
  reducers: {
    startRequest: (state) => {
      state.loading = true;
    },
    endRequest: (state) => {
      state.loading = false;
    },
    // fetchSummary: (state, action) => {
    //   state.summary = action.payload;
    // },
    fetchAllCostElement: (state, action) => {
      state.costElement = action.payload;
      state.loading = false;
    },
    fetchCostElementList: (state, action) => {
      state.listCostElement = action.payload;
    }
  }
});

const { actions, reducer } = CostElementSlice;

export const { startRequest, endRequest, fetchAllCostElement, fetchCostElementList } = actions;

// export const getSummary = (): AppThunk => async (dispatch: AppDispatch, getState) => {
//   const { userToken } = getState().user;
//   try {
//     const res = await axios.get(`${BASE_URL}/budgets/summary`, {
//       headers: {
//         Authorization: `Bearer ${userToken}`,
//         "Content-Type": "application/json"
//       }
//     });
//     if ([200, 201].includes(res.status)) {
//       dispatch(fetchSummary(res.data.data));
//     }
//   } catch (err) {
//     toastr.error(err?.response?.data?.message);
//   }
// };

export const getAllCostElement =
  (page?: number, length: number = 1000, search?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(startRequest());
    const { userToken } = getState().user;
    try {
      const res = await axios.get(`${BASE_URL}/cost_elements/get`, {
        params: {
          page,
          length,
          search
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(res.status)) {
        dispatch(endRequest);
        dispatch(fetchAllCostElement(res.data));
        // toastr.success(res.data?.message);
      }
    } catch (err) {
      dispatch(endRequest());
      toastr.error(err?.response?.data?.message);
    }
  };

export const getCostElementList =
  (page?: number, length: number = 1000, search?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(startRequest());
    const { userToken } = getState().user;
    try {
      const res = await axios.get(`${BASE_URL}/cost_elements/list`, {
        params: {
          page,
          length,
          search
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(res.status)) {
        dispatch(endRequest);
        dispatch(fetchCostElementList(res.data));
        // toastr.success(res.data?.message);
      }
    } catch (err) {
      dispatch(endRequest());
      toastr.error(err?.response?.data?.message);
    }
  };

export const setCostElement =
  (data?: object, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(startRequest());
    const { userToken } = getState().user;
    try {
      const res = await axios.post(
        `${BASE_URL}/invoices/update_cost_element`,
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json"
          }
        }
      );
      if ([200, 201].includes(res.status)) {
        dispatch(endRequest);
        toastr.success(res.data?.message);
        cb && cb();
      }
    } catch (err) {
      dispatch(endRequest());
      toastr.error(err?.response?.data?.message);
    }
  };

// export const createBudgetHeader =
//   (data?: object, cb?: () => void): AppThunk =>
//   async (dispatch: AppDispatch, getState) => {
//     dispatch(startRequest());
//     const { userToken } = getState().user;
//     try {
//       const res = await axios.post(
//         `${BASE_URL}/budget_headers/create`,
//         { ...data },
//         {
//           headers: {
//             Authorization: `Bearer ${userToken}`,
//             "Content-Type": "application/json"
//           }
//         }
//       );
//       if ([200, 201].includes(res.status)) {
//         dispatch(endRequest);
//         toastr.success(res.data?.message);
//         cb && cb();
//       }
//     } catch (err) {
//       dispatch(endRequest());
//       toastr.error(err?.response?.data?.message);
//     }
//   };

// export const editBudgetHeader =
//   (data?: object, cb?: () => void): AppThunk =>
//   async (dispatch: AppDispatch, getState) => {
//     dispatch(startRequest());
//     const { userToken } = getState().user;
//     try {
//       const res = await axios.post(
//         `${BASE_URL}/budget_headers/edit`,
//         { ...data },
//         {
//           headers: {
//             Authorization: `Bearer ${userToken}`,
//             "Content-Type": "application/json"
//           }
//         }
//       );
//       if ([200, 201].includes(res.status)) {
//         dispatch(endRequest);
//         toastr.success(res.data?.message);
//         cb && cb();
//       }
//     } catch (err) {
//       dispatch(endRequest());
//       toastr.error(err?.response?.data?.message);
//     }
//   };

// export const getAllBudgetHeaders =
//   (page?: number, length?: number, search?: string, start_date?: string, end_date?: string, budget_header_status?: string): AppThunk =>
//   async (dispatch: AppDispatch, getState) => {
//     dispatch(startRequest());
//     const { userToken } = getState().user;
//     try {
//       const res = await axios.get(`${BASE_URL}/budget_headers/get?`, {
//         params: {
//           page,
//           length,
//           search,
//           start_date,
//           end_date,
//           budget_header_status
//         },
//         headers: {
//           Authorization: `Bearer ${userToken}`,
//           "Content-Type": "application/json"
//         }
//       });
//       if ([200, 201].includes(res.status)) {
//         dispatch(fetchBudgetHeaders(res.data));
//       }
//     } catch (err) {
//       dispatch(endRequest());
//       toastr.error(err?.response?.data?.message);
//     }
//   };

// export const getSingleBudgetHeader =
//   (id?: string): AppThunk =>
//   async (dispatch: AppDispatch, getState) => {
//     dispatch(startRequest());
//     const { userToken } = getState().user;
//     try {
//       const res = await axios.get(`${BASE_URL}/budget_headers/get/${id}`, {
//         headers: {
//           Authorization: `Bearer ${userToken}`,
//           "Content-Type": "application/json"
//         }
//       });
//       if ([200, 201].includes(res.status)) {
//         dispatch(fetchSingleBudgetHeader(res.data.data[0]));
//       }
//     } catch (err) {
//       dispatch(endRequest());
//       toastr.error(err?.response?.data?.message);
//     }
//   };

// export const getAllBudgets =
//   (page?: number, length?: number, search?: string): AppThunk =>
//   async (dispatch: AppDispatch, getState) => {
//     dispatch(startRequest());
//     const { userToken } = getState().user;
//     try {
//       const res = await axios.get(`${BASE_URL}/budgets/get?`, {
//         params: {
//           page,
//           length,
//           search
//         },
//         headers: {
//           Authorization: `Bearer ${userToken}`,
//           "Content-Type": "application/json"
//         }
//       });
//       if ([200, 201].includes(res.status)) {
//         dispatch(fetchAllBudgets(res.data));
//       }
//     } catch (err) {
//       dispatch(endRequest());
//       toastr.error(err?.response?.data?.message);
//     }
//   };

// export const deleteBudgetHeader =
//   (data?: object, cb?: () => void): AppThunk =>
//   async (dispatch: AppDispatch, getState) => {
//     dispatch(startRequest());
//     const { userToken } = getState().user;
//     try {
//       const res = await axios.post(
//         `${BASE_URL}/budget_headers/delete`,
//         { ...data },
//         {
//           headers: {
//             Authorization: `Bearer ${userToken}`,
//             "Content-Type": "application/json"
//           }
//         }
//       );
//       if ([200, 201].includes(res.status)) {
//         dispatch(endRequest);
//         toastr.success(res.data?.message);
//         cb && cb();
//       }
//     } catch (err) {
//       dispatch(endRequest());
//       toastr.error(err?.response?.data?.message);
//     }
//   };

// export const deleteBudget =
//   (data?: object, cb?: () => void): AppThunk =>
//   async (dispatch: AppDispatch, getState) => {
//     dispatch(startRequest());
//     const { userToken } = getState().user;
//     try {
//       const res = await axios.post(
//         `${BASE_URL}/budgets/delete`,
//         { ...data },
//         {
//           headers: {
//             Authorization: `Bearer ${userToken}`,
//             "Content-Type": "application/json"
//           }
//         }
//       );
//       if ([200, 201].includes(res.status)) {
//         dispatch(endRequest);
//         toastr.success(res.data?.message);
//         cb && cb();
//       }
//     } catch (err) {
//       dispatch(endRequest());
//       toastr.error(err?.response?.data?.message);
//     }
//   };

// export const confirmBudget =
//   (data?: object, cb?: () => void): AppThunk =>
//   async (dispatch: AppDispatch, getState) => {
//     dispatch(startRequest());
//     const { userToken } = getState().user;

//     try {
//       const res = await axios.post(
//         `${BASE_URL}/budget_headers/confirm`,
//         { ...data },
//         {
//           headers: {
//             Authorization: `Bearer ${userToken}`,
//             "Content-Type": "application/json"
//           }
//         }
//       );
//       if ([200, 201].includes(res.status)) {
//         toastr.success(res.data.message);
//         dispatch(endRequest());
//         cb && cb();
//       }
//     } catch (err) {
//       dispatch(endRequest());
//       toastr.error(err?.response?.data?.message);
//     }
//   };

export default reducer;
