import React from "react";
import AuthLayout from "./AuthContainer/AuthLayout";
import image from "assests/illustrations/buyer.png";
import Textbox from "components/Textbox";
import Button from "components/Button";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
// import { forgetPassword, forgetPasswordFail } from "store/slice/AuthSlice/AuthSlice";
import ErrorMesssage from "components/ErrorMessage";
import { RootState } from "store/store";
import { useHistory } from "react-router-dom";
import { forgetPassword } from "store/slice/BuyerSlice/AuthSlice/AuthSlice";

const ForgotPassword: React.FC = (props) => {
  type FormData = {
    email: string;
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const { errorMessage, isLoading } = useSelector((state: RootState) => state.buyerAuth);

  const { register, handleSubmit, errors } = useForm<FormData>({
    mode: "onBlur"
  });

  const onFormSubmit = (vals: FormData) => {
    const payload = { ...vals }
    dispatch(
        forgetPassword(payload, () => {
            
        })
    );
  };

  React.useEffect(() => {
    // dispatch(forgetPasswordFail(""));
  }, []);

  return (
    <AuthLayout
      bottomImage={image}
      content="Enter your GZI Buyer Portal email address and we’ll send you a link to reset your password."
      headerText="Forgot your Password?"
      firstLink="Remember your password?"
      firstLinkColor="Sign In"
      link="/buyer/login"
      padTop="6.8rem"
    >
      <div>
        {/* {errorMessage !== "" && <ErrorMesssage message={errorMessage} />} */}
        {/* {message !== "" && <ErrorMesssage message={message} success={true} />} */}
        {errorMessage !== "" && <ErrorMesssage message={errorMessage} />}
        <form className="pt-3" onSubmit={handleSubmit(onFormSubmit)}>
          <Textbox
            type="email"
            label="Email Address"
            placeholder="Enter Email"
            name="email"
            boxClasses="pb-4"
            error={errors.email && errors.email.message}
            customRef={register({
              required: "This field is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address"
              }
            })}
          />
          <Button label="Reset my Password" btnType="btn-primary" btnStyle="100%" loading={isLoading} />
        </form>
      </div>
    </AuthLayout>
  );
};

export default ForgotPassword;
