import React from "react";
import Tabs from "components/Tabs";
import Overview from "./Overview";
import CommercialRequirements from "pages/BuyerDashboard/SourcingMain/SourcingGeneral/CommercialRequirements";
import TechnicalRequirements from "./TechnicalRequirements";
import InvitedVendors from "./InvitedVendors";
import { useDispatch, useSelector } from "react-redux";
import { getRfpFields, getSingleRfp, publishRfp } from "store/slice/SourcingSlice/SourcingSlice";
import { RootState } from "store/store";
import RejectOnboarding from "components/ModalBuyer/RejectOnboarding";
import { useHistory, useLocation } from "react-router";
import { toastPopUp } from "utils/utils";
import { approveItem, rejectItem } from "store/slice/BuyerSlice/ApprovalSlice/ApprovalSlice";
import './styles.scss'

const RFPApproval = ({ match }) => {
  const menus = ["Overview", "Technical Requirements", "Commercial Requirements", "Invited Vendors"];

  const [menu, setMenu] = React.useState(menus[0]);

  const history = useHistory();
  const location = useLocation();
  const [rejectModal, setRejectModal] = React.useState<boolean>(false);

  const { id } = match.params;

  const dispatch = useDispatch();

  const overViewRef = React.useRef<any>();

  const { rfp, isLoading } = useSelector((state: RootState) => state.sourcing);

  const selectMenu = (name) => {
    setMenu(name);
    if (rfp.rfp_status === "-2") {
      if (menu === "Overview") {
        saveToDraft();
      }
    } else return;
  };

  const saveToDraft = () => {
    if (!overViewRef.current) return;
    overViewRef.current.getAlert();
  };

  const handlePublish = () => {
    const data = { rfp_id: id };
    dispatch(publishRfp(data));
  };

  React.useEffect(() => {
    dispatch(getRfpFields(id));
    dispatch(getSingleRfp(id));
  }, [id]);

  const approve = () => {
    toastPopUp(
      () => {
        dispatch(
          approveItem(
            {
              approval_queue_id: location.state,
              note: ""
            },
            () => history.push("/buyer/approvals")
          )
        );
      },
      "Approve",
      "Cancel",
      "Approve RFP",
      "",
      "#737a91"
    );
  };

  const reject = (vals) => {
    // if (window.confirm("Confirm Approve")) {
    dispatch(
      rejectItem(
        {
          approval_queue_id: location.state,
          note: vals
        },
        () => history.push("/buyer/approvals")
      )
    );
    // }
  };

  const closeReject = () => {
    setRejectModal(false);
  };

  return (
    <div id="sourcing-general">
      {rejectModal && <RejectOnboarding closeModal={closeReject} reject={reject} />}
      <div className="container">
        <div className="tab-container" style={{ position: "relative" }}>
          <div className="tab-select align-items-sm-center justify-content-between">
            <Tabs menus={menus} onSelect={selectMenu} />
            <div className="d-flex justify-content-center align-items-center mb-3" style={{ columnGap: "2rem" }}>
              <button
                type="button"
                // disabled={checkFiles?.includes("-1")}
                style={{
                  padding: "8px 30px",
                  background: " #F85A5A",
                  borderRadius: "10px",
                  color: "white"
                }}
                onClick={() => setRejectModal(true)}
              >
                {isLoading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Reject"}
              </button>
              <button
                type="submit"
                // disabled={checkFiles?.includes("-1") || vendor === ""}
                style={{
                  padding: "8px 30px",
                  background: "#00B0A5",
                  borderRadius: "10px",
                  color: "white"
                }}
                onClick={approve}
              >
                {isLoading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Approve"}
              </button>
            </div>
          </div>
          {/* )} */}
          <div className="tabs-page pb-5">
            <div className="route-transition">
              {menu === "Overview" && <Overview ref={overViewRef} parentCallback id={id} />}
              {menu === "Technical Requirements" && <TechnicalRequirements id={id} />}
              {menu === "Commercial Requirements" && <CommercialRequirements id={id} />}
              {menu === "Invited Vendors" && <InvitedVendors id={id} />}
              {/* {menu === "Other Information" && <h1>Other Information tab here</h1>} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RFPApproval;
