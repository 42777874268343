/* eslint-disable react-hooks/exhaustive-deps */
import StatsCard from "components/StatsCard";
import React, { useEffect, useState } from "react";
import "../styles.scss";
import LocationIcon from "assests/icons/buyer/group-box.svg";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import SearchIcon from "components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { refreshColumn } from "../data";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import useSearch from "hooks/useSearch";
import Pagination from "components/Pagination";
import { checkPermission, toastPopUp } from "utils/utils";
import moment from "moment";
import { deleteRefreshAction, getRefresh } from "store/slice/BuyerSlice/RefreshSlice/refreshSlice";

const RefreshPage = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { role_access } = useSelector((state: RootState) => state.user?.user);

  //Redux
  const dispatch = useDispatch<AppDispatch>();
  const { refresh, loading } = useSelector((state: RootState) => state.refresh);
  console.log(refresh);
  //Hook
  const { search, inputRef, handleChange, searched } = useSearch(getRefresh, perPage);

  // get material categories
  useEffect(() => {
    dispatch(getRefresh(page, perPage));
  }, []);

  useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  //Show Number for PerPage Request
  const handleNewPage = (pageNo: number, page: number = 1) => {
    setPerPage(pageNo);
    setPage(1);
    dispatch(getRefresh(page, pageNo, search));
  };

  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, perPage: number) => {
      setPage(page);
      dispatch(getRefresh(page, perPage, search));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, perPage: number) => {
      setPage(pageNo);
      dispatch(getRefresh(pageNo, perPage, search));
    },
    [search]
  );

  const delRefresh = (id: string, name: string) => {
    const payload = { purchase_order_request_id: id };

    toastPopUp(
      () => {
        dispatch(
          deleteRefreshAction(payload, () => {
            dispatch(getRefresh(page, perPage));
          })
        );
      },
      "Delete",
      "Cancel",
      "Delete the entity",
      name,
      "red"
    );
  };

  const refreshList =
    refresh &&
    refresh?.data?.map((item) => {
      return {
        req_code: item.requisition_code,
        name: `${item.lastname} ${item.othernames}`,
        email: item.email,
        date: moment(item.created_at).format("l"),
        deleteRefresh: () => checkPermission(role_access, "SETUP_INDEX") && delRefresh(item.id, item.requisition_code)
      };
    });

  return (
    <div id="settings-material-data">
      <div className="container mt-3">
        <div className="row mt-3 mb-5">
          <StatsCard statsNumber={refresh?.count ?? 0} text="Refresh Queue" classes="stats-icon" image={LocationIcon} iconBackgroundColor="rgba(45, 156, 219, 0.1)" />
        </div>

        <div className="d-flex  mb-3">
          <SearchIcon placeholder="Search" boxClasses="" textRef={inputRef} value={search} onChange={handleChange} />
          <Dropdown perPage={perPage} action={handleNewPage} />
        </div>
        <DataTable columns={refreshColumn} data={refreshList} loading={loading} />

        <Pagination page={page} lastPage={refresh?.pagination?.pageCount} paginate={paginate} total={refresh?.pagination?.total} handlePageChange={handlePageChange} perPage={perPage} />
      </div>
    </div>
  );
};

export default RefreshPage;
