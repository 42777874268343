import { useEffect } from "react";
import Modal from "components/Modal";
import Textbox from "components/Textbox";
import Button from "components/Button";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import SelectDropdown from "components/SelectDropdown";

import { getAllcurrencies } from "store/slice/CurrencySlice/CurrencySlice";
import { createExchangeRate, editExchangeRate, getExchageRate } from "store/slice/BuyerSlice/ExhangeRate/ExchangeRateSlice";

type Props = {
  closeModal: () => void;
  singleExchange?: any;
};
const CreateOrEditExchange = ({ closeModal, singleExchange = null }: Props) => {
  const { register, handleSubmit, errors, setValue } = useForm<FormData>({
    mode: "onChange"
  });
  console.log(singleExchange, "singleExchange");

  //Redux Store
  const dispatch = useDispatch<AppDispatch>();
  const { currencies } = useSelector((state: RootState) => state.currency);
  const { isLoading } = useSelector((state: RootState) => state.exchange_rate);

  type FormData = {
    from_currency_code: string;
    to_currency_code: string;
    exchange_rate: number;
  };

  useEffect(() => {
    if (singleExchange !== null) {
      setValue("from_currency_code", singleExchange.currency_from);
      setValue("to_currency_code", singleExchange.currency_to);
      setValue("exchange_rate", singleExchange.amount);
    }
  }, [singleExchange]);

  const onFormSubmit = (vals: FormData) => {
    if (singleExchange) {
      const payload = { ...vals, id: singleExchange.id };
      dispatch(
        editExchangeRate(payload, () => {
          closeModal();

          dispatch(getExchageRate(1, 10));
        })
      );
      return;
    }

    dispatch(
      createExchangeRate(vals, () => {
        closeModal();
        dispatch(getExchageRate(1, 10));
      })
    );
  };

  useEffect(() => {
    dispatch(getAllcurrencies());
  }, []);

  //Getting Companies List
  const currencyOptions =
    currencies &&
    currencies?.map((_data: { currency_iso: string; currency_description: string }) => {
      return {
        value: _data?.currency_iso,
        optionName: _data?.currency_description
      };
    });

  return (
    <Modal modalText={singleExchange === null ? "Create" : "Edit"} onClose={closeModal} modallength="modal-length">
      <div id="buyer-create-location">
        <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
          <SelectDropdown
            boxClasses="mt-3"
            name="from_currency_code"
            label="Currency From"
            placeholder="Currency From"
            options={currencyOptions}
            customRef={register({ required: true })}
            error={errors.from_currency_code && "This field is required"}
          />
          <SelectDropdown
            boxClasses="mt-3"
            name="to_currency_code"
            label="Currency To"
            placeholder="Currency To"
            options={currencyOptions}
            customRef={register({ required: true })}
            error={errors.to_currency_code && "This field is required"}
          />

          <Textbox
            type="number"
            label="Exchange Rate"
            name="exchange_rate"
            placeholder="Exchange Rate Amount"
            boxClasses="mt-3"
            error={errors.exchange_rate && "This field is required"}
            customRef={register({ required: true })}
          />

          <Button label={singleExchange === null ? "Create" : "Edit"} btnType="btn-primary" boxClasses="mt-4 mb-5" btnStyle="100%" loading={isLoading} />
        </form>
      </div>
    </Modal>
  );
};

export default CreateOrEditExchange;
