/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useLayoutEffect, useRef, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import "./styles.scss";
import Button from "components/Button";
import CloseMenu from "assests/icons/close-menu.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { useLocation } from "react-router-dom";
import { ReactComponent as User } from "assests/icons/sidebar/person-add.svg";
import { ReactComponent as Budget } from "assests/icons/sidebar/budget.svg";
import { ReactComponent as Settings } from "assests/icons/sidebar/settings.svg";
import { ReactComponent as Chart } from "assests/icons/sidebar/ios-pie.svg";
import { ReactComponent as Histogram } from "assests/icons/sidebar/histogram.svg";
import { ReactComponent as ApprovalIcon } from "../../assests/icons/rect.svg";
import { ReactComponent as SupplierIcon } from "assests/icons/buyer/sidebar/supplierIcon.svg";
import { ReactComponent as RequistionIcon } from "assests/icons/buyer/sidebar/requistion.svg";
import { ReactComponent as OrderIcon } from "assests/icons/buyer/sidebar/order.svg";
import { ReactComponent as InvoiceIcon } from "assests/icons/buyer/sidebar/invoice.svg";
import { ReactComponent as InventoryIcon } from "assests/icons/buyer/sidebar/invent.svg";
import { ReactComponent as EvalutionIcon } from "assests/icons/buyer/sidebar/evalution.svg";
import { ReactComponent as SourcingIcon } from "assests/icons/buyer/sidebar/sourcing.svg";
import { ReactComponent as ArrowUp } from "assests/icons/buyer/sidebar/Group (1).svg";
import { ReactComponent as ArrowDown } from "assests/icons/Vector (3) copy 3.svg";
import { ReactComponent as Treasury } from "assests/icons/buyer/sidebar/bank-svgrepo-com 2.svg";
import HelpIcon from "assests/icons/buyer/sidebar/icons8-help-24.png";
import GZIBuyerLogo from "components/GZIBuyerLogo";
import { ReactComponent as Statement } from "assests/icons/sidebar/statement.svg";
export interface SidebarProps {
  menu: boolean;
  handleMenu: () => void;
  setMenu: any;
}

const BuyerSidebar: React.FunctionComponent<SidebarProps> = ({ menu, handleMenu, setMenu }) => {
  const dropdown = useRef<HTMLDivElement | null>(null);
  const { pathname } = useLocation();
  const history = useHistory();

  //Closes the sideBar on route changes on mobile view
  React.useEffect(() => {
    if (menu) {
      setMenu(false);
    }
  }, [pathname]);

  //closes the sidebar
  useEffect(() => {
    const hide = (e: any): void => {
      if (!dropdown!.current?.contains(e.target)) {
        setMenu(false);
      }
    };

    window.addEventListener("mousedown", hide);
    return () => {
      window.removeEventListener("mousedown", hide);
    };
  }, []);

  const dispatch = useDispatch();
  // const { permissions } = useSelector((state: RootState) => state.user);
  const { roles, isLoading, permissions } = useSelector((state: RootState) => state.roles);
  const { role_access } = useSelector((state: RootState) => state.user?.user);
  const userPermissions = role_access.map((perm) => perm.permission_name);
  // console.log(userPermissions);

  return (
    <div className={`buyer-side-bar ${menu ? "show-left" : "hide-left"}`} ref={dropdown}>
      <div className="side-bar-navigation">
        <div className="brand-logo">
          <GZIBuyerLogo small />
          {/* <img src={CloseMenu} alt="menu" className="close-menu cursor-pointer" onClick={handleMenu} /> */}
        </div>
        <div className="mt-4 mb-2 fixedDiv">
          <NavLink activeClassName="active" className="d-flex align-items-center nav-link" to="/buyer/dashboard">
            <Chart className="icon" />
            <h6 className="ml-3 route-name">Dashboard</h6>
          </NavLink>
          <NavLink activeClassName="active" className="d-flex align-items-center nav-link" to="/buyer/suppliers">
            <SupplierIcon className="icon" />
            <h6 className="ml-3 route-name">Suppliers</h6>
          </NavLink>
          {/* Approval */}
          <NavLink activeClassName="active" className="d-flex align-items-center nav-link" to="/buyer/approvals">
            <ApprovalIcon className="icon" />
            <h6 className="ml-3 route-name">Approvals</h6>
          </NavLink>
          {/* Approval end */}

          {userPermissions?.includes("REQUISITIONS_VIEW") && (
            <NavLink activeClassName="active" className="d-flex align-items-center nav-link" to="/buyer/requisition">
              <RequistionIcon className="icon" />
              <h6 className="ml-3 route-name">Requisition</h6>
            </NavLink>
          )}

          {userPermissions?.includes("RFPS_INDEX") && (
            <NavLink activeClassName="active" className="d-flex align-items-center nav-link" to="/buyer/sourcing">
              <SourcingIcon className="icon" />
              <h6 className="ml-3 route-name">Sourcing</h6>
            </NavLink>
          )}

          {userPermissions?.includes("RFPS_INDEX") && (
            <NavLink activeClassName="active" className="d-flex align-items-center nav-link" to="/buyer/rfx-evaluation">
              <EvalutionIcon className="icon" />
              <h6 className="ml-3 route-name">Evaluation</h6>
            </NavLink>
          )}

          {userPermissions?.includes("PURCHASE_ORDERS_INDEX") && (
            <NavLink activeClassName="active" className="d-flex align-items-center nav-link" to="/buyer/purchase-orders">
              <OrderIcon className="icon" />
              <h6 className="ml-3 route-name">Purchase Orders</h6>
            </NavLink>
          )}
          <NavLink activeClassName="active" className="d-flex align-items-center nav-link" to="/buyer/goods-receipt">
            <InventoryIcon className="icon" />
            <h6 className="ml-3 route-name">Goods Receipt</h6>
          </NavLink>

          {userPermissions?.includes("INVOICE_MANAGEMENT") && (
            <NavLink activeClassName="active" className="d-flex align-items-center nav-link" to="/buyer/invoice">
              <InvoiceIcon className="icon" />
              <h6 className="ml-3 route-name">Invoice</h6>
            </NavLink>
          )}

          {/* {userPermissions?.includes("") && ( */}
          {/* <NavLink activeClassName="active" className="d-flex align-items-center nav-link" to="/buyer/inventory">
            <InventoryIcon className="icon" />
            <h6 className="ml-3 route-name">Inventory</h6>
          </NavLink> */}
          {/* )} */}

          {/* {userPermissions?.includes("") && ( */}
          <NavLink activeClassName="active" className="d-flex align-items-center nav-link" to="/buyer/report">
            <Histogram className="icon" />
            <h6 className="ml-3 route-name">Reports</h6>
          </NavLink>
          {/* )} */}

          {userPermissions?.includes("USER_ROLES_INDEX") && (
            <NavLink activeClassName="active" className="d-flex align-items-center nav-link" to="/buyer/user-mgt">
              <User className="icon" />
              <h6 className="ml-3 route-name">User Mgt</h6>
            </NavLink>
          )}

          {userPermissions?.includes("BUDGET_MANAGEMENT") && (
            <NavLink activeClassName="active" className="d-flex align-items-center nav-link" to="/buyer/budget">
              <Budget className="icon" />
              <h6 className="ml-3 route-name">Budget</h6>
            </NavLink>
          )}

          {userPermissions?.includes("BUDGET_MANAGEMENT") && (
            <NavLink activeClassName="active" className="d-flex align-items-center nav-link mb-3" to="/buyer/statement">
              <Statement className="icon" />
              <h6 className="ml-3 route-name">Supplier Statement</h6>
            </NavLink>
          )}

          {/* {userPermissions?.includes("BUDGET_MANAGEMENT") && ( */}
          {userPermissions?.includes("TREASURY_INDEX") && (
            <React.Fragment>
              <NavLink activeClassName="active" className="d-flex align-items-center nav-link" to="/buyer/treasury">
                <Treasury className="icon" />
                <h6 className="ml-3 route-name">Treasury</h6>
                <span className="d-inline-block mx-auto">{pathname.split("/")[2] === "treasury" ? <ArrowUp /> : <ArrowDown />}</span>
              </NavLink>
              {pathname.split("/")[2] === "treasury" && (
                <ul>
                  <li className={pathname.split("/")[3] === undefined ? "active" : ""} onClick={() => history.push("/buyer/treasury")}>
                    Posted Invoices
                  </li>
                  <li className={pathname.split("/")[3] === "proposal-queue" ? "active" : ""} onClick={() => history.push("/buyer/treasury/proposal-queue")}>
                    Proposal Queue
                  </li>
                  <li className={pathname.split("/")[3] === "payment-runs" ? "active" : ""} onClick={() => history.push("/buyer/treasury/payment-runs")}>
                    Payment Run
                  </li>

                  <li className={pathname.split("/")[3] === "bank-proposal" ? "active" : ""} onClick={() => history.push("/buyer/treasury/bank-proposal")}>
                    Bank Proposal
                  </li>
                </ul>
              )}
            </React.Fragment>
          )}

          {/* )} */}

          {userPermissions?.includes("VENDOR_EVALUATIONS") && (
            <NavLink activeClassName="active" className="d-flex align-items-center nav-link" to="/buyer/vendor-rating">
              <Histogram className="icon" />
              <h6 className="ml-3 route-name">Supplier Rating</h6>
            </NavLink>
          )}

          {userPermissions?.includes("SETUP_INDEX") && (
            <NavLink activeClassName="active" className="d-flex align-items-center nav-link" to="/buyer/settings">
              <Settings className="icon" />
              <h6 className="ml-3 route-name">Settings</h6>
            </NavLink>
          )}

          <a className="d-flex align-items-center nav-link" target="_blank" href="http://buyer-docs.gzi.yaraa.io">
            <img src={HelpIcon} alt="icon" />
            <h6 className="ml-3 route-name">Training Doc</h6>
          </a>
        </div>
      </div>
    </div>
  );
};

export default BuyerSidebar;
