import { useEffect } from "react";
import Modal from "components/Modal";
import Textbox from "components/Textbox";
import Button from "components/Button";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import SelectDropdown from "components/SelectDropdown";
import { editLocation, getLocations } from "store/slice/BuyerSlice/LocationSlice/LocationSlice";
import { uploadFile } from "store/slice/BuyerSlice/ProductSlice/ProductSlice";
import { useState } from "react";
import FileUploaderBox from "components/FileUploaderBox";
import { createElement, getElements } from "store/slice/BuyerSlice/CostElement/CostElementSlice";
import { createTax, editTax, getTax } from "store/slice/BuyerSlice/TaxSlice/TaxSlice";
import { getAllCompaniesData } from "store/slice/BuyerSlice/CompanyDataSlice/CompanyDataSlice";

type Props = {
  closeModal: () => void;
  singleTax?: any;
};
const CreateOrEditTax = ({ closeModal, singleTax = null }: Props) => {
  const { register, handleSubmit, errors, setValue } = useForm<FormData>({
    mode: "onChange"
  });

  //Redux Store
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading } = useSelector((state: RootState) => state.tax);
  const { companies } = useSelector((state: RootState) => state.companiesData);
  
  const [files, setFiles] = useState<any>([]);
  const [uploadBool, setUploadBool] = useState(false);
  const checkUpload = () => {
    setUploadBool(!uploadBool);
  };

  type FormData = {
    tax_code: string;
    tax_code_description: string;
    tax_amount:string;
    company_id:string
  };

  useEffect(() => {
    if (singleTax !== null) {
      setValue("tax_code", singleTax.tax_code);
      setValue("tax_code_description", singleTax.tax_code_description);
      setValue("tax_amount", singleTax.tax_amount);
      setValue("company_id", singleTax.company_id);
    }
  }, [singleTax]);


  useEffect(() => {
    dispatch(getAllCompaniesData(1,1000));
  }, []);



  const onFormSubmit = (vals: FormData) => {
    if (singleTax) {
      const payload = { ...vals, id: singleTax.id };
      dispatch(
        editTax(payload, () => {
          closeModal();
          dispatch(getTax());
        })
      );
      return;
    }

    dispatch(
      
      createTax(vals, () => {
        closeModal();
        dispatch(getTax());
      })
    );
  };
  const handleFile = (e, field_name: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(e);
    reader.onloadend = () => {
      // use a regex to remove data url part
      let read: any = reader.result;
      const payload = {
        base64_data: read.replace("data:", "").replace(/^.+,/, ""),
        file_extension: e.name.split(".").pop()
      };
      dispatch(uploadFile(payload));

      setFiles(payload);
    };
  };

   //Getting Companies List
   const companiesOptions =
   companies &&
   companies?.data?.map((_data: { id: string; company_description: string }) => {
     return {
       value: _data?.id,
       optionName: _data?.company_description
     };
   });

  
  return (
    <Modal
      modalText={singleTax === null ? "Create" : "Edit"}
      onClose={closeModal}
      modallength="modal-length"
      isUpload={true}
      checkUpload={checkUpload} uploadBool={uploadBool}
    >
      {
        uploadBool ? 
        <div id="buyer-create-location">
          <p className="">Download and fill the Product Category template. adhere to file structure before uploading the Categoroes</p>
          <form className="mt-2">
            <p className="template">xxxx Teemplate sourcing team</p>

            <FileUploaderBox
              fileName=""
              boxClasses="mt-3 mb-5"
              handleFile={handleFile}
              acceptFiles={".jpg, .jpeg, .png"}
              customRef={register({ required: true })}
              error={errors["logo"] && "This field is required"}
            />

          <Button
            label={singleTax === null ? "Create Category" : "Edit Category"}
            btnType="btn-primary"
            boxClasses="mt-4 mb-5"
            btnStyle="100%"
            loading={isLoading}
          />
          </form>
        </div>
      
        : 
        <div id="buyer-create-location">
   

        <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
          <Textbox
            type="text"
            name="tax_code"
            label="Tax Code"
            placeholder="Tax Code"
            error={errors.tax_code && "This field is required"}
            customRef={register({
              required: true
            })}
          />
          <Textbox
            type="text"
            label="Tax Description"
            name="tax_code_description"
            placeholder="Tax Description"
            boxClasses="mt-3"
            error={errors.tax_code_description && "This field is required"}
            customRef={register({ required: true })}
          />
           <Textbox
            type="text"
            label="Tax Amount"
            name="tax_amount"
            placeholder="Tax Amount"
            boxClasses="mt-3"
            error={errors.tax_amount && "This field is required"}
            customRef={register({ required: true })}
          />
          <SelectDropdown
             boxClasses="mt-3 mb-4"
             name="company_id"
             label="Company"
             placeholder="Company"
             options={companiesOptions}
             customRef={register({ required: true })}
             error={errors.company_id && "This field is required"}
           />
     

          <Button
            label={singleTax === null ? "Create" : "Edit"}
            btnType="btn-primary"
            boxClasses="mt-4 mb-5"
            btnStyle="100%"
            loading={isLoading}
          />
        </form>
      </div>
   
      }
      
    </Modal>
  );
};

export default CreateOrEditTax;
