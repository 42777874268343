import React, { useEffect, useState } from 'react';
import Modal from 'components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { createBudgetLineItems, getSingleBudgetHeader } from 'store/slice/BuyerSlice/Budget/BudgetSlice';
import { useForm, Controller } from 'react-hook-form';
import Textbox from 'components/Textbox';
import AsyncSelect from "react-select/async";
import API from "utils/httpsRequest";
import { RootState } from 'store/store';
import Button from 'components/Button';
import styled from "styled-components";

const Form = styled.form`
  .mySelect__control {
    border-radius: 10px;
    border: 1px solid rgb(223, 226, 230) !important;
  }

  .mySelect__value-container {
    height: 3rem;
  }
`;

const CreateLineItems = ({
    closeModal,
    modalData,
    modalText,
    id,
    companyId,
    budgetId
}) => {

    const dispatch = useDispatch();

    const { user } = useSelector((state: RootState) => state.user);

    const { isLoading } = useSelector((state: RootState) => state.budget);

    const [curVal, setCurVal] = useState('');
    const [selCurVal, setSelCurVal] = useState('');

    const [depVal, setDeptVal] = useState('');
    const [selDeptVal, setSelDeptVal] = useState('');

    const [matVal, setMatVal] = useState('');
    const [selMatVal, setSelMatVal] = useState('');

    const [pgVal, setPgVal] = useState('');
    const [selPgVal, setSelPgVal] = useState('');

    const { token } = user;

    const {
        register,
        handleSubmit,
        errors,
        control,
    } = useForm({
        mode: 'onChange'
    });

    const onSubmit = (vals) => {
        const data = {
            ...vals,
            currency_id: selCurVal?.['value'],
            department_id: selDeptVal?.['value'],
            material_category_id: selMatVal?.['value'],
            purchasing_group: selPgVal?.['value'],
            company_id: companyId,
            budget_header_id: budgetId,
        }
        console.log(data);
        dispatch(createBudgetLineItems(data, () => {
            dispatch(getSingleBudgetHeader(id));
            closeModal();
        }))
    }

    const handleInputChangeCur = val => setCurVal(val);

    const onChangeCur = val => setSelCurVal(val);

    const handleInputChangeDept = val => setDeptVal(val);

    const onChangeDept = val => setSelDeptVal(val);

    const handleInputChangeMat = val => setMatVal(val);

    const onChangeMat = val => setSelMatVal(val);

    const handleInputChangePg = val => setPgVal(val);

    const onChangePg = val => setSelPgVal(val);

    const loadCur = () => {
        return API.get(`/currencies/get?page=1&length=10&search=${curVal}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            return res.data.data.map((item) => ({
                label: item.currency_description,
                value: item.id,
            }))
        })
    }

    const loadDept = () => {
        return API.get(`/departments/get?page=1&length=10&company_id=${companyId}&search=${depVal}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            return res.data.data.map((item) => ({
                label: item.department_description,
                value: item.id,
            }))
        })
    }

    const loadPurchaseGrp = () => {
        return API.get(`/purchasing_groups/get?page=1&length=10&search=${pgVal}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            return res.data.data.map((item) => ({
                label: `${item.purchasing_group_description} - ${item.purchasing_group_code}`,
                value: item.purchasing_group_code,
            }))
        })
    }

    const loadMatCat = () => {
        return API.get(`/material_categories/get?page=1&length=10&search=${matVal}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            return res.data.data.map((item) => ({
                label: item.material_category_description,
                value: item.id,
            }))
        })
    }

    useEffect(() => {
        console.log(modalData)
    }, [modalData]);

    return (
        <Modal
            modalText={`${modalText} Budget Line`}
            onClose={() => {
                closeModal();
                dispatch(getSingleBudgetHeader(id));
            }}
        >
            <p className="">Here you would create the budget line items</p>
            <Form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
                <Textbox
                    type="text"
                    name="budget_description"
                    label="Budget Description"
                    placeholder="Budget Description"
                    customRef={register({
                        required: true
                    })}
                    error={errors.budget_description && 'This field is required'}
                />

                <br />

                <Controller
                    control={control}
                    defaultValue
                    name="currency_id"
                    render={({ ref }) => (
                        <AsyncSelect
                            cacheOptin
                            defaultOptions
                            placeholder="Select Currency"
                            value={selCurVal}
                            loadOptions={loadCur}
                            onInputChange={handleInputChangeCur}
                            onChange={onChangeCur}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            classNamePrefix="mySelect"
                        />
                    )}
                />

                <br />

                <Textbox
                    type="number"
                    name="budget_amount"
                    label="Budget Amount"
                    placeholder="Budget Amount"
                    error={errors.budget_amount && 'This field is required'}
                    customRef={register({
                        required: true,
                    })}
                />

                <Textbox
                    type="date"
                    boxClasses="mt-3"
                    name="start_date"
                    label="Start Date"
                    placeholder="Select Start Date"
                    error={errors.start_date && "This field is required"}
                    customRef={register({
                        required: true
                    })}
                />
                <Textbox
                    type="date"
                    boxClasses="mt-3"
                    name="end_date"
                    label="End Date"
                    placeholder="Select End Date"
                    customRef={register({ required: true })}
                    error={errors.end_date && 'This field is required'}
                />

                <br />

                <Controller
                    control={control}
                    defaultValue
                    name="department_id"
                    render={({ ref }) => (
                        <AsyncSelect
                            cacheOptin
                            defaultOptions
                            placeholder="Select Company Department"
                            value={selDeptVal}
                            loadOptions={loadDept}
                            onInputChange={handleInputChangeDept}
                            onChange={onChangeDept}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            classNamePrefix="mySelect"
                        />
                    )}
                />

                <br />

                <Controller
                    control={control}
                    defaultValue
                    name="material_category_id"
                    render={({ ref }) => (
                        <AsyncSelect
                            cacheOptin
                            defaultOptions
                            placeholder="Select Material Category"
                            value={selMatVal}
                            loadOptions={loadMatCat}
                            onInputChange={handleInputChangeMat}
                            onChange={onChangeMat}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            classNamePrefix="mySelect"
                        />
                    )}
                />

                <br />

                <Controller
                    control={control}
                    defaultValue
                    name="purchasing_group"
                    render={({ ref }) => (
                        <AsyncSelect
                            cacheOptin
                            defaultOptions
                            placeholder="Select Purchasing Group"
                            value={selPgVal}
                            loadOptions={loadPurchaseGrp}
                            onInputChange={handleInputChangePg}
                            onChange={onChangePg}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            classNamePrefix="mySelect"
                        />
                    )}
                />

                <br />

                <Button
                    label={isLoading ? 'Please wait' : 'Create Budget Line Item'}
                    btnType="btn-primary"
                    boxClasses="mt-4 mb-3"
                    btnStyle="100%"
                    disabled={isLoading}

                />
                <p onClick={closeModal} style={{ textAlign: "center", cursor: "pointer" }}>
                    Cancel
                </p>

            </Form>
        </Modal>
    )
}

export default CreateLineItems;