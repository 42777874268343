import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { idText } from "typescript";

const useSearch = (url: any, perpage: number, id?: any, params: any = []) => {
  const [search, setSearch] = useState("");
  const [searched, setSearched] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const componentJustMounted = useRef<boolean>(true);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    let timer: any;
    if (!componentJustMounted.current) {
      timer = setTimeout(() => {
        //Debounce
        if (search === inputRef.current!.value) {
          if (inputRef.current!.value) setSearched(true);
          if (id) {
            dispatch(url({ page: 1, length: perpage, search, ...params, id: id }));
          } else {
            dispatch(url(1, perpage, search, ...params, id));
          }
        }
      }, 500);
    }
    componentJustMounted.current = false;
    return () => {
      clearTimeout(timer);
      setSearched(false);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, inputRef]);

  return {
    search,
    inputRef,
    handleChange,
    searched,
    setSearch
  };
};

export default useSearch;
