import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { statementState, Action } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";

const initialState: statementState = {
  loading: false,
  deleting: false,
  isLoading: false,
  statement: null,
  successMessage: "",
  errorMessage: ""
};

const statementSlice = createSlice({
  name: "statement",
  initialState,
  reducers: {
    getAllStatementLoading: (state) => {
      state.loading = true;
    },
    getAllStatementSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.statement = action.payload;
    },
    getAllStatementFail: (state) => {
      state.loading = false;
    }
  }
});

const { actions, reducer } = statementSlice;
export const { getAllStatementLoading, getAllStatementSuccess, getAllStatementFail } = actions;

export const getStatement =
  (
    page?: number,
    length?: number,
    search?: string,
    vendor_id?: any,
    company_id?: string,
    run_date?: string,
    proposal_id?: string,
    proposal_item_id?: string,
    currency_id?: string,
    start_date?: string,
    end_date?: string
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getAllStatementLoading());
    try {
      const response = await API.get("/invoice_debits/get_by_vendor", {
        params: {
          page,
          length,
          search,
          vendor_id,
          start_date,
          end_date,
          company_id,
          run_date,
          proposal_id,
          proposal_item_id,
          currency_id,
          cleared: "No"
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getAllStatementSuccess(response.data));
      }
    } catch (error) {
      dispatch(getAllStatementFail());
    }
  };

export default reducer;
