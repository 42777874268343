import React, { useEffect } from "react";
import Modal from "components/Modal";
import Textbox from "components/Textbox";
import Button from "components/Button";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { createSupplierUser, editSupplierUser, getSupplierUser, getSupplierUserBuyer, getSupplierUsers } from "store/slice/SupplierSlices/SupplierUserSlice/SupplierUserSlice";
import { RootState } from "store/store";
import { getCompaniesEntityList } from "store/slice/CompaniesSlice/CompaniesSlice";
import { getRoles } from "store/slice/Roleslice/RoleSlice";
import { getDepartments } from "store/slice/BuyerSlice/DepartmentSlice/DepartmentSlice";
import SelectDropdown from "components/SelectDropdown";
import { getUsersSupplier } from "store/slice/UserMgtSlice/UserMgtSlice";

type Props = {
  closeModal: () => void;
  typeUser?: string;
  vendId?: string;
  modalData: { userId: string; viewStatus: boolean; editStatus: boolean };
};

const SupplierCreateUser = ({ closeModal, modalData, typeUser, vendId }: Props) => {
  const [error, setError] = React.useState("");
  const { register, handleSubmit, errors, setValue, watch } = useForm<FormData>({
    mode: "onChange"
  });
  const dispatch = useDispatch();
  const values = watch();

  type FormData = {
    othernames: string;
    lastname: string;
    password: string;
    email: string;
    phone_number: number;
    // company_id: string;
    user_type: string;
    // department_id: number;
    user_role_id: number;
  };

  const { userId, viewStatus, editStatus } = modalData;
  // const { roles } = useSelector((state: RootState) => state.roles);
  const { isLoading, singleUser, users } = useSelector((state: RootState) => state.supplierUser);
  const { companies } = useSelector((state: RootState) => state.companies);
  const { roles } = useSelector((state: RootState) => state.roles);
  const { departments } = useSelector((state: RootState) => state.department);

  //Get needed form data
  React.useEffect(() => {
    // dispatch(getCompaniesEntityList());
    dispatch(getRoles(undefined, undefined, undefined, "vendor"));
    // dispatch(getDepartments());
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Get single user details

  React.useEffect(() => {
    if (viewStatus || editStatus) {
      if (typeUser === "buyer") {
        dispatch(getSupplierUserBuyer(userId));
      } else {
        dispatch(getSupplierUser(userId));
      }
    }
  }, [userId, viewStatus, editStatus]);

  //Update fields
  React.useEffect(() => {
    if (singleUser !== null && (editStatus || viewStatus)) {
      setValue("othernames", singleUser?.data[0]?.othernames);
      setValue("email", singleUser?.data[0]?.email);
      setValue("lastname", singleUser?.data[0]?.lastname);
      setValue("phone_number", singleUser?.data[0]?.phone_number);
      setValue("user_role_id", singleUser?.data[0].user_role_id);
      //   setValue("user_type", singleUser?.data[0].user_type);
      //   setValue("company_id", singleUser?.data[0].company_id);
      //   setValue("department_id", singleUser?.data[0].department_id);
    }
  }, [singleUser, editStatus, viewStatus]);

  //   validate phone
  const validatePhoneNo = (value: any) => {
    let values = value.replace(/[^0-9]*/g, "");
    if (value.length > 11 || value !== values) return "Should be a phone number";
  };

  const onFormSubmit = (vals: FormData) => {
    if (isLoading) return;
    // if (selectedOption === "" && !editStatus) {
    //   setError("Please select a role");
    //   return;
    // }
    const { ...rest } = vals;
    const data: any = { ...rest };
    if (editStatus) {
      dispatch(
        editSupplierUser("", { id: userId, user_type: "vendor", user_role_id: "4000002", ...data }, () => {
          closeModal();
          if (typeUser === "buyer") {
            dispatch(getUsersSupplier({ page: 1, length: 10, id: vendId }));
          } else {
            dispatch(getSupplierUsers(1, 10, "", "vendor"));
          }
        })
      );
    } else {
      dispatch(
        createSupplierUser({ user_role_id: "4000002", user_type: "vendor", ...data }, () => {
          closeModal();
          dispatch(getSupplierUsers(1, 10, "", "vendor"));
        })
      );
    }
  };

  //   //Remove error message
  //   React.useEffect(() => {
  //     if (keyOption) {
  //       setError("");
  //     }
  //     //eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [keyOption]);

  //Companies array
  const companiesArray =
    companies &&
    companies.map((element: { id: string; company_description: string }) => {
      return {
        value: element?.id,
        optionName: element?.company_description
      };
    });

  //departments array
  //   const departmentArray = [] as any;
  //   departments &&
  //     departments?.data?.forEach((element: { id: string; company_id: string; department_description; string }) => {
  //       if (element.company_id === values.company_id) {
  //         departmentArray.push({
  //           value: element?.id,
  //           optionName: element?.department_description
  //         });
  //       }
  //     });

  //Roles array
  const rolesArray =
    roles &&
    roles?.data.map((element: { id: string; user_role_name: string }) => {
      return {
        value: element?.id,
        optionName: element?.user_role_name
      };
    });

  //  user Types
  //   const userTypes: Object[] = [
  //     { value: "Vendor", optionName: "Vendor" },
  //     { value: "Company", optionName: "Company" }
  //   ];
  // Modal text
  let modalText = "Create User";
  if (editStatus) {
    modalText = "Edit User";
  } else if (viewStatus) {
    modalText = "View User";
  }

  return (
    <Modal modalText={modalText} onClose={closeModal} modallength={!viewStatus ? "modal-length" : ""}>
      <div id="createuser-modal">
        {!viewStatus && !editStatus ? <p className="">Complete this form with accurate data to create a new user on GZI Connect.</p> : null}
        <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
          <Textbox
            type="text"
            name="othernames"
            label="First Name"
            placeholder="First Name"
            error={errors.othernames && "This field is required"}
            customRef={register({
              required: true
            })}
            disabled={viewStatus}
          />
          <Textbox
            type="text"
            label="Last Name"
            name="lastname"
            placeholder="Last Name"
            boxClasses="mt-3"
            error={errors.lastname && "This field is required"}
            customRef={register({
              required: true
            })}
            disabled={viewStatus}
          />
          {/* <ArrowDropdown
            text="Role"
            options={[]}
            handleDropdownChange={handleDropdownChange}
            handleAction={handleAction}
            selectedOption={selectedOption}
            open={open}
            boxClasses="mt-3"
            ref={dropdownRef}
          /> */}
          <p className={`${error ? "d-block" : "d-none"} error mt-1`}>{error}</p>
          <Textbox
            type="email"
            label="Email address"
            placeholder="Email address"
            boxClasses="mt-3"
            name="email"
            error={errors.email && errors.email.message}
            customRef={register({
              required: "This field is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address"
              }
            })}
            disabled={viewStatus}
          />
          <Textbox
            type="number"
            name="phone_number"
            label="Phone Number"
            error={errors.phone_number && errors.phone_number.message}
            customRef={register({
              required: "This field is required",
              validate: validatePhoneNo
            })}
            placeholder="Phone Number"
            boxClasses="mt-3"
            disabled={viewStatus}
          />
          {/* <SelectDropdown
            boxClasses="mt-3"
            name="user_role_id"
            label="Role"
            placeholder="Select a Role"
            options={rolesArray}
            customRef={register({ required: true })}
            error={errors.user_role_id && "This field is required"}
            disabled={viewStatus}
          /> */}
          {/* <SelectDropdown
            boxClasses="mt-3"
            name="user_type"
            label="User Type"
            placeholder="Select User Type"
            options={userTypes}
            customRef={register({ required: true })}
            error={errors.user_type && "This field is required"}
            disabled={viewStatus}
          />
          <SelectDropdown
            boxClasses="mt-3"
            name="company_id"
            label="Company"
            placeholder="Select company"
            options={companiesArray}
            customRef={register({ required: false })}
            error={errors.company_id && "This field is required"}
            disabled={viewStatus}
          />
          <SelectDropdown
            boxClasses="mt-3"
            name="department_id"
            label="Department"
            placeholder="Select Depertment"
            options={departmentArray}
            customRef={register({ required: false })}
            error={errors.department_id && "This field is required"}
            disabled={viewStatus}
          /> */}

          {!viewStatus ? <Button label={editStatus ? "Edit User" : "Create User"} btnType="btn-primary" boxClasses="mt-5 mb-5" btnStyle="100%" loading={isLoading} /> : null}
        </form>
      </div>
    </Modal>
  );
};

export default SupplierCreateUser;
