import { useEffect } from "react";
import Modal from "components/Modal";
import Textbox from "components/Textbox";
import Button from "components/Button";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import SelectDropdown from "components/SelectDropdown";
import { editLocation, getLocations } from "store/slice/BuyerSlice/LocationSlice/LocationSlice";
import { createProductCategory, createProductData, editProductCategory, editProductData, getMaterialCategories, getMaterials, uploadFile } from "store/slice/BuyerSlice/ProductSlice/ProductSlice";
import { useState } from "react";
import FileUploaderBox from "components/FileUploaderBox";
import { createElement, editElement, getElements } from "store/slice/BuyerSlice/CostElement/CostElementSlice";
import { getAllCompaniesData } from "store/slice/BuyerSlice/CompanyDataSlice/CompanyDataSlice";

type Props = {
  closeModal: () => void;
  singleElement?: any;
};
const CreateOrEditElement = ({ closeModal, singleElement = null }: Props) => {
  const { register, handleSubmit, errors, setValue } = useForm<FormData>({
    mode: "onChange"
  });

  //Redux Store
  const dispatch = useDispatch<AppDispatch>();
  const { elements, isLoading } = useSelector((state: RootState) => state.elements);
  const { companies } = useSelector((state: RootState) => state.companiesData);
  console.log(companies);
  const [files, setFiles] = useState<any>([]);
  const [uploadBool, setUploadBool] = useState(false);
  const checkUpload = () => {
    setUploadBool(!uploadBool);
  };

  type FormData = {
    cost_element_description: string;
    cost_element_number: string;
    company_id: string;
  };

  useEffect(() => {
    if (singleElement !== null) {
      setValue("cost_element_description", singleElement.cost_element_description);
      setValue("cost_element_number", singleElement.cost_element_number);
      setValue("company_id", singleElement.company_id);
    }
  }, [singleElement]);

  const onFormSubmit = (vals: FormData) => {
    if (singleElement) {
      const payload = { ...vals, id: singleElement.id };
      dispatch(
        editElement(payload, () => {
          closeModal();

          dispatch(getElements(1, 10));
        })
      );
      return;
    }

    dispatch(
      createElement(vals, () => {
        closeModal();
        dispatch(getElements(1, 10));
      })
    );
  };
  const handleFile = (e, field_name: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(e);
    reader.onloadend = () => {
      // use a regex to remove data url part
      let read: any = reader.result;
      const payload = {
        base64_data: read.replace("data:", "").replace(/^.+,/, ""),
        file_extension: e.name.split(".").pop()
      };
      dispatch(uploadFile(payload));

      setFiles(payload);
    };
  };
  //MaterialCategory List
  const elementsOptions =
    elements &&
    elements.data.map((_data: { id: string; material_category_description: string }) => {
      return {
        value: _data?.id,
        optionName: _data?.material_category_description
      };
    });
  useEffect(() => {
    dispatch(getAllCompaniesData(1, 1000));
  }, []);

  const categoryType = [
    {
      value: "Service",
      optionName: "Service"
    },
    {
      value: "Material",
      optionName: "Material"
    }
  ];

  //Getting Companies List
  const companiesOptions =
    companies &&
    companies?.data?.map((_data: { id: string; company_description: string }) => {
      return {
        value: _data?.id,
        optionName: _data?.company_description
      };
    });
  console.log(companiesOptions, "lolllllllllllllllllllllllllllll");
  return (
    <Modal modalText={singleElement === null ? "Create" : "Edit"} onClose={closeModal} modallength="modal-length" isUpload={true} checkUpload={checkUpload} uploadBool={uploadBool}>
      {uploadBool ? (
        <div id="buyer-create-location">
          <p className="">Download and fill the Product Category template. adhere to file structure before uploading the Categoroes</p>
          <form className="mt-2">
            <p className="template">xxxx Teemplate sourcing team</p>

            <FileUploaderBox
              fileName=""
              boxClasses="mt-3 mb-5"
              handleFile={handleFile}
              acceptFiles={".jpg, .jpeg, .png"}
              customRef={register({ required: true })}
              error={errors["logo"] && "This field is required"}
            />

            <Button label={singleElement === null ? "Create Category" : "Edit Category"} btnType="btn-primary" boxClasses="mt-4 mb-5" btnStyle="100%" loading={isLoading} />
          </form>
        </div>
      ) : (
        <div id="buyer-create-location">
          <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
            <Textbox
              type="text"
              name="cost_element_description"
              label="Description"
              placeholder="Description"
              error={errors.cost_element_description && "This field is required"}
              customRef={register({
                required: true
              })}
            />
            <Textbox
              type="number"
              label="Element Number"
              name="cost_element_number"
              placeholder="Element Number"
              boxClasses="mt-3"
              error={errors.cost_element_number && "This field is required"}
              customRef={register({ required: true })}
            />

            <SelectDropdown
              boxClasses="mt-3 mb-4"
              name="company_id"
              label="Company"
              placeholder="Company"
              options={companiesOptions}
              customRef={register({ required: true })}
              error={errors.company_id && "This field is required"}
            />

            <Button label={singleElement === null ? "Create" : "Edit"} btnType="btn-primary" boxClasses="mt-4 mb-5" btnStyle="100%" loading={isLoading} />
          </form>
        </div>
      )}
    </Modal>
  );
};

export default CreateOrEditElement;
