import Button from "components/Button";
import Document from "assests/icons/sidebar/inventory.svg";
import { Link } from "react-router-dom";
import "./styles.scss";

export const documentsColumns = [
  {
    name: "Invoice ID",
    index: "id",
    id: 1
    // render: () => (
    //     <span>
    //         <img src={Document} className="avatar" alt="icon" />
    //     </span>
    // )
  },
  {
    name: "Confirmation No.",
    index: "invoice_number",
    id: 2
  },
  {
    name: "Description",
    index: "description",
    id: 3
  },
  {
    name: "Amount",
    index: "invoice_amount",
    id: 5
  },
  {
    name: "Currency",
    index: "currency",
    id: 4
  },
  // {
  //     name: "Status",
  //     index: "invoice_status",
  //     id: 6
  // },
  {
    name: "Status",
    id: 6,
    render: (item) => (
      <div>
        {/* {item.invoice_status === "Posted" ? (
          <button
            type="button"
            style={{
              padding: "8px 16px",
              background: "rgba(0, 70, 176, 0.15)",
              borderRadius: "10px",
              color: "rgba(47, 128, 237, 1)"
            }}
          >
            {item.invoice_status}
          </button>
        ) : ( */}
          <button
            type="button"
            // style={{
            //   padding: "8px 16px",
            //   background: item.invoice_status !== "Approved" ? "rgba(245, 150, 37, 0.15)" : "rgba(0, 176, 7, 0.15)",
            //   borderRadius: "10px",
            //   color: item.invoice_status !== "Approved" ? "rgba(245, 150, 37, 1)" : "#387C57"
            // }}
            className={`status ${item.btnClass}`}
          >
            {item.invoice_status}
          </button>
        {/* )} */}
      </div>
    )
  }
  // {
  //     name: "Action",
  //     id: 7,
  //     render: (item) => (
  //         <Link to={`/supplier/invoice/${item.id}`}>
  //             <Button
  //                 label="View"
  //                 btnType="btn-secondary"
  //                 boxClasses="button-container"
  //                 xtraClass="button-position"
  //             />
  //         </Link>
  //     )
  // }
];

export const documents = [
  {
    doc_no: "45000008910",
    description: "Apple Iphone 12 Max, 64GB",
    quantity: "18",
    amount: "N4,800,000",
    status: "Delivered",
    id: 1
  },
  {
    doc_no: "45000008233",
    description: "In-house software development",
    quantity: "1",
    amount: "N34,120,000",
    status: "In Progress",
    id: 2
  },
  {
    doc_no: "45000008738",
    description: "Computer Sales",
    quantity: "20",
    amount: "N5,560,900",
    status: "PO Received",
    id: 3
  },
  {
    doc_no: "45000008910",
    description: "Office Supplies",
    quantity: "5",
    amount: "N78,900",
    status: "Out for delivery",
    id: 4
  }
];
