/* eslint-disable react-hooks/exhaustive-deps */
import StatsCard from "components/StatsCard";
import React, { useEffect, useState } from "react";
import "../styles.scss";
import LocationIcon from "assests/icons/buyer/group-box.svg";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import SearchIcon from "components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { reverseColumn } from "../data";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import useSearch from "hooks/useSearch";
import Pagination from "components/Pagination";
import { toastPopUp } from "utils/utils";
import moment from "moment";
import { getReverse } from "store/slice/BuyerSlice/ReverseSlice/reverseSlice";

const ReversePage = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  //Redux
  const dispatch = useDispatch<AppDispatch>();
  const { reverse, loading } = useSelector((state: RootState) => state.reverse);
  console.log(reverse);
  //Hook
  const { search, inputRef, handleChange, searched } = useSearch(getReverse, perPage);

  // get material categories
  useEffect(() => {
    dispatch(getReverse(page, perPage));
  }, []);

  useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  //Show Number for PerPage Request
  const handleNewPage = (pageNo: number, page: number = 1) => {
    setPerPage(pageNo);
    setPage(1);
    dispatch(getReverse(page, pageNo, search));
  };

  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, perPage: number) => {
      setPage(page);
      dispatch(getReverse(page, perPage, search));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, perPage: number) => {
      setPage(pageNo);
      dispatch(getReverse(pageNo, perPage, search));
    },
    [search]
  );

  const reverseList =
    reverse &&
    reverse?.data?.map((item) => {
      return {
        record_code: item.record_code,
        name: `${item.lastname} ${item.othernames}`,
        activity: item.activity,
        date: moment(item.created_at).format("l")
      };
    });

  return (
    <div id="settings-material-data">
      <div className="container mt-3">
        <div className="row mt-3 mb-5">
          <StatsCard statsNumber={reverse?.count ?? 0} text="Reversals" classes="stats-icon" image={LocationIcon} iconBackgroundColor="rgba(45, 156, 219, 0.1)" />
        </div>

        <div className="d-flex  mb-3">
          <SearchIcon placeholder="Search" boxClasses="" textRef={inputRef} value={search} onChange={handleChange} />
          <Dropdown perPage={perPage} action={handleNewPage} />
        </div>
        <DataTable columns={reverseColumn} data={reverseList} loading={loading} />

        <Pagination page={page} lastPage={reverse?.pagination?.pageCount} paginate={paginate} total={reverse?.pagination?.total} handlePageChange={handlePageChange} perPage={perPage} />
      </div>
    </div>
  );
};

export default ReversePage;
