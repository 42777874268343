import React from "react";
import RegistrationLayout from "../RegistrationLayout";
import { useForm } from "react-hook-form";
import Button from "components/Button";
import { useHistory, useLocation, useParams } from "react-router-dom";
import arrowBack from "assests/icons/arrow-back-circle.svg";
import FileUploaderBox from "components/FileUploaderBox";
import { useState, useEffect, useCallback, useRef } from "react";
import { AppDispatch, RootState } from "store/store";
import { useDispatch, useSelector } from "react-redux";
import { uploadFile, vendorOnBoardSubmission } from "store/slice/SupplierSlices/RegistrationSlice/RegistrationSlice";

type Props = {};
const ComplianceInformation: React.FunctionComponent<Props> = () => {
  //State
  const [modal, setModal] = useState<boolean>(false);
  const [files, setFiles] = useState<any>([]);

  //Redux Hook
  const { complaince_data_fields, onboarding_data, isLoading } = useSelector((state: RootState) => state.vendorRegistration);

  //Helpers
  let history = useHistory();
  let location = useLocation();
  let { id }: any = useParams();
  const { register, handleSubmit, errors, setValue } = useForm<FormData>({ mode: "onBlur" });

  //
  const dispatch = useDispatch<AppDispatch>();

  type FormData = {
    farmer_id: string;
    household: string;
    first_name: string;
    second_name: string;
    phone: string;
    gender: string;
    state: string;
    lga: string;
    community: string;
  };

  useEffect(() => {
    // if (location.state) {
    //   const { userID }: any = location.state;
    // } else {
    //   history.push("/supplier/registration/contact-information");
    // }
    // if (id != onboarding_data?.vendor_id) {
    //   history.push("/supplier/registration/contact-information");
    // }
  }, []);

  const onSubmit = (vals) => {
    const legal_data = localStorage.getItem("legal_data");
    const saved_bankDetails = JSON.parse(localStorage.getItem("bank_data") ?? "");
    let legalfiles: any = null;
    // let bankfiles: any = null;
    if (legal_data) {
      legalfiles = Object.entries(JSON.parse(legal_data)).map(([key, value]) => {
        return {
          field_name: key,
          value: value
        };
      });
    }

    const { vendor_fields, ...rest } = onboarding_data;
    const payload = {
      ...rest,
      ...saved_bankDetails,
      meta: [...legalfiles, ...files]
    };

    console.log(payload);

    dispatch(
      vendorOnBoardSubmission(payload, () => {
        history.push("/supplier/login");
        // clearing stored data
        let keysToRemove = ["bank_data", "legal_data"];
        keysToRemove.forEach((k) => localStorage.removeItem(k));
      })
    );
  };

  const handleFile = (e, field_name: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(e);
    reader.onloadend = () => {
      // use a regex to remove data url part
      let read: any = reader.result;
      const payload = {
        field_name,
        base64_data: read.replace("data:", "").replace(/^.+,/, ""),
        file_extension: e.name.split(".").pop(),
        vendor_id: id,
        onboard_code: onboarding_data?.onboard_code
      };

      /**NEW CONCEPT */
      dispatch(uploadFile(payload)).then((data) => {
        console.log(data);
      });

      /**PREVIOUS CONCEPT */

      // let filesome = [...files, payload];
      // const filteredFiles = filesome.reduce((acc, current) => {
      //   let x = acc.find((item: { field_name: string }) => item.field_name === current.field_name);

      //   if (!x) {
      //     return acc.concat([current]);
      //   }
      //   if (x) {
      //     //Removing and replacing the document if it already exist in the array[] of files
      //     const xy = acc.filter((item) => item.field_name !== current.field_name).concat([current]);
      //     //ressigning
      //     //   Object.assign(x, current);
      //     return xy;
      //   }
      // }, []);
      // setFiles(filteredFiles);
    };
  };

  return (
    <>
      <RegistrationLayout>
        <div className="supplier-contact-information">
          <h4 className="text-center">Supplier Registration</h4>

          <div className="form-container mt-3 pb-5">
            <h4 className="temp_no">Temp ID : {onboarding_data?.onboard_code}</h4>

            <h6 className="form-header mt-1">Compliance Information</h6>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-grid">
                {complaince_data_fields
                  ? complaince_data_fields.map((field, i) => {
                      return (
                        <FileUploaderBox
                          key={i}
                          label={field.field_label}
                          customRef={register({
                            required: true
                          })}
                          handleFile={handleFile}
                          boxClasses="mt-3"
                          fileName={field.field_name}
                          error={errors[field.field_name] && "This field is required"}
                        />
                      );
                    })
                  : null}
              </div>
              <div className="d-flex justify-content-center align-items-center mt-5">
                {/* <Button
                                    type="button"
                                    label="Save Draft"
                                    btnType="btn-skeleton"
                                    xtraClass="w-100"
                                    boxClasses="w-7 mr-3"
                                /> */}
                <Button
                  type="button"
                  label="Back"
                  btnType="btn-skeleton"
                  xtraClass="w-100 d-flex justify-content-around align-items-center py-2"
                  boxClasses="w-7 mr-3"
                  onClick={() => history.goBack()}
                  icon={arrowBack}
                />
                <Button type="submit" label="Save" btnType="btn-temporary" xtraClass="w-100" boxClasses="w-7" loading={isLoading} />
              </div>
            </form>
          </div>
        </div>
      </RegistrationLayout>
    </>
  );
};

export default ComplianceInformation;
