import DropdownMenu from "components/DropdownMenu";
import "./styles.scss";



export const WorkflowColumn = [
    {
        name: "Step",
        index: "step",
        id: 1
    },
    {
        name: "User ID",
        index: "user_id",
        id: 2
    },
    {
        name: "User name",
        index: "user_name",
        id: 3
    },
    {
        name: "Step Description",
        index: "step_description",
        id: 4
    },
    {
        name: "Actions",
        id: 5,
        render: ({ deleteWork, edit }) => {
            const settings = [
                {
                    name: "Edit",
                    style: "activate_tag",
                    select: edit
                },
                {
                    name: "Delete",
                    style: "delete_tag",
                    select: deleteWork
                }
            ];
            return (
                <span style={{ position: "relative" }}>
                    <DropdownMenu options={settings} boxClasses="dropdown-style">
                        <p style={{ fontSize: "2rem" }} className="cursor-pointer">
                            ...
                        </p>
                    </DropdownMenu>
                </span>
            );
        }
    }
];
