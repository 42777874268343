/* eslint-disable react-hooks/exhaustive-deps */
import StatsCard from "components/StatsCard";
import React, { useState } from "react";
import "./styles.scss";
import workflow from "assests/icons/buyer/workflow.svg";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import SearchIcon from "components/SearchBar";
import Dropdown from "components/Dropdown";
import { AppDispatch, RootState } from "store/store";
import DataTable from "components/DataTable";
import {  WorkflowColumn } from "./data";
import CreateWorkFlow from "components/ModalBuyer/CreateWorkFlow";
import { useDispatch, useSelector } from "react-redux";
import useSearch from "hooks/useSearch";
import { useEffect } from "react";
import { toastPopUp } from "utils/utils";
import Pagination from "components/Pagination";
import { deleteApprovalStep, getApprovalStep } from "store/slice/BuyerSlice/ApprovalStepSlice/ApprovalStepSlice";
import { useParams } from "react-router-dom";
import CreateWorkFlowStep from "components/ModalBuyer/CreateWorkFlowStep";
import { checkPermission } from "utils/utils";

const ApprovalWorkFlowSteps = () => {

    //State
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [singleWorkFlowStep, setSingleWorkFlowStep] = useState<any>(null);

    //Redux
    const dispatch = useDispatch<AppDispatch>();
    const {approvalStep, loading } = useSelector((state: RootState) => state.approvalStep);
    const [modal, setModal] = useState<boolean>(false);
    const closeModal = () => {
        setModal((prev) => !prev);
    };

    const { idWork } = useParams<{idWork?: any}>()

    const { role_access } = useSelector((state: RootState) => state.user?.user);


    

    //Hook
    // const { search, inputRef, handleChange, searched } = useSearch(getApprovalStep , perPage, idWork);

    //Show Number for PerPage Request
    const handleNewPage = (pageNo: number, page: number = 1) => {
        setPerPage(pageNo);
        setPage(page);
        dispatch(getApprovalStep({page,length:perPage,id:idWork}));
    };

       //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, perPage: number) => {
      setPage(page);
      dispatch(getApprovalStep({page,length:perPage,id:idWork}));
    },
    []
  );

  const paginate = React.useCallback(
    (pageNo: number, perPage: number) => {
      setPage(pageNo);
      dispatch(getApprovalStep({page,length:perPage,id:idWork}));
    },
    []
  );

     // setpage to the first page when a searched executed
    // useEffect(() => {
    //     if (searched) setPage(1);
    // }, [searched]);

    useEffect(() => {
        dispatch(getApprovalStep({page,length:perPage,id:idWork}));
      }, []);

      //Edit CompanyData
      const editWorkFlowStep = (id: string, approving_user_id: string,step_description:string) => {
        setSingleWorkFlowStep({ id, approving_user_id, step_description });
        setModal((prev) => !prev);
      };

    //   deleteCompany

    const deleteWorkStep = (name: string, id: string) => {
        const payload = { approval_workflow_step_id: id };
        toastPopUp(
          () => {
            dispatch(
              deleteApprovalStep(payload, () => {
                dispatch(getApprovalStep({page,length:perPage,id:idWork}));
              })
            );
          },
          "Delete",
          "Cancel",
          "Delete the entity",
          name,
          "red"
        );
      };

    const approvalStepList =
    approvalStep &&
    approvalStep?.data?.map((flow) => {
        
      return {
        step: flow.step,
        user_id: flow.approving_user_id,
        user_name:`${flow.lastname} ${flow.othernames}`,
        step_description:flow.step_description,
  
        edit: () => checkPermission(role_access, "SETUP_INDEX") && editWorkFlowStep(
           flow.id,
           flow.approving_user_id,
           flow.step_description),
        deleteWork: () => checkPermission(role_access, "SETUP_INDEX") && deleteWorkStep(flow.lastname, flow.id)
      };
    });
    


    return (
        <div id="settings-approval">
            {modal && <CreateWorkFlowStep closeModal={closeModal} id={idWork} singleWorkFlowStep={singleWorkFlowStep} />}
            
            <div className="container mt-3">
                <h3 className="page-title">Workflow Step for {window.localStorage.getItem("name")}</h3>
                <div className="row mt-3 mb-5">
                    <StatsCard image={AddIcon} classes="middle-img" centerText="Add Step" onClick={closeModal} />
                </div>

                <div className="d-flex  mb-3">
                {/* <SearchIcon
                    placeholder="Search for step"
                    boxClasses=""
                    textRef={inputRef}
                    value={search}
                    onChange={handleChange}
                /> */}
                  <Dropdown perPage={perPage} action={handleNewPage} />
                </div>
                <DataTable columns={WorkflowColumn} data={approvalStepList} loading={loading} />
                {
                  approvalStep?.count > 10 ? 
                  <Pagination
                    page={page}
                    lastPage={approvalStep?.pagination?.lastPage}
                    paginate={paginate}
                    total={approvalStep?.pagination?.total}
                    handlePageChange={handlePageChange}
                    perPage={perPage}
                />
                  : null
                }
                
              
            </div>
        </div>
    );
};

export default ApprovalWorkFlowSteps;
