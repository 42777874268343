import React, { useEffect } from "react";
import ImageIllustration from "assests/illustrations/Group.png";
import Textbox from "components/Textbox";
import Button from "components/Button";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import "./styles.scss";
import GZILogo from "components/GZILogo";
import { getOnboardingDraft } from "store/slice/SupplierSlices/RegistrationSlice/RegistrationSlice";

type Props = {};

type FormData = {
  temp_code: string;
};
const Activity: React.FC<RouteComponentProps> = (props) => {
  //Redux State and Dispatch
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state: RootState) => state.vendorRegistration);

  //React-hook form
  const { register, handleSubmit, errors } = useForm<FormData>({ mode: "onBlur" });

  const onFormSubmit = (vals: FormData) => {
    dispatch(getOnboardingDraft(vals.temp_code, props.history));
  };

  return (
    <div className="auth-layout container-fluid">
      <div className="row r-100">
        <div className="col-md-3 px-0 display">
          <div className="left-col ">
            <GZILogo />
            <div id="coverAct">
              <div className="circle-background"></div>
              <img src={ImageIllustration} alt="investment" className="bottom-img" />
            </div>
          </div>
        </div>
        <div className="col-md right-col">
          <div className="d-flex flex-column h-100 justify-content-between align-items-center">
            <div className="d-flex flex-column  align-items-center content">
              <h4 className="mb-4">
                <span>Welcome To GZI</span> <span>Supplier Portal</span>
              </h4>
              <p className="font">
                We are taking steps to bridge the GAP between Supply Chain and Our Suppliers, In other to promote trust, enhance our relationship with you by collaborating effectively and ultimately
                drive transparency throughout Our transactions <br /> Welcome aboard!
              </p>
              <div className="cta-buttons my-5">
                <Link to="/supplier/registration/contact-information" className="btn-primary w-100 btn-cta">
                  New Supplier Registration
                </Link>

                <Link to="/supplier/login" className="btn-primary w-100 btn-cta">
                  Already Registered - Sign In
                </Link>
              </div>
              <p className="w-65 mb-2 font">If you have Temp Supplier Registration ID generated from your last visit, Please enter the Temp ID to Continue your Application.</p>
              <form noValidate className="d-flex flex-column  align-items-center w-100" onSubmit={handleSubmit(onFormSubmit)}>
                <Textbox
                  type="text"
                  placeholder="Temp Code"
                  name="temp_code"
                  error={errors.temp_code && errors.temp_code.message}
                  boxClasses="w-60"
                  inputClasses="px-4 py-1"
                  customRef={register({
                    required: "This field is required"
                  })}
                />
                <Button type="submit" label="Continue" btnType="btn-primary" xtraClass="w-100 btn-cta fontBig" boxClasses="mt-3 btn-cta-wrapper" loading={isLoading} />
              </form>
            </div>
            <div className="py-5">
              <p className="">
                Having trouble signing in?{" "}
                <a href="http://supplier-docs.gzi.yaraa.io/support/home" target="_blank" className="link">
                  Contact Support
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Activity);
