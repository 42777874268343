import DropdownMenu from "components/DropdownMenu";
import "./styles.scss";

export const companydatas = [
  {
    code: "0010",
    name: "GZI Nigeria",
    currency: "NGN",
    value: "1000"
  },
  {
    code: "0011",
    name: "GZI Nairobi",
    currency: "USD",
    value: "2000"
  }
];

export const companyColumn = [
  {
    name: "ID",
    index: "id",
    id: 1
  },
  {
    name: "Email",
    index: "email",
    id: 2
  },
  {
    name: "Name",
    index: "name",
    id: 3
  },
  {
    name: "Purchase Order Code",
    index: "purchase_order_code",
    id: 4
  },
  // {
  //   name: "Status",
  //   // index: "status",
  //   id: 5,
  //   render: (item) => (
  //     <button
  //       type="button"
  //       style={{
  //         padding: "8px 16px",
  //         background: item.status !== "Approved" ? "rgba(245, 150, 37, 0.15)" : "rgba(0, 176, 7, 0.15)",
  //         borderRadius: "10px",
  //         color: item.status !== "Approved" ? "rgba(245, 150, 37, 1)" : "#387C57"
  //       }}
  //     >
  //       {item.status}
  //     </button>
  //   )
  // },
  {
    name: "Actions",
    id: 6,
    render: ({ deleteEntity }) => {
      const settings = [
        {
          name: "Delete",
          style: "delete_tag",
          select: deleteEntity
        }
      ];
      return (
        <span style={{ position: "relative" }}>
          <DropdownMenu options={settings} boxClasses="dropdown-style">
            <p style={{ fontSize: "2rem" }} className="cursor-pointer">
              ...
            </p>
          </DropdownMenu>
        </span>
      );
    }
  }
];
