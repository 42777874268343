import DropdownMenu from "components/DropdownMenu";
import "./styles.scss";

export const Workflowdatas = [
    {
        code: "0010",
        name: "Purchase Requisition",
        subProcess: "Stock",
        legal: "steve manga",
        location: "All",
        level: "4",
        type: "steve manga"
    },
    {
        code: "0011",
        name: "Purchase Order",
        subProcess: "Non-Stock",
        legal: "steve manga",
        location: "All",
        level: "2",
        type: "steve manga"
    }
];

export const WorkflowColumn = [
    {
        name: "Workflow ID",
        index: "code",
        id: 1
    },
    {
        name: "Processs",
        index: "name",
        id: 2
    },
    {
        name: "department",
        index: "department",
        id: 3
    },
    {
        name: "Legal Entity",
        index: "legal",
        id: 4
    },
    {
        name: "Location",
        index: "location",
        id: 5
    },
    {
        name: "levels",
        index: "level",
        id: 6
    },
    {
        name: "Actions",
        id: 7,
        render: ({ deleteWork, edit,viewSteps }) => {
            const settings = [

                {
                    name: "View Steps",
                    style: "activate_tag",
                    select: viewSteps
                },
                {
                    name: "Edit",
                    style: "activate_tag",
                    select: edit
                },
                {
                    name: "Delete",
                    style: "delete_tag",
                    select: deleteWork
                }
            ];
            return (
                <span style={{ position: "relative" }}>
                    <DropdownMenu options={settings} boxClasses="dropdown-style">
                        <p style={{ fontSize: "2rem" }} className="cursor-pointer">
                            ...
                        </p>
                    </DropdownMenu>
                </span>
            );
        }
    }
];
