import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { rfpState, Action } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";
import { idText } from "typescript";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const initialState: rfpState = {
  loading: false,
  isLoading: false,
  deleting: false,
  eachLoading: false,
  rfps: null,
  rfpsDownloadList: null,
  eachRfp: null,
  oneRfp: null,
  summary: null,
  analysis: null,
  analysisLoading: false,
  oneLoading: false,
  scoreLoading: false,
  inviteLoading: false,
  publishLoading: false,
  awardLoading: false,
  disqualifyLoading: false,
  summaryLoading: false,
  chartTechnical: null,
  chartCommercial: null,
  chartLoading: false,
  successMessage: "",
  errorMessage: "",
  urlLink: null,
  sourcingDoc: false
};

const rfpDataSlice = createSlice({
  name: "rfpData",
  initialState,
  reducers: {
    getAllrfpDataLoading: (state) => {
      state.loading = true;
    },
    getEachRfpLoading: (state) => {
      state.eachLoading = true;
    },
    getEachRfpSuccess: (state, action: PayloadAction<Action>) => {
      state.eachLoading = false;
      state.eachRfp = action.payload;
    },
    getAllRfpDownload: (state, action:  PayloadAction<Action>) => {
      state.rfpsDownloadList = action.payload;
    },
    getChartLoading: (state) => {
      state.loading = true;
    },
    getChartSuccess: (state, action: PayloadAction<Action>) => {
      state.eachLoading = false;
      state.chartTechnical = action.payload;
    },
    getChartFail: (state) => {
      state.eachLoading = false;
    },
    getAnalysisLoading: (state) => {
      state.loading = true;
    },
    getAnalysisSuccess: (state, action: PayloadAction<Action>) => {
      state.eachLoading = false;
      state.analysis = action.payload;
    },
    getAnalysisFail: (state) => {
      state.eachLoading = false;
    },
    getChartComLoading: (state) => {
      state.loading = true;
    },
    getChartComSuccess: (state, action: PayloadAction<Action>) => {
      state.eachLoading = false;
      state.chartCommercial = action.payload;
    },
    getChartComFail: (state) => {
      state.eachLoading = false;
    },

    getEachRfpFail: (state) => {
      state.eachLoading = false;
    },
    getOneRfpLoading: (state) => {
      state.oneLoading = true;
    },
    getOneRfpSuccess: (state, action: PayloadAction<Action>) => {
      state.oneLoading = false;
      state.oneRfp = action.payload;
    },
    getOneRfpFail: (state) => {
      state.oneLoading = false;
    },
    getAllrfpDataSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.rfps = action.payload;
    },
    getAllrfpDataFail: (state) => {
      state.loading = false;
    },
    scoreRfpLoading: (state) => {
      state.scoreLoading = true;
    },
    scoreRfpSuccess: (state, action: PayloadAction<Action>) => {
      state.scoreLoading = false;
      state.rfps = action.payload;
    },
    scoreRfpFail: (state, action: PayloadAction<string>) => {
      state.scoreLoading = false;
      state.errorMessage = action.payload;
    },
    inviteLoading: (state) => {
      state.inviteLoading = true;
    },
    inviteSuccess: (state, action: PayloadAction<Action>) => {
      state.inviteLoading = false;
    },
    inviteFail: (state, action: PayloadAction<string>) => {
      state.inviteLoading = false;
      state.errorMessage = action.payload;
    },

    awardLoading: (state) => {
      state.awardLoading = true;
    },
    awardSuccess: (state, action: PayloadAction<Action>) => {
      state.awardLoading = false;
    },
    awardFail: (state, action: PayloadAction<string>) => {
      state.awardLoading = false;
      state.errorMessage = action.payload;
    },
    publishLoading: (state) => {
      state.publishLoading = true;
    },
    publishSuccess: (state, action: PayloadAction<Action>) => {
      state.publishLoading = false;
    },
    publishFail: (state, action: PayloadAction<string>) => {
      state.publishLoading = false;
      state.errorMessage = action.payload;
    },
    disqualifyLoading: (state) => {
      state.disqualifyLoading = true;
    },
    disqualifySuccess: (state, action: PayloadAction<Action>) => {
      state.disqualifyLoading = false;
    },
    disqualifyFail: (state, action: PayloadAction<string>) => {
      state.disqualifyLoading = false;
      state.errorMessage = action.payload;
    },
    summaryLoading: (state) => {
      state.summaryLoading = true;
    },
    summarySuccess: (state, action: PayloadAction<Action>) => {
      state.summaryLoading = false;
      state.summary = action.payload;
    },
    summaryFail: (state) => {
      state.summaryLoading = false;
    },
    uploadingImageRequest: (state) => {
      state.isLoading = true;
    },
    uploadingImageSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.urlLink = action.payload;
    },
    uploadingImageFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
    },
    createSourcingDocLoading: (state) => {
      state.isLoading = true;
      state.sourcingDoc = true;
    },
    createSourcingDocSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.sourcingDoc = false;
    },
    createSourcingDocFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    }
  }
});

const { actions, reducer } = rfpDataSlice;
export const {
  getAllrfpDataLoading,
  getAllrfpDataSuccess,
  getAllrfpDataFail,
  getEachRfpLoading,
  getEachRfpSuccess,
  getEachRfpFail,
  getOneRfpLoading,
  getOneRfpSuccess,
  getOneRfpFail,
  scoreRfpLoading,
  scoreRfpSuccess,
  scoreRfpFail,
  inviteLoading,
  inviteSuccess,
  inviteFail,
  awardLoading,
  awardSuccess,
  awardFail,
  publishLoading,
  publishSuccess,
  publishFail,
  disqualifyLoading,
  disqualifySuccess,
  disqualifyFail,
  summaryLoading,
  summarySuccess,
  summaryFail,
  getChartLoading,
  getChartSuccess,
  getChartFail,
  getChartComLoading,
  getChartComSuccess,
  getChartComFail,
  getAnalysisLoading,
  getAnalysisSuccess,
  getAnalysisFail,
  uploadingImageRequest,
  uploadingImageSuccess,
  uploadingImageFail,
  createSourcingDocFail,
  createSourcingDocLoading,
  createSourcingDocSuccess,
  getAllRfpDownload
} = actions;

// upload

export const uploadFileEvaluate =
  (payload): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(uploadingImageRequest());
    const { userToken } = getState().user;
    try {
      const response = await axios.post(`${BASE_URL}/prometheus/upload_file`, payload, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "multipart/form-data"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(uploadingImageSuccess(response.data.s3url));
        toastr.success("", "File Saved, click to upload");
      }
    } catch (error) {
      dispatch(uploadingImageFail(""));
    }
  };

// get all rfps
export const getAllrfpData =
  (
    page?: number,
    length?: number,
    search?: string,
    department_status?: string,
    department_code?: string,
    company_id?: string,
    location_id?: string,
    hod_user_id?: string,
    evaluation?: string,
    negotation_started_date_from?: string,
    negotation_started_date_to?: string,
    rfp_status?: string
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getAllrfpDataLoading());
    try {
      const response = await API.get("/rfps/get", {
        params: {
          page,
          length,
          search,
          department_status,
          department_code,
          company_id,
          location_id,
          hod_user_id,
          evaluation,
          negotation_started_date_from,
          negotation_started_date_to,
          rfp_status
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getAllrfpDataSuccess(response.data));
      }
    } catch (error) {
      dispatch(getAllrfpDataFail());
    }
  };


// get all rfps donwload
export const getAllrfpDataDownload =
(
  page?: number,
  length?: number,
  search?: string,
  department_status?: string,
  department_code?: string,
  company_id?: string,
  location_id?: string,
  hod_user_id?: string,
  evaluation?: string,
  negotation_started_date_from?: string,
  negotation_started_date_to?: string,
  rfp_status?: string
): AppThunk =>
async (dispatch: AppDispatch, getState) => {
  const { userToken } = getState().user;
  try {
    // dispatch(startRequest());
    const response = await API.get("/rfps/get", {
      params: {
        page,
        length: "40000",
        search,
        department_status,
        department_code,
        company_id,
        location_id,
        hod_user_id,
        evaluation,
        negotation_started_date_from,
        negotation_started_date_to,
        rfp_status
      },
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });
    if ([200, 201].includes(response.status)) {
      dispatch(getAllRfpDownload(response.data));
    }
  } catch (error) {
    // dispatch(endRequest());
  }
};

// get each rfp

type getEachRfp = {
  page?: number;
  length?: number;
  id?: any;
  search?: string;
  vendor_id?: any;
  category?: any;
  categoryName?: any;
};

export const getEachRfp =
  ({ page, length, id, search, vendor_id, categoryName }: getEachRfp): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    console.log(categoryName, "nnn");
    dispatch(getEachRfpLoading());
    try {
      const response = await API.get(`/rfp_responses/get/${id}`, {
        params: {
          page,
          length,
          vendor_id,
          search,
          category: categoryName
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getEachRfpSuccess(response.data));
      }
    } catch (error) {
      dispatch(getEachRfpFail());
    }
  };

// getone rfp

// get each rfp

type getOneRfp = {
  page?: number;
  length?: number;
  id?: any;
  search?: string;
  activity_name?: string;
  approval_status?: string;
};

export const getOneRfp =
  ({ page, length, id, search, activity_name, approval_status }: getOneRfp): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getOneRfpLoading());
    try {
      const response = await API.get(`/rfps/get/${id}`, {
        params: {
          page,
          activity_name,
          length,
          search,
          approval_status
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getOneRfpSuccess(response.data));
      }
    } catch (error) {
      dispatch(getOneRfpFail());
    }
  };

export const scoreRfpData =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(scoreRfpLoading());
    try {
      const response = await API.post(`/rfp_responses/assign_score`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(scoreRfpSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      if (error.response) {
        dispatch(scoreRfpFail(error.response.data?.message));
        toastr.error(error.response.data.message);
      } else {
        dispatch(scoreRfpFail("No internet connection"));
        toastr.error("No internet connection");
      }

      setTimeout(() => {
        dispatch(scoreRfpFail(""));
      }, 3000);
    }
  };

export const publish =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(publishLoading());
    try {
      const response = await API.post(`/rfps/complete_technical_evaluation/${data.rfp_id}/${data.vendor_id}`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(publishSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      if (error.response) {
        dispatch(publishFail(error.response.data?.message));
        toastr.error(error.response.data.message);
      } else {
        dispatch(publishFail("No internet connection"));
        toastr.error("No internet connection");
      }

      setTimeout(() => {
        dispatch(publishFail(""));
      }, 3000);
    }
  };

export const award =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(awardLoading());
    try {
      const response = await API.post(`/rfps/award`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(awardSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      if (error.response) {
        dispatch(awardFail(error.response.data?.message));
        toastr.error(error.response.data.message);
      } else {
        dispatch(awardFail("No internet connection"));
        toastr.error("No internet connection");
      }
      setTimeout(() => {
        dispatch(inviteFail(""));
      }, 3000);
    }
  };

export const disqualify =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(disqualifyLoading());
    try {
      const response = await API.post(`/rfps/disqualify`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(disqualifySuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      if (error.response) {
        dispatch(disqualifyFail(error.response.data?.message));
        toastr.error(error.response.data.message);
      } else {
        dispatch(disqualifyFail("No Internet Connection"));
        toastr.error("No Internet Connection");
      }
      setTimeout(() => {
        dispatch(inviteFail(""));
      }, 3000);
    }
  };

export const getSummary =
  (rfp_status?: string, evaluation?: string, requisition_id?: string, rfp_id?: string, date_from?: string, date_to?: string, department_id?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(summaryLoading());
    try {
      const response = await API.get(`/rfps/summary`, {
        params: {
          evaluation,
          rfp_status,
          rfp_id,
          requisition_id,
          date_from,
          date_to,
          department_id
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(summarySuccess(response.data));
      }
    } catch (error) {
      dispatch(summaryFail());
    }
  };

export const invite =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(inviteLoading());
    try {
      const response = await API.post(`/rfps/invite_for_negotiations`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(inviteSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      if (error.response) {
        dispatch(inviteFail(error.response.data?.message));
        toastr.error(error.response.data.message);
      } else {
        dispatch(inviteFail("No internet connection"));
        toastr.error("No internet connection");
      }

      setTimeout(() => {
        dispatch(inviteFail(""));
      }, 3000);
    }
  };

type getChatType = {
  id?: any;
};

export const getChart =
  ({ id }: getChatType): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getChartLoading());
    try {
      const response = await API.get(`/rfps/get_vendor_scores/${id}/Technical`, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getChartSuccess(response.data));
      }
    } catch (error) {
      dispatch(getChartFail());
    }
  };

export const getChartCom =
  ({ id }: getChatType): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getChartComLoading());
    try {
      const response = await API.get(`/rfps/get_vendor_scores/${id}/Commercial`, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getChartComSuccess(response.data));
      }
    } catch (error) {
      dispatch(getChartComFail());
    }
  };

export const getAnalysis =
  ({ id }: getChatType): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getAnalysisLoading());
    try {
      const response = await API.get(`/rfps/get_analysis/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getAnalysisSuccess(response.data));
      }
    } catch (error) {
      dispatch(getAnalysisFail());
    }
  };

export const createSourcingDoc =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(createSourcingDocLoading());
    try {
      const response = await API.post(`/rfps/update_rfp_report_url`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(createSourcingDocSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      if (error.response) {
        dispatch(createSourcingDocFail(error.response.data?.message));
        toastr.error(error.response.data.message);
      } else {
        dispatch(createSourcingDocFail("No internet connection"));
        toastr.error("No internet connection");
      }

      setTimeout(() => {
        dispatch(createSourcingDocFail(""));
      }, 3000);
    }
  };

// edit company data
// export const editRfpData =
//   (data: any, cb?: () => void): AppThunk =>
//   async (dispatch: AppDispatch, getState) => {
//     const { userToken } = getState().user;
//     dispatch(editRfpLoading());
//     try {
//       const response = await API.post(`/rfps/edit`, data, {
//         headers: {
//           Authorization: `Bearer ${userToken}`
//         }
//       });
//       if ([200, 201].includes(response.status)) {
//         dispatch(editRfpSuccess(response.data.message));
//         toastr.success("", response.data.message);
//         cb && cb();
//       }
//     } catch (error) {
//       dispatch(editRfpFail(error.response.data.message));
//       toastr.error(error.response.data.message);
//       setTimeout(() => {
//         dispatch(editRfpFail(""));
//       }, 3000);
//     }
//   };

// delete company data
// export const deleteRfpData =
//   (data?: any, cb?: () => void): AppThunk =>
//   async (dispatch: AppDispatch, getState) => {
//     const { userToken } = getState().user;
//     dispatch(deleteRfpLoading());
//     try {
//       const response = await API.post(`/rfps/delete`, data, {
//         headers: {
//           Authorization: `Bearer ${userToken}`
//         }
//       });
//       if ([200, 201].includes(response.status)) {
//         dispatch(deleteRfpSuccess(response.data.message));
//         toastr.success("", response.data.message);
//         cb && cb();
//       }
//     } catch (error) {
//       dispatch(deleteRfpFail(error.response.data.message));
//       toastr.error(error.response.data.message);
//       setTimeout(() => {
//         dispatch(deleteRfpFail(""));
//       }, 3000);
//     }
//   };

export default reducer;
