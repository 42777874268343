import { combineReducers } from "@reduxjs/toolkit";
import userMgtReducer from "store/slice/UserMgtSlice/UserMgtSlice";
import dashboardReducer from "store/slice/DashboardSlice/DashboardSlice";
import dispatchReducer from "store/slice/DispatchSlice/DispatchSlice";
import accountReducer from "store/slice/AccountSlice/accountSlice";
import collectionPointReducer from "store/slice/CollectionPointSlice/CollectionPointSlice";
import stateReducer from "store/slice/StateSlice/StateSlice";
import uiReducer from "store/slice/UISlice/UISlice";
import { reducer as toastrReducer } from "react-redux-toastr";
import vendorAuthReducer from "store/slice/SupplierSlices/AuthSlice/AuthSlice";
import buyerAuthReducer from "store/slice/BuyerSlice/AuthSlice/AuthSlice";
import userReducer from "store/slice/UserSlice/UserSlice";
import SupplieruserReducer from "store/slice/SupplierSlices/SupplierUserSlice/SupplierUserSlice";
import vendorRegistration from "store/slice/SupplierSlices/RegistrationSlice/RegistrationSlice";
import businessCategoryReducer from "store/slice/BusinessCategorySlice/BusinessCategorySlice";
import countryReducer from "store/slice/CountriesSlice/CountriesSlice";
import uomReducer from "store/slice/UomSlice/Uom";
import statesReducer from "store/slice/CountryStatesSlice/StateSlice";
import cityReducer from "store/slice/CitySlice/CitySlice";
import bankReducer from "store/slice/BankSlice/BankSlice";
import companyDataReducer from "store/slice/BuyerSlice/CompanyDataSlice/CompanyDataSlice";
import companiesReducer from "store/slice/CompaniesSlice/CompaniesSlice";
import locationReducer from "store/slice/BuyerSlice/LocationSlice/LocationSlice";
import departmentReducer from "store/slice/BuyerSlice/DepartmentSlice/DepartmentSlice";
import productReducer from "store/slice/BuyerSlice/ProductSlice/ProductSlice";
import approvalReducer from "store/slice/BuyerSlice/ApprovalSlice/ApprovalSlice";
import approvalStepReducer from "store/slice/BuyerSlice/ApprovalStepSlice/ApprovalStepSlice";
import fieldReducer from "store/slice/BuyerSlice/onBoardSlice/onBoardSlice";
import vendorDetailsReducer from "store/slice/BuyerSlice/VendorSlice/VendorSlice";
import rolesReducer from "store/slice/Roleslice/RoleSlice";
import requisitionReducer from "store/slice/RequisitionSlice/RequisitionSlice";
import bidsReducer from "store/slice/SupplierSlices/BidSlice/BidsSlice";
import waybillReducer from "store/slice/SupplierSlices/Waybill/WayBillSlice";
import currencyReducer from "store/slice/CurrencySlice/CurrencySlice";
import sourcingReducer from "store/slice/SourcingSlice/SourcingSlice";
import rfpDataReducer from "store/slice/BuyerSlice/EvaluationSlice/EvaluationSlice";
import teamReducer from "store/slice/BuyerSlice/TeamSlice/TeamSlice";
import businessReducer from "store/slice/BuyerSlice/BusinessSlice/BusinessSlice";
import summariesReducer from "store/slice/SummarySlice/SummarySlice";
import documentReducer from "store/slice/BuyerSlice/DocumentSlice/DocumentSlice";
import goodsReducer from "store/slice/BuyerSlice/GoodsSlice/GoodsSlice";
import KpiSetupReducer from "store/slice/BuyerSlice/KpiSetupSlice/KpiSetupSlice";
import InvoiceReducer from "store/slice/BuyerSlice/Invoice/InvoiceSlice";
import reportReducer from "store/slice/BuyerSlice/ReportSlice/RepotSlice";
import purchaseGroupReducer from "store/slice/BuyerSlice/PurchaseGroup/PurchaseGroup";
import budgetReducer from "store/slice/BuyerSlice/Budget/BudgetSlice";
import costElementReducer from "store/slice/CostElementSlice/CostElementSlice";
import elementReducer from "store/slice/BuyerSlice/CostElement/CostElementSlice";
import taxReducer from "store/slice/BuyerSlice/TaxSlice/TaxSlice";
import notifySetupReducer from "store/slice/BuyerSlice/NotifySetup/NotifySetupSlice";
import userNotifyReducer from "store/slice/NotificationSlice/NotificationSlice";
import rateReducer from "store/slice/BuyerSlice/VendorRate/VendorRateSlice";
import taxSupplyReducer from "store/slice/SupplierSlices/TaxSlice/TaxSlice";
import postedInvoice from "store/slice/BuyerSlice/PostedInvoiceSlice/PostedInvoiceSlice";
import statement from "store/slice/BuyerSlice/VendorStatementSlice/VendorStatementSlice";
import statement_supplier from "store/slice/SupplierSlices/SupplierStatementSlice/SupplierStatementSlice";
import exchangeReducer from "../slice/BuyerSlice/ExhangeRate/ExchangeRateSlice";
import reverseReducer from "../slice/BuyerSlice/ReverseSlice/reverseSlice";
import refreshReducer from "../slice/BuyerSlice/RefreshSlice/refreshSlice";
const rootReducer = combineReducers({
  //GZI Supplier/Vendor Reducers
  dashboard: dashboardReducer,
  dispatchReducer,
  points: collectionPointReducer,
  account: accountReducer,
  stateReducer: stateReducer,
  vendorAuth: vendorAuthReducer,
  supplierUser: SupplieruserReducer,
  vendorRegistration: vendorRegistration,
  roles: rolesReducer,
  bids: bidsReducer,
  waybill: waybillReducer,
  //Shared Reducers
  ui: uiReducer,
  toastr: toastrReducer,
  businessCategory: businessCategoryReducer,
  country: countryReducer,
  uom: uomReducer,
  currency: currencyReducer,
  states: statesReducer,
  city: cityReducer,
  bank: bankReducer,
  companies: companiesReducer,
  user: userReducer,
  summaries: summariesReducer,
  business: businessReducer,
  costElement: costElementReducer,

  //GZI Buyer Reducers
  userMgt: userMgtReducer,
  buyerAuth: buyerAuthReducer,
  companiesData: companyDataReducer,
  reverse: reverseReducer,
  refresh: refreshReducer,
  location: locationReducer,
  department: departmentReducer,
  product: productReducer,
  approvals: approvalReducer,
  approvalStep: approvalStepReducer,
  field: fieldReducer,
  vendorDetails: vendorDetailsReducer,
  requisition: requisitionReducer,
  sourcing: sourcingReducer,
  team: teamReducer,
  rfpData: rfpDataReducer,
  document: documentReducer,
  kpisetup: KpiSetupReducer,
  invoice: InvoiceReducer,
  goods: goodsReducer,
  report: reportReducer,
  purchaseGroup: purchaseGroupReducer,
  budget: budgetReducer,
  elements: elementReducer,
  exchange_rate: exchangeReducer,
  tax: taxReducer,
  taxSupply: taxSupplyReducer,
  notifySetup: notifySetupReducer,
  userNotify: userNotifyReducer,
  rate: rateReducer,
  postedInvoice: postedInvoice,
  statement: statement,
  statement_supplier: statement_supplier
});
export default rootReducer;
