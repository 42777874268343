import React, { useEffect, useState } from "react";
import "./styles.scss";
import Textbox from "components/Textbox";
import { useForm } from "react-hook-form";
import Button from "components/Button";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppDispatch, RootState } from "store/store";

type Props = {
  vendorData?: any;
};
const ContactInformation: React.FunctionComponent<Props> = ({ vendorData = {} }) => {
  //State
  const [modal, setModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState({});

  //Helpers
  const { register, handleSubmit, errors, setValue, watch } = useForm<FormData>({ mode: "onBlur" });
  const values = watch();

  //Redux Hooks
  const dispatch = useDispatch<AppDispatch>();
  const { singleVendor } = useSelector((state: RootState) => state.vendorDetails);
  const { allBusinessCategory } = useSelector((state: RootState) => state.businessCategory);
  const { countries } = useSelector((state: RootState) => state.country);
  const { states } = useSelector((state: RootState) => state.states);
  const { cities } = useSelector((state: RootState) => state.city);
  let history = useHistory();

  // useEffect(() => {
  //     dispatch(getCompaniesList());
  //     dispatch(getAllBusinessCategory());
  //     dispatch(getAllCountries());
  //     dispatch(getAllStates());
  //     dispatch(getAllCities());
  // }, []);

  //Getting Bussiness Categories
  const businessCategoryOptions =
    allBusinessCategory &&
    allBusinessCategory.map((_data: { id: string; business_category_description: string }) => {
      return {
        value: _data.id,
        optionName: _data.business_category_description
      };
    });

  //Getting Countries
  const countriesOptions =
    countries &&
    countries.map((_data: { country_description: string }) => {
      return {
        value: _data.country_description,
        optionName: _data.country_description
      };
    });

  //Getting States
  const stateOptions = [] as any;
  states &&
    states.forEach((element: { country_description: string; state_name: string }) => {
      if (element.country_description === values.country) {
        stateOptions.push({
          value: element.state_name,
          optionName: element.state_name
        });
      }
    });

  //Getting Cities
  const cityOptions = [] as any;
  cities &&
    cities.forEach((element: { state_name: string; id: string; city_name: string; country_description: string }) => {
      if (element.state_name === values.state && element.country_description === values.country) {
        cityOptions.push({
          value: element.id,
          optionName: element.city_name
        });
      }
    });

  type FormData = {
    othernames: string;
    lastname: string;
    city_name: string;
    phone_number: string;
    email: string;
    vendor_description: string;
    business_category_id: string;
    country: string;
    state: string;
    position: string;
    street_number: string;
    street_name: string;
    country_description: string;
  };

  //------------------------------------------------
  //modal toggler
  //------------------------------------------------
  const closeModal = () => {
    setModal(!modal);
  };

  const onSubmit = (vals: FormData) => {
    setModal(!modal);
    setModalData({ ...vals });
  };

  useEffect(() => {
    if (vendorData && vendorData !== undefined) {
      setValue("othernames", vendorData?.contact_user?.othernames);
      setValue("lastname", vendorData?.contact_user?.lastname);
      setValue("email", vendorData?.email);
      setValue("phone_number", vendorData?.phone_number);
      setValue("position", vendorData?.contact_user?.position);
      setValue("street_number", vendorData?.street_number);
      setValue("street_name", vendorData?.street_name);
      setValue("city_name", vendorData?.city_name);
      setValue("country_description", vendorData?.country_description);
    }
  }, [vendorData]);

  return (
    <>
      {vendorData && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <Textbox
                label="FirstName"
                name="othernames"
                customRef={register({ required: true })}
                placeholder="FirstName"
                boxClasses="mt-4"
                disabled={true}
              />
              <Textbox
                label="LastNames"
                name="lastname"
                customRef={register({ required: true })}
                placeholder="LastName"
                boxClasses="mt-4"
                disabled={true}
              />
              <Textbox
                label="Email Address"
                name="email"
                type="email"
                customRef={register({
                  required: true
                })}
                placeholder="Email Address"
                boxClasses="mt-4"
                error={errors.email && "This field is required"}
                disabled={true}
              />
              <Textbox
                label="Phone Number"
                type="text"
                name="phone_number"
                customRef={register({
                  required: true
                })}
                placeholder="Phone Number"
                boxClasses="mt-4"
                disabled={true}
              />
              <Textbox
                label="Position In Company"
                name="position"
                customRef={register({
                  required: true
                })}
                placeholder="Position In Company"
                boxClasses="mt-3"
                error={errors.position && "This field is required"}
                disabled={true}
              />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <Textbox
                label="House Number"
                name="street_number"
                customRef={register({
                  required: true
                })}
                type="number"
                placeholder="House Number"
                boxClasses="mt-4"
                error={errors.street_number && "This field is required"}
                // value={vendorData?.street_number}
                // onChange={() => console.log()}
                disabled={true}
              />
              <Textbox
                label="Street"
                name="street_name"
                customRef={register({
                  required: true
                })}
                placeholder="Street"
                boxClasses="mt-4"
                error={errors.street_name && "This field is required"}
                disabled={true}
              />
              <Textbox
                label="City"
                name="city_name"
                customRef={register({
                  required: true
                })}
                placeholder="Street"
                boxClasses="mt-4"
                error={errors.street_name && "This field is required"}
                disabled={true}
              />
              <Textbox
                label="Country"
                name="country_description"
                customRef={register({
                  required: true
                })}
                placeholder="Street"
                boxClasses="mt-4"
                error={errors.country_description && "This field is required"}
                disabled={true}
              />
            </div>
          </div>
          {/* {showButtons && (
            <div className="d-flex justify-content-center align-items-center mt-5">
              <Button type="button" label="Save Draft" btnType="btn-skeleton" xtraClass="w-100" boxClasses="w-7 mr-3" disabled={true} />
              <Button type="submit" label="Next" btnType="btn-temporary" xtraClass="w-100" boxClasses="w-7" />
            </div>
          )} */}
        </form>
      )}
    </>
  );
};

export default ContactInformation;
