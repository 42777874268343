import { toastr } from "react-redux-toastr";
import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import API from "utils/httpsRequest";
import { CompaniesState, Action } from "./interface";

const initialState: CompaniesState = {
  isLoading: false,
  companies: null
};

const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    getCompaniesLoading: (state) => {
      state.isLoading = true;
    },
    getCompaniesSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.companies = action.payload;
    },
    getCompaniesFail: (state) => {
      state.isLoading = false;
    }
  }
});

const { actions, reducer } = companiesSlice;

export const { getCompaniesLoading, getCompaniesSuccess, getCompaniesFail } = actions;

export const getCompaniesEntityList =
  (search?: string, page?: number, length?: number): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getCompaniesLoading());
    try {
      const response = await API.get("/companies/get", {
        params: {
          search,
          page,
          length
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getCompaniesSuccess(response.data.data));
      }
    } catch (error) {
      dispatch(getCompaniesFail());
    }
  };

export default reducer;
