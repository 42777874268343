import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import { useTheme } from "@material-ui/styles";

const DoughnutChart = ({ data, name, color = [] as any, radius = ["60%", "80%"] as any }) => {
  const theme = useTheme();
  // const myStyle = chartStyle(height);
  const myChart = useRef(null) as any;
  const options = {
    color,
    // legend: {
    //   show: false,
    //   itemGap: 10,
    //   icon: "circle",
    //   bottom: 0,
    //   textStyle: {
    //     color: "#000",
    //     fontSize: 13,
    //   }
    // },
    // grid,
    // tooltip: {
    //   show: false,
    //   trigger: "item",
    //   formatter: "{a} <br/>{b}: {c} ({d}%)"
    // },
    tooltip: {
      trigger: "item"
    },
    legend: {
      top: "0%",
      left: "center"
    },
    // xAxis: [
    //   {
    //     axisLine: {
    //       show: false
    //     },
    //     splitLine: {
    //       show: false
    //     }
    //   }
    // ],
    // yAxis: [
    //   {
    //     axisLine: {
    //       show: false
    //     },
    //     splitLine: {
    //       show: false
    //     }
    //   }
    // ],
    series: [
      {
        name,
        // type: "pie",
        // radius: radius,
        // avoidLabelOverlap: true,
        // label: {
        //   show: true,
        //   fontSize: "14",
        //   color:'#737A91',
        //   position: "center"
        // },
        // emphasis: {
        //   label: {
        //     // formatter: "{b} \n{c} ({d}%)"
        //     show: true
        //   }
        // },
        // labelLine: {
        //   normal: {
        //     show: true
        //   }
        // },

        type: "pie",
        // radius: ["40%", "70%"],
        radius,
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center"
        },
        emphasis: {
          label: {
            show: true,
            // fontSize: "40",
            // fontWeight: "bold"
            fontSize: "14",
            color: "#737A91"
          }
        },
        // labelLine: {
        //   show: false
        // },
        labelLine: {
          normal: {
            show: true
          }
        },
        data
      }
    ]
  };

  useEffect(() => {
    const chart = echarts.init(myChart.current);
    chart.setOption(options);
  }, [data]);

  return <div ref={myChart} style={{ height: "15rem" }} />;
};

export default DoughnutChart;
