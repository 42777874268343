import ScoreOrView from "components/ModalBuyer/ScoreOrView";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { disqualify, getEachRfp, invite, publish } from "store/slice/BuyerSlice/EvaluationSlice/EvaluationSlice";
import { AppDispatch, RootState } from "store/store";
import { toastr } from "react-redux-toastr";
// import doc from "../../../../assests/images/doc.png";
import "./styles.scss";
import DataTable from "components/DataTable";
import { formatCurrency } from "utils/utils";

const Evaluation_4 = () => {
  const [modals, setModals] = useState(false);
  const [whichModal, setWhichModals] = useState(false);
  const [fieldId, setFieldId] = useState("");
  const [fieldQuestion, setFieldQuestion] = useState("");
  const [order, setOrder] = useState("");
  const [vendorScore, SetVendorScore] = useState(null);

  const closeModal = () => {
    setModals(!modals);
  };
  const dispatch = useDispatch<AppDispatch>();
  const { eachRfp, inviteLoading, disqualifyLoading, publishLoading,eachLoading } = useSelector((state: RootState) => state.rfpData);
  const [totalScore, setTotalScore] = useState(0);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const idResponse = urlParams.get("id");
  const vendor_id = urlParams.get("vendor_id");
  const vendor_name = urlParams.get("vendor_name");
  const categoryName = urlParams.get("category");
  const rfpStatus = urlParams.get("status");
  const rfp_vendor_status = urlParams.get("rfp_vendor_status");
  const publish_status = urlParams.get("publish_status");
  const quote = urlParams.get("quote");
  const currentRfp = eachRfp && eachRfp.data;

  useEffect(() => {
    dispatch(getEachRfp({ id: idResponse, vendor_id: vendor_id, categoryName: categoryName }));
  }, []);
  // Create Invoice
  const ScoreReview = (id, question, order) => {
    if (rfpStatus === "4") {
      toastr.error("Action is not allowed");
    } else {
      setFieldId(id);
      setFieldQuestion(question);
      setOrder(order);
      setWhichModals(true);
      closeModal();
    }
  };

  const inviteHandle = () => {
    if (rfpStatus === "4") {
      toastr.error("Action is not allowed");
    } else {
      const payload = {
        vendor_id,
        rfp_id: idResponse
      };
      dispatch(
        invite(payload, () => {
          dispatch(getEachRfp({ id: idResponse, vendor_id: vendor_id, categoryName: categoryName }));
        })
      );
    }
  };

  const publishHandle = () => {
    if (rfpStatus === "4") {
      toastr.error("Action is not allowed");
    } else {
      const payload = {
        vendor_id,
        rfp_id: idResponse
      };
      dispatch(
        publish(payload, () => {
          dispatch(getEachRfp({ id: idResponse, vendor_id: vendor_id, categoryName: categoryName }));
        })
      );
    }
  };

  const disqualifyHandle = () => {
    if (rfpStatus === "4") {
      toastr.error("Action is not allowed");
    } else {
      const payload = {
        vendor_id,
        rfp_id: idResponse
      };
      dispatch(
        disqualify(payload, () => {
          dispatch(getEachRfp({ id: idResponse, vendor_id: vendor_id, categoryName: categoryName }));
        })
      );
    }
  };

  const ViewReview = (vendor_score, question, order) => {
    SetVendorScore(vendor_score);
    setFieldQuestion(question);
    setOrder(order);
    setWhichModals(false);
    closeModal();
  };

  useEffect(() => {
    calcScore();
  });
  const calcScore = () => {
    let start = 0;
    eachRfp?.data?.[0]?.vendor_responses &&
      eachRfp?.data?.[0]?.vendor_responses.map((item) => {
        if (item.response_score) {
          start += parseInt(item.response_score, 10);
        }
      });

    setTotalScore(start);
  };
console.log(eachRfp?.data?.[0]?.vendor_responses)
  return (
    <div id="evaluation_4">
      <div className="container">
        {modals && (
          <ScoreOrView
            modalText={whichModal}
            order={order}
            question={fieldQuestion}
            vendor_id={currentRfp?.[0] && currentRfp?.[0]?.vendor_id}
            rfp_id={currentRfp?.[0] && currentRfp?.[0]?.rfp_id}
            rfp_field_id={fieldId}
            vendor_score={vendorScore}
            closeModal={closeModal}
            categoryName={categoryName}
          />
        )}
        <header className="header-container">
          {/* QT-{currentRfp?.[0] && currentRfp?.[0]?.rfp_id} */}
          <h3 className="header">
            {quote} - {vendor_name}
          </h3>
          {/* <div className="save-to-draft mt-4">
            <button disabled={rfpStatus === "4"?true:false} type="button" id="upload" onClick={() => {}}>
              Save to Draft
            </button>
          </div>{" "} */}
          <div className="invite-for-negotiations mt-4">
            {categoryName === "Technical" ? (
              <button className="invitebtn" id="upload">
                Invite for Presentation
              </button>
            ) : (
              <button disabled={rfpStatus === "4" ? true : rfp_vendor_status === "3" ? true : false} type="button" className="invitebtn" id="upload" onClick={inviteHandle}>
                {inviteLoading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : `Invite For ${categoryName === "Technical" ? "Presentation" : "Negotiations"}`}
              </button>
            )}
          </div>
          <div className="disqualify mt-4">
            <button disabled={rfpStatus === "4" ? true : rfp_vendor_status === "3" ? true : publish_status === "1" ? true : false} type="button" id="upload" onClick={disqualifyHandle}>
              {disqualifyLoading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Disqualify"}
            </button>
          </div>
          <div className="publish mt-4" onClick={publishHandle}>
            <button
              disabled={rfpStatus === "4" ? true : rfp_vendor_status === "3" ? true : publish_status === "1" ? true : false}
              type="button"
              className={rfpStatus === "4" ? "published publish-btn" : "publish-btn"}
              id="upload"
              onClick={() => {}}
            >
              {publishLoading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Publish"}
            </button>
          </div>
        </header>
        <div className="evaluation mt-5">
          <div className="overall-container">
            <h3 className="">{categoryName} Evaluation</h3>
            <h3>Overall Score : {totalScore}</h3>
          </div>
          <hr />{" "}
          <div className="bid-technical-requirment-page-answers">
            {" "}
            <div className="col-lg-10 col-md-12 pl-0">
              {categoryName === 'Technical' ? (
                <form className="table_form_ev" action="">
                <table className="table_evaluate">
                  <thead>
                    <tr>
                      {" "}
                      <th>Question</th>
                      <th>Answer</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {eachRfp?.data?.[0]?.vendor_responses &&
                      eachRfp?.data?.[0]?.vendor_responses.map((item, i) => {
                        return (
                          <tr>
                            <td>
                              {" "}
                              <label className="question-label" htmlFor="">
                                <span className="rating-header">
                                  Q{i + 1}: {item?.field_label}
                                </span>
                              </label>
                            </td>
                            <td>
                              {item.field_type === "File" ? (
                                <div className="fileType">
                                  <a className="pointer-event text-info" href={item.vendor_response} target="_blank" rel="noopener noreferrer">View File</a>
                                </div>
                              ) : (
                                <p className="textarea">{item?.vendor_response}</p>
                              )}
                            </td>
                            <td>
                              <div className="rating">
                                {item.vendor_score ? (
                                  <button
                                    type="button"
                                    id="upload"
                                    onClick={() => {
                                      ViewReview(item.vendor_score, item.field_label, item.field_order);
                                    }}
                                  >
                                    View
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    id="upload"
                                    onClick={() => {
                                      ScoreReview(item.rfp_field_id, item.field_label, item.field_order);
                                    }}
                                  >
                                    Score
                                  </button>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>

                {/* {eachRfp?.data?.[0]?.vendor_responses &&
                  eachRfp?.data?.[0]?.vendor_responses.map((item) => {
                    return (
                      <div key={item.id} className="question-box">
                        <label className="question-label" htmlFor="">
                          <span className="rating-header">
                            Q{item?.field_order}: {item?.field_label}
                          </span>
                          <div className="rating">
                            {item.vendor_score ? (
                              <button
                                type="button"
                                id="upload"
                                onClick={() => {
                                  ViewReview(item.vendor_score, item.field_label, item.field_order);
                                }}
                              >
                                View
                              </button>
                            ) : (
                              <button
                                type="button"
                                id="upload"
                                onClick={() => {
                                  ScoreReview(item.rfp_field_id, item.field_label, item.field_order);
                                }}
                              >
                                Score
                              </button>
                            )}
                          </div>
                        </label>
                        {item.field_type === "file" ? (
                          <div className="fileType">
                            <a href="#">
                              <img src={doc} alt="docImage" />
                            </a>
                          </div>
                        ) : (
                          <p className="textarea">{item?.vendor_response}</p>
                        )}
                      </div>
                    );
                  })} */}

                {/* <div className="question-box">
                                    <label className="question-label" htmlFor="">
                                        <span className="rating-header"> Q2: How will training be Coordinated</span>
                                        <div className="rating">
                                            <span>1.0</span>
                                            <button type="button" id="upload" onClick={ViewReview}>
                                                View
                                            </button>
                                        </div>
                                    </label>
                                    <p className="textarea">
                                        catching fire. Sometimes you need to take a site offline for routine maintenance
                                        or upgrades. While a no-downtime update is ideal, it isn’t always possible. The
                                        advantage is, with planned
                                    </p>
                                </div>
                                <div className="question-box">
                                    <label className="question-label" htmlFor="">
                                        <span className="rating-header">Q3: Are You CISA Certified </span>
                                        <div className="rating">
                                            <span>2.0</span>
                                            <button type="button" id="upload" onClick={ViewReview}>
                                                View
                                            </button>
                                        </div>
                                    </label>
                                    <div className="d-flex radio-box ">
                                        <div className="">
                                            <input
                                                type="radio"
                                                name="certified"
                                                id="yes"
                                                className="mr-2"
                                                checked
                                                readOnly
                                            />
                                            <label className="radio-box_label" htmlFor="yes">
                                                Yes
                                            </label>
                                        </div>
                                        <div className="">
                                            <input type="radio" name="certified" id="no" className="mr-2" />
                                            <label className="radio-box_label" htmlFor="no">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                 */}
                {/* <div className="question-box"> */}
                {/* <label className="question-label" htmlFor="">
                                        <span className="rating-header">Q4: Technical Proposal</span>
                                        <div className="rating">
                                            <span>4.0</span>
                                            <button type="button" id="upload" onClick={ViewReview}>
                                                View
                                            </button>
                                        </div>
                                    </label>

                                    <p className="textarea">
                                        catching fire. Sometimes you need to take a site offline for routine maintenance
                                        or upgrades. While a no-downtime update is ideal, it isn’t always possible. The
                                        advantage is, with planned
                                    </p> */}
                {/* <div className="upload-box">
                                            <label className="upload-box_label" htmlFor="">
                                                Attachments:
                                            </label>
                                            <FileUploaderBox boxClasses="fileuploader" />
                                        </div>*/}
                {/* </div> */}

                {/* <div className="question-box">
                                    <label className="question-label" htmlFor="">
                                        <span className="rating-header">Q5: Proposal Presentation</span>
                                        <div className="rating">
                                            <span>2.0</span>
                                            <button type="button" id="upload" onClick={ViewReview}>
                                                View
                                            </button>
                                        </div>
                                    </label>
                                    <p className="textarea">
                                        catching fire. Sometimes you need to take a site offline for routine maintenance
                                        or upgrades. While a no-downtime update is ideal, it isn’t always possible. The
                                        advantage is, with planned
                                    </p>
                                </div>
                             */}
              </form>
              ) : (
                <div className="mt-4">
                <DataTable columns={[
                    {
                      name: "S/N",
                      index: "s/n",
                      id: 1,
                      render: (_,y) => `Q${y+1}`
                    },
                    {
                      name: "Question",
                      index: "field_label",
                      id: 10
                    },
                    {
                      name: "Answer",
                      index: "res",
                      id: 2,
                      render: (item) => item.field_type?.toLowerCase() === 'file' ? <a className="pointer-event text-info" href={item.vendor_response} target="_blank" rel="noopener noreferrer">View File</a> : item.vendor_response
                    },
                    {
                      name: "Quantity",
                      index: "qty",
                      id: 720,
                      render: (x) => `${x?.requisition_material?.quantity_required || '--'} ${x?.requisition_material?.uom_description || ''}`
                    },
                    {
                      name: "Discount",
                      index: "discount",
                      id: 240,
                      render: (item) => `${item.vendor_response_discount || 0}%` || '----'
                    },
                    // {
                    //   name: "Gross Amount",
                    //   index: "gross",
                    //   id: 210,
                    //   render: (item) => {
                    //     return formatCurrency(item.vendor_response_gross) || '----'
                    //   } 
                    // },
                    {
                      name: "Amount",
                      index: "discount",
                      id: 210,
                      render: (item) => {
                        const amount = item.vendor_response_discount ? (item.vendor_response_gross * (100 - item.vendor_response_discount)) / 100 : item.vendor_response_gross;
                        return formatCurrency(amount) || '----'
                      } 
                    },
                    {
                      name: "Action",
                      index: "team_lead",
                      id: 220,
                      render: (item) => (
                        <div className="rating">
                                    {item.vendor_score ? (
                                      <button
                                        type="button"
                                        id="upload"
                                        onClick={() => {
                                          ViewReview(item.vendor_score, item.field_label, item.field_order);
                                        }}
                                      >
                                        View
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        id="upload"
                                        onClick={() => {
                                          ScoreReview(item.rfp_field_id, item.field_label, item.field_order);
                                        }}
                                      >
                                        Score
                                      </button>
                                    )}
                                  </div>
                      )
                    },
                  ]
                    } data={eachRfp?.data?.[0]?.vendor_responses || []} loading={eachLoading} />
                  </div>
              )}
              
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Evaluation_4;
