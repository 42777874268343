import * as React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import "./styles.scss";

type TabMenuProps = {
  menus: any[];
  classes?: string;
};

const TabMenu: React.FC<TabMenuProps> = ({ menus, classes }) => {
  const match = useRouteMatch();
  return (
    <div className={`tab-menu ${classes}`}>
      {menus &&
        menus.map((menu) => (
          <NavLink to={{ pathname: `${match.url}/${menu.path}` }} key={menu.name} className="navslink" activeClassName="item--active">
            <span className="item">{menu.name}</span>
          </NavLink>
        ))}
    </div>
  );
};

export default TabMenu;
