import React, { useState } from "react";
import Modal from "components/Modal";
import Button from "components/Button";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import FileUploaderBox from "components/FileUploaderBox";

import { createSourcingDoc, getOneRfp, uploadFileEvaluate } from "store/slice/BuyerSlice/EvaluationSlice/EvaluationSlice";

type Props = {
  closeModal: () => void;
  setIsUpload?: any;
  rfp_id?: any;
};
const CreateDoc = ({ closeModal, rfp_id = null, setIsUpload }: Props) => {
  const { register, handleSubmit, errors, control, setValue } = useForm<FormData>({
    mode: "onChange"
  });

  //State
  const [files, setFiles] = useState<any>([]);
  const [uploadBool, setUploadBool] = useState(false);

  //Redux Store
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector((state: RootState) => state.user);
  const { urlLink, isLoading } = useSelector((state: RootState) => state.rfpData);

  const onFormSubmit = (vals) => {
    const payload = {
      rfp_id: rfp_id,
      sourcing_report_document_url: urlLink
    };
    console.log(payload);
    dispatch(
      createSourcingDoc(payload, () => {
        setIsUpload(true);
        dispatch(getOneRfp({ id: rfp_id }));

        closeModal();
        // dispatch(getAllCompaniesData());
      })
    );
  };

  const handleFile = (e, field_name: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(e);
    reader.onloadend = () => {
      // use a regex to remove data url part
      let read: any = reader.result;
      const payload = {
        base64_data: read.replace("data:", "").replace(/^.+,/, ""),
        file_extension: e.name.split(".").pop()
      };
      dispatch(uploadFileEvaluate(payload));

      setFiles(payload);
    };
  };

  return (
    <Modal modalText="Upload" onClose={closeModal} modallength="modal-length">
      <div id="buyer-legal-entity">
        <p className="">Upload Document</p>

        <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
          <FileUploaderBox
            placeholder="Document"
            fileName="company_logo_url"
            boxClasses="mt-3 mb-2"
            label="Document"
            handleFile={handleFile}
            acceptFiles={".jpg, .jpeg, .png"}
            customRef={register({ required: true })}
            error={errors["logo"] && "This field is required"}
          />

          <Button label="Upload" btnType="btn-primary" boxClasses="mt-5 mb-5" disabled={isLoading} btnStyle="100%" loading={isLoading} />
        </form>
      </div>
    </Modal>
  );
};

export default CreateDoc;
