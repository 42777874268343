import DropdownMenu from "components/DropdownMenu";
import './styles.scss';

export const budgetCol = [
    {
        name: 'Name',
        index: 'name',
        id: 1,
    },
    {
        name: 'Location',
        index: 'location',
        id: 2,
    },
    {
        name: 'Budget Holder',
        index: 'budget_holder',
        id: 3,
    },
    {
        name: 'Date Created',
        index: 'date_created',
        id: 4,
    },
    {
        name: 'Budget Utilization',
        index: 'budget_utilization',
        id: 5,
    },
    {
        name: 'Available',
        index: 'available',
        id: 6,
    },
    {
        name: 'Action',
        id: 7,
        render: ({
            id,
            view,
            edit,
            deleteId,
        }) => {
            const settings = [
                {
                    name: 'View',
                    style: 'activate_tag',
                    select: view,
                },
                {
                    name: 'Edit',
                    style: 'activate_tag',
                    select: edit
                },
                {
                    name: 'Delete',
                    style: 'delete_tag',
                    select: deleteId
                }
            ];
            return (
                <span>
                    <DropdownMenu options={settings} boxClasses="dropdown-style">
                        <p style={{ fontSize: "2rem" }} className="cursor-pointer" id={id}>
                            ...
                        </p>
                    </DropdownMenu>
                </span>
            )
        }
    },
];