import { ReactComponent as GoArr } from "../../../assests/icons/goArr.svg";
export const tableCol = [
  {
    name: "Company",
    index: "legal",
    id: 3
  },
  {
    name: "Company Code",
    index: "company",
    id: 1
  },

  {
    name: "Vendor Name",
    index: "vendor",
    id: 2
  },

  {
    name: "",
    id: 7,
    render: (item) => {
      return <GoArr style={{ width: "18px", marginLeft: "5px" }} />;
    }
  }
];

export const tableCol2 = [
  {
    name: "Posting Date",
    index: "posting_date",
    id: 1
  },

  {
    name: "Invoice Number",
    index: "invoice_no",
    id: 2
  },
  {
    name: "Narration",
    index: "narration",
    id: 3
  },
  {
    name: "Amount To Be Paid",
    index: "invoice_amount",
    id: 4
  }
];
export const tableData = [
  {
    company: "Office supplies",
    vendor: "0803-371-0912",
    balance: "jessica.hanson@gmail.com"
  },
  {
    company: "Office supplies",
    vendor: "0803-371-0912",
    balance: "jessica.hanson@gmail.com"
  },
  {
    company: "Office supplies",
    vendor: "0803-371-0912",
    balance: "jessica.hanson@gmail.com"
  },
  {
    company: "Office supplies",
    vendor: "0803-371-0912",
    balance: "jessica.hanson@gmail.com"
  }
];
export const tableData2 = [
  {
    posting_date: "2/3/2928",
    invoice_no: "0803-371-0912",
    narration: "jessica.hanson@gmail.com",
    invoice_amount: "30000"
  },
  {
    posting_date: "2/3/2928",
    invoice_no: "0803-371-0912",
    narration: "jessica.hanson@gmail.com",
    invoice_amount: "30000"
  },
  {
    posting_date: "2/3/2928",
    invoice_no: "0803-371-0912",
    narration: "jessica.hanson@gmail.com",
    invoice_amount: "30000"
  },
  {
    posting_date: "2/3/2928",
    invoice_no: "0803-371-0912",
    narration: "jessica.hanson@gmail.com",
    invoice_amount: "30000"
  }
];
