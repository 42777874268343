import React, { useCallback, useState } from "react";
import "./styles.scss";
import TabSmall from "components/TabSmall";
import LocationLog from "./components/LocationLog";
import DepartmentLog from "./components/DepartmentLog";
import PurchaseGroup from "./components/PurchaseGroup";

const LocationData = () => {
    const menus = [
        { name: "Location Log", id: "log" },
        { name: "Department Log", id: "department" },
        { name: "Purchase Group", id: "purchaseGroup" }
    ];
    //State
    const [state, setstate] = useState(menus[0].id);

    const selectMenu = useCallback((name) => {
        setstate(name);
    }, []);

    return (
        <div id="settings-location-data">
            <div className="container">
                <TabSmall menus={menus} onSelect={selectMenu} classes="mb-3" />

                {state === "log" && <LocationLog />}
                {state === "department" && <DepartmentLog />}
                {state === "purchaseGroup" &&  <PurchaseGroup/>}
            </div>
        </div>
    );
};

export default LocationData;
