import DataTable from "components/DataTable";
import Pagination from "components/Pagination";
import Tabs from "components/Tabs";
import Textbox from "components/Textbox";

import BankInformation from "./BankInformation";
import ContactInformation from "./ContactInformation";
import LegalInformation from "pages/SupplierDashboard/Registration/LegalInformation";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getSingleVendor } from "store/slice/BuyerSlice/VendorSlice/VendorSlice";
import { AppDispatch, RootState } from "store/store";
import { documentsColumns, documents } from "./data";
import "./styles.scss";
import StarRating from "components/Star";
import { removeZero } from "utils/utils";
import Star from "components/Star";
import moment from "moment";
const SingleCompanyDoc = () => {
  const [vendor, setVendor] = useState<string>("");
  const [tabNames, setTabNames] = useState([] as any);
  const menus = ["Contact Information", ...tabNames, "Bank Informtion", "Business Category"];
  const { user } = useSelector((state: RootState) => state.user);
  //State
  const [state, setstate] = useState(menus[0]);

  //   store
  const { singleVendor } = useSelector((state: RootState) => state.vendorDetails);

  //Hooks
  const { register, handleSubmit, errors, setValue } = useForm<FormData>({ mode: "onBlur" });
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  //Tab Handler
  const selectMenu = useCallback((name) => {
    setstate(name);
  }, []);

  // check for ID
  useEffect(() => {
    if (location.state) {
      const state: any = location.state;
      dispatch(getSingleVendor(state, () => setTabNames([])));
    } else {
      history.push("/supplier/company-info");
    }
  }, []);

  const vendorData = singleVendor?.data[0];

  //   single vendor meta objedcts
  const vendorDataFiles = singleVendor?.data[0]?.vendor_meta;
  useEffect(() => {
    if (vendorData?.vendor_type === "Contractor") {
      setVendor("Contractor");
    } else if (vendorData?.vendor_type === "Supplier") {
      setVendor("Supplier");
    } else setVendor("");
  }, [vendorData]);

  // set dynamic tabs
  useEffect(() => {
    if (vendorDataFiles && vendorDataFiles === 0) {
      setTabNames([]);
    }
  }, [vendorDataFiles]);

  //   vendor files
  const vendorFiles = vendorDataFiles && vendorDataFiles.filter((file) => file.field_type === "File");

  //   Table data
  const tableData =
    vendorFiles &&
    vendorFiles.map((file) => {
      const { category, field_label, expires_on, data_status, vendor_data_id, value } = file;
      return {
        category: category,
        field_label,
        expires_on: expires_on === null ? "" : moment(expires_on).format("L"),
        data_status: data_status === "-1" ? "In Progress" : "Verified",
        link: value
      };
    });

  // get dynamic tabs
  let test = [] as any;
  let newData = [] as any;
  if (vendorDataFiles && vendorDataFiles.length > 0) {
    vendorDataFiles?.forEach((items, i) => {
      if (items.field_type === "Text") {
        test.push(items);
        if (tabNames.includes(items.category)) {
          return;
        } else setTabNames([...tabNames, items.category as any]);
        return items;
      }
    });

    for (let i = 0; i < tabNames.length; i++) {
      let data = {};
      data = { ...data, header: tabNames[i], array: [] };
      test.forEach((item, i) => {
        if (item.category === data["header"]) {
          data["array"] = [
            ...data["array"],

            {
              label: item.field_label,
              value: item.value
            }
          ];
        }
      });
      newData.push(data);
    }
  }

  useEffect(() => {
    if (vendorDataFiles && vendorDataFiles === 0) {
      setTabNames([]);
    }
  }, [vendorDataFiles]);

  return (
    <div className="singlecompany-doc">
      {/* <div className="container">
        <h6 className="page-title">Stakle Registration Data</h6>
        <div className="tab-container">
          <Tabs menus={menus} onSelect={selectMenu} />

          <div className="tabs-page pb-5">
            {state === "Contact Information" && (
              <div className="route-transition">
                <ContactInformation />
              </div>
            )}

            {state === "Legal Information" && (
              <div className="route-transition">
                <LegalInformation />
              </div>
            )}
            {state === "Bank Informtion" && (
              <div className="route-transition">
                <BankInformation />
              </div>
            )}
            {state === "Business Category" && (
              <div className="route-transition">
                <form>
                  <div className="row">
                    <div className="col-md-12 col-lg-4">
                      <Textbox label="Business Field" name="farmer_id" customRef={register({})} placeholder="Businees Field" boxClasses="mt-3" />
                      <Textbox label="Business Sector" name="household" placeholder="Business Sector" boxClasses="mt-4" customRef={register({})} />
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>

        <div className="mt-5">
          <DataTable columns={documentsColumns} data={tableData} />
          <Pagination page={0} lastPage={0} paginate={() => {}} total={0} handlePageChange={() => {}} perPage={0} />
        </div>
      </div> */}
      <div className="container">
        <div className="d-flex align-items-center mb-3">
          <h4 className="page-title m-0">
            {vendorData && vendorData?.vendor_description} {vendorData?.vendor_code ? "|" : ""} {vendorData && removeZero(vendorData?.vendor_code)}
          </h4>
          {/* <StarRating stars={3} disabled={true} /> */}
          <Star stars={user && user.overall_ratings} disabled={true} />
        </div>
        <div className="tab-container">
          <Tabs menus={menus} onSelect={selectMenu} />

          <div className="tabs-page pb-5">
            {state === "Contact Information" && (
              <div className="route-transition">
                <ContactInformation vendorData={vendorData} />
              </div>
            )}

            {state === "Bank Informtion" && (
              <div className="route-transition">
                <BankInformation />
              </div>
            )}
            {newData.map((tab, i) => {
              return (
                state === tab.header && (
                  <div className="route-transition" key={i}>
                    <form>
                      <div className="row">
                        {tab.array.map((textBox, i) => {
                          return (
                            <div className="col-md-12 col-lg-6" key={i}>
                              <Textbox disabled={true} name="farmer_id" value={textBox.value} label={textBox.label} boxClasses="mt-3" />
                            </div>
                          );
                        })}
                      </div>
                    </form>
                  </div>
                )
              );
            })}

            {state === "Business Category" && (
              <div className="route-transition">
                <form>
                  <div className="row">
                    {vendorData.categories.map((biz, i) => {
                      return (
                        <div className="col-md-12 col-lg-6" key={i}>
                          <Textbox value={biz.business_category_description} placeholder="Businees Field" boxClasses="mt-4" disabled={true} />
                        </div>
                      );
                    })}
                  </div>
                </form>
              </div>
            )}

            {state === "Other Information" && (
              <div className="route-transition">
                <form>
                  <div className="row">
                    <div className="col-md-12 col-lg-6 mt-4">
                      <p className="page-title" style={{ fontSize: "20px" }}>
                        Supplier Selection
                      </p>
                      <div className="d-flex radio-box mt-4 justify-content-between">
                        <div className="">
                          <input
                            type="radio"
                            name="certified"
                            id="yes"
                            className="mr-2"
                            checked={vendor === "Contractor"}
                            // onChange={() => vendorTypeChange("Contractor")}
                          />
                          <label className="radio-box_label" htmlFor="yes">
                            Contractor
                          </label>
                        </div>
                        <div className="">
                          <input
                            type="radio"
                            name="certified"
                            id="no"
                            className="mr-2"
                            checked={vendor === "Supplier"}
                            // onChange={() => vendorTypeChange("Supplier")}
                          />
                          <label className="radio-box_label" htmlFor="no">
                            Supplier
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>

        <div className="mt-5">
          <DataTable columns={documentsColumns} data={tableData} />
          {/* <Pagination page={0} lastPage={0} paginate={() => {}} total={0} handlePageChange={() => {}} perPage={0} /> */}
        </div>
      </div>
    </div>
  );
};

export default SingleCompanyDoc;
