import React, { useCallback, useEffect, useState } from "react";
import "./styles.scss";

import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootState } from "store/store";

import useSearch from "hooks/useSearch";
import { getWayBill } from "store/slice/SupplierSlices/Waybill/WayBillSlice";

import { getInvoiceSummary } from "store/slice/BuyerSlice/Invoice/InvoiceSlice";
import StatsCard from "components/StatsCard";
import SearchBar from "../../../components/SearchBar";
import file from "../../../assests/icons/supplier/file.svg";
import accordionImg from "../../../assests/icons/supplier/accordion.svg";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { tableCol2, tableData2 } from "./data";
import { getStatement } from "store/slice/BuyerSlice/VendorStatementSlice/VendorStatementSlice";
import { formatCurrency } from "utils/utils";
import Analytics from "assests/icons/buyer/analytics.svg";
interface IUserMenuProps {}

const EachVendorStatement: React.FC<IUserMenuProps> = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //State
  const [modal, setModal] = useState<boolean>(false);
  const [active, setActive] = useState(true);
  const [active2, setActive2] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageNum, setPageNumber] = useState(10);
  const [showFilter, setShowFilter] = useState(false);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");
  const vend = urlParams.get("vendor");
  const { statement, loading } = useSelector((state: RootState) => state.statement);

  const [raStatement, setRaStatement] = useState([]);

  const [kaStatement, setKaStatement] = useState([]);
  const [hstatement, setHstatement] = useState<any>([]);
  const [sstatement, setSstatement] = useState<any>([]);

  const [sumAmount, setSumAmount] = useState(0);

  useEffect(() => {
    const raFilter = statement
      ? statement.data.length
        ? statement.data[0].invoice_debits.filter((item) => {
            return item.document_type === "RE" || item.document_type === "KR";
          })
        : []
      : [];

    setRaStatement(raFilter);

    const kaFilter = statement
      ? statement.data.length
        ? statement.data[0].invoice_debits.filter((item) => {
            return item.document_type !== "RE" && item.document_type !== "KR";
          })
        : []
      : [];

    setKaStatement(kaFilter);

    const hFilter = statement
      ? statement.data.length
        ? statement.data[0].invoice_debits.filter((item) => {
            return item.db_cr_ind === "H";
          })
        : []
      : [];

    setHstatement(hFilter);

    const sFilter = statement
      ? statement.data.length
        ? statement.data[0].invoice_debits.filter((item) => {
            return item.db_cr_ind === "S";
          })
        : []
      : [];

    setSstatement(sFilter);
  }, [statement]);

  const [hsum, setHsum] = useState(0);
  const [ssum, setSsum] = useState(0);

  useEffect(() => {
    if (hstatement.length) {
      // const hSum = hstatement.reduce((a: any, b: any) => {
      //   return parseInt(a.amount) + parseInt(b.amount);
      // });
      // console.log(hSum, "summa");
      let hsum2 = 0;
      for (let index = 0; index < hstatement.length; index++) {
        console.log(hsum, parseInt(hstatement[index].amount), "what to");
        hsum2 = parseFloat(hstatement[index].amount) + hsum2;
        console.log(hsum2);
        setHsum(hsum2);
      }
    }
  }, [hstatement]);
  useEffect(() => {
    if (sstatement.length) {
      // const hSum = hstatement.reduce((a: any, b: any) => {
      //   return parseInt(a.amount) + parseInt(b.amount);
      // });
      // console.log(hSum, "summa");
      let ssum2 = 0;
      for (let index = 0; index < sstatement.length; index++) {
        console.log(hsum, parseInt(sstatement[index].amount), "what to");
        ssum2 = parseFloat(sstatement[index].amount) + ssum2;
        console.log(ssum2);
        setSsum(ssum2);
      }
    }
  }, [sstatement]);

  useEffect(() => {
    if (hsum > ssum) {
      setSumAmount(hsum - ssum);
    } else {
      setSumAmount(ssum - hsum);
    }
  }, [ssum, hsum]);

  // const [_modalData, set_ModalData] = useState(null);

  // const { search, inputRef, handleChange, searched } = useSearch(getWayBill, perPage);

  //Redux Hook
  const dispatch = useDispatch<AppDispatch>();

  const { user } = useSelector((state: RootState) => state.user);

  const [openNonPO, setOpenNonPO] = useState(false);
  useEffect(() => {
    dispatch(getStatement(page, perPage, "", id));
  }, []);

  const closeModal = () => {
    setModal(!modal);
  };
  const closePo = () => {
    setOpenNonPO(!openNonPO);
  };

  useEffect(() => {}, [modal]);

  const raList = raStatement[0]
    ? raStatement.map((item: any) => {
        return {
          posting_date: item.payment_date,
          invoice_no: item.document_number,
          narration: item.invoice_debit_description,
          invoice_amount: `${item.currency_iso} ${formatCurrency(item.amount)}`
        };
      })
    : [];

  const kaList = kaStatement[0]
    ? kaStatement.map((item: any) => {
        return {
          posting_date: item.payment_date,
          invoice_no: item.document_number,
          narration: item.invoice_debit_description,
          invoice_amount: `${item.currency_iso} ${formatCurrency(item.amount)}`
        };
      })
    : [];

  return (
    <section className="statement">
      <div className="container">
        <div className="mt-4 row">{/* <StatsCard statsNumber={0} text="Vendor/Open Items" classes="stats-icon-orange" image={file} /> */}</div>
        <div className="document mt-2">
          <h3 className="title font-weight-bold">Transaction History - {vend && vend}</h3>

          <div className="row mt-3 mb-5">
            <StatsCard
              // statsNumber={statement ? (statement.data.length ? statement.data[0].total_amount : 0) : 0}
              statsNumber={sumAmount}
              text="Balance"
              classes="stats-icon"
              image={Analytics}
              iconBackgroundColor="rgba(45, 156, 219, 0.1)"
            />
          </div>
          <div className="d-flex mt-3">
            {/* <SearchBar placeholder="Search Transacation History" /> */}
            {/* <Dropdown perPage={10} action={(num) => setPageNumber(num)} /> */}
          </div>
          <div className="mt-3">
            <div className="accordion">
              <div className={`contentBx ${active ? "active" : ""}`}>
                <div
                  onClick={() => {
                    setActive(!active);
                  }}
                  className="label"
                >
                  <div className="text">
                    <p className="open">Open Invoice Items</p>
                    <p className="amount">{/* Total Amount: <span>12,756,847</span> */}</p>
                  </div>
                  <img src={accordionImg} alt="accordion" />
                </div>
                <div className="content">
                  <DataTable columns={tableCol2} data={raList} loading={loading} />
                </div>
              </div>
            </div>

            <div className="accordion red">
              <div className={`contentBx ${active2 ? "active" : ""}`}>
                <div
                  onClick={() => {
                    setActive2(!active2);
                  }}
                  className="label"
                >
                  <div className="text">
                    <p className="open">Open Item</p>
                    <p className="amount">{/* Total Amount: <span>-12,756,847</span> */}</p>
                  </div>
                  <img src={accordionImg} alt="accordion" />
                </div>
                <div className="content">
                  <DataTable columns={tableCol2} data={kaList} loading={loading} />
                </div>
              </div>
            </div>
            {/* <DataTable columns={tableCol} data={tableData} /> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default EachVendorStatement;
