import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CompaniesState, Action } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const initialState: CompaniesState = {
  loading: false,
  isLoading: false,
  deleting: false,
  companies: null,
  audit: null,
  audit2: null,
  s3url: null,
  successMessage: "",
  errorMessage: "",
  isLoadingReq: false,
  loadingReq: false,
  poReq: null,
  extendLoad: false
};

const companyDataSlice = createSlice({
  name: "companyData",
  initialState,
  reducers: {
    getPoReqLoading: (state) => {
      state.loadingReq = true;
    },
    getPoReqSuccess: (state, action: PayloadAction<Action>) => {
      state.loadingReq = false;
      state.poReq = action.payload;
    },
    getPoReqFail: (state) => {
      state.loadingReq = false;
    },
    extendLoading: (state) => {
      state.extendLoad = true;
    },
    extendSuccess: (state) => {
      state.extendLoad = false;
    },
    extendFail: (state) => {
      state.extendLoad = false;
    },
    createPoReqLoading: (state) => {
      state.isLoadingReq = true;
    },
    createPoReqSuccess: (state) => {
      state.isLoadingReq = false;
    },
    createPoReqFail: (state) => {
      state.isLoadingReq = false;
    },
    delReqLoading: (state) => {
      state.isLoadingReq = true;
    },
    delReqSuccess: (state) => {
      state.isLoadingReq = false;
    },
    delReqFail: (state) => {
      state.isLoadingReq = false;
    },
    getAllCompaniesDataLoading: (state) => {
      state.loading = true;
    },
    getAllCompaniesDataSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.companies = action.payload;
    },
    getAllCompaniesDataFail: (state) => {
      state.loading = false;
    },
    getAllAuditDataLoading: (state) => {
      state.loading = true;
    },
    getAllAuditDataSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.audit = action.payload;
    },
    getAllAuditDataSuccess2: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.audit2 = action.payload;
    },
    getAllAuditDataFail: (state) => {
      state.loading = false;
    },
    createCompanyLoading: (state) => {
      state.isLoading = true;
    },
    createCompanySuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.companies = action.payload;
    },
    createCompanyFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    editCompanyLoading: (state) => {
      state.isLoading = true;
    },
    editCompanySuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.companies = action.payload;
    },
    editCompanyFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    deleteCompanyLoading: (state) => {
      state.deleting = true;
    },
    deleteCompanySuccess: (state, action: PayloadAction<Action>) => {
      state.deleting = false;
      state.companies = action.payload;
    },
    deleteCompanyFail: (state, action: PayloadAction<string>) => {
      state.deleting = false;
      state.errorMessage = action.payload;
    },
    uploadingImageRequest: (state) => {
      state.isLoading = true;
    },
    uploadingImageSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.s3url = action.payload;
    },
    uploadingImageFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
    }
  }
});

const { actions, reducer } = companyDataSlice;
export const {
  getPoReqFail,
  getPoReqLoading,
  getPoReqSuccess,
  createPoReqFail,
  createPoReqLoading,
  createPoReqSuccess,
  delReqFail,
  delReqLoading,
  delReqSuccess,
  getAllCompaniesDataLoading,
  getAllCompaniesDataSuccess,
  getAllCompaniesDataFail,
  createCompanyLoading,
  createCompanySuccess,
  createCompanyFail,
  editCompanyLoading,
  editCompanySuccess,
  editCompanyFail,
  deleteCompanyLoading,
  deleteCompanySuccess,
  deleteCompanyFail,
  uploadingImageRequest,
  uploadingImageSuccess,
  uploadingImageFail,
  getAllAuditDataFail,
  getAllAuditDataLoading,
  getAllAuditDataSuccess,
  getAllAuditDataSuccess2,
  extendFail,
  extendLoading,
  extendSuccess
} = actions;

// get all companies
export const getAllCompaniesData =
  (page?: number, length?: number, search?: string, department_status?: string, department_code?: string, company_id?: string, location_id?: string, hod_user_id?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getAllCompaniesDataLoading());
    try {
      const response = await API.get("/companies/get", {
        params: {
          page,
          length,
          search,
          department_status,
          department_code,
          company_id,
          location_id,
          hod_user_id
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getAllCompaniesDataSuccess(response.data));
      }
    } catch (error) {
      dispatch(getAllCompaniesDataFail());
    }
  };

export const getAllAuditData =
  (
    page?: number,
    length?: number,
    search?: string,
    user_type?: string,
    vendor_id?: string,
    company_id?: string,
    user_role_id?: string,
    department_id?: string,
    location_id?: string,

    date_from?: any,
    date_to?: any
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getAllAuditDataLoading());
    try {
      const response = await API.get("/system_audits/get", {
        params: {
          page,
          length,
          search,
          user_type,
          vendor_id,
          company_id,
          user_role_id,
          department_id,
          location_id,
          date_from,
          date_to
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getAllAuditDataSuccess(response.data));
      }
    } catch (error) {
      dispatch(getAllAuditDataFail());
    }
  };

export const getAllAuditData2 =
  (page?: number, length?: number, search?: string, user_type?: string, vendor_id?: string, company_id?: string, user_role_id?: string, department_id?: string, location_id?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getAllAuditDataLoading());
    try {
      const response = await API.get("/system_audits/get", {
        params: {
          page,
          length: 30000,
          search,
          user_type,
          vendor_id,
          company_id,
          user_role_id,
          department_id,
          location_id
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getAllAuditDataSuccess2(response.data));
      }
    } catch (error) {
      dispatch(getAllAuditDataFail());
    }
  };

export const getAllPOReq =
  (page?: number, length?: number, search?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getPoReqLoading());
    try {
      const response = await API.get("purchase_order_requests/get", {
        params: {
          page,
          length,
          search
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getPoReqSuccess(response.data));
      }
    } catch (error) {
      dispatch(getPoReqFail());
    }
  };

export const createPoReq =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(createPoReqLoading());
    try {
      const response = await API.post(`purchase_order_requests/create?admin_exception=yes`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(createPoReqSuccess());
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error: any) {
      if (error.response) {
        dispatch(createPoReqFail());
        toastr.error(error.response.data.message);
      } else {
        dispatch(createCompanyFail("No network connecton"));
        toastr.error("No network connecton");
      }

      setTimeout(() => {
        dispatch(createCompanyFail(""));
      }, 3000);
    }
  };

export const extendRfp =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(extendLoading());
    try {
      const response = await API.post(`rfps/extend`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(extendSuccess());
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error: any) {
      if (error.response) {
        dispatch(extendFail());
        toastr.error(error.response.data.message);
      } else {
        dispatch(extendFail());
        toastr.error("No network connecton");
      }
    }
  };

export const delPoReq =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(delReqLoading());
    try {
      const response = await API.post(`purchase_order_requests/delete`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(delReqSuccess());
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error: any) {
      if (error.response) {
        dispatch(delReqFail());
        toastr.error(error.response.data.message);
      } else {
        dispatch(createCompanyFail("No network connecton"));
        toastr.error("No network connecton");
      }

      setTimeout(() => {
        dispatch(createCompanyFail(""));
      }, 3000);
    }
  };

// create company
export const createCompanyData =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(createCompanyLoading());
    try {
      const response = await API.post(`/companies/create`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(createCompanySuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error: any) {
      if (error.response) {
        dispatch(createCompanyFail(error.response.data?.message));
        toastr.error(error.response.data.message);
      } else {
        dispatch(createCompanyFail("No network connecton"));
        toastr.error("No network connecton");
      }

      setTimeout(() => {
        dispatch(createCompanyFail(""));
      }, 3000);
    }
  };

// upload file

export const uploadFile =
  (payload, cb?: (data) => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(uploadingImageRequest());
    const { userToken } = getState().user;
    try {
      const response = await axios.post(`${BASE_URL}/prometheus/upload_file`, payload, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "multipart/form-data"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(uploadingImageSuccess(response.data.s3url));
        toastr.success("", "File Successfully Uploaded");
        cb && cb(response.data.s3url);
      }
    } catch (error) {
      dispatch(uploadingImageFail(""));
    }
  };

// edit company data
export const editCompanyData =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(editCompanyLoading());
    try {
      const response = await API.post(`/companies/edit`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(editCompanySuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error: any) {
      if (error.response) {
        dispatch(editCompanyFail(error.response.data.message));
        toastr.error(error.response.data.message);
      } else {
        dispatch(createCompanyFail("No network connecton"));
        toastr.error("No network connecton");
      }

      setTimeout(() => {
        dispatch(editCompanyFail(""));
      }, 3000);
    }
  };

// delete company data
export const deleteCompanyData =
  (data?: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(deleteCompanyLoading());
    try {
      const response = await API.post(`/locations/delete`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(deleteCompanySuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error: any) {
      if (error.response) {
        dispatch(deleteCompanyFail(error.response.data.message));
        toastr.error(error.response.data.message);
      } else {
        dispatch(deleteCompanyFail("No internet connection"));
        toastr.error("No internet connection");
      }

      setTimeout(() => {
        dispatch(deleteCompanyFail(""));
      }, 3000);
    }
  };

export default reducer;
