import React from "react";
import { Link } from "react-router-dom";
import "./AuthLayout.scss";
import logo from "assests/images/image 3 (1).png";
import GziLogo from "assests/images/gziLogo.png";
import GZILogo from "components/GZILogo";

type AuthProps = {
  bottomImage?: string;
  headerText?: string;
  imgWidth?: string;
  content?: string;
  children?: React.ReactNode;
  colorText?: string;
  firstLink?: string;
  firstLinkColor?: string;
  secondLink?: string;
  secondLinkColor?: string;
  padTop?: string;
  link: string;
  linkTo?: string;
};

const AuthLayout: React.FC<AuthProps> = (props: AuthProps) => {
  return (
    <div className="auth-layout container-fluid">
      <div className="row r-100">
        <div className="col-md-3 px-0 display">
          <div className="left-col ">
            <GZILogo />
            <div id="coverAct">
              <img src={props.bottomImage} alt="investment" className="bottom-img" style={{ width: `${props.imgWidth}` }} />
              <div className="circle-background"></div>
            </div>
            {/* <div>
              <img src={props.bottomImage} alt="investment" className="bottom-img" style={{ width: `${props.imgWidth}` }} />
              <div className="circle-background"></div>
            </div> */}
          </div>
        </div>
        <div className="col-md right-col" style={{ paddingTop: `${props.padTop}` }}>
          <div className="row justify-content-md-center">
            <div className="col-md-6 col-xs-10 d-flex justify">
              <div className="right-content">
                <h3>
                  {props.headerText} <span className="link">{props.colorText}</span>
                </h3>
                <p className="pt-4 p-content">{props.content}</p>
                {props.children}
                <p className="p-link pt-4">
                  {props.firstLink}{" "}
                  <span className="link">
                    <Link to={props.link}>{props.firstLinkColor} </Link>
                  </span>
                </p>
                <footer>
                  <p className="support">
                    {props.secondLink}{" "}
                    <a href={props.linkTo} target="_blank" className="link">
                      {props.secondLinkColor}
                    </a>
                  </p>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
