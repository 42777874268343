import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { notificationStates, Action } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";

const initialState: notificationStates = {
  loading: false,
  deleting: false,
  isLoading: false,
  notifications: null,
  successMessage: "",
  errorMessage: ""
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    getAlllNotificationLoading: (state) => {
      state.loading = true;
    },
    getAlllNotificationSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.notifications = action.payload;
    },
    getAlllNotificationFail: (state) => {
      state.loading = false;
    },
    readNotificationLoading: (state) => {
      state.isLoading = true;
    },
    readNotificationSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.notifications = action.payload;
    },
    readNotificationFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },

    deleteNotificationLoading: (state) => {
      state.deleting = true;
    },
    deleteNotificationSuccess: (state, action: PayloadAction<Action>) => {
      state.deleting = false;
      state.notifications = action.payload;
    },
    deleteNotificationFail: (state, action: PayloadAction<string>) => {
      state.deleting = false;
      state.errorMessage = action.payload;
    }
  }
});

const { actions, reducer } = notificationsSlice;
export const {
  getAlllNotificationLoading,
  getAlllNotificationSuccess,
  getAlllNotificationFail,
  readNotificationLoading,
  readNotificationSuccess,
  readNotificationFail,
  deleteNotificationLoading,
  deleteNotificationSuccess,
  deleteNotificationFail
} = actions;

export const getUserNotification =
  (page?: number, length?: number, search?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getAlllNotificationLoading());
    try {
      const response = await API.get("/user_notifications/get", {
        params: {
          page,
          length,
          search
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getAlllNotificationSuccess(response.data));
      }
    } catch (error) {
      dispatch(getAlllNotificationFail());
    }
  };

export const readNotification =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(readNotificationLoading());
    try {
      const response = await API.post(`/user_notifications/mark_as_read`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(readNotificationSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      if (error.response) {
        dispatch(readNotificationFail(error.response.data?.message));
        toastr.error(error.response.data.message);
      } else {
        dispatch(readNotificationFail("No network connecton"));
        toastr.error("No network connecton");
      }
      setTimeout(() => {
        dispatch(readNotificationFail(""));
      }, 3000);
    }
  };

export const deleteNotification =
  (data?: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(deleteNotificationLoading());
    try {
      const response = await API.post(`/user_notifications/delete`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(deleteNotificationSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      dispatch(deleteNotificationFail(error.response.data.message));
      toastr.error(error.response.data.message);
      setTimeout(() => {
        dispatch(deleteNotificationFail(""));
      }, 3000);
    }
  };

export default reducer;
