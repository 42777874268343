import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { taxStates, Action } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";

const initialState: taxStates = {
  loading: false,
  deleting: false,
  isLoading: false,
  tax: null,
  successMessage: "",
  errorMessage: ""
};

const taxSlice = createSlice({
  name: "tax",
  initialState,
  reducers: {
    getAlllTaxLoading: (state) => {
      state.loading = true;
    },
    getAlllTaxSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.tax = action.payload;
    },
    getAlllTaxFail: (state) => {
      state.loading = false;
    }
  }
});

const { actions, reducer } = taxSlice;
export const { getAlllTaxLoading, getAlllTaxSuccess, getAlllTaxFail } = actions;

export const getTaxSupplier =
  (page?: number, length?: number, search?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;

    dispatch(getAlllTaxLoading());
    try {
      const response = await API.get("/tax_codes/get", {
        params: {
          page,
          length,
          search
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getAlllTaxSuccess(response.data));
      }
    } catch (error) {
      dispatch(getAlllTaxFail());
    }
  };

export default reducer;
