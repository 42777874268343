import React, { useEffect, useState } from "react";
import Modal from "components/Modal";
import Textbox from "components/Textbox";
import Button from "components/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import "./styles.scss";
import { useForm } from "react-hook-form";
import { doverifyVendorFile, getSingleVendor } from "store/slice/BuyerSlice/VendorSlice/VendorSlice";
import { setConstantValue } from "typescript";

type Props = {
  closeModal: () => void;
  docName?: string;
  data_id?: string;
  id?: string;
  expire?: any;
};
const VerifyFile = ({ closeModal, docName = "", data_id = "", id = "", expire = null }: Props) => {
  console.log(expire, "edit2");
  //Redux Store
  const dispatch = useDispatch();
  const { handleSubmit, setValue, register } = useForm<FormData>({
    mode: "onBlur"
  });
  const { loading } = useSelector((state: RootState) => state.vendorDetails);

  const [req, setReq] = useState(true);

  const [date, setDate] = useState<any>("");
  const [disable, setDisable] = useState(false);
  const onChange = (e) => {
    setDate(e.target.value);
  };
  useEffect(() => {
    if (expire) {
      setDate(expire);
      setValue("farmer_id", expire);
    } else {
    }
  }, [expire, setValue]);
  const onFormSubmit = () => {
    if (expire) {
      dispatch(
        doverifyVendorFile({ expires_on: date !== "" ? date : null, vendor_data_id: data_id }, () => {
          dispatch(getSingleVendor(id));
          closeModal();
        })
      );
    } else {
      dispatch(
        doverifyVendorFile({ expires_on: date !== "" ? date : null, vendor_data_id: data_id }, () => {
          dispatch(getSingleVendor(id));
          closeModal();
        })
      );
    }
  };
  const handleCheck = (checked) => {
    console.log(checked);
    if (checked) {
      setDate("");
      setReq(false);
      setDisable(true);
    } else {
      setDate(date);
      setReq(true);
      setDisable(false);
    }
  };
  return (
    <Modal modalText="Verify Document" onClose={closeModal} boxClasses="role-modal-top">
      <div className="verify-file-modal">
        <p className="">Verify Document and append Expiry Date if Required.</p>
        <form className="mt-4" onSubmit={handleSubmit(onFormSubmit)}>
          <p className="mb-3 yaraa-color">Document Name</p>
          <p>{docName}</p>
          <Textbox
            type="date"
            name="farmer_id"
            value={date}
            label="Expiry Date"
            boxClasses="mt-3"
            required={req}
            customRef={register({ required: req })}
            // error={"This field is required"}
            disabled={disable}
            placeholder="Expiry Date"
            onChange={onChange}
            min={new Date().toISOString().slice(0, 10)}
          />
          <div className="coverCheck d-flex mt-3" style={{ display: "flex", alignItems: "center" }}>
            <input
              onChange={(e) => {
                handleCheck(e.target.checked);
              }}
              style={{ marginRight: "5px" }}
              type="checkbox"
              name="check"
              id="check"
            />
            <label className="mb-0" htmlFor="check">
              This Document does not expire
            </label>
          </div>
          <Button label="Verify" btnType="btn-primary" boxClasses="mt-4 mb-5" btnStyle="100%" loading={loading} disabled={loading} />
        </form>
        <p className="role-p" onClick={closeModal}>
          Cancel
        </p>
      </div>
    </Modal>
  );
};

export default VerifyFile;
