/* eslint-disable react-hooks/exhaustive-deps */
import StatsCard from "components/StatsCard";
import React, { useEffect, useState } from "react";
import "../styles.scss";
import LocationIcon from "assests/icons/buyer/group-box.svg";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import SearchIcon from "components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { productCateoryColumn } from "../data";
import CreateOrEditLocation from "components/ModalBuyer/CreateOrEditLocation";
import { deleteCategory, getMaterialCategories, getMaterials } from "store/slice/BuyerSlice/ProductSlice/ProductSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import CreateOrEditProductCategory from "components/ModalBuyer/CreateOrEditProductCategory";
import useSearch from "hooks/useSearch";
import Pagination from "components/Pagination";
import { toastPopUp } from "utils/utils";
import { checkPermission } from "utils/utils";

const ProductCategory = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [modal, setModal] = useState<boolean>(false);
  const [singleProductCategory, setSingleProductCategory] = useState<any>(null);

  const closeModal = () => {
    setModal((prev) => !prev);
    setSingleProductCategory(null);
  };

  //Redux
  const dispatch = useDispatch<AppDispatch>();
  const { materialCategory, loading } = useSelector((state: RootState) => state.product);
  const { role_access } = useSelector((state: RootState) => state.user?.user);

  //Hook
  const { search, inputRef, handleChange, searched } = useSearch(getMaterialCategories, perPage);

  // get material categories
  useEffect(() => {
    dispatch(getMaterialCategories(page, perPage));
  }, []);

  useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  //Show Number for PerPage Request
  const handleNewPage = (pageNo: number, page: number = 1) => {
    setPerPage(pageNo);
    setPage(1);
    dispatch(getMaterialCategories(page, pageNo, search));
  };

  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, perPage: number) => {
      setPage(page);
      dispatch(getMaterialCategories(page, perPage, search));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, perPage: number) => {
      setPage(pageNo);
      dispatch(getMaterialCategories(pageNo, perPage, search));
    },
    [search]
  );

  //Edit DepartmentData
  const editCategoryData = (id: string, material_category_code: string, material_category_description: string, material_category_type: string) => {
    setSingleProductCategory({ id, material_category_code, material_category_description, material_category_type });
    setModal((prev) => !prev);
  };

  //  delete
  const deleteCategory2 = (name: string, id: string) => {
    const payload = { material_id: id };

    toastPopUp(
      () => {
        dispatch(
          deleteCategory(payload, () => {
            dispatch(getMaterialCategories(page, perPage));
          })
        );
      },
      "Delete",
      "Cancel",
      "Delete the entity",
      name,
      "red"
    );
  };

  const materialCategoryList =
    materialCategory &&
    materialCategory?.data?.map((material) => {
      return {
        id: material.id,
        code: material.material_category_code,
        name: material.material_category_description,
        edit: () =>
          checkPermission(role_access, "SETUP_INDEX") && editCategoryData(material.id, material.material_category_code, material.material_category_description, material.material_category_type),

        deleteCategoryData: () => checkPermission(role_access, "SETUP_INDEX") && deleteCategory2(material.material_category_description, material.id)
      };
    });

  return (
    <div id="settings-material-data">
      {modal && <CreateOrEditProductCategory closeModal={closeModal} singleProductCategory={singleProductCategory} />}
      <div className="container mt-3">
        <div className="row mt-3 mb-5">
          <StatsCard statsNumber={materialCategory?.count ?? 0} text="Product Category" classes="stats-icon" image={LocationIcon} iconBackgroundColor="rgba(45, 156, 219, 0.1)" />
          <StatsCard image={AddIcon} classes="middle-img" centerText="Create Category" onClick={closeModal} />
        </div>

        <div className="d-flex  mb-3">
          <SearchIcon placeholder="Search for Product Category" boxClasses="" textRef={inputRef} value={search} onChange={handleChange} />
          <Dropdown perPage={perPage} action={handleNewPage} />
        </div>
        <DataTable columns={productCateoryColumn} data={materialCategoryList} loading={loading} />
        {materialCategory?.count > 10 ? (
          <Pagination
            page={page}
            lastPage={materialCategory?.pagination?.lastPage}
            paginate={paginate}
            total={materialCategory?.pagination?.total}
            handlePageChange={handlePageChange}
            perPage={perPage}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ProductCategory;
