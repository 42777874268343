import DropdownMenu from "components/DropdownMenu";
import "./styles.scss";

export const productCateoryColumn = [
  {
    name: "ID",
    index: "id",
    id: 1
  },
  {
    name: "Category Name",
    index: "name",
    id: 2
  },
  {
    name: "ERP ID",
    index: "code",
    id: 3
  },
  {
    name: "Actions",
    id: 4,
    render: ({ deleteCategoryData, edit }) => {
      const settings = [
        {
          name: "Edit",
          style: "edit_tag",
          select: edit
        },
        {
          name: "Delete",
          style: "delete_tag",
          select: deleteCategoryData
        }
      ];
      return (
        <span style={{ position: "relative" }}>
          <DropdownMenu options={settings} boxClasses="dropdown-style">
            <p style={{ fontSize: "2rem" }} className="cursor-pointer">
              ...
            </p>
          </DropdownMenu>
        </span>
      );
    }
  }
];

export const taxColumn = [
  {
    name: "ID",
    index: "id",
    id: 1
  },
  {
    name: "Tax Code",
    index: "tax_code",
    id: 2
  },
  {
    name: "Description",
    index: "tax_code_description",
    id: 3
  },
  {
    name: "Tax Amount",
    index: "tax_amount",
    id: 4
  },
  {
    name: "Company",
    index: "company",
    id: 5
  },
  {
    name: "Actions",
    id: 6,
    render: ({ deleteTaxData, edit }) => {
      const settings = [
        {
          name: "Edit",
          style: "edit_tag",
          select: edit
        },
        {
          name: "Delete",
          style: "delete_tag",
          select: deleteTaxData
        }
      ];
      return (
        <span style={{ position: "relative" }}>
          <DropdownMenu options={settings} boxClasses="dropdown-style">
            <p style={{ fontSize: "2rem" }} className="cursor-pointer">
              ...
            </p>
          </DropdownMenu>
        </span>
      );
    }
  }
];

export const elementColumn = [
  {
    name: "ID",
    index: "id",
    id: 1
  },
  {
    name: "Description",
    index: "cost_element_description",
    id: 2
  },
  {
    name: "Company",
    index: "company",
    id: 3
  },
  {
    name: "Number",
    index: "cost_element_number",
    id: 4
  },
  {
    name: "Actions",
    id: 5,
    render: ({ deleteElementData, edit }) => {
      const settings = [
        {
          name: "Edit",
          style: "edit_tag",
          select: edit
        },
        {
          name: "Delete",
          style: "delete_tag",
          select: deleteElementData
        }
      ];
      return (
        <span style={{ position: "relative" }}>
          <DropdownMenu options={settings} boxClasses="dropdown-style">
            <p style={{ fontSize: "2rem" }} className="cursor-pointer">
              ...
            </p>
          </DropdownMenu>
        </span>
      );
    }
  }
];

export const exchangeRateColumn = [
  {
    name: "Date",
    index: "date",
    id: 1
  },
  {
    name: "Currency (From)",
    index: "currency_from",
    id: 2
  },
  {
    name: "Currency (To)",
    index: "currency_to",
    id: 3
  },
  {
    name: "Exchange Rate Amount",
    index: "amount",
    id: 4
  },

  {
    name: "Actions",
    id: 8,
    render: ({ deleteExchangeRate, edit }) => {
      const settings = [
        {
          name: "Edit",
          style: "edit_tag",
          select: edit
        },
        {
          name: "Delete",
          style: "delete_tag",
          select: deleteExchangeRate
        }
      ];
      return (
        <span style={{ position: "relative" }}>
          <DropdownMenu options={settings} boxClasses="dropdown-style">
            <p style={{ fontSize: "2rem" }} className="cursor-pointer">
              ...
            </p>
          </DropdownMenu>
        </span>
      );
    }
  }
];

export const reverseColumn = [
  {
    name: "Date",
    index: "date",
    id: 1
  },
  {
    name: "Activity",
    index: "activity",
    id: 2
  },
  {
    name: "Record Code",
    index: "record_code",
    id: 3
  },
  {
    name: "Name",
    index: "name",
    id: 4
  }
];

export const productDataColumn = [
  {
    name: "SKU ID",
    index: "id",
    id: 1
  },
  {
    name: "Picture",
    index: "name",
    id: 2
  },
  {
    name: "Product Name",
    index: "product_name",
    id: 3
  },
  {
    name: "UoM",
    index: "uom",
    id: 4
  },
  {
    name: "Product Category",
    index: "category",
    id: 5
  },
  {
    name: "Product type",
    index: "type",
    id: 6
  },
  {
    name: "Price",
    index: "price_per_unit",
    id: 7
  },
  {
    name: "Actions",
    id: 8,
    render: ({ deleteProduct, edit }) => {
      const settings = [
        {
          name: "Edit",
          style: "edit_tag",
          select: edit
        },
        {
          name: "Delete",
          style: "delete_tag",
          select: deleteProduct
        }
      ];
      return (
        <span style={{ position: "relative" }}>
          <DropdownMenu options={settings} boxClasses="dropdown-style">
            <p style={{ fontSize: "2rem" }} className="cursor-pointer">
              ...
            </p>
          </DropdownMenu>
        </span>
      );
    }
  }
];

export const businessGroupColumn = [
  {
    name: "ID",
    index: "id",
    id: 1
  },
  {
    name: "Business Category",
    index: "business_category",
    id: 2
  },
  {
    name: "Business ID",
    index: "business_code",
    id: 3
  },
  {
    name: "Material Category",
    index: "material_category",
    id: 4
  },
  {
    name: "Material ID",
    index: "material_code",
    id: 5
  },
  {
    name: "Actions",
    id: 6,
    render: ({ deleteProduct }) => {
      const settings = [
        // {
        //   name: "Edit",
        //   style: "edit_tag",
        //   select: edit
        // },
        {
          name: "Delete",
          style: "delete_tag",
          select: deleteProduct
        }
      ];
      return (
        <span style={{ position: "relative" }}>
          <DropdownMenu options={settings} boxClasses="dropdown-style">
            <p style={{ fontSize: "2rem" }} className="cursor-pointer">
              ...
            </p>
          </DropdownMenu>
        </span>
      );
    }
  }
];

export const businessDataColumn = [
  {
    name: "ID",
    index: "id",
    id: 1
  },
  {
    name: "Business Category",
    index: "business_category",
    id: 2
  },
  {
    name: "Parent Business Category",
    index: "parent_category",
    id: 2
  },

  {
    name: "Actions",
    id: 4,
    render: ({ deleteProduct }) => {
      const settings = [
        // {
        //   name: "Edit",
        //   style: "edit_tag",
        //   select: edit
        // },
        {
          name: "Delete",
          style: "delete_tag",
          select: deleteProduct
        }
      ];
      return (
        <span style={{ position: "relative" }}>
          <DropdownMenu options={settings} boxClasses="dropdown-style">
            <p style={{ fontSize: "2rem" }} className="cursor-pointer">
              ...
            </p>
          </DropdownMenu>
        </span>
      );
    }
  }
];

export const refreshColumn = [
  {
    name: "Date",
    index: "date",
    id: 1
  },
  {
    name: "email",
    index: "email",
    id: 2
  },
  {
    name: "Requisition Code",
    index: "req_code",
    id: 3
  },
  {
    name: "Name",
    index: "name",
    id: 4
  }
  // {
  //   name: "Actions",
  //   id: 5,
  //   render: ({ deleteRefresh }) => {
  //     const settings = [
  //       {
  //         name: "Delete",
  //         style: "delete_tag",
  //         select: deleteRefresh
  //       }
  //     ];
  //     return (
  //       <span style={{ position: "relative" }}>
  //         <DropdownMenu options={settings} boxClasses="dropdown-style">
  //           <p style={{ fontSize: "2rem" }} className="cursor-pointer">
  //             ...
  //           </p>
  //         </DropdownMenu>
  //       </span>
  //     );
  //   }
  // }
];
