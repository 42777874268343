import Button from "components/Button";
import DataTable from "components/DataTable";
import VerifyFile from "components/ModalBuyer/VerifyFile";
import Pagination from "components/Pagination";
import SelectDropdown from "components/SelectDropdown";
import StarRating from "components/Star";
import StatsCard from "components/StatsCard";
import Tabs from "components/Tabs";
import Textbox from "components/Textbox";

import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { approveItem, approveItemLoading } from "store/slice/BuyerSlice/ApprovalSlice/ApprovalSlice";
import { doChangeVendorType, getSingleVendor } from "store/slice/BuyerSlice/VendorSlice/VendorSlice";
import { activateUser, deactivateUser, deleteUser, getUsers, getUsersSupplier } from "store/slice/UserMgtSlice/UserMgtSlice";
import { AppDispatch, RootState } from "store/store";
import { checkPermission, toastPopUp } from "utils/utils";
import BankInformation from "./BankInformation";
import ContactInformation from "./ContactInformation";
import { toastr } from "react-redux-toastr";
import { documentsColumns, documents, usersColumn } from "./data";
import MembersBlue from "assests/icons/buyer/members-blue.svg";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import "./styles.scss";
import SearchIcon from "components/SearchBar";
import useSearch from "hooks/useSearch";
import CreateUser from "components/ModalSupplier/CreateOrEditUser";
import { removeZero } from "../../../../utils/utils";
import Remove from "../../../../assests/icons/Remove/Vector (6).svg";
import Add from "../../../../assests/icons/Remove/Vector (5).svg";
import { doAddVendorBusinessCategory, doRemoveVendorBusinessCategory, getAllBusinessCategory } from "store/slice/BusinessCategorySlice/BusinessCategorySlice";
import MapBusinessCategory from "components/ModalBuyer/MapBusinessCategory";
import moment from "moment";
const MasterData = () => {
  //State
  const [page, setPage] = useState<number>(1);
  const [length, setLength] = useState<number>(10);
  const [vendor, setVendor] = useState<string>("");
  const [tabNames, setTabNames] = useState([] as any);
  const [modal, setModal] = useState<boolean>(false);
  const [doc, setDoc] = useState("");
  const [dataId, setDataId] = useState("");
  const [showCategory, setShowCategory] = useState<boolean>(false);

  const history = useHistory();
  const location = useLocation();

  const menus = ["Contact Information", ...tabNames, "Bank Informtion", "Business Category", "Other Information", "Manage Users"];
  const [state, setstate] = useState(menus[0]);
  //Redux Store
  const dispatch = useDispatch<AppDispatch>();
  const { singleVendor } = useSelector((state: RootState) => state.vendorDetails);
  const { loading } = useSelector((state: RootState) => state.approvals);
  const { role_access } = useSelector((state: RootState) => state.user?.user);
  const vendorData = singleVendor?.data[0];
  const { allBusinessCategory } = useSelector((state: RootState) => state.businessCategory);

  // category options

  //Getting Bussiness Categories
  const businessCategoryOption =
    allBusinessCategory &&
    allBusinessCategory.map((_data: { id: string; business_category_description: string }) => {
      return {
        value: _data.id,
        optionName: _data.business_category_description
      };
    });

  useEffect(() => {
    if (vendorData) {
      dispatch(getAllBusinessCategory(undefined, vendorData.business_category_id));
    }
  }, [vendorData]);

  //Hooks
  const { register, handleSubmit, errors, setValue } = useForm<FormData>({ mode: "onBlur" });

  //Tab Handler
  const selectMenu = useCallback((name) => {
    setstate(name);
  }, []);

  //   Helpers
  let { id }: any = useParams();

  // check for ID
  useEffect(() => {
    if (location.state) {
      const state: any = location.state;
      dispatch(getSingleVendor(state, () => setTabNames([])));
    } else {
      history.push("/buyer/suppliers");
    }
  }, []);

  const vendorDataFiles = singleVendor?.data[0]?.vendor_meta;
  useEffect(() => {
    if (vendorData?.vendor_type === "Contractor") {
      setVendor("Contractor");
    } else if (vendorData?.vendor_type === "Supplier") {
      setVendor("Supplier");
    } else setVendor("");
  }, [vendorData]);

  const vendorFiles = vendorDataFiles && vendorDataFiles.filter((file) => file.field_type === "File");

  const tableData =
    vendorFiles &&
    vendorFiles.map((file) => {
      const { category, field_label, expires_on, data_status, vendor_data_id, value } = file;
      return {
        category: category,
        field_label,
        expires_on: expires_on === null ? "" : moment(expires_on).format("L"),
        data_status: data_status === "-1" ? "In Progress" : "Verified",
        link: value
      };
    });

  let test = [] as any;
  let newData = [] as any;
  if (vendorDataFiles && vendorDataFiles.length > 0) {
    vendorDataFiles?.forEach((items, i) => {
      if (items.field_type === "Text") {
        test.push(items);
        if (tabNames.includes(items.category)) {
          return;
        } else setTabNames([...tabNames, items.category as any]);
        return items;
      }
    });

    for (let i = 0; i < tabNames.length; i++) {
      let data = {};
      data = { ...data, header: tabNames[i], array: [] };
      test.forEach((item, i) => {
        if (item.category === data["header"]) {
          data["array"] = [
            ...data["array"],

            {
              label: item.field_label,
              value: item.value
            }
          ];
        }
      });
      newData.push(data);
    }
  }

  useEffect(() => {
    if (vendorDataFiles && vendorDataFiles === 0) {
      setTabNames([]);
    }
  }, [vendorDataFiles]);

  const closeModal = () => {
    setModal(!modal);
  };

  //--------------------------------------------
  //Pagination
  //---------------------------------------------
  const handlePageChange = useCallback((page: number, length: number) => {
    setPage(page);
    dispatch(getSingleVendor(state, () => setTabNames([]), page, length));
  }, []);

  const paginate = useCallback((pageNo: number, length: number) => {
    setPage(pageNo);
    dispatch(getSingleVendor(state, () => setTabNames([]), page, length));
  }, []);

  //------------------------------------------------------------
  //Show Number for PerPage Function
  //------------------------------------------------------------
  const handleNewPage = useCallback((pageNo: number, page = 1) => {
    setLength(pageNo);
    setPage(1);
    dispatch(getSingleVendor(state, () => setTabNames([]), page, length));
  }, []);

  // users management

  const [modal2, setModal2] = useState<boolean>(false);
  const [modal3, setModal3] = useState<boolean>(false);
  const [page2, setPage2] = useState<number>(1);
  const [length2, setLength2] = useState<number>(10);
  const closeModal2 = () => {
    setModal2(!modal2);
  };
  const closeModal3 = () => {
    setModal3(!modal3);
  };
  const [modalData, setModalData] = useState({
    userId: "",
    viewStatus: false,
    editStatus: false
  });
  const { users, isLoading } = useSelector((state: RootState) => state.userMgt);
  console.log(vendorData, "vendorData");
  useEffect(() => {
    dispatch(getUsersSupplier({ page, length: length2, id: vendorData ? vendorData.id : undefined }));
  }, [vendorData]);

  const editUser = (id: string) => {
    // if (!permissions?.includes("USER_EDIT")) {
    //   toastr.error("", "You don't have the Admin rights to do this");
    //   return;
    // }
    closeModal2();
    setModalData({ ...modalData, viewStatus: false, userId: id, editStatus: true });
  };

  const activateUserID = (id: string, name: string) => {
    // if (!permissions?.includes("USER_ACTIVATE")) {
    //   toastr.error("", "You don't have the Admin rights to do this");
    //   return;
    // }
    toastPopUp(
      () => {
        dispatch(
          activateUser({ user_id: id }, () => {
            dispatch(getUsersSupplier({ page, length: length2, id: vendorData ? vendorData.id : undefined }));
          })
        );
      },
      "Activate",
      "Cancel",
      "Activate",
      name,
      "#00B050"
    );
  };

  const deactivateUserID = (id: string, name: string) => {
    // if (!permissions?.includes("USER_DEACTIVATE")) {
    //   toastr.error("", "You don't have the Admin rights to do this");
    //   return;
    // }
    toastPopUp(
      () => {
        dispatch(
          deactivateUser({ user_id: id }, () => {
            dispatch(getUsersSupplier({ page, length: length2, id: vendorData ? vendorData.id : undefined }));
          })
        );
      },
      "Deactivate",
      "Cancel",
      "Deactivate",
      name,
      "#737a91"
    );
  };

  const deleteUserId = (id: string, name: string) => {
    // if (!permissions?.includes("USER_DELETE")) {
    //   toastr.error("", "You don't have the Admin rights to do this");
    //   return;
    // }

    toastPopUp(
      () => {
        dispatch(
          deleteUser(id, () => {
            dispatch(getUsersSupplier({ page, length: length2, id: vendorData ? vendorData.id : undefined }));
          })
        );
      },
      "Delete",
      "Cancel",
      "Delete the user",
      name,
      "red"
    );
  };

  const { search, inputRef, handleChange, searched } = useSearch(getUsersSupplier, length, vendorData ? vendorData.id : undefined, ["company"]);
  const handlePageChange2 = useCallback(
    (page: number, length: number) => {
      setPage2(page);
      dispatch(getUsersSupplier({ page, length: length2, search, id: vendorData ? vendorData.id : undefined }));
    },
    [search]
  );

  const paginate2 = useCallback(
    (pageNo: number, length: number) => {
      setPage(pageNo);
      dispatch(getUsersSupplier({ page: pageNo, length, search, id: vendorData ? vendorData.id : undefined }));
    },
    [search]
  );

  const userList =
    users &&
    users?.data?.map((data: any) => {
      let status;
      if (data.user_status === "1") {
        status = "Active";
      } else if (data.user_status === "0") {
        status = "Inactive";
      } else if (data.user_status === "-1") {
        status = "Unapproved";
      }
      return {
        firstName: data.othernames,
        lastName: data.lastname,
        phoneNo: data.phone_number,
        email: data.username,
        role: data.user_role_name,
        // toggleDropdown: handleTableDropdown,
        userId: data.id,
        status,
        // tableID: tableID,
        // view: () => viewUser(data.id),
        edit: () => checkPermission(role_access, "USERS_EDIT") && editUser(data.id),
        deleteUserId: () => checkPermission(role_access, "USERS_DELETE") && deleteUserId(data.id, data.othernames),
        activateUserID: () => checkPermission(role_access, "USERS_ACTIVATE") && activateUserID(data.id, data.othernames),
        deactivateUserID: () => checkPermission(role_access, "USERS_DEACTIVATE") && deactivateUserID(data.id, data.othernames)
      };
    });
  const checkPermission2 = (permissions: any, permission: string): boolean => {
    const userPerm = permissions.map((data) => data.permission_name);
    if (!userPerm?.includes(permission)) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <div className="master-data">
      {modal && <VerifyFile closeModal={closeModal} docName={doc} data_id={dataId} id={id} />}
      {modal2 && <CreateUser vendId={vendorData ? vendorData.id : undefined} typeUser="buyer" closeModal={closeModal2} modalData={modalData} />}
      {modal3 && <MapBusinessCategory count={vendorData && vendorData?.categories?.length} vendorData={vendorData} closeModal={closeModal3} vendorId={vendorData && vendorData.id} />}
      <div className="container">
        <div className="d-flex align-items-center mb-3">
          <h6 className="page-title m-0">
            {vendorData && vendorData?.vendor_description} {vendorData && vendorData?.vendor_code ? "|" : ""} {vendorData && removeZero(vendorData?.vendor_code)}
          </h6>
          <StarRating stars={vendorData && vendorData?.overall_ratings} disabled={true} />
        </div>
        <div className="tab-container">
          <Tabs menus={menus} onSelect={selectMenu} />

          <div className="tabs-page pb-5">
            {state === "Contact Information" && (
              <div className="route-transition">
                <ContactInformation vendorData={vendorData} />
              </div>
            )}

            {state === "Bank Informtion" && (
              <div className="route-transition">
                <BankInformation />
              </div>
            )}
            {newData.map((tab, i) => {
              return (
                state === tab.header && (
                  <div className="route-transition" key={i}>
                    <form>
                      <div className="row">
                        {tab.array.map((textBox, i) => {
                          return (
                            <div className="col-md-12 col-lg-6" key={i}>
                              <Textbox disabled={true} name="farmer_id" value={textBox.value} label={textBox.label} boxClasses="mt-3" />
                            </div>
                          );
                        })}
                      </div>
                    </form>
                  </div>
                )
              );
            })}

            {state === "Business Category" && (
              <div className="route-transition">
                <form>
                  <div className="row cat_sub ">
                    <div className="first_side">
                      <p className="topic">Parent Category</p>
                      <div className="col-md-12  d-flex col-lg-6 pl-0 wider cover_btn_change">
                        <Textbox value={vendorData && vendorData?.business_category_description} placeholder="Businees Field" boxClasses="mt-2" disabled={true} />
                        {/* <img src={Remove} alt="" className="pl-3 cursor-pointer" /> */}
                        {checkPermission2(role_access, "UPDATE_VENDOR_CATEGORY") ? (
                          <div
                            onClick={() => {
                              checkPermission(role_access, "UPDATE_VENDOR_CATEGORY") && closeModal3();
                            }}
                            className="btn_change"
                          >
                            Manage
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="second_side mt-4">
                      {vendorData && vendorData?.categories?.length ? (
                        <>
                          <p className="topic">Sub Category</p>
                          {vendorData?.categories?.map((biz, i) => {
                            return (
                              <div className="col-md-12 col-lg-6 d-flex pl-0 wider" key={i}>
                                <Textbox value={biz.business_category_description} placeholder="Businees Field" boxClasses="mt-2" disabled={true} />
                                {/* <img
                              src={Remove}
                              alt=""
                              className="pl-3 cursor-pointer"
                              onClick={() =>
                                dispatch(doRemoveVendorBusinessCategory({ vendor_id: vendorData.id, business_category_id: biz.business_category_id }, () => dispatch(getSingleVendor(vendorData.id))))
                              }
                            /> */}
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* <div className="d-flex d-flex mt-3 cursor-pointer" onClick={() => setShowCategory(!showCategory)}>
                      <img src={Add} alt="" />
                      <p> {showCategory ? "Hide  Category" : "Add Category"}</p>
                    </div> */}
                    {showCategory && (
                      <div className="col-6 d-flex">
                        <SelectDropdown
                          boxClasses="mt-3"
                          name="business_category_id"
                          label="Business Category"
                          placeholder="Business Category"
                          options={businessCategoryOption}
                          customRef={register({
                            // required: { value: requiredForm, message: "This field is required" }
                          })}
                          onChange={(e) =>
                            dispatch(
                              doAddVendorBusinessCategory({ vendor_id: vendorData.id, business_category_id: e.target.value }, () => {
                                dispatch(getSingleVendor(vendorData.id));
                                setValue("business_category_id", "");
                                setShowCategory(false);
                              })
                            )
                          }
                          // error={errors.business_category_id && errors.business_category_id.message}
                        />
                        {/* <ArrowDropdown text={placeholder} label="Business Categories" error={businessFieldError && businessFieldError} options={businessList} boxClasses="mt-3" {...rest} search={true} /> */}
                        {/* <Button type="button" label="Confirm" btnType="btn-primary" xtraClass="w-100 px-2" boxClasses="pl-3 mt-auto"  />
                      <Button type="button" label="Cancel" btnType="btn-skeleton" xtraClass="w-100 px-2" boxClasses="pl-3 mt-auto"  /> */}
                      </div>
                    )}
                  </div>
                </form>
              </div>
            )}

            {state === "Other Information" && (
              <div className="route-transition">
                <form>
                  <div className="row">
                    <div className="col-md-12 col-lg-6 mt-4">
                      <p className="page-title" style={{ fontSize: "20px" }}>
                        Supplier Selection
                      </p>
                      <div className="d-flex radio-box mt-4 justify-content-between">
                        <div className="">
                          <input
                            type="radio"
                            name="certified"
                            id="yes"
                            className="mr-2"
                            checked={vendor === "Contractor"}
                            // onChange={() => vendorTypeChange("Contractor")}
                          />
                          <label className="radio-box_label" htmlFor="yes">
                            Contractor
                          </label>
                        </div>
                        <div className="">
                          <input
                            type="radio"
                            name="certified"
                            id="no"
                            className="mr-2"
                            checked={vendor === "Supplier"}
                            // onChange={() => vendorTypeChange("Supplier")}
                          />
                          <label className="radio-box_label" htmlFor="no">
                            Supplier
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
            {state === "Manage Users" && (
              <div className="container">
                <div className="row mt-3 mb-5">
                  <StatsCard statsNumber={users?.count} text="Users" classes="stats-icon" image={MembersBlue} iconBackgroundColor="rgba(45, 156, 219, 0.1)" />
                  {/* <StatsCard image={AddIcon} classes="middle-img" centerText="Create User" onClick={createUser} /> */}
                </div>
                <h4 className="page-title mb-2">User Logs</h4>
                <div className="search-field">
                  <SearchIcon placeholder="Search for First name, Phone no. or email" boxClasses=" mb-3" textRef={inputRef} value={search} onChange={handleChange} />
                  {/* <Dropdown perPage={length} action={handleNewPage} /> */}
                </div>

                <DataTable columns={usersColumn} data={userList} loading={isLoading} />
                <Pagination page={page2} lastPage={users?.pagination?.pageCount} paginate={paginate2} total={users?.pagination?.total} handlePageChange={handlePageChange2} perPage={length2} />
              </div>
            )}
          </div>
        </div>

        {state !== "Manage Users" && (
          <>
            {" "}
            <div className="mt-5">
              <DataTable columns={documentsColumns} data={tableData} />
              {/* <Pagination page={0} lastPage={0} paginate={() => {}} total={0} handlePageChange={() => {}} perPage={0} /> */}
              <Pagination page={page} lastPage={singleVendor?.pagination?.pageCount} paginate={paginate} total={singleVendor?.pagination?.total} handlePageChange={handlePageChange} perPage={length} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MasterData;
