import React, { useState, useEffect } from "react";
import Modal from "components/Modal";
import Button from "components/Button";
import "./styles.scss";
import { AppDispatch, RootState } from "store/store";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { contactRegistration } from "store/slice/SupplierSlices/RegistrationSlice/RegistrationSlice";
import { onBoardVendorPayload } from "store/slice/SupplierSlices/RegistrationSlice/interface";

type Props = {
    closeModal: () => void;
    data?: {};
};

const SelectLegalEntity = ({ closeModal, data }: Props) => {
    //State
    const [selectedoption, setSelectedoption] = useState<string | null>(null);
    const [error, setError] = useState("");

    //Hook
    let history = useHistory();

    //Redux Store
    const dispatch = useDispatch<AppDispatch>();
    const { isLoading, companies } = useSelector((state: RootState) => state.vendorRegistration);

    const handleChange = (event: { target: { value: React.SetStateAction<string | null> } }) => {
        setSelectedoption(event.target.value);
        setError("");
    };

    const onFormSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();

        if (!selectedoption) {
            return setError("Please Select an Entity");
        }

        const payload = {
            company_id: selectedoption,
            user_role_id: "4000002",
            ...data
        };

        dispatch(
            contactRegistration(payload, history, () => {
                closeModal();
            })
        );
    };

    return (
        <Modal modalText="Select Legal Entity" onClose={closeModal}>
            <div id="supplier-create-entity-modal">
                <p className="">Which GZI entity do you want to register with? </p>
                <form className="mt-3" onSubmit={onFormSubmit}>
                    {companies &&
                        companies.map((data) => (
                            <div key={data?.id}>
                                <input
                                    type="checkbox"
                                    value={data?.id}
                                    id={data?.company_description}
                                    className="mr-2"
                                    name="entity"
                                    checked={selectedoption === data?.id}
                                    onChange={handleChange}
                                />
                                <label htmlFor={data?.company_description} className="cursor-pointer">
                                    {data?.company_description}
                                </label>
                            </div>
                        ))}

                    {/* <div>
                        <input
                            type="checkbox"
                            value="kenya"
                            id="kenya"
                            className="mr-2"
                            name="entity"
                            checked={selectedoption === "kenya"}
                            onChange={handleChange}
                        />
                        <label htmlFor="kenya" className="cursor-pointer">
                            GZI Kenya
                        </label>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            value="south"
                            id="south"
                            className="mr-2"
                            name="entity"
                            checked={selectedoption === "south"}
                            onChange={handleChange}
                        />
                        <label htmlFor="south" className="cursor-pointer">
                            GZI South Africa
                        </label>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            value="sister"
                            id="sister"
                            className="mr-2"
                            name="entity"
                            checked={selectedoption === "sister"}
                            onChange={handleChange}
                        />
                        <label htmlFor="sister" className="cursor-pointer">
                            Sister Company
                        </label>
                    </div> */}
                    <p className={`${error ? "d-block" : "d-none"} error mt-1 mb-1`}>{error}</p>
                    <Button
                        label="Continue"
                        type="submit"
                        btnType="btn-primary"
                        boxClasses="mt-4 mb-5"
                        btnStyle="100%"
                        loading={isLoading}
                    />
                </form>
            </div>
        </Modal>
    );
};

export default SelectLegalEntity;
