import { Link } from "react-router-dom";

import greenCheck from "assests/icons/buyer/green-check.svg";

import "./styles.scss";
import HoverEffect from "./hover";

export const tableCol = [
  // {
  //     name: "",
  //     index: "",
  //     id: 1,
  //     render: (item: { invoice_status: string }) => (
  //         <>{item.invoice_status === "Verified" && <img src={greenCheck} className="avatar" alt="icon" />}</>
  //     )
  // },
  {
    name: "Invoice Date",
    index: "invoice_date",
    id: 2
  },
  {
    name: "Vendor Name",
    index: "vendor_name",
    id: 3
  },
  {
    name: "PO No.",
    index: "po_number",
    id: 4
  },
  {
    name: "Invoice No.",
    index: "invoice_number",
    id: 5
  },
  {
    name: "Supplier Ref.",
    index: "supp_no",
    id: 5
  },
  {
    name: "Amount",
    index: "invoice_amount",
    id: 6
  },
  {
    name: "SAP Invoice",
    // index: "sap_invoice",
    id: 8,
    render: (item) => {
      if (item.sap_error) {
        return <HoverEffect error={item.sap_error ? item.sap_error : ""} />;
      } else {
        return <p>{item.sap_invoice}</p>;
      }
    }
  },
  {
    name: "Status",
    id: 7,
    render: (item) => (
      <div>
        {/* {item.invoice_status === "Posted" ? (
          <button
            type="button"
            // style={{
            //   padding: "8px 16px",
            //   background: "rgba(0, 70, 176, 0.15)",
            //   borderRadius: "10px",
            //   color: "rgba(47, 128, 237, 1)"
            // }}
            className={`status ${item.btnClass}`}
          >
            {item.invoice_status}
          </button>
        ) : ( */}
        <button
          type="button"
          // style={{
          //   padding: "8px 16px",
          //   background: item.invoice_status !== "Approved" ? "rgba(245, 150, 37, 0.15)" : "rgba(0, 176, 7, 0.15)",
          //   borderRadius: "10px",
          //   color: item.invoice_status !== "Approved" ? "rgba(245, 150, 37, 1)" : "#387C57"
          // }}
          className={`status ${item.btnClass}`}
        >
          {item.invoice_status}
        </button>
        {/* )} */}
      </div>
    )
  }
  // {
  //     name: "Action",
  //     id: 9,
  //     render: (item) => (
  //         <Link to={`/buyer/invoice/${item.id}`}>
  //             <button
  //                 type="button"
  //                 style={{
  //                     padding: "8px 16px",
  //                     background: "rgba(0, 70, 176, 0.15)",
  //                     borderRadius: "10px",
  //                     color: "rgba(47, 128, 237, 1)",
  //                 }}
  //             >
  //                 View
  //             </button>
  //         </Link>
  //     )
  // }
];

export const tableData = [
  {
    invoice_date: "2nd Sept. 2019",
    vendor_name: "Brent Fawaz",
    po_number: "123333",
    invoice_number: "123333",
    invoice_amount: "3000",
    invoice_error: "",
    invoice_tracker: "Internal Control",
    status: "Verified",
    id: 1
  },
  {
    invoice_date: "24th May 2019",
    vendor_name: "Chandler Ross",
    po_number: "112233",
    invoice_number: "123333",
    invoice_amount: "3I2388",
    invoice_error: "IT Services",
    invoice_tracker: "Audit Processing",
    status: "Verified",
    id: 1
  },
  {
    invoice_date: "5th Jun. 2019",
    vendor_name: "Monica Williams",
    po_number: "112233",
    invoice_number: "112233",
    invoice_amount: "256389",
    invoice_error: "Consulting",
    invoice_tracker: "F&A",
    status: "Pending",
    id: 1
  },
  {
    invoice_date: "7th July 2020",
    vendor_name: "Asa Okoro",
    po_number: "738932",
    invoice_number: "738932",
    invoice_amount: "6356782",
    invoice_error: "Consulting",
    invoice_tracker: "F&A",
    status: "Verified",
    id: 1
  },
  {
    invoice_date: "19th Aug. 2020",
    vendor_name: "Jamal Abdul",
    po_number: "11233123",
    invoice_number: "11233123",
    invoice_amount: "24562",
    invoice_error: "General Contractor",
    invoice_tracker: "Payment Processing",
    status: "Pending",
    id: 1
  },
  {
    invoice_date: "22nd Feb. 2020",
    vendor_name: "Sani Abubakar",
    po_number: "43637.",
    invoice_number: "43637.",
    invoice_amount: "78992",
    invoice_error: "IT Services",
    invoice_tracker: "verifying",
    status: "Pending",
    id: 1
  }
];
