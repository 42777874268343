import StatsCard from "components/StatsCard";
import Icon from "assests/icons/statsCard/Vector (4).png";
import greenCheck from "assests/icons/buyer/green-check.svg";
import rotate from "assests/icons/buyer/rotate 1.svg";
import purpleHands from "assests/icons/buyer/purple-hands.svg";
import SearchBar from "../../../components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { tableCol, tableData } from "./data";
import { AppDispatch, RootState } from "store/store";
import "./styles.scss";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import useSearch from "hooks/useSearch";
import React, { useEffect } from "react";
import SearchIcon from "../../../components/SearchBar";
import Pagination from "components/Pagination";
import { getAllrfpData, getAllrfpDataDownload, getAllRfpDownload, getSummary } from "store/slice/BuyerSlice/EvaluationSlice/EvaluationSlice";
import FilterCard from "components/FilterCard";
import { useForm } from "react-hook-form";
import filterIcon from "assests/icons/buyer/filter-icon.svg";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const FilterContainer = styled.div`
  .mySelect__control {
    border-radius: 10px;
    border: 1px solid rgb(223, 226, 230) !important;
  }

  .mySelect__value-container {
    height: 3rem;
  }
`;

const RFxEvaluation = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const dispatch = useDispatch<AppDispatch>();
  const { rfps, rfpsDownloadList, summary, loading } = useSelector((state: RootState) => state.rfpData);
  const [startDate, setStartDate] = useState("");
  const [status, setStatus] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const { register, handleSubmit, reset, control } = useForm({
    mode: "onChange"
    // defaultValues: {
    //   start_date: '',
    //   end_date: '',
    //   from: '',
    //   to: '',
    // }
  });

  const onSubmit = (vals) => {
    setStartDate(vals.date_from);
    setEndDate(vals.date_to);
    setStatus(vals.rfp_status);

    const data = {
      ...vals
    };
    console.log(data);
    dispatch(getAllrfpData(page, perPage, search, undefined, undefined, undefined, undefined, undefined, "Yes", data.date_from, data.date_to, data.rfp_status));
  };

  const handleClear = () => {
    setStartDate("");
    setEndDate("");
    setStatus("");
    dispatch(getAllrfpData(page, perPage, search, undefined, undefined, undefined, undefined, undefined, "Yes"));
  };

  const history = useHistory();

  //Hook
  const { search, inputRef, handleChange, searched } = useSearch(getAllrfpData, perPage, undefined, [undefined, undefined, undefined, undefined, undefined, "Yes", startDate, endDate, status]);

  //Show Number for PerPage Request
  const handleNewPage = (pageNo: number, page: number = 1) => {
    setPerPage(pageNo);
    setPage(page);
    dispatch(getAllrfpData(page, pageNo, search, undefined, undefined, undefined, undefined, undefined, "Yes", startDate, endDate, status));
  };

  // setpage to the first page when a searched executed
  useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  useEffect(() => {
    dispatch(getAllrfpData(page, perPage, undefined, undefined, undefined, undefined, undefined, undefined, "Yes"));
    dispatch(getAllrfpDataDownload());
    dispatch(getSummary("3"));
  }, []);

  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, perPage: number) => {
      setPage(page);
      dispatch(getAllrfpData(page, perPage, search, undefined, undefined, undefined, undefined, undefined, "Yes", startDate, endDate, status));
    },
    [search, status, startDate, endDate]
  );

  const paginate = React.useCallback(
    (pageNo: number, perPage: number) => {
      setPage(pageNo);
      dispatch(getAllrfpData(pageNo, perPage, search, undefined, undefined, undefined, undefined, undefined, "Yes", startDate, endDate, status));
    },
    [search, status, startDate, endDate]
  );

  // const view = (id: String,type:String) => {
  //   console.log(type)
  //   if (type==="RFX") {
  //     history.push(`/buyer/rfx-evaluation/evaluation/commercial/?id=${id}&rfpType=${"rfq"}`);
  //   }
  //   else{
  //     history.push(`/buyer/rfx-evaluation/evaluation/technical/?id=${id}&rfpType=${"rfp"}`);
  //   }

  // };

  const view = (e, item) => {
    if (item.rfx_type === "RFQ") {
      window.location.href = `/buyer/rfx-evaluation/evaluation/commercial/?id=${item.id}&rfpType=${"rfq"}`;
    } else {
      window.location.href = `/buyer/rfx-evaluation/evaluation/technical/?id=${item.id}&rfpType=${"rfp"}`;
    }
  };

  const rfpList =
    rfps &&
    rfps?.data?.map((item) => {
      return {
        id: item.id,
        team_lead: `${item.team_lead_othernames} ${item.team_lead_lastname}`,
        project_id: item.rfp_code,
        description: item.rfp_title,
        requisition_code: item.requisition_code,
        Submitted_quotes: item.total_submissions,
        invited_vendors: item.total_invited_vendors,
        rfp_status: item.rfp_status,
        rfx_type: item.rfx_type
        // status: () => view(item)
      };
    });

   

const determineRfpStatus = (status: string) => {
  switch (status) {
    case "1":
      return "Approved";
    case "-2":
      return "Draft";
    case "-1":
      return "Pending";
      case "4":
      return "Awarded";
    case "5":
      return "In Negotiation";
    case "3":
      return "Selection";
    default:
      return "Draft";
  }
};


    const rfpListDownload =
    rfpsDownloadList &&
    rfpsDownloadList?.data?.map((item) => {
      return {
        id: item.id,
        team_lead: `${item.team_lead_othernames} ${item.team_lead_lastname}`,
        project_id: item.rfp_code,
        description: item.rfp_title,
        requisition_code: item.requisition_code,
        Submitted_quotes: item.total_submissions,
        invited_vendors: item.total_invited_vendors,
        rfp_status: determineRfpStatus(item.rfp_status),
        rfx_type: item.rfx_type
        // status: () => view(item)
      };
    });

  // const newRfpList =
  //   rfpList &&
  //   rfpList.filter((item) => {
  //     console.log(item.rfp_status);
  //     return item.rfp_status === "3";
  //   });

  return (
    <div id="evaluation">
      <div className="container">
        <h3 className="header">Vendor Selection</h3>
        <div className="stats-card mt-4 row">
          <StatsCard text="In Selection Process" classes="stats-icon-blue" image={rotate} statsNumber={summary?.data && summary?.data?.in_selection?.total_rfps} />
          <StatsCard text="In Negotiations" classes="stats-icon-purple" image={purpleHands} statsNumber={summary?.data && summary?.data?.in_negotiation?.total_rfps} />
          <StatsCard text="Awarded" classes="stats-icon-green" image={greenCheck} statsNumber={summary?.data && summary?.data?.awarded?.total_rfps} />
        </div>

        {showFilter && (
          <FilterContainer>
            <FilterCard handleSubmit={handleSubmit(onSubmit)} clear={handleClear}>
              <div className="row">
                <div className="col col-4">
                  <label style={{ display: "block" }}>Start Date</label>
                  <input
                    type="date"
                    name="date_from"
                    placeholder="Start Date"
                    style={{
                      width: "100%",
                      height: "3rem",
                      padding: "5px",
                      borderRadius: "10px",
                      border: "1px solid #dfe2e6"
                    }}
                    ref={register({ required: false })}
                  />
                </div>
                <div className="col col-4">
                  <label style={{ display: "block" }}>End Date</label>
                  <input
                    type="date"
                    name="date_to"
                    placeholder="End Date"
                    style={{
                      width: "100%",
                      height: "3rem",
                      padding: "5px",
                      borderRadius: "10px",
                      border: "1px solid #dfe2e6"
                    }}
                    ref={register({ required: false })}
                  />
                </div>
                <div className="col col-4">
                  <label style={{ display: "block" }}>Status</label>
                  <select
                    style={{
                      width: "100%",
                      height: "3rem",
                      padding: "5px",
                      borderRadius: "10px",
                      border: "1px solid #dfe2e6"
                    }}
                    name="rfp_status"
                    ref={register({
                      required: false
                    })}
                  >
                    <option value="">Select</option>
                    {/* <option value="-2">Draft</option>
                    <option value="-1">Pending</option>
                    <option value="1">Approved</option> */}
                    <option value="3">Selection</option>
                    <option value="4">Awarded</option>
                    <option value="5">In Negotiation</option>
                  </select>
                </div>
              </div>
            </FilterCard>
          </FilterContainer>
        )}

        <div className="d-flex  mb-3 mt-4 " style={{ justifyContent: "space-between" }}>
          <SearchIcon placeholder="Search for ID or Discription" boxClasses="mb-3 width80" textRef={inputRef} value={search} onChange={handleChange} />
          <div className="d-flex align-items-center">
            <button
              style={{
                padding: "5px 10px",
                borderRadius: "10px",
                background: "#FFFFFF",
                color: "#737A91",
                border: "1px solid #DFE2E6",
                fontSize: "18px",
                marginLeft: "1rem",
                marginRight: "1rem",
                width: "max-content"
              }}
              onClick={() => setShowFilter(!showFilter)}
            >
              <img src={filterIcon} alt="icon" className="icon-image" />
              <span
                style={{
                  paddingLeft: "1rem"
                }}
              >
                {!showFilter ? "Filters" : "Hide Filters"}
              </span>
            </button>
            <Dropdown perPage={perPage} action={handleNewPage} />
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button btn btn-success ml-4"
                table="table-to-xls"
                filename="Evaluation"
                sheet="tablexls"
                buttonText="Download"
              />
          </div>
        </div>

        <div style={{ display: "none" }} className="downloadSide">
            <table id="table-to-xls">
              <thead>
                <th>Project ID</th>
                <th>Requisition Number</th>
                <th>Description</th>
                <th>Team Lead</th>
                <th>Submitted Quotes</th>
                <th>Invited Vendors</th>
                <th>Status</th>
              </thead>
              <tbody>
                {rfpListDownload &&
                  rfpListDownload.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td>{item.project_id}</td>
                        <td>{item.requisition_code}</td>
                        <td>{item.description}</td>
                        <td>{item.team_lead}</td>
                        <td>{item.Submitted_quotes}</td>
                        <td>{item.invited_vendors}</td>
                        <td>{item.rfp_status}</td>
                      </tr>
                    );
                  })}
                <tr></tr>
              </tbody>
            </table>
          </div>

        <div className="mt-4">
          <DataTable columns={tableCol} data={rfpList} onClick={view} loading={loading} />
        </div>

        {rfps?.count > 10 ? (
          <Pagination page={page} lastPage={rfps?.pagination?.lastPage} paginate={paginate} total={rfps?.pagination?.total} handlePageChange={handlePageChange} perPage={perPage} />
        ) : null}
      </div>
    </div>
  );
};

export default RFxEvaluation;
