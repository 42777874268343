import React, { useEffect, useState } from "react";
import Modal from "components/Modal";
import Button from "components/Button";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import FileUploaderBox from "components/FileUploaderBox";

import { PaymentRef, uploadFileProposal } from "store/slice/BuyerSlice/PostedInvoiceSlice/PostedInvoiceSlice";

type Props = {
  closeModal: any;
  id?: any;
  proposal_id: any;
};
const UploadProposal = ({ closeModal, id, proposal_id }: Props) => {
  const { register, handleSubmit, errors, control, setValue } = useForm<FormData>({
    mode: "onChange"
  });

  //State
  const [files, setFiles] = useState<any>([]);
  const [uploadBool, setUploadBool] = useState(false);

  const checkUpload = () => {
    setUploadBool(!uploadBool);
  };

  type FormData = {};

  //Redux Store
  const dispatch = useDispatch<AppDispatch>();

  const { s3urlProposal, isLoading } = useSelector((state: RootState) => state.postedInvoice);
  const { user } = useSelector((state: RootState) => state.user);
  const { token } = user;

  const onFormSubmit = (vals) => {
    const { company_logo_url, ...rest } = vals;
    const payload = {
      ...rest,
      payment_file_ref: s3urlProposal,
      proposal_id,
      paying_bank_id: id
    };
    console.log(payload);
    dispatch(PaymentRef(payload));
  };

  const handleFile = (e, field_name: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(e);
    reader.onloadend = () => {
      // use a regex to remove data url part
      let read: any = reader.result;
      const payload = {
        base64_data: read.replace("data:", "").replace(/^.+,/, ""),
        file_extension: e.name.split(".").pop()
      };
      dispatch(uploadFileProposal(payload));

      setFiles(payload);
    };
  };

  return (
    <Modal modalText={"Upload"} onClose={closeModal} modallength="modal-length">
      <div id="buyer-legal-entity">
        <p className="">Upload File</p>

        <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
          <FileUploaderBox
            placeholder="File"
            fileName="payment_file_ref"
            boxClasses="mt-3 mb-2"
            label="File"
            handleFile={handleFile}
            acceptFiles={".jpg, .jpeg, .png"}
            customRef={register({ required: true })}
            error={errors["logo"] && "This field is required"}
          />

          <Button label={"Submit"} btnType="btn-primary" boxClasses="mt-5 mb-5" btnStyle="100%" loading={isLoading} />
        </form>
      </div>
    </Modal>
  );
};

export default UploadProposal;
