import Document from "assests/icons/sidebar/inventory.svg";
import Button from "components/Button/index";
import "./styles.scss";
import { Link } from "react-router-dom";
import DropdownMenu from "components/DropdownMenu";

export const documentsColumns = [
  // {
  //     name: "",
  //     index: "",
  //     id: 1,
  //     render: () => (
  //         <span>
  //             <img src={Document} className="avatar" alt="icon" />
  //         </span>
  //     )
  // },
  {
    name: "Category Type",
    index: "category",
    id: 1
  },
  {
    name: " Category Description",
    // index: "field_label",
    id: 2,
    render: ({ link, field_label }) => {
      return (
        <a target="_blank" rel="noreferrer" href={link} className="hover-a">
          {field_label}
        </a>
      );
    }
  },
  {
    name: "Expiry Date",
    index: "expires_on",
    id: 3
  },
  // {
  //   name: "Status",
  //   index: "data_status",
  //   id: 4
  // },
  {
    name: "Status",
    id: 5,
    render: (item) => {
      return (
        <button
          type="button"
          style={{
            padding: "8px 16px",
            width: "150px",
            background: !item.link ? "#FFAEBC" : item.data_status === "Verified" ? "rgba(0, 176, 7, 0.15)" : "rgba(0, 70, 176, 0.15)",
            borderRadius: "10px",
            color: !item.link ? "white" : item.data_status === "Verified" ? "#387C57" : "rgba(47, 128, 237, 1)"
          }}
          // onClick={openVerify}
        >
          {item.link ? item.data_status : "Not Provided"}
        </button>
      );
      //   </Link>
    }
  }
  // {
  //   name: "Action",
  //   id: 5,
  //   render: (item) => {
  //     //   <Link to={`/supplier/invoice/100`}>
  //     let btnText = "Verify";
  //     if (item.data_status !== "In Progress") {
  //       btnText = "Done";
  //     }
  //     const openVerify = () => {
  //       console.log(item.data_status === "Verify");
  //       if (btnText === "Done") {
  //         return;
  //       } else {
  //         item.open();
  //       }
  //     };
  //     return (
  //       <button
  //         type="button"
  //         style={{
  //           padding: "8px 16px",
  //           background: item.data_status === "Verified" ? "rgba(0, 176, 7, 0.15)" : "rgba(0, 70, 176, 0.15)",
  //           borderRadius: "10px",
  //           color: item.data_status === "Verified" ? "#387C57" : "rgba(47, 128, 237, 1)"
  //         }}
  //         // onClick={openVerify}
  //       >
  //         {btnText}
  //       </button>
  //     );
  //     //   </Link>
  //   }
  // }
];

export const documents = [
  {
    doc_no: "45000008910",
    description: "Apple Iphone 12 Max, 64GB",
    expiry: "03.03.2021",
    amount: "N4,800,000",
    status: "Delivered",
    id: 1
  },
  {
    doc_no: "45000008233",
    description: "In-house software development",
    expiry: "03.03.2021",
    amount: "N34,120,000",
    status: "In Progress",
    id: 2
  },
  {
    doc_no: "45000008738",
    description: "Computer Sales",
    expiry: "03.03.2021",
    amount: "N5,560,900",
    status: "PO Received",
    id: 3
  },
  {
    doc_no: "45000008910",
    description: "Office Supplies",
    expiry: "03.03.2021",
    amount: "N78,900",
    status: "Out for delivery",
    id: 4
  }
];

export const usersColumn = [
  {
    name: "First Name",
    index: "firstName",
    id: 1
  },
  {
    name: "Last Name",
    index: "lastName",
    id: 2
  },
  {
    name: " Phone Number",
    index: "phoneNo",
    id: 3
  },
  {
    name: "Email",
    index: "email",
    id: 4
  },
  {
    name: "Status",
    index: "status",
    id: 5
  },
  {
    name: "Role",
    index: "role",
    id: 6
  },
  {
    name: "Actions",
    id: 7,
    render: ({ toggleDropdown, userId, tableID, view, edit, deleteUserId, status, activateUserID, deactivateUserID }) => {
      const settings = [
        // {
        //   name: "View",
        //   style: "activate_tag",
        //   select: view
        // },
        {
          name: `${status === "Pending" || status === "Inactive" ? "Activate" : "Deactivate"}`,
          style: `${status === "Pending" ? "activate_tag" : "disable_tag"}`,
          select: status === "Pending" || status === "Inactive" ? activateUserID : deactivateUserID
        },

        {
          name: "Edit",
          style: "activate_tag",
          select: edit
        },
        {
          name: "Delete",
          style: "delete_tag",
          select: deleteUserId
        }
      ];
      return (
        <span>
          <DropdownMenu options={settings} boxClasses="dropdown-style">
            <p style={{ fontSize: "2rem" }} className="cursor-pointer" id={userId}>
              ...
            </p>
          </DropdownMenu>
        </span>
      );
    }
  }
];
