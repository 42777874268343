import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { approvalState, Action } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";

const initialState: approvalState = {
  loading: false,
  approvalStep: null,
  isLoading:false,
  successMessage: "",
  errorMessage: "",
  deleting:false,
};

const approvalSlice = createSlice({
  name: "approvalStep",
  initialState,
  reducers: {
    getAllApprovalStepsLoading: (state) => {
      state.loading = true;
    },
    getAllApprovalStepSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.approvalStep = action.payload;
    },
    getAllApprovalFail: (state) => {
      state.loading = false;
    },
    approveItemLoading: (state) => {
      state.loading = true;
    },
    approveItemSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.successMessage = action.payload;
    },
    approveItemFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    createWorkFlowLoading: (state) => {
      state.isLoading = true;
    },
    createtWorkFlowSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.approvalStep = action.payload;
    },
    createWorkFlowFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    editWorkFlowLoading: (state) => {
      state.isLoading = true;
    },
    editWorkFlowSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.approvalStep = action.payload;
    },
    editWorkFlowFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    deleteWorkFlowLoading: (state) => {
      state.deleting = true;
    },
    deleteWorkFlowSuccess: (state, action: PayloadAction<Action>) => {
      state.deleting = false;
      state.approvalStep = action.payload;
    },
    deleteWorkFlowFail: (state, action: PayloadAction<string>) => {
      state.deleting = false;
      state.errorMessage = action.payload;
    }
  }
});

const { actions, reducer } = approvalSlice;
export const {
  getAllApprovalStepsLoading, 
  getAllApprovalStepSuccess, 
  getAllApprovalFail, 
  approveItemFail, 
  approveItemLoading, 
  approveItemSuccess,
  createWorkFlowLoading,
  createtWorkFlowSuccess,
  createWorkFlowFail,
  editWorkFlowLoading,
  editWorkFlowSuccess,
  editWorkFlowFail,
  deleteWorkFlowLoading,
  deleteWorkFlowSuccess,
  deleteWorkFlowFail,
 } = actions;

 type getApprovalSteps = {
  page?: number,
  length?: number,
  id?: string,
  search?: string,
  activity_name?: string,
  approval_status?: string
 }



  export const getApprovalStep =
  ({page, length, id, search,  activity_name, approval_status}:getApprovalSteps): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getAllApprovalStepsLoading());
    try {
      const response = await API.get(`/approval_workflow_steps/get/${id}`, {
        params: {
          activity_name,
          length,
          search,
          approval_status,
          page
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getAllApprovalStepSuccess(response.data));
      }
    } catch (error) {
      dispatch(getAllApprovalFail());
    }
  };
  export const createApprovalStep =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(createWorkFlowLoading());
    try {
      const response = await API.post(`/approval_workflow_steps/create`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(createtWorkFlowSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      
      if (error.response) {
        dispatch(createWorkFlowFail(error.response.data?.message));
      toastr.error(error.response.data.message);
      }
      else{
        dispatch(createWorkFlowFail("No network connecton"));
        toastr.error("No network connecton");
      }
      setTimeout(() => {
        dispatch(createWorkFlowFail(""));
      }, 3000);
    }
  };

  export const editApprovalStep =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
      const { userToken } = getState().user;
      dispatch(editWorkFlowLoading());
      try {
          const response = await API.post(`/approval_workflow_steps/edit`, data, {
              headers: {
                  Authorization: `Bearer ${userToken}`
              }
          });
          if ([200, 201].includes(response.status)) {
              dispatch(editWorkFlowSuccess(response.data.message));
              toastr.success("", response.data.message);
              cb && cb();
          }
      } catch (error) {
          
          if (error.response) {
            dispatch(editWorkFlowFail(error.response.data.message));
          toastr.error(error.response.data.message);
          }
          else{
            dispatch(editWorkFlowFail("No network connecton"));
            toastr.error("No network connecton");
          }
          setTimeout(() => {
              dispatch(editWorkFlowFail(""));
          }, 3000);
      }
  };

  export const deleteApprovalStep =
  (data?: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
      const { userToken } = getState().user;
      dispatch(deleteWorkFlowLoading());
      try {
          const response = await API.post(`/approval_workflow_steps/delete`, data, {
              headers: {
                  Authorization: `Bearer ${userToken}`
              }
          });
          if ([200, 201].includes(response.status)) {
              dispatch(deleteWorkFlowSuccess(response.data.message));
              toastr.success("", response.data.message);
              cb && cb();
          }
      } catch (error) {
          dispatch(deleteWorkFlowFail(error.response.data.message));
          toastr.error(error.response.data.message);
          setTimeout(() => {
              dispatch(deleteWorkFlowFail(""));
          }, 3000);
      }
  };



export default reducer;
