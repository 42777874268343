import ErrorIcon from "@mui/icons-material/Error";
import "./styles.scss";
type hoverProp = {
  error: string;
};

const HoverEffect = ({ error }: hoverProp) => {
  return (
    <div className="cover_hover">
      <ErrorIcon color="warning" />
      <div className="box_hover">
        <p className="error_real" dangerouslySetInnerHTML={{ __html: error }} />
      </div>
    </div>
  );
};

export default HoverEffect;
