/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import StatsCard from "components/StatsCard";
import Icon from "assests/icons/statsCard/Vector (4).png";
import greenCheck from "assests/icons/buyer/green-check.svg";
import arrowdown from "assests/icons/Vector (2).svg";
import membersGreen from "assests/icons/buyer/members-green.svg";
import membersBlue from "assests/icons/buyer/members-blue.svg";
import purpleClock from "assests/icons/buyer/purple-clock1.svg";
import { AppDispatch, RootState } from "store/store";
import mail1 from "assests/icons/buyer/mail-1.svg";
import SearchBar from "../../../../components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { tableCol, tableData } from "./data";
import { toastr } from "react-redux-toastr";
import exportIcon from "assests/icons/buyer/export-21.svg";
import "./styles.scss";
import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import DropdownMenu from "components/DropdownMenu";
import { useDispatch, useSelector } from "react-redux";
import useSearch from "hooks/useSearch";
import { useEffect } from "react";
import SearchIcon from "../../../../components/SearchBar";
import { award, disqualify, getAnalysis, getChart, getChartCom, getEachRfp, getOneRfp, invite } from "store/slice/BuyerSlice/EvaluationSlice/EvaluationSlice";
import Pagination from "components/Pagination";
import SelectDropdown from "components/SelectDropdown";
import CheckBox from "./checkbox";
import { Bar } from "react-chartjs-2";
import { dataTool } from "echarts";
import CreateDoc from "components/ModalBuyer/CreateDoc";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import { formatCurrency, kFormatter, moneyFormatter } from "utils/utils";
import { Button } from "@mui/material";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import CheckBox2 from "./checkbox2";
import Checkbox from "@mui/material/Checkbox";

// import Chart from "react-apexcharts";
const Evaluation_5 = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  // const { idEach } = useParams<{ idEach?: any }>();
  const [seen, setSeen] = useState(true);
  const [seen2, setSeen2] = useState(true);
  const [companyList, setCompanyList] = useState<string[]>([]);
  const [companyScore, setCompanyScore] = useState<number[]>([]);
  const [companyListCom, setCompanyListCom] = useState<string[]>([]);
  const [companyScoreCom, setCompanyScoreCom] = useState<number[]>([]);
  const [companyPrice, setCompanyPrice] = useState<number[]>([]);
  const [analyze, setAnalyze] = useState(false);
  const [status, setStatus] = useState(null);
  const [isPrice, setIsPrice] = useState(false);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const idEach = urlParams.get("id");
  const rfpType = urlParams.get("rfpType");
  const [modal, setModal] = useState<boolean>(false);
  const [isUpload, setIsUpload] = useState(false);
  const handleAnalyze = () => {
    setAnalyze(!analyze);
  };
  const { eachRfp, inviteLoading, eachLoading, oneRfp, awardLoading, disqualifyLoading, oneLoading, chartCommercial, chartTechnical, analysis } = useSelector((state: RootState) => state.rfpData);
  useEffect(() => {
    const filt =
      chartTechnical &&
      chartTechnical?.data
        ?.filter((item) => {
          return item.rfp_vendor_status !== "-2" && item.rfp_vendor_status !== "3";
        })
        ?.filter((val, i) => {
          return i <= 4;
        });
    if (filt) {
      // if (seen) {
      const testArray: string[] = [];
      const testScore: number[] = [];
      filt.forEach((item) => testArray.push(item.vendor_description.split(" ")[0]));
      filt.forEach((item) => testScore.push(item.total_vendor_evaluated_score));
      setCompanyList([...testArray]);
      setCompanyScore([...testScore]);
      //   setSeen(false);
      // }
    }
  }, [chartTechnical]);

  useEffect(() => {
    const filt2 =
      chartCommercial &&
      chartCommercial?.data
        ?.filter((val, i) => i <= 5)
        .filter((item) => {
          return item.rfp_vendor_status !== "-2" && item.rfp_vendor_status !== "3";
        });

    if (filt2) {
      // if (seen2) {
      const testArrayCom: string[] = [];
      const testScoreCom: number[] = [];
      const testPrice: number[] = [];

      filt2.forEach((item) => testArrayCom.push(item.vendor_description.split(" ")[0]));
      filt2.forEach((item) => testScoreCom.push(item.total_vendor_evaluated_score));
      filt2.forEach((item) => testPrice.push(item.vendor_discounted_price));
      setCompanyListCom([...testArrayCom]);
      setCompanyScoreCom([...testScoreCom]);
      setCompanyPrice([...testPrice]);
      //   setSeen2(false);
      // }
    }
  }, [chartCommercial]);

  const handleFilter = (isPrice) => {
    if (isPrice) {
      setIsPrice(true);
    } else {
      setIsPrice(false);
    }
  };

  const options = [
    {
      value: "technical",
      optionName: "Technical Requirements"
    },
    {
      value: "commercial",
      optionName: "Commercial Requirements"
    }
  ];

  const optionsChart = [
    {
      value: "scores",
      optionName: "scores"
    },
    {
      value: "price",
      optionName: "price"
    }
  ];

  const data = [
    {
      question: "How do you intend to deliver the project?",
      scores: [30, 20, 40, 10, 50, 30]
    },
    {
      question: "How will training be cordinated",
      scores: [30, 20, 40, 10, 50, 30]
    },
    {
      question: "Are you CSIA certified",
      scores: [3, 2, 4, 30, 50, 30]
    },
    {
      question: "Technical Prosposal",
      scores: [30, 20, 40, 10, 50, 30]
    }
  ];

  const commercialData = [
    {
      question: "Price",
      scores: [30, 20, 40, 10, 50, 30]
    },
    {
      question: "Payment Terms",
      scores: []
    }
  ];

  const TableRow = ({ question, question2, scores, price }) => {
    return (
      <tr>
        <td>
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* <input style={{ display: "block", marginRight: "10px" }} type="checkbox" name="" id="" />
             */}
            <Checkbox />
            {question}
          </div>
        </td>
        <td className="empty"></td>
        {scores?.map((el, i) => (
          <td key={el + i}>
            {el} <br /> {question2.includes("price") ? price && price.length && `(${kFormatter(price[i], "")})` : null}
          </td>
        ))}
      </tr>
    );
  };

  const TableRow2 = ({ question, question2, scores, price, quantity }) => {
    return (
      <tr>
        <td>
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* <input style={{ display: "block", marginRight: "10px" }} type="checkbox" name="" id="" />
             */}
            {question} <br /> {quantity ? `Quantity : ${quantity}` : ""}
          </div>
        </td>
        <td className="empty"></td>
        {scores?.map((el, i) => (
          <td key={el + i}>
            Score: {el} <br /> Price: {question2.includes("price") ? price && price.length && `${formatCurrency(price[i])}` : null}
          </td>
        ))}
      </tr>
    );
  };

  const [selectedCompany, setSelectedCompany] = useState({ companies: "" });

  // const companies = [
  //   "Satkle Solutions",
  //   "Novel Grey",
  //   "Absolute",
  //   "SARS",
  //   "Potate",
  //   "Goat Crop",
  // ];
  const closeModal = () => {
    setModal((prev) => !prev);
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    setSelectedCompany({ ...selectedCompany, [name]: value });
  };

  const awardHandle = () => {
    if (status === "4") {
      toastr.error("Action is not allowed");
    } else {
      if (selectedCompany.companies === "") {
        toastr.error("Select a company");
      } else {
        let one = oneRfp?.data?.[0] ? oneRfp?.data?.[0]?.sourcing_report_document_url : null;
        if (one) {
          const payload = {
            vendor_id: selectedCompany.companies,
            rfp_id: idEach
          };

          dispatch(
            award(payload, () => {
              dispatch(getAnalysis({ id: idEach }));
              dispatch(getOneRfp({ id: idEach }));
            })
          );
        } else {
          toastr.error("Attach a document");
        }
      }
    }
  };

  const disqualifyHandle = () => {
    if (status === "4") {
      toastr.error("Action is not allowed");
    } else {
      if (selectedCompany.companies === "") {
        toastr.error("Select a company");
      } else {
        const payload = {
          vendor_id: selectedCompany.companies,
          rfp_id: idEach
        };

        dispatch(
          disqualify(payload, () => {
            dispatch(getAnalysis({ id: idEach }));
          })
        );
      }
    }
  };

  const inviteHandle = () => {
    if (status === "4") {
      toastr.error("Action is not allowed");
    } else {
      if (selectedCompany.companies === "") {
        toastr.error("Select a company");
      } else {
        const payload = {
          vendor_id: selectedCompany.companies,
          rfp_id: idEach
        };
        dispatch(
          invite(payload, () => {
            dispatch(getAnalysis({ id: idEach }));
          })
        );
      }
    }
  };

  //Redux
  const dispatch = useDispatch<AppDispatch>();

  const { search, inputRef, handleChange, searched } = useSearch(getEachRfp, perPage, idEach);

  //Show Number for PerPage Request
  const handleNewPage = (pageNo: number, page: number = 1) => {
    setPerPage(pageNo);
    setPage(page);
    dispatch(getEachRfp({ page, length: pageNo, id: idEach, category: "Commercial" }));
  };
  const history = useHistory();

  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback((page: number, perPage: number) => {
    setPage(page);
    dispatch(getEachRfp({ page, length: perPage, id: idEach, category: "Commercial" }));
  }, []);

  const paginate = React.useCallback((pageNo: number, perPage: number) => {
    setPage(pageNo);
    dispatch(getEachRfp({ page, length: perPage, id: idEach, category: "Commercial" }));
  }, []);

  // const;

  // barchart data
  // const TechnicalCapacityData = {
  //   labels: companyList && companyList,
  //   datasets: [
  //     {
  //       label: "Technical Evaluation",
  //       backgroundColor: "#A6CEE3",
  //       data: companyScore && companyScore
  //     }
  //   ]
  // };

  const [TechnicalCapacityData, setTechnicalCapacityData] = useState({
    labels: [],
    datasets: [
      {
        label: "",
        backgroundColor: "",
        data: []
      }
    ]
  });
  useEffect(() => {
    setTechnicalCapacityData({
      labels: companyList && companyList,
      datasets: [
        {
          label: "Technical Evaluation",
          backgroundColor: "#A6CEE3",
          data: companyScore && companyScore
        }
      ]
    });
  }, [companyScore]);
  const [CoomercialCapacityData, setCoomercialCapacityData] = useState({
    labels: [],
    datasets: [
      {
        label: "",
        backgroundColor: "",
        data: []
      }
    ]
  });
  useEffect(() => {
    setCoomercialCapacityData({
      labels: companyListCom && companyListCom,
      datasets: [
        // in_house_estimate
        // oneRfp?.data?.[0] && oneRfp?.data?.[0]?.rfp_status
        {
          label: "In House Estimate",
          data:
            !isPrice &&
            companyListCom &&
            companyListCom.map((item) => {
              return oneRfp?.data?.[0] ? (oneRfp?.data?.[0]?.in_house_estimate ? parseInt(oneRfp?.data?.[0]?.in_house_estimate) : 0) : 0;
            }),
          type: "line",
          // this dataset is drawn on top
          backgroundColor: "#E81B1B",
          borderColor: "#E81B1B",
          order: 1
        },
        {
          label: "Commercial Requirement",

          backgroundColor: "#A6CEE3",
          order: 2,
          data: isPrice ? companyScoreCom && companyScoreCom : companyPrice && companyPrice
        }
      ]
    });
  }, [companyScoreCom, companyPrice, isPrice]);

  // const CoomercialCapacityData = {
  //   labels: companyListCom && companyListCom,
  //   datasets: [
  //     {
  //       label: "Commercial Requirement",
  //       backgroundColor: "#A6CEE3",
  //       data: isPrice ? companyScoreCom && companyScoreCom : companyPrice && companyPrice
  //     }
  //   ]
  // };

  //   setpage to the first page when a searched executed
  useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  useEffect(() => {
    setStatus(oneRfp?.data?.[0] && oneRfp?.data?.[0]?.rfp_status);
  }, [oneRfp]);

  useEffect(() => {
    dispatch(getEachRfp({ page, length: perPage, id: idEach, category: "Commercial" }));
    dispatch(getOneRfp({ id: idEach }));
    dispatch(getAnalysis({ id: idEach }));
    dispatch(getChart({ id: idEach }));
    dispatch(getChartCom({ id: idEach }));
  }, []);

  // const review = (id: String, vendor_id: string) => {
  //   history.push(`/buyer/rfx-evaluation/evaluation-4/?id=${id}&vendor_id=${vendor_id}&category=Commercial&status=${oneRfp?.data?.[0] && oneRfp?.data?.[0]?.rfp_status}`);
  // };

  const review = (e, item) => {
    window.location.href = `/buyer/rfx-evaluation/evaluation-4/?id=${item.rfp_id}&vendor_id=${item.vendor_id}&vendor_name=${item.vendor_name}&quote=${item.quote_id}&category=Commercial&status=${
      oneRfp?.data?.[0] && oneRfp?.data?.[0]?.rfp_status
    }`;
  };

  const rfpList =
    eachRfp &&
    eachRfp?.data?.map((item) => {
      return {
        // review: () => review(item.rfp_id, item.vendor_id),
        rfp_id: item.rfp_id,
        vendor_id: item.vendor_id,
        date: item.created_at,
        rfp_vendor_status: item.rfp_vendor_status,
        quote_id: item.submission_id && `QT - ${item.submission_id.id}`,
        vendor_name: item.vendor_description,
        category: "Commercial"
      };
    });

  const questionFilterTech =
    analysis &&
    analysis?.data?.analysis?.filter((item) => {
      return item.category === "Technical";
    });

  const questionFilterCom =
    analysis &&
    analysis?.data?.analysis?.filter((item) => {
      return item.category === "Commercial";
    });

  const questionTech = questionFilterTech
    ? questionFilterTech?.map((item) => {
        return {
          question: item.field_label,
          question2: item.field_name,
          scores:
            item.evaluated_scores &&
            item.evaluated_scores.map((el) => {
              return el ? parseFloat(el).toFixed(2) : 0;
            })
        };
      })
    : [];

  const [totalArray, setTotalArray] = useState<any>([]);
  const [totalComArray, setTotalComArray] = useState<any>([]);
  const [totalComArray2, setTotalComArray2] = useState<any>([]);
  // useEffect(()=>{
  //   let onceSeen = true
  //   let totalArr:any = []
  //   if (onceSeen  && questionTech) {
  //       for (let i = 0; i < questionTech[0].scores.length; i++) {
  //         let totalScore = questionTech.map(el => {
  //           if(el.scores[i] !== undefined){
  //           return parseFloat(el.scores[i]);
  //           }
  //         }).reduce((a,b) => a + b);
  //         totalArr.push(totalScore);
  //       }
  //       console.log(totalArr,'total arrray');

  //   }
  //   onceSeen=false
  //   setTotalArray(totalArr)

  // },[questionTech])
  // const [loadingPage, setLoadingPage] = useState(true);

  // useEffect(() => {
  //   if (loadingPage) {
  //     window.location.reload();
  //   } else {
  //     console.log("page already loaded");
  //   }
  // }, [loadingPage]);

  const [onceSeen, setOnceSeen] = useState(true);
  const [onceSeen2, setOnceSeen2] = useState(true);
  useEffect(() => {
    let totalArr: any = [];
    if (questionTech.length > 1) {
      if (onceSeen) {
        if (questionTech[0].scores) {
          for (let i = 0; i < questionTech[0].scores.length; i++) {
            let totalScore = questionTech
              .map((el) => {
                if (el.scores) {
                  if (el.scores[i] !== undefined) {
                    return parseFloat(el.scores[i]);
                  }
                }
              })
              .reduce((a, b) => a + b);
            totalArr.push(totalScore);
          }
          setTotalArray(totalArr ? totalArr : []);

          setOnceSeen(false);
        }
      }
    }
  }, [questionTech]);

  const questionCom = questionFilterCom
    ? questionFilterCom?.map((item) => {
        return {
          question: item.field_label,
          question2: item.field_name,
          quantity: item.requisition_material ? item.requisition_material.quantity_required : 0,
          price:
            item.vendor_response &&
            item.vendor_response.map((item) => {
              // return item ? kFormatter(item, "") : 0;
              return item ? item : 0;
            }),
          scores:
            item.evaluated_scores &&
            item.evaluated_scores.map((el) => {
              return el ? parseFloat(el).toFixed(2) : 0;
            })
        };
      })
    : [];

  const findLongest = (list) => {
    let longest = 0;
    for (let i = 0; i < list.length; i++) {
      console.log(list[i], "list[i]");
      if (i !== list.length - 1) {
        console.log(list[i].price, "list[i].price");
        if (list[i] && list[i].price && list[i + 1].price) {
          if (list[i].price.length >= list[i + 1].price.length) {
            if (list[i].price.length >= longest) {
              longest = list[i].price.length;
            } else {
              longest = longest;
            }
          } else if (list[i + 1].price.length >= list[i].price.length) {
            if (list[i + 1].price.length >= longest) {
              longest = i + 1;
            } else {
              longest = longest;
            }
          }
        }
      } else {
        console.log(list[i].price, "list[i].price");
        if (list[i].price && list[i].price.length >= longest) {
          longest = list[i].price.length;
        } else {
          longest = longest;
        }
      }
    }
    console.log(longest, "longest");
    return longest;
  };

  useEffect(() => {
    let totalArr2: any = [];
    let totalArr3: any = [];
    if (questionCom.length) {
      if (onceSeen2) {
        if (questionCom[0].scores) {
          for (let i = 0; i < questionCom[0].scores.length; i++) {
            let totalScore = questionCom
              .map((el) => {
                if (el.scores) {
                  if (el.scores[i] !== undefined) {
                    return parseFloat(el.scores[i]);
                  }
                }
              })
              .reduce((a, b) => a + b);
            totalArr2.push(totalScore);
          }
        }
        console.log(questionCom, questionCom[0].scores, "loll");
        const index = findLongest(questionCom);
        console.log(index, "index");
        if (questionCom[0].price) {
          for (let i = 0; i < index; i++) {
            let totalScore2 = questionCom
              .filter((item) => {
                return item.question2.includes("price");
              })
              .map((el) => {
                console.log(el, "el");
                if (el.price) {
                  if (el.price[i] !== undefined) {
                    console.log(el.price[i], "el.price[i]");
                    return parseFloat(el.price[i]);
                  }
                }
              })
              .reduce((a, b) => {
                return a + b;
              });
            console.log(totalScore2, "totalScore2");
            totalArr3.push(totalScore2);
          }
        }
        setTotalComArray(totalArr2 ? totalArr2 : []);
        setTotalComArray2(totalArr3 ? totalArr3 : []);
        setOnceSeen2(false);
      }
    }
  }, [questionCom]);

  // new graph
  const graphArray = {
    series: [
      {
        name: "Commercial Requirement",
        type: "column",
        data: isPrice ? companyScoreCom && companyScoreCom : companyPrice && companyPrice
      },
      {
        name: "In house",
        type: "line",
        data: [500000, 500000]
      }
    ],
    options: {
      chart: {
        height: 350,
        type: "line"
      },
      stroke: {
        width: [0, 4]
      },

      dataLabels: {
        enabled: true,
        enabledOnSeries: [1]
      },
      labels: companyListCom && companyListCom
    }
  };
  return (
    <div id="evaluation-3">
      {modal && <CreateDoc rfp_id={idEach} closeModal={closeModal} setIsUpload={setIsUpload} />}

      <div className="container">
        <h3 className="header">
          Sourcing Project: {oneRfp?.data?.[0] && oneRfp?.data?.[0]?.requisition_description} TENDER FOR {oneRfp?.data?.[0] && oneRfp?.data?.[0]?.sourcing_team_description}
        </h3>

        <div className="stats-card mt-4 row">
          <StatsCard text="Invited Vendors" classes="stats-icon-blue" image={membersBlue} statsNumber={oneRfp?.data?.[0] && oneRfp?.data?.[0]?.summary?.total_invited_vendors} />
          <StatsCard text="Submitted Quotes" classes="stats-icon-orange" image={mail1} statsNumber={oneRfp?.data?.[0] && oneRfp?.data?.[0]?.summary?.total_responses} />
          {/* <StatsCard text="Selection Duration" classes="stats-icon-purple" image={purpleClock} statsNumber={oneRfp?.data?.[0] && oneRfp?.data?.[0]?.summary?.total_invited_vendors} />
           */}
          <StatsCard text="Qualified Vendors" classes="stats-icon-green" image={membersGreen} statsNumber={oneRfp?.data?.[0] && oneRfp?.data?.[0]?.summary?.total_qualified_vendors} />
          {oneRfp?.data?.[0] ? (
            oneRfp?.data?.[0]?.sourcing_report_document_url ? (
              <StatsCard
                image={<VisibilityIcon color="primary" />}
                isImg={true}
                classes="middle-img"
                centerText="View Document"
                onClick={() => {
                  window.open(oneRfp?.data?.[0]?.sourcing_report_document_url);
                }}
              />
            ) : (
              <StatsCard image={AddIcon} classes="middle-img" centerText="Upload Document" onClick={closeModal} />
            )
          ) : (
            ""
          )}
          {/* <StatsCard image={AddIcon} classes="middle-img" centerText="Upload Document" onClick={closeModal} /> */}
        </div>
        {oneRfp?.data?.[0] ? (
          oneRfp?.data?.[0]?.sourcing_report_document_url ? (
            oneRfp?.data?.[0]?.rfp_status === "4" ? (
              ""
            ) : (
              <div className="extraClick">
                <Button startIcon={<ArrowCircleUpIcon />} onClick={closeModal}>
                  Re Attach
                </Button>
              </div>
            )
          ) : (
            ""
          )
        ) : (
          ""
        )}

        <div className={`charts ${rfpType === "rfq" ? "centerr" : ""}`}>
          {rfpType === "rfq" ? null : (
            <div className="chart">
              <Bar
                data={TechnicalCapacityData}
                width={100}
                height={50}
                options={{
                  title: {
                    display: true,
                    text: "Technical Capacity",
                    fontSize: 40
                  },
                  maintainAspectRatio: false,

                  legend: {
                    display: true,
                    position: "right"
                  }
                }}
              />
            </div>
          )}

          <div className="chart">
            <Bar
              data={CoomercialCapacityData}
              width={100}
              height={50}
              options={{
                title: {
                  display: true,
                  text: "Commercial Capacity",
                  fontSize: 40
                },
                maintainAspectRatio: false,

                legend: {
                  display: true,
                  position: "right"
                }
              }}
            />
            {/* <Chart options={graphArray.options} series={graphArray.series} type="donut" width="380" /> */}
          </div>
        </div>

        <div className={`analyzeDiv ${rfpType === "rfq" ? "place" : ""}`}>
          <SelectDropdown
            additionalClass="additionalClass"
            name="Filter"
            label="Filter"
            placeholder="Filter"
            options={optionsChart}
            onChange={(e) => {
              if (e.target.value === "scores") {
                handleFilter(true);
              } else {
                handleFilter(false);
              }
            }}
          />
          <button onClick={handleAnalyze} className="analyze">
            Analyze
          </button>
        </div>
        <div className="evalaution mt-5">
          <h3 className="title font-weight-bold">Supplier Quotes</h3>
          <div className="search-field mt-3">
            <SearchIcon placeholder="Search" boxClasses="" textRef={inputRef} value={search} onChange={handleChange} />
            <Dropdown perPage={10} action={(num) => setPerPage(num)} />
          </div>
          <div className="px-3 d-flex row mt-4 justify-content-between align-items-center">
            {/* <DropdownMenu options={options}>
                                <span>Technical Requirements</span> <img src={arrowdown} alt="alt" className="pl-3" />
                            </DropdownMenu> */}

            <SelectDropdown
              additionalClass="additionalClass"
              name="Category"
              label="Category"
              placeholder="Category"
              options={options}
              onChange={(e) => {
                if (e.target.value === "commercial") {
                  history.push(`/buyer/rfx-evaluation/evaluation/commercial?id=${idEach}&rfpType=${"rfp"}`);
                } else {
                  history.push(`/buyer/rfx-evaluation/evaluation/technical?id=${idEach}&rfpType=${"rfp"}`);
                }
              }}
            />

            {/* <div className="upload">
           
              <a target="_blank" style={{ padding: "1.0rem 1.7rem" }} id="upload" href={`${oneRfp?.data?.[0] && oneRfp?.data?.[0]?.sourcing_report_document_url}`}>
                <img src={exportIcon} alt="alt" className="pr-3" /> View Document
              </a>
            </div> */}
            {analyze ? (
              <div className="btn-wrap2">
                <button disabled={status === "4" ? true : false} onClick={inviteHandle} className="btn specBtn">
                  {inviteLoading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Invite For Negotiations"}
                </button>

                <button disabled={status === "4" ? true : false} className="btn specBtn" onClick={disqualifyHandle}>
                  {disqualifyLoading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Disqualify"}
                </button>

                <button disabled={status === "4" ? true : false} className="btn specBtn" onClick={awardHandle}>
                  {awardLoading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Award"}
                </button>
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="download-table-xls-button btn btn-success"
                  table="table-to-xls"
                  filename="Evaluation Analysis"
                  sheet="tablexls"
                  buttonText="Download"
                />
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="mt-3">
            {analyze ? (
              <div className="page-wrap">
                <div className="table-wrap">
                  <table>
                    <thead>
                      <tr className="table-heading">
                        <th>Component Description</th>
                        <th></th>
                        {/* replace this array with the company's array from backend */}
                        {analysis?.data && analysis?.data?.vendors.length
                          ? analysis?.data?.vendors.map((item) => {
                              return (
                                <th key={item.vendor_id}>
                                  <CheckBox value={item.vendor_id} handleInput={handleInput} label={item.vendor_description} name="companies" />
                                </th>
                              );
                            })
                          : ""}
                      </tr>
                      {questionTech.length ? (
                        <tr>
                          <th>Technical Requirements </th>
                          <th className="empty"></th>

                          {/* replace his numbers array with the total scores from backend */}
                          {totalArray.map((el, i) => (
                            <th key={el + i}>{parseFloat(el).toFixed(2)}</th>
                          ))}
                        </tr>
                      ) : (
                        ""
                      )}
                    </thead>
                    <tbody>{questionTech && questionTech.map(({ question, scores, question2 }, i) => <TableRow question={question} question2={question2} scores={scores} price={[]} key={i} />)}</tbody>
                    <tfoot>
                      <tr>
                        <th>Commercial Requirement</th>
                        <th className="empty"></th>

                        {totalComArray.map((el, i) => (
                          <th key={el + i}>
                            {
                              <>
                                {parseFloat(el).toFixed(2)} <br />
                                {`(${kFormatter(totalComArray2[i], "")})`}
                              </>
                            }
                          </th>
                        ))}

                        {/* replace this array with backend */}
                        {/* {companies.map((el, i) => (
                <th key={el + i}></th> 
              ))} */}
                      </tr>

                      {questionCom && questionCom.map(({ question, scores, price, question2 }, i) => <TableRow price={price} question={question} question2={question2} scores={scores} key={i} />)}
                    </tfoot>
                  </table>
                  {true && (
                    <table style={{ display: "none" }} id="table-to-xls">
                      <thead>
                        <tr className="table-heading">
                          <th>Component Description</th>
                          <th></th>
                          {analysis?.data
                            ? analysis?.data?.vendors.length
                              ? analysis?.data?.vendors.map((item) => {
                                  return (
                                    <th key={item.vendor_id}>
                                      <CheckBox2 value={item.vendor_id} handleInput={handleInput} label={item.vendor_description} name="companies" />
                                    </th>
                                  );
                                })
                              : ""
                            : ""}
                        </tr>
                        {questionTech.length ? (
                          <tr>
                            <th>Technical Requirements </th>
                            <th className="empty"></th>
                            {totalArray.map((el, i) => (
                              <th key={el + i}>{parseFloat(el).toFixed(2)}</th>
                            ))}
                          </tr>
                        ) : (
                          ""
                        )}
                      </thead>
                      <tbody>
                        {questionTech &&
                          questionTech.map(({ question, scores, question2 }, i) => <TableRow2 quantity={0} question={question} question2={question2} scores={scores} price={[]} key={i} />)}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>Commercial Requirement</th>
                          <th className="empty"></th>

                          {totalComArray.map((el, i) => (
                            <th key={el + i}>
                              {
                                <>
                                  Total Score: {parseFloat(el).toFixed(2)} <br />
                                  Total Price: {`(${formatCurrency(totalComArray2[i])})`}
                                </>
                              }
                            </th>
                          ))}
                        </tr>

                        {questionCom &&
                          questionCom.map(({ question, scores, price, question2, quantity }, i) => (
                            <TableRow2 quantity={quantity} price={price} question={question} question2={question2} scores={scores} key={i} />
                          ))}
                      </tfoot>
                    </table>
                  )}
                  {/* <div className="btn-wrap">
                    <button disabled={status === "4" ? true : false} onClick={inviteHandle} className="btn">
                      {inviteLoading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Invite For Negotiations"}
                    </button>

                    <button disabled={status === "4" ? true : false} className="btn" onClick={disqualifyHandle}>
                      {disqualifyLoading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Disqualify"}
                    </button>

                    <button disabled={status === "4" ? true : false} className="btn" onClick={awardHandle}>
                      {awardLoading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Award"}
                    </button>
                  </div> */}
                </div>
              </div>
            ) : (
              <DataTable columns={tableCol} data={rfpList} loading={eachLoading} onClick={review} />
            )}
          </div>
          {eachRfp?.count > 10 ? (
            <Pagination page={page} lastPage={eachRfp?.pagination?.lastPage} paginate={paginate} total={eachRfp?.pagination?.total} handlePageChange={handlePageChange} perPage={perPage} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Evaluation_5;
