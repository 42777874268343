import { Link } from "react-router-dom";

import greenCheck from "assests/icons/buyer/green-check.svg";

export const tableCol = [
    {
        name: "",
        index: "",
        id: 1,
        render: (item: { status: string; }) => (
            <>{item.status === "Verified" && <img src={greenCheck} className="avatar" alt="icon" />}</>
        )
    },
    {
        name: "Invoice Date",
        index: "invoice_date",
        id: 1
    },
    {
        name: "Vendor Name",
        index: "vendor_name",
        id: 2
    },
    {
        name: "PO Number",
        index: "po_number",
        id: 3
    },
    {
        name: "Invoice Number",
        index: "invoice_number",
        id: 4
    },
    {
        name: "Invoice Amount",
        index: "invoice_amount",
        id: 5
    },
    {
        name: "Invoice Error",
        index: "invoice_error",
        id: 6
    },
    {
        name: "Invoice Tracker",
        index: "invoice_tracker",
        id: 6
    },
    {
        name: "Status",
        id: 7,
        render: (item) => (
            <Link to={`/supplier/invoice/100`}>
                <button
                    type="button"
                    style={{
                        padding: "8px 16px",
                        background: item.status === "Pending" ? "rgba(245, 150, 37, 0.15)" : "rgba(0, 70, 176, 0.15)",
                        borderRadius: "10px",
                        color: item.status === "Pending" ? "rgba(245, 150, 37, 1)" : "rgba(47, 128, 237, 1)"
                    }}
                >
                    {item.status}
                </button>
            </Link>
        )
    }
];

export const tableData = [
    {
        invoice_date: "2nd Sept. 2019",
        vendor_name: "Brent Fawaz",
        po_number: "123333",
        invoice_number: "123333",
        invoice_amount: "3000",
        invoice_error: "",
        invoice_tracker: "Internal Control",
        status: "Verified",
        id: 1
    },
    {
        invoice_date: "24th May 2019",
        vendor_name: "Chandler Ross",
        po_number: "112233",
        invoice_number: "123333",
        invoice_amount: "3I2388",
        invoice_error: "IT Services",
        invoice_tracker: "Audit Processing",
        status: "Verified",
        id: 1
    },
    {
        invoice_date: "5th Jun. 2019",
        vendor_name: "Monica Williams",
        po_number: "112233",
        invoice_number: "112233",
        invoice_amount: "256389",
        invoice_error: "Consulting",
        invoice_tracker: "F&A",
        status: "Pending",
        id: 1
    },
    {
        invoice_date: "7th July 2020",
        vendor_name: "Asa Okoro",
        po_number: "738932",
        invoice_number: "738932",
        invoice_amount: "6356782",
        invoice_error: "Consulting",
        invoice_tracker: "F&A",
        status: "Verified",
        id: 1
    },
    {
        invoice_date: "19th Aug. 2020",
        vendor_name: "Jamal Abdul",
        po_number: "11233123",
        invoice_number: "11233123",
        invoice_amount: "24562",
        invoice_error: "General Contractor",
        invoice_tracker: "Payment Processing",
        status: "Pending",
        id: 1
    },
    {
        invoice_date: "22nd Feb. 2020",
        vendor_name: "Sani Abubakar",
        po_number: "43637.",
        invoice_number: "43637.",
        invoice_amount: "78992",
        invoice_error: "IT Services",
        invoice_tracker: "verifying",
        status: "Pending",
        id: 1
    }
];
