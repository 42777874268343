import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { approvalState, Action } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";

const initialState: approvalState = {
  loading: false,
  fields: null,
  successMessage: "",
  errorMessage: "",
  deleting:false,
};

const approvalSlice = createSlice({
  name: "field",
  initialState,
  reducers: {
    getAllfieldsLoading: (state) => {
      state.loading = true;
    },
    getAllfieldSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.fields = action.payload;
    },
    getAllfieldFail: (state) => {
      state.loading = false;
    },
    createFieldLoading: (state) => {
      state.loading = true;
    },
    createFieldSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.fields = action.payload;
    },
    createFieldFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    editFieldLoading: (state) => {
      state.loading = true;
    },
    editFieldSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.fields = action.payload;
    },
    editFieldFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    deleteFieldLoading: (state) => {
      state.deleting = true;
    },
    deleteFieldSuccess: (state, action: PayloadAction<Action>) => {
      state.deleting = false;
      state.fields = action.payload;
    },
    deleteFieldFail: (state, action: PayloadAction<string>) => {
      state.deleting = false;
      state.errorMessage = action.payload;
    }
  }
});

const { actions, reducer } = approvalSlice;
export const {
  getAllfieldsLoading, 
  getAllfieldSuccess, 
  getAllfieldFail, 
  createFieldLoading,
  createFieldSuccess,
  createFieldFail,
  editFieldLoading,
  editFieldSuccess,
  editFieldFail,
  deleteFieldLoading,
  deleteFieldSuccess,
  deleteFieldFail,
 } = actions;

 type getField = {
  page?: number, 
  length?: number, 
  id?:string, 
  search?: string, 
  activity_name?: string, 
  approval_status?: string,
 }

export const getFields =
  ({page,length,id,search,activity_name,approval_status}:getField): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getAllfieldsLoading());
    try {
      const response = await API.get(`/vendor_fields/get/${id}`, {
        params: {
          activity_name,
          length,
          search,
          approval_status,
          page
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getAllfieldSuccess(response.data));
      }
    } catch (error) {
      dispatch(getAllfieldFail());
    }
  };

 


export const createField =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(createFieldLoading());
    try {
      const response = await API.post(`/vendor_fields/create`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(createFieldSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      dispatch(createFieldFail(error.response.data?.message));
      toastr.error(error.response.data.message);
      // setTimeout(() => {
      //   dispatch(createFieldFail(""));
      // }, 3000);
    }
  };

  export const editField =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
      const { userToken } = getState().user;
      dispatch(editFieldLoading());
      try {
          const response = await API.post(`/vendor_fields/create`, data, {
              headers: {
                  Authorization: `Bearer ${userToken}`
              }
          });
          if ([200, 201].includes(response.status)) {
              dispatch(editFieldSuccess(response.data.message));
              toastr.success("", response.data.message);
              cb && cb();
          }
      } catch (error) {
          dispatch(editFieldFail(error.response.data.message));
          toastr.error(error.response.data.message);
          setTimeout(() => {
              dispatch(editFieldFail(""));
          }, 3000);
      }
  };

  export const deleteField =
  (data?: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
      const { userToken } = getState().user;
      dispatch(deleteFieldLoading());
      try {
          const response = await API.post(`/vendor_fields/delete`, data, {
              headers: {
                
                  Authorization: `Bearer ${userToken}`
              }
          });
          if ([200, 201].includes(response.status)) {
              dispatch(deleteFieldSuccess(response.data.message));
              toastr.success("", response.data.message);
              cb && cb();
          }
      } catch (error) {
          dispatch(deleteFieldFail(error.response.data.message));
          toastr.error(error.response.data.message);
          setTimeout(() => {
              dispatch(deleteFieldFail(""));
          }, 3000);
      }
  };


export default reducer;
