import Accordion from "components/Accordion";
import useAccordion from "hooks/useAccordion";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { ReactComponent as DeleteIcon } from "assests/icons/Vector (3) copy 2.svg";
import "./styles.scss";
import { useEffect, useState } from "react";
import {
  addInvoiceToPayment,
  deletePaymentProposal,
  fetchDebitLines,
  getAllDebitLines,
  getSinglePaymentProprosals,
  removeDebitLine,
  removeInvoiceFromPayment,
  sendPaymentProposal
} from "store/slice/BuyerSlice/PostedInvoiceSlice/PostedInvoiceSlice";
import { useHistory, useLocation, useParams } from "react-router";
import { formatCurrency, toastPopUp } from "utils/utils";
import RejectOnboarding from "components/ModalBuyer/RejectOnboarding";
import DebitLines from "components/ModalBuyer/DebitLine";
import { approveItem, rejectItem } from "store/slice/BuyerSlice/ApprovalSlice/ApprovalSlice";

const ProposalApproval = () => {
  interface Provider {
    open: boolean;
    ids: object;
  }
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  let { id }: any = useParams();
  const { singlePaymentProposals, loading } = useSelector((state: RootState) => state.postedInvoice);
  let data = singlePaymentProposals?.data[0];

  console.log(data, "assss");
  // state
  const [data_, setData_] = useState<object[]>([]);
  const [rejectModal, setRejectModal] = useState<boolean>(false);
  const [modal, setModal] = useState<Provider>({
    open: false,
    ids: {}
  });
  const [notes, setNotes] = useState<string>("");

  useEffect(() => {
    dispatch(getSinglePaymentProprosals(id));
  }, []);

  useEffect(() => {
    setData_(dataArr);
  }, [data]);

  // type isSelected = {
  //   action: string;
  // };

  const { getAmount, checkAccordion, handleCheckbox, handlerAccordion, index, headerChecked, getSelected, isSelected } = useAccordion(data_);
  // invoices_added;
  const dataArr: object[] = data?.proposal_items?.map((item) => ({
    id: item.id,
    name: item.vendor_description,
    vendor_code: item.vendor_code,
    proposal_amt: Number(item?.amount),
    invoice: item.invoices_added.map((item) => ({
      id: item.proposal_id,
      amount: item.gross_amount,
      date: item.posting_date,
      invoice_number: item.invoice_code,
      wht: "",
      invoice_id: item.invoice_id,
      currency: item.currency_iso,
      purchase_order_code: item.purchase_order_code,
      invoice_code: item.invoice_code,
      vendor_id: item.vendor_id,
      proposal_items_id: item.id,
      witholding_tax: item.witholding_tax,
      invoice_document_url: item.invoice_document_url
    })),
    debitLines: item.debit_lines_added.map((item) => ({
      id: item.id,
      amount: item.amount,
      date: item.payment_date,
      invoice_number: item.document_number,
      wht: "",
      invoice_id: item.invoice_id,
      currency: item.currency_iso,
      invoice_code: item.invoice_code,
      vendor_id: item.vendor_id,
      witholding_tax: item.witholding_tax,
      proposal_items_id: item.id
    })),
    invoice_added: item.invoices_added.map(({ invoice_code }) => invoice_code),
    // amount: item?.invoices_added?.reduce((arr, curr) => arr + Number(curr.gross_amount), 0) - item?.debit_lines_added?.reduce((arr, curr) => arr + Number(curr.amount), 0)

    // amount: item?.invoices_added
    //   ?.map((item) => {
    //     return parseInt(item.gross_amount) - parseInt(item.witholding_tax);
    //   })
    //   .reduce((cur, next) => {
    //     return cur + next;
    //   })

    amount: item?.total_invoiced_value
  }));

  //   function getItemStatus(status) {
  //     if (status === "-2") return "Draft";
  //     if (status === "-1") return "Pending Approval";
  //     if (status === "1") return "Approved";
  //     if (status === "2") return "Paid";
  //     if (status === "3") return "Parked";
  //     if (status === "4") return "Posted";
  //   }

  //   function getBtnClass(status) {
  //     if (status === "-3") return "reject";
  //     if (status === "-2") return "draft";
  //     if (status === "-1") return "draft";
  //     if (status === "1") return "approved";
  //     if (status === "2") return "posted";
  //     if (status === "3") return "posted";
  //     if (status === "4") return "posted";
  //   }

  function getItemStatus(status) {
    if (status === "-3") return "Rejected";
    if (status === "-2") return "New Proposal";
    if (status === "-1") return "FC Approval";
    if (status === "1") return "Payment Approved";
    if (status === "2") return "Paid";
    if (status === "3") return "Parked";
    if (status === "4") return "Posted";
  }

  function getBtnClass(status) {
    if (status === "-3") return "reject";
    if (status === "-2") return "posted";
    if (status === "-1") return "draft";
    if (status === "1") return "approved";
    if (status === "2") return "posted";
    if (status === "3") return "posted";
    if (status === "4") return "posted";
  }

  const approveBtnClicked = () => {
    toastPopUp(
      () => {
        dispatch(sendPaymentProposal({ proposal_id: id }, () => dispatch(getSinglePaymentProprosals(id))));
      },
      "Confirm",
      "Cancel",
      "Confirm Payment Proposal?",
      "",
      "green"
    );
  };
  const deleteBtnClicked = () => {
    toastPopUp(
      () => {
        dispatch(deletePaymentProposal({ proposal_id: id }, () => history.push("/buyer/treasury/proposal-queue")));
      },
      "Confirm",
      "Cancel",
      "Delete Payment Proposal?",
      "",
      "red"
    );
  };

  // const openModal = (e, { invoice }, i) => {
  //   e.stopPropagation();
  //   setModal({
  //     open: true,
  //     ids: { vendor_id: invoice[0].vendor_id, proposal_items_id: invoice[0].proposal_items_id, index: i }
  //   });
  // };

  const openModal = (e, data, i) => {
    e.stopPropagation();
    setModal({
      open: true,
      ids: { vendor_id: data.invoice[0].vendor_id, proposal_items_id: data.id, index: i }
    });
  };

  const handleCheckboxDebit = (invoice_debit_id, proposal_item_id) => {
    dispatch(removeDebitLine({ proposal_item_id, invoice_debit_id }, () => dispatch(getSinglePaymentProprosals(id))));
  };

  const approve = () => {
    toastPopUp(
      () => {
        dispatch(
          approveItem(
            {
              approval_queue_id: location.state,
              note: ""
            },
            () => history.push("/buyer/approvals")
          )
        );
      },
      "Approve",
      "Cancel",
      "Approve Proposal?",
      "",
      "#737a91"
    );
  };

  const reject = () => {
    // if (window.confirm("Confirm Approve")) {
    toastPopUp(
      () => {
        dispatch(
          rejectItem(
            {
              approval_queue_id: location.state,
              note: notes
            },
            () => history.push("/buyer/approvals")
          )
        );
      },
      "Reject",
      "Cancel",
      "Reject Proposal?",
      "",
      "red"
    );
    // }
  };

  const closeReject = () => {
    setRejectModal(false);
  };

  const handleChange = ({ target: { value } }) => {
    setNotes(value);
  };

  return (
    <div id="proposal-approval">
      {/* {rejectModal && <RejectOnboarding closeModal={closeReject} reject={reject} />} */}
      <div className="row mb-5">
        <div className="col-6  col-xs-12">
          <div className="row">
            <div className="col-4 mb-4">
              <h6>Run ID</h6>
              <p>{data?.proposal_code}</p>
            </div>
            <div className="col-4">
              <h6>Run Date</h6>
              <p>{data?.run_date}</p>
            </div>
            <div className="col-4">
              <h6>Proposal Amt.</h6>
              <p>{formatCurrency(data?.total_proposal_amount)}</p>
            </div>
            <div className="col-4">
              <h6>Status</h6>
              <button className={`status mt-2 ${getBtnClass(data?.proposal_status)}`}>{getItemStatus(data?.proposal_status)}</button>
            </div>
            {data?.proposal_status === "-3" && (
              <div className="col-4">
                <h6>Rejection Date</h6>
                <p>{data?.run_date}</p>
              </div>
            )}
          </div>
        </div>
        <div className="col-6 col-xs-12">
          <div className="d-flex justify-content-end align-items-center mb-3" style={{ columnGap: "2rem" }}>
            <button
              type="button"
              // disabled={checkFiles?.includes("-1")}
              style={{
                padding: "8px 30px",
                background: " #F85A5A",
                borderRadius: "10px",
                color: "white"
              }}
              onClick={reject}
            >
              {loading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Reject"}
            </button>
            <button
              type="submit"
              // disabled={checkFiles?.includes("-1") || vendor === ""}
              style={{
                padding: "8px 30px",
                background: "#00B0A5",
                borderRadius: "10px",
                color: "white"
              }}
              onClick={approve}
            >
              {loading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Approve"}
            </button>
          </div>
        </div>
      </div>
      <hr />
      <div className="d-flex py-4 mb-5" style={{ columnGap: "1rem" }}>
        <h6>
          Total amount to be paid: <span style={{ color: "#737A91" }}>{formatCurrency(data?.total_proposal_amount)}</span>
        </h6>
        <h6>
          Currency: <span style={{ color: "#737A91" }}>{data?.currency_iso}</span>
        </h6>
      </div>
      <Accordion
        arr={dataArr}
        isDisabled={true}
        getAmount={getAmount}
        checkAccordion={checkAccordion}
        handleCheckbox={handleCheckbox}
        handlerAccordion={handlerAccordion}
        index={index}
        headerChecked={headerChecked}
        btnClicked={openModal}
        getSelected={getSelected}
        loading={loading}
        handleCheckboxDebit={handleCheckboxDebit}
      />

      <textarea className="mt-3 notes" onChange={handleChange} rows={5} value={notes} placeholder="Type message here..."></textarea>
    </div>
  );
};

export default ProposalApproval;
