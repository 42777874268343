import React, { useEffect, useState, useRef, useCallback } from "react";
import "./styles.scss";
import StatsCard from "components/StatsCard";
import DataTable from "components/DataTable";
import { documentsColumns } from "./data";
import Icon from "assests/icons/add-circle.svg";
import Cash from "assests/icons/statsCard/cash.png";
import Contract from "assests/icons/alarm2.svg";
import Truck from "assests/icons/truck.svg";
import Invoice from "assests/icons/invoice.svg";
import CardContainer from "components/CardContainer";
import SearchIcon from "components/SearchBar";
import { useDispatch, useSelector } from "react-redux";
// import { getHouseholdSummary, getAllHousehold } from "store/slice/HouseHoldsSlice/HouseholdSlice";
import { RootState } from "store/store";
import Pagination from "components/Pagination";
import Dropdown from "components/Dropdown";
import useSearch from "hooks/useSearch";
import useArrowDropdown from "hooks/useArrowDropdown";
// import { getCommunity } from "store/slice/CommunitySlice/communitySlice";
import Star from "components/Star";
import { getSingleVendor, getVendors } from "store/slice/BuyerSlice/VendorSlice/VendorSlice";

import { useHistory } from "react-router-dom";
import { getExpiredDoc } from "store/slice/BuyerSlice/DocumentSlice/DocumentSlice";
import { getAllCountries } from "store/slice/CountriesSlice/CountriesSlice";
import { getAllStates } from "store/slice/CountryStatesSlice/StateSlice";
import { getAllCities } from "store/slice/CitySlice/CitySlice";
import { removeZero } from "utils/utils";
import moment from "moment";

const CompanyDocs = () => {
  //State
  const [page, setPage] = useState<number>(1);
  const [length, setLength] = useState<number>(10);
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  //Hooks
  const { search, inputRef, handleChange, searched } = useSearch(getSingleVendor, length);
  const dispatch = useDispatch();
  const history = useHistory();

  //Redux
  const { vendors } = useSelector((state: RootState) => state.vendorDetails);
  const { user } = useSelector((state: RootState) => state.user);
  const { singleVendor } = useSelector((state: RootState) => state.vendorDetails);
  const { expiredDoc } = useSelector((state: RootState) => state.document);

  // get vendors
  useEffect(() => {
    // dispatch(getVendors(undefined, undefined, undefined, { fetch_type: "detailed" }));
    dispatch(getSingleVendor(user?.vendor_id));
    dispatch(getExpiredDoc());
  }, []);

  useEffect(() => {
    if (singleVendor?.data[0]?.erp_special_status === "-1") {
      const data = singleVendor?.data[0];
      dispatch(getAllCountries());
      // dispatch(getAllStates(undefined, 1000, undefined, '1', undefined, undefined, data.state_name));
      // dispatch(getAllCities(undefined, 1000, '1', '3901', undefined, undefined, undefined, data.city_id));
    }
  }, [singleVendor?.data[0]]);

  //   single vendor meta objedcts
  const vendorDataFiles = singleVendor?.data[0]?.vendor_meta;

  //   vendor files
  const vendorFiles = vendorDataFiles && vendorDataFiles.filter((file) => file.field_type === "File");

  //   Table data
  const tableData =
    vendorFiles &&
    vendorFiles.map((file) => {
      const { category, field_label, expires_on, data_status, vendor_data_id, value } = file;
      let data_status_;
      let status;
      if (data_status === "-2") {
        data_status_ = "Replace";
        status = "Expired";
      }
      if (data_status === "-1") {
        data_status_ = "In Review";
        status = "Pending";
      }
      if (data_status === "1") {
        data_status_ = "View";
        status = "Verified";
      }
      return {
        category: category,
        field_label,
        expires_on: expires_on !== null ? moment(expires_on).format("L") : "",
        data_status: data_status_,
        status: status,
        link: value
      };
    });

  // approved vendors
  const approved = vendors?.data.filter((item) => item.vendor_status > 0);

  // setpage to the first page when a searched executed
  useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  //--------------------------------------------
  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, length: number) => {
      setPage(page);
      dispatch(getSingleVendor(user?.vendor_id, () => {}, page, length));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, length: number) => {
      setPage(pageNo);
      dispatch(getSingleVendor(user?.vendor_id, () => {}, page, length));
    },
    [search]
  );

  //------------------------------------------------------------
  //Show Number for PerPage Function
  //------------------------------------------------------------
  const handleNewPage = React.useCallback(
    (pageNo: number, page = 1) => {
      setLength(pageNo);
      setPage(1);
      dispatch(getSingleVendor(user?.vendor_id, () => {}, page, length));
    },
    [search]
  );

  // const searchTable = tableData.filter((item) => table)

  useEffect(() => {
    if (searchValue) {
      const searchedData = tableData?.filter((item) => item.field_label.toLowerCase().includes(searchValue.toLowerCase()));
      setSearchResult(searchedData);
    }
  }, [searchValue]);

  const table = searchValue ? searchResult : tableData;
  // console.log(table?.length);
  // console.log(expiredDoc?.count);

  const manageReg = () => {
    if (singleVendor?.data[0]?.erp_special_status === "-1") {
      history.push({
        pathname: "/supplier/company-info/contact-info",
        state: {
          user: user?.vendor_id,
          stateId: singleVendor?.data[0].state_id,
          countryID: singleVendor?.data[0].country_id,
          state_name: singleVendor?.data[0].state_name,
          city_id: singleVendor?.data[0].city_id
        }
      });
    } else {
      history.push({ pathname: "/supplier/company-info/company", state: user?.vendor_id });
    }
  };

  // console.log(singleVendor?.data[0]?.erp_special_status);

  return (
    <div id="company-docs-page">
      <div className="container">
        <div className="d-flex align-items-center mb-3">
          {/* <h4 className="page-title mr-2 ">Stakle’s Ratings</h4> */}
          <h4 className="page-title mr-2 ">
            {user?.vendor_description} | {removeZero(user?.vendor_code)}
          </h4>
          <Star stars={user && user.overall_ratings} disabled={true} />
        </div>
        <div className=" row mb-5">
          <StatsCard statsNumber={expiredDoc?.count || 0} text="Expiring Docs" classes="stats-expiring" image={Contract} />
          <StatsCard image={Icon} classes="middle-img" centerText="Manage Registration Data " onClick={() => manageReg()} />
        </div>

        <h4 className="page-title mb-2">Recent Documents</h4>
        <div className="search-field">
          <SearchIcon placeholder="Search for Document" boxClasses=" mb-4" textRef={inputRef} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
          {/* <Dropdown perPage={length} action={() => {}} /> */}
        </div>

        <DataTable columns={documentsColumns} data={table} />
        {/* <Pagination page={page} lastPage={table?.length/page} paginate={paginate} total={table?.length} handlePageChange={handlePageChange} perPage={length} /> */}
      </div>
    </div>
  );
};

export default CompanyDocs;
