import StatsCard from "components/StatsCard";
import React, { useState } from "react";
import "../styles.scss";
import Analytics from "assests/icons/buyer/analytics.svg";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import SearchIcon from "components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { productDataColumn } from "../data";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { useEffect } from "react";
import {
  getMaterialCategories,
  getMaterials,
  deleteProductData
} from "store/slice/BuyerSlice/ProductSlice/ProductSlice";
import CreateOrEditProductData from "components/ModalBuyer/CreateOrEditProductData";
import { toastPopUp } from "utils/utils";
import useSearch from "hooks/useSearch";
import Pagination from "components/Pagination";
import { checkPermission } from "utils/utils";

const ProductData = () => {
  //State
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [modal, setModal] = useState<boolean>(false);
  const [singleProductData, setSingleProductData] = useState<any>(null);

  //Redux
  const dispatch = useDispatch<AppDispatch>();
  const { materials, loading } = useSelector((state: RootState) => state.product);
  const { role_access } = useSelector((state: RootState) => state.user?.user);

  //Hook
  const { search, inputRef, handleChange, searched } = useSearch(getMaterials, perPage);

  useEffect(() => {
    dispatch(getMaterials(page, perPage));
  }, []);

  // setpage to the first page when a searched executed
  useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  // new page
  const handleNewPage = (pageNo: number, page: number = 1) => {
 
    setPerPage(pageNo);
    setPage(1);
    dispatch(getMaterials(page, pageNo, search));
};

  //Modal Handler
  const closeModal = () => {
    setModal((prev) => !prev);
    setSingleProductData(null);
  };

      //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, perPage: number) => {
      setPage(page);
      dispatch(getMaterials(page, perPage, search));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, perPage: number) => {
      setPage(pageNo);
      dispatch(getMaterials(pageNo, perPage, search));
    },
    [search]
  );

  //Function Declaration
  const editProductData = (_data: any) => {
    setSingleProductData(_data);
    setModal((prev) => !prev);
  };

  const deleteProduct = (id: string, name: string) => {
     const payload = { material_category_id: id };
     
    toastPopUp(
      () => {
        dispatch(
          deleteProductData(payload, () => {
            dispatch(getMaterials(page,perPage));
          })
        );
      },
      "Delete",
      "Cancel",
      "Delete the entity",
      name,
      "red"
    );
  };

  const materialList =
    materials &&
    materials?.data?.map((material) => {
      return {
        id: material.material_code,
        product_name: material.material_description,
        category: material.material_category_description,
        uom: material.uom,
        price_per_unit: material.price_per_unit,
        edit: () => checkPermission(role_access, "SETUP_INDEX") && editProductData(material),
        deleteProduct: () => checkPermission(role_access, "SETUP_INDEX") && deleteProduct(material.id, material.material_description)
      };
    });

 
  return (
    <div id="settings-material-data">
      {modal && <CreateOrEditProductData closeModal={closeModal} singleProductData={singleProductData} />}
      <div className="container mt-3">
        <div className="row mt-3 mb-5">
          <StatsCard
            statsNumber={materials?.count ?? 0}
            text="Product Data"
            classes="stats-icon"
            image={Analytics}
            iconBackgroundColor="rgba(45, 156, 219, 0.1)"
          />
          <StatsCard image={AddIcon} classes="middle-img" centerText="Create Product" onClick={closeModal} />
        </div>

        <div className="d-flex  mb-3">
          <SearchIcon
            placeholder="Search for Product Data"
            boxClasses=""
            textRef={inputRef}
            value={search}
            onChange={handleChange}
          />
          <Dropdown perPage={10} action={handleNewPage} />
        </div>
        <DataTable columns={productDataColumn} data={materialList} loading={loading} />
        {
          materials?.count > 10 ? 
          <Pagination
                    page={page}
                    lastPage={materials?.pagination?.lastPage}
                    paginate={paginate}
                    total={materials?.pagination?.total}
                    handlePageChange={handlePageChange}
                    perPage={perPage}
                />: null
        }
        
                
      </div>
    </div>
  );
};

export default ProductData;
