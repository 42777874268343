import axios from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "store/store";
import { AuthState, Action } from "./interface";
import { getProfile, updateUser } from "store/slice/UserSlice/UserSlice";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";

const initialState: AuthState = {
  isLoading: false,
  user: null,
  userToken: "",
  errorMessage: "",
  message: ""
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginLoading: (state) => {
      state.isLoading = true;
    },
    loginSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.user = action.payload.user;
      state.userToken = action.payload.token;
    },
    loginFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },

    forgetPasswordLoading: (state) => {
      state.isLoading = true;
    },
    forgetPasswordSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.errorMessage = "";
    },
    forgetPasswordFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    setPasswordLoading: (state) => {
      state.isLoading = true;
    },
    setPasswordSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.message = action.payload.message;
    },
    setPasswordFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    verifyPassword: (state) => {
      state.isLoading = false;
    }
  }
});

const { actions, reducer } = authSlice;

export const { loginLoading, loginSuccess, loginFail, setPasswordFail, setPasswordLoading, setPasswordSuccess, forgetPasswordLoading, forgetPasswordSuccess, forgetPasswordFail, verifyPassword } =
  actions;

type Login = {
  // email: string;
  password: string;
  username: string;
};

type SetPassword = {
  new_password: string;
};

type ForgetPassword = {
  email: string;
};

export const loginRequest =
  (payload: Login, check?: string, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(loginLoading());
    try {
      const response = await API.post("/login/authenticate", payload);
      if ([200, 201].includes(response.status)) {
        dispatch(loginSuccess(response.data.data));

        if (response.data.data.force_password_change === "1") {
          dispatch(
            updateUser({
              authorized: true,
              user: response.data.data,
              token: response.data.data.token,
              portal: "Buyer"
            })
          );
          dispatch(getProfile(response.data.data.user_id));
          toastr.success("", "You are required to set a new password");
          setTimeout(() => {
            window.location.href = "/buyer/newPassword";
          }, 2000);
        } else {
          dispatch(
            updateUser({
              authorized: true,
              user: response.data.data,
              token: response.data.data.token,
              portal: "Buyer"
            })
          );
          dispatch(getProfile(response.data.data.user_id));
        }

        // localStorage.setItem("sebore", JSON.stringify(response.data.token));
        // dispatch(getProfile(response.data.user._id));
      }
    } catch (error: any) {
      if (error.response) {
        dispatch(loginFail(error?.response?.data?.message));
        toastr.error(error?.response?.data?.message);
      } else {
        dispatch(loginFail("No network connecton"));
        toastr.error("No network connecton");
      }
    }
  };

export const setPasswordRequest =
  (payload: SetPassword, cb: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setPasswordLoading());
    try {
      const response = await API.post("/login/confirm_recovery", payload);
      if ([200, 201].includes(response.status)) {
        dispatch(setPasswordSuccess(response.data));
        toastr.success("", response.data.message);

        cb && cb();
      }
    } catch (error: any) {
      if (error.response) {
        dispatch(setPasswordFail(error?.response?.data?.message));
        toastr.error(error.response.data.message);
      } else {
        dispatch(setPasswordFail("No network connecton"));
        toastr.error("No network connecton");
      }
      setTimeout(() => {
        dispatch(setPasswordFail(""));
      }, 3000);
    }
  };

export const setPasswordNew =
  (payload: any, cb: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(setPasswordLoading());
    try {
      // const response = await API.post("/users/change_password", payload);
      const response = await API.post(`/users/change_password`, payload, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(setPasswordSuccess(response.data));
        toastr.success("", response.data.message);

        cb && cb();
      }
    } catch (error: any) {
      if (error.response) {
        dispatch(setPasswordFail(error?.response?.data?.message));
        toastr.error(error.response.data.message);
      } else {
        dispatch(setPasswordFail("No network connecton"));
        toastr.error("No network connecton");
      }
      setTimeout(() => {
        dispatch(setPasswordFail(""));
      }, 3000);
    }
  };

export const forgetPassword =
  (payload: ForgetPassword, cb: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(forgetPasswordLoading());

    try {
      const response = await API.post("/login/forgot_password", payload);
      // if (response.status) {
      //     dispatch(forgetPasswordSuccess(response.data));
      //     setTimeout(() => {
      //         cb();
      //     }, 1500);
      // }
      if ([200, 201].includes(response.status)) {
        dispatch(forgetPasswordSuccess(response.data));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error: any) {
      if (error.response) {
        dispatch(forgetPasswordFail(error?.response?.data?.message));
        toastr.error(error.response.data.message);
      } else {
        dispatch(forgetPasswordFail("No network connecton"));
        toastr.error("No network connecton");
      }
      setTimeout(() => {
        dispatch(forgetPasswordFail(""));
      }, 3000);
    }
  };

export default reducer;
