import React from "react";
import Search from "assests/icons/search-outline.png";
import "./styles.scss";

type Props = {
  placeholder?: string;
  classes?: string;
  type?: string;
  boxClasses?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: string;
  textRef?: any;
  inputWidth?: string;
};

const SearchIcon = ({ placeholder, classes, type = "text", boxClasses, onChange, value, textRef, inputWidth }: Props) => {
  return (
    <div className={`form-grop ${boxClasses}`}>
      <span>
        <img src={Search} alt="search" />
      </span>
      <input
        type="search"
        className="form-icon col-md-7 "
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        ref={textRef}
        style={{
          maxWidth: inputWidth
        }}
      />
    </div>
  );
};
export default SearchIcon;
