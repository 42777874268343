import React,{ useEffect } from "react";
import Modal from "components/Modal";
import Textbox from "components/Textbox";
import Button from "components/Button";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import SelectDropdown from "components/SelectDropdown";
import { editLocation, getLocations } from "store/slice/BuyerSlice/LocationSlice/LocationSlice";
import { createProductData, editProductData, getMaterials, uploadFile } from "store/slice/BuyerSlice/ProductSlice/ProductSlice";
import FileUploaderBox from "components/FileUploaderBox";
import { useState } from "react";
import { uploadProfilePicture } from "store/slice/SupplierSlices/UserSlice/UserSlice";
import { getAlluom } from "store/slice/UomSlice/Uom";
import API from "utils/httpsRequest";
import { Controller } from "react-hook-form";
import AsyncSelect from "react-select/async";
import {
  getMaterialCategories,
} from "store/slice/BuyerSlice/ProductSlice/ProductSlice";

type Props = {
  closeModal: () => void;
  singleProductData?: any;
};
const CreateOrEditProductData = ({ closeModal, singleProductData = null }: Props) => {
  const { register, handleSubmit, errors,control, setValue } = useForm<FormData>({
    mode: "onChange"
  });

  //Redux Store
  const dispatch = useDispatch<AppDispatch>();
  const { materialCategory, loading,isLoading } = useSelector((state: RootState) => state.product);
  const { uom } = useSelector((state: RootState) => state.uom)
  const { s3url } = useSelector((state: RootState) => state.product);
  const [uomSel, setUomSel] = React.useState("");
  const [selUomVal, setSelUomVal] = React.useState("");
  const [catSel, setCatSel] = React.useState("");
  const [selCatVal, setSelCatVal] = React.useState("");
  const { user } = useSelector((state: RootState) => state.user)
  const { token } = user;
 
  const handleInputChangeUom = value => {
    setUomSel(value);
  }
  
  const onChangeUom = value => {
    setSelUomVal(value);
  }

  const handleInputChangeCat = value => {
    setCatSel(value);
  }
  
  const onChangeCat = value => {
    setSelCatVal(value);
  }


  const [files, setFiles] = useState<any>([]);
  const [uploadBool, setUploadBool] = useState(false);
  const checkUpload = () => {
    setUploadBool(!uploadBool);
  };

  type FormData = {
    material_description: string;
    material_code: string;
    uom: string;
    // uom_id: string;
    material_category_id: string;
    price_per_unit: string;
    material_image_url: string;
    material_type: string;
    stock_type: string;
  };

  useEffect(() => {
    if (singleProductData !== null) {
      setValue("material_description", singleProductData.material_description);
      setValue("material_code", singleProductData.material_code);
      setValue("material_category_id", singleProductData.material_category_id);
      setValue("uom", singleProductData.uom);
      setValue("price_per_unit", singleProductData.price_per_unit);
    }
  }, [singleProductData]);
console.log(singleProductData)
  //Request
  useEffect(() => {
    dispatch(getAlluom());
    dispatch(getMaterialCategories(1,1000));
}, []);

  const onFormSubmit = (vals: FormData) => {
    if (singleProductData) {
      const payload = { ...vals, id: singleProductData.id,uom_id: selUomVal?.['value'], material_category_id: selCatVal?.['value'] };
      dispatch(
        editProductData(payload, () => {
          closeModal();
          dispatch(getMaterials());
        })
      );
      return;
    }

    const { material_image_url, ...rest } = vals;
        const payload = {
            ...rest,
            material_image_url: s3url,
            uom_id: selUomVal?.['value'],
            // material_category_id: selCatVal?.['value']

        };
       
    dispatch(
      createProductData(payload, () => {
        closeModal();
        
      })
    );
  };


  //MaterialCategory List
  const materialCategoryOptions =
    materialCategory &&
    materialCategory.data.map((_data: { id: string; material_category_description: string }) => {
      return {
        value: _data?.id,
        optionName: _data?.material_category_description
      };
    });

    const materialCategoryOptions2 = () => {
      return API.get(`/material_categories/get?search=${catSel}&length=1000`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }).then((res) => {
      
        return res.data.data.map((item) => (
          {
            label: item.material_category_description,
            value: item.id
          }
        ))
      })
    }

    //uom List
  const uomOptions =
  uom &&
  uom.map((_data: { id: string; uom_description: string }) => {
    return {
      value: _data?.id,
      optionName: _data?.uom_description
    };
  });

  const uomOptions2 = () => {
    return API.get(`/uoms/get?length=1000&search=${uomSel}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      return res.data.data.map((item) => (
        {
          label: item.uom_description,
          value: item.id
        }
      ))
    })
  }

    // handle file

    const handleFile = (e, field_name: any) => {
      const reader = new FileReader();
      reader.readAsDataURL(e);
      reader.onloadend = () => {
          // use a regex to remove data url part
          let read: any = reader.result;
          const payload = {
            base64_data: read.replace("data:", "").replace(/^.+,/, ""),
            file_extension: e.name.split(".").pop()
          };
         dispatch(
            uploadFile(payload)
          )
         
          setFiles(payload);
      };
  };


  const units = [
    // {
    //   value: "Volume",
    //   optionName: "Volume"
    // },
    {
      value: "Pack",
      optionName: "Pack"
    },
    {
      value: "Pieces",
      optionName: "Pieces"
    }
  ];

  const Stock = [
    {
      value: "Stock",
      optionName: "Stock"
    },
    {
      value: "Non-stock",
      optionName: "Non-stock"
    }
  ];
  const type = [
    {
      value: "Product",
      optionName: "Product"
    },
    {
      value: "Service",
      optionName: "Service"
    }
  ];

  return (
    <Modal
      modalText={singleProductData === null ? "Create" : "Edit Product Data"}
      onClose={closeModal}
      modallength="modal-length"
      isUpload={true}
      checkUpload={checkUpload} 
      uploadBool={uploadBool}
    >
      {
        uploadBool
        ?
        <div id="buyer-create-location">
          <p className="">Download and fill the entity template. adhere to file structure before uploading the Legal Entity(s)</p>
          <form className="mt-2">
            <p className="template">xxxx Teemplate sourcing team</p>

            <FileUploaderBox
              fileName=""
              boxClasses="mt-3 mb-5"
              handleFile={handleFile}
              acceptFiles={".jpg, .jpeg, .png"}
              customRef={register({ required: true })}
              error={errors["logo"] && "This field is required"}
            />

          <Button
            label={singleProductData === null ? "Create Product" : "Edit Product"}
            btnType="btn-primary"
            boxClasses="mt-4 mb-5"
            btnStyle="100%"
            loading={isLoading}
          />
          </form>
        </div>
      
        :
        <div id="buyer-create-location">
        <p className="">
          {singleProductData === null
            ? "Complete this form with accurate data to create Product Data."
            : "Complete this form with accurate data to Update Product Data."}
        </p>

        <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
          <Textbox
            type="number"
            name="material_code"
            label="Product ID"
            placeholder="Product ID"
            error={errors.material_code && "This field is required"}
            customRef={register({
              required: true
            })}
          />
          <Textbox
            type="text"
            label="Product Description"
            name="material_description"
            placeholder="Product Description"
            boxClasses="mt-3 mb-4"
            error={errors.material_description && "This field is required"}
            customRef={register({ required: true })}
          />
          {/* <SelectDropdown
            boxClasses="mt-3 mb-3"
            name="uom"
            label="Unit Of Measure"
            placeholder="Unit Of Measure"
            options={units}
            customRef={register({ required: true })}
            error={errors.uom && "This field is required"}
          /> */}
          {/* <SelectDropdown
            boxClasses="mt-3"
            name="uom_id"
            label="Unit Of Measure ID"
            placeholder="Unit Of Measure"
            options={uomOptions}
            customRef={register({ required: true })}
            error={errors.uom_id && "This field is required"}
          /> */}
           <Controller
            control={control}
            defaultValue
            boxClasses="mt-3"
            name="uom"
            render={({ ref }) => (
              <AsyncSelect
                cacheOptions
                defaultOptions
                placeholder="Unit Of Measure ID"
                value={selUomVal}
                loadOptions={uomOptions2}
                onInputChange={handleInputChangeUom}
                onChange={onChangeUom}
              />
            )} />
          {/* <SelectDropdown
            boxClasses="mt-3"
            name="material_category_id"
            label="Material Category"
            placeholder="Material Category"
            options={materialCategoryOptions}
            customRef={register({ required: true })}
            error={errors.material_category_id && "This field is required"}
          /> */}
          <SelectDropdown
              boxClasses="mt-3 mb-3"
              name="stock_type"
              label="Stock type"
              placeholder="Stock type"
              options={Stock}
              customRef={register({ required: true })}
              error={errors.stock_type && "This field is required"}
          />

          <SelectDropdown
            boxClasses="mt-3 mb-4"
            name="material_type"
            label="Material Type"
            placeholder="Material Type"
            options={type}
            customRef={register({ required: true })}
            onChange={(e) => {
              console.log(e.target.value,'target')
              // const countryID = countries.find((country) => country.country_description === e.target.value);
              dispatch(getMaterialCategories(undefined, 100000, undefined,undefined,undefined, e.target.value));
            }}
            error={errors.material_category_id && "This field is required"}
          />

        <SelectDropdown
            boxClasses="mt-3 mb-4"
            name="material_category_id"
            label="Material Category"
            placeholder="Material Category"
            options={materialCategoryOptions}
            customRef={register({ required: true })}
            error={errors.material_category_id && "This field is required"}
          />

          {/* <Controller
            control={control}
            defaultValue
            boxClasses="mt-3"
            name="material_category_id"
            render={({ ref }) => (
              <AsyncSelect
                cacheOptions
                defaultOptions
                placeholder="Material Category"
                value={selCatVal}
                loadOptions={materialCategoryOptions2}
                onInputChange={handleInputChangeCat}
                onChange={onChangeCat}
              />
            )} /> */}

        
          <Textbox
            type="number"
            label="Price"
            name="price_per_unit"
            placeholder="Price"
            boxClasses="mt-3"
            error={errors.price_per_unit && "This field is required"}

            customRef={register({
              required: true
            })}
          />

          {singleProductData === null && (
                        <>
                            <FileUploaderBox
                                placeholder="Material Image"
                                fileName="material_image_url"
                                boxClasses="mt-3 mb-2"
                                label="Material Image"
                                handleFile={handleFile}
                                acceptFiles={".jpg, .jpeg, .png"}
                            />
                        </>
                    )}

          <Button
            label={singleProductData === null ? "Create Product" : "Edit Product"}
            btnType="btn-primary"
            boxClasses="mt-4 mb-5"
            btnStyle="100%"
            loading={isLoading}
          />
        </form>
      </div>
   
      }
      
    </Modal>
  );
};

export default CreateOrEditProductData;
