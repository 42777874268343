const CheckBox2 = ({ label, value, handleInput, name }) => {
  return (
    <div className="checkbox">
      {/* <input type="radio" value={value} onChange={handleInput} name={name} id={name + "_" + value} /> */}
      <label htmlFor={name + "_" + value}>{label}</label>
    </div>
  );
};

export default CheckBox2;
