import StatsCard from "components/StatsCard";
import Icon from "assests/icons/statsCard/Vector (4).png";
import SearchBar from "../../../components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import BusinessTrade from "assests/icons/buyer/business-and-trade.svg";
import { tableCol, tableData } from "./data";
import "./styles.scss";
import React, { useEffect, useState } from "react";
import { AppDispatch, RootState } from "store/store";
import { useDispatch, useSelector } from "react-redux";
import useSearch from "hooks/useSearch";
import CreateOrEditReport from "components/ModalBuyer/CreateOrEditReport";
import Pagination from "components/Pagination";
import { getAllReport } from "store/slice/BuyerSlice/ReportSlice/RepotSlice";
import { useHistory } from "react-router";
const Report = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [singleReport, setSingleReport] = useState<any>(null);
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const { reports, loading } = useSelector((state: RootState) => state.report);
  const [modal, setModal] = useState<boolean>(false);
  const closeModal = () => {
    setModal((prev) => !prev);
    setSingleReport(null);
  };

  //Hook
  // const { search, inputRef, handleChange } = useSearch(getAllReport, perPage);

  //Show Number for PerPage Request
  const handleNewPage = (pageNo: number, page: number = 1) => {
    setPerPage(pageNo);
    setPage(page);
    dispatch(getAllReport(page, pageNo));
  };

  // setpage to the first page when a searched executed
  // useEffect(() => {
  //     if (searched) setPage(1);
  // }, [searched]);

  useEffect(() => {
    // dispatch(getAllReport(page,perPage));
  }, []);

  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback((page: number, perPage: number) => {
    setPage(page);
    dispatch(getAllReport(page, perPage));
  }, []);

  const paginate = React.useCallback((pageNo: number, perPage: number) => {
    setPage(pageNo);
    dispatch(getAllReport(pageNo, perPage));
  }, []);

  const handleView = (e, item) => {
    history.push(`/buyer/report/${item.link}`);
  };
  //   const deleteCompany = (name: string, id: string) => {
  //     const payload = { id: id };

  //     toastPopUp(
  //       () => {
  //         dispatch(
  //           deleteCompanyData(payload, () => {
  //             dispatch(getAllReport(page,perPage));
  //           })
  //         );
  //       },
  //       "Delete",
  //       "Cancel",
  //       "Delete the entity",
  //       name,
  //       "red"
  //     );
  //   };

  // const editCompanyData = (id: string, desc: string, code: string,country:string) => {
  //     setSingleCompanyData({ id, desc, code, country });
  //     setModal((prev) => !prev);
  //   };

  const reportList =
    reports &&
    reports?.data?.map((company) => {
      return {
        company_id: company.id,
        company_description: company.company_description,
        company_code: company.company_code,
        currency: company.currency_iso ? company.currency_iso : "N/A"
        // edit: () => editCompanyData(company.id, company.company_description, company.company_code, company.country_id),
        // deleteEntity: () => deleteCompany(company.location_description, company.id)
      };
    });

  return (
    <div id="report">
      {modal && <CreateOrEditReport closeModal={closeModal} />}

      <div className="container">
        <h3 className="header">Reporting Dashboard</h3>
        <div className="mt-4 stats-card">
          <StatsCard statsNumber={5} text="Reports" classes="stats-icon" image={BusinessTrade} iconBackgroundColor="rgba(45, 156, 219, 0.1)" />
        </div>
        <div className="document mt-5">
          {/* <h3 className="title font-weight-bold">Reporting List</h3> */}
          <div className="search-field mt-3">
            {/* <SearchBar placeholder="Search for Product name, ID" /> */}
            <Dropdown perPage={10} action={handleNewPage} />
          </div>
          <div className="mt-3">
            <DataTable columns={tableCol} data={tableData} onClick={handleView} />
            {reports?.count > 10 ? (
              <Pagination page={page} lastPage={reports?.pagination?.lastPage} paginate={paginate} total={reports?.pagination?.total} handlePageChange={handlePageChange} perPage={perPage} />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;
