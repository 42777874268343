import React from "react";
import Modal from "components/Modal";
import Textbox from "components/Textbox";
import Button from "components/Button";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { createPoReq, getAllPOReq } from "store/slice/BuyerSlice/CompanyDataSlice/CompanyDataSlice";

type Props = {
  closeModal: () => void;
  singleCompanyData?: any;
};
const CreateOREditLegacyPo = ({ closeModal }: Props) => {
  const { register, handleSubmit, errors } = useForm<FormData>({
    mode: "onChange"
  });

  //State

  type FormData = {
    purchase_order_code: string;
  };

  //Redux Store
  const dispatch = useDispatch<AppDispatch>();

  const { isLoadingReq } = useSelector((state: RootState) => state.companiesData);

  // const { user } = useSelector((state: RootState) => state.user);

  const onFormSubmit = (vals) => {
    dispatch(
      createPoReq(vals, () => {
        closeModal();
        dispatch(getAllPOReq());
      })
    );
  };

  return (
    <Modal modalText="Request Legacy Purchase Order" isUpload={false} onClose={closeModal} modallength="modal-length">
      <div id="buyer-legal-entity">
        <p className="">Do you have open purchase orders that have not been completed prior to 1st of Jan 2022?, you can request for it to be added to your queue</p>

        <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
          <Textbox
            type="text"
            name="purchase_order_code"
            label="Purchase Order Code"
            placeholder=""
            error={errors.purchase_order_code && "This field is required"}
            customRef={register({
              required: true
            })}
            boxClasses="mb-3"
          />

          <Button label="Submit" btnType="btn-primary" boxClasses="mt-5 mb-5" btnStyle="100%" loading={isLoadingReq} />
        </form>
      </div>
    </Modal>
  );
};

export default CreateOREditLegacyPo;
