import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CitiesState, Action } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";

const initialState: CitiesState = {
  isLoading: false,
  cities: null,
  successMessage: "",
  errorMessage: ""
};

const citySlice = createSlice({
  name: "states",
  initialState,
  reducers: {
    getAllCitiesLoading: (state) => {
      state.isLoading = true;
    },
    getAllCitiesSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.cities = action.payload;
    },
    getAllCitiesFail: (state) => {
      state.isLoading = false;
    }
  }
});

const { actions, reducer } = citySlice;
export const { getAllCitiesLoading, getAllCitiesSuccess, getAllCitiesFail } = actions;

export const getAllCities =
  (page?: number, length?: number, country_id?: string, state_id?: string, search?: string, sortBy?: string, orderBy?: string, city_id?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(getAllCitiesLoading());
    try {
      const response = await API.get("/cities/get", {
        params: {
          page,
          length,
          country_id,
          state_id,
          search,
          sortBy,
          orderBy
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getAllCitiesSuccess(response.data.data));
      }
    } catch (error) {
      dispatch(getAllCitiesFail());
    }
  };

export default reducer;
