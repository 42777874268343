import React, { useEffect } from "react";
import AuthLayout from "./AuthContainer/AuthLayout";
import image from "assests/illustrations/sign.svg";
import ErrorMesssage from "components/ErrorMessage";
import Textbox from "components/Textbox";
import Button from "components/Button";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
// import { loginFail, loginRequest } from "store/slice/AuthSlice/AuthSlice";
import { AppDispatch, RootState } from "store/store";
import { loginFail, loginRequest } from "store/slice/SupplierSlices/AuthSlice/AuthSlice";

type Props = {};
const Login: React.FC = (props: Props) => {
  type FormData = {
    username: string;
    password: string;
  };

  const dispatch = useDispatch<AppDispatch>();
  const { errorMessage, isLoading } = useSelector((state: RootState) => state.vendorAuth);

  const { register, handleSubmit, setError, errors } = useForm<FormData>({
    mode: "onBlur"
  });

  const onFormSubmit = (vals: FormData) => {
    dispatch(loginRequest(vals));
  };

  useEffect(() => {
    dispatch(loginFail(""));
  }, []);

  return (
    <AuthLayout
      headerText="Sign in to"
      bottomImage={image}
      content="Enter your email address & password to sign In to your GZI Supplier Portal account."
      colorText="GZI Supplier Portal"
      firstLink="Forgot your password?"
      firstLinkColor="Click here"
      secondLink="Having trouble signing in?"
      secondLinkColor="Contact Support"
      link="/supplier/forgot-password"
      linkTo="http://supplier-docs.gzi.yaraa.io/support/home"
    >
      <div>
        {errorMessage !== "" && <ErrorMesssage message={errorMessage} />}
        <form className="pt-3" onSubmit={handleSubmit(onFormSubmit)}>
          <Textbox
            type="email"
            label="Email Address"
            placeholder="Enter Email"
            name="username"
            error={errors.username && errors.username.message}
            boxClasses="pb-4"
            customRef={register({
              required: "This field is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address"
              }
            })}
          />
          {/* <Textbox
                        type="text"
                        label="Username"
                        placeholder="Enter Username"
                        name="username"
                        error={errors.username && errors.username.message}
                        boxClasses="pb-4"
                        customRef={register({
                            required: "This field is required"
                        })}
                    /> */}
          <Textbox
            type="password"
            label="Password"
            placeholder="Enter Password"
            name="password"
            error={errors.password && "This field is required"}
            boxClasses="pb-4"
            customRef={register({
              required: true
            })}
          />
          <Button label="Sign In" btnType="btn-primary" btnStyle="100%" loading={isLoading} />
        </form>
      </div>
    </AuthLayout>
  );
};

export default Login;
