import DropdownMenu from "components/DropdownMenu";
import "./styles.scss";

export const budgetLineCol = [
    {
        name: 'Name',
        index: 'name',
        id: 1
    },
    {
        name: 'Department',
        index: 'department',
        id: 2
    },
    {
        name: 'Mat. Cat',
        index: 'material_category',
        id: 3
    },
    {
        name: 'Currency',
        index: 'currency',
        id: 4
    },
    {
        name: 'Amount',
        index: 'amount',
        id: 5
    },
    {
        name: 'Ledger Balance',
        index: 'ledger_balance',
        id: 6
    },
    {
        name: 'Action',
        id: 7,
        render: ({
            id,
            view,
            edit,
            deleteId,
        }) => {
            const settings = [
                // {
                //     name: 'View',
                //     style: 'activate_tag',
                //     select: view,
                // },
                // {
                //     name: 'Edit',
                //     style: 'activate_tag',
                //     select: edit
                // },
                {
                    name: 'Delete',
                    style: 'delete_tag',
                    select: deleteId
                }
            ];
            return (
                <span>
                    <DropdownMenu options={settings} boxClasses="dropdown-style">
                        <p style={{ fontSize: "2rem" }} className="cursor-pointer" id={id}>
                            ...
                        </p>
                    </DropdownMenu>
                </span>
            )
        }
    },
]