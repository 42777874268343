import StatsCard from "components/StatsCard";
import React, { useEffect, useState } from "react";
import "../styles.scss";
import Target from "assests/icons/buyer/target.svg";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import SearchIcon from "components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { KpiColumn } from "../data";
import CreateKPIModal from "components/ModalBuyer/CreateKPI";
import CreateKpiGroup from "components/ModalBuyer/CreateKPIGroup";
import { toastPopUp } from "utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { useHistory } from "react-router-dom";
import { deleteKpiGroup, getKpiGroup } from "store/slice/BuyerSlice/KpiSetupSlice/KpiSetupSlice";
import ApprovalWorkFlowSteps from "../../ApprovalWorkFlowSteps";
import { checkPermission } from "utils/utils";

const KpiGroup = () => {
    const [modal, setModal] = useState<boolean>(false);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const history = useHistory()
   const [singleGroup, setSingleGroup]= useState(null)
    const closeModal = () => {
        setModal((prev) => !prev);
        setSingleGroup(null)
    };

    const dispatch = useDispatch<AppDispatch>();

    const { role_access } = useSelector((state: RootState) => state.user?.user);

  
    const { kpiGroup, kpiGroupLoading } = useSelector((state: RootState) => state.kpisetup);

    console.log(singleGroup,'jj')
     //Edit CompanyData
     const editKpiGroup_two = (data) => {
        setSingleGroup(data);
        setModal((prev) => !prev);
      };

    //   deleteCompany

    const deleteGroup2 = (name: string, id: string) => {
        const payload = { kpi_group_id: id };
        toastPopUp(
          () => {
            dispatch(
    
              deleteKpiGroup(payload, () => {
                dispatch(getKpiGroup({page,length:perPage}));
              })
            );
          },
          "Delete",
          "Cancel",
          "Delete the entity",
          name,
          "red"
        );
      };

      const showProcess = (id,name) => {
        localStorage.setItem("name",name );
        history.push({ pathname: `/buyer/settings/sourcing/${id}`});
      };

          //Show Number for PerPage Request
    const handleNewPage = (pageNo: number, page: number = 1) => {
      setPerPage(pageNo);
      setPage(page);
      dispatch(getKpiGroup({page,length:pageNo}));
  };

  const handlePageChange = React.useCallback(
    (page: number, perPage: number) => {
      setPage(page);
      dispatch(getKpiGroup({page,length:perPage}));
    },
    []
  );

  useEffect(() => {
    dispatch(getKpiGroup({page,length:perPage}));
  }, []);
  
  

  const paginate = React.useCallback(
    (pageNo: number, perPage: number) => {
      setPage(pageNo);
      dispatch(getKpiGroup({page,length:perPage}));
    },
    []
  );

    const KpigroupList =
    kpiGroup &&
    kpiGroup?.data?.map((item) => {
        
      return {
        kpi_group_description: item.kpi_group_description,
        project_type: item.project_type,
        viewSteps:()=>showProcess(item.id, item.kpi_group_description),
        edit: () => checkPermission(role_access, "SETUP_INDEX") && editKpiGroup_two(item),
        deleteWork: () => checkPermission(role_access, "SETUP_INDEX") && deleteGroup2(item.kpi_group_description, item.id)
      };
    });

    return (
        <div id="settings-sourcing">
            {modal && <CreateKpiGroup singleGroup={singleGroup}  closeModal={closeModal} />}
            <div className="container mt-3">
                <div className="row mt-3 mb-5">
                    <StatsCard
                        statsNumber={14}
                        text="KPI’s"
                        classes="stats-icon"
                        image={Target}
                        iconBackgroundColor="rgba(45, 156, 219, 0.1)"
                    />
                    <StatsCard image={AddIcon} classes="middle-img" centerText="Create KPI Group" onClick={closeModal} />
                </div>

                <div className="d-flex  mb-3">
                    <SearchIcon placeholder="Search for KPI Group" boxClasses="" />
                    <Dropdown perPage={10} action={handleNewPage} />
                </div>
                <DataTable columns={KpiColumn} data={KpigroupList} />
            </div>
        </div>
    );
};

export default KpiGroup;
