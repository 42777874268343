const FilterCard = (props) => {
    return <form style={{
        background: '#F2F2F2',
        padding: '2rem 4rem',
        marginTop: '2rem',
        borderRadius: '10px',
        marginBottom: '2rem',
    }}
        onSubmit={props.handleSubmit}
    >
        <div>
            {props.children}
            <div style={{
                textAlign: 'right',
                paddingTop: '1rem',
            }}>
                <p
                    style={{
                        display: 'inline-block',
                        marginRight: '1rem',
                        cursor: 'pointer'
                    }}
                    onClick={props.clear}>Clear</p>
                <button style={{
                    border: '2px solid #2F80ED',
                    padding: '10px 15px',
                    minWidth: '10rem',
                    borderRadius: '6px',
                    color: '#2F80ED',
                }}>Filter</button>
            </div>
        </div>
    </form>
}

export default FilterCard;