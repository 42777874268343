import React, { useEffect } from "react";
import Modal from "components/Modal";
import ArrowDropdown from "components/ArrowDropdown";
import Textbox from "components/Textbox";
import Button from "components/Button";
import useArrowDropdown from "hooks/useArrowDropdown";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getCompaniesEntityList } from "store/slice/CompaniesSlice/CompaniesSlice";
import { AppDispatch, RootState } from "store/store";
import SelectDropdown from "components/SelectDropdown";
import { createDepartment, editDepartment, getDepartments } from "store/slice/BuyerSlice/DepartmentSlice/DepartmentSlice";
import { addMember, createSource, editSource, getSource } from "store/slice/BuyerSlice/TeamSlice/TeamSlice";
import { getUsers } from "store/slice/UserMgtSlice/UserMgtSlice";
import { getMaterialCategories } from "store/slice/BuyerSlice/ProductSlice/ProductSlice";
import { createBusiness, createBusinessCat, getBusiness, getBusinessCat } from "store/slice/BuyerSlice/BusinessSlice/BusinessSlice";
import { Controller } from "react-hook-form";
import AsyncSelect from "react-select/async";
import API from "utils/httpsRequest";

type Props = {
  closeModal: () => void;
  singleBusinessCategory?: any;
};
const CreateOrEditBusinessCategory = ({ closeModal, singleBusinessCategory = null }: Props) => {
  //State
  const [error, setError] = React.useState("");
  const [parentSel, setParentSel] = React.useState("");
  const [selParentVal, setSelParentVal] = React.useState("");
  const { user } = useSelector((state: RootState) => state.user);
  const { token } = user;

  type FormData = {
    business_category_description: string;
    business_category_code: string;
  };

  const handleInputChangeParent = (value) => {
    setParentSel(value);
  };

  const onChangeParent = (value) => {
    setSelParentVal(value);
  };

  const parentOptions = () => {
    return API.get(`/business_categories/get?search=${parentSel}&length=20000&business_category_type=Parent`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: item.business_category_description,
        value: item.id
      }));
    });
  };

  //Setting field on edit mode
  // useEffect(() => {
  //   if (singleBusinessCategory !== null) {
  //     setValue("sourcing_team_description", singleBusinessCategory?.sourcing_team_description);
  //   }
  // }, [singleBusinessCategory]);

  //Redux Store
  const dispatch = useDispatch<AppDispatch>();

  //Hooks
  const { register, handleSubmit, errors, setValue, control } = useForm<FormData>({ mode: "onBlur" });
  const { open, selectedOption, handleDropdownChange, handleAction, keyOption, ref } = useArrowDropdown();
  const { loadingCat, isLoadingCat } = useSelector((state: RootState) => state.business);

  const onFormSubmit = (vals: FormData) => {
    const payload = {
      ...vals,
      parent_business_category_id: selParentVal["value"] ? selParentVal["value"] : null
    };
    dispatch(
      createBusinessCat(payload, () => {
        closeModal();
        dispatch(getBusinessCat(1, 10));
      })
    );
  };

  return (
    <Modal modalText={singleBusinessCategory === null ? "Create" : "Edit"} onClose={closeModal} modallength="modal-length">
      <div id="buyer-create-location">
        <p className="">Create Business Category </p>

        <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
          <Textbox
            type="text"
            name="business_category_description"
            label="Business Description"
            placeholder="Business Description"
            error={errors.business_category_description && "This field is required"}
            customRef={register({
              required: true
            })}
          />

          <Textbox
            type="text"
            name="business_category_code"
            label="Business Code"
            boxClasses="mt-3 mb-3"
            placeholder="Business Code"
            error={errors.business_category_code && "This field is required"}
            customRef={register({
              required: true
            })}
          />

          <Controller
            control={control}
            defaultValue
            boxClasses="mt-3"
            name="parent_business_category_id"
            render={({ ref }) => (
              <AsyncSelect cacheOptions defaultOptions placeholder="Parent Business Category" value={selParentVal} loadOptions={parentOptions} onInputChange={handleInputChangeParent} onChange={onChangeParent} />
            )}
          />

          <Button label="Create Business Category" btnType="btn-primary" boxClasses="mt-5 mb-5" btnStyle="100%" loading={isLoadingCat} />
        </form>
      </div>
    </Modal>
  );
};

export default CreateOrEditBusinessCategory;
