/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Modal from "components/Modal";
import ArrowDropdown from "components/ArrowDropdown";
import Textbox from "components/Textbox";
import Button from "components/Button";
import useArrowDropdown from "hooks/useArrowDropdown";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { useEffect } from "react";
import { getAllCompaniesData } from "store/slice/BuyerSlice/CompanyDataSlice/CompanyDataSlice";
import { getAllcurrencies } from "store/slice/CurrencySlice/CurrencySlice";
import { getAllCountries } from "store/slice/CountriesSlice/CountriesSlice";
import { getDepartments } from "store/slice/BuyerSlice/DepartmentSlice/DepartmentSlice";
import { getLocations } from "store/slice/BuyerSlice/LocationSlice/LocationSlice";
import { createWorkFlow_two, editWorkFlow_two, getApprovals_two } from "store/slice/BuyerSlice/ApprovalSlice/ApprovalSlice";
import SelectDropdown from "components/SelectDropdown";
import { getMaterialCategories } from "store/slice/BuyerSlice/ProductSlice/ProductSlice";
import { Controller } from "react-hook-form";
import API from "utils/httpsRequest";
import AsyncSelect from "react-select/async";
import useSearch from "hooks/useSearch";
import { useState } from "react";
import { getPurchaseGroup } from "store/slice/BuyerSlice/PurchaseGroup/PurchaseGroup";

type Props = {
  closeModal: () => void;
  singleWorkFlow?: any;
};
const CreateWorkFlow = ({ closeModal, singleWorkFlow = null }: Props) => {
  const [error, setError] = React.useState("");
  const { register, handleSubmit, errors, control, setValue } = useForm<FormData>({
    mode: "onChange"
  });
  const { user } = useSelector((state: RootState) => state.user);
  const { token } = user;
  const [catSel, setCatSel] = React.useState("");
  const [selCatVal, setSelCatVal] = React.useState("");
  const [companySel, setCompanySel] = React.useState("");
  const [selCompanyVal, setSelCompanyVal] = React.useState("");
  const [locationSel, setLocationSel] = React.useState("");
  const [selLocationVal, setSelLocationVal] = React.useState("");
  const [deptSel, setDeptSel] = React.useState("");
  const [selDeptVal, setSelDeptVal] = React.useState("");
  const [initialId, setInitailId] = React.useState("");
  const [dept, setDept] = React.useState({});

  const handleInputChangeCat = (value) => {
    setCatSel(value);
  };

  const onChangeCat = (value) => {
    setSelCatVal(value);
  };

  const handleInputChangeCompany = (value) => {
    setCompanySel(value);
  };

  const onChangeCompany = (value) => {
    // console.log(value,'valuee')
    // setInitailId(value.value)
    setSelCompanyVal(value);
  };

  const handleInputChangeLocation = (value) => {
    setLocationSel(value);
  };

  const onChangeLocation = (value) => {
    setSelLocationVal(value);
  };

  const handleInputChangeDept = (value) => {
    // console.log(initialId)
    // deptOptions2(initialId)
    setDeptSel(value);
  };

  const onChangeDept = (value) => {
    setSelDeptVal(value);
  };

  type FormData = {
    process_name: string;
    department_id: string;
    location_id: string;
    company_id: string;
    material_category_id: string;
    purchasing_group_id: string;
  };

  //Redux Store
  const dispatch = useDispatch<AppDispatch>();
  const { companies } = useSelector((state: RootState) => state.companiesData);
  const { loading, isLoading } = useSelector((state: RootState) => state.approvals);
  const { countries } = useSelector((state: RootState) => state.country);
  const { departments } = useSelector((state: RootState) => state.department);
  const { purchaseGroup } = useSelector((state: RootState) => state.purchaseGroup);
  const { locations } = useSelector((state: RootState) => state.location);
  const { materialCategory } = useSelector((state: RootState) => state.product);

  useEffect(() => {
    if (singleWorkFlow !== null) {
      setValue("process_name", singleWorkFlow?.proccess_name);
      setValue("location_id", singleWorkFlow?.location_id);
      setValue("company_id", singleWorkFlow?.company_id);
      setValue("department_id", singleWorkFlow?.department_id);
      setValue("purchasing_group_id", singleWorkFlow?.purchasing_group_id);
    }
  }, [singleWorkFlow]);

  console.log(singleWorkFlow, "kkjkjkk");

  useEffect(() => {
    dispatch(getAllCountries());
    dispatch(getAllcurrencies());
    dispatch(getAllCompaniesData(1, 1000));
    dispatch(getDepartments(1, 1000));
    dispatch(getPurchaseGroup(1, 1000));
    dispatch(getLocations(1, 1000));
    dispatch(getMaterialCategories(1, 1000));
  }, []);

  const onFormSubmit = (vals: FormData) => {
    if (singleWorkFlow) {
      const payload = {
        ...vals,
        id: singleWorkFlow.id,
        material_category_id: selCatVal?.["value"],
        location_id: selLocationVal?.["value"]
        // company_id: selCompanyVal?.["value"],
        // material_category_id: selCatVal?.["value"],
        // location_id: selLocationVal?.["value"],
        // department_id: selDeptVal?.["value"] ,
      };

      dispatch(
        editWorkFlow_two(payload, () => {
          closeModal();
          dispatch(getApprovals_two());
        })
      );
      return;
    }

    const payload = {
      ...vals,
      // company_id: selCompanyVal?.["value"],
      material_category_id: selCatVal?.["value"],
      location_id: selLocationVal?.["value"]
      // department_id: selDeptVal?.["value"]
    };
    dispatch(
      createWorkFlow_two(payload, () => {
        closeModal();
        dispatch(getApprovals_two());
      })
    );
  };

  const materialCategoryOptions2 = () => {
    return API.get(`/material_categories/get?search=${catSel}&length=1000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: item.material_category_description,
        value: item.id
      }));
    });
  };

  materialCategoryOptions2();

  const companiesOptions2 = () => {
    return API.get(`/companies/get?search=${companySel}&length=1000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: item.company_description,
        value: item.id
      }));
    });
  };

  const locationOptions2 = () => {
    return API.get(`/locations/get?search=${locationSel}&length=1000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: item.location_description,
        value: item.id
      }));
    });
  };

  // const deptOptions2 = (id) => {
  //   return API.get(`/departments/get?search=${companySel}&company_id=${id}`, {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       "Content-Type": "application/json"
  //     }
  //   }).then((res) => {
  //     console.log(res,'departtt')
  //     setDept({

  //     })
  //     return res.data.data.map((item) => ({
  //       label: item.department_description,
  //       value: item.id
  //     }));
  //   });
  // };

  // const deptOptions2 = () => {
  //   return API.get(`/departments/get?search=${deptSel}&length=1000`, {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       "Content-Type": "application/json"
  //     }
  //   }).then((res) => {

  //     return res.data.data.map((item) => ({
  //       label: item.department_description,
  //       value: item.id
  //     }));
  //   });
  // };

  // getting all companies
  const companiesOptions =
    companies &&
    companies.data.map((_data: { id: string; company_description: string }) => {
      return {
        value: _data?.id,
        optionName: _data?.company_description
      };
    });

  //Getting Countries
  const countriesOptions =
    countries &&
    countries.map((_data: { country_description: string; id: string }) => {
      return {
        value: _data.id,
        optionName: _data.country_description
      };
    });

  //Getting Countries
  const departmentOptions =
    departments &&
    departments.data.map((_data: { department_description: string; id: string }) => {
      return {
        value: _data.id,
        optionName: _data.department_description
      };
    });

  const purchaseOptions =
    purchaseGroup &&
    purchaseGroup.data.map((_data: { purchasing_group_description: string; id: string }) => {
      return {
        value: _data.id,
        optionName: _data.purchasing_group_description
      };
    });
  //  getting locations
  const locationOptions =
    locations &&
    locations.data.map((_data: { location_description: string; id: string }) => {
      return {
        value: _data.id,
        optionName: _data.location_description
      };
    });
  // process
  const processOptions = [
    {
      value: "VENDOR_ONBOARDING",
      optionName: "Vendor Onboarding"
    },
    {
      value: "RFP_APPROVAL",
      optionName: "Rfp Approval"
    },
    {
      value: "PURCHASE_ORDER_APPROVAL",
      optionName: "Purchase Order Approval"
    },
    {
      value: "REQUISITION_APPROVAL",
      optionName: "Requisition Approval"
    },
    {
      value: "INVOICE_APPROVAL",
      optionName: "Invoice Approval"
    },
    {
      value: "PAYMENT_PROPOSAL_APPROVAL",
      optionName: "Payment Proposal Approval"
    },
    {
      value: "BANK_PROPOSAL_APPROVAL",
      optionName: "Bank Proposal Approval"
    }
  ];

  //MaterialCategory List
  const materialCategoryOptions =
    materialCategory &&
    materialCategory.data.map((_data: { id: string; material_category_description: string }) => {
      return {
        value: _data?.id,
        optionName: _data?.material_category_description
      };
    });

  const { open, selectedOption, handleDropdownChange, handleAction, keyOption, ref } = useArrowDropdown();

  return (
    <Modal modallength="modal-length" modalText={singleWorkFlow === null ? "Create Workflow." : "Edit Workflow"} onClose={closeModal}>
      <div id="buyer-create-location">
        <p className="">{singleWorkFlow === null ? "Complete this form with accurate data to create a new workflow." : "Complete this form with accurate data to edit workflow"}</p>
        <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
          <SelectDropdown
            boxClasses="mt-3"
            name="process_name"
            label="Process"
            placeholder="Process"
            options={processOptions}
            customRef={register({ required: true })}
            error={errors.process_name && "This field is required"}
          />
          <SelectDropdown
            boxClasses="mt-3"
            name="company_id"
            label="Legal Entity"
            placeholder="Legal Entity"
            options={companiesOptions}
            customRef={register({ required: true })}
            error={errors.company_id && "This field is required"}
            onChange={(e) => {
              // const countryID = countries.find((country) => country.country_description === e.target.value);
              dispatch(getDepartments(undefined, 100000, undefined, undefined, undefined, e.target.value));
            }}
          />
          {/* <Controller
            control={control}
            defaultValue
            boxClasses="mt-3"
            name="company_id"
            render={({ ref }) => (
              <AsyncSelect
                cacheOptions
                defaultOptions
                placeholder="Legal Entity"
                value={selCompanyVal}
                loadOptions={companiesOptions2}
                onInputChange={handleInputChangeCompany}
                onChange={onChangeCompany}
              />
            )}
          /> */}
          <br />

          <Controller
            control={control}
            defaultValue
            boxClasses="mt-3"
            name="location_id"
            render={({ ref }) => (
              <AsyncSelect
                cacheOptions
                defaultOptions
                placeholder="Location"
                value={selLocationVal}
                loadOptions={locationOptions2}
                onInputChange={handleInputChangeLocation}
                onChange={onChangeLocation}
              />
            )}
          />

          <br />
          {/* <Controller
            control={control}
            defaultValue
            boxClasses="mt-3"
            name="department_id"
            render={({ ref }) => (
              <AsyncSelect
                cacheOptions
                defaultOptions
                placeholder="Department"
                value={selDeptVal}
                // loadOptions={()=>{deptOptions2(initialId)}}
                loadOptions={deptOptions2}
                onInputChange={handleInputChangeDept}
                onChange={onChangeDept}
              />
            )}
          />

<br/> */}
          <Controller
            control={control}
            defaultValue
            boxClasses="mt-3"
            name="material_category_id"
            render={({ ref }) => (
              <AsyncSelect
                cacheOptions
                defaultOptions
                placeholder="Material Category"
                value={selCatVal}
                loadOptions={materialCategoryOptions2}
                onInputChange={handleInputChangeCat}
                onChange={onChangeCat}
              />
            )}
          />

          {/* <SelectDropdown
            boxClasses="mt-3"
            name="location_id"
            label="Location"
            placeholder="Location"
            options={locationOptions}
            customRef={register({ required: false })}
            // error={errors.location_id && "This field is required"}
          /> */}
          <SelectDropdown
            boxClasses="mt-3"
            name="purchasing_group_id"
            label="Purchase Group"
            placeholder="Purchase Group"
            options={purchaseOptions}
            customRef={register({ required: false })}
            error={errors.department_id && "This field is required"}
          />
          <SelectDropdown
            boxClasses="mt-3"
            name="department_id"
            label="Department"
            placeholder="Department"
            options={departmentOptions}
            customRef={register({ required: false })}
            error={errors.department_id && "This field is required"}
          />

          {/* <SelectDropdown
            boxClasses="mt-3"
            name="material_category_id"
            label="Material Category"
            placeholder="Material Category"
            options={materialCategoryOptions}
            customRef={register({ required: false })}
            // error={errors.material_category_id && "This field is required"}
          /> */}
          <Button label={singleWorkFlow === null ? "Create Workflow" : "Edit Workflow"} btnType="btn-primary" boxClasses="mt-4 mb-5" btnStyle="100%" loading={isLoading} />
        </form>
      </div>
    </Modal>
  );
};

export default CreateWorkFlow;
