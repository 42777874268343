import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { taxStates, Action } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";

const initialState: taxStates = {
    loading: false,
    deleting: false,
    isLoading: false,
    tax: null,
    successMessage: "",
    errorMessage: ""
};

const taxSlice = createSlice({
    name: "tax",
    initialState,
    reducers: {
        getAlllTaxLoading: (state) => {
            state.loading = true;
        },
        getAlllTaxSuccess: (state, action: PayloadAction<Action>) => {
            state.loading = false;
            state.tax = action.payload;
        },
        getAlllTaxFail: (state) => {
            state.loading = false;
        },
        createTaxLoading: (state) => {
            state.isLoading = true;
        },
        createTaxSuccess: (state, action: PayloadAction<Action>) => {
            state.isLoading = false;
            state.tax = action.payload;
        },
        createTaxFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
        editTaxLoading: (state) => {
            state.isLoading = true;
        },
        editTaxSuccess: (state, action: PayloadAction<Action>) => {
            state.isLoading = false;
            state.tax = action.payload;
        },
        editTaxFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
        deleteTaxLoading: (state) => {
            state.deleting = true;
        },
        deleteTaxSuccess: (state, action: PayloadAction<Action>) => {
            state.deleting = false;
            state.tax = action.payload;
        },
        deleteTaxFail: (state, action: PayloadAction<string>) => {
            state.deleting = false;
            state.errorMessage = action.payload;
        },
    }
});

const { actions, reducer } = taxSlice;
export const {
    getAlllTaxLoading,
    getAlllTaxSuccess,
    getAlllTaxFail,
    createTaxLoading,
    createTaxSuccess,
    createTaxFail,
    editTaxLoading,
    editTaxSuccess,
    editTaxFail,
    deleteTaxLoading,
    deleteTaxSuccess,
    deleteTaxFail
} = actions;

export const getTax =
    (
        page?: number,
        length?: number,
        search?: string,
        company_id?: string,
        tax_code?: string,
        tax_code_id?: string,
    
    ): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(getAlllTaxLoading());
        try {
            const response = await API.get("/tax_codes/get", {
                params: {
                    page,
                    length,
                    search,
                    company_id,
                    tax_code,
                    tax_code_id
                },
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(getAlllTaxSuccess(response.data));
            }
        } catch (error) {
            dispatch(getAlllTaxFail());
        }
    };

export const createTax =
    (data: any, cb?: () => void): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(createTaxLoading());
        try {
            const response = await API.post(`/tax_codes/create`, data, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(createTaxSuccess(response.data.message));
                toastr.success("", response.data.message);
                cb && cb();
            }
        } catch (error) {
            
            if (error.response) {
                dispatch(createTaxFail(error.response.data?.message));
                toastr.error(error.response.data.message);
              }
              else{
                dispatch(createTaxFail("No network connecton"));
                toastr.error("No network connecton");
              }
            setTimeout(() => {
                dispatch(createTaxFail(""));
            }, 3000);
        }
    };

export const editTax =
    (data: any, cb?: () => void): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(editTaxLoading());
        try {
            const response = await API.post(`/tax_codes/edit`, data, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(editTaxSuccess(response.data.message));
                toastr.success("", response.data.message);
                cb && cb();
            }
        } catch (error) {
            
            if (error.response) {
                dispatch(editTaxFail(error.response.data.message));
                toastr.error(error.response.data.message);
              }
              else{
                dispatch(editTaxFail("No network connecton"));
                toastr.error("No network connecton");
              }
            setTimeout(() => {
                dispatch(editTaxFail(""));
            }, 3000);
        }
    };

    export const deleteTax =
    (data?: any, cb?: () => void): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(deleteTaxLoading());
        try {
            const response = await API.post(`/tax_codes/delete`, data, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(deleteTaxSuccess(response.data.message));
                toastr.success("", response.data.message);
                cb && cb();
            }
        } catch (error) {
            dispatch(deleteTaxFail(error.response.data.message));
            toastr.error(error.response.data.message);
            setTimeout(() => {
                dispatch(deleteTaxFail(""));
            }, 3000);
        }
    };


export default reducer;
