import DropdownMenu from "components/DropdownMenu";
import "./styles.scss";

export const departmentData = [
    {
        id: "1000",
        name: "Finance & Accounts",
        code: "OP78-092",
        entity: "GZI Nigeria",
        location: "All",
        hod: "Tom Hendricks"
    },
    {
        id: "2000",
        name: "Admin Services",
        code: "OP78-092",
        entity: "GZI Nigeria",
        location: "Nigeria HQ",
        hod: "Busola Ayo"
    }
];

export const departmentColumn = [
    {
        name: "Department ID",
        index: "id",
        id: 1
    },
    {
        name: "Department Name",
        index: "department_description",
        id: 2
    },
    {
        name: "ERP Code",
        index: "department_code",
        id: 3
    },
    {
        name: "Assigned Entity",
        index: "company_description",
        id: 4
    },
    {
        name: "Assigned Location",
        index: "location_description",
        id: 5
    },
    {
        name: "Assigned HOD",
        index: "hod",
        id: 6
    },
    {
        name: "Actions",
        id: 7,
        render: ({ deleteDepartment4, edit }) => {
            const settings = [
                {
                    name: "Edit",
                    style: "edit_tag",
                    select: edit
                },
                {
                    name: "Delete",
                    style: "delete_tag",
                    select: deleteDepartment4
                }
            ];
            return (
                <span style={{ position: "relative" }}>
                    <DropdownMenu options={settings} boxClasses="dropdown-style">
                        <p style={{ fontSize: "2rem" }} className="cursor-pointer">
                            ...
                        </p>
                    </DropdownMenu>
                </span>
            );
        }
    }
];

export const purchaseGroupColumn = [
    {
        name: "Department ID",
        index: "id",
        id: 1
    },
    {
        name: "Purchasing Group Code",
        index: "purchasing_group_code",
        id: 2
    },
    {
        name: "Purchasing Group Description",
        index: "purchasing_group_description",
        id: 3
    },
    {
        name: "Actions",
        id: 7,
        render: ({ deletePurchaseGroup4, edit }) => {
            const settings = [
                {
                    name: "Edit",
                    style: "edit_tag",
                    select: edit
                },
                {
                    name: "Delete",
                    style: "delete_tag",
                    select: deletePurchaseGroup4
                }
            ];
            return (
                <span style={{ position: "relative" }}>
                    <DropdownMenu options={settings} boxClasses="dropdown-style">
                        <p style={{ fontSize: "2rem" }} className="cursor-pointer">
                            ...
                        </p>
                    </DropdownMenu>
                </span>
            );
        }
    }
];

export const locationData = [
    {
        id: "1000",
        name: "Ojuju C. Point",
        address: "Ikeja GRA",
        country: "Nigeria",
        relationship: "GZI Nigeria"
    },
    {
        id: "2000",
        name: "Softcom C. Point",
        address: "Lekki 1",
        country: "Nigeria",
        relationship: "GZI Nigeria"
    }
];

export const LocationColumn = [
    {
        name: "ID",
        index: "id",
        id: 1
    },
    {
        name: "Location Name",
        index: "location_description",
        id: 2
    },
    {
        name: "ERP Code",
        index: "location_id",
        id: 3
    },
    {
        name: "Address",
        index: "address",
        id: 4
    },
    {
        name: "Country",
        index: "country_description",
        id: 5
    },
    {
        name: "Relationship",
        index: "company_description",
        id: 6
    },
    {
        name: "Actions",
        id: 7,
        render: ({ deleteEntity, edit }) => {
            const settings = [
                {
                    name: "Edit",
                    style: "edit_tag",
                    select: edit
                },
                {
                    name: "Delete",
                    style: "delete_tag",
                    select: deleteEntity
                }
            ];
            return (
                <span style={{ position: "relative" }}>
                    <DropdownMenu options={settings} boxClasses="dropdown-style">
                        <p style={{ fontSize: "2rem" }} className="cursor-pointer">
                            ...
                        </p>
                    </DropdownMenu>
                </span>
            );
        }
    }
];
