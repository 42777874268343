import React from "react";
import SearchIcon from "components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import Pagination from "components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { addMaterials, getMaterials } from "store/slice/RequisitionSlice/RequisitionSlice";
import useSearch from "hooks/useSearch";
import { RootState } from "store/store";
import { materialCol } from "../data";
import { toastr } from "react-redux-toastr";

const Catalogue = () => {
  const dispatch = useDispatch();

  const [perpage, setPerpage] = React.useState<number>(10);
  const [page, setPage] = React.useState<number>(1);

  const { search, inputRef, handleChange, searched } = useSearch(getMaterials, perpage);
  const { isLoading, materials, singleRequisition } = useSelector((store: RootState) => store.requisition);

  const handleNewPage = React.useCallback(
    (pageNo: number, page = 1) => {
      setPerpage(pageNo);
      setPage(1);
      dispatch(getMaterials(page, pageNo, search, '', '', 'Catalog'));
    },
    [search]
  );

  const materialList =
    materials &&
    materials?.data?.map((item: any) => {
      return {
        image: item?.material_image_url || <img src="https://via.placeholder.com/50" alt="alt" />,
        sku: item?.material_code || "n/a",
        description: item?.material_description || "n/a",
        // price: `${item?.price_per_unit} NGN` || "n/a",
        price: item.price_per_unit === null ? "" : item.price_per_unit,
        category: item?.material_category_description || "n/a",
        material_id: item?.id,
        requisition_id: singleRequisition && singleRequisition["id"],
        addItem: (data) => {
          if (!data.quantity_required) {
            toastr.error('', 'Please fill in quantity')
          } else addItemToReq(data)
        }
      };
    });

  const paginate = React.useCallback(
    (pageNo: number, length: number) => {
      setPage(pageNo);
      dispatch(getMaterials(pageNo, length, search, '', '', 'Catalog'));
    },
    [search]
  );

  const handlePageChange = React.useCallback(
    (page: number, length: number) => {
      setPage(page);
      dispatch(getMaterials(page, length, search, '', '', 'Catalog'));
    },
    [search]
  );

  const addItemToReq = (data) => {
    const _data = { ...data };
    delete _data.addItem;
    delete _data.image;
    delete _data.sku;
    delete _data.description;
    delete _data.category;
    delete _data.price;
    dispatch(addMaterials(_data, (res) => handleSuccess(res)));
  };

  const handleSuccess = (res) => {
    console.log(res);
  };

  React.useEffect(() => {
    dispatch(getMaterials(page, perpage, '', '', '', 'Catalog'));
  }, []);

  return (
    <div className="route-transition">
      <div className="search-field">
        <SearchIcon placeholder="Search for Catalogue items" boxClasses="mb-3" textRef={inputRef} value={search} onChange={handleChange} />
        <Dropdown perPage={perpage} action={handleNewPage} />
      </div>
      <div className="mt-3">
        <DataTable columns={materialCol} data={materialList} loading={isLoading} />
        <Pagination page={page} lastPage={materials?.pagination?.lastPage} paginate={paginate} total={materials?.pagination?.total} handlePageChange={handlePageChange} perPage={perpage} />
      </div>
    </div>
  );
};

export default Catalogue;
