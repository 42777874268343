import React, { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootState } from "store/store";

import useSearch from "hooks/useSearch";
import { getWayBill } from "store/slice/SupplierSlices/Waybill/WayBillSlice";

import { getInvoiceSummary } from "store/slice/BuyerSlice/Invoice/InvoiceSlice";
import StatsCard from "components/StatsCard";

import file from "../../../assests/icons/supplier/file.svg";
import accordionImg from "../../../../assests/icons/supplier/accordion.svg";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { getStatement } from "store/slice/BuyerSlice/VendorStatementSlice/VendorStatementSlice";
import { formatCurrency, moneyFormatter, toastPopUp } from "utils/utils";
import { eachProposalCol } from "../data";
import { getEachBankPropsal } from "store/slice/BuyerSlice/PostedInvoiceSlice/PostedInvoiceSlice";
import { sendApproval } from "store/slice/BuyerSlice/PostedInvoiceSlice/PostedInvoiceSlice";
import Button from "components/Button";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "./styles.scss";
import UploadProposal from "components/ModalBuyer/UploadProposal";
import ProposalComponent from "./proposalComponent";
import { toastr } from "react-redux-toastr";
interface IUserMenuProps {}

const EachBankProposal: React.FC<IUserMenuProps> = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //State
  const [active, setActive] = useState(true);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");
  const statusProp = urlParams.get("statusProp");
  const { eachBankProposal, loading, sendLoading } = useSelector((state: RootState) => state.postedInvoice);
  const [recentId, setRecentId] = useState("");
  const [proposalId, setRecentProposalId] = useState("");
  const { s3urlProposal } = useSelector((state: RootState) => state.postedInvoice);
  // const [_modalData, set_ModalData] = useState(null);

  // const { search, inputRef, handleChange, searched } = useSearch(getWayBill, perPage);

  //Redux Hook
  const dispatch = useDispatch<AppDispatch>();

  const { user } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    dispatch(getEachBankPropsal({ id: id }));
  }, []);

  const [modal, setModal] = useState<boolean>(false);
  const closeModal = () => {
    setModal((prev) => !prev);
  };

  // delete
  const sendApprovalFunc = (id: any) => {
    const payload = { bank_proposal_id: id };
    if (s3urlProposal) {
      dispatch(sendApproval(payload));
    } else {
      toastr.error("", "Upload a file");
    }
  };
  function getItemStatus(status) {
    if (status === "-2") return "In Bank Selection";
    if (status === "-1") return "Authoriser Approval";
    if (status === "1") return "Approved";
  }
  function getBtnClass(status) {
    if (status === "-2") return "draft";
    if (status === "-1") return "reject";
    if (status === "1") return "approved";
  }
  return (
    <section className="statement" id="prop2">
      <div className="container">
        <div className="btnBox5">
          {eachBankProposal ? (
            eachBankProposal.data[0].bank_proposal_status === "-2" ? (
              <Button
                label={"Send For Approval"}
                onClick={() => {
                  sendApprovalFunc(id);
                }}
                btnType="btn-primary"
                boxClasses=""
                btnStyle="195px"
                loading={sendLoading}
                xtraClass="py-2"
              />
            ) : null
          ) : null}
        </div>

        <div className="document mt-5">
          {modal && <UploadProposal id={recentId} proposal_id={proposalId} closeModal={closeModal} />}

          <div className="col-4 mb-2">
            <h6>Status</h6>
            <button className={`status mt-2 ${getBtnClass(statusProp)}`}>{getItemStatus(statusProp)}</button>
          </div>

          {eachBankProposal &&
            eachBankProposal.data[0].per_bank_items.map((item) => {
              return (
                <ProposalComponent
                  key={item.id}
                  item={item}
                  setRecentId={setRecentId}
                  status={eachBankProposal && eachBankProposal.data[0].bank_proposal_status}
                  setRecentProposalId={setRecentProposalId}
                  closeModal={closeModal}
                />
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default EachBankProposal;
