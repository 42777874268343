import DropdownMenu from "components/DropdownMenu";
import "./styles.scss";

export const companydatas = [
  {
    code: "0010",
    name: "GZI Nigeria",
    currency: "NGN",
    value: "1000"
  },
  {
    code: "0011",
    name: "GZI Nairobi",
    currency: "USD",
    value: "2000"
  }
];

export const companyColumn = [
  {
    name: "Name",
    index: "user",
    id: 1
  },
  {
    name: "Action Type",
    index: "type",
    id: 2
  },
  {
    name: "Action Description",
    index: "action",
    id: 2
  },
  {
    name: "Time",
    index: "time",
    id: 3
  },
  {
    name: "Platform",
    index: "platform",
    id: 4
  },
  {
    name: "Ip",
    index: "ip",
    id: 5
  }
  // {
  //   name: "Status",
  //   // index: "status",
  //   id: 5,
  //   render: (item) => (
  //     <button
  //       type="button"
  //       style={{
  //         padding: "8px 16px",
  //         background: item.status !== "Approved" ? "rgba(245, 150, 37, 0.15)" : "rgba(0, 176, 7, 0.15)",
  //         borderRadius: "10px",
  //         color: item.status !== "Approved" ? "rgba(245, 150, 37, 1)" : "#387C57"
  //       }}
  //     >
  //       {item.status}
  //     </button>
  //   )
  // },
  // {
  //   name: "Actions",
  //   id: 6,
  //   render: ({ deleteEntity, edit }) => {
  //     const settings = [
  //       {
  //         name: "Edit",
  //         style: "edit_tag",
  //         select: edit
  //       }
  //       // {
  //       //     name: "Delete",
  //       //     style: "delete_tag",
  //       //     select: deleteEntity
  //       // }
  //     ];
  //     return (
  //       <span style={{ position: "relative" }}>
  //         <DropdownMenu options={settings} boxClasses="dropdown-style">
  //           <p style={{ fontSize: "2rem" }} className="cursor-pointer">
  //             ...
  //           </p>
  //         </DropdownMenu>
  //       </span>
  //     );
  //   }
  // }
];
