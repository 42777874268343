import StatsCard from "components/StatsCard";
import React, { useState } from "react";
import "../styles.scss";
import Analytics from "assests/icons/buyer/analytics.svg";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import SearchIcon from "components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { notifyColumn } from "../data";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { useEffect } from "react";
import { getMaterialCategories, getMaterials, deleteProductData } from "store/slice/BuyerSlice/ProductSlice/ProductSlice";
import CreateOrEditProductData from "components/ModalBuyer/CreateOrEditProductData";
import { toastPopUp } from "utils/utils";
import useSearch from "hooks/useSearch";
import Pagination from "components/Pagination";
import { deleteNotifySetup, getNotifySetup } from "store/slice/BuyerSlice/NotifySetup/NotifySetupSlice";
import CreateNotify from "components/ModalBuyer/CreateNotify";

const NotificationSetup = () => {
  //State
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [modal, setModal] = useState<boolean>(false);

  //Redux
  const dispatch = useDispatch<AppDispatch>();
  const { notifySetup, loading } = useSelector((state: RootState) => state.notifySetup);

  //Hook
  const { search, inputRef, handleChange, searched } = useSearch(getNotifySetup, perPage);

  useEffect(() => {
    dispatch(getNotifySetup(page, perPage));
  }, []);

  // setpage to the first page when a searched executed
  useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  // new page
  const handleNewPage = (pageNo: number, page: number = 1) => {
    setPerPage(pageNo);
    setPage(1);
    dispatch(getNotifySetup(page, pageNo, search));
  };

  //Modal Handler
  const closeModal = () => {
    setModal((prev) => !prev);
  };

  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, perPage: number) => {
      setPage(page);
      dispatch(getNotifySetup(page, perPage, search));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, perPage: number) => {
      setPage(pageNo);
      dispatch(getNotifySetup(pageNo, perPage, search));
    },
    [search]
  );

  //Function Declaration
  const editProductData = (_data: any) => {
    setModal((prev) => !prev);
  };

  const deleteNotification = (id: string, name: string) => {
    const payload = { notification_setting_id: id };

    toastPopUp(
      () => {
        dispatch(
          deleteNotifySetup(payload, () => {
            dispatch(getNotifySetup(page, perPage));
          })
        );
      },
      "Delete",
      "Cancel",
      "Delete the entity",
      name,
      "red"
    );
  };

  const notifyList =
    notifySetup &&
    notifySetup?.data?.map((material) => {
      return {
        id: material.id,
        activity: material.activity,
        name: `${material.lastname ? material.lastname : ""} ${material.othernames ? material.othernames : ""}`,
        role: material.user_role_name,
        deleteNotifyData: () => deleteNotification(material.id, material.activity)
      };
    });

  return (
    <div id="settings-material-data">
      {modal && <CreateNotify closeModal={closeModal} />}
      <div className="container mt-3">
        <div className="row mt-3 mb-5">
          <StatsCard statsNumber={notifySetup?.count ?? 0} text="Notification(s)" classes="stats-icon" image={Analytics} iconBackgroundColor="rgba(45, 156, 219, 0.1)" />
          <StatsCard image={AddIcon} classes="middle-img" centerText="Create" onClick={closeModal} />
        </div>

        <div className="d-flex  mb-3">
          <SearchIcon placeholder="Search" boxClasses="" textRef={inputRef} value={search} onChange={handleChange} />
          <Dropdown perPage={10} action={handleNewPage} />
        </div>
        <DataTable columns={notifyColumn} data={notifyList} loading={loading} />
        <Pagination page={page} lastPage={notifySetup?.pagination?.lastPage} paginate={paginate} total={notifySetup?.pagination?.total} handlePageChange={handlePageChange} perPage={perPage} />
      </div>
    </div>
  );
};

export default NotificationSetup;
