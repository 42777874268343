import { useState } from "react";

const useAccordion = (data_) => {
  const [index, setIndex] = useState(null);
  const [headerChecked, setHeaderChecked] = useState([]) as any;
  const [isSelected, setIsSelected] = useState<object>({
    action: ""
  });

  const handlerAccordion = (i) => {
    if (i === index) {
      setIndex(null);
    } else setIndex(i);
  };
  const handleCheckbox = (item, name, i, index_) => {
    // const data = {
    //   id: name,
    //   selectedItems: [item],
    //   amount: item.amount
    // };
    // let temp = [...headerChecked] as any;
    // const index = headerChecked.findIndex((item) => item.id === name);
    // if (index > -1) {
    //   const checkSelecteditems = temp[index].selectedItems.findIndex((data) => data.name === item.name);
    //   if (checkSelecteditems > -1) {
    //     temp[index].selectedItems.splice(checkSelecteditems, 1);
    //   } else temp[index].selectedItems.push(item);
    //   temp[index].amount = temp[index].selectedItems.reduce((arr, item) => arr + item.amount, 0);
    //   if (temp[index].selectedItems.length < 1) temp.splice(index, 1);
    //   setHeaderChecked(temp);
    // } else setHeaderChecked([...headerChecked, data]);

    if (name.invoice_added.includes(item.invoice_code)) {
      setIsSelected({
        proposal_item_id: name.id,
        invoice_id: data_[i]?.invoice[index_].invoice_id,
        action: "remove"
      });
    } else {
      setIsSelected({
        proposal_item_id: name.id,
        invoice_id: data_[i]?.invoice[index_].invoice_id,
        action: "add"
      });
    }
    console.log(item)
    console.log(name)
  };

  const checkAccordion = ({ invoice_added }) => {
    // const selected = headerChecked.find((item) => item.id === name);
    // if (selected?.selectedItems.length > 0) {
    //   return true;
    // } else return false;
    if (invoice_added.length >= 1) {
      return true;
    } else return false;
  };
  const getAmount = ({ amount }) => {
    // let proposalAmt = null;
    // const selected = headerChecked.find((item) => item.id === name);
    // if (selected) {
    //   return (proposalAmt = selected.amount);
    // } else {
    //   return proposalAmt;
    // }
    return amount;
  };

  // const btnClicked = (e) => {
  //   e.stopPropagation();
  //   console.log("clicked");
  // };

  const getSelected = (amount) => {
    // let totalSelected = 0;
    // let selected = headerChecked.find((item) => item.id === name);
    // if (selected) totalSelected = selected.selectedItems.length;
    // return totalSelected;
    // return invoice_added?.length;
    return amount;
  };

  return {
    getAmount,
    checkAccordion,
    handleCheckbox,
    handlerAccordion,
    index,
    headerChecked,
    // btnClicked,
    getSelected,
    isSelected
  };
};
export default useAccordion;
