import axios from "axios";
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch, AppThunk } from "store/store";
import { BudgetState } from './interface';
import { toastr } from 'react-redux-toastr';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState: BudgetState = {
    isLoading: false,
    summary: {},
    budgets: null,
    singleBudget: {},
    budgetHeaders: null,
    singleBudgetHeader: {},
};

const budgetSlice = createSlice({
    name: 'budget',
    initialState,
    reducers: {
        startRequest: (state) => {
            state.isLoading = true;
        },
        endRequest: (state) => {
            state.isLoading = false;
        },
        fetchSummary: (state, action) => {
            state.summary = action.payload;
        },
        fetchAllBudgets: (state, action) => {
            state.budgets = action.payload;
            state.isLoading = false;
        },
        fetchSingleBudget: (state, action) => {
            state.singleBudget = action.payload;
        },
        fetchBudgetHeaders: (state, action) => {
            state.budgetHeaders = action.payload;
            state.isLoading = false;
        },
        fetchSingleBudgetHeader: (state, action) => {
            state.singleBudgetHeader = action.payload;
            state.isLoading = false;
        }
    }
});

const { actions, reducer } = budgetSlice;

export const {
    startRequest,
    endRequest,
    fetchSummary,
    fetchAllBudgets,
    fetchSingleBudget,
    fetchBudgetHeaders,
    fetchSingleBudgetHeader,
} = actions;

export const getSummary = (): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        try {
            const res = await axios.get(`${BASE_URL}/budgets/summary`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    "Content-Type": "application/json"
                }
            });
            if ([200, 201].includes(res.status)) {
                dispatch(fetchSummary(res.data.data));
            }
        } catch (err) {
            toastr.error(err?.response?.data?.message)
        }
    }

export const createBudgetLineItems = (
    data?: object,
    cb?: () => void,
): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        dispatch(startRequest());
        const { userToken } = getState().user;
        try {
            const res = await axios.post(`${BASE_URL}/budgets/create`, { ...data }, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    "Content-Type": "application/json"
                }
            });
            if ([200, 201].includes(res.status)) {
                dispatch(endRequest);
                toastr.success(res.data?.message);
                cb && cb();
            }
        } catch (err) {
            dispatch(endRequest())
            toastr.error(err?.response?.data?.message)
        }
    }

export const createBudgetHeader = (
    data?: object,
    cb?: () => void,
): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        dispatch(startRequest());
        const { userToken } = getState().user;
        try {
            const res = await axios.post(`${BASE_URL}/budget_headers/create`, { ...data }, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    "Content-Type": "application/json"
                }
            });
            if ([200, 201].includes(res.status)) {
                dispatch(endRequest);
                toastr.success(res.data?.message);
                cb && cb();
            }
        } catch (err) {
            dispatch(endRequest())
            toastr.error(err?.response?.data?.message)
        }
    }

export const editBudgetHeader = (
    data?: object,
    cb?: () => void,
): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        dispatch(startRequest());
        const { userToken } = getState().user;
        try {
            const res = await axios.post(`${BASE_URL}/budget_headers/edit`, { ...data }, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    "Content-Type": "application/json"
                }
            });
            if ([200, 201].includes(res.status)) {
                dispatch(endRequest);
                toastr.success(res.data?.message);
                cb && cb();
            }
        } catch (err) {
            dispatch(endRequest())
            toastr.error(err?.response?.data?.message)
        }
    }

export const getAllBudgetHeaders = (
    page?: number,
    length?: number,
    search?: string,
    start_date?: string,
    end_date?: string,
    budget_header_status?: string,
): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        dispatch(startRequest());
        const { userToken } = getState().user;
        try {
            const res = await axios.get(`${BASE_URL}/budget_headers/get?`, {
                params: {
                    page,
                    length,
                    search,
                    start_date,
                    end_date,
                    budget_header_status,
                },
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    "Content-Type": "application/json"
                }
            });
            if ([200, 201].includes(res.status)) {
                dispatch(fetchBudgetHeaders(res.data))
            }
        } catch (err) {
            dispatch(endRequest())
            toastr.error(err?.response?.data?.message)
        }
    }

export const getSingleBudgetHeader = (
    id?: string,
): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        dispatch(startRequest());
        const { userToken } = getState().user;
        try {
            const res = await axios.get(`${BASE_URL}/budget_headers/get/${id}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    "Content-Type": "application/json"
                }
            });
            if ([200, 201].includes(res.status)) {
                dispatch(fetchSingleBudgetHeader(res.data.data[0]))
            }
        } catch (err) {
            dispatch(endRequest())
            toastr.error(err?.response?.data?.message)
        }
    }

export const getAllBudgets = (
    page?: number,
    length?: number,
    search?: string,
): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        dispatch(startRequest());
        const { userToken } = getState().user;
        try {
            const res = await axios.get(`${BASE_URL}/budgets/get?`, {
                params: {
                    page,
                    length,
                    search
                },
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    "Content-Type": "application/json"
                }
            });
            if ([200, 201].includes(res.status)) {
                dispatch(fetchAllBudgets(res.data))
            }
        } catch (err) {
            dispatch(endRequest())
            toastr.error(err?.response?.data?.message)
        }
    }

export const deleteBudgetHeader = (
    data?: object,
    cb?: () => void
): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        dispatch(startRequest());
        const { userToken } = getState().user;
        try {
            const res = await axios.post(`${BASE_URL}/budget_headers/delete`, { ...data }, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    "Content-Type": "application/json"
                }
            });
            if ([200, 201].includes(res.status)) {
                dispatch(endRequest);
                toastr.success(res.data?.message);
                cb && cb();
            }
        } catch (err) {
            dispatch(endRequest())
            toastr.error(err?.response?.data?.message)
        }
    }

export const deleteBudget = (
    data?: object,
    cb?: () => void
): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        dispatch(startRequest());
        const { userToken } = getState().user;
        try {
            const res = await axios.post(`${BASE_URL}/budgets/delete`, { ...data }, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    "Content-Type": "application/json"
                }
            });
            if ([200, 201].includes(res.status)) {
                dispatch(endRequest);
                toastr.success(res.data?.message);
                cb && cb();
            }
        } catch (err) {
            dispatch(endRequest())
            toastr.error(err?.response?.data?.message)
        }
    }

export const confirmBudget = (data?: object, cb?: () => void): AppThunk => async (dispatch: AppDispatch, getState) => {
    dispatch(startRequest());
    const { userToken } = getState().user;

    try {
        const res = await axios.post(`${BASE_URL}/budget_headers/confirm`, { ...data }, {
            headers: {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "application/json"
            }
        })
        if ([200, 201].includes(res.status)) {
            toastr.success(res.data.message)
            dispatch(endRequest())
            cb && cb();
        }
    } catch (err) {
        dispatch(endRequest())
        toastr.error(err?.response?.data?.message);
    }
}

export default reducer;