import { Link } from "react-router-dom";
export const documentsColumns = [
  // {
  //   name: "Date",
  //   index: "date",
  //   id: 1
  // },
  {
    name: "Legal Entity",
    index: "legal",
    id: 1
  },
  {
    name: "Supplier No..",
    index: "no",
    id: 2
  },
  {
    name: "Supplier Name",
    index: "name",
    id: 3
  },
  // {
  //   name: "RC No.",
  //   index: "rc_number",
  //   id: 4
  // },
  {
    name: "Specialization",
    index: "specialization",
    id: 5
  },
  {
    name: "Status",
    id: 6,
    render: (item) => (
      <button
        type="button"
        style={{
          padding: "8px 16px",
          background: item.data_status === "Pending" ? "rgba(245, 150, 37, 0.15)" : "rgba(0, 176, 7, 0.15)",
          borderRadius: "10px",
          color: item.data_status === "Pending" ? "rgba(245, 150, 37, 1)" : "#387C57"
        }}
      >
        {item.data_status}
      </button>
    )
  },
  {
    name: "Action",
    id: 7,
    render: (item) => {
      return (
        <span className="span-container">
          {/* {item.data_status !== "Pending" && ( */}
          <span>
            {item.purchase_order_count > 0 && (
              <p className="edit">
                <Link to={`/buyer/suppliers/${item.id}`}>Transactions</Link>
              </p>
            )}
            {item.purchase_order_count < 1 && (
              <p className="" style={{ color: "#45454570" }}>
                Transactions
              </p>
            )}
          </span>
          {/* )} */}
          <p className="delete pl-3">
            <Link to={{ pathname: "/buyer/suppliers/master-data", state: item.id }}>Master Data</Link>
          </p>
        </span>
      );
    }
  }
];

export const reportCol = [
  {
    name: "Country",
    index: "country_description",
    id: 1
  },
  {
    name: "Total Vendors",
    index: "total_vendors",
    id: 2
  },
  {
    name: "Company ID",
    index: "total_companies",
    id: 3
  }
];

export const reportCategory = [
  {
    name: "ID",
    index: "business_category_id",
    id: 1
  },
  {
    name: "Category",
    index: "business_category_description",
    id: 2
  },
  {
    name: "Total Vendor",
    index: "total_vendors",
    id: 3
  }
];

export const reportDoc = [
  {
    name: "ID",
    index: "id",
    id: 1
  },
  {
    name: "Field Label",
    index: "field_label",
    id: 2
  },
  {
    name: "Total Vendor",
    index: "total_vendors",
    id: 3
  }
];

export const reportData = [
  {
    department_code: "Brent Fawaz",
    department_description: "13",
    amount: "N23,345",
    id: 1
  },
  {
    location: "Brent Fawaz",
    department: "13",
    amount: "N23,345",
    id: 2
  },
  {
    location: "Brent Fawaz",
    department: "13",
    amount: "N23,345",
    id: 4
  }
];
