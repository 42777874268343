import axios from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "store/store";
import { AccountAction, AccountState } from "./interface";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState: AccountState = {
    isLoading: false,
    number: "",
    id: "",
    errorMessage: ""
};

const accountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        getaccountLoading: (state) => {
            state.isLoading = true;
        },
        getaccountSuccess: (state, action: PayloadAction<AccountAction>) => {
            state.isLoading = false;
            state.number = action.payload.eyowo_number;
            state.id = action.payload._id;
        },
        getaccountFail: (state) => {
            state.isLoading = false;
        },
        editaccountLoading: (state) => {
            state.isLoading = true;
        },
        editaccountSuccess: (state, action: PayloadAction<AccountAction>) => {
            state.isLoading = false;
            state.number = action.payload.eyowo_number;
            state.id = action.payload._id;
        },
        editaccountFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        }
    }
});

const { actions, reducer } = accountSlice;

export const {
    getaccountFail,
    getaccountLoading,
    getaccountSuccess,
    editaccountFail,
    editaccountLoading,
    editaccountSuccess
} = actions;

export const getAccount = (): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getaccountLoading());
    try {
        const response = await axios.get(`${BASE_URL}/account/get`, {
            headers: {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "application/json"
            }
        });
        if (response.status) {
            dispatch(getaccountSuccess(response.data.account[0]));
        }
    } catch (error) {
        dispatch(getaccountFail());
    }
};

export const editAccount = (data: any, id: string, cb: () => void): AppThunk => async (
    dispatch: AppDispatch,
    getState
) => {
    const { userToken } = getState().user;
    dispatch(editaccountLoading());
    try {
        const response = await axios.post(`${BASE_URL}/account/edit/${id}`, data, {
            headers: {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "application/json"
            }
        });
        if (response.status) {
            dispatch(editaccountSuccess(response.data.account));
            cb();
        }
    } catch (error) {
        dispatch(editaccountFail(""));
    }
};

export default reducer;
