import React, { useEffect, useState } from "react";
import StatsCard from "components/StatsCard";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import budgetIcon from "assests/icons/buyer/budget-icon.svg";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import filterIcon from "assests/icons/buyer/filter-icon.svg";
import "../styles.scss";
import SearchIcon from "components/SearchBar";
import Dropdown from "components/Dropdown";
import useSearch from "hooks/useSearch";
import { deleteBudgetHeader, getSummary } from "store/slice/BuyerSlice/Budget/BudgetSlice";
import { paymentRuns } from "../data";
import DataTable from "components/DataTable";
import Pagination from "components/Pagination";
import Textbox from "components/Textbox";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";
import FilterCard from "components/FilterCard";
import SelectDropdown from "components/SelectDropdown";
import { getVendors } from "store/slice/BuyerSlice/VendorSlice/VendorSlice";
import { getPaymentProprosals } from "store/slice/BuyerSlice/PostedInvoiceSlice/PostedInvoiceSlice";
import { formatCurrency } from "utils/utils";

const PaymentProposalIndex = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  const { paymentProposals } = useSelector((state: RootState) => state.postedInvoice);
  const [page, setPage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [showFilter, setShowFilter] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [modalText, setModalText] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [bugHead, setBugHead] = useState("");
  const [runDate, setRunDate] = useState("");

  const { search, inputRef, handleChange } = useSearch(getPaymentProprosals, perpage, "", [undefined, runDate, undefined, undefined, startDate, endDate, bugHead]);

  const { register, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      start_date: "",
      end_date: "",
      from: "",
      to: ""
    }
  });

  const onSubmit = (vals) => {
    setBugHead(vals.proposal_status);
    setStartDate(vals.start_date);
    setEndDate(vals.end_date);
    setRunDate(vals.run_date);
    dispatch(getPaymentProprosals(page, perpage, search, undefined, vals.run_date, undefined, undefined, vals.start_date, vals.end_date, vals.proposal_status));
  };

  const handleClear = () => {
    setBugHead("");
    setStartDate("");
    setEndDate("");
    setRunDate("");
    dispatch(getPaymentProprosals(page, perpage, search));
    reset();
  };

  const handleNewPage = React.useCallback(
    (pageNo: number, page = 1) => {
      setPerpage(pageNo);
      setPage(1);
      dispatch(getPaymentProprosals(page, pageNo, search, undefined, runDate, undefined, undefined, startDate, endDate, bugHead));
    },
    [search, runDate, bugHead, startDate, endDate, dispatch]
  );

  const paginate = React.useCallback(
    (pageNo: number, length: number) => {
      setPage(pageNo);
      dispatch(getPaymentProprosals(pageNo, length, search, undefined, runDate, undefined, undefined, startDate, endDate, bugHead));
    },
    [search, runDate, bugHead, startDate, endDate, dispatch]
  );

  const handlePageChange = React.useCallback(
    (page: number, length: number) => {
      setPage(page);
      dispatch(getPaymentProprosals(page, length, search, undefined, runDate, undefined, undefined, startDate, endDate, bugHead));
    },
    [search, runDate, bugHead, startDate, endDate, dispatch]
  );
  useEffect(() => {
    dispatch(getPaymentProprosals(page, perpage, ""));
  }, []);

  // function getItemStatus(status) {
  //   if (status === "-2") return "Draft";
  //   if (status === "-1") return "Pending";
  //   if (status === "1") return "Approved";
  //   if (status === "2") return "Paid";
  //   if (status === "3") return "Parked";
  //   if (status === "4") return "Posted";
  // }

  function getItemStatus(status) {
    if (status === "-3") return "Rejected";
    if (status === "-2") return "New Proposal";
    if (status === "-1") return "FC Approval";
    if (status === "1") return "Payment Approved";
    if (status === "2") return "Paid";
    if (status === "3") return "Parked";
    if (status === "4") return "Posted";
  }

  // function getBtnClass(status) {
  //   if (status === "-3") return "reject";
  //   if (status === "-2") return "posted";
  //   if (status === "-1") return "draft";
  //   if (status === "1") return "approved";
  //   if (status === "2") return "posted";
  //   if (status === "3") return "posted";
  //   if (status === "4") return "posted";
  // }

  function getBtnClass(status) {
    if (status === "-3") return "reject";
    if (status === "-2") return "draft";
    if (status === "-1") return "pending";
    if (status === "1") return "approved";
    if (status === "2") return "paid";
    if (status === "3") return "posted";
    if (status === "4") return "posted";
    if (status === "5") return "paid";
  }

  const postedInvoiceList =
    paymentProposals &&
    paymentProposals?.data?.map((item) => {
      return {
        run_id: item.proposal_code,
        run_date: item.run_date,
        // run_date: item.format(new Date(item.run_date), "dd.MM.yyyy"),
        company_code: item.company_code,
        vendor_number: item.total_vendors,
        amount: formatCurrency(item.total_proposal_amount),
        currency: item.currency_iso,

        status: getItemStatus(item.proposal_status),
        id: item.id,
        btnClass: getBtnClass(item.proposal_status)
        // posted_status: getItemStatus(item.invoice_status)

        // view: () => history.push(`/buyer/budget/${item.id}`),
        // edit: () => editBudget(item),
        // deleteId: () => handleDeleteBudget(item.id)
      };
    });

  useEffect(() => {
    dispatch(getSummary());
    dispatch(getPaymentProprosals());
  }, []);

  useEffect(() => {
    if (!modal) setModalData({});
  }, [modal]);

  const handleView = (e, item) => {
    history.push(`/buyer/treasury/payment-runs/${item.id}`);
  };

  console.log(paymentProposals);

  return (
    <div id="budget">
      <StatsCard statsNumber={paymentProposals?.count} text="Payment Proposal" classes="stats-icon-orange" image={budgetIcon} iconBackgroundColor="rgba(0, 176, 80, 0.1)" />

      <div className="container">
        {showFilter && (
          <FilterCard handleSubmit={handleSubmit(onSubmit)} clear={handleClear}>
            <div className="row">
              {/* <div className="col col-4">
                <label style={{ display: "block" }}>Start Date</label>
                <input
                  type="date"
                  name="start_date"
                  className="mt-3"
                  placeholder="Start Date"
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  ref={register({ required: false })}
                />
              </div> */}
              <div className="col col-4">
                <label style={{ display: "block" }}>Run Date</label>
                <input
                  type="date"
                  name="run_date"
                  className="mt-3"
                  placeholder="Run Date"
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  ref={register({ required: false })}
                />
              </div>
              <div className="col col-4">
                <label style={{ display: "block" }}>Start Date</label>
                <input
                  type="date"
                  name="start_date"
                  className="mt-3"
                  placeholder="Start Date"
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  ref={register({ required: false })}
                />
              </div>
              <div className="col col-4">
                <label style={{ display: "block" }}>End Date</label>
                <input
                  type="date"
                  name="end_date"
                  className="mt-3"
                  placeholder="End Date"
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  ref={register({ required: false })}
                />
              </div>
            </div>
            <div
              className="row"
              style={{
                marginTop: "2rem"
              }}
            >
              <div className="col col-4">
                <label style={{ display: "block" }}>Proposal Status</label>
                <select
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  name="proposal_status"
                  ref={register({
                    required: false
                  })}
                >
                  <option value="">Select</option>
                  <option value="-2">Draft</option>
                  <option value="-1">Pending Approval</option>
                  <option value="1">Approved</option>
                  <option value="-3">Rejected</option>
                </select>
              </div>
            </div>
          </FilterCard>
        )}
        <div className="document mt-5">
          <div className="search-field">
            <SearchIcon placeholder="Search Payment Proposal" boxClasses="mb-3" textRef={inputRef} value={search} onChange={handleChange} />
            <Dropdown perPage={perpage} action={handleNewPage} />
            <div>
              <button
                style={{
                  padding: "5px 10px",
                  borderRadius: "10px",
                  background: "#FFFFFF",
                  color: "#737A91",
                  border: "1px solid #DFE2E6",
                  fontSize: "18px",
                  marginLeft: "1rem",
                  width: "max-content"
                }}
                onClick={() => setShowFilter(!showFilter)}
              >
                <img src={filterIcon} alt="icon" className="icon-image" />
                <span
                  style={{
                    paddingLeft: "1rem"
                  }}
                >
                  {!showFilter ? "Filters" : "Hide Filters"}
                </span>
              </button>
            </div>
          </div>
          <div className="mt-3">
            <DataTable columns={paymentRuns} data={postedInvoiceList} onClick={handleView} loading={false} />
            <Pagination
              page={page}
              lastPage={paymentProposals?.pagination?.pageCount}
              paginate={paginate}
              total={paymentProposals?.pagination?.total}
              handlePageChange={handlePageChange}
              perPage={perpage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentProposalIndex;
