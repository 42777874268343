import React, { useEffect } from "react";
import Modal from "components/Modal";
import Button from "components/Button";
import { useForm } from "react-hook-form";
import Textbox from "components/Textbox";
import styled from "styled-components";
import SelectDropdown from "components/SelectDropdown";
import uuid from "react-uuid";
import deleteIcon from "assests/icons/buyer/delete-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { createRfpFields } from "store/slice/SourcingSlice/SourcingSlice";
import { RootState } from "store/store";
import { createKpiList, editKpiList, getKpiList } from "store/slice/BuyerSlice/KpiSetupSlice/KpiSetupSlice";

const Btn = styled.div`
  border: 1px solid #dfe2e6;
  padding: 5px;
  border-radius: 6px;
  color: #737a91;
  cursor: pointer;
  width: fit-content;
`;

type Props = {
  closeModal: () => void;
  singleList: any;
  groupId: any;
};

type optionType = {
  optionName: string;
  optionValue: string;
};

const requiredOption = [
  {
    value: "true",
    optionName: "True"
  },
  {
    value: "false",
    optionName: "False"
  }
];

const categoryOption = [
  {
    value: "Technical",
    optionName: "Technical"
  },
  {
    value: "Commercial",
    optionName: "Commercial"
  }
];

const fieldTypeOption = [
  {
    value: "Text",
    optionName: "Text"
  },
  {
    value: "File",
    optionName: "File"
  },
  {
    value: "Image",
    optionName: "Image"
  },
  {
    value: "Select",
    optionName: "Select"
  },
  {
    value: "Number",
    optionName: "Number"
  },
  {
    value: "Radio",
    optionName: "Radio"
  },
  {
    value: "Checkbox",
    optionName: "Checkbox"
  },
  {
    value: "Date",
    optionName: "Date"
  }
];

const importance = Array.from(Array(10).keys());

const CreateKpiSetup = ({ closeModal, singleList, groupId }: Props) => {
  const dispatch = useDispatch();

  const [ext, setExt] = React.useState("");
  const [options, setOptions] = React.useState<any>([]);
  const [responseType, setResponseType] = React.useState("");
  const [field_types, setFieldTypes] = React.useState("Text");
  const [displayOption, setDisplayOption] = React.useState(false);
  const [displayExtension, setDisplayExtension] = React.useState(false);
  const [eachOption, setEachOption] = React.useState<optionType>({
    optionName: "",
    optionValue: ""
  });
  const { kpiList, kpiFieldisLoading } = useSelector((state: RootState) => state.kpisetup);
  const [resTypeIndexes, setResTypeIndexes] = React.useState([uuid()]);
  const [resTypeCounter, setResTypeCounter] = React.useState(0);

  const { register, handleSubmit, errors, setValue } = useForm({
    mode: "onChange"
  });

  const { isLoading } = useSelector((state: RootState) => state.sourcing);

  const {
    register: resTypeRegister,
    handleSubmit: resTypeHandleSubmit,
    formState: { errors: resTypeErrors },
    trigger: resTypeTrigger
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true
    // shouldUseNativeValidation: false,
    // delayError: undefined,
  });

  const submitForm = (form) => {
    const payload = {
      ...form,
      field_name: Array.from(Array(7), () => Math.floor(Math.random() * 36).toString(36)).join(""),
      field_options: options,
      field_type: field_types,
      evaluation_type: responseType === "automatic" ? "Automatic" : "Manual"
    };
    resTypeHandleSubmit(onSubmitRes)(payload);
  };

  const onSubmitRes = (vals, payload) => {
    let resTypeArr = Object.keys(vals).map((item) => vals[item]);
    if (window.confirm("Are you sure?")) {
      sendData(resTypeArr, payload);
    }
  };

  const sendData = (resTypeArr, payload) => {
    // console.log('resTypeArr', resTypeArr);

    let _field_options: any[] = [];
    resTypeArr.forEach((item) => {
      _field_options.push({
        option_title: item.response,
        option_value: item.response
      });
    });
    payload.field_options = _field_options;
    const data = {
      ...payload,
      kpi_group_id: groupId,
      response_scores: resTypeArr
    };

    // dispatch(createRfpFields(data, () => handleSuc()));
    handleSub(data);
  };

  const handleSub = (payload) => {
    let data;

    if (singleList === null) {
      dispatch(
        createKpiList(payload, () => {
          closeModal();
          dispatch(getKpiList({ page: 1, length: 10, id: groupId }));
          handleSuc();
        })
      );
    } else {
      data = {
        ...payload,
        id: singleList.id
      };

      dispatch(
        editKpiList(data, () => {
          closeModal();
          dispatch(getKpiList({ page: 1, length: 10, id: groupId }));
          handleSuc();
        })
      );
    }
  };

  const handleSuc = () => {
    closeModal();
  };

  const handleFieldChange = (e) => {
    setFieldTypes(e.target.value);

    if (e.target.value === "Select" || e.target.value === "Radio" || e.target.value === "Checkbox") {
      setDisplayOption(true);
      setDisplayExtension(false);
    } else if (e.target.value === "File") {
      setDisplayExtension(true);
      setDisplayOption(false);
    } else {
      setDisplayOption(false);
      setDisplayExtension(false);
    }
  };

  const handleExt = (e) => {
    setExt(e.target.value);
  };

  const handleOptionChange = (e) => {
    const { name, value } = e.target;
    setEachOption({ ...eachOption, [name]: value });
  };

  const addOption = () => {
    setOptions([...options, { ...eachOption }]);

    setEachOption({
      optionName: "",
      optionValue: ""
    });
  };

  useEffect(() => {
    if (singleList !== null) {
      setValue("field_label", singleList.field_label);
      setValue("field_order", singleList.field_order);
      setValue("field_importance", singleList.field_importance);
      setValue("category", singleList.category);
      setValue("required", "True");
      setValue("required", "False");
    }
  }, [singleList]);

  const addResType = () => {
    setResTypeIndexes((prevIndexes) => [...prevIndexes, uuid()]);
    setResTypeCounter((prevCounter) => prevCounter + 1);
  };

  const removeResType = (index) => {
    if (resTypeCounter === 0) return;
    setResTypeIndexes((prevIndexes) => [...prevIndexes.filter((item) => item !== index)]);
    setResTypeCounter((prevCounter) => prevCounter - 1);
  };

  return (
    <Modal modalText={singleList === null ? "Create Question" : "Edit Question"} onClose={closeModal} modallength={!false ? "modal-length" : ""}>
      <div>
        <p className="" style={{ paddingBottom: "1.5rem" }}>
          Create KPI's that will be used to evaluate Vendors Ability to deliver
        </p>
        <form className="mt-2" onSubmit={handleSubmit(submitForm)}>
          <Textbox
            type="text"
            name="field_label"
            label="Question"
            placeholder="Question"
            error={errors.field_label && "This field is required"}
            customRef={register({
              required: true
            })}
            boxClasses="mt-3"
          />

          <Textbox
            type="number"
            name="field_order"
            label="Question Order"
            placeholder="Question Order"
            error={errors.field_order && "This field is required"}
            customRef={register({
              required: true
            })}
            boxClasses="mt-3"
          />

          <SelectDropdown
            boxClasses="mt-3"
            name="field_importance"
            placeholder="Question Importance"
            label="Question Importance"
            options={importance.map((item) => ({ optionName: item + 1, value: item + 1 }))}
            error={errors.field_importance && "This field is required"}
            customRef={register({
              required: true
            })}
          />

          <SelectDropdown
            boxClasses="mt-3"
            name="category"
            label="Category"
            placeholder="Category"
            options={categoryOption}
            customRef={register({ required: true })}
            error={errors.required && "This field is required"}
          />

          <SelectDropdown
            boxClasses="mt-3"
            name="required"
            label="Required"
            placeholder="Should this be mandatory?"
            options={requiredOption}
            customRef={register({ required: true })}
            error={errors.required && "This field is required"}
          />

          <div className="mt-3 select-dropdown">
            <fieldset>
              <legend>Field Type</legend>
              <div className="d-flex">
                <select
                  autoComplete="off"
                  defaultValue="Field Type"
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                  name="field_type"
                  placeholder="Field Type"
                >
                  <option value="">Field Type</option>
                  {fieldTypeOption &&
                    fieldTypeOption.map((option) => (
                      <option key={option?.value} value={option?.value}>
                        {option?.optionName}
                      </option>
                    ))}
                </select>
              </div>
            </fieldset>
          </div>

          {displayExtension && (
            <div className="mt-3 textbox">
              <fieldset>
                <legend id="label-legend" className="pl2 pr2">
                  File Extension
                </legend>
                <div className="d-flex">
                  <input type="text" name="allowed_file_extensions" value={ext} onChange={handleExt} placeholder="File Extension" />
                </div>
              </fieldset>
            </div>
          )}

          {field_types === "Select" || field_types === "Checkbox" || field_types === "Radio" ? (
            <>
              <div className="radio-box justify-content-between" style={{ paddingTop: "1.5rem" }}>
                <h5 className="inline-block">Response Type</h5>
                <div className="d-flex radio-box mt-4 justify-content-between" style={{ margin: "0 auto" }}>
                  <div className="">
                    <input type="radio" value="manual" name="price" id="manual" className="mr-2" onChange={(e) => setResponseType(e.target.value)} />
                    <label className="radio-box_label" htmlFor="manual" style={{ cursor: "pointer" }}>
                      Manual
                    </label>
                  </div>
                  <div className="">
                    <input type="radio" value="automatic" name="price" id="automatic" className="mr-2" onChange={(e) => setResponseType(e.target.value)} />
                    <label className="radio-box_label" htmlFor="automatic" style={{ cursor: "pointer" }}>
                      Automatic
                    </label>
                  </div>
                </div>
              </div>
              {responseType.length > 0 && (
                <>
                  <Btn onClick={addResType}>Add Response</Btn>
                  {resTypeIndexes.map((i) => (
                    <InputGroup
                      key={i}
                      index={i}
                      resName={`${i}.response`}
                      resVal={`${i}.response_score`}
                      register={resTypeRegister}
                      errors={resTypeErrors}
                      trigger={resTypeTrigger}
                      resLabel="Option Value"
                      resValLabel="Assigned Score"
                      resType={responseType}
                      removeItem={(index) => removeResType(index)}
                    />
                  ))}
                </>
              )}
            </>
          ) : null}

          <div style={{ marginTop: "1rem" }}>
            <button
              style={{
                padding: "8px 30px",
                background: "#00B0A5",
                borderRadius: "10px",
                color: "white",
                margin: "0 auto",
                height: "40px"
              }}
            >
              {kpiFieldisLoading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Save Details"}
            </button>
            <p onClick={closeModal} style={{ textAlign: "center", cursor: "pointer" }}>
              Cancel
            </p>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CreateKpiSetup;

export const InputGroup = ({ index, register, errors, trigger, resName, resVal, disabled = false, resLabel = "", resValLabel = "", removeItem, resType }) => {
  const onChange = () => {
    trigger && trigger();
  };
  React.useEffect(() => {
    trigger && trigger();
  }, []);
  React.useEffect(() => {
    trigger && trigger();
  }, [resType]);
  // console.log(errors);
  return (
    <div key={index} className="mt-3 textbox" style={{ display: "inline-flex", gap: "5px" }}>
      <fieldset className={`${errors[index] && "error"} ${disabled && "disabled-input"}`}>
        {resLabel && (
          <legend id="label-legend" className={`pl-2 pr-2 ${errors[index] && "label-error"}`}>
            {resLabel}
          </legend>
        )}
        <div className="d-flex">
          <input type="text" placeholder="Option Value" onChange={onChange} name={resName} ref={register({ required: true })} />
        </div>
      </fieldset>
      {resType === "automatic" && (
        <fieldset className={`${errors[index] && "error"} ${disabled && "disabled-input"}`}>
          {resValLabel && (
            <legend id="label-legend" className={`pl-2 pr-2 ${errors[index] && "label-error"}`}>
              {resValLabel}
            </legend>
          )}
          <div className="d-flex">
            <input type="number" max="100" placeholder="Assigned Score" onChange={onChange} name={resVal} ref={register({ required: true })} />
          </div>
        </fieldset>
      )}
      <img style={{ cursor: "pointer" }} src={deleteIcon} alt="delete item" onClick={() => removeItem(index)} />
    </div>
  );
};
