import { Link } from "react-router-dom";

export const reportCol = [
    {
        name: "Aging Bucket",
        index: "days",
        id: 1
    },
    {
        name: "Invoice Amount",
        index: "value",
        id: 2
    },
    {
        name: "Total Vendor",
        index: "vendor",
        id: 3
    },
    {
        name: "Total Invoice",
        index: "inv",
        id: 4
    }

];

