import React, { useEffect } from "react";
import Modal from "components/Modal";
import Button from "components/Button";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import { AppDispatch, RootState } from "store/store";

import { useState } from "react";

import API from "utils/httpsRequest";
import { Controller } from "react-hook-form";
import AsyncSelect from "react-select/async";

import { getUserNotification } from "store/slice/NotificationSlice/NotificationSlice";
import StarRating from "components/Star";
import { createRate, editRate, getViewRate } from "store/slice/BuyerSlice/VendorRate/VendorRateSlice";

type Props = {
  closeModal: () => void;
  id: any;
  singleView?: any;
};
const CreateRate = ({ closeModal, id, singleView }: Props) => {
  const { register, handleSubmit, errors, control, setValue } = useForm<FormData>({
    mode: "onChange"
  });

  //Redux Store
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading } = useSelector((state: RootState) => state.rate);

  const { user } = useSelector((state: RootState) => state.user);
  const { token } = user;
  const [goodSel, setGoodSel] = React.useState("");
  const [selGoodVal, setSelGoodVal] = React.useState("");

  const [delivery, setDelivery] = useState(0);
  const [timely, setTimely] = useState(0);
  const [quality, setQuality] = useState(0);

  type FormData = {
    comment: string;
  };

  const handleInputChangeGood = (value) => {
    setGoodSel(value);
  };

  const onChangeGood = (value) => {
    setSelGoodVal(value);
  };

  const goodOptions = () => {
    return API.get(`/grs/get?search=${goodSel}&length=1000&vendor_id=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: `${item.gr_code}`,
        value: item.id
      }));
    });
  };

  useEffect(() => {
    if (singleView !== null) {
      setValue("comment", singleView.comment);
      setTimely(singleView.timeliness_rating);
      setQuality(singleView.quality_rating);
      setDelivery(singleView.delivery_rating);
    }
  }, [singleView]);

  const onFormSubmit = (vals: FormData) => {
    if (singleView) {
      const payload = {
        timeliness_rating: timely,
        delivery_rating: delivery,
        quality_rating: quality,
        ...vals,
        id: singleView.id
      };
      console.log(payload, "edit");
      dispatch(
        editRate(payload, () => {
          dispatch(getViewRate({ page: 1, length: 10, id }));
          closeModal();
        })
      );
      return;
    }

    const { ...rest } = vals;
    const payload = {
      ...rest,
      purchase_order_gr_id: selGoodVal?.["value"],
      timeliness_rating: timely,
      delivery_rating: delivery,
      quality_rating: quality
    };
    console.log(payload, "pauuuu");
    dispatch(
      createRate(payload, () => {
        dispatch(getViewRate({ page: 1, length: 10, id }));
        closeModal();
      })
    );
  };

  const handleRateDelivery = (item) => {
    console.log(item);
    setDelivery(item);
  };
  const handleTimely = (item) => {
    console.log(item);
    setTimely(item);
  };
  const handleQuality = (item) => {
    console.log(item);
    setQuality(item);
  };

  return (
    <Modal modalText={singleView ? "Edit" : "Create"} onClose={closeModal} modallength="modal-length">
      {
        <div id="buyer-create-location">
          <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
            {singleView ? null : (
              <Controller
                control={control}
                defaultValue
                boxClasses="mt-3"
                name="user_id"
                render={({ ref }) => (
                  <AsyncSelect cacheOptions defaultOptions placeholder="Good Receipts" value={selGoodVal} loadOptions={goodOptions} onInputChange={handleInputChangeGood} onChange={onChangeGood} />
                )}
              />
            )}

            <br />

            <div className="rateBox mb-4">
              <p>Timeliness Rating</p>
              <StarRating disabled={false} stars={singleView ? singleView.timeliness_rating : 0} onChange={handleTimely} />
            </div>

            <div className="rateBox mb-4">
              <p>Delivery Rating</p>
              <StarRating disabled={false} stars={singleView ? singleView.delivery_rating : 0} onChange={handleRateDelivery} />
            </div>

            <div className="rateBox mb-4">
              <p>Quality Rating</p>
              <StarRating disabled={false} stars={singleView ? singleView.quality_rating : 0} onChange={handleQuality} />
            </div>

            <textarea name="comment" id="" rows={2} className="textarea" placeholder="Comment" ref={register({ required: true })} />

            <Button label={singleView ? "Edit" : "Create"} btnType="btn-primary" boxClasses="mt-4 mb-5" btnStyle="100%" loading={isLoading} />
          </form>
        </div>
      }
    </Modal>
  );
};

export default CreateRate;
