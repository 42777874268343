import React from 'react';
import Modal from 'components/Modal';
import Button from 'components/Button';
import { useForm, Controller } from "react-hook-form";
import Textbox from 'components/Textbox';
import AsyncSelect from "react-select/async";
import API from "utils/httpsRequest";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { createRfpFromKpiGrp, getRfpFields, getSingleRfp } from 'store/slice/SourcingSlice/SourcingSlice';

type Props = {
    closeModal: () => void;
    id: string;
};

type FormData = {
    project_type: string;
};

const SelectKpi = ({ closeModal, id }) => {

    const { user } = useSelector((state: RootState) => state.user);
    const { rfp, isLoading } = useSelector((state: RootState) => state.sourcing);

    const [kpiVal, setKpiVal] = React.useState('');
    const [selKpiVal, setSelKpiVal] = React.useState('');
    const [kpiGrp, setKpiGrp] = React.useState<any>([]);
    const [projectType, setProjectType] = React.useState('');

    const { token } = user;

    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        errors,
        control,
        formState: { isValid }
    } = useForm<FormData>({
        mode: "onChange"
    });

    const submitForm = (form) => {
        const data = {
            rfp_id: rfp.id,
            kpi_group_id: selKpiVal?.['value'],
        }
        dispatch(createRfpFromKpiGrp(data, () => handleSuc()))
    }

    const handleSuc = () => {
        closeModal();
        dispatch(getRfpFields(id));
        dispatch(getSingleRfp(id));
    }

    const handleInputChangeKpi = value => setKpiVal(value);

    const onChangeKpi = value => {
        setSelKpiVal(value)
    };

    const loadKpi = () => {
        return API.get(`/kpis/get_groups?page=1&length=10&search=${kpiVal}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            setKpiGrp(res.data.data)
            return res.data.data.map((item) => ({
                label: item.kpi_group_description,
                value: item.id,
            }))
        })
    }

    React.useEffect(() => {
        kpiGrp.forEach((item) => {
            if (item.id === selKpiVal?.['value']) {
                console.log(item)
                setProjectType(item.project_type)
            }
        })
    }, [selKpiVal])

    // React.useEffect(() => {
    //     console.log('kpiGrp', kpiGrp);
    // }, [kpiGrp])

    return (
        <Modal modalText={'Select KPI'} onClose={closeModal} modallength={!false ? 'modal-length' : ''}>
            <p className="" style={{ paddingBottom: '1.5rem' }}>
                Select Predefined Question Set for the Sourcing Project
            </p>
            <form className="mt-2" onSubmit={handleSubmit(submitForm)}>

                <Controller
                    control={control}
                    defaultValue
                    name="kpi_group_id"
                    render={({ ref }) => (
                        <AsyncSelect
                            cacheOption
                            defaultOptions
                            placeholder="Select KPI Group"
                            value={selKpiVal}
                            loadOptions={loadKpi}
                            onInputChange={handleInputChangeKpi}
                            onChange={onChangeKpi} />
                    )} />

                <br />
                <Textbox
                    type="text"
                    name="project_type"
                    label="Project Type"
                    placeholder="Project Type"
                    disabled
                    value={projectType}
                />

                <Button
                    label={isLoading ? 'Please wait...' : "Save Details"}
                    btnType="btn-primary"
                    boxClasses="mt-4 mb-3"
                    btnStyle="100%"
                    disabled={isLoading}
                />
                <p onClick={closeModal} style={{ textAlign: "center", cursor: "pointer" }}>
                    Cancel
                </p>
            </form>
        </Modal>
    )
}

export default SelectKpi;