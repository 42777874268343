import React, { useEffect } from "react";
import Modal from "components/Modal";
import ArrowDropdown from "components/ArrowDropdown";
import Textbox from "components/Textbox";
import Button from "components/Button";
import useArrowDropdown from "hooks/useArrowDropdown";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { AppDispatch, RootState } from "store/store";
import API from "utils/httpsRequest";
import SelectDropdown from "components/SelectDropdown";
import {
  createLocation,
  editLocation,
  getLocations
} from "store/slice/BuyerSlice/LocationSlice/LocationSlice";
import { getCompaniesEntityList } from "store/slice/CompaniesSlice/CompaniesSlice";

type Props = {
  closeModal: () => void;
  singleLocationData?: any;
};
const CreateOrEditLocation = ({ closeModal, singleLocationData = null }: Props) => {
  const [error, setError] = React.useState("");
  const { register, handleSubmit, errors,control, setValue } = useForm<FormData>({
    mode: "onChange"
  });

  //Redux Store
  const dispatch = useDispatch<AppDispatch>();
  const { companies } = useSelector((state: RootState) => state.companies);
  const { loading,isLoading } = useSelector((state: RootState) => state.location);
  const [companySel, setCompanySel] = React.useState("");
  const [selCompanyVal, setSelCompanyVal] = React.useState("");
  const { user } = useSelector((state: RootState) => state.user)
  const { token } = user;

  const handleInputChangeCompany = value => {
    setCompanySel(value);
  }
  
  const onChangeCompany = value => {
    setSelCompanyVal(value);
  }

  type FormData = {
    location_description: string;
    location_code: string;
    currency: string;
    email: string;
    phoneNo: string;
    company_id: string;
    address:string;
  };

  useEffect(() => {
    dispatch(getCompaniesEntityList());
  }, []);

  useEffect(() => {
    if (singleLocationData !== null && companies) {
      setValue("location_description", singleLocationData[0]["location_description"]);
      setValue("location_code", singleLocationData[0]["location_code"]);
      setValue("company_id", singleLocationData[0]["company_id"]);
      setValue("address", singleLocationData[0]["location_address"]);
      
    }
  }, [singleLocationData, companies]);

  const { open, selectedOption, handleDropdownChange, handleAction, keyOption, ref } =
    useArrowDropdown();

  const onFormSubmit = (vals: FormData) => {
    if (singleLocationData) {
      const payload = { ...vals, id: singleLocationData[0]["id"], company_id: selCompanyVal?.['value'] };
      dispatch(
        editLocation(payload, () => {
          closeModal();
          dispatch(getLocations());
        })
      );
      return;
    }

    const { ...rest } = vals;
    const payload = {
      ...rest,
      company_id: selCompanyVal?.['value']
    };

 

    dispatch(
      createLocation(payload, () => {
        closeModal();
        dispatch(getLocations());
      })
    );
  };

  //Getting Companies List
  const companiesOptions =
    companies &&
    companies.map((_data: { id: string; company_description: string }) => {
      return {
        value: _data?.id,
        optionName: _data?.company_description
      };
    });

    const companiesOptions2 = () => {
      return API.get(`/companies/get?search=${companySel}&length=1000`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        return res.data.data.map((item) => (
          {
            label: item.company_description,
            value: item.id
          }
        ))
      })
    }


  return (
    <Modal
      modalText={singleLocationData === null ? "Create Location" : "Edit Location"}
      onClose={closeModal}
      modallength="modal-length"
    >
      <div id="buyer-create-location">
        <p className="">
          {singleLocationData === null
            ? "Complete this form with accurate data to create a new Location and assign to the relevant Legal Entity."
            : "Complete this form with accurate data to edit the Location and assign to the relevant Legal Entity."}
        </p>

        <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
          <Textbox
            type="text"
            name="location_description"
            label="Location Name"
            placeholder="Location Name"
            error={errors.location_description && "This field is required"}
            customRef={register({
              required: true
            })}
          />
          <Textbox
            type="text"
            label="Address"
            name="address"
            placeholder="Address"
            boxClasses="mt-3  mb-3"
            customRef={register({})}
          />
          {/* <SelectDropdown
            boxClasses="mt-3"
            name="company_id"
            label="Legal Entity"
            placeholder="Legal Entity"
            options={companiesOptions}
            customRef={register({ required: true })}
            error={errors.company_id && "This field is required"}
          /> */}
            <Controller
            control={control}
            defaultValue
            boxClasses="mt-3"
            name="company_id"
            render={({ ref }) => (
              <AsyncSelect
                cacheOptions
                defaultOptions
                placeholder="Assign to Legal Entity"
                value={selCompanyVal}
                loadOptions={companiesOptions2}
                onInputChange={handleInputChangeCompany}
                onChange={onChangeCompany}
              />
            )} />
            
          <Textbox
            type="text"
            label="ERP ID"
            name="location_code"
            placeholder="ERP ID"
            boxClasses="mt-3 mb-4"
            error={errors.location_code && "This field is required"}
            customRef={register({
              required: true
            })}
          />
          <Button
            label={singleLocationData === null ? "Create Location" : "Edit Location"}
            btnType="btn-primary"
            boxClasses="mt-5 mb-5"
            btnStyle="100%"
            loading={isLoading}
          />
        </form>
      </div>
    </Modal>
  );
};

export default CreateOrEditLocation;
