import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Action, statementSupplierState } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";

const initialState: statementSupplierState = {
  loading: false,
  deleting: false,
  isLoading: false,
  statement_supplier: null,
  successMessage: "",
  errorMessage: ""
};

const statementSupplierSlice = createSlice({
  name: "statement_supplier",
  initialState,
  reducers: {
    getAllStatementSupplierLoading: (state) => {
      state.loading = true;
    },
    getAllStatementSupplierSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.statement_supplier = action.payload;
    },
    getAllStatementSupplierFail: (state) => {
      state.loading = false;
    }
  }
});

const { actions, reducer } = statementSupplierSlice;
export const { getAllStatementSupplierLoading, getAllStatementSupplierFail, getAllStatementSupplierSuccess } = actions;

export const getStatementSupplier =
  (
    page?: number,
    length?: number,
    search?: string,
    vendor_id?: any,
    company_id?: string,
    run_date?: string,
    proposal_id?: string,
    proposal_item_id?: string,
    currency_id?: string,
    start_date?: string,
    end_date?: string
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getAllStatementSupplierLoading());
    try {
      const response = await API.get("/invoice_debits/get_by_vendor", {
        params: {
          page,
          length,
          search,
          vendor_id,
          start_date,
          end_date,
          company_id,
          run_date,
          proposal_id,
          proposal_item_id,
          currency_id,
          cleared: "No"
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getAllStatementSupplierSuccess(response.data));
      }
    } catch (error) {
      dispatch(getAllStatementSupplierFail());
    }
  };

export default reducer;
