import React, { useEffect, useState } from "react";
import Modal from "components/Modal";
import Textbox from "components/Textbox";
import Button from "components/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import "./styles.scss";
import { useForm } from "react-hook-form";
import { doverifyVendorFile, getSingleVendor } from "store/slice/BuyerSlice/VendorSlice/VendorSlice";
import { Document, Page, pdfjs } from "react-pdf";
import { isValidURL } from "utils/utils";

type Props = {
  closeModal: () => void;
  img: string;
};
const ViewPdf = ({ closeModal, img }: Props) => {
  //Redux Store
  const { handleSubmit, register, setValue } = useForm<FormData>({
    mode: "onBlur"
  });
  const { loading } = useSelector((state: RootState) => state.approvals);
  const [numPages, setNumPages] = useState(null) as any;
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Modal modalText="View File" onClose={closeModal} boxClasses="content-wrap">
      {/* <iframe height="100%" width="100%" src={img} title="Uploaded File"></iframe> */}

      {/* <Document file={img} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} scale="2.5" />
      </Document>
      <div>
        <p className="px-2">
          Page {pageNumber} of {numPages}
        </p>
        <div className="px-2">
          {numPages && (
            <button onClick={() => setPageNumber(pageNumber - 1)} disabled={numPages < 2 || pageNumber === 1}>
              Prev
            </button>
          )}

          {numPages && (
            <button className="pl-5" onClick={() => setPageNumber(pageNumber + 1)} disabled={numPages < 2 || pageNumber === numPages}>
              Next
            </button>
          )}
        </div>
      </div> */}

      <div>
        {img && isValidURL(img) ? (
          <div>
            <object data={img} type="application/pdf" className="w-100" style={{ height: "30rem" }}>
              <embed src={img} type="application/pdf" />
            </object>
          </div>
        ) : (
          <div>
            <Document file={img} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} scale="2.5" />
            </Document>
            <div>
              <p className="px-2">
                Page {pageNumber} of {numPages}
              </p>
              <div className="px-2">
                {numPages && (
                  <button onClick={() => setPageNumber(pageNumber - 1)} disabled={numPages < 2 || pageNumber === 1}>
                    Prev
                  </button>
                )}

                {numPages && (
                  <button className="pl-5" onClick={() => setPageNumber(pageNumber + 1)} disabled={numPages < 2 || pageNumber === numPages}>
                    Next
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ViewPdf;
