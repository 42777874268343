import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { elementStates, Action } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";

const initialState: elementStates = {
  loading: false,
  isLoading: false,
  reverse: null,
  successMessage: "",
  errorMessage: ""
};

const reverseSlice = createSlice({
  name: "reverse",
  initialState,
  reducers: {
    startLoad: (state) => {
      state.loading = true;
    },
    getReverseSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.reverse = action.payload;
    },
    endLoad: (state) => {
      state.loading = false;
    },
    reverseGrs: (state) => {
      state.loading = false;
    },
    reverseGrsFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorMessage = action.payload;
    }
  }
});

const { actions, reducer } = reverseSlice;
export const { startLoad, endLoad, reverseGrs, getReverseSuccess, reverseGrsFail } = actions;

export const getReverse =
  (page?: number, length?: number, search?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(startLoad());
    try {
      const response = await API.get("reversals/get", {
        params: {
          page,
          length,
          search
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getReverseSuccess(response.data));
      }
    } catch (error) {
      dispatch(endLoad());
    }
  };

export const reverseGrsAction =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(startLoad());
    try {
      const response = await API.post(
        `/grs/reverse/${data}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userToken}`
          }
        }
      );
      if ([200, 201].includes(response.status)) {
        dispatch(reverseGrs());
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error: any) {
      if (error.response) {
        dispatch(reverseGrsFail(error.response.data?.message));
        toastr.error(error.response.data.message);
      } else {
        dispatch(reverseGrsFail("No network connecton"));
        toastr.error("No network connecton");
      }
      setTimeout(() => {
        dispatch(reverseGrsFail(""));
      }, 3000);
    }
  };

export const reverseInvoiceAction =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(startLoad());
    try {
      const response = await API.post(
        `/invoices/reverse/${data}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userToken}`
          }
        }
      );
      if ([200, 201].includes(response.status)) {
        dispatch(reverseGrs());
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error: any) {
      if (error.response) {
        dispatch(reverseGrsFail(error.response.data?.message));
        toastr.error(error.response.data.message);
      } else {
        dispatch(reverseGrsFail("No network connecton"));
        toastr.error("No network connecton");
      }
      setTimeout(() => {
        dispatch(reverseGrsFail(""));
      }, 3000);
    }
  };

export const reversePoAction =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(startLoad());
    try {
      const response = await API.post(
        `/purchase_orders/reverse/${data}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userToken}`
          }
        }
      );
      if ([200, 201].includes(response.status)) {
        dispatch(reverseGrs());
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error: any) {
      if (error.response) {
        dispatch(reverseGrsFail(error.response.data?.message));
        toastr.error(error.response.data.message);
      } else {
        dispatch(reverseGrsFail("No network connecton"));
        toastr.error("No network connecton");
      }
      setTimeout(() => {
        dispatch(reverseGrsFail(""));
      }, 3000);
    }
  };

export default reducer;
