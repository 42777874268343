import Document from "assests/icons/sidebar/inventory.svg";
import Button from "components/Button/index";
import "./styles.scss";
import { Link } from "react-router-dom";

export const documentsColumns = [
  {
    name: "Category Type",
    index: "category",
    id: 1
  },
  {
    name: " Category Description",
    // index: "field_label",
    id: 2,
    render: ({ link, field_label }) => {
      return (
        <a target="_blank" rel="noreferrer" href={link}>
          {field_label}
        </a>
      );
    }
  },
  {
    name: "Expiry Date",
    index: "expires_on",
    id: 3
  },
  {
    name: "Status",
    index: "data_status",
    id: 4
  },
  {
    name: "Action",
    id: 5,
    render: (item) => {
      //   <Link to={`/supplier/invoice/100`}>
      let btnText = "Verify";
      if (item.data_status !== "In Progress") {
        btnText = "Done";
      }
      const openVerify = () => {
        console.log(item.data_status === "Verify");
        if (btnText === "Done") {
          return;
        } else {
          item.open();
        }
      };
      return (
        <button
          type="button"
          style={{
            padding: "8px 16px",
            background: btnText === "Verify" ? "rgba(245, 150, 37, 0.15)" : "rgba(0, 176, 7, 0.15)",
            borderRadius: "10px",
            color: btnText === "Verify" ? "rgba(245, 150, 37, 1)" : "#387C57"
          }}
          // onClick={openVerify}
        >
          {btnText}
        </button>
      );
      //   </Link>
    }
  }
];

export const documents = [
  {
    doc_no: "45000008910",
    description: "Apple Iphone 12 Max, 64GB",
    expiry: "03.03.2021",
    amount: "N4,800,000",
    status: "Delivered",
    id: 1
  },
  {
    doc_no: "45000008233",
    description: "In-house software development",
    expiry: "03.03.2021",
    amount: "N34,120,000",
    status: "In Progress",
    id: 2
  },
  {
    doc_no: "45000008738",
    description: "Computer Sales",
    expiry: "03.03.2021",
    amount: "N5,560,900",
    status: "PO Received",
    id: 3
  },
  {
    doc_no: "45000008910",
    description: "Office Supplies",
    expiry: "03.03.2021",
    amount: "N78,900",
    status: "Out for delivery",
    id: 4
  }
];
