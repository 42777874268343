import * as React from "react";
import { kFormatter } from "utils/utils";
import "./styles.scss";

type Props = {
  statsNumber?: string | number;
  text?: string;
  classes?: string;
  image?: any;
  centerText?: string;
  onClick?: () => void;
  amount?: string;
  litre?: string;
  volume?: string;
  iconBackgroundColor?: string;
  isImg?: boolean;
};

export const StatsCard: React.FunctionComponent<Props> = ({ statsNumber, text, classes, image, centerText, onClick, amount = "", litre = "", volume = "", iconBackgroundColor, isImg }) => {
  return (
    <div className="col-lg-6 col-xl-3 col-md-6 mb-3 mb-xl-0">
      <div id="stats-card">
        <div className="card d-flex flex-row p-4 justify-content-between">
          <div className="stats">
            <p>
              {kFormatter(statsNumber, amount)}
              {litre === "Lt." && statsNumber !== 0 && litre}
              {volume === "kg" && statsNumber !== 0 && volume}
            </p>
            <p>{text}</p>
          </div>
          <div className={`${!centerText && `icon-badge`} ${classes}`} style={{ backgroundColor: iconBackgroundColor }} onClick={onClick}>
            {isImg ? (
              <div style={{ display: "flex", justifyContent: "center" }} className="icon-image">
                {image}
              </div>
            ) : (
              <img src={image} alt="icon" className="icon-image" />
            )}

            <h5>{centerText}</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsCard;
