import Document from "assests/icons/sidebar/inventory.svg";
import Button from "components/Button/index";
import "./styles.scss";
import { Link } from "react-router-dom";
import DropdownMenu from "components/DropdownMenu";

export const documentsColumns = [
  // {
  //     name: "",
  //     index: "",
  //     id: 1,
  //     render: () => (
  //         <span>
  //             <img src={Document} className="avatar" alt="icon" />
  //         </span>
  //     )
  // },
  {
    name: "Category Type",
    index: "category",
    id: 1
  },
  {
    name: " Category Description",
    // index: "field_label",
    id: 2,
    render: ({ link, field_label, required }) => {
      if (link) {
        return (
          <a target="_blank" rel="noreferrer" href={link} className="hover-a">
            {field_label} <span className="asterikspan">*</span>
          </a>
        );
      } else {
        return <p className="hover-a">{field_label}</p>;
      }
    }
  },
  {
    name: "Expiry Date",
    index: "expires_on",
    id: 3
  },
  // {
  //   name: "Status",
  //   index: "data_status",
  //   id: 4
  // },
  {
    name: "Status",
    id: 5,
    render: (item) => {
      //   <Link to={`/supplier/invoice/100`}>
      // let btnText = "Verified";
      // if (item.data_status !== "In Progress") {
      //   btnText = "In Progress";
      // }
      // const openVerify = () => {
      //   if (btnText === "Done") {
      //     return;
      //   } else {
      //     item.open();
      //   }
      // };
      return (
        <button
          type="button"
          style={{
            padding: "8px 16px",
            width: "150px",
            background: !item.link ? "#FFAEBC" : item.data_status === "Verified" ? "rgba(0, 176, 7, 0.15)" : "rgba(0, 70, 176, 0.15)",
            borderRadius: "10px",
            color: !item.link ? "white" : item.data_status === "Verified" ? "#387C57" : "rgba(47, 128, 237, 1)"
          }}
          // onClick={openVerify}
        >
          {item.link ? item.data_status : "Not Provided"}
        </button>
      );
      //   </Link>
    }
  },
  {
    name: "Actions",
    id: 6,
    render: ({ open, unverify, data_status, edit, link }) => {
      const settings = [
        {
          name: data_status === "In Progress" ? "" : "Update",
          style: "edit_tag",
          select: edit
        },
        {
          name: data_status === "In Progress" ? "Verify" : "Unverify",
          style: "edit_tag",
          select: data_status === "In Progress" ? open : unverify
        }

        // {
        //     name: "Delete",
        //     style: "delete_tag",
        //     select: deleteEntity
        // }
      ];
      return (
        <span style={{ position: "relative" }}>
          {link && (
            <DropdownMenu options={settings} boxClasses="dropdown-style">
              <p style={{ fontSize: "2rem" }} className="cursor-pointer">
                ...
              </p>
            </DropdownMenu>
          )}
        </span>
      );
    }
  }
];

export const documents = [
  {
    doc_no: "45000008910",
    description: "Apple Iphone 12 Max, 64GB",
    expiry: "03.03.2021",
    amount: "N4,800,000",
    status: "Delivered",
    id: 1
  },
  {
    doc_no: "45000008233",
    description: "In-house software development",
    expiry: "03.03.2021",
    amount: "N34,120,000",
    status: "In Progress",
    id: 2
  },
  {
    doc_no: "45000008738",
    description: "Computer Sales",
    expiry: "03.03.2021",
    amount: "N5,560,900",
    status: "PO Received",
    id: 3
  },
  {
    doc_no: "45000008910",
    description: "Office Supplies",
    expiry: "03.03.2021",
    amount: "N78,900",
    status: "Out for delivery",
    id: 4
  }
];
