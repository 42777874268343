
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRfpFields, getSingleRfp, remRfpField } from "store/slice/SourcingSlice/SourcingSlice";
import { RootState } from "store/store";
import styled from "styled-components";
// import CreateKpi from "../../Modals/CreateKpi";
// import SelectKpi from "../../Modals/SelectKpi1";

const Wrapper = styled.div`
  margin-top: 3rem;

  div.spinner-border {
    display: inline-block;
    width: 5rem;
    height: 5rem;
    vertical-align: text-bottom;
    border: 0.8em solid rgba(47, 128, 237, 0.2);
    border-right-color: transparent;
    border-radius: 50%;
    animation: 1.9s linear infinite spinner-border;
  }
`;

const Box = styled.div`
  border: 1px solid #dfe2e6;
  margin-top: 1rem;
  margin-bottom: 2.5rem;
  padding: 1rem;
  padding-right: 5rem;
  border-radius: 6px;

  h5.main {
    border-bottom: 1px solid #dfe2e6;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
  }

  h5.inline-block {
    display: inline-block !important;
  }

  div.radio-box {
    display: inline-flex !important;
    margin-left: 1rem;

    div.single {
      margin-left: 1rem;
      label {
        cursor: pointer;
      }
    }
  }
`;

const PlainBtn = styled.button`
  border: 1px solid #dfe2e6;
  margin-left: 10px;
  padding: 5px;
  width: 7rem;
  border-radius: 6px;
  color: #737a91;
`;

const TechnicalRequirements = ({ id }) => {

  const dispatch = useDispatch();

  const { rfp, rfp_fields, isLoading } = useSelector((state: RootState) => state.sourcing);

  const [openCreate, setOpenCreate] = React.useState(false);
  const [openSelect, setOpenSelect] = React.useState(false);

  const [fields, setFields] = React.useState<string[]>([]);

  const handleModal = (param) => {
    if (param === 'create') setOpenCreate(!openCreate);
    if (param === 'select') setOpenSelect(!openSelect);
  }

  const handleRemField = (id) => {
    // console.log(id);
    const data = {
      rfp_field_id: id,
    }
    dispatch(remRfpField(data, () => handleSuc()));
  }

  const handleSuc = () => {
    dispatch(getRfpFields(id));
  }

  React.useEffect(() => {
    let arr: any[] = [];
    rfp_fields?.data.forEach((item) => {
      if (item.category === 'Technical') {
        arr.push(item);
      }
    })
    setFields(arr);
  }, [rfp_fields])

  // React.useEffect(() => {
  //   if (!openCreate) {
  //     dispatch(getRfpFields(id));
  //     dispatch(getSingleRfp(id));
  //   }
  // }, [openCreate])

  // React.useEffect(() => {
  //   if (!openSelect) {
  //     dispatch(getRfpFields(id));
  //     dispatch(getSingleRfp(id));
  //   }
  // }, [openSelect])

  return (
    <Wrapper className="route-transition">
      {/* {openCreate && <CreateKpi id={id} rfp={rfp} category="Technical" closeModal={() => handleModal('create')} />}
      {openSelect && <SelectKpi closeModal={() => handleModal('select')} id={id} />} */}
      <h5 className="header main" style={{ borderBottom: "1px solid #e8e8e8", paddingBottom: "1rem" }}>
        Technical Requirements{" "}
        {rfp?.rfp_status === '-2' && (
          <span style={{ display: "inline-flex", marginLeft: "1.5rem" }}>
            <PlainBtn onClick={() => setOpenSelect(true)}>Select KPI</PlainBtn>
            <PlainBtn onClick={() => setOpenCreate(true)}>Create KPI</PlainBtn>
          </span>
        )}
      </h5>

      {isLoading ? (
        <div className="justify-content-center w-100 d-flex pb-5 pt-5">
          <div className="spinner-border spinner-border-primary spinner-border-lg justify-content-center mr-2"></div>
        </div>
      ) : (
        <>
          {fields.map((item, index) => {
            return (
              <Box key={index}>
                <h5 className="header main">
                  Question: {item?.['field_label']} <button style={{ float: 'right', color: '#737A91' }} onClick={() => handleRemField(item?.['id'])}>Remove</button>
                </h5>
                <h5 className="inline-block">Response Type</h5>
                <div className="radio-box justify-content-between">
                  <div className="single">
                    <input disabled type="radio" name={`price${index}`} id="manual" className="mr-2" defaultChecked={item?.['evaluation_type'] === 'Manual'} />
                    <label className="radio-box_label" htmlFor="manual">
                      Manual
                    </label>
                  </div>
                  <div className="single">
                    <input disabled type="radio" name={`price${index}`} id="automatic" className="mr-2" defaultChecked={item?.['evaluation_type'] === 'Automatic'} />
                    <label className="radio-box_label" htmlFor="automatic">
                      Automatic
                    </label>
                  </div>
                </div>
                {item?.['response_scores'] && (
                  <>
                    <h5 className="header main">Response Values [ {item?.['field_type']} ]</h5>
                    <table className="table table-striped" style={{ width: "50%", marginTop: "2rem" }}>
                      <thead style={{ background: "rgba(47,128,237, 0.1)", borderRadius: "6px" }}>
                        <tr>
                          <th style={{ border: "0" }}>Response Value</th>
                          <th style={{ border: "0" }}>Assigned Score</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item?.['response_scores'].map((item, index) => (
                          <tr key={index}>
                            <td>{item.response}</td>
                            <td>{item.response_score}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
              </Box>
            )
          })}
        </>
      )}

    </Wrapper>
  );
};

export default TechnicalRequirements;