import { toastr } from "react-redux-toastr";
import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RoleState, Action } from "./interface";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState: RoleState = {
  isLoading: false,
  roles: null,
  role: null,
  permissions: null,
  success: "",
  errorMessage: ""
};

const roleMgtSlice = createSlice({
  name: "roleMgt",
  initialState,
  reducers: {
    getRolesLoading: (state) => {
      state.isLoading = true;
    },
    getRolesSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.roles = action.payload;
    },
    getRolesFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
    },
    getRoleLoading: (state) => {
      state.isLoading = true;
    },
    getRoleSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.role = action.payload;
    },
    getRoleFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
    },
    createRoleLoading: (state) => {
      state.isLoading = true;
    },
    createRoleSuccess: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    createRoleFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    deleteRoleLoading: (state) => {
      state.isLoading = true;
    },
    deleteRoleSuccess: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    deleteRoleFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    editRoleLoading: (state) => {
      state.isLoading = true;
    },
    editRoleSuccess: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    editRoleFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    activateRoleLoading: (state) => {
      state.isLoading = true;
    },
    activateRoleSuccess: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    activateRoleFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    deactivateRoleLoading: (state) => {
      state.isLoading = true;
    },
    deactivateRoleSuccess: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    deactivateRoleFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    getPermissionsLoading: (state) => {
      state.isLoading = true;
    },
    getPermissionsSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.permissions = action.payload;
    },
    getPermissionsFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
    }
  }
});

const { actions, reducer } = roleMgtSlice;

export const {
  createRoleLoading,
  createRoleSuccess,
  createRoleFail,
  getRoleLoading,
  getRoleSuccess,
  getRoleFail,
  deleteRoleFail,
  deleteRoleLoading,
  deleteRoleSuccess,
  editRoleFail,
  editRoleLoading,
  editRoleSuccess,
  activateRoleLoading,
  activateRoleFail,
  activateRoleSuccess,
  deactivateRoleLoading,
  deactivateRoleSuccess,
  deactivateRoleFail,
  getRolesFail,
  getRolesLoading,
  getRolesSuccess,
  getPermissionsFail,
  getPermissionsLoading,
  getPermissionsSuccess
} = actions;

export const getRoles =
  (page?: number, length?: number, search?: string, role_type?: string, sortBy?: string, orderBy?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getRolesLoading());
    try {
      const response = await axios.get(`${BASE_URL}/user_roles/get`, {
        params: {
          page,
          length,
          search,
          sortBy,
          orderBy,
          role_type
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if (response.status) {
        dispatch(getRolesSuccess(response.data));
      }
    } catch (error) {
      dispatch(getRolesFail("Fail"));
    }
  };

export const getRole =
  (id: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getRoleLoading());
    try {
      const response = await axios.get(`${BASE_URL}/user_roles/get/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if (response.status) {
        dispatch(getRoleSuccess(response.data));
      }
    } catch (error) {
      dispatch(getRoleFail("Fail"));
    }
  };

export const createRole =
  (payload: object, cb: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(createRoleLoading());
    const { userToken } = getState().user;
    try {
      const response = await axios.post(`${BASE_URL}/user_roles/create`, payload, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(createRoleSuccess(response.data.message));
        toastr.success("", response.data.message);
        dispatch(getRoles());
        cb();
      }
    } catch (error) {
      dispatch(createRoleFail(error?.response?.data?.error));
      toastr.error(error?.response?.data.message);
    }
  };
export const editRole =
  (data: any, cb: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(editRoleLoading());
    try {
      const response = await axios.post(`${BASE_URL}/user_roles/edit`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if (response.status) {
        dispatch(editRoleSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb();
      }
    } catch (error) {
      dispatch(editRoleFail(error.response.data.message));
      toastr.error(error.response.data.message);
    }
  };

export const deleteRole =
  (id: string, cb: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(deleteRoleLoading());
    try {
      const response = await axios.post(`${BASE_URL}/user_roles/delete`, id, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if (response.status) {
        dispatch(deleteRoleSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb();
      }
    } catch (error) {
      dispatch(deleteRoleFail(error.response.data.message));
      toastr.error(error.response.data.message);
    }
  };

// export const deactivateRole =
//   (id, cb: () => void): AppThunk =>
//   async (dispatch: AppDispatch, getState) => {
//     const data = { status: "inactive" };
//     const { userToken } = getState().user;
//     dispatch(deactivateRoleLoading());
//     try {
//       const response = await axios.post(`${BASE_URL}/user_roles/deactivate/${id}`, data, {
//         headers: {
//           Authorization: `Bearer ${userToken}`,
//           "Content-Type": "application/json"
//         }
//       });
//       if (response.status) {
//         dispatch(deactivateRoleSuccess(response.data.message));
//         toastr.success("", response.data.message);
//         cb();
//       }
//     } catch (error) {
//       dispatch(deactivateRoleFail(error.response.data.message));
//       toastr.error(error.response.data.message);
//     }
//   };

// export const activateRole =
//   (id, cb: () => void): AppThunk =>
//   async (dispatch: AppDispatch, getState) => {
//     const { userToken } = getState().user;
//     const data = { status: "active" };
//     dispatch(activateRoleLoading());
//     try {
//       const response = await axios.post(`${BASE_URL}/user_roles/activate/${id}`, data, {
//         headers: {
//           Authorization: `Bearer ${userToken}`,
//           "Content-Type": "application/json"
//         }
//       });
//       if (response.status) {
//         dispatch(activateRoleSuccess(response.data.message));
//         toastr.success("", response.data.message);
//         cb();
//       }
//     } catch (error) {
//       dispatch(activateRoleFail(error.response.data.message));
//       toastr.error(error.response.data.message);
//     }
//   };

export const getPermissions =
  (type?: string, page?: number, length?: number, search?: string, sortBy?: string, orderBy?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getPermissionsLoading());
    try {
      const response = await axios.get(`${BASE_URL}/permissions/get/${type}`, {
        params: {
          page,
          length,
          search,
          sortBy,
          orderBy
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if (response.status) {
        dispatch(getPermissionsSuccess(response.data));
      }
    } catch (error) {
      dispatch(getPermissionsFail("Fail"));
    }
  };

export default reducer;
