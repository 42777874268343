import DataTable from "components/DataTable";
import Dropdown from "components/Dropdown";
import StatsCard from "components/StatsCard";
import Icon from "assests/icons/statsCard/Vector (4).png";
import clock from "assests/icons/buyer/clock.svg";
import interview from "assests/icons/buyer/interview 1.svg";
import React, { useCallback, useEffect, useState } from "react";
import { tableCol } from "./data";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import useSearch from "hooks/useSearch";
import CreateRequisition from "components/ModalBuyer/CreateRequisition";
import { getReqSummary, getRequisitions, getRequisitionsDownload } from "store/slice/RequisitionSlice/RequisitionSlice";

import { useHistory } from "react-router-dom";
import SearchIcon from "../../../components/SearchBar";
import filterIcon from "assests/icons/buyer/filter-icon.svg";
import Pagination from "components/Pagination";
import FilterCard from "components/FilterCard";
import { useForm, Controller } from "react-hook-form";
import AsyncSelect from "react-select/async";
import API from "utils/httpsRequest";
import styled from "styled-components";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import moment from "moment";

const FilterContainer = styled.div`
  .mySelect__control {
    border-radius: 10px;
    border: 1px solid rgb(223, 226, 230) !important;
  }

  .mySelect__value-container {
    height: 3rem;
  }
`;

const Requisition = () => {
  const [page, setPage] = useState<number>(1);
  const [perpage, setPerpage] = useState<number>(10);
  const [modals, setModals] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [deptVal, setDeptVal] = useState("");
  const [selDeptVal, setSelDeptVal] = useState<any>("");

  const [locVal, setLocVal] = useState("");
  const [selLocVal, setSelLocVal] = useState<any>("");
  const [startDate, setStartDate] = useState("");
  const [urgency, setUrgency] = useState("");
  const [endDate, setEndDate] = useState("");
  const [reqStatus, setReqStatus] = useState("");
  const [deptId, setDeptId] = useState("");
  const [locId, setLocId] = useState("");
  const { search, inputRef, handleChange, searched } = useSearch(getRequisitions, perpage, undefined, [reqStatus, undefined, startDate, endDate, deptId, locId, urgency]);

  const dispatch = useDispatch<AppDispatch>();

  //Redux
  const { requisitions, requisitionsDownload, isLoading, summary } = useSelector((state: RootState) => state.requisition);
  const { user } = useSelector((state: RootState) => state.user);

  const history = useHistory();

  const { token } = user;

  const { register, handleSubmit, reset, control } = useForm({
    mode: "onChange"
    // defaultValues: {
    //   start_date: '',
    //   end_date: '',
    //   from: '',
    //   to: '',
    // }
  });

  const onSubmit = (vals) => {
    setStartDate(vals.date_from);
    setEndDate(vals.date_to);
    setReqStatus(vals.requisition_status);
    setDeptId(selDeptVal["value"]);
    setLocId(selLocVal["value"]);
    setUrgency(vals.urgency);
    const data = {
      ...vals,
      department_id: selDeptVal["value"] || "",
      location_id: selLocVal["value"] || ""
    };
    dispatch(getRequisitions(page, perpage, search, data.requisition_status, undefined, data.date_from, data.date_to, data.department_id, data.location_id, data.urgency));
    dispatch(getRequisitionsDownload(1, 10000, "", data.requisition_status, undefined, data.date_from, data.date_to, data.department_id, data.location_id, data.urgency));
  };

  const handleClear = () => {
    setStartDate("");
    setEndDate("");
    setReqStatus("");
    setDeptId("");
    setLocId("");
    dispatch(getRequisitions(page, perpage, search));
    dispatch(getRequisitionsDownload());
    reset();
    setSelDeptVal("");
    setSelLocVal("");
  };

  const handleInputChangeDept = (value) => setDeptVal(value);

  const onChangeDept = (value) => setSelDeptVal(value);

  const handleInputChangeLoc = (value) => setLocVal(value);

  const onChangeLoc = (value) => setSelLocVal(value);

  const loadDept = () => {
    return API.get(`/departments/get?page=1&length=10000&search=${deptVal}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: `${item.department_description} - ${item.department_code}`,
        value: item.id
      }));
    });
  };

  const loadLoc = () => {
    return API.get(`/locations/get?page=1&length=10000&search=${deptVal}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: `${item.location_description}`,
        value: item.id
      }));
    });
  };

  useEffect(() => {
    dispatch(getRequisitions(page, perpage));
    dispatch(getRequisitionsDownload());
    dispatch(getReqSummary());
  }, []);

  const paginate = React.useCallback(
    (pageNo: number, length: number) => {
      setPage(pageNo);
      dispatch(getRequisitions(pageNo, length, search, reqStatus, undefined, startDate, endDate, deptId, locId, urgency));
    },
    [search, reqStatus, startDate, endDate, deptId, locId, urgency, dispatch]
  );

  React.useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  const closeModal = () => {
    setModals(!modals);
  };

  // CreateRequisition
  const createReq = () => {
    closeModal();
  };

  const showRequisition = (e, item) => {
    history.push({ pathname: `/buyer/requisition/${item.id}` });
  };

  const handleNewPage = React.useCallback(
    (pageNo: number, page = 1) => {
      setPerpage(pageNo);
      setPage(1);
      dispatch(getRequisitions(page, pageNo, search, reqStatus, undefined, startDate, endDate, deptId, locId, urgency));
    },
    [search, reqStatus, dispatch, startDate, endDate, deptId, locId, urgency]
  );

  const handlePageChange = React.useCallback(
    (page: number, length: number) => {
      setPage(page);
      dispatch(getRequisitions(page, length, search, reqStatus, undefined, startDate, endDate, deptId, locId, urgency));
    },
    [search, reqStatus, dispatch, startDate, endDate, deptId, locId, urgency]
  );

  const checkUrgency = (status, devDate) => {
    let urgentStatus = 0;
    let day1 = new Date();
    var day2 = new Date(devDate);

    let difference = day2.getTime() - day1.getTime();
    var days3 = Math.ceil(difference / (1000 * 3600 * 24));

    if (parseInt(status) >= 2) {
      urgentStatus = 4;
    } else if (days3 < 0) {
      urgentStatus = 1;
    } else if (days3 <= 5) {
      urgentStatus = 2;
    } else if (days3 > 5) {
      urgentStatus = 0;
    }
    // else{
    //   if (difference <== 432,000,000 ) {

    //   }

    // }
    // console.log(difference, urgentStatus);
    return urgentStatus;
  };
  const checkUrgency2 = (status, devDate) => {
    let urgentStatus;
    let day1 = new Date();
    var day2 = new Date(devDate);

    let difference = day2.getTime() - day1.getTime();
    var days3 = Math.ceil(difference / (1000 * 3600 * 24));

    if (parseInt(status) >= 2) {
      urgentStatus = 4;
    } else if (days3 < 0) {
      urgentStatus = "Overdue";
    } else if (days3 <= 5) {
      urgentStatus = "Urgent";
    } else if (days3 > 5) {
      urgentStatus = "Not Urgent";
    }
    // else{
    //   if (difference <== 432,000,000 ) {

    //   }

    // }
    // console.log(difference, urgentStatus);
    return urgentStatus;
  };
  const checkStat = (status) => {
    let stat;
    if (status === "1") {
      stat = "Approved";
    } else if (status === "-1") {
      stat = "Pending";
    } else if (status === "-2") {
      stat = "Draft";
    } else if (status === "2") {
      stat = "Rfx Generated";
    } else if (status === "3") {
      stat = "Completed";
    }
    return stat;
  };

  const requisitionList =
    requisitions &&
    requisitions?.data?.map((item: any) => {
      return {
        ...item,
        created_at: moment(item.requisition_date).format("L"),
        requisition_code: item.requisition_code,
        requisition_title: item.requisition_description,
        location_description: item.location_description,
        requisition_type: item.requisition_type,
        approved_by: `${item.approved_by_othernames === null ? "" : item.approved_by_othernames} ${item.approved_by_lastname === null ? "" : item.approved_by_lastname}`,
        id: item.id,
        urgency: checkUrgency(item.requisition_status, item.delivery_date)
      };
    });

  const requisitionDownloadList =
    requisitionsDownload &&
    requisitionsDownload?.data?.map((item: any) => {
      return {
        ...item,
        created_at: moment(item.requisition_date).format("L"),
        requisition_code: item.requisition_code,
        requisition_title: item.requisition_description,
        location_description: item.location_description,
        requisition_type: item.requisition_type,
        approved_by: `${item.approved_by_othernames === null ? "" : item.approved_by_othernames} ${item.approved_by_lastname === null ? "" : item.approved_by_lastname}`,
        id: item.id,
        urgency: checkUrgency2(item.requisition_status, item.delivery_date),
        status: checkStat(item.requisition_status)
      };
    });

  return (
    <div id="requisition">
      <div className="container">
        {modals && <CreateRequisition closeModal={closeModal} />}
        <h3 className="header">Requisition Stats</h3>
        <div className="stats-card mt-4 row">
          <StatsCard text="Requisitions" classes="stats-icon-blue" image={interview} statsNumber={requisitions?.count} />
          <StatsCard text="Pending Approval" classes="stats-icon-orange" image={clock} statsNumber={summary?.["total_requisitions"]} />
          {/* <StatsCard image={Icon} classes="middle-img" centerText="Create Requisition" onClick={createReq} />{" "} */}
        </div>
        {showFilter && (
          <FilterContainer>
            <FilterCard handleSubmit={handleSubmit(onSubmit)} clear={handleClear}>
              <div className="row">
                <div className="col col-4">
                  <label style={{ display: "block" }}>Start Date</label>
                  <input
                    type="date"
                    name="date_from"
                    className="mt-3"
                    placeholder="Start Date"
                    style={{
                      width: "100%",
                      height: "3rem",
                      padding: "5px",
                      borderRadius: "10px",
                      border: "1px solid #dfe2e6"
                    }}
                    ref={register({ required: false })}
                  />
                </div>
                <div className="col col-4">
                  <label style={{ display: "block" }}>End Date</label>
                  <input
                    type="date"
                    name="date_to"
                    className="mt-3"
                    placeholder="End Date"
                    style={{
                      width: "100%",
                      height: "3rem",
                      padding: "5px",
                      borderRadius: "10px",
                      border: "1px solid #dfe2e6"
                    }}
                    ref={register({ required: false })}
                  />
                </div>
                <div className="col col-4">
                  <label style={{ display: "block" }}>Urgency Status</label>
                  <select
                    className="mt-3"
                    style={{
                      width: "100%",
                      height: "3rem",
                      padding: "5px",

                      borderRadius: "10px",
                      border: "1px solid #dfe2e6"
                    }}
                    name="urgency"
                    ref={register({
                      required: false
                    })}
                  >
                    <option value="">Select</option>
                    <option value="Not Urgent">Not Urgent</option>
                    <option value="Urgent">Urgent</option>
                    <option value="Overdue">Overdue </option>
                    <option value="Processed">Processed</option>
                  </select>
                </div>
              </div>
              <div
                className="row"
                style={{
                  marginTop: "2rem"
                }}
              >
                <div className="col col-4">
                  <label style={{ display: "block" }}>Requisition Status</label>
                  <select
                    style={{
                      width: "100%",
                      height: "3rem",
                      padding: "5px",
                      borderRadius: "10px",
                      border: "1px solid #dfe2e6"
                    }}
                    name="requisition_status"
                    ref={register({
                      required: false
                    })}
                  >
                    <option value="">Select</option>
                    <option value="1">Approved</option>
                    <option value="-1">Pending</option>
                    <option value="-2">Draft</option>
                    <option value="2">Generated</option>
                    <option value="3">Completed</option>
                  </select>
                </div>
                <div className="col col-4">
                  <label style={{ display: "block" }}>Department</label>
                  <Controller
                    control={control}
                    defaultValue
                    name="department_id"
                    render={({ ref }) => (
                      <AsyncSelect
                        cacheOption
                        defaultOptions
                        placeholder="Select Department"
                        value={selDeptVal}
                        loadOptions={loadDept}
                        onInputChange={handleInputChangeDept}
                        onChange={onChangeDept}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        classNamePrefix="mySelect"
                      />
                    )}
                  />
                </div>
                <div className="col col-4">
                  <label style={{ display: "block" }}>Location</label>
                  <Controller
                    control={control}
                    defaultValue
                    name="department_id"
                    render={({ ref }) => (
                      <AsyncSelect
                        cacheOption
                        defaultOptions
                        placeholder="Select Location"
                        value={selLocVal}
                        loadOptions={loadLoc}
                        onInputChange={handleInputChangeLoc}
                        onChange={onChangeLoc}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        classNamePrefix="mySelect"
                      />
                    )}
                  />
                </div>
              </div>
            </FilterCard>
          </FilterContainer>
        )}
        <div className="document mt-5">
          <h3 className="title font-weight-bold" style={{ paddingBottom: "1rem" }}>
            Requisition List
          </h3>

          <div className="search-field">
            <SearchIcon placeholder="Search for Requisition" boxClasses="mb-3 width80" textRef={inputRef} value={search} onChange={handleChange} />
            <Dropdown perPage={perpage} action={handleNewPage} />
            <div style={{ display: "flex", alignItems: "center" }}>
              <button
                style={{
                  padding: "5px 10px",
                  borderRadius: "10px",
                  background: "#FFFFFF",
                  color: "#737A91",
                  border: "1px solid #DFE2E6",
                  fontSize: "18px",
                  marginLeft: "1rem",
                  marginRight: "1rem",
                  width: "max-content"
                }}
                onClick={() => setShowFilter(!showFilter)}
              >
                <img src={filterIcon} alt="icon" className="icon-image" />
                <span
                  style={{
                    paddingLeft: "1rem"
                  }}
                >
                  {!showFilter ? "Filters" : "Hide Filters"}
                </span>
              </button>
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button btn btn-success"
                table="table-to-xls"
                filename="Requisition"
                sheet="tablexls"
                buttonText="Download"
              />
            </div>
          </div>

          <div style={{ display: "none" }} className="downloadSide">
            <table id="table-to-xls">
              <thead>
                <th>Requisition Date</th>
                <th>Requisition Number</th>
                <th>Title</th>
                <th>Delivery Location</th>
                <th>Requisition Type</th>
                <th>Approved By</th>
                <th>Status</th>
                <th>Urgency</th>
              </thead>
              <tbody>
                {requisitionDownloadList &&
                  requisitionDownloadList.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td>{item.created_at}</td>

                        <td>{item.requisition_code}</td>
                        <td>{item.requisition_title}</td>
                        <td>{item.location_description}</td>
                        <td>{item.requisition_type}</td>
                        <td>{item.approved_by}</td>
                        <td>{item.status}</td>
                        <td>{item.urgency}</td>
                      </tr>
                    );
                  })}
                <tr></tr>
              </tbody>
            </table>
          </div>

          <div className="mt-3">
            <DataTable columns={tableCol} data={requisitionList} loading={isLoading} onClick={showRequisition} />
            <Pagination page={page} lastPage={requisitions?.pagination?.lastPage} paginate={paginate} total={requisitions?.pagination?.total} handlePageChange={handlePageChange} perPage={perpage} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Requisition;
