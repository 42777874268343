import StatsCard from "components/StatsCard";
import React, { useCallback, useState } from "react";
import "../styles.scss";
import PeopleIcon from "assests/icons/buyer/people-icon.svg";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import SearchIcon from "components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { departmentColumn, departmentData, purchaseGroupColumn } from "../data";
import CreateOrEditDepartment from "components/ModalBuyer/CreateOrEditDepartment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import useSearch from "hooks/useSearch";
import Pagination from "components/Pagination";
import { getCompaniesEntityList } from "store/slice/CompaniesSlice/CompaniesSlice";
import { toastPopUp } from "utils/utils";
import { deletePurchaseGroup, getPurchaseGroup } from "store/slice/BuyerSlice/PurchaseGroup/PurchaseGroup";
import CreateOrEditPurchaseGroup from "components/ModalBuyer/CreateOrEditPurchaseGroup";
import { checkPermission } from "utils/utils";

const PurchaseGroup = () => {
  //State
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [modal, setModal] = useState<boolean>(false);
  const [singlePurchaseGroup, setSinglePurchaseGroup] = useState<any>(null);

  //Redux
  const dispatch = useDispatch<AppDispatch>();
  const { purchaseGroup, loading } = useSelector((state: RootState) => state.purchaseGroup);

  const { role_access } = useSelector((state: RootState) => state.user?.user);


  //Hook
  const { search, inputRef, handleChange, searched } = useSearch(getPurchaseGroup, perPage);

  //Show Number for PerPage Request
  const handleNewPage = (pageNo: number, page: number = 1) => {
    setPerPage(pageNo);
    setPage(1);
    dispatch(getPurchaseGroup(page, pageNo, search));
  };

    //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, perPage: number) => {
      setPage(page);
      dispatch(getPurchaseGroup(page, perPage, search));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, perPage: number) => {
      setPage(pageNo);
      dispatch(getPurchaseGroup(pageNo, perPage, search));
    },
    [search]
  );

  //Onmout Requests
  useEffect(() => {
    // dispatch(getPurchaseGroup(page, perPage));
    dispatch(getPurchaseGroup(page,perPage));
  }, []);

  // setpage to the first page when a searched executed
  useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  const closeModal = () => {
    setModal((prev) => !prev);
    setSinglePurchaseGroup(null);
  };

  //Edit DepartmentData
  const editPurchaseGroup = (id: string, purchasing_group_code: string, purchasing_group_description: string) => {
    setSinglePurchaseGroup({ id, purchasing_group_code, purchasing_group_description });
    setModal((prev) => !prev);
  };

  //  delete
   const deletePurchaseGroup2 = (name: string, id: string) => {
    const payload = { purchasing_group_id: id };
    toastPopUp(
        () => {
            dispatch(
                deletePurchaseGroup(payload, () => {
                    dispatch(getPurchaseGroup(page, perPage));
                })
            );
        },
        "Delete",
        "Cancel",
        "Delete the entity",
        name,
        "red"
    );
};


  const purchaseGroupList =
    purchaseGroup &&
    purchaseGroup?.data?.map((item) => {
      return {
        id: item.id,
        purchasing_group_code: item.purchasing_group_code,
        purchasing_group_description: item.purchasing_group_description,
        edit: () =>
        checkPermission(role_access, "SETUP_INDEX") && editPurchaseGroup(
            item.id,
            item.purchasing_group_code,
            item.purchasing_group_description,
          ),
        deletePurchaseGroup4: () =>  checkPermission(role_access, "SETUP_INDEX") && deletePurchaseGroup2(item.purchasing_group_description, item.id)
      };
    });

  return (
    <div id="settings-location-data">
      
      {modal && <CreateOrEditPurchaseGroup closeModal={closeModal} singlePurchaseGroup={singlePurchaseGroup} />}
      <div className="container mt-3">
        <div className="row mt-3 mb-5">
          <StatsCard
            statsNumber={purchaseGroup?.count ?? 0}
            text="Purchase Group"
            classes="stats-icon"
            image={PeopleIcon}
            iconBackgroundColor="rgba(45, 156, 219, 0.1)"
          />
          <StatsCard image={AddIcon} classes="middle-img" centerText="Create Purchase Group" onClick={closeModal} />
        </div>

        <div className="d-flex  mb-3">
          <SearchIcon
            placeholder="Search for Purchase Group"
            boxClasses=""
            textRef={inputRef}
            value={search}
            onChange={handleChange}
          />
          <Dropdown perPage={perPage} action={handleNewPage} />
        </div>
        <DataTable columns={purchaseGroupColumn} data={purchaseGroupList} loading={loading} />
        {
          purchaseGroup?.count > 10 ?
              <Pagination
              page={page}
              lastPage={purchaseGroup?.pagination?.lastPage}
              paginate={paginate}
              total={purchaseGroup?.pagination?.total}
              handlePageChange={handlePageChange}
              perPage={perPage}
              />
           : null
        }
       
      </div>
    </div>
  );
};

export default PurchaseGroup;
