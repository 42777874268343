/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import StatsCard from "components/StatsCard";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import budgetIcon from "assests/icons/buyer/budget-icon.svg";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import filterIcon from "assests/icons/buyer/filter-icon.svg";
import "./styles.scss";
import SearchIcon from "components/SearchBar";
import Dropdown from "components/Dropdown";
import useSearch from "hooks/useSearch";
import { deleteBudgetHeader, getAllBudgetHeaders, getSummary } from "store/slice/BuyerSlice/Budget/BudgetSlice";
import { postedInvoiceCol } from "./data";
import DataTable from "components/DataTable";
import Pagination from "components/Pagination";
import Textbox from "components/Textbox";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import FilterCard from "components/FilterCard";
import SelectDropdown from "components/SelectDropdown";
import { getVendors } from "store/slice/BuyerSlice/VendorSlice/VendorSlice";
import ApprovedUsers from "assests/icons/buyer/members-green.svg";
import { getPostedInvoice } from "store/slice/BuyerSlice/PostedInvoiceSlice/PostedInvoiceSlice";
import { formatCurrency, kFormatter, moneyFormatter } from "../../../utils/utils";
import { getInvoice } from "store/slice/BuyerSlice/Invoice/InvoiceSlice";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Typography } from "@mui/material";
const Treasury = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  const { posted_invoice, loading } = useSelector((state: RootState) => state.postedInvoice);
  console.log(posted_invoice, "posted invoice");
  const [page, setPage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [showFilter, setShowFilter] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [modalText, setModalText] = useState("");
  const [open, setOpen] = React.useState(false);
  const [noteList, setNoteList] = useState<Array<any>>([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setNoteList([]);
    setOpen(false);
  };
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { search, inputRef, handleChange } = useSearch(getPostedInvoice, perpage, "", [undefined, undefined, undefined, undefined, startDate, endDate]);

  const { register, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      start_date: "",
      end_date: "",
      from: "",
      to: ""
    }
  });
  // pop style
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "white",
    boxShadow: 24,
    borderRadius: "10px",
    p: 4
  };

  const onSubmit = (vals) => {
    setStartDate(vals.start_date);
    setEndDate(vals.end_date);
    dispatch(getPostedInvoice(page, perpage, search, undefined, undefined, undefined, undefined, vals.start_date, vals.end_date));
  };

  const handleClear = () => {
    setStartDate("");
    setEndDate("");
    dispatch(getPostedInvoice(page, perpage, search));
    reset();
  };
  useEffect(() => {
    dispatch(getPostedInvoice(page, perpage));
  }, []);
  const handleNewPage = React.useCallback(
    (pageNo: number, page = 1) => {
      setPerpage(pageNo);
      setPage(1);
      dispatch(getPostedInvoice(page, pageNo, search, undefined, undefined, undefined, undefined, startDate, endDate));
    },
    [search, startDate, endDate]
  );

  const paginate = React.useCallback(
    (pageNo: number, length: number) => {
      setPage(pageNo);
      dispatch(getPostedInvoice(pageNo, length, search, undefined, undefined, undefined, undefined, startDate, endDate));
    },
    [search, startDate, endDate, dispatch]
  );

  const handlePageChange = React.useCallback(
    (page: number, length: number) => {
      setPage(page);
      dispatch(getPostedInvoice(page, length, search, undefined, undefined, undefined, undefined, startDate, endDate));
    },
    [search, startDate, endDate, dispatch]
  );

  // function getItemStatus(status) {
  //   if (status === "-2") return "Draft";
  //   if (status === "-1") return "Pending";
  //   if (status === "1") return "Approved";
  //   if (status === "2") return "Paid";
  //   if (status === "3") return "Parked";
  // }

  const { invoice, isLoading: loadingInv } = useSelector((state: RootState) => state.invoice);
  console.log(noteList);
  useEffect(() => {
    // if (invoice) {
    //   for (let i = 0; i < invoice.workflow.length; i++) {
    //     if (invoice.workflow[i].approval_note) {
    //       console.log(invoice.workflow[i].approval_note, "lolkllkk");
    //       setNoteList([...noteList, invoice.workflow[i].approval_note]);
    //     } else {
    //       setNoteList([...noteList]);
    //     }
    //   }
    // }
    if (invoice) {
      const workflow = invoice.workflow;

      const filt = workflow.filter((item) => {
        return item.approval_note !== null;
      });

      filt.map((item) => {
        setNoteList((prev) => {
          return [...prev, { note: item.approval_note, other_name: item.action_owner_othernames, last_name: item.action_owner_lastname }];
        });
      });

      console.log(filt, "filtt");
    }
  }, [invoice]);
  console.log(invoice, noteList, "part");
  const read = (item: any) => {
    // console.log(item.invoice_id);
    // console.log(item);

    dispatch(getInvoice(item.invoice_id));
    handleOpen();
  };
  const postedInvoiceList =
    posted_invoice &&
    posted_invoice?.data?.map((item) => {
      return {
        // Invoice_date: "",
        posting_date: item.posting_date,
        vendor_name: item.vendor_description,
        po_number: item.purchase_order_code,
        invoice_number: item.invoice_code,
        invoice_amount: `${item.currency_iso} ${formatCurrency(item.gross_amount)}`,
        invoice_tracker: item.invoice_tracker_description,
        status: item.invoice_status,
        id: item.invoice_id,
        readNote: () => read(item)
        // posted_status: getItemStatus(item.invoice_status)

        // view: () => history.push(`/buyer/budget/${item.id}`),
        // edit: () => editBudget(item),
        // deleteId: () => handleDeleteBudget(item.id)
      };
    });

  useEffect(() => {
    if (!modal) setModalData({});
  }, [modal]);

  return (
    <div id="budget">
      <StatsCard statsNumber={posted_invoice?.count} text="Posted Invoices" classes="stats-icon-orange" image={budgetIcon} iconBackgroundColor="rgba(0, 176, 80, 0.1)" />

      <div className="container">
        {showFilter && (
          <FilterCard handleSubmit={handleSubmit(onSubmit)} clear={handleClear}>
            <div className="row">
              <div className="col col-4">
                <label style={{ display: "block" }}>Start Date</label>
                <input
                  type="date"
                  name="start_date"
                  className="mt-3"
                  placeholder="Start Date"
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  ref={register({ required: false })}
                />
              </div>
              <div className="col col-4">
                <label style={{ display: "block" }}>End Date</label>
                <input
                  type="date"
                  name="end_date"
                  className="mt-3"
                  placeholder="End Date"
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  ref={register({ required: false })}
                />
              </div>
            </div>
          </FilterCard>
        )}
        <div className="document mt-5">
          <div className="search-field">
            <SearchIcon placeholder="Search for invoice Item" boxClasses="mb-3" textRef={inputRef} value={search} onChange={handleChange} />
            <Dropdown perPage={perpage} action={handleNewPage} />
            <div>
              <button
                style={{
                  padding: "5px 10px",
                  borderRadius: "10px",
                  background: "#FFFFFF",
                  color: "#737A91",
                  border: "1px solid #DFE2E6",
                  fontSize: "18px",
                  marginLeft: "1rem",
                  width: "max-content"
                }}
                onClick={() => setShowFilter(!showFilter)}
              >
                <img src={filterIcon} alt="icon" className="icon-image" />
                <span
                  style={{
                    paddingLeft: "1rem"
                  }}
                >
                  {!showFilter ? "Filters" : "Hide Filters"}
                </span>
              </button>
            </div>
          </div>
          <div className="mt-3">
            <DataTable columns={postedInvoiceCol} data={postedInvoiceList} loading={loading} />
            <Pagination
              page={page}
              lastPage={posted_invoice?.pagination?.lastPage}
              paginate={paginate}
              total={posted_invoice?.pagination?.total}
              handlePageChange={handlePageChange}
              perPage={perpage}
            />
          </div>
        </div>

        {/* modal pop */}
        <Modal
          open={open}
          onClose={() => {
            setNoteList([]);
            handleClose();
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {/* <Typography id="modal-modal-title" variant="h6" component="h2">
              Notes
            </Typography> */}
            {noteList.length ? (
              <ol>
                {noteList.map((item: any) => {
                  return (
                    <li className="md-2 mb-3" key={item?.id}>
                      <p style={{ fontWeight: "bold", color: "black" }} className="notename mb-1">{`${item.other_name} ${item.last_name}`}</p>
                      <p className="">Note: {item.note}</p>
                    </li>
                  );
                })}
              </ol>
            ) : (
              "Empty Note"
            )}
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default Treasury;
