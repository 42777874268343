import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { businessStates, Action } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";

const initialState: businessStates = {
    loading: false,
    loadingCat: false,
    isLoading: false,
    isLoadingCat:false,
    deleting: false,
    business: null,
    businessCat: null,
    successMessage: "",
    errorMessage: ""
};

const businessSlice = createSlice({
    name: "business",
    initialState,
    reducers: {
        getAllBusinessLoading: (state) => {
            state.loading = true;
        },
        getAllBusinessSuccess: (state, action: PayloadAction<Action>) => {
            state.loading = false;
            state.business = action.payload;
        },
        getAllBusinessFail: (state) => {
            state.loading = false;
        },
        getAllBusinessCatLoading: (state) => {
            state.loadingCat = true;
        },
        getAllBusinessCatSuccess: (state, action: PayloadAction<Action>) => {
            state.loadingCat = false;
            state.businessCat = action.payload;
        },
        getAllBusinessCatFail: (state) => {
            state.loadingCat = false;
        },
        createBusinessLoading: (state) => {
            state.isLoading = true;
        },
        createBusinessSuccess: (state, action: PayloadAction<Action>) => {
            state.isLoading = false;
            state.business = action.payload;
        },
        createBusinessFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
        createBusinessCatLoading: (state) => {
            state.isLoadingCat = true;
        },
        createBusinessCatSuccess: (state, action: PayloadAction<Action>) => {
            state.isLoadingCat = false;
            state.businessCat = action.payload;
        },
        createBusinessCatFail: (state, action: PayloadAction<string>) => {
            state.isLoadingCat = false;
            state.errorMessage = action.payload;
        },
        // editSourceLoading: (state) => {
        //     state.loading = true;
        // },
        // editSourceSuccess: (state, action: PayloadAction<Action>) => {
        //     state.loading = false;
        //     state.business = action.payload;
        // },
        // editSourceFail: (state, action: PayloadAction<string>) => {
        //     state.loading = false;
        //     state.errorMessage = action.payload;
        // },
        deleteBusinessLoading: (state) => {
            state.deleting = true;
        },
        deleteBusinessSuccess: (state, action: PayloadAction<Action>) => {
            state.deleting = false;
            state.business = action.payload;
        },
        deleteBusinessFail: (state, action: PayloadAction<string>) => {
            state.deleting = false;
            state.errorMessage = action.payload;
        },
        deleteBusinessCatLoading: (state) => {
            state.deleting = true;
        },
        deleteBusinessCatSuccess: (state, action: PayloadAction<Action>) => {
            state.deleting = false;
            state.businessCat = action.payload;
        },
        deleteBusinessCatFail: (state, action: PayloadAction<string>) => {
            state.deleting = false;
            state.errorMessage = action.payload;
        },
    }
});

const { actions, reducer } = businessSlice;
export const {
    getAllBusinessLoading,
    getAllBusinessSuccess,
    getAllBusinessFail,
    getAllBusinessCatLoading,
    getAllBusinessCatSuccess,
    getAllBusinessCatFail,
    createBusinessLoading,
    createBusinessSuccess,
    createBusinessFail,
    createBusinessCatLoading,
    createBusinessCatSuccess,
    createBusinessCatFail,
    // editSourceLoading,
    // editSourceSuccess,
    // editSourceFail,
    deleteBusinessLoading,
    deleteBusinessSuccess,
    deleteBusinessFail,
    deleteBusinessCatLoading,
    deleteBusinessCatSuccess,
    deleteBusinessCatFail
} = actions;

export const getBusiness =
    (
        page?: number,
        length?: number,
        search?: string,
        sourcing_team_status?: string,
        sourcing_team_code?: string,
        team_lead_user_id?: string,
    ): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(getAllBusinessLoading());
        try {
            const response = await API.get("/material_category_businesses/get", {
                params: {
                    page,
                    length,
                    search,
                    sourcing_team_status,
                    sourcing_team_code,
                    team_lead_user_id,
                },
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(getAllBusinessSuccess(response.data));
            }
        } catch (error) {
            dispatch(getAllBusinessFail());
        }
    };

    export const getBusinessCat =
    (
        page?: number,
        length?: number,
        search?: string,
        sourcing_team_status?: string,
        sourcing_team_code?: string,
        team_lead_user_id?: string,
    ): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(getAllBusinessCatLoading());
        try {
            const response = await API.get("/business_categories/get", {
                params: {
                    page,
                    length,
                    search,
                    sourcing_team_status,
                    sourcing_team_code,
                    team_lead_user_id,
                },
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(getAllBusinessCatSuccess(response.data));
            }
        } catch (error) {
            dispatch(getAllBusinessCatFail());
        }
    };

export const createBusiness =
    (data: any, cb?: () => void): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(createBusinessLoading());
        try {
            const response = await API.post(`/material_category_businesses/create`, data, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(createBusinessSuccess(response.data.message));
                toastr.success("", response.data.message);
                cb && cb();
            }
        } catch (error) {
           
            if (error.response) {
                dispatch(createBusinessFail(error.response.data?.message));
                toastr.error(error.response.data.message);
              }
              else{
                dispatch(createBusinessFail("No network connecton"));
                toastr.error("No network connecton");
              }
            setTimeout(() => {
                dispatch(createBusinessFail(""));
            }, 3000);
        }
    };

    export const createBusinessCat =
    (data: any, cb?: () => void): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(createBusinessCatLoading());
        try {
            const response = await API.post(`/business_categories/create`, data, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(createBusinessCatSuccess(response.data.message));
                toastr.success("", response.data.message);
                cb && cb();
            }
        } catch (error) {
            
            if (error.response) {
                dispatch(createBusinessCatFail(error.response.data?.message));
                toastr.error(error.response.data.message);
              }
              else{
                dispatch(createBusinessCatFail("No network connecton"));
                toastr.error("No network connecton");
              }
            setTimeout(() => {
                dispatch(createBusinessFail(""));
            }, 3000);
        }
    };

// export const editSource =
//     (data: any, cb?: () => void): AppThunk =>
//     async (dispatch: AppDispatch, getState) => {
//         const { userToken } = getState().user;
//         dispatch(editSourceLoading());
//         try {
//             const response = await API.post(`/sourcing_business/edit`, data, {
//                 headers: {
//                     Authorization: `Bearer ${userToken}`
//                 }
//             });
//             if ([200, 201].includes(response.status)) {
//                 dispatch(editSourceSuccess(response.data.message));
//                 toastr.success("", response.data.message);
//                 cb && cb();
//             }
//         } catch (error) {
//             dispatch(editSourceFail(error.response.data.message));
//             toastr.error(error.response.data.message);
//             setTimeout(() => {
//                 dispatch(editSourceFail(""));
//             }, 3000);
//         }
//     };

  

    export const deleteBusiness =
    (data?: any, cb?: () => void): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(deleteBusinessLoading());
        try {
            const response = await API.post(`/material_category_businesses/delete`, data, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(deleteBusinessSuccess(response.data.message));
                toastr.success("", response.data.message);
                cb && cb();
            }
        } catch (error) {
            dispatch(deleteBusinessFail(error.response.data.message));
            toastr.error(error.response.data.message);
            setTimeout(() => {
                dispatch(deleteBusinessFail(""));
            }, 3000);
        }
    };

    export const deleteBusinessCat =
    (data?: any, cb?: () => void): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(deleteBusinessLoading());
        try {
            const response = await API.post(`/business_categories/delete`, data, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(deleteBusinessCatSuccess(response.data.message));
                toastr.success("", response.data.message);
                cb && cb();
            }
        } catch (error) {
            dispatch(deleteBusinessCatFail(error.response.data.message));
            toastr.error(error.response.data.message);
            setTimeout(() => {
                dispatch(deleteBusinessCatFail(""));
            }, 3000);
        }
    };


export default reducer;
