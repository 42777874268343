/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import StatsCard from "components/StatsCard";
import membersGreen from "assests/icons/buyer/members-green.svg";
import membersBlue from "assests/icons/buyer/members-blue.svg";
import { AppDispatch, RootState } from "store/store";
import mail1 from "assests/icons/buyer/mail-1.svg";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { tableCol } from "./data";
import "./styles.scss";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import useSearch from "hooks/useSearch";
import { useEffect } from "react";
import SearchIcon from "../../../../components/SearchBar";
import { getChart, getEachRfp, getOneRfp } from "store/slice/BuyerSlice/EvaluationSlice/EvaluationSlice";
import Pagination from "components/Pagination";
import SelectDropdown from "components/SelectDropdown";
import { Bar } from "react-chartjs-2";
import CreateDoc from "components/ModalBuyer/CreateDoc";
import VisibilityIcon from "@mui/icons-material/Visibility";

const Evaluation_3 = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  // const { idEach } = useParams<{ idEach?: any }>();
  const [companyList, setCompanyList] = useState<string[]>([]);
  const [companyScore, setCompanyScore] = useState<number[]>([]);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const idEach = urlParams.get("id");
  const [modal, setModal] = useState<boolean>(false);
  const [isUpload, setIsUpload] = useState(false);
  const closeModal = () => {
    setModal((prev) => !prev);
  };
  const { eachRfp, eachLoading, oneRfp, chartTechnical } = useSelector((state: RootState) => state.rfpData);
  console.log(chartTechnical, "chartTechnical");
  useEffect(() => {
    const filt =
      chartTechnical &&
      chartTechnical?.data
        ?.filter((item) => {
          return item.rfp_vendor_status !== "-2" && item.rfp_vendor_status !== "3";
        })
        ?.filter((val, i) => {
          return i <= 4;
        });

    if (filt) {
      // if (seen) {
      const testArray: string[] = [];
      const testScore: number[] = [];
      filt.forEach((item) => testArray.push(item.vendor_description.split(" ")[0]));
      filt.forEach((item) => testScore.push(item.total_vendor_evaluated_score));
      setCompanyList([...testArray]);
      setCompanyScore([...testScore]);
      //   setSeen(false);
      // }
    }
  }, [chartTechnical]);

  const options = [
    {
      value: "technical",
      optionName: "Technical Requirements"
    },
    {
      value: "commercial",
      optionName: "Commercial Requirements"
    }
  ];

  //Redux
  const dispatch = useDispatch<AppDispatch>();

  const { search, inputRef, handleChange, searched } = useSearch(getEachRfp, perPage, idEach);

  //Show Number for PerPage Request
  const handleNewPage = (pageNo: number, page: number = 1) => {
    setPerPage(pageNo);
    setPage(page);
    dispatch(getEachRfp({ page, length: pageNo, id: idEach, category: "Technical" }));
  };
  const history = useHistory();

  // chart data

  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback((page: number, perPage: number) => {
    setPage(page);
    dispatch(getEachRfp({ page, length: perPage, id: idEach, category: "Technical" }));
  }, []);

  const paginate = React.useCallback((pageNo: number, perPage: number) => {
    setPage(pageNo);
    dispatch(getEachRfp({ page, length: perPage, id: idEach, category: "Technical" }));
  }, []);

  // barchart data
  // const TechnicalCapacityData = {
  //   labels: companyList && companyList,
  //   datasets: [
  //     {
  //       label: "Technical Evaluation",
  //       backgroundColor: "#A6CEE3",
  //       data: companyScore && companyScore
  //     }
  //   ]
  // };
  console.log(companyScore, "companyScore");
  const [TechnicalCapacityData, setTechnicalCapacityData] = useState({
    labels: [],
    datasets: [
      {
        label: "",
        backgroundColor: "",
        data: []
      }
    ]
  });
  console.log(TechnicalCapacityData, "TechnicalCapacityData");
  useEffect(() => {
    setTechnicalCapacityData({
      labels: companyList && companyList,
      datasets: [
        {
          label: "Technical Evaluation [Top Five]",
          backgroundColor: "#A6CEE3",
          data: companyScore && companyScore
        }
      ]
    });
  }, [companyScore]);
  //   setpage to the first page when a searched executed
  useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  useEffect(() => {
    dispatch(getEachRfp({ page, length: perPage, id: idEach, category: "Technical" }));
    dispatch(getOneRfp({ id: idEach }));
    dispatch(getChart({ id: idEach }));
  }, []);

  const review = (e, item) => {
    window.location.href = `/buyer/rfx-evaluation/evaluation-4/?id=${item.rfp_id}&vendor_id=${item.vendor_id}&vendor_name=${item.vendor_name}&quote=${item.quote_id}&category=Technical&status=${
      oneRfp?.data?.[0] && oneRfp?.data?.[0]?.rfp_status
    }&rfp_vendor_status=${item.rfp_vendor_status}&publish_status=${item.publish_status}`;
  };

  const rfpList =
    eachRfp &&
    eachRfp?.data?.map((item) => {
      return {
        // review: () => review(item.rfp_id, item.vendor_id),
        rfp_id: item.rfp_id,
        vendor_id: item.vendor_id,
        date: item.created_at,
        rfp_vendor_status: item.rfp_vendor_status,
        publish_status: item.evaluation_status,
        quote_id: item.submission_id && `QT - ${item.submission_id.id}`,
        vendor_name: item.vendor_description,
        category: "Technical"
      };
    });
  const [def, setDef] = useState("");
  return (
    <div id="evaluation-3">
      {modal && <CreateDoc rfp_id={idEach} closeModal={closeModal} setIsUpload={setIsUpload} />}

      <div className="container">
        <h3 className="header">
          Sourcing Project: {oneRfp?.data?.[0] && oneRfp?.data?.[0]?.requisition_description} TENDER FOR {oneRfp?.data?.[0] && oneRfp?.data?.[0]?.sourcing_team_description}
        </h3>

        <div className="stats-card mt-4 row">
          <StatsCard text="Invited Vendors" classes="stats-icon-blue" image={membersBlue} statsNumber={oneRfp?.data?.[0] && oneRfp?.data?.[0]?.summary?.total_invited_vendors} />
          <StatsCard text="Submitted Quotes" classes="stats-icon-orange" image={mail1} statsNumber={oneRfp?.data?.[0] && oneRfp?.data?.[0]?.summary?.total_responses} />
          {/* <StatsCard text="Selection Duration" classes="stats-icon-purple" image={purpleClock} statsNumber={oneRfp?.data?.[0] && oneRfp?.data?.[0]?.summary?.total_invited_vendors} />
           */}
          <StatsCard text="Qualified Vendors" classes="stats-icon-green" image={membersGreen} statsNumber={oneRfp?.data?.[0] && oneRfp?.data?.[0]?.summary?.total_qualified_vendors} />
          {/* <StatsCard image={AddIcon} classes="middle-img" centerText="Upload Document" onClick={closeModal} /> */}
          {oneRfp?.data?.[0] ? (
            oneRfp?.data?.[0]?.sourcing_report_document_url ? (
              <StatsCard
                image={<VisibilityIcon color="primary" />}
                isImg={true}
                classes="middle-img"
                centerText="View Document"
                onClick={() => {
                  window.open(oneRfp?.data?.[0]?.sourcing_report_document_url);
                }}
              />
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
        <div className="charts">
          <div className="chart">
            <Bar
              data={TechnicalCapacityData}
              width={100}
              height={50}
              options={{
                title: {
                  display: true,
                  text: "Technical Capacity",
                  fontSize: 40
                },
                maintainAspectRatio: false,
                legend: {
                  display: true,
                  position: "right"
                }
              }}
            />
          </div>
        </div>
        <div className="evalaution mt-5">
          <h3 className="title font-weight-bold">Supplier Quotes</h3>
          <div className="search-field mt-3">
            <SearchIcon placeholder="Search" boxClasses="" textRef={inputRef} value={search} onChange={handleChange} />
            <Dropdown perPage={10} action={(num) => setPerPage(num)} />
          </div>
          <div className="px-3 d-flex row mt-4 justify-content-between  align-items-center">
            {oneRfp?.data?.[0] && (
              <SelectDropdown
                additionalClass="additionalClass"
                name="Category"
                label="Category"
                placeholder="Category"
                options={options}
                defaultValue={def}
                onChange={(e) => {
                  if (e.target.value === "commercial") {
                    if (oneRfp?.data?.[0]?.rfp_evaluation_status) {
                      window.location.href = `/buyer/rfx-evaluation/evaluation/commercial?id=${idEach}&rfpType=${"rfp"}`;
                    } else {
                      toastr.error("Complete technical evaluation");
                      setDef("");
                    }
                  } else {
                    window.location.href = `/buyer/rfx-evaluation/evaluation/technical?id=${idEach}&rfpType=${"rfp"}`;
                  }
                }}
              />
            )}

            <div className="upload">
              {/* <button type="button" id="upload">
                <img src={exportIcon} alt="alt" className="pr-3" />{" "}
              </button> */}
              {/* {oneRfp?.data?.[0] ? (
                oneRfp?.data?.[0]?.sourcing_report_document_url ? (
                  <a target="_blank" style={{ padding: "1.0rem 1.7rem" }} id="upload" href={`${oneRfp?.data?.[0] && oneRfp?.data?.[0]?.sourcing_report_document_url}`}>
                    {" "}
                    <img src={exportIcon} alt="alt" className="pr-3" /> View Document
                  </a>
                ) : (
                  ""
                )
              ) : (
                ""
              )} */}
              {/* <a
                target="_blank"
                style={{ padding: "1.0rem 1.7rem" }}
                id="upload"
                href={`${oneRfp?.data?.[0] ? (oneRfp?.data?.[0]?.sourcing_report_document_url ? oneRfp?.data?.[0]?.sourcing_report_document_url : "#") : "#"}`}
              >
                <img src={exportIcon} alt="alt" className="pr-3" /> View Document
              </a> */}
            </div>
          </div>

          <div className="mt-3">
            <DataTable columns={tableCol} data={rfpList} loading={eachLoading} onClick={review} />
          </div>
          {eachRfp?.count > 10 ? (
            <Pagination page={page} lastPage={eachRfp?.pagination?.lastPage} paginate={paginate} total={eachRfp?.pagination?.total} handlePageChange={handlePageChange} perPage={perPage} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Evaluation_3;
