import React, { useEffect, useState } from "react";
import StatsCard from "components/StatsCard";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import MembersBlue from "assests/icons/buyer/members-blue.svg";
import filterIcon from "assests/icons/buyer/filter-icon.svg";
import "../styles.scss";
import SearchIcon from "components/SearchBar";
import Dropdown from "components/Dropdown";
import useSearch from "hooks/useSearch";
import { deleteBudgetHeader, getAllBudgetHeaders, getSummary } from "store/slice/BuyerSlice/Budget/BudgetSlice";
import { proposalCol2 } from "../data";
import DataTable from "components/DataTable";
import Pagination from "components/Pagination";
import Textbox from "components/Textbox";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import FilterCard from "components/FilterCard";
import SelectDropdown from "components/SelectDropdown";
import { getVendors } from "store/slice/BuyerSlice/VendorSlice/VendorSlice";
import { getBankProposal } from "store/slice/BuyerSlice/PostedInvoiceSlice/PostedInvoiceSlice";
import { formatCurrency, moneyFormatter } from "utils/utils";

const BankProposal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  const { bankProposal, loading } = useSelector((state: RootState) => state.postedInvoice);
  const { summary } = useSelector((state: RootState) => state.budget);
  const [page, setPage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [showFilter, setShowFilter] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [modalText, setModalText] = useState("");

  const { search, inputRef, handleChange } = useSearch(getBankProposal, perpage);

  const { register, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      start_date: "",
      end_date: "",
      from: "",
      to: ""
    }
  });

  const onSubmit = (vals) => {
    dispatch(getBankProposal(page, perpage, search, vals.start_date, vals.end_date));
  };

  const handleClear = () => {
    dispatch(getBankProposal());
    reset();
  };

  const handleNewPage = React.useCallback(
    (pageNo: number, page = 1) => {
      setPerpage(pageNo);
      setPage(1);
      dispatch(getBankProposal(page, pageNo, search));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, length: number) => {
      setPage(pageNo);
      dispatch(getBankProposal(pageNo, length, search));
    },
    [search]
  );

  const handlePageChange = React.useCallback(
    (page: number, length: number) => {
      setPage(page);
      dispatch(getBankProposal(page, length, search));
    },
    [search]
  );

  // function getItemStatus(status) {
  //   if (status === "-2") return "Draft";
  //   if (status === "-1") return "Pending";
  //   if (status === "1") return "Approved";
  //   if (status === "2") return "Paid";
  //   if (status === "3") return "Parked";
  // }
  const bankProp =
    bankProposal &&
    bankProposal?.data?.map((item) => {
      return {
        id: item.id,
        run_id: item.proposal_code,
        run_date: item.run_date,
        company_description: item.company_description,
        vendor_number: item.total_vendors,
        amount: formatCurrency(item.total_amount),
        currency: item.budget_ledger_balance,
        status: item.bank_proposal_status
      };
    });
  console.log(bankProp);
  useEffect(() => {
    dispatch(getBankProposal());
  }, []);

  useEffect(() => {
    if (!modal) setModalData({});
  }, [modal]);

  const handleView = (e, item) => {
    console.log(item);
    history.push(`/buyer/treasury/bank-proposal/each-bank-proposal?id=${item.id}&statusProp=${item.status}`);
  };

  return (
    <div id="budget">
      <div className="container">
        <div className="stats-card mt-4 row">
          <StatsCard text="Bank Proposal" classes="stats-icon-blue" image={MembersBlue} statsNumber={bankProposal?.count || 0} />
          {/* <StatsCard text="Pending Approval" classes="stats-icon-blue" image={MembersBlue} statsNumber={bankProposal?.count || 0} /> */}
        </div>
        {showFilter && (
          <FilterCard handleSubmit={handleSubmit(onSubmit)} clear={handleClear}>
            <div className="row">
              <div className="col col-4">
                <label style={{ display: "block" }}>Start Date</label>
                <input
                  type="date"
                  name="start_date"
                  className="mt-3"
                  placeholder="Start Date"
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  ref={register({ required: false })}
                />
              </div>
              <div className="col col-4">
                <label style={{ display: "block" }}>End Date</label>
                <input
                  type="date"
                  name="end_date"
                  className="mt-3"
                  placeholder="End Date"
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  ref={register({ required: false })}
                />
              </div>
            </div>
            <div
              className="row"
              style={{
                marginTop: "2rem"
              }}
            >
              <div className="col col-4">
                <label style={{ display: "block" }}>Budget Header Status</label>
                <select
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  name="budget_header_status"
                  ref={register({
                    required: false
                  })}
                >
                  <option value="">Select</option>
                  <option value="-2">Draft</option>
                  <option value="-1">Pending Approval</option>
                  <option value="1">Approved</option>
                </select>
              </div>
            </div>
          </FilterCard>
        )}
        <div className="document mt-5">
          <div className="search-field">
            <SearchIcon placeholder="Search" boxClasses="mb-3" textRef={inputRef} value={search} onChange={handleChange} />
            <Dropdown perPage={perpage} action={handleNewPage} />
            {/* <div>
              <button
                style={{
                  padding: "5px 10px",
                  borderRadius: "10px",
                  background: "#FFFFFF",
                  color: "#737A91",
                  border: "1px solid #DFE2E6",
                  fontSize: "18px",
                  marginLeft: "1rem",
                  width: "max-content"
                }}
                onClick={() => setShowFilter(!showFilter)}
              >
                <img src={filterIcon} alt="icon" className="icon-image" />
                <span
                  style={{
                    paddingLeft: "1rem"
                  }}
                >
                  {!showFilter ? "Filters" : "Hide Filters"}
                </span>
              </button>
            </div> */}
          </div>
          <div className="mt-3">
            <DataTable columns={proposalCol2} data={bankProp} onClick={handleView} loading={loading} />
            <Pagination page={page} lastPage={bankProposal?.pagination?.lastPage} paginate={paginate} total={bankProposal?.pagination?.total} handlePageChange={handlePageChange} perPage={perpage} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankProposal;
