import React from "react";
import styled from "styled-components";
import { ReactComponent as CloseIcon } from "assests/icons/close.svg";
import { ModalPane, ModalContainer, ModalTop, ModalBottom, ModalContent } from "components/Modal/ModalFull";
import TabSmall from "components/TabSmall";
import Catalogue from "pages/BuyerDashboard/Requisition/Catalogue";
import NonCatalogue from "pages/BuyerDashboard/Requisition/NonCatalogue";
import Service from "pages/BuyerDashboard/Requisition/Service";

const Flex = styled.div`
  display: flex;
  width: 100%;

  p {
    width: 100%;
    text-align: center;
    color: #000000;
    font-weight: 600;
  }
`;

const Center = styled.div`
  display: block;
  width: 100%;
  text-align: center;
`;

const AddItemModal = ({ show, handleClose, title, backdropClose = true }) => {
  const menus = [
    {
      name: "Catalog Item",
      id: "cat"
    },
    {
      name: "Non Catalog Item",
      id: "noncat"
    },
    {
      name: "Service Item",
      id: "service"
    }
  ];

  const [menu, setMenu] = React.useState(menus[0].id);

  const selectMenu = (name) => {
    setMenu(name);
  };

  return (
    <ModalPane
      className="modal-backdrop"
      show={show}
      onClick={(e) => {
        if (backdropClose && e.target.classList.contains("modal-backdrop")) {
          handleClose();
        }
      }}
    >
      <ModalContainer>
        <ModalTop showBorderBottom="true">
          <Flex>
            <p className="medium-font">{title}</p>
          </Flex>
          <>
            {" "}
            <div className="closebtn">
              <CloseIcon onClick={() => handleClose(true)} className="cursor-pointer" />
            </div>
          </>
        </ModalTop>
        <ModalContent style={{ maxHeight: "85%" }}>
          <div className="tab-container">
            <TabSmall menus={menus} onSelect={selectMenu} />

            <div className="tabs-page pb-5">
              {menu === "cat" && <Catalogue />}
              {menu === "noncat" && <NonCatalogue />}
              {menu === "service" && <Service />}
            </div>
          </div>
        </ModalContent>
        <ModalBottom>
          <Center>
            <button
              className="btn-primary"
              style={{
                padding: "5px 15px",
                minWidth: "5rem",
                borderRadius: "10px",
                margin: "0 auto"
              }}
              onClick={handleClose}
            >
              Close
            </button>
          </Center>
        </ModalBottom>
      </ModalContainer>
    </ModalPane>
  );
};

export default AddItemModal;
