/* eslint-disable react-hooks/exhaustive-deps */
import StatsCard from "components/StatsCard";
import React, { useEffect, useState } from "react";
import "../styles.scss";
import LocationIcon from "assests/icons/buyer/group-box.svg";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import SearchIcon from "components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { taxColumn } from "../data";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import useSearch from "hooks/useSearch";
import Pagination from "components/Pagination";
import { toastPopUp } from "utils/utils";
import { deleteTax, getTax } from "store/slice/BuyerSlice/TaxSlice/TaxSlice";
import { kFormatter } from "utils/utils";
import CreateOrEditTax from "components/ModalBuyer/CreateOrEditTax";

const TaxCode = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [modal, setModal] = useState<boolean>(false);
  const [singleTax, setSingleTax] = useState<any>(null);

  const closeModal = () => {
    setModal((prev) => !prev);
    setSingleTax(null);
  };

  //Redux
  const dispatch = useDispatch<AppDispatch>();
  const { tax, loading } = useSelector((state: RootState) => state.tax);

  //Hook
  const { search, inputRef, handleChange, searched } = useSearch(getTax, perPage);

  // get material categories
  useEffect(() => {
    dispatch(getTax(page, perPage));
  }, []);

  useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  //Show Number for PerPage Request
  const handleNewPage = (pageNo: number, page: number = 1) => {
    setPerPage(pageNo);
    setPage(1);
    dispatch(getTax(page, pageNo, search));
  };

  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, perPage: number) => {
      setPage(page);
      dispatch(getTax(page, perPage, search));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, perPage: number) => {
      setPage(pageNo);
      dispatch(getTax(pageNo, perPage, search));
    },
    [search]
  );

  //Edit DepartmentData
  const editElement = (id: string, tax_code_description: string, tax_code: string, tax_amount, company_id) => {
    setSingleTax({ id, tax_code_description, tax_code, tax_amount, company_id });
    setModal((prev) => !prev);
  };

  //  delete
  const deleteTax2 = (name: string, id: string) => {
    const payload = { tax_code_id: id };

    toastPopUp(
      () => {
        dispatch(
          deleteTax(payload, () => {
            dispatch(getTax(page, perPage));
          })
        );
      },
      "Delete",
      "Cancel",
      "Delete the entity",
      name,
      "red"
    );
  };

  const taxList =
    tax &&
    tax?.data?.map((item) => {
      return {
        id: item.id,
        tax_code_description: item.tax_code_description,
        tax_code: item.tax_code,
        cost_element_number: item.tax_code,
        tax_amount: kFormatter(item.tax_amount, ""),
        company: item.company_description,

        edit: () => editElement(item.id, item.tax_code_description, item.tax_code, item.tax_amount, item.company_id),
        deleteTaxData: () => deleteTax2(item.tax_code_description, item.id)
      };
    });

  return (
    <div id="settings-material-data">
      {modal && <CreateOrEditTax closeModal={closeModal} singleTax={singleTax} />}
      <div className="container mt-3">
        <div className="row mt-3 mb-5">
          <StatsCard statsNumber={tax?.count ?? 0} text="Tax Codes" classes="stats-icon" image={LocationIcon} iconBackgroundColor="rgba(45, 156, 219, 0.1)" />
          <StatsCard image={AddIcon} classes="middle-img" centerText="Create" onClick={closeModal} />
        </div>

        <div className="d-flex  mb-3">
          <SearchIcon placeholder="Search" boxClasses="" textRef={inputRef} value={search} onChange={handleChange} />
          <Dropdown perPage={perPage} action={handleNewPage} />
        </div>
        <DataTable columns={taxColumn} data={taxList} loading={loading} />

        <Pagination page={page} lastPage={tax?.pagination?.pageCount} paginate={paginate} total={tax?.pagination?.total} handlePageChange={handlePageChange} perPage={perPage} />
      </div>
    </div>
  );
};

export default TaxCode;
