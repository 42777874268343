export const tableCol = [
    {
        name: "First Name",
        index: "first_name",
        id: 1
    },
    {
        name: "Last Name",
        index: "last_name",
        id: 2
    },
    {
        name: "Phone Number",
        index: "phone_no",
        id: 3
    },
    {
        name: "Email",
        index: "email",
        id: 4
    },
    {
        name: "Role",
        index: "role",
        id: 5
    },
    {
        name: "Action",
        index: "action",
        id: 6
    },
];

export const tableData = [
    {
        first_name: "Regina",
        last_name: "Robertson",
        phone_no: "0803-371-0912",
        email: "jessica.hanson@gmail.com",
        role: "Admin",
        action: "",
        id: 1
    },
    {
        first_name: "Regina",
        last_name: "Robertson",
        phone_no: "0803-371-0912",
        email: "jessica.hanson@gmail.com",
        role: "Officer",
        action: "",  
        id: 1
    },
    {
        first_name: "Regina",
        last_name: "Robertson",
        phone_no: "0803-371-0912",
        email: "jessica.hanson@gmail.com",
        role: "Officer",
        action: "",
        id: 1
    },
    {
        first_name: "Regina",
        last_name: "Robertson",
        phone_no: "0803-371-0912",
        email: "jessica.hanson@gmail.com",
        role: "Admin",
        action: "",
        id: 1
    },
    {
        first_name: "Regina",
        last_name: "Robertson",
        phone_no: "0803-371-0912",
        email: "jessica.hanson@gmail.com",
        role: "Officer",
        action: "",
        id: 1
    },
    {
        first_name: "Regina",
        last_name: "Robertson",
        phone_no: "0803-371-0912",
        email: "jessica.hanson@gmail.com",
        role: "Admin",
        action: "",
        id: 1
    },
    {
        first_name: "Regina",
        last_name: "Robertson",
        phone_no: "0803-371-0912",
        email: "jessica.hanson@gmail.com",
        role: "Officer",
        action: "",
        id: 1
    },
]
