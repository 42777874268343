import React, { useEffect } from "react";
import "./styles.scss";
import Cash from "assests/icons/hand.svg";
import Contracts from "assests/icons/order.svg";
import Truck from "assests/icons/truck2.svg";
import Invoice from "assests/icons/bill.svg";
import { getdashboardSummary } from "store/slice/DashboardSlice/DashboardSlice";
import StatsCard from "components/StatsCard";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import SearchIcon from "components/SearchBar";
import useSearch from "hooks/useSearch";
// import { getTopFarmers } from "store/slice/TransactionSlice/TransactionSlice";
import { documentColumn, documentsData, actionColumn, actionsData } from "./data";
import DataTable from "components/DataTable";
import Star from "components/Star";
import { getDocuments, setPurchaseOrder } from "store/slice/BuyerSlice/DocumentSlice/DocumentSlice";
import { getPOSummaries, getPOSummariesParam } from "store/slice/SummarySlice/SummarySlice";
import Pagination from "components/Pagination";
import { removeZero, toastPopUp } from "utils/utils";
import { getInvoiceSummary } from "store/slice/BuyerSlice/Invoice/InvoiceSlice";
import { useHistory } from "react-router";
import axios from "axios";
import { getUserNotification } from "store/slice/NotificationSlice/NotificationSlice";
type Props = {};

declare global {
  interface Window {
    OneSignal: any;
  }
}

const Dashboard: React.FunctionComponent<Props> = () => {
  // OneSignal
  // States
  const [page, setPage] = React.useState<number>(1);
  const [length, setlength] = React.useState<number>(5);
  const [perpage, setPerpage] = React.useState<number>(10);
  const [outDel, setOutdel] = React.useState("0");

  //Redux
  const dispatch = useDispatch();
  const { dashboardSummary, isLoading } = useSelector((state: RootState) => state.dashboard);
  const { POSummaries } = useSelector((state: RootState) => state.summaries) as any;
  const { summary } = useSelector((state: RootState) => state.invoice) as any;
  const { user } = useSelector((state: RootState) => state.user);
  const { documents } = useSelector((state: RootState) => state.document);
  const history = useHistory();
  console.log(user);
  //Hook
  const { search, inputRef, handleChange } = useSearch(getDocuments, length);

  useEffect(() => {
    dispatch(getdashboardSummary());
    dispatch(getDocuments(page, length));
    dispatch(getPOSummaries());
    dispatch(
      getPOSummariesParam(undefined, undefined, "3", (data) => {
        setOutdel(data?.overall?.total_purchase_orders);
      })
    );
    dispatch(getInvoiceSummary(user?.vendor_id));
  }, []);

  window.OneSignal = window.OneSignal || [];
  const OneSignal = window.OneSignal;
  const [granted, setGranted] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);

  useEffect(() => {
    setLoaded(true);
    OneSignal.push([
      "init",
      {
        appId: "99a20fc9-e619-4f62-a8aa-369f79ced2b1"
        // Your other init settings
      }
    ]);
  }, []);

  console.log(OneSignal);

  useEffect(() => {
    /**
     * YOUR ONE SIGNAL CONFIGURATION GOES HERE
     */
    if (loaded) {
      OneSignal.push(function () {
        OneSignal.isPushNotificationsEnabled(function (isEnabled) {
          // console.log(isEnabled);

          if (isEnabled) {
            setGranted(true);
            OneSignal.getUserId().then(function (userId) {
              // console.log("OneSignal User ID:", userId);
              axios.post(
                "https://api.yaraa.stakle.com/users/create_push_subscription",
                { fcm_id: userId },
                {
                  headers: {
                    Authorization: `Bearer ${user?.token}`
                  }
                }
              );
              // (Output) OneSignal User ID: 270a35cd-4dda-4b3f-b04e-41d7463a2316
            });

            OneSignal.getExternalUserId().then(function (externalUserId) {
              console.log("externalUserId: ", externalUserId);
              if (externalUserId === null) {
                let externalUserId = user?.email; // You will supply the external user id to the OneSignal SDK

                OneSignal.push(function () {
                  console.log("setExternal");
                  OneSignal.setExternalUserId(externalUserId);
                });
              }
            });
          }
        });
        OneSignal.push(function () {
          OneSignal.on("notificationDisplay", function (event) {
            console.log("event");
            console.log(event);
            dispatch(getUserNotification(1, 10));
            // Call a method on my functional component
          });

          //This event can be listened to via the `on()` or `once()` listener
        });
      });
    }
  }, [loaded, OneSignal, user]);
  // console.log(granted, "granted");
  // console.log("check");

  // console.log(user, "user");

  // console.log(summary, "summary");

  const data = documents?.data;

  const setPurchase = (id: string, stat: string, description: string) => {
    // if (!permissions?.includes("USER_DEACTIVATE")) {
    //   toastr.error("", "You don't have the Admin rights to do this");
    //   return;
    // }
    const payload = {
      purchase_order_id: id,
      purchase_order_status: stat,
      status_description: description
    };
    toastPopUp(
      () => {
        dispatch(
          setPurchaseOrder(payload, () => {
            dispatch(getDocuments(page, length));
          })
        );
      },
      "Confirm",
      "Cancel",
      description,
      "",
      "#737a91"
    );
  };

  const tableData1 = documents?.data?.map((item) => {
    let statsNumber;
    if (item?.purchase_order_status === "-2") {
      statsNumber = "In Draft";
    }
    if (item?.purchase_order_status === "-1") {
      statsNumber = "Pending";
    }
    if (item?.purchase_order_status === "1") {
      statsNumber = "Approved";
    }
    if (item?.purchase_order_status === "2") {
      statsNumber = "Accepted";
    }
    if (item?.purchase_order_status === "3") {
      statsNumber = "Out for Delivery";
    }
    if (item?.purchase_order_status === "4") {
      statsNumber = "Partially Delivered";
    }
    if (item?.purchase_order_status === "5") {
      statsNumber = "Delivered";
    }
    // if (item?.purchase_order_status === "6") {
    //   statsNumber = "Closed";
    // }
    return {
      doc_no: item?.purchase_order_code,
      title: `PO - ${item?.purchase_order_code}`,
      date: item.purchase_order_date,
      status: statsNumber,
      stat: item.purchase_order_status
    };
  });

  const tableData2 = documents?.data?.map((item) => {
    let statsNumber;
    if (item?.purchase_order_status === "1") {
      statsNumber = "Pending Acceptance";
    }
    if (item?.purchase_order_status === "2") {
      statsNumber = "Accepted";
    }
    if (item?.purchase_order_status === "3") {
      statsNumber = "Out for Delivery";
    }
    if (item?.purchase_order_status === "4") {
      statsNumber = "Delivered";
    }
    if (item?.purchase_order_status === "5") {
      statsNumber = "Awaiting Payment";
    }
    if (item?.purchase_order_status === "6") {
      statsNumber = "Closed";
    }
    return {
      doc_no: item?.purchase_order_code,
      title: `PO - ${item?.purchase_order_code}`,
      date: item.purchase_order_date,
      to_do: statsNumber,
      purchase_order_status: item?.purchase_order_status,
      accept_po: () => setPurchase(item?.id, "2", "Vendor Accepted"),
      in_draft: () => setPurchase(item?.id, "-2", "In draft/creation mode"),
      pending_approval: () => setPurchase(item?.id, "-1", "Pending Approval"),
      approved: () => setPurchase(item?.id, "1", "Approved"),
      partially_delivered: () => setPurchase(item?.id, "4", "Partially Delivered"),
      delivered: () => setPurchase(item?.id, "5", "Delivered"),
      out_for_delivery: () => setPurchase(item?.id, "3", "Out For Delivery"),
      view: () => history.push(`/supplier/contracts/${item.id}`)
    };
  });

  // console.log(documents?.data);
  // console.log(POSummaries);

  //--------------------------------------------
  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, length: number) => {
      setPage(page);
      dispatch(getDocuments(page, length, search));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, length: number) => {
      setPage(pageNo);
      dispatch(getDocuments(pageNo, length));
    },
    [search]
  );

  //------------------------------------------------------------
  //Show Number for length Function
  //------------------------------------------------------------
  const handleNewPage = React.useCallback(
    (pageNo: number, page = 1) => {
      setlength(pageNo);
      setPage(1);
      dispatch(getDocuments(page, pageNo, search));
    },
    [search]
  );

  return (
    <div id="dashboard-page">
      <div className="container">
        <div className="quick-stats mb-5">
          <div className="d-flex align-items-center mb-3">
            {/* <h4 className="page-title mr-2 ">Stakle’s Ratings</h4> */}
            <h4 className="page-title mr-2 ">
              {user?.vendor_description} | {removeZero(user?.vendor_code)}
            </h4>
            <Star stars={user && user.overall_ratings} disabled={true} />
          </div>

          <div className="row mt-2">
            <StatsCard statsNumber={POSummaries?.overall?.total_purchase_orders} text="Purchase Orders" classes="stats-icon-orange" image={Contracts} />

            <StatsCard statsNumber={outDel || 0} text="Out For Delivery" classes="stats-icon-leaf" image={Truck} />

            <StatsCard statsNumber={summary?.overall?.total_invoices || 0} text="Invoice" classes="stats-icon-folder" image={Invoice} />

            <StatsCard statsNumber={POSummaries?.overall?.total_purchase_order_value} text="Total Amount" classes="stats-icon-cash" image={Cash} amount="" />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-lg-6">
            <h4 className="page-title mb-2">Recent Documents</h4>
            <div className="search-field">
              <SearchIcon placeholder="Search for PO ID, Description, name, or Invoice ID" boxClasses="mb-4 search-length" textRef={inputRef} value={search} onChange={handleChange} />
            </div>

            <DataTable columns={documentColumn} data={tableData1} loading={false} />
            <Pagination page={page} lastPage={documents?.pagination?.pageCount} paginate={paginate} total={documents?.pagination?.total} handlePageChange={handlePageChange} perPage={length} />
          </div>
          <div className="col-md-12 col-lg-6 mt-md-4 mt-lg-0 mt-4">
            <h4 className="page-title mb-2">Action Items</h4>
            <div className="search-field">
              <SearchIcon placeholder="Search for PO ID, Description, name, or Invoice ID" boxClasses="mb-4 search-length" textRef={inputRef} value={search} onChange={handleChange} />
            </div>
            <DataTable columns={actionColumn} data={tableData2} loading={false} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
