import { Link } from "react-router-dom";

import greenCheck from "assests/icons/buyer/green-check.svg";
import rectangle from "assests/icons/buyer/rectangle-buyer.svg";

import "./styles.scss";

export const tableCol = [
  {
    name: "Project ID",
    index: "project_id",
    id: 1
  },
  {
    name: "Requisition Code",
    index: "requisition_code",
    id: 10
  },
  {
    name: "Description",
    index: "description",
    id: 2
  },
  {
    name: "Team Lead",
    index: "team_lead",
    id: 20
  },
  {
    name: "Submitted Quotes",
    index: "Submitted_quotes",
    id: 3
  },
  {
    name: "Invited Vendors",
    index: "invited_vendors",
    id: 4
  },
  {
    name: "Status",
    id: 5,
    render: (item) => {
      if (item.rfp_status === "1") {
        return (
          <button
            style={{
              padding: "8px 16px",
              background: "rgba(0, 176, 7, 0.15)",
              borderRadius: "10px",
              color: "rgb(56, 124, 87)"
            }}
          >
            Approved
          </button>
        );
      }
      if (item.rfp_status === "-2") {
        return (
          <button
            style={{
              padding: "8px 16px",
              background: "rgba(245, 150, 37, 0.15)",
              borderRadius: "10px",
              color: "rgb(245, 150, 37)"
            }}
          >
            Draft
          </button>
        );
      }
      if (item.rfp_status === "-1") {
        return (
          <button
            style={{
              padding: "8px 16px",
              background: "rgba(245, 150, 37, 0.15)",
              borderRadius: "10px",
              color: "rgb(245, 150, 37)"
            }}
          >
            Pending
          </button>
        );
      }
      if (item.rfp_status === "4") {
        return (
          <button
            style={{
              padding: "8px 16px",
              background: "rgba(0, 176, 7, 0.15)",
              borderRadius: "10px",
              color: "rgb(56, 124, 87)"
            }}
          >
            {" "}
            Awarded
          </button>
        );
      }
      if (item.rfp_status === "5") {
        return (
          <button
            style={{
              padding: "8px 16px",
              background: "rgba(45, 156, 219, 0.185)",
              borderRadius: "10px",
              color: "#2f80ed"
            }}
          >
            In Negotiation
          </button>
        );
      }
      if (item.rfp_status === "3") {
        return (
          <button
            style={{
              padding: "8px 16px",
              background: "rgba(245, 150, 37, 0.15)",
              borderRadius: "10px",
              color: "rgb(245, 150, 37)"
            }}
          >
            Selection
          </button>
        );
      }
      // return (
      //   <button
      //     style={{
      //       color: "#FFF",
      //       background: `${item.status === "published" ? "#0ACC94" : "#737A91"}`,
      //       padding: "5px 15px",
      //       minWidth: "6rem",
      //       borderRadius: "10px"
      //     }}
      //   >
      //     {item.status}
      //   </button>
      // );
    }
  }
  // {
  //     name: "Action",
  //     id: 7,
  //     render: (item) => (

  //             <button
  //                 type="button"
  //                 onClick={item.status}
  //                 style={{
  //                     padding: "8px 16px",
  //                     background: "rgba(0, 70, 176, 0.15)",
  //                     borderRadius: "10px",
  //                     color: "rgba(47, 128, 237, 1)"
  //                 }}
  //             >
  //                 View
  //             </button>

  //     )
  // }
];

export const tableData = [
  {
    project_id: "2nd Sept. 2019",
    description: "Brent Fawaz",
    category: "78600090",
    invited_vendors: "123333",
    status: "View",
    id: 1
  },
  {
    project_id: "24th May 2019",
    description: "Chandler Ross",
    category: "78600091",
    invited_vendors: "123333",
    status: "View",
    id: 0
  }
];
