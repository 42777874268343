import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Action, rateStates } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";

const initialState: rateStates = {
  loading: false,
  loadingView: false,
  deleting: false,
  isLoading: false,
  rate: null,
  viewRate: null,
  successMessage: "",
  errorMessage: ""
};

const rateSlice = createSlice({
  name: "rate",
  initialState,
  reducers: {
    getRateLoading: (state) => {
      state.loading = true;
    },
    getRateSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.rate = action.payload;
    },
    getRateFail: (state) => {
      state.loading = false;
    },
    getViewRateLoading: (state) => {
      state.loadingView = true;
    },
    getViewRateSuccess: (state, action: PayloadAction<Action>) => {
      state.loadingView = false;
      state.viewRate = action.payload;
    },
    getViewRateFail: (state) => {
      state.loadingView = false;
    },
    createRateLoading: (state) => {
      state.isLoading = true;
    },
    createRateSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.rate = action.payload;
    },
    createRateFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    editRateLoading: (state) => {
      state.isLoading = true;
    },
    editRateSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.rate = action.payload;
    },
    editRateFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    }
  }
});

const { actions, reducer } = rateSlice;
export const {
  getRateLoading,
  getRateSuccess,
  getRateFail,
  createRateLoading,
  createRateSuccess,
  createRateFail,
  getViewRateFail,
  getViewRateLoading,
  getViewRateSuccess,
  editRateFail,
  editRateLoading,
  editRateSuccess
} = actions;

export const getRate =
  (page?: number, length?: number, search?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getRateLoading());
    try {
      const response = await API.get("/vendor_evaluations/get_overall", {
        params: {
          page,
          length,
          search
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getRateSuccess(response.data));
      }
    } catch (error) {
      dispatch(getRateFail());
    }
  };

type getViewSteps = {
  page?: number;
  length?: number;
  id?: any;
  search?: string;
};

export const getViewRate =
  ({ page, length, search, id }: getViewSteps): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getViewRateLoading());
    try {
      const response = await API.get("/vendor_evaluations/get", {
        params: {
          page,
          length,
          search,
          vendor_id: id
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getViewRateSuccess(response.data));
      }
    } catch (error) {
      dispatch(getViewRateFail());
    }
  };

export const createRate =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(createRateLoading());
    try {
      const response = await API.post(`/vendor_evaluations/create`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(createRateSuccess(response.data.message));
        toastr.success("", "Successful");
        cb && cb();
      }
    } catch (error) {
      if (error.response) {
        dispatch(createRateFail(error.response.data?.message));
        toastr.error(error.response.data.message);
      } else {
        dispatch(createRateFail("No network connecton"));
        toastr.error("No network connecton");
      }
      setTimeout(() => {
        dispatch(createRateFail(""));
      }, 3000);
    }
  };

export const editRate =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(editRateLoading());
    try {
      const response = await API.post(`/vendor_evaluations/edit`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(editRateSuccess(response.data.message));
        toastr.success("", "Successful");
        cb && cb();
      }
    } catch (error) {
      if (error.response) {
        dispatch(editRateFail(error.response.data?.message));
        toastr.error(error.response.data.message);
      } else {
        dispatch(editRateFail("No network connecton"));
        toastr.error("No network connecton");
      }
      setTimeout(() => {
        dispatch(editRateFail(""));
      }, 3000);
    }
  };

export default reducer;
