import React, { useState } from "react";
import Modal from "components/Modal";
import ArrowDropdown from "components/ArrowDropdown";
import Textbox from "components/Textbox";
import Button from "components/Button";
import useArrowDropdown from "hooks/useArrowDropdown";
import { useForm } from "react-hook-form";
// import { getRoles } from "store/slice/RoleSlice/RoleSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { createUser, editUser, getUsers } from "store/slice/UserMgtSlice/UserMgtSlice";
import { CreateUserPayload } from "store/slice/UserMgtSlice/interface";
import "./styles.scss";
import { getSingleUser } from "store/slice/UserMgtSlice/UserMgtSlice";
import SelectDropdown from "components/SelectDropdown";
import { getCompaniesEntityList } from "store/slice/CompaniesSlice/CompaniesSlice";
import { getRoles } from "store/slice/Roleslice/RoleSlice";
import { getDepartments } from "store/slice/BuyerSlice/DepartmentSlice/DepartmentSlice";
import { useEffect } from "react";

type Props = {
  closeModal: () => void;
  modalData: { userId: string; viewStatus: boolean; editStatus: boolean };
};
const CreateBuyerUser = ({ closeModal, modalData }: Props) => {
  const [error, setError] = React.useState("");
  const [allComp, setAllComp] = React.useState<boolean>(true);
  const { register, handleSubmit, errors, setValue, watch } = useForm<FormData>({
    mode: "onChange"
  });

  const values = watch();

  type FormData = {
    othernames: string;
    lastname: string;
    password: string;
    email: string;
    phone_number: number;
    company_id: string;
    user_type: string;
    department_id: number;
    user_role_id: number;
    certified: string;
  };

  const { userId, viewStatus, editStatus } = modalData;
  //Redux Store
  const dispatch = useDispatch();
  // const { roles } = useSelector((state: RootState) => state.roles);
  const { isLoading, singleUser, users } = useSelector((state: RootState) => state.userMgt);
  const { companies } = useSelector((state: RootState) => state.companies);
  const { roles } = useSelector((state: RootState) => state.roles);
  const { departments } = useSelector((state: RootState) => state.department);
  console.log(singleUser, "singleUser");
  //Get needed form data
  React.useEffect(() => {
    dispatch(getCompaniesEntityList(undefined, undefined, 1000));
    dispatch(getRoles(undefined, 1000, undefined, "company"));
    dispatch(getDepartments(undefined, 1000));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Get single user details

  React.useEffect(() => {
    if (viewStatus || editStatus) {
      dispatch(getSingleUser(userId));
    }
  }, [userId, viewStatus, editStatus]);

  //Update fields
  React.useEffect(() => {
    if (singleUser !== null && (editStatus || viewStatus)) {
      setValue("othernames", singleUser?.data[0]?.othernames);
      setValue("email", singleUser?.data[0]?.email);
      setValue("lastname", singleUser?.data[0]?.lastname);
      setValue("phone_number", singleUser?.data[0]?.phone_number);
      setValue("user_role_id", singleUser?.data[0].user_role_id);
      setValue("user_type", singleUser?.data[0].user_type);
      setValue("company_id", singleUser?.data[0].company_id);
      setValue("department_id", singleUser?.data[0].department_id);
    }
    if (singleUser !== null && (editStatus || viewStatus)) {
      if (!singleUser?.data[0].company_id) {
        setValue("certified", "yes");
        setAllComp(true);
      } else {
        setValue("company_id", singleUser?.data[0].company_id);
        setAllComp(false);
      }
    }
  }, [singleUser, editStatus, viewStatus]);
  // console.log(singleUser?.data);
  // console.log(singleUser?.data?.lastname);

  // const rolesList =
  //     roles &&
  //     roles?.myroles?.map((data: any) => {
  //         return {
  //             name: data.role_name,
  //             id: data._id
  //         };
  //     });

  //   const roleName = () => {
  //     let theRole;
  //     if (rolesList && (editStatus || viewStatus)) {
  //       rolesList.forEach((role) => {
  //         if (role.id === singleUser?.role) theRole = role.name;
  //       });
  //     }
  //     return theRole;
  //   };

  const { open, selectedOption, handleDropdownChange, handleAction, keyOption, ref } = useArrowDropdown();

  const validatePhoneNo = (value: any) => {
    let values = value.replace(/[^0-9]*/g, "");
    if (value.length > 11 || value !== values) return "Should be a phone number";
  };

  const onFormSubmit = (vals: FormData) => {
    if (isLoading) return;
    // if (selectedOption === "" && !editStatus) {
    //   setError("Please select a role");
    //   return; true ? "t" : data.company_id
    // }
    const { ...rest } = vals;
    const data: any = { ...rest };
    if (editStatus) {
      const payload = { ...data, id: userId, company_id: allComp ? "" : data.company_id, user_type: "company" };
      dispatch(
        editUser(payload, "", () => {
          closeModal();
          dispatch(getUsers(1, 10, "", "company"));
        })
      );
    } else {
      dispatch(
        createUser({ ...data, user_type: "company" }, () => {
          closeModal();
          dispatch(getUsers(1, 10, "", "company"));
        })
      );
    }
  };

  //Remove error message
  React.useEffect(() => {
    if (keyOption) {
      setError("");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyOption]);

  //Companies array
  const companiesArray =
    companies &&
    companies.map((element: { id: string; company_description: string }) => {
      return {
        value: element?.id,
        optionName: element?.company_description
      };
    });

  //departments array
  // const departmentArray = [] as any;

  // console.log(departments?.data, "departmentArray");
  //departments all comp array
  // const departmentArrayAll = [] as any;

  const [departmentArray, setDepartmentArray] = useState<any>([]);
  const [departmentArrayAll, setDepartmentArrayAll] = useState<any>([]);

  // useEffect(() => {
  //   departments &&
  //     departments?.data?.forEach((element: { id: string; company_id: string; department_description; string }) => {
  //       if (element.company_id === values.company_id) {
  //         if (element.company_id === values.company_id) {
  //           setDepartmentArray([{ value: element?.id, optionName: element?.department_description }]);
  //         }
  //       }
  //     });

  //   departments &&
  //     departments?.data?.forEach((element: { id: string; company_id: string; department_description; string }) => {
  //       if (element.company_id === values.company_id) {
  //         setDepartmentArrayAll([{ value: element?.id, optionName: element?.department_description }]);
  //       }
  //     });
  // }, [values.company_id]);
  // console.log(departmentArray, departmentArrayAll, "departmentArray");

  //Roles array
  const rolesArray =
    roles &&
    roles?.data.map((element: { id: string; user_role_name: string }) => {
      return {
        value: element?.id,
        optionName: element?.user_role_name
      };
    });

  console.log(departmentArrayAll, "departmentArrayAll");
  // console.log(roles?.data);

  //  user Types
  // const userTypes: Object[] = [
  //   { value: "Vendor", optionName: "Vendor" },
  //   { value: "Company", optionName: "Company" }
  // ];
  // Modal text
  let modalText = "Create User";
  if (editStatus) {
    modalText = "Edit User";
  } else if (viewStatus) {
    modalText = "View User";
  }

  // fetching dept
  const [compId, setCompId] = useState<any>(null);
  const [deptOptions, setDeptOptions] = useState([]);
  useEffect(() => {
    if (compId) {
      console.log(compId, "lokkkkkkkkk");
      dispatch(getDepartments(undefined, 1000, undefined, undefined, undefined, compId));
    }
  }, [compId]);

  return (
    <Modal modalText={modalText} onClose={closeModal} modallength={!viewStatus ? "modal-length" : ""}>
      <div id="buyer-createuser-modal">
        {!viewStatus && !editStatus ? <p className="">Complete this form with accurate data to create a new user on GZISP Platform.</p> : null}
        <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
          <Textbox
            type="text"
            name="othernames"
            label="First Name"
            placeholder="First Name"
            error={errors.othernames && "This field is required"}
            customRef={register({
              required: true
            })}
            disabled={viewStatus}
          />
          <Textbox
            type="text"
            label="Last Name"
            name="lastname"
            placeholder="Last Name"
            boxClasses="mt-3"
            error={errors.lastname && "This field is required"}
            customRef={register({
              required: true
            })}
            disabled={viewStatus}
          />
          {/* <ArrowDropdown
            text="Role"
            options={[]}
            handleDropdownChange={handleDropdownChange}
            handleAction={handleAction}
            selectedOption={selectedOption}
            open={open}
            boxClasses="mt-3"
            ref={dropdownRef}
          /> */}
          <p className={`${error ? "d-block" : "d-none"} error mt-1`}>{error}</p>
          <Textbox
            type="email"
            label="Email address"
            placeholder="Email address"
            boxClasses="mt-3"
            name="email"
            error={errors.email && errors.email.message}
            customRef={register({
              required: "This field is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address"
              }
            })}
            disabled={viewStatus}
          />
          <Textbox
            type="number"
            name="phone_number"
            label="Phone Number"
            error={errors.phone_number && errors.phone_number.message}
            customRef={register({
              required: "This field is required",
              validate: validatePhoneNo
            })}
            placeholder="Phone Number"
            boxClasses="mt-3"
            disabled={viewStatus}
          />
          <SelectDropdown
            boxClasses="mt-3"
            name="user_role_id"
            label="Role"
            placeholder="Select a Role"
            options={rolesArray}
            customRef={register({ required: true })}
            error={errors.user_role_id && "This field is required"}
            disabled={viewStatus}
          />
          {/* <SelectDropdown
            boxClasses="mt-3"
            name="user_type"
            label="User Type"
            placeholder="Select User Type"
            options={userTypes}
            customRef={register({ required: true })}
            error={errors.user_type && "This field is required"}
            disabled={viewStatus}
          /> */}
          <div className="mt-3">Select all Company</div>
          <div className="d-flex radio-box" style={{ columnGap: "2rem" }}>
            <div className="">
              <input type="radio" name="certified" id="yes" checked={allComp} className="mr-2" onChange={() => setAllComp(true)} />
              <label className="radio-box_label" htmlFor="yes">
                Yes
              </label>
            </div>
            <div className="">
              <input type="radio" name="certified" id="no" className="mr-2" checked={!allComp} onChange={() => setAllComp(false)} />
              <label className="radio-box_label" htmlFor="no">
                No
              </label>
            </div>
          </div>
          <SelectDropdown
            boxClasses="mt-3"
            name="company_id"
            label="Company"
            placeholder="Select company"
            options={companiesArray}
            customRef={register({ required: false })}
            error={errors.company_id && "This field is required"}
            disabled={viewStatus || allComp}
            onChange={(e) => {
              console.log(e.target.value, "vallllllllll");
              // setDepartmentArray([]);
              // setDepartmentArrayAll([]);
              setCompId(e.target.value);
            }}
          />
          <SelectDropdown
            boxClasses="mt-3"
            name="department_id"
            label="Department"
            placeholder="Select Department"
            // options={!allComp ? departmentArray : departmentArrayAll}
            options={
              departments
                ? departments?.data?.map((item) => {
                    return {
                      value: item?.id,
                      optionName: item?.department_description
                    };
                  })
                : []
            }
            customRef={register({ required: false })}
            error={errors.department_id && "This field is required"}
            disabled={viewStatus}
          />

          {!viewStatus ? <Button label={editStatus ? "Edit User" : "Create User"} btnType="btn-primary" boxClasses="mt-5 mb-5" btnStyle="100%" loading={isLoading} /> : null}
        </form>
      </div>
    </Modal>
  );
};

export default CreateBuyerUser;
