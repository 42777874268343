import { useEffect } from "react";
import Modal from "components/Modal";
import Textbox from "components/Textbox";
import Button from "components/Button";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import SelectDropdown from "components/SelectDropdown";
import { editLocation, getLocations } from "store/slice/BuyerSlice/LocationSlice/LocationSlice";
import { createProductCategory, createProductData, editProductCategory, editProductData, getMaterialCategories, getMaterials, uploadFile } from "store/slice/BuyerSlice/ProductSlice/ProductSlice";
import { useState } from "react";
import FileUploaderBox from "components/FileUploaderBox";

type Props = {
  closeModal: () => void;
  singleProductCategory?: any;
};
const CreateOrEditProductCategory = ({ closeModal, singleProductCategory = null }: Props) => {
  const { register, handleSubmit, errors, setValue } = useForm<FormData>({
    mode: "onChange"
  });

  //Redux Store
  const dispatch = useDispatch<AppDispatch>();
  const { materialCategory, loading,isLoading } = useSelector((state: RootState) => state.product);

  const [files, setFiles] = useState<any>([]);
  const [uploadBool, setUploadBool] = useState(false);
  const checkUpload = () => {
    setUploadBool(!uploadBool);
  };

  type FormData = {
    material_category_code: string;
    material_category_description: string;
    material_category_type:string;
  };

  useEffect(() => {
    if (singleProductCategory !== null) {
      setValue("material_category_code", singleProductCategory.material_category_code);
      setValue("material_category_description", singleProductCategory.material_category_description);
      setValue("material_category_type", singleProductCategory.material_category_type);
      
    }
  }, [singleProductCategory]);



  const onFormSubmit = (vals: FormData) => {
    if (singleProductCategory) {
      const payload = { ...vals, id: singleProductCategory.id };
      dispatch(
        editProductCategory(payload, () => {
          closeModal();
          dispatch(getMaterialCategories());
        })
      );
      return;
    }

    dispatch(
      
      createProductCategory(vals, () => {
        closeModal();
        dispatch(getMaterialCategories());
      })
    );
  };
  const handleFile = (e, field_name: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(e);
    reader.onloadend = () => {
      // use a regex to remove data url part
      let read: any = reader.result;
      const payload = {
        base64_data: read.replace("data:", "").replace(/^.+,/, ""),
        file_extension: e.name.split(".").pop()
      };
      dispatch(uploadFile(payload));

      setFiles(payload);
    };
  };
  //MaterialCategory List
  const materialCategoryOptions =
    materialCategory &&
    materialCategory.data.map((_data: { id: string; material_category_description: string }) => {
      return {
        value: _data?.id,
        optionName: _data?.material_category_description
      };
    });

  const categoryType = [
    {
      value: "Service",
      optionName: "Service"
    },
    {
      value: "Material",
      optionName: "Material"
    }
  ];

  return (
    <Modal
      modalText={singleProductCategory === null ? "Create" : "Edit"}
      onClose={closeModal}
      modallength="modal-length"
      isUpload={true}
      checkUpload={checkUpload} uploadBool={uploadBool}
    >
      {
        uploadBool ? 
        <div id="buyer-create-location">
          <p className="">Download and fill the Product Category template. adhere to file structure before uploading the Categoroes</p>
          <form className="mt-2">
            <p className="template">xxxx Teemplate sourcing team</p>

            <FileUploaderBox
              fileName=""
              boxClasses="mt-3 mb-5"
              handleFile={handleFile}
              acceptFiles={".jpg, .jpeg, .png"}
              customRef={register({ required: true })}
              error={errors["logo"] && "This field is required"}
            />

          <Button
            label={singleProductCategory === null ? "Create Category" : "Edit Category"}
            btnType="btn-primary"
            boxClasses="mt-4 mb-5"
            btnStyle="100%"
            loading={isLoading}
          />
          </form>
        </div>
      
        : 
        <div id="buyer-create-location">
        <p className="">
          {singleProductCategory === null
            ? "Complete this form with accurate data to create New Product Categories."
            : "Complete this form with accurate data to Update Product Category."}
        </p>

        <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
          <Textbox
            type="text"
            name="material_category_code"
            label="Category Code"
            placeholder="Category Code"
            error={errors.material_category_code && "This field is required"}
            customRef={register({
              required: true
            })}
          />
          <Textbox
            type="text"
            label="Category Description"
            name="material_category_description"
            placeholder="Category Description"
            boxClasses="mt-3"
            error={errors.material_category_description && "This field is required"}
            customRef={register({ required: true })}
          />
          <SelectDropdown
             boxClasses="mt-3 mb-4"
             name="material_category_type"
             label="Category Type"
             placeholder="Category Type"
             options={categoryType}
             customRef={register({ required: true })}
             error={errors.material_category_type && "This field is required"}
           />
     

          <Button
            label={singleProductCategory === null ? "Create Category" : "Edit Category"}
            btnType="btn-primary"
            boxClasses="mt-4 mb-5"
            btnStyle="100%"
            loading={isLoading}
          />
        </form>
      </div>
   
      }
      
    </Modal>
  );
};

export default CreateOrEditProductCategory;
