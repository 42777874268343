/* eslint-disable @typescript-eslint/no-unused-vars */
import StatsCard from "components/StatsCard";
import React, { useEffect, useState } from "react";
import "../styles.scss";
import Target from "assests/icons/buyer/target.svg";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import SearchIcon from "components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import {KpiSetupColumn } from "../data";
import CreateKPIModal from "components/ModalBuyer/CreateKPI";
import CreateKpiGroup from "components/ModalBuyer/CreateKPIGroup";
import { toastPopUp } from "utils/utils";
import { useDispatch, useSelector} from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { useHistory, useParams } from "react-router-dom";
import CreateKpiSetup from "components/ModalBuyer/CreateKPISetup";
import { deleteKpiList, getKpiList } from "store/slice/BuyerSlice/KpiSetupSlice/KpiSetupSlice";
import CreateKpi from "pages/BuyerDashboard/SourcingMain/Modals/CreateKpi";
import useSearch from "hooks/useSearch";
import Pagination from "components/Pagination";
import { checkPermission } from "utils/utils";

const KpiSetup = () => {
    const [modal, setModal] = useState<boolean>(false);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const history = useHistory()
    const [singleList,setSingleList] = useState(null)
 
    const closeModal = () => {
        setModal((prev) => !prev);
        setSingleList(null)
    };
    const { idKpi } = useParams<{idKpi?: any}>()
    const dispatch = useDispatch<AppDispatch>();
    const { kpiList, kpiFieldLoading } = useSelector((state: RootState) => state.kpisetup);
    const { search, inputRef, handleChange, searched } = useSearch(getKpiList, perPage,idKpi);
    const { role_access } = useSelector((state: RootState) => state.user?.user);

    const editKpiList_two = (data) => {
        setSingleList(data);
        setModal((prev) => !prev);
      };

      const deleteList2 = (name: string, id: string) => {
        const payload = { kpi_id: id };
        toastPopUp(
            () => {
                dispatch(
                    deleteKpiList(payload, () => {
                      dispatch(getKpiList({page,length:perPage,id:idKpi}));
                    })
                );
            },
            "Delete",
            "Cancel",
            "Delete the entity",
            name,
            "red"
        );
      };

    //Show Number for PerPage Request
    const handleNewPage = (pageNo: number, page: number = 1) => {
        setPerPage(pageNo);
        setPage(page);
        dispatch(getKpiList({page,length:pageNo,id:idKpi}));
    };
  
    const handlePageChange = React.useCallback(
      (page: number, perPage: number) => {
        setPage(page);
        dispatch(getKpiList({page,length:perPage,id:idKpi}));
      },
      []
    );

    useEffect(() => {
        dispatch(getKpiList({page,length:perPage,id:idKpi}));
      }, []);

      const paginate = React.useCallback(
        (pageNo: number, perPage: number) => {
          setPage(pageNo);
          dispatch(getKpiList({page,length:perPage,id:idKpi}));
        },
        []
      );


      const KpiFieldList =
      kpiList &&
      kpiList?.data?.map((item) => {
          
        return {
          field_name: item.field_name,
          field_order: item.field_order,
          field_type: item.field_type,
          evaluation_type: item.evaluation_type,
          edit: () => checkPermission(role_access, "SETUP_INDEX") && editKpiList_two(item),
          deleteWork: () => checkPermission(role_access, "SETUP_INDEX") && deleteList2(item.field_name, item.id)
        };
      });

    return (
        <div id="settings-sourcing">
            {modal && <CreateKpiSetup closeModal={closeModal} singleList={singleList} groupId={idKpi} />}
            {/* {modal && <CreateKpi rfp={rfp} category="Commercial" closeModal={closeModal} />} */}
            <div className="container mt-3">
                <div className="row mt-3 mb-5">
                    <StatsCard
                        statsNumber={kpiList && kpiList?.count}
                        text="KPI’s"
                        classes="stats-icon"
                        image={Target}
                        iconBackgroundColor="rgba(45, 156, 219, 0.1)"
                    />
                    <StatsCard image={AddIcon} classes="middle-img" centerText="Create KPI" onClick={closeModal} />
                </div>

                <div className="d-flex  mb-3">
                <SearchIcon
            placeholder="Search for Kpi"
            boxClasses=""
            textRef={inputRef}
            value={search}
            onChange={handleChange}
          />
                    <Dropdown perPage={10} action={handleNewPage} />
                </div>
                <DataTable columns={KpiSetupColumn} data={KpiFieldList} loading={kpiFieldLoading}  />
                {
                  kpiList?.count > 10 ? 
                  <Pagination
                  page={page}
                  lastPage={kpiList?.pagination?.lastPage}
                  paginate={paginate}
                  total={kpiList?.pagination?.total}
                  handlePageChange={handlePageChange}
                  perPage={perPage}
                />
                  : null
                }
            </div>
        </div>
    );
};

export default KpiSetup;
