import StatsCard from "components/StatsCard";
import React, { useState } from "react";
import "../styles.scss";
import Analytics from "assests/icons/buyer/analytics.svg";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import SearchIcon from "components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { tableCol } from "../data";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { useEffect } from "react";
import { checkPermission, toastPopUp } from "utils/utils";
import useSearch from "hooks/useSearch";
import Pagination from "components/Pagination";
import CreateNotify from "components/ModalBuyer/CreateNotify";
import { getViewRate } from "store/slice/BuyerSlice/VendorRate/VendorRateSlice";
import { useHistory } from "react-router";
import StarRating from "components/Star";
import CreateRate from "components/ModalBuyer/CreateRate";
import moment from "moment";

const ViewRating = () => {
  //State
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [modal, setModal] = useState<boolean>(false);
  const history = useHistory();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const vendorName = urlParams.get("name");
  const totalRate = urlParams.get("rate");
  const id = urlParams.get("id");
  const [singleView, setSingleView] = useState<any>(null);

  //Redux
  const dispatch = useDispatch<AppDispatch>();
  const { viewRate, loadingView } = useSelector((state: RootState) => state.rate);
  const { role_access } = useSelector((state: RootState) => state.user?.user);

  //Hook
  const { search, inputRef, handleChange, searched } = useSearch(getViewRate, perPage, id);

  useEffect(() => {
    dispatch(getViewRate({ page, length: perPage, id }));
  }, []);

  // setpage to the first page when a searched executed
  useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  // new page
  const handleNewPage = (pageNo: number, page: number = 1) => {
    setPerPage(pageNo);
    setPage(1);
    dispatch(getViewRate({ page, length: pageNo, id }));
  };

  //Modal Handler
  const closeModal = () => {
    setModal((prev) => !prev);
    setSingleView(null);
  };

  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, perPage: number) => {
      setPage(page);
      dispatch(getViewRate({ page, length: perPage, id, search }));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, perPage: number) => {
      setPage(pageNo);
      dispatch(getViewRate({ page, length: perPage, id, search }));
    },
    [search]
  );

  //Edit Rating
  const editViewRate = (id: string, quality_rating: string, timeliness_rating: string, delivery_rating: string, comment: string) => {
    setSingleView({ id, quality_rating, delivery_rating, timeliness_rating, comment });
    setModal((prev) => !prev);
  };

  const rateList =
    viewRate &&
    viewRate?.data?.map((item) => {
      return {
        goods_received_date: moment(item.created_at).format("l"),
        gr_code: item.purchase_order_gr_id,
        delivery_rating: item.delivery_rating,
        timeliness_rating: item.timeliness_rating,
        quality_rating: item.quality_rating,
        edit: () => checkPermission(role_access, "VENDOR_EVALUATION_CREATE") && editViewRate(item.id, item.quality_rating, item.timeliness_rating, item.delivery_rating, item.comment)
      };
    });

  return (
    <div id="settings-material-data">
      {modal && <CreateRate closeModal={closeModal} id={id} singleView={singleView} />}
      <div className="container mt-3">
        <div className="about d-flex">
          <h3 className="page-title">{vendorName}</h3>
          <StarRating disabled stars={Number(totalRate)} />
        </div>

        <div className="row mt-3 mb-5">
          <StatsCard statsNumber={viewRate?.count ?? 0} text="Good Receipts" classes="stats-icon" image={Analytics} iconBackgroundColor="rgba(45, 156, 219, 0.1)" />
          <StatsCard image={AddIcon} classes="middle-img" centerText="Rate" onClick={closeModal} />
        </div>

        <div className="d-flex  mb-3">
          <SearchIcon placeholder="Search" boxClasses="" textRef={inputRef} value={search} onChange={handleChange} />
          <Dropdown perPage={10} action={handleNewPage} />
        </div>
        <DataTable columns={tableCol} data={rateList} loading={loadingView} />
        <Pagination page={page} lastPage={viewRate?.pagination?.lastPage} paginate={paginate} total={viewRate?.pagination?.total} handlePageChange={handlePageChange} perPage={perPage} />
      </div>
    </div>
  );
};

export default ViewRating;
