type ButtonType = JSX.IntrinsicElements["button"]["type"];

type Props = {
    label: string;
    value?: string;
    boxClasses?: string;
    disabled?: boolean;
    btnType: string;
    xtraClass?: string;
    btnStyle?: string;
    loading?: boolean;
    onClick?: () => void;
    onSubmit?: (vals: any) => void;
    name?: string;
    type?: ButtonType;
    icon?: string;
    addStyle?:string;
};

const Button = ({
    label,
    boxClasses,
    disabled = false,
    btnType,
    xtraClass,
    btnStyle = "auto",
    loading,
    onClick,
    onSubmit,
    name,
    type = "submit",
    icon
}: Props) => {
    return (
        <div className={`${boxClasses}`}>
            <button
                type={type}
                className={`btn ${btnType} btn-block ${xtraClass}`}
                disabled={disabled}
                style={{ width: `${btnStyle}` }}
                onClick={onClick}
                onSubmit={onSubmit}
                name={name}
            >
                {icon && <img src={icon} alt="icon" />}
                {loading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : label}
            </button>
        </div>
    );
};

export default Button;
