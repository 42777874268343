import DropdownMenu from "components/DropdownMenu";
import "./styles.scss";

export const companydatas = [
    {
        code: "0010",
        name: "GZI Nigeria",
        currency: "NGN",
        value: "1000"
    },
    {
        code: "0011",
        name: "GZI Nairobi",
        currency: "USD",
        value: "2000"
    }
];

export const companyColumn = [
    {
        name: "Entity Code",
        index: "company_id",
        id: 1
    },
    {
        name: "Entity Name",
        index: "company_description",
        id: 2
    },
    {
        name: "Defalt Currency",
        index: "currency",
        id: 3
    },
    {
        name: "ERP ID",
        index: "company_code",
        id: 4
    },
    {
        name: "Actions",
        id: 5,
        render: ({ deleteEntity, edit }) => {
            const settings = [
                {
                    name: "Edit",
                    style: "edit_tag",
                    select: edit
                }
                // {
                //     name: "Delete",
                //     style: "delete_tag",
                //     select: deleteEntity
                // }
            ];
            return (
                <span style={{ position: "relative" }}>
                    <DropdownMenu options={settings} boxClasses="dropdown-style">
                        <p style={{ fontSize: "2rem" }} className="cursor-pointer">
                            ...
                        </p>
                    </DropdownMenu>
                </span>
            );
        }
    }
];
