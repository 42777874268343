import React from "react";
import Modal from "components/Modal";
import Button from "components/Button";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";

import API from "utils/httpsRequest";
import { checkPermission, toastPopUp } from "utils/utils";
import { Controller } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { toastr } from "react-redux-toastr";
import { createNotifySetup, getNotifySetup } from "store/slice/BuyerSlice/NotifySetup/NotifySetupSlice";
import { getUserNotification } from "store/slice/NotificationSlice/NotificationSlice";
import { doAddVendorBusinessCategoryparent, doRemoveVendorBusinessCategory, getAllBusinessCategory, doAddVendorBusinessCategory } from "store/slice/BusinessCategorySlice/BusinessCategorySlice";
import { getSingleVendor } from "store/slice/BuyerSlice/VendorSlice/VendorSlice";
import Remove from "../../../assests/icons/Remove/Vector (6).svg";
import Add from "../../../assests/icons/Remove/Vector (5).svg";
import Textbox from "components/Textbox";
import SelectDropdown from "components/SelectDropdown";
import { useState, useEffect } from "react";
import Loaderhead from "components/LoaderHead/loaderhead";
type Props = {
  closeModal: () => void;
  count?: any;
  vendorId: any;
  vendorData: any;
};
const MapBusinessCategory = ({ closeModal, vendorId, count, vendorData }: Props) => {
  const { register, handleSubmit, errors, control, setValue } = useForm<FormData>({
    mode: "onChange"
  });
  const { allBusinessCategory } = useSelector((state: RootState) => state.businessCategory);

  // category options

  //Getting Bussiness Categories
  const businessCategoryOption =
    allBusinessCategory &&
    allBusinessCategory.map((_data: { id: string; business_category_description: string }) => {
      return {
        value: _data.id,
        optionName: _data.business_category_description
      };
    });

  useEffect(() => {
    if (vendorData) {
      dispatch(getAllBusinessCategory(undefined, vendorData.business_category_id));
    }
  }, [vendorData]);
  //Redux Store
  const dispatch = useDispatch<AppDispatch>();
  const { loadingParent, loadingremove } = useSelector((state: RootState) => state.businessCategory);

  const { user } = useSelector((state: RootState) => state.user);
  const { token } = user;
  const [showCategory, setShowCategory] = useState<boolean>(false);
  const [catSel, setCatSel] = React.useState("");
  const [selCatVal, setSelCatVal] = React.useState();
  const [defaultCat, setDefaultCat] = useState([]);
  const { role_access } = useSelector((state: RootState) => state.user?.user);
  console.log(vendorData, "vvv");
  type FormData = {
    activity: string;
    business_category_id: string;
  };

  const handleInputChangeCat = (value) => {
    setCatSel(value);
  };

  const onChangeCat = (value) => {
    setSelCatVal(value);
  };

  const catOptions = () => {
    return API.get(`/business_categories/get?search=${catSel}&length=1000&business_category_type=Parent`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: `${item.business_category_description}`,
        value: item.id
      }));
    });
  };

  // useEffect(() => {
  //   catOptions();
  // }, []);

  // then((res) => {
  //   return res.data.data.map((item) => ({
  // label: `${item.business_category_description}`,
  // value: item.id
  //   }));
  // });
  // ${catSel ? catSel : vendorData.business_category_description}
  // then((res) => {
  //   let guy =
  //     res.data &&
  //     res.data.data.map((item) => ({
  //       label: item.location_description,
  //       value: item.id
  //     }));

  //   setNewLocationOption(guy);
  //   return guy;
  // });

  const onFormSubmit = (vals: FormData) => {
    const payload = {
      vendor_id: vendorId,
      business_category_id: selCatVal?.["value"]
    };
    if (vendorData && vendorData?.categories?.length === 0) {
      checkPermission(role_access, "UPDATE_VENDOR_CATEGORY") &&
        dispatch(
          doAddVendorBusinessCategoryparent(payload, () => {
            dispatch(getSingleVendor(vendorId));
            closeModal();
          })
        );
    } else {
      toastr.error("", "All sub categories have to be cleared");
    }
  };

  return (
    <Modal modalText={` ${vendorData && vendorData.business_category_description}`} onClose={closeModal} modallength="modal-length">
      <>
        <Loaderhead status={loadingremove} />
        {
          <div id="buyer-create-location">
            <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
              <Controller
                control={control}
                defaultValue
                boxClasses="mt-3"
                name="role"
                render={({ ref }) => (
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    // defaultOptions={defaultCat}
                    placeholder={`${vendorData && vendorData.business_category_description}`}
                    value={selCatVal}
                    loadOptions={catOptions}
                    onInputChange={handleInputChangeCat}
                    onChange={onChangeCat}
                  />
                )}
              />
              <Button label={"Edit"} btnType="btn-primary" boxClasses="mt-4 mb-5" btnStyle="100%" loading={loadingParent} />
            </form>
            <div className="second_side mt-4">
              <p className="topic">Sub Category</p>

              {vendorData &&
                vendorData?.categories?.map((biz, i) => {
                  return (
                    <div className=" d-flex pl-0 wider" key={i}>
                      <Textbox value={biz.business_category_description} placeholder="Businees Field" boxClasses="mt-2" disabled={true} />
                      <img
                        src={Remove}
                        alt=""
                        className="pl-3 cursor-pointer"
                        onClick={() =>
                          dispatch(doRemoveVendorBusinessCategory({ vendor_id: vendorData?.id, business_category_id: biz.business_category_id }, () => dispatch(getSingleVendor(vendorData?.id))))
                        }
                      />
                    </div>
                  );
                })}
            </div>
            <div className="d-flex d-flex mt-3 cursor-pointer" onClick={() => setShowCategory(!showCategory)}>
              <img src={Add} alt="" />
              <p> {showCategory ? "Hide  Category" : "Add Category"}</p>
            </div>
            {showCategory && (
              <div className="d-flex">
                <SelectDropdown
                  boxClasses="mt-3"
                  name="business_category_id"
                  label="Business Category"
                  placeholder="Business Category"
                  options={businessCategoryOption}
                  customRef={register({
                    // required: { value: requiredForm, message: "This field is required" }
                  })}
                  onChange={(e) =>
                    dispatch(
                      doAddVendorBusinessCategory({ vendor_id: vendorData.id, business_category_id: e.target.value }, () => {
                        dispatch(getSingleVendor(vendorData.id));
                        setValue("business_category_id", "");
                        setShowCategory(false);
                      })
                    )
                  }
                />
              </div>
            )}
          </div>
        }
      </>
    </Modal>
  );
};

export default MapBusinessCategory;
