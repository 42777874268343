import { Link } from "react-router-dom";

export const reportCol = [
  {
    name: "ID",
    index: "id",
    id: 1
  },
  {
    name: "Name",
    index: "user_name",
    id: 2
  },
  {
    name: "Location",
    index: "total_delivery_locations",
    id: 3
  },
  {
    name: "Requisition",
    index: "total_requisitions",
    id: 4
  },
  // {
  //     name: "Total Purchasing Groups",
  //     index: "total_purchasing_groups",
  //     id: 5
  // },
  {
    name: "PO Lead",
    index: "po",
    id: 5
  }
];

export const reportData = [
  {
    department_code: "Brent Fawaz",
    department_description: "13",
    amount: "N23,345",
    id: 1
  },
  {
    location: "Brent Fawaz",
    department: "13",
    amount: "N23,345",
    id: 2
  },
  {
    location: "Brent Fawaz",
    department: "13",
    amount: "N23,345",
    id: 4
  }
];
