import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { approvalState, Action } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";

const initialState: approvalState = {
  kpiList: null,
  kpiGroup:null,
  kpiFieldLoading:false,
  kpiGroupLoading:false,
  kpiFieldisLoading:false,
  kpiGroupisLoading:false,
  successMessage: "",
  errorMessage: "",
  deleting:false,
};

const KpiSetupSlice = createSlice({
  name: "kpiSetup",
  initialState,
  reducers: {
    getKpiGroupLoading: (state) => {
      state.kpiGroupLoading = true;
    },
    getKpiGroupSuccess: (state, action: PayloadAction<Action>) => {
      state.kpiGroupLoading = false;
      state.kpiGroup = action.payload;
    },
    getKpiGroupFail: (state) => {
      state.kpiGroupLoading = false;
    },
    getKpiListLoading: (state) => {
      state.kpiFieldLoading = true;
    },
    getKpiListSuccess: (state, action: PayloadAction<Action>) => {
      state.kpiFieldLoading = false;
      state.kpiList = action.payload;
    },
    getKpiListFail: (state) => {
      state.kpiFieldLoading = false;
    },
    createKpiGroupLoading: (state) => {
      state.kpiGroupisLoading = true;
    },
    createKpiGroupSuccess: (state, action: PayloadAction<Action>) => {
      state.kpiGroupisLoading = false;
      state.kpiGroup = action.payload;
    },
    createKpiGroupFail: (state, action: PayloadAction<string>) => {
      state.kpiGroupisLoading = false;
      state.errorMessage = action.payload;
    },
    createKpiFieldLoading: (state) => {
      state.kpiFieldisLoading = true;
    },
    createKpiFieldSuccess: (state, action: PayloadAction<Action>) => {
      state.kpiFieldisLoading = false;
      state.kpiList = action.payload;
    },
    createKpiFieldFail: (state, action: PayloadAction<string>) => {
      state.kpiFieldisLoading = false;
      state.errorMessage = action.payload;
    },
    editKpiGoupLoading: (state) => {
      state.kpiGroupisLoading = true;
    },
    editKpiGroupSuccess: (state, action: PayloadAction<Action>) => {
      state.kpiGroupisLoading = false;
      state.kpiGroup = action.payload;
    },
    editKpiGroupFail: (state, action: PayloadAction<string>) => {
      state.kpiGroupisLoading = false;
      state.errorMessage = action.payload;
    },
    editKpiListLoading: (state) => {
      state.kpiFieldisLoading = true;
    },
    editKpiListSuccess: (state, action: PayloadAction<Action>) => {
      state.kpiFieldisLoading = false;
      state.kpiList = action.payload;
    },
    editKpiListFail: (state, action: PayloadAction<string>) => {
      state.kpiFieldisLoading = false;
      state.errorMessage = action.payload;
    },
    deleteKpiGroupLoading: (state) => {
      state.deleting = true;
    },
    deleteKpiGroupSuccess: (state, action: PayloadAction<Action>) => {
      state.deleting = false;
      state.kpiGroup = action.payload;
    },
    deleteKpiGroupFail: (state, action: PayloadAction<string>) => {
      state.deleting = false;
      state.errorMessage = action.payload;
    },
    deleteKpiListLoading: (state) => {
      state.deleting = true;
    },
    deleteKpiListSuccess: (state, action: PayloadAction<Action>) => {
      state.deleting = false;
      state.kpiList = action.payload;
    },
    deleteKpiListFail: (state, action: PayloadAction<string>) => {
      state.deleting = false;
      state.errorMessage = action.payload;
    }
  }
});

const { actions, reducer } = KpiSetupSlice;
export const {
  getKpiGroupFail,
  getKpiGroupLoading,
  getKpiGroupSuccess,
  getKpiListSuccess,
  getKpiListLoading,
  getKpiListFail,
  createKpiGroupFail,
  createKpiGroupLoading,
  createKpiGroupSuccess,
  createKpiFieldSuccess,
  createKpiFieldLoading,
  createKpiFieldFail,
  editKpiGoupLoading,
  editKpiGroupSuccess,
  editKpiGroupFail,
  editKpiListFail,
  editKpiListLoading,
  editKpiListSuccess,
  deleteKpiGroupFail,
  deleteKpiGroupLoading,
  deleteKpiGroupSuccess,
  deleteKpiListFail,
  deleteKpiListLoading,
  deleteKpiListSuccess,
 } = actions;

 type getKpi = {
  page?: number, 
  length?: number, 
  id?:string, 
  kpi_group_id?:string,
  search?: string, 
  activity_name?: string, 
  project_type?: string,
 }

export const getKpiGroup =
  ({page,length,search,activity_name,project_type}:getKpi): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getKpiGroupLoading());
    try {
      const response = await API.get(`/kpis/get_groups`, {
        params: {
          activity_name,
          length,
          search,
          project_type,
          page
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getKpiGroupSuccess(response.data));
      }
    } catch (error) {
      dispatch(getKpiGroupFail());
    }
  };



  export const getKpiList =
  ({page,length,id,search,activity_name,project_type}:getKpi): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;

    dispatch(getKpiListLoading());
    try {
      const response = await API.get(`/kpis/get`, {
        params: {
          activity_name,
          length,
          kpi_group_id:id,
          search,
          project_type,
          page
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getKpiListSuccess(response.data));
      }
    } catch (error) {
      dispatch(getKpiListFail());
    }
  };
 


export const createKpiGroup =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(createKpiGroupLoading());
    try {
      const response = await API.post(`/kpis/create_group`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(createKpiGroupSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      if (error.response) {
        dispatch(createKpiGroupFail(error.response.data?.message));
        toastr.error(error.response.data.message);
      }
      else{
        dispatch(createKpiGroupFail("No internet connection"));
        toastr.error("No internet connection");
      }
   
      setTimeout(() => {
        dispatch(createKpiGroupFail(""));
      }, 3000);
    }
  };

  export const createKpiList =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(createKpiFieldLoading());
    try {
      const response = await API.post(`/kpis/create`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(createKpiFieldSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      if (error.response) {
        dispatch(createKpiFieldFail(error.response.data?.message));
        toastr.error(error.response.data.message);
      }
      else{
        dispatch(createKpiFieldFail("No internet connection"));
        toastr.error("No internet connection");
      }
   
      setTimeout(() => {
        dispatch(createKpiFieldFail(""));
      }, 3000);
    }
  };

  export const editKpiGroup =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
      const { userToken } = getState().user;
      dispatch(editKpiGoupLoading());
      try {
          const response = await API.post(`/kpis/edit_group`, data, {
              headers: {
                  Authorization: `Bearer ${userToken}`
              }
          });
          if ([200, 201].includes(response.status)) {
              dispatch(editKpiGroupSuccess(response.data.message));
              toastr.success("", response.data.message);
              cb && cb();
          }
      } catch (error) {
        
          if (error.response) {
            dispatch(editKpiGroupFail(error.response.data.message));
            toastr.error(error.response.data.message);
          }
          else{
            dispatch(editKpiGroupFail("No internet connection"));
            toastr.error("No internet connection");
          }
          setTimeout(() => {
              dispatch(editKpiGroupFail(""));
          }, 3000);
      }
  };

  export const editKpiList =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
      const { userToken } = getState().user;
      dispatch(editKpiListLoading());
      try {
          const response = await API.post(`/kpis/edit`, data, {
              headers: {
                  Authorization: `Bearer ${userToken}`
              }
          });
          if ([200, 201].includes(response.status)) {
              dispatch(editKpiListSuccess(response.data.message));
              toastr.success("", response.data.message);
              cb && cb();
          }
      } catch (error) {
        
          if (error.response) {
            dispatch(editKpiListFail(error.response.data.message));
            toastr.error(error.response.data.message);
          }
          else{
            dispatch(editKpiListFail("No internet connection"));
            toastr.error("No internet connection");
          }
          setTimeout(() => {
              dispatch(editKpiListFail(""));
          }, 3000);
      }
  };

  export const deleteKpiGroup =
  (data?: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
      const { userToken } = getState().user;
      dispatch(deleteKpiGroupLoading());
      try {
          const response = await API.post(`/kpis/remove_group`, data, {
              headers: {
                
                  Authorization: `Bearer ${userToken}`
              }
          });
          if ([200, 201].includes(response.status)) {
              dispatch(deleteKpiGroupSuccess(response.data.message));
              toastr.success("", response.data.message);
              cb && cb();
          }
      } catch (error) {
         
          if (error.response) {
            dispatch(deleteKpiGroupFail(error.response.data.message));
            toastr.error(error.response.data.message);
          }
          else{
            dispatch(deleteKpiGroupFail("No internet connection"));
            toastr.error("No internet connection");
          }
          setTimeout(() => {
              dispatch(deleteKpiGroupFail(""));
          }, 3000);
      }
  };

  export const deleteKpiList =
  (data?: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
      const { userToken } = getState().user;
      dispatch(deleteKpiListLoading());
      try {
          const response = await API.post(`/kpis/remove`, data, {
              headers: {
                
                  Authorization: `Bearer ${userToken}`
              }
          });
          if ([200, 201].includes(response.status)) {
              dispatch(deleteKpiListSuccess(response.data.message));
              toastr.success("", response.data.message);
              cb && cb();
          }
      } catch (error) {
         
          if (error.response) {
            dispatch(deleteKpiListFail(error.response.data.message));
            toastr.error(error.response.data.message);
          }
          else{
            dispatch(deleteKpiListFail("No internet connection"));
            toastr.error("No internet connection");
          }
          setTimeout(() => {
              dispatch(deleteKpiListFail(""));
          }, 3000);
      }
  };


export default reducer;
