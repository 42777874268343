export const tableCol = [
    {
        name: 'Date',
        index: 'date',
        id: 0,
    },
    {
        name: 'Document No.',
        index: 'document_no',
        id: 1,
    },
    {
        name: 'Supplier ID',
        index: 'supplier_id',
        id: 2,
    },
    {
        name: 'Supplier Name',
        index: 'supplier_name',
        id: 3,
    },
    {
        name: 'Amount',
        index: 'amount',
        id: 4,
    },
    {
        name: 'Currency',
        index: 'currency',
        id: 5,
    },
    {
        name: 'Status',
        id: 6,
        render: (item) => {
            if (item.purchase_order_status === '1') {
                return (
                    <button
                        style={{
                            background: 'rgba(0, 176, 7, 0.15)',
                            borderRadius: '10px',
                            color: 'rgb(56, 124, 87)',
                            padding: "8px 16px",
                        }}
                    >
                        Approved
                    </button>
                )
            }
            if (item.purchase_order_status === '-1') {
                return (
                    <button
                        style={{
                            padding: "8px 16px",
                            background: "rgba(239, 196, 66, 0.15)",
                            borderRadius: "10px",
                            color: "rgb(47, 128, 237)"
                        }}
                    >
                        Pending
                    </button>
                )
            }
            if (item.purchase_order_status === '-2') {
                return (
                    <button
                        style={{
                            padding: "8px 16px",
                            background: "rgba(245, 150, 37, 0.15)",
                            borderRadius: "10px",
                            color: "rgb(245, 150, 37)"
                        }}
                    >
                        In Draft
                    </button>
                )
            }
            if (item.purchase_order_status === '2') {
                return (
                    <button
                        style={{
                            padding: "8px 16px",
                            background: "rgba(245, 150, 37, 0.15)",
                            borderRadius: "10px",
                            color: "rgb(245, 150, 37)"
                        }}
                    >
                        Accepted
                    </button>
                )
            }
            if (item.purchase_order_status === '3') {
                return (
                    <button
                        style={{
                            padding: "8px 16px",
                            background: "rgba(245, 150, 37, 0.15)",
                            borderRadius: "10px",
                            color: "rgb(245, 150, 37)"
                        }}
                    >
                        Out for Delivery
                    </button>
                )
            }
            if (item.purchase_order_status === '4') {
                return (
                    <button
                        style={{
                            padding: "8px 16px",
                            background: "rgba(245, 150, 37, 0.15)",
                            borderRadius: "10px",
                            color: "rgb(245, 150, 37)"
                        }}
                    >
                        Partially Delivered
                    </button>
                )
            }
            if (item.purchase_order_status === '5') {
                return (
                    <button
                        style={{
                            background: 'rgba(0, 176, 7, 0.15)',
                            borderRadius: '10px',
                            color: 'rgb(56, 124, 87)',
                            padding: "8px 16px",
                        }}
                    >
                        Delivered
                    </button>
                )
            }
        }
    },
]