import React, { useCallback, useEffect, useState } from "react";
import "./styles.scss";
import Star from "../../../components/Star";
import { documentsColumns, documents } from "./data";
import StatsCard from "components/StatsCard/";
import Cash from "assests/icons/statsCard/cash.png";
import Invoicedoc from "assests/icons/bill.svg";
import Contracts from "assests/icons/bill2.svg";
import Truck from "assests/icons/truck.svg";
// import { getHouseholdSummary, getAllHousehold } from "store/slice/HouseHoldsSlice/HouseholdSlice";
import { RootState } from "store/store";
import InvoiceIcon from "assests/icons/invoice.svg";
import dashboardSummary from "store/slice/DashboardSlice/DashboardSlice";
import contract from "../../../assests/icons/supplier/sidebar/contracts.svg";
import docs from "../../../assests/icons/supplier/sidebar/docs.svg";
import docsGreen from "../../../assests/icons/bill2.svg";
import vehicle from "../../../assests/icons/supplier/vehicle.svg";
import unpaid from "../../../assests/icons/supplier/unpaid.svg";
import SearchBar from "../../../components/SearchBar";
import DataTable from "components/DataTable";
import Dropdown from "components/Dropdown";
import DefaultButton from "components/DefaultButton";
import SearchIcon from "../../../components/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import useSearch from "hooks/useSearch";
import useArrowDropdown from "hooks/useArrowDropdown";
import Pagination from "components/Pagination";
import CreateInvoice from "components/ModalSupplier/CreateInvoice";
import Icon from "assests/icons/add-circle.svg";
import { getInvoices, getInvoiceSummary } from "store/slice/BuyerSlice/Invoice/InvoiceSlice";

import { formatCurrency, removeZero } from "utils/utils";
import { useForm } from "react-hook-form";
import FilterCard from "components/FilterCard";
import filterIcon from "assests/icons/buyer/filter-icon.svg";
import { useHistory } from "react-router-dom";

// interface IInvoiceProps {}

const Invoice: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageNum, setPageNumber] = useState(10);
  const [perpage, setPerpage] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [selectComboxOption, setSelectComboxOption] = React.useState("");
  const [modals, setModals] = useState(false);
  const [whichModal, setWhichModals] = useState(false);
  const [showFilter, setShowFilter] = React.useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const { summary, invoices } = useSelector((state: RootState) => state.invoice);
  const { user } = useSelector((state: RootState) => state.user);
  const { register, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      date_to: "",
      date_from: "",
      open: ""
      // to: '',
    }
  });

  const { open: open2, selectedOption: selectedOption2, handleDropdownChange: handleDropdownChange2, handleAction: handleAction2, ref: dropdownRef2 } = useArrowDropdown();

  const params = [selectedOption2 !== "" && selectedOption2 !== "All" ? selectedOption2 : undefined, selectComboxOption !== "" && selectComboxOption !== "All" ? selectComboxOption : undefined];
  const { search, inputRef, handleChange, searched } = useSearch(getInvoices, perpage);

  const closeModal = () => {
    setModals(!modals);
  };

  const handleNewPage = React.useCallback(
    (pageNo: number, page = 1) => {
      setPerpage(pageNo);
      setPage(1);
      dispatch(getInvoices(page, page, search));
    },
    [search]
  );

  const handlePageChange = React.useCallback(
    (page: number, length: number) => {
      setPage(page);
      dispatch(getInvoices(page, length, search));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, length: number) => {
      setPage(pageNo);
      dispatch(getInvoices(pageNo, length, search));
    },
    [search]
  );

  // Create Invoice
  const createInvoice = () => {
    setWhichModals(true);
    closeModal();
  };

  const uploadInvoice = () => {
    setWhichModals(false);
    closeModal();
  };

  useEffect(() => {
    console.log(summary);
  }, [summary]);

  useEffect(() => {
    dispatch(getInvoices());
    dispatch(getInvoiceSummary());
  }, []);

  function getItemStatus(status) {
    if (status === "-2") return "Draft";
    if (status === "-1") return "Pending";
    if (status === "1") return "Approved";
    if (status === "2") return "Paid";
    if (status === "3") return "Parked";
    if (status === "4") return "Posted";
    if (status === "5") return "Paid";
  }

  // const invoiceList =
  //   invoices && invoices.data?.map((item: any) => {
  //     return {
  //       ...item,
  //       invoice_date: format(new Date(item.invoice_due_date), 'dd.MM.yyyy'),
  //       vendor_name: item.vendor_description,
  //       po_number: item.purchase_order_code,
  //       invoice_number: item.invoice_code || '',
  //       invoice_amount: item.total_invoice_amount,
  //       invoice_status: getItemStatus(item.invoice_status),
  //     }
  //   })

  function getBtnClass(status) {
    if (status === "-3") return "reject";
    if (status === "-2") return "draft";
    if (status === "-1") return "pending";
    if (status === "1") return "approved";
    if (status === "2") return "paid";
    if (status === "3") return "posted";
    if (status === "4") return "posted";
    if (status === "5") return "paid";
  }

  const invoiceList =
    invoices &&
    invoices.data?.map((item: any) => {
      return {
        ...item,
        invoice_date: item.invoice_due_date,
        description: item.company_description,
        currency: item.currency_iso,
        invoice_number: item.invoice_code || "",
        invoice_amount: formatCurrency(item.total_invoice_amount),
        invoice_status: getItemStatus(item.invoice_status),
        id: item.id,
        btnClass: getBtnClass(item.invoice_status)
      };
    });

  const onSubmit = (vals) => {
    dispatch(getInvoices(page, perpage, search, undefined, vals.date_from, vals.date_to, vals.purchase_order_code, undefined, undefined, vals.invoice_code, vals.supplier_invoice_number));
  };

  const handleClear = () => {
    dispatch(getInvoices());
    reset();
  };

  return (
    <div id="dashboard-page">
      <div className="container">
        {modals && <CreateInvoice modalText={whichModal} closeModal={closeModal} />}
        <div className="quick-stats mb-5">
          <div className="d-flex align-items-center mb-3">
            {/* <h4 className="page-title mr-2 ">Stakle’s Ratings</h4> */}
            <h4 className="page-title mr-2 ">
              {user?.vendor_description} | {removeZero(user?.vendor_code)}
            </h4>
            <Star stars={user && user.overall_ratings} disabled={true} />
          </div>

          <div className="row mt-2">
            <StatsCard statsNumber={summary?.overall?.total_invoices || "0"} text="Total Invoices " classes="stats-icon-orange" image={Contracts} />

            <StatsCard statsNumber={summary?.unpaid?.total_invoices || "0"} text="Unpaid Invoices" classes="stats-icon-folder" image={Invoicedoc} />

            <StatsCard statsNumber={summary?.paid?.total_invoices || "0"} text="Paid Invoices" classes="stats-icon-green" image={docsGreen} />

            <StatsCard image={Icon} classes="middle-img" centerText="Create Invoice" onClick={uploadInvoice} />
          </div>
        </div>
        <h4 className="page-title mb-2">Recent Documents</h4>
        {showFilter && (
          <FilterCard handleSubmit={handleSubmit(onSubmit)} clear={handleClear}>
            <div className="row">
              <div className="col col-4 mb-3">
                <label style={{ display: "block" }}>Start Date</label>
                <input
                  type="date"
                  name="date_from"
                  className="mt-3"
                  placeholder="Start Date"
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  ref={register({ required: false })}
                />
              </div>
              <div className="col col-4 mb-3">
                <label style={{ display: "block" }}>End Date</label>
                <input
                  type="date"
                  name="date_to"
                  className="mt-3"
                  placeholder="End Date"
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  ref={register({ required: false })}
                />
              </div>
              <div className="col col-4 mb-3">
                <label style={{ display: "block" }}>Purchase Order Code</label>
                <input
                  type="text"
                  name="purchase_order_code"
                  className="mt-3"
                  placeholder="Purchase Order Code"
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  ref={register({ required: false })}
                />
              </div>
              <div className="col col-4 mb-3">
                <label style={{ display: "block" }}>SAP Invoice</label>
                <input
                  type="text"
                  name="invoice_code"
                  className="mt-3"
                  placeholder="SAP Invoice"
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  ref={register({ required: false })}
                />
              </div>
              <div className="col col-4 mb-3">
                <label style={{ display: "block" }}>Supplier Invoice Number</label>
                <input
                  type="text"
                  name="supplier_invoice_number"
                  className="mt-3"
                  placeholder="Supplier Invoice Number"
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  ref={register({ required: false })}
                />
              </div>
            </div>
          </FilterCard>
        )}
        <div className="search-field mt-3">
          <SearchIcon placeholder="Search for Invoice No." boxClasses="mb-3" textRef={inputRef} value={search} onChange={handleChange} />
          <Dropdown perPage={perpage} action={handleNewPage} />
          <div>
            <button
              style={{
                padding: "5px 10px",
                borderRadius: "10px",
                background: "#FFFFFF",
                color: "#737A91",
                border: "1px solid #DFE2E6",
                fontSize: "18px",
                marginLeft: "1rem"
              }}
              onClick={() => setShowFilter(!showFilter)}
            >
              <img src={filterIcon} alt="icon" className="icon-image" />
              <span
                style={{
                  paddingLeft: "1rem"
                }}
              >
                {!showFilter ? "Filters" : "Hide Filters"}
              </span>
            </button>
          </div>
        </div>
        <DataTable columns={documentsColumns} data={invoiceList} onClick={(e, item) => history.push({ pathname: `/supplier/invoice/${item.id}` })} />
        <Pagination page={page} lastPage={invoices?.pagination?.lastPage} paginate={paginate} total={invoices?.pagination?.total} handlePageChange={handlePageChange} perPage={perpage} />
      </div>
    </div>
  );
};

export default Invoice;
