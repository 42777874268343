import StatsCard from "components/StatsCard";
import React, { useCallback, useState } from "react";
import "../styles.scss";
import PeopleIcon from "assests/icons/buyer/people-icon.svg";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import SearchIcon from "components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { departmentColumn, departmentData } from "../data";
import CreateOrEditDepartment from "components/ModalBuyer/CreateOrEditDepartment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { getDepartments, deleteDepartment } from "store/slice/BuyerSlice/DepartmentSlice/DepartmentSlice";
import useSearch from "hooks/useSearch";
import Pagination from "components/Pagination";
import { getCompaniesEntityList } from "store/slice/CompaniesSlice/CompaniesSlice";
import { toastPopUp } from "utils/utils";
import { checkPermission } from "utils/utils";

const DepartmentLog = () => {
  //State
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [modal, setModal] = useState<boolean>(false);
  const [singleDepartmentData, setSingleDepartmentData] = useState<any>(null);

  //Redux
  const dispatch = useDispatch<AppDispatch>();
  const { departments, loading } = useSelector((state: RootState) => state.department);
  const { role_access } = useSelector((state: RootState) => state.user?.user);

  //Hook
  const { search, inputRef, handleChange, searched } = useSearch(getDepartments, perPage);

  //Show Number for PerPage Request
  const handleNewPage = (pageNo: number, page: number = 1) => {
    setPerPage(pageNo);
    setPage(1);
    dispatch(getDepartments(page, pageNo, search));
  };

  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, perPage: number) => {
      setPage(page);
      dispatch(getDepartments(page, perPage, search));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, perPage: number) => {
      setPage(pageNo);
      dispatch(getDepartments(pageNo, perPage, search));
    },
    [search]
  );

  //Onmout Requests
  useEffect(() => {
    // dispatch(getDepartments(page, perPage));
    dispatch(getDepartments(page, perPage));
    dispatch(getCompaniesEntityList());
  }, []);

  // setpage to the first page when a searched executed
  useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  const closeModal = () => {
    setModal((prev) => !prev);
    setSingleDepartmentData(null);
  };

  //Edit DepartmentData
  const editDepartmentData = (id: string, depart_name: string, depart_code: string, entity_code: string, hod_code) => {
    setSingleDepartmentData({ id, depart_name, depart_code, entity_code, hod_code });
    setModal((prev) => !prev);
  };

  //  delete
  const deleteDepartment2 = (name: string, id: string) => {
    const payload = { department_id: id };
    toastPopUp(
      () => {
        dispatch(
          deleteDepartment(payload, () => {
            dispatch(getDepartments(page, perPage));
          })
        );
      },
      "Delete",
      "Cancel",
      "Delete the entity",
      name,
      "red"
    );
  };

  const departmentList =
    departments &&
    departments?.data?.map((department) => {
      return {
        id: department.id,
        department_description: department.department_description,
        department_code: department.department_code,
        company_description: department.company_description,
        location_description: department.location_description ?? "N/A",
        hod: department.hod_lastname ? `${department.hod_lastname} ${department.hod_othernames}` : "N/A",
        edit: () =>
          checkPermission(role_access, "SETUP_INDEX") &&
          editDepartmentData(department.id, department.department_description, department.department_code, department.company_id, department.hod_user_id),
        deleteDepartment4: () => checkPermission(role_access, "SETUP_INDEX") && deleteDepartment2(department.department_description, department.id)
      };
    });

  return (
    <div id="settings-location-data">
      {modal && <CreateOrEditDepartment closeModal={closeModal} singleDepartmentData={singleDepartmentData} />}
      <div className="container mt-3">
        <div className="row mt-3 mb-5">
          <StatsCard statsNumber={departments?.count ?? 0} text="Departments" classes="stats-icon" image={PeopleIcon} iconBackgroundColor="rgba(45, 156, 219, 0.1)" />
          <StatsCard image={AddIcon} classes="middle-img" centerText="Create Department" onClick={closeModal} />
        </div>

        <div className="d-flex  mb-3">
          <SearchIcon placeholder="Search for Department Name, Location" boxClasses="" textRef={inputRef} value={search} onChange={handleChange} />
          <Dropdown perPage={perPage} action={handleNewPage} />
        </div>
        <DataTable columns={departmentColumn} data={departmentList} loading={loading} />
        {departments?.count > 10 ? (
          <Pagination page={page} lastPage={departments?.pagination?.lastPage} paginate={paginate} total={departments?.pagination?.total} handlePageChange={handlePageChange} perPage={perPage} />
        ) : null}
      </div>
    </div>
  );
};

export default DepartmentLog;
