import Button from "components/Button";
import StarRating from "components/Star";
import stakleLogo from "../../../../assests/new/Group-833.svg";
import greenVerified from "../../../../assests/icons/supplier/green-verified.svg";
import grayVerified from "../../../../assests/icons/supplier/gray-verified.svg";
import document from "../../../../assests/icons/supplier/document.svg";
import greenDocument from "../../../../assests/icons/Group.svg";
import downloadArrow from "../../../../assests/icons/buyer/download-svgrepo-com (1) 1.svg";
import Icon from "assests/icons/statsCard/Vector (4).png";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { confirmInvoice, deleteLineItems, getInvoice, updateInvoice, uploadLogo } from "store/slice/BuyerSlice/Invoice/InvoiceSlice";
import { useParams } from "react-router";
import { AppDispatch, RootState } from "store/store";

import { formatCurrency, isValidURL, toastPopUp } from "utils/utils";
import NotesModal from "components/ModalBuyer/NotesModal";
import FileUploaderBox from "components/FileUploaderBox";
import { useForm } from "react-hook-form";
import { ArrowSvg } from "components/SVG/arrowSvg";
import { NewTabIcon } from "components/SVG/newTabIcon";
import ViewPdf from "components/ModalSupplier/ViewPdF";
import { Document, Page, pdfjs } from "react-pdf";
import { uploadFile } from "store/slice/BuyerSlice/CompanyDataSlice/CompanyDataSlice";
import CreateLineItem from "components/ModalBuyer/CreateLineItem/CreateLineItem";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
interface Props {
  invoiceAction?: boolean;
}

const SingleInvoice = ({ invoiceAction = false }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  let { id }: any = useParams();

  const initialState = {
    item_invoice_amount: null,
    line_item_description: "",
    line_item_quantity: null,
    line_item_tax_value: null,
    invoice_id: "",
    open: false,
    isEdit: false,
    id: ""
  };

  const [notes, setNotes] = useState({
    noteval: "",
    open: false
  });
  const [uploadDoc, setUploadDoc] = useState({
    name: "",
    img: ""
  }) as any;
  const [showUploadDoc, setShowUploadDoc] = useState(false);
  const [showFullPdf, setshowFullPdf] = useState(false);
  const [numPages, setNumPages] = useState(null) as any;
  const [pageNumber, setPageNumber] = useState(1);
  const [logo, setLogo] = useState({ img: "", name: "" });
  const [lineItems, setlineItem] = useState(initialState);
  const [uploadErr, setUploadErr] = useState<boolean>(false);

  const { register, handleSubmit, errors, setValue } = useForm<FormData>({ mode: "onBlur" });
  const { invoice, isLoading } = useSelector((state: RootState) => state.invoice);
  const uploading = useSelector((state: RootState) => state.companiesData.isLoading);

  useEffect(() => {
    dispatch(getInvoice(id));
  }, []);

  useEffect(() => {
    if (invoice?.invoice_document_url) {
      const { invoice_document_url } = invoice;
      setUploadDoc({
        img: invoice_document_url,
        name: ""
      });
    }
    if (invoice?.note) {
      setNotes({ ...notes, noteval: invoice.note });
    } else setNotes({ ...notes, noteval: "" });
  }, [invoice]);

  useEffect(() => {
    if (uploadDoc.img !== "") {
      setUploadErr(false);
    }
  }, [uploadDoc]);

  function getItemStatus(status) {
    if (status === "-2") return "Draft";
    if (status === "-1") return "Pending";
    if (status === "1") return "Approved";
    if (status === "2") return "Paid";
    if (status === "3") return "Parked";
    if (status === "4") return "Posted";
    if (status === "5") return "Paid";
  }

  const updateNote = (noteval) => {
    setNotes({
      noteval,
      open: false
    });
    // localStorage.setItem("notes", noteval) as any;
  };

  // if (notes.noteval !== "" && notes.noteval !== invoice?.note && invoice?.invoice_status === "-2") {
  //   console.log(notes.noteval !== "" && notes.noteval !== invoice?.note && invoice?.invoice_status === "-2");
  //   window.onbeforeunload = function () {
  //     return "Are you really want to perform the action? All Unsaved data will be lost";
  //   };
  // }

  // const noteStorage = localStorage.getItem("notes") as any;

  // useEffect(() => {
  //   if (noteStorage) {
  //     setNotes({ ...notes, noteval: noteStorage });
  //   }
  // }, [noteStorage]);

  //   useEffect(() => {
  //     if (invoice?.invoice_document_url) {
  //       setUploadDoc({ ...uploadDoc, img: invoice?.invoice_document_url });
  //     }
  //   }, [invoice]);

  // Handle upload for files
  const handleFile = (e) => {
    // setIsUploading(true);
    const reader = new FileReader();
    reader.readAsDataURL(e);
    reader.onloadend = () => {
      let read: any = reader.result;
      setPageNumber(1);
      setNumPages(null);
      setUploadDoc({
        img: read,
        name: e.name
      });
    };
    // let files;
    // if (e.dataTransfer) {
    //   files = e.dataTransfer.files;
    // } else if (e.target) {
    //   files = e.target.files;
    // }
    // const reader = new FileReader() as any;
    // reader.onloadend = () => {
    //   setUploadDoc(reader.result);
    // };
    // if (e.target.files[0]) reader.readAsDataURL(files[0]);
  };

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const uploadSuccess = (data) => {
    const payload = {
      id,
      invoice_due_date: invoice?.invoice_due_date,
      total_tax_amount: invoice?.total_tax_amount,
      payment_terms: invoice?.payment_terms,
      invoice_document_url: data,
      note: notes.noteval,
      supplier_invoice_number: invoice?.supplier_invoice_number
    };
    dispatch(updateInvoice(payload, () => dispatch(getInvoice(id))));
  };

  const updateLogo = (data) => {
    const payload = {
      logo_url: data,
      vendor_id: invoice?.vendor_id
    };
    dispatch(uploadLogo(payload, () => dispatch(getInvoice(id))));
  };

  const submit = () => {
    toastPopUp(
      () => {
        if (uploadDoc.img && !isValidURL(uploadDoc.img)) {
          const { img, name } = uploadDoc;
          const payload = {
            base64_data: img.replace("data:", "").replace(/^.+,/, ""),
            file_extension: name.split(".").pop()
          };
          dispatch(uploadFile(payload, uploadSuccess));
        } else {
          const payload = {
            id,
            invoice_due_date: invoice?.invoice_due_date,
            total_tax_amount: invoice?.total_tax_amount,
            payment_terms: invoice?.payment_terms,
            invoice_document_url: uploadDoc.img,
            note: notes.noteval,
            supplier_invoice_number: invoice?.supplier_invoice_number
          };
          dispatch(updateInvoice(payload, () => dispatch(getInvoice(id))));
        }
        if (logo) {
          const { img, name } = logo;
          const payload = {
            base64_data: img.replace("data:", "").replace(/^.+,/, ""),
            file_extension: name.split(".").pop()
          };
          dispatch(uploadFile(payload, updateLogo));
        }
      },
      "Submit",
      "Cancel",
      "Save Invoice?",
      "",
      "green"
    );
  };

  const confirm = () => {
    toastPopUp(
      () => {
        dispatch(confirmInvoice({ invoice_id: id }, () => dispatch(getInvoice(id))));
      },
      "Confirm",
      "Cancel",
      "Confirm Invoice? This process is irreversible",
      "",
      "green"
    );
  };

  const handleMediaUpload = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader() as any;
    reader.onloadend = () => {
      // setLogo(reader.result);
      setLogo({
        img: reader.result,
        name: files[0].name
      });
    };
    if (e.target.files[0]) reader.readAsDataURL(files[0]);
  };

  const updateLineItem = (item) => {
    const { id, item_invoice_amount, line_item_description, line_item_quantity, line_item_tax_value } = item;
    setlineItem({
      ...lineItems,
      id,
      item_invoice_amount,
      line_item_description,
      line_item_quantity,
      line_item_tax_value,
      isEdit: true,
      open: true
    });
  };

  const deleteLineItem = (item) => {
    console.log(item.id);
    toastPopUp(
      () => {
        dispatch(deleteLineItems({ id: item.id }, () => dispatch(getInvoice(invoice?.id))));
      },
      "Delete",
      "Cancel",
      "Delete Line Item? This process is irreversible",
      "",
      "red"
    );
  };

  const subTotal =
    invoice?.invoice_items &&
    invoice?.invoice_items?.reduce((acc, current) => {
      return acc + Number(current.item_invoice_amount);
    }, 0);
  const subTotalGR =
    // invoice?.gr_items.length > 0 &&
    invoice?.gr_items?.reduce((acc, current) => {
      return acc + Number(current.gr_amount);
    }, 0);

  const subTotalDelivery =
    // invoice?.gr_items.length > 0 &&
    invoice?.gr_items?.reduce((acc, current) => {
      return acc + Number(current.gr_delivery_cost);
    }, 0);

  function getItemStatus_(status) {
    if (status === "-3") return "Rejected";
    if (status === "-2") return "Payment Proposal Created";
    if (status === "-1") return "Pending Approval";
    if (status === "1") return "Approved";
    if (status === "2") return "Paid";
    if (status === "3") return "Parked";
    if (status === "4") return "Posted";
    if (status === "5") return "Paid";
  }
  function getItemStatus_bank(status) {
    // if (status === "-3") return "Rejected";
    if (status === "-2") return "In Bank Selection";
    if (status === "-1") return "Authoriser Approval";
    if (status === "1") return "Approved";
    // if (status === "2") return "Paid";
    // if (status === "3") return "Parked";
    // if (status === "4") return "Posted";
  }

  function getBtnClass(status) {
    if (status === "-3") return "reject";
    if (status === "-2") return "draft";
    if (status === "-1") return "pending";
    if (status === "1") return "approved";
    if (status === "2") return "paid";
    if (status === "3") return "posted";
    if (status === "4") return "posted";
    if (status === "5") return "paid";
  }

  // console.log(getBtnClass(invoice?.invoice_status));

  return (
    <div id="singleinvoice-page_">
      {lineItems.open && <CreateLineItem closeModal={() => setlineItem(initialState)} loading={isLoading} id={invoice?.id} lineItems={lineItems} />}
      {notes.open && <NotesModal closeModal={() => setNotes({ open: false, noteval: "" })} updateNote={updateNote} notes={notes.noteval} />}

      {showFullPdf && <ViewPdf img={uploadDoc.img} closeModal={() => setshowFullPdf(false)} />}

      <div className="container">
        <div className="quick-stats mb-2">
          <div className="d-flex align-items-center mb-3">
            <h4 className="page-title mr-2 ">{invoice?.vendor_description}</h4>
            <StarRating stars={invoice && invoice?.vendor?.overall_ratings} disabled={true} />
          </div>
        </div>

        <div className="header-container w-100 justify-content-between">
          <div>
            <span className="title">Invoice Status</span>
            {invoiceAction ? <span className="not-saved">Not Saved</span> : <span className={` ml-2 status ${getBtnClass(invoice?.invoice_status)}`}>{getItemStatus(invoice?.invoice_status)}</span>}
          </div>
          {/* <button type="button" id="download">
            Download
          </button> */}
          {invoice?.invoice_status === "-2" && (
            <div className="d-flex justify-content-center align-items-center mb-3" style={{ columnGap: "2rem" }}>
              <button type="button" id="download" onClick={submit}>
                {isLoading || uploading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2" style={{ width: "inherit", height: "inherit" }}></div> : "Save as Draft"}
              </button>
              <button
                type="submit"
                disabled={(invoice?.invoice_type === "Purchase Order" && subTotalGR === 0) || (invoice?.invoice_type !== "Purchase Order" && subTotal === 0) || invoice?.invoice_document_url === null}
                style={{
                  padding: "8px 30px",
                  background:
                    (invoice?.invoice_type === "Purchase Order" && subTotalGR === 0) || (invoice?.invoice_type !== "Purchase Order" && subTotal === 0) || invoice?.invoice_document_url === null
                      ? "#D8D8D8"
                      : "#00B0A5",
                  borderRadius: "10px",
                  color: "white"
                }}
                onClick={confirm}
              >
                {isLoading || uploading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2" style={{ width: "inherit", height: "inherit" }}></div> : "Confirm"}
              </button>
            </div>
          )}
        </div>

        <div className="main-container">
          <div className="first-col">
            <div>
              <div className="first-section">
                <div className="first-section__first-col">
                  <p className="tag mb-3">INVOICE #</p>
                  <p className="id-number">INV-{invoice?.id}</p>
                  <p className="id-number">{invoice?.invoice_code}</p>
                  <p className="date">{invoice?.invoice_due_date}</p>
                </div>
                <div>
                  <label htmlFor="file-input" className="mb-0">
                    <img
                      src={(invoice?.logo_url && invoice?.logo_url) || logo.img || stakleLogo}
                      alt="stakle-logo"
                      style={{ maxHeight: "3rem", maxWidth: "7rem", width: "auto" }}
                      className="mr-3 cursor-pointer"
                    />
                  </label>
                  <input type="file" id="file-input" onChange={(e) => handleMediaUpload(e)} accept=".png, .jpg, .jpeg" style={{ display: "none" }} disabled={invoice?.invoice_status !== "-2"} />
                  {/* <p style={{ fontSize: "12px" }}>click image to change</p> */}
                  {invoice?.invoice_status === "-2" && <p style={{ fontSize: "12px" }}>click image to change</p>}
                </div>
              </div>

              <div className="second-section justify-content-between">
                <div className={`second-section__first-col ${invoiceAction && "not-saved-first-second-col"} w-100`}>
                  <h6 className="address-header">BILL TO:</h6>
                  <ul className="address-details">
                    <li className="industry-name">{invoice?.company_description}</li>
                    <li className="address">{invoice?.company_address || "N/A"}</li>
                    <li className="phone-number">{invoice?.company_phone_number || "N/A"}</li>
                  </ul>
                </div>

                <div className={`second-section__second-col ${invoiceAction && "not-saved-first-second-col"} w-100`}>
                  <h6 className="address-header">BILL FROM: </h6>
                  <ul className="address-details">
                    {/* <li className="industry-name">Stakle Solutions</li>
                  <li className="address">Area 5, Iperin gate, Opic Industrial Estate, Agbara, Ogun state, Nigeria. </li>
                  <li className="phone-number">+234 (1) 4633260-7</li> */}
                    <li className="industry-name">{invoice?.vendor?.vendor_description || "N/A"}</li>
                    <li className="address">{invoice?.vendor?.street_name || "N/A"}</li>
                    <li className="phone-number">{invoice?.vendor?.phone_number || "N/A"}</li>
                  </ul>
                </div>
              </div>

              <div className="third-section">
                <div className="third-section__first-col">
                  <h6 className="list-header">Terms & Conditions</h6>
                  <ul className="terms-details">
                    {/* <li className="terms-date">Payment Terms : 30 Days</li>
                  <li className="ref-number">Reference PO : 4510092736</li> */}
                    <li className="terms-date">Payment Terms : {invoice?.payment_terms || "N/A"} Days</li>
                    <li className="ref-number">Reference PO : {invoice?.purchase_order?.[0]?.purchase_order_code || "N/A"}</li>
                    <li className="ref-number">Supplier Invoice No. : {invoice?.supplier_invoice_number || "N/A"}</li>
                    <li className="ref-number">Currency : {invoice?.currency_description || "N/A"}</li>
                  </ul>
                </div>
              </div>

              {invoice?.invoice_type === "Purchase Order" && (
                <div className="purchase-table mt-4">
                  <table>
                    <tbody>
                      <tr className="table-header" style={{ color: "#3d59b7", backgroundColor: "rgba(119, 157, 207, 0.1)" }}>
                        <th>Item</th>
                        <th>Description</th>
                        <th>Quantity</th>
                        {/* <th>Price</th> */}
                        <th>Amount</th>
                      </tr>
                      {invoice?.gr_items?.map((item, index) => (
                        <tr className="table-data-row" key={index}>
                          <td>{index + 1}</td>
                          <td>{item?.material_description}</td>
                          <td>{item?.overall_quantity_delivered}</td>
                          {/* <td>339, 900</td> */}
                          <td>{formatCurrency(item?.gr_amount)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="total-tax">
                    <p className="tax-total">
                      <span>Sub-Total</span>
                      <span>Delivery Cost</span>
                      <span>Tax</span>
                      <span>Total</span>
                    </p>

                    <p className="prices">
                      {/* <span>
                        {" "}
                        <b> {invoice && invoice?.freight_cost ? formatCurrency(Number(subTotalGR) - Number(invoice?.freight_cost)) : formatCurrency(subTotalGR)} </b>{" "}
                      </span>
                      <span>
                        {" "}
                        <b> {invoice && invoice?.freight_cost ? formatCurrency(invoice?.freight_cost) : "0"} </b>{" "}
                      </span> */}
                      <span>
                        {" "}
                        <b> {formatCurrency(Number(subTotalGR) - Number(subTotalDelivery))} </b>{" "}
                      </span>
                      <span>
                        {" "}
                        <b> {formatCurrency(Number(subTotalDelivery))} </b>{" "}
                      </span>
                      <span>
                        <b>{formatCurrency(Number(invoice?.total_tax_amount))}</b>
                      </span>
                      <span>
                        {" "}
                        <b> {formatCurrency(Number(invoice?.total_invoice_amount) + Number(invoice?.total_tax_amount))}</b>
                      </span>
                    </p>
                  </div>
                </div>
              )}

              {invoice?.invoice_type !== "Purchase Order" && (
                <div className="purchase-table mt-4">
                  <table>
                    <tbody>
                      <tr className="table-header" style={{ color: "#3d59b7", backgroundColor: "rgba(119, 157, 207, 0.1)" }}>
                        <th>Item</th>
                        <th>Description</th>
                        <th>Quantity</th>
                        {/* <th>Price</th> */}
                        <th>Amount</th>
                        <th></th>
                      </tr>
                      {invoice?.invoice_items?.map((item, index) => (
                        <tr className="table-data-row" key={index}>
                          <td>{index + 1}</td>
                          <td>{item?.line_item_description}</td>
                          <td>{item?.line_item_quantity}</td>
                          <td>{formatCurrency(item?.item_invoice_amount / item?.line_item_quantity)}</td>
                          <td>
                            <div className="d-flex justify-content-between">
                              {(getItemStatus(invoice?.invoice_status) === "Draft" || getItemStatus(invoice?.invoice_status) === "Pending") && (
                                <EditIcon fontSize="small" className="cursor-pointer" onClick={() => updateLineItem(item)} />
                              )}
                              {getItemStatus(invoice?.invoice_status) === "Draft" && <DeleteIcon fontSize="small" className="cursor-pointer" onClick={() => deleteLineItem(item)} />}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {invoice?.invoice_status === "-2" && (
                    <Button
                      onClick={() => setlineItem({ ...lineItems, open: true })}
                      label="Add Line Item"
                      btnType="btn-primary"
                      boxClasses="mt-4 mb-2 "
                      xtraClass="btm-btn px-3 py-2"
                      btnStyle="fit-content"
                    />
                  )}
                  <div className="total-tax">
                    <p className="tax-total">
                      <span>Sub-Total</span>
                      <span>Tax</span>
                      <span>Total</span>
                    </p>

                    <p className="prices">
                      <span>
                        {" "}
                        <b> {formatCurrency(subTotal)}</b>
                      </span>
                      <span>
                        {" "}
                        <b> {formatCurrency(Number(invoice?.total_tax_amount))}</b>
                      </span>
                      <span>
                        {" "}
                        <b>{formatCurrency(subTotal + Number(invoice?.total_tax_amount))}</b>
                      </span>
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div className="last-section">
              {!notes.noteval ? (
                <div className="d-flex px-3 py-2" style={{ columnGap: "1rem", backgroundColor: "rgba(119, 157, 207, 0.1)" }}>
                  <p className="mr-5">
                    <span className="mr-2">Notes: </span>
                    {/* <img onClick={() => setNotes({ ...notes, open: true })} src={Icon} alt="add-circle" className="cursor-pointer" /> */}
                    {invoice?.invoice_status === "-2" && <img onClick={() => setNotes({ ...notes, open: true })} src={Icon} alt="add-circle" className="cursor-pointer" />}
                  </p>
                </div>
              ) : (
                <>
                  <div className="d-flex px-3 py-2" style={{ columnGap: "1rem", backgroundColor: "rgba(119, 157, 207, 0.1)" }}>
                    <h6>Notes: </h6>
                    {/* <img onClick={() => setNotes({ ...notes, open: true })} src={Icon} alt="add-circle" className="cursor-pointer" /> */}
                    {invoice?.invoice_status === "-2" && <img onClick={() => setNotes({ ...notes, open: true })} src={Icon} alt="add-circle" className="cursor-pointer" />}
                  </div>
                  <p className="notes-para">{notes.noteval}</p>
                </>
              )}
            </div>

            <div className="last-section">
              <div className="d-flex px-3 py-2" style={{ columnGap: "1rem", backgroundColor: "rgba(119, 157, 207, 0.1)" }}>
                <h5>Payment Details </h5>
              </div>
              <div className="d-flex payment py-5 justify-content-between">
                <p>
                  <span>Payment Date:</span>
                  {invoice?.payment_proposal?.approved_on}
                </p>
                <p>
                  <span>Payment Reference:</span>
                  {invoice?.payment_proposal?.payment_run}
                </p>
                <p>
                  Download Payment confirmation: <img src={downloadArrow} alt="" /> {invoice?.payment_proposal?.payment_file_ref}
                </p>
              </div>
            </div>

            <div className="last-section">
              <div className="d-flex px-3 py-2" style={{ columnGap: "1rem", backgroundColor: "rgba(119, 157, 207, 0.1)" }}>
                <h5>Transaction History </h5>
              </div>
              <div className="payment justify-content-between w-100">
                {invoice?.invoice_posting && (
                  <div className="w-100 mb-3">
                    <div className="">
                      {/* <div className="col-md-12 col-xs-12"> */}
                      <div className="border-container justify-content-between w-100 d-flex">
                        <span className="d-flex">
                          <div className="px-3" style={{ width: "fit-content" }}>
                            <p className="">Invoice posting</p>
                          </div>
                          <div className="px-3" style={{ width: "fit-content" }}>
                            {/* <p className="">{req.requisition_code ? req?.requisition_code + " - " + req.pr_item_id : "N?A"}</p> */}
                            <p className="">Posting Date : {`${invoice?.invoice_posting?.posting_date}`}</p>
                          </div>
                        </span>

                        <span className="d-flex">
                          <div className="px-3" style={{ width: "fit-content" }}>
                            <p className="">Posted By : {`${invoice?.invoice_posting?.posted_by}`}</p>
                          </div>
                          <div className="px-3" style={{ width: "fit-content" }}>
                            {/* <p className="">Creation Date : {req?.created_at ? format(new Date(req?.created_at), "dd.MM.yyyy") : ""}</p> */}
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                {invoice?.payment_proposal && (
                  <div className="w-100 mb-3">
                    <div className="">
                      {/* <div className="" style={{ width: " max-content", overflowX: "scroll" }}> */}
                      {/* <div className="col-md-12 col-xs-12"> */}
                      <div className="border-container px-3 justify-content-between w-100">
                        {/* <div className="d-flex justify-content-between">
                          <span className="d-flex">
                            <div className="px-3" style={{ width: "fit-content" }}>
                              <p className="">Payment Run</p>
                            </div>
                            <div className="px-3" style={{ width: "fit-content" }}>
                              <p className="">Posting Date : {`${invoice?.payment_proposal?.run_date}`}</p>
                            </div>
                          </span>

                          <span className="d-flex">
                            <div className="px-3" style={{ width: "fit-content" }}>
                              <p className="">Status : {`${getItemStatus_(invoice?.payment_proposal?.proposal_status)}`}</p>
                            </div>
                            <div className="px-3" style={{ width: "fit-content" }}>
                              <p className="">Approval Date : {format(new Date(invoice?.payment_proposal?.approved_on), "l")}</p>
                            </div>
                          </span>
                        </div> */}

                        <table className="w-100">
                          <tr>
                            <th>
                              {" "}
                              <p className="">Payment Run</p>
                            </th>
                            <th>
                              {" "}
                              <p className="">Posting Date : {`${invoice?.payment_proposal?.run_date}`}</p>
                            </th>
                            <th>
                              {" "}
                              <p className="">Status : {`${getItemStatus_(invoice?.payment_proposal?.proposal_status)}`}</p>
                            </th>
                            <th>
                              {" "}
                              <p className="">Approval Date : {moment(invoice?.payment_proposal?.approved_on).format("l")}</p>
                            </th>
                          </tr>

                          <tr>
                            <th> {/* <p className="">Posting Date : {`${invoice?.payment_proposal?.run_date}`}</p> */}</th>
                            <th>
                              {" "}
                              <p className="">Run ID : {`${invoice?.payment_proposal?.proposal_code}`}</p>
                            </th>
                            <th>
                              {" "}
                              <p className="">Amount : {`${formatCurrency(invoice?.payment_proposal?.total_proposal_amount)}`}</p>
                            </th>
                            <th>
                              {" "}
                              <p className="">Deducted Wht : {`${invoice?.invoice_posting?.witholding_tax}`}</p>
                            </th>
                          </tr>
                        </table>

                        {/* <div className="d-flex justify-content-between">
                          <span className="d-flex flex-grow-1 justify-content-center">
                            <div className="px-3" style={{ width: "fit-content" }}>
                              <p className=""></p>
                            </div>
                            <div className="px-3" style={{ width: "fit-content" }}>
                              <p className="">Run ID : {`${invoice?.payment_proposal?.proposal_code}`}</p>
                            </div>
                          </span>
                          <span className="d-flex">
                            <div className="px-3" style={{ width: "fit-content" }}>
                              <p className="">Amount : {`${formatCurrency(invoice?.payment_proposal?.total_proposal_amount)}`}</p>
                            </div>
                            <div className="px-3" style={{ width: "fit-content" }}>
                              <p className="">Deducted Wht : {`${invoice?.invoice_posting?.witholding_tax}`}</p>
                            </div>
                          </span>
                        </div> */}
                      </div>
                    </div>
                  </div>
                )}

                {invoice?.bank_proposal && (
                  <div className="w-100 mb-3">
                    <div className="w-100" style={{ width: " max-content", overflowX: "scroll" }}>
                      <div className="border-container justify-content-between w-100 d-flex">
                        <span className="d-flex">
                          <div className="px-3" style={{ width: "fit-content" }}>
                            <p className="">Bank Processing</p>
                          </div>
                          <div className="px-3" style={{ width: "fit-content" }}>
                            <p className="">Creation Date : {`${moment(invoice?.bank_proposal?.created_at).format("l")}`}</p>
                          </div>
                        </span>

                        <span className="d-flex">
                          <div className="px-3" style={{ width: "fit-content" }}>
                            <p className="">Status : {`${getItemStatus_bank(invoice?.bank_proposal?.bank_proposal_status)}`}</p>
                          </div>
                          {invoice?.bank_proposal?.bank_proposal_status === "1" && (
                            <div className="px-3" style={{ width: "fit-content" }}>
                              <p className="">Approval Date : {`${moment(invoice?.bank_proposal?.approved_on).format("l")}`}</p>
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="second-col">
            {/* <h5>Comments</h5>

            <hr />
            <form className="comment-container">
              <textarea rows={4} className="comments" />
              <Button type="submit" label="Send" btnType="btn-skeleton" xtraClass="btn-padding px-5 ml-auto" boxClasses="mt-3 mb-4 mr-2"></Button>
            </form> */}
            {invoice?.invoice_status === "-2" && (
              <FileUploaderBox
                label="Upload phyiscal invoice"
                customRef={register({
                  // required: !files.some((file: any) => file.field_name === field.field_name) && requiredForm
                })}
                handleFile={(e) => handleFile(e)}
                boxClasses="mt-3"
                fileName="upload_doc"
                //   error={errors[field.field_name] && "This field is required"}
                //   fileNameRef={register({
                //     // required: { value: requiredForm, message: "This field is required" }
                //   })}
              />
            )}

            {uploadErr && <p className="error">This is required</p>}

            {uploadDoc.img && (
              <div>
                <div className="d-flex justify-content mb-3" style={{ border: "solid 1px grey", borderRadius: "5px" }}>
                  <span style={{ border: "solid 1px grey", flexGrow: 8, textAlign: "center" }}>{uploadDoc.name || "File Uploaded"}</span>
                  <span style={{ border: "solid 1px grey", flexGrow: 1, textAlign: "center" }} className="cursor-pointer" onClick={() => setShowUploadDoc(!showUploadDoc)}>
                    <ArrowSvg />
                  </span>
                  <span style={{ border: "solid 1px grey", flexGrow: 1, textAlign: "center" }} className="cursor-pointer" onClick={() => setshowFullPdf(true)}>
                    <NewTabIcon />
                  </span>
                </div>
                {showUploadDoc && (
                  <div className="w-100" style={{ height: "30rem" }}>
                    <div>
                      {uploadDoc?.img && isValidURL(uploadDoc?.img) ? (
                        <div>
                          <object data={uploadDoc.img} type="application/pdf" className="w-100" style={{ height: "30rem" }}>
                            <embed src={uploadDoc.img} type="application/pdf" />
                          </object>
                        </div>
                      ) : (
                        <div style={{ width: "100%", maxHeight: "20rem" }}>
                          <Document file={uploadDoc.img} onLoadSuccess={onDocumentLoadSuccess} className="w-100 pdf">
                            <Page width="300" className="pdf" pageNumber={pageNumber} />
                          </Document>
                          <div className="mt-3">
                            <p className="px-2">
                              Page {pageNumber} of {numPages}
                            </p>
                            <div className="px-2">
                              {numPages && (
                                <button onClick={() => setPageNumber(pageNumber - 1)} disabled={numPages < 2 || pageNumber === 1}>
                                  Prev
                                </button>
                              )}

                              {numPages && (
                                <button className="pl-5" onClick={() => setPageNumber(pageNumber + 1)} disabled={numPages < 2 || pageNumber === numPages}>
                                  Next
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className="invoice-status">
              <h5>Invoice Status</h5>
              <hr />
              <ul className="status-flow">
                <li className="item">
                  <p className="green-circle mr-4" style={{ borderColor: invoice?.workflow.length < 1 ? "#515551" : "#22A238" }}>
                    <span className="rigid-circle" style={{ backgroundColor: invoice?.workflow.length < 1 ? "#515551" : "#22A238" }}></span>
                    {invoice?.workflow.length < 1 ? null : <span className="pipe"></span>}
                  </p>
                  {invoice?.workflow.length < 1 && <p>Not Saved</p>}
                </li>

                {invoice?.workflow.map((item, i) => {
                  return (
                    // <li className="item" key={i}>
                    //   <p className="green-circle mr-4" style={{ borderColor: invoiceAction === true ? "#515551" : "#22A238" }}>
                    //     <img src={invoiceAction ? grayVerified : greenVerified} alt="green-verified" />
                    //     {invoiceAction ? null : <span className="pipe"></span>}
                    //     {invoiceAction ? null : <span className="upward"></span>}
                    //   </p>
                    //   <p>
                    //     {item.step_details.step_description} ({item.action_owner_othernames} {item.action_owner_lastname})
                    //   </p>
                    // </li>

                    <li className="item" key={i}>
                      <p className={`${item.approval_status === "1" ? "green-circle" : "green-circle gray-circle"} mr-4`} style={{ borderColor: item.approval_status !== "1" ? "#515551" : "#22A238" }}>
                        <img src={item.approval_status !== "1" ? grayVerified : greenVerified} alt="green-verified" />
                        {invoiceAction ? null : <span className="pipe" style={{ backgroundColor: item.approval_status === "-1" ? "rgb(111 94 94 / 90%)" : "" }}></span>}
                        {invoiceAction ? null : <span className="upward"></span>}
                      </p>
                      <p>
                        {item.step_details.step_description} ({item.action_owner_othernames} {item.action_owner_lastname})
                      </p>
                    </li>
                  );
                })}

                {/* <li className="item">
                  <p className="green-circle mr-4" style={{ borderColor: invoiceAction === true ? "#515551" : "#22A238" }}>
                    <img src={invoiceAction ? grayVerified : greenVerified} alt="green-verified" />
                    {invoiceAction ? null : <span className="pipe"></span>}
                    {invoiceAction ? null : <span className="upward"></span>}
                  </p>
                  <p>Audit</p>
                </li>

                <li className="item">
                  <p className="green-circle mr-4" style={{ borderColor: invoiceAction === true ? "#515551" : "#22A238" }}>
                    <img src={invoiceAction ? grayVerified : greenVerified} alt="green-verified" />
                    {invoiceAction ? null : <span className="pipe"></span>}
                    {invoiceAction ? null : <span className="upward"></span>}
                  </p>
                  <p>Finance Manager Approval</p>
                </li> */}

                {invoice?.workflow?.length > 0 && (
                  <li className="item">
                    {getItemStatus(invoice?.invoice_status) === "Approved" || getItemStatus(invoice?.invoice_status) === "Parked" || getItemStatus(invoice?.invoice_status) === "Posted" ? (
                      <span className="d-flex">
                        <p className="green-circle mr-4">
                          <img src={greenDocument} alt="document" /> <span className="upward"></span>
                        </p>
                        <p>Invoice Parked (SAP)</p>
                      </span>
                    ) : (
                      <span>
                        <p className="gray-circle mr-4">
                          <img src={document} alt="document" />
                        </p>
                      </span>
                    )}
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleInvoice;
