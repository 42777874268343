import React, { useEffect, useState, useRef, useCallback } from "react";
import "./styles.scss";
import StatsCard from "../../../../components/StatsCard";
import DataTable from "../../../../components/DataTable";

import { documentsColumns } from "./data";
import Bill from "../../../../assests/icons/buyer/bill.svg";
import AddIcon from "../../../../assests/icons/buyer/add-icon.svg";
import ApprovedUsers from "../../../../assests/icons/buyer/members-green.svg";
import MembersBlue from "../../../../assests/icons/buyer/members-blue.svg";
import SearchIcon from "../../../../components/SearchBar";
import { useDispatch, useSelector } from "react-redux";
// import { getHouseholdSummary, getAllHousehold } from "store/slice/HouseHoldsSlice/HouseholdSlice";
import { RootState } from "store/store";
import Pagination from "../../../../components/Pagination";
import Dropdown from "../../../../components/Dropdown";
import useSearch from "hooks/useSearch";
import useArrowDropdown from "hooks/useArrowDropdown";
// import { getCommunity } from "store/slice/CommunitySlice/communitySlice";
import Stars from "../../../../components/Star/index";

import { getVendors } from "store/slice/BuyerSlice/VendorSlice/VendorSlice";
import CreateVendor from "../../../../components/ModalBuyer/CreateVendor";
import { getCategoryVendor } from "store/slice/SummarySlice/SummarySlice";
import FilterCard from "../../../../components/FilterCard";
import { useForm } from "react-hook-form";
import filterIcon from "../../../../assests/icons/buyer/filter-icon.svg";
import { removeZero } from "utils/utils";

type Props = {};

const ListSuppliers: React.FunctionComponent<Props> = () => {
  const [page, setPage] = useState<number>(1);
  const [length, setlength] = useState<number>(10);
  const [selectComboxOption, setSelectComboxOption] = React.useState("");
  const dispatch = useDispatch();
  const [modal, setModal] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState(false);

  //Redux

  const { catVendor, isLoadingbbb } = useSelector((state: RootState) => state.summaries);

  // const { summaries } = useSelector((state: RootState) => state.summaries);
  // const { summary, households } = useSelector((state: RootState) => state.household);
  // const { community } = useSelector((state: RootState) => state.community);

  // getting params

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const business_category_name = urlParams.get("business_category");
  const business_category = urlParams.get("business_category_id");

  console.log(business_category);
  //hook
  // const { open, selectedOption, handleDropdownChange, handleAction } = useArrowDropdown();
  const { open: open2, selectedOption: selectedOption2, handleDropdownChange: handleDropdownChange2, handleAction: handleAction2, ref: dropdownRef2 } = useArrowDropdown();

  //
  const params = [selectedOption2 !== "" && selectedOption2 !== "All" ? selectedOption2 : undefined, selectComboxOption !== "" && selectComboxOption !== "All" ? selectComboxOption : undefined];
  const { search, inputRef, handleChange, searched } = useSearch(getVendors, length);
  const { register, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      vendor_code: "",
      vendor_classification: "",
      sap_sync: ""
      // to: '',
    }
  });

  // setpage to the first page when a searched executed
  useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  // get vendors
  useEffect(() => {
    dispatch(getCategoryVendor({ business_category_id: business_category }));
    // dispatch(getSummaries());
  }, []);

  //--------------------------------------------
  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, length: number) => {
      setPage(page);
      dispatch(getCategoryVendor({ business_category_id: business_category }));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, length: number) => {
      setPage(pageNo);
      dispatch(getCategoryVendor({ business_category_id: business_category, page, length, search }));
    },
    [search]
  );

  //------------------------------------------------------------
  //Show Number for length Function
  //------------------------------------------------------------
  const handleNewPage = React.useCallback(
    (pageNo: number, page = 1) => {
      setlength(pageNo);
      setPage(1);
      dispatch(getCategoryVendor({ business_category_id: business_category, page, length, search }));
    },
    [search]
  );

  //   Table data
  const vendorsTable =
    catVendor &&
    catVendor?.data?.map((data: any) => {
      // let status = "Apprroved/Active" as string;
      // if (data.vendor_status === "-2") {
      //   status = "Pending On-boarding";
      // } else if (data.vendor_status === "-1") {
      //   status = "Pending Approval";
      // }
      return {
        date: moment(data.created_at).format("L"),
        legal: data?.company_description,
        name: data.vendor_description,
        id: data?.id,
        specialization: data.business_category_description,
        data_status: data.vendor_status < 1 ? "Pending" : "Verified",
        no: data.vendor_code && removeZero(data.vendor_code),
        purchase_order_count: data?.purchase_order_count
      };
    });

  // approved vendors
  const approved = catVendor?.data.filter((item) => item.vendor_status > 0);

  const closeModal = () => {
    setModal(!modal);
  };

  const onSubmit = (vals) => {
    dispatch(getVendors(page, length, search, { vendor_code: vals.vendor_code, fetch_type: "detailed", vendor_classification: vals.vendor_classification, sap_sync: vals.sap_sync }));
  };

  const handleClear = () => {
    dispatch(getVendors(page, length, "", { fetch_type: "detailed" }));
    reset();
  };

  return (
    <div id="buyer-supplier-page">
      {modal && <CreateVendor closeModal={closeModal} />}
      <div className="container">
        <div className="d-flex align-items-center mb-3">
          <h3 className="header">{business_category_name}</h3>
        </div>
        <div className=" row mb-5">
          <StatsCard statsNumber={catVendor?.count} text="Suppliers" classes="stats-icon-orange" image={ApprovedUsers} iconBackgroundColor="rgba(0, 176, 80, 0.1)" />

          {/* <StatsCard statsNumber={summaries?.total_unapproved_vendors} text="Pending Approvals" classes="stats-icon-leaf" image={MembersBlue} iconBackgroundColor="rgba(45, 156, 219, 0.1)" />
           */}
          {/* <StatsCard statsNumber={0} text="Unpaid Invoices" classes="stats-icon-leaf" image={Bill} iconBackgroundColor="rgba(255, 65, 65, 0.1)" /> */}
          {/* <StatsCard
            image={AddIcon}
            classes="middle-img"
            centerText="Create Supplier"
            onClick={() => {
              setModal(true);
            }}
          /> */}
        </div>

        <h4 className="page-title mb-2">Supplier List</h4>
        {showFilter && (
          <FilterCard handleSubmit={handleSubmit(onSubmit)} clear={handleClear}>
            <div className="row">
              <div className="col col-4">
                <label style={{ display: "block" }}>Vendor Code</label>
                <input
                  type="text"
                  name="vendor_code"
                  className="mt-3"
                  placeholder="Vendor Code"
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  ref={register({ required: false })}
                />
              </div>
              {/* <div className="col col-4">
                <label style={{ display: "block" }}>End Date</label>
                <input
                  type="date"
                  name="end_date"
                  className="mt-3"
                  placeholder="End Date"
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  ref={register({ required: false })}
                />
              </div> */}
              <div className="col col-4">
                <label style={{ display: "block" }}>Vendor Classification </label>
                <select
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  name="vendor_classification"
                  className="mt-3"
                  ref={register({
                    required: false
                  })}
                >
                  <option value="">Select</option>
                  <option value="Foreign">Foreign</option>
                  <option value="Local">Local</option>
                </select>
              </div>

              <div className="col col-4">
                <label style={{ display: "block" }}>SAP </label>
                <select
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  className="mt-3"
                  name="sap_sync"
                  ref={register({
                    required: false
                  })}
                >
                  <option value="">Select</option>
                  <option value="1">Sync</option>
                  <option value="-1">Unsync</option>
                </select>
              </div>
            </div>
          </FilterCard>
        )}
        <div className="search-field">
          <SearchIcon placeholder="Search for Supplier ID or Name." boxClasses=" mb-4" textRef={inputRef} value={search} onChange={handleChange} />
          <Dropdown perPage={length} action={handleNewPage} />
          <div>
            <button
              style={{
                padding: "5px 10px",
                borderRadius: "10px",
                background: "#FFFFFF",
                color: "#737A91",
                border: "1px solid #DFE2E6",
                fontSize: "18px",
                marginLeft: "1rem"
              }}
              onClick={() => setShowFilter(!showFilter)}
            >
              <img src={filterIcon} alt="icon" className="icon-image" />
              <span
                style={{
                  paddingLeft: "1rem"
                }}
              >
                {!showFilter ? "Filters" : "Hide Filters"}
              </span>
            </button>
          </div>
        </div>

        <DataTable loading={isLoadingbbb} columns={documentsColumns} data={vendorsTable} />
        <Pagination page={page} lastPage={catVendor?.pagination?.pageCount} paginate={paginate} total={catVendor?.pagination?.total} handlePageChange={handlePageChange} perPage={length} />
      </div>
    </div>
  );
};

export default ListSuppliers;
