import StatsCard from "components/StatsCard";
import React, { useEffect } from "react";
import Cash from "assests/icons/statsCard/cash.png";
import Leaf from "assests/icons/statsCard/leaf-sharp.png";
import Invalid from "assests/icons/statsCard/Vector (1).png";
import Valid from "assests/icons/statsCard/Vector.png";
import "./styles.scss";
import Button from "components/Button";
import DoughnutChart from "components/Charts/DonutChart";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getSingleDocument } from "store/slice/BuyerSlice/DocumentSlice/DocumentSlice";
import { RootState } from "store/store";
import { getPOSummaries } from "store/slice/SummarySlice/SummarySlice";
import { getInvoiceSummary } from "store/slice/BuyerSlice/Invoice/InvoiceSlice";
import { formatCurrency, removeZero } from "utils/utils";
import Star from "components/Star";
type Props = {};
const SingleContract = ({}: Props) => {
  const dispatch = useDispatch();
  let { id }: any = useParams();
  const history = useHistory();
  //   States
  const { singleDoc } = useSelector((state: RootState) => state.document);
  const { user } = useSelector((state: RootState) => state.user);
  const { POSummaries } = useSelector((state: RootState) => state.summaries) as any;
  const { summary } = useSelector((state: RootState) => state.invoice) as any;
  const data = singleDoc?.data[0];

  useEffect(() => {
    if (id) {
      dispatch(getSingleDocument(id));
      dispatch(getPOSummaries());
      dispatch(getInvoiceSummary(undefined, id));
    } else history.push("/buyer/purchase-orders");
  }, []);

  const color = ["#A6CEE3", "#1F78B4"];
  let sumQty = data?.materials.reduce((accumulator, current) => {
    return accumulator + Number(current.quantity);
  }, 0);

  let sumQtyDel = data?.materials?.reduce((accumulator, current) => {
    return accumulator + Number(current.quantity_delivered);
  }, 0);
  let notDelSum = sumQty - sumQtyDel;
  const chartData = [
    { value: notDelSum / sumQty, name: `Yet to Delivered ${((notDelSum / sumQty) * 100).toFixed(2)}%` },
    { value: (sumQty - notDelSum) / sumQty, name: `Deliver ${((100 * (sumQty - notDelSum)) / sumQty).toFixed(2)}%` }
  ];
  let status;
  if (data?.purchase_order_status === "-2") {
    status = "In Draft";
  }
  if (data?.purchase_order_status === "-1") {
    status = "Pending";
  }
  if (data?.purchase_order_status === "1") {
    status = "Approved";
  }
  if (data?.purchase_order_status === "2") {
    status = "Accepted";
  }
  if (data?.purchase_order_status === "3") {
    status = "Out for Delivery";
  }
  if (data?.purchase_order_status === "4") {
    status = "Partially Delivered";
  }
  if (data?.purchase_order_status === "5") {
    status = "Delivered";
  }

  const totalLineItemtax =
    data?.materials?.length > 0 &&
    data?.materials?.reduce((acc, current) => {
      return acc + (Number(current.tax_percent) * Number(current.total_amount)) / 100;
    }, 0);

  const totalAmt = Number(data?.total_value) + totalLineItemtax;
  console.log(data, "looooo");

  const subTotal =
    data?.invoices?.length > 0 &&
    data?.invoices?.reduce((acc, current) => {
      return acc + Number(current.total_invoice_amount);
    }, 0);
  // const subTotal =
  //   // invoice?.gr_items.length > 0 &&
  //   data?.invoice_items?.reduce((acc, current) => {
  //     return acc + Number(current.item_invoice_amount);
  //   }, 0);

  return (
    <div id="single-contract-page">
      <div className="container">
        <div className="d-flex">
          <h4 className="page-title mr-2 pl-md-0 " style={{ fontSize: "1.125em" }}>
            {user?.vendor_description} | {removeZero(user?.vendor_code)}
          </h4>
          <Star stars={user && user.overall_ratings} disabled={true} />
        </div>

        <div className="row mt-2 ">
          <StatsCard statsNumber={data?.total_value} text="Value (Amount)" classes="stats-icon-cash" image={Cash} amount="" />

          <StatsCard statsNumber={0} text="Evaluation Score" classes="stats-icon-leaf" image={Leaf} volume="kg" />

          <StatsCard statsNumber={summary?.overall?.total_invoices} text="Invoice Submitted" classes="stats-icon-valid" image={Valid} iconBackgroundColor="rgba(45, 156, 219, 0.1)" />

          <StatsCard statsNumber={0} text="Defects Reported" classes="stats-icon-invalid" image={Invalid} />
        </div>

        <div className="purchase_order">
          <div className="purchase_order_heading d-flex align-items-center">
            <h5 className="page-title">Purchase Order: {data?.purchase_order_code}</h5>
            {/* <Button type="button" label="Delivered" btnType="btn-skeleton" xtraClass="btn-padding" boxClasses="mr-3"></Button> */}
            {/* <Button type="button" label="Download Purchase Order" btnType="btn-skeleton" xtraClass="btn-padding" boxClasses="mr-3"></Button> */}
          </div>
          <div className="row mt-4">
            <div className="col-md-8 ">
              <div className="purchase-details d-flex flex-row mb-4">
                <div className="w-50  purchase-details_list  d-flex flex-column ">
                  <div className="">
                    <h6 className="yara-color mb-2">Created On:</h6>
                    <p>{data?.purchase_order_date}</p>
                  </div>
                  <div className="">
                    <h6 className="yara-color mb-2">Legal Entity: </h6>
                    <p className="text-lengthy">{data?.department[0]?.company_description}</p>
                  </div>
                  <div className="">
                    <h6 className="yara-color mb-2">Purchase Group: </h6>
                    <p>Supply Chain</p>
                  </div>
                  <div className="">
                    <h6 className="yara-color mb-2">Purchase Text: </h6>
                    <p>{data?.header_text}</p>
                  </div>
                </div>
                <div className="ml-5 d-flex purchase-details_list flex-column">
                  <div className="">
                    <h6 className="yara-color mb-2">Expected Delivery Date: </h6>
                    <p>{data?.delivery_date}</p>
                  </div>
                  <div className="">
                    <h6 className="yara-color mb-2 d-block">Supplier Details:</h6>
                    <p>{data?.vendor_description}</p>
                    <p>{data?.street_name}</p>
                  </div>
                  <div className="">
                    <h6 className="yara-color mb-2 d-block">Status:</h6>
                    <span
                      style={{
                        padding: "8px 16px",
                        background: status === "Pending" ? "rgba(245, 150, 37, 0.15)" : "rgba(0, 176, 7, 0.15)",
                        borderRadius: "10px",
                        color: status === "Pending" ? "rgba(245, 150, 37, 1)" : "#387C57"
                      }}
                    >
                      {status}
                    </span>
                  </div>
                </div>
              </div>

              {/* <div className="purchase-table mt-4">
                                <table>
                                    <tbody>
                                        <tr className="table-header">
                                            <th>Item </th>
                                            <th>Mat. No </th>
                                            <th>Product Description</th>
                                            <th>Quantity</th>
                                            <th>UoM</th>
                                            <th>Amount</th>
                                        </tr>

                                        <tr className="table-data-row">
                                            <td>1 </td>
                                            <td>100234157</td>
                                            <td>Apple Iphone 12 Max,64GB </td>
                                            <td>20</td>
                                            <td>EA</td>
                                            <td>4,500,000 NGN</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> */}
            </div>
            <div className="col-md-4">
              <DoughnutChart data={chartData} color={color} name="" />
            </div>
          </div>
        </div>
        <div className="purchase-table">
          <div className="row mb-3">
            <div className="col-md-12">
              <table>
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Location</th>
                    <th>Mat. No</th>
                    <th>Category</th>
                    <th>Product Description</th>
                    <th>Quantity</th>
                    <th>UoM</th>
                    <th>Net Amt</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.materials?.map((mat, i) => (
                    <tr key={i} className="border-container mb-3">
                      <td className="first-td">{i + 1}</td>
                      <td>{mat?.location_description}</td>
                      <td>{mat?.material_code}</td>
                      <td>{mat?.material_category_description}</td>
                      <td>{mat?.material_description}</td>
                      <td>{mat?.quantity}</td>
                      <td>{mat?.uom_description}</td>
                      <td className="last-td">{mat?.price}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="d-flex newStyle" style={{ width: "fit-content", float: "right" }}>
                {/* <div className="d-block">
                  {" "}
                  <p className="page-title">Sub Total: </p>
                  <p className="page-title">Tax: </p>
                  <p className="page-title">Total Amount:</p>
                </div>
                <div className="" style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                  <p className="page-title">&nbsp; {formatCurrency(totalAmt - totalLineItemtax)} </p>
                  <p className="page-title">&nbsp; {formatCurrency(totalLineItemtax)} </p>
                  <p className="page-title">&nbsp; {formatCurrency(totalAmt)}</p>
                </div> */}
                <div className="d-block">
                  <p className="page-title">Net Amount: </p>
                  <p className="page-title">Tax: </p>
                  <p className="page-title">Delivery Cost: </p>
                  <p className="page-title">Total Amount: </p>
                </div>
                <div className="d-block extra">
                  <p className="page-title">&nbsp; {data && data?.total_value ? formatCurrency(data?.total_value) : "0"} </p>
                  <p className="page-title">&nbsp; {formatCurrency(totalLineItemtax)} </p>
                  <p className="page-title">&nbsp; {data && data?.freight_cost ? formatCurrency(data?.freight_cost) : "0"} </p>

                  <p className="page-title">&nbsp; {formatCurrency(Number(totalAmt) + Number(data ? (data?.freight_cost ? data?.freight_cost : 0) : 0))}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="transaction_history mt-5 mb-5">
          <h4 className="lead-text">Transaction History</h4>

          <div className="mt-4">
            <div className="transaction_box mb-2  d-flex">
              <p>Delivery </p>
              <p>29008891</p>
              <p>Created By : Kanjo Mabele</p>
              <p>Creation Date : 15/04/2021</p>
            </div>
            <div className="transaction_box d-flex">
              <p>Delivery </p>
              <p>29008891</p>
              <p>Created By : Kanjo Mabele</p>
              <p>Creation Date : 15/04/2021</p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default SingleContract;
