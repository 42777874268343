import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { departmentStates, Action } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";

const initialState: departmentStates = {
    loading: false,
    deleting: false,
    isLoading: false,
    departments: null,
    successMessage: "",
    errorMessage: ""
};

const departmentSlice = createSlice({
    name: "department",
    initialState,
    reducers: {
        getAllDepartmentLoading: (state) => {
            state.loading = true;
        },
        getAllDepartmentSuccess: (state, action: PayloadAction<Action>) => {
            state.loading = false;
            state.departments = action.payload;
        },
        getAllDepartmentFail: (state) => {
            state.loading = false;
        },
        createDepartmentLoading: (state) => {
            state.isLoading = true;
        },
        createDepartmentSuccess: (state, action: PayloadAction<Action>) => {
            state.isLoading = false;
            state.departments = action.payload;
        },
        createDepartmentFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
        editDepartmentLoading: (state) => {
            state.isLoading = true;
        },
        editDepartmentSuccess: (state, action: PayloadAction<Action>) => {
            state.isLoading = false;
            state.departments = action.payload;
        },
        editDepartmentFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
        deleteDepartmentLoading: (state) => {
            state.deleting = true;
        },
        deleteDepartmentSuccess: (state, action: PayloadAction<Action>) => {
            state.deleting = false;
            state.departments = action.payload;
        },
        deleteDepartmentFail: (state, action: PayloadAction<string>) => {
            state.deleting = false;
            state.errorMessage = action.payload;
        },
    }
});

const { actions, reducer } = departmentSlice;
export const {
    getAllDepartmentLoading,
    getAllDepartmentSuccess,
    getAllDepartmentFail,
    createDepartmentLoading,
    createDepartmentSuccess,
    createDepartmentFail,
    editDepartmentLoading,
    editDepartmentSuccess,
    editDepartmentFail,
    deleteDepartmentLoading,
    deleteDepartmentSuccess,
    deleteDepartmentFail
} = actions;

export const getDepartments =
    (
        page?: number,
        length?: number,
        search?: string,
        department_status?: string,
        department_code?: string,
        company_id?: string,
        location_id?: string,
        hod_user_id?: string
    ): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(getAllDepartmentLoading());
        try {
            const response = await API.get("/departments/get", {
                params: {
                    page,
                    length,
                    search,
                    department_status,
                    department_code,
                    company_id,
                    location_id,
                    hod_user_id
                },
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(getAllDepartmentSuccess(response.data));
            }
        } catch (error) {
            dispatch(getAllDepartmentFail());
        }
    };

export const createDepartment =
    (data: any, cb?: () => void): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(createDepartmentLoading());
        try {
            const response = await API.post(`/departments/create`, data, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(createDepartmentSuccess(response.data.message));
                toastr.success("", response.data.message);
                cb && cb();
            }
        } catch (error) {
            
            if (error.response) {
                dispatch(createDepartmentFail(error.response.data?.message));
                toastr.error(error.response.data.message);
              }
              else{
                dispatch(createDepartmentFail("No network connecton"));
                toastr.error("No network connecton");
              }
            setTimeout(() => {
                dispatch(createDepartmentFail(""));
            }, 3000);
        }
    };

export const editDepartment =
    (data: any, cb?: () => void): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(editDepartmentLoading());
        try {
            const response = await API.post(`/departments/edit`, data, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(editDepartmentSuccess(response.data.message));
                toastr.success("", response.data.message);
                cb && cb();
            }
        } catch (error) {
            
            if (error.response) {
                dispatch(editDepartmentFail(error.response.data.message));
                toastr.error(error.response.data.message);
              }
              else{
                dispatch(editDepartmentFail("No network connecton"));
                toastr.error("No network connecton");
              }
            setTimeout(() => {
                dispatch(editDepartmentFail(""));
            }, 3000);
        }
    };

    export const deleteDepartment =
    (data?: any, cb?: () => void): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(deleteDepartmentLoading());
        try {
            const response = await API.post(`departments/delete`, data, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(deleteDepartmentSuccess(response.data.message));
                toastr.success("", response.data.message);
                cb && cb();
            }
        } catch (error) {
            dispatch(deleteDepartmentFail(error.response.data.message));
            toastr.error(error.response.data.message);
            setTimeout(() => {
                dispatch(deleteDepartmentFail(""));
            }, 3000);
        }
    };


export default reducer;
