import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { elementStates, Action } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";

const initialState: elementStates = {
  loading: false,
  isLoading: false,
  refresh: null,
  successMessage: "",
  errorMessage: ""
};

const refreshSlice = createSlice({
  name: "refresh",
  initialState,
  reducers: {
    startLoad: (state) => {
      state.loading = true;
    },
    getRefreshSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.refresh = action.payload;
    },
    deleteRefreshSuccess: (state) => {
      state.loading = false;
    },
    endLoad: (state) => {
      state.loading = false;
    },
    refreshReq: (state) => {
      state.loading = false;
    },
    refreshReqFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorMessage = action.payload;
    }
  }
});

const { actions, reducer } = refreshSlice;
export const { startLoad, endLoad, refreshReq, getRefreshSuccess, refreshReqFail, deleteRefreshSuccess } = actions;

export const getRefresh =
  (page?: number, length?: number, search?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(startLoad());
    try {
      const response = await API.get("requisition_requests/get", {
        params: {
          page,
          length,
          search
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getRefreshSuccess(response.data));
      }
    } catch (error) {
      dispatch(endLoad());
    }
  };

export const refreshReqAction =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(startLoad());
    try {
      const response = await API.post(`/requisition_requests/create`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(refreshReq());
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error: any) {
      if (error.response) {
        dispatch(refreshReqFail(error.response.data?.message));
        toastr.error(error.response.data.message);
      } else {
        dispatch(refreshReqFail("No network connecton"));
        toastr.error("No network connecton");
      }
      setTimeout(() => {
        dispatch(refreshReqFail(""));
      }, 3000);
    }
  };

export const deleteRefreshAction =
  (data?: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(startLoad());
    try {
      const response = await API.post(`/requisition_requests/delete`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(deleteRefreshSuccess());
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error: any) {
      dispatch(endLoad());
      toastr.error(error.response.data.message);
      setTimeout(() => {
        dispatch(endLoad());
      }, 3000);
    }
  };

export const reverseInvoiceAction =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(startLoad());
    try {
      const response = await API.post(
        `/invoices/reverse/${data}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userToken}`
          }
        }
      );
      if ([200, 201].includes(response.status)) {
        dispatch(refreshReq());
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error: any) {
      if (error.response) {
        dispatch(refreshReqFail(error.response.data?.message));
        toastr.error(error.response.data.message);
      } else {
        dispatch(refreshReqFail("No network connecton"));
        toastr.error("No network connecton");
      }
      setTimeout(() => {
        dispatch(refreshReqFail(""));
      }, 3000);
    }
  };

export const reversePoAction =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(startLoad());
    try {
      const response = await API.post(
        `/purchase_orders/reverse/${data}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userToken}`
          }
        }
      );
      if ([200, 201].includes(response.status)) {
        dispatch(refreshReq());
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error: any) {
      if (error.response) {
        dispatch(refreshReqFail(error.response.data?.message));
        toastr.error(error.response.data.message);
      } else {
        dispatch(refreshReqFail("No network connecton"));
        toastr.error("No network connecton");
      }
      setTimeout(() => {
        dispatch(refreshReqFail(""));
      }, 3000);
    }
  };

export default reducer;
