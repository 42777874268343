import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { StatesProps, Action } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";

const initialState: StatesProps = {
  isLoading: false,
  states: null,
  successMessage: "",
  errorMessage: ""
};

const statesSlice = createSlice({
  name: "states",
  initialState,
  reducers: {
    getAllStatesLoading: (state) => {
      state.isLoading = true;
    },
    getAllStatesSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.states = action.payload;
    },
    getAllStatesFail: (state) => {
      state.isLoading = false;
    }
  }
});

const { actions, reducer } = statesSlice;
export const { getAllStatesLoading, getAllStatesSuccess, getAllStatesFail } = actions;

export const getAllStates =
  (page?: number, length?: number, search?: string, country_id?: string, sortBy?: string, orderBy?: string, state_name?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(getAllStatesLoading());
    try {
      const response = await API.get("/states/get", {
        params: {
          page,
          length,
          search,
          country_id,
          sortBy,
          orderBy,
          state_name
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getAllStatesSuccess(response.data.data));
      }
    } catch (error) {
      dispatch(getAllStatesFail());
    }
  };

export default reducer;
