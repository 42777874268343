import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import rootReducer from "./reducer/index";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["user", "vendorRegistration"]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const isDevMode = process.env.NODE_ENV === "development";
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: isDevMode //only show dev tools if we are in development mode
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
export const persistor = persistStore(store);

export default store;
