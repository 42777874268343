/* eslint-disable react-hooks/exhaustive-deps */
import StatsCard from "components/StatsCard";
import Icon from "assests/icons/statsCard/Vector (4).png";
import SearchBar from "../../../../components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { reportCol, reportData } from "./data";
import "./styles.scss";
import Select from "react-select";
import React, { useEffect, useState } from "react";
import { AppDispatch, RootState } from "store/store";
import { useDispatch, useSelector } from "react-redux";
import useSearch from "hooks/useSearch";
import CreateOrEditReport from "components/ModalBuyer/CreateOrEditReport";
import Pagination from "components/Pagination";
import { getAllReport } from "store/slice/BuyerSlice/ReportSlice/RepotSlice";
import { Pie, Doughnut, Bar } from "react-chartjs-2";
import lineImg from "../../../../assests/icons/line-chart.png";
import dateImg from "../../../../assests/icons/calendar.png";
import SelectDropdown from "components/SelectDropdown";
import { Controller, useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import API from "utils/httpsRequest";
import { useHistory } from "react-router";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import { kFormatter } from "utils/utils";
import moment from "moment";
import { getCompanySummary, getDeptSummary, getPRCompanySummary, getPRDeptSummary, getPReqSummary, getPRUserSummary } from "store/slice/SummarySlice/SummarySlice";

const ReportPR = () => {
  const [singleReport, setSingleReport] = useState<any>(null);

  const dispatch = useDispatch<AppDispatch>();
  const { reports, loading } = useSelector((state: RootState) => state.report);
  const { locations } = useSelector((state: RootState) => state.location);
  const [modal, setModal] = useState<boolean>(false);
  const { user } = useSelector((state: RootState) => state.user);
  const { token } = user;

  const [locationSel, setLocationSel] = React.useState("");
  const [selLocationVal, setSelLocationVal] = React.useState("");
  const [DepartmentSel, setDepartmentSel] = React.useState("");
  const [selDepartmentVal, setSelDepartmentVal] = React.useState("");
  const [UsersSel, setUsersSel] = React.useState("");
  const [selUsersVal, setSelUsersVal] = React.useState("");
  const [amountSel, setAmountSel] = React.useState("");
  const [selAmountVal, setSelAmountVal] = React.useState("");
  const [companySel, setCompanySel] = React.useState("");
  const [selCompanyVal, setSelCompanyVal] = React.useState("");
  const [catSel, setCatSel] = React.useState("");
  const [selCatVal, setSelCatVal] = React.useState("");
  const [company, setCompany] = useState(user.company_id ? user.company_id : "10002");
  const [companyName, setCompanyName] = useState(user.company_description ? user.company_description : "GZ Industries Ltd - Nigeria");
  const [location, setLocation] = useState("");
  const [locationName, setLocationName] = useState("");
  const [dept, setDept] = useState("");
  const [deptName, setDeptName] = useState("");
  const [users, setUsers] = useState("");
  const [usersName, setUsersName] = useState("");
  const [status, setStatus] = useState("");
  const [statusName, setStatusName] = useState("");
  const [cat, setCat] = useState("");
  const [catName, setCatName] = useState("");
  const [sumDept, setSumDept] = useState(0);
  const [sumItem, setSumItem] = useState(0);
  const [sumMoney, setSumMoney] = useState(0);
  const [sumIso, setSumIso] = useState(null);
  const [firstFive, setFirstFive] = useState<any>([]);
  const [firstFive2, setFirstFive2] = useState<any>([]);

  const [isUser, setIsUser] = useState(true);
  const [isDept, setIsDept] = useState(false);

  // const [defaultLegal, setDefaultLegal] = useState([{

  //     label: "GZ Industries Ltd - Nigeria",
  //     value: "10002"

  // }]);
  const [newLocationOption, setNewLocationOption] = useState([]);

  const [newDepartmentOption, setNewDepartmentOption] = useState([]);
  const [seen, setSeen] = useState(true);

  // from slice

  const { prCompanySummary, isLodingCompanyPr, isLoadingPrUser, prUserSummary, Preq, PrDept } = useSelector((state: RootState) => state.summaries);

  // setting summary place
  useEffect(() => {
    prCompanySummary && prCompanySummary.data.length ? setSumDept(prCompanySummary.data[0].total_departments) : setSumDept(0);
    prCompanySummary && prCompanySummary.data.length ? setSumItem(prCompanySummary.data[0].total_requisitions) : setSumItem(0);
    prUserSummary && prUserSummary.data ? setSumMoney(prUserSummary?.count) : setSumMoney(0);
  }, [prCompanySummary, prUserSummary]);

  // setting pie chat data

  const [labelList, setLabelList] = useState<string[]>([]);
  const [labelList2, setLabelList2] = useState<string[]>([]);
  // const [onceSeen, setOnceSeen] = useState(true);
  const [pointScore, setPointScore] = useState<number[]>([]);
  const [pointScore2, setPointScore2] = useState<number[]>([]);
  let onceSeen = true;
  let onceSeen2 = true;

  useEffect(() => {
    // const newFilt = prUserSummary ? prUserSummary?.data.sort((a, b) => b.total_requisitions - a.total_requisitions) : null;

    const filt =
      prUserSummary &&
      [...prUserSummary?.data]
        ?.sort((a, b) => {
          return Number(b.total_requisitions) - Number(a.total_requisitions);
        })
        ?.filter((val, i) => i <= 4)
        ?.filter((item) => {
          return item;
        });

    if (filt) {
      if (filt.length) {
        setFirstFive(filt);
        onceSeen = true;
        if (onceSeen) {
          let testArray: string[] = [];
          let testScore: number[] = [];
          // item.department_description.split(" ")[0]
          filt.forEach((item) => testArray.push(`${item.lastname ? item.lastname : ""} ${item.othernames ? item.othernames : ""}`));
          filt.forEach((item) => testScore.push(item.total_requisitions));
          setLabelList([...testArray]);
          setPointScore([...testScore]);

          onceSeen = false;
        }
      } else {
        setLabelList([]);
        setPointScore([]);
      }
    }
  }, [prUserSummary]);

  useEffect(() => {
    const filt2 =
      PrDept &&
      [...PrDept?.data]
        ?.sort((a, b) => {
          return Number(b.total_requisitions) - Number(a.total_requisitions);
        })
        ?.filter((val, i) => i <= 4)
        ?.filter((item) => {
          return item;
        });

    if (filt2) {
      if (filt2.length) {
        setFirstFive2(filt2);
        onceSeen2 = true;
        if (onceSeen2) {
          let testArray2: string[] = [];
          let testScore2: number[] = [];
          // item.department_description.split(" ")[0]
          filt2.forEach((item) => testArray2.push(`${item.department_description}`));
          filt2.forEach((item) => testScore2.push(item.total_requisitions));
          setLabelList2([...testArray2]);
          setPointScore2([...testScore2]);

          onceSeen2 = false;
        }
      } else {
        setLabelList2([]);
        setPointScore2([]);
      }
    }
  }, [PrDept]);

  // view

  const handleView = (e, item) => {
    history.push(`/buyer/requisition/${item.id}`);
  };

  const [dateRange, setDateRange] = useState([
    {
      // ate_from=&date_to=
      startDate: new Date(2022, 0, 1),
      endDate: new Date(),
      key: "selection"
    }
  ]);

  const [newDate, setNewDate] = useState([{}]);

  useEffect(() => {
    setNewDate([
      {
        startDate: dateRange[0].startDate,
        endDate: dateRange[0].endDate
      }
    ]);
  }, [dateRange]);

  // get summary for company
  useEffect(() => {
    // /purchase_orders/summary_by_company?company_id
  }, [company]);

  const [showRange, setShowRange] = useState(false);

  const handleRangeShow = () => {
    setShowRange(!showRange);
  };

  const history = useHistory();

  const closeModal = () => {
    setModal((prev) => !prev);
    setSingleReport(null);
  };

  const handleInputChangeLocation = (value) => {
    setLocationSel(value);
  };

  const handleInputChangeUsers = (value) => {
    setUsersSel(value);
  };

  const onChangeUsers = (value) => {
    setUsers(value.value);
    setUsersName(value.label);
    setSelUsersVal(value);
  };

  const onChangeLocation = (value) => {
    setLocation(value.value);
    setLocationName(value.label);
    setSelLocationVal(value);
  };

  const handleInputChangeDepartment = (value) => {
    setDepartmentSel(value);
  };

  const onChangeDepartment = (value) => {
    setDept(value.value);
    setDeptName(value.label);
    setSelDepartmentVal(value);
  };

  const handleInputChangeCat = (value) => {
    setCatSel(value);
  };

  const onChangeCat = (value) => {
    setCat(value.value);
    setCatName(value.label);
    setSelCatVal(value);
  };

  const handleInputChangeCompany = (value) => {
    setCompanySel(value);
  };

  const onChangeCompany = (value) => {
    setCompany(value.value);
    setCompanyName(value.label);
    // setInitailId(value.value)
    setSelCompanyVal(value);

    LocationOptions({ companyId: value.value });
    DepartmentOptions({ companyId: value.value });
  };

  const onChangeStatus = (value) => {
    setStatus(value.value);
    setStatusName(value.label);
  };

  const removeFilter = (nameFilt) => {
    if (nameFilt === "date") {
      setDateRange([
        {
          // ate_from=&date_to=
          startDate: new Date(2022, 0, 1),
          endDate: new Date(),
          key: "selection"
        }
      ]);
    }
    if (nameFilt === "legal") {
      setCompany("");
      setCompanyName("");
    }
    if (nameFilt === "users") {
      setUsers("");
      setUsersName("");
      setSelUsersVal("");
    }
    if (nameFilt === "location") {
      setLocation("");
      setLocationName("");
      setSelLocationVal("");
    }
    if (nameFilt === "dept") {
      setDept("");
      setDeptName("");
      setSelDepartmentVal("");
    }
    if (nameFilt === "cat") {
      setCat("");
      setCatName("");
      setSelCatVal("");
    }

    if (nameFilt === "usersname") {
      setUsers("");
      setUsersName("");
      setSelUsersVal("");
    }
  };

  const companiesOptions = () => {
    return API.get(`/companies/get?search=${companySel}&length=1000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: item.company_description,
        value: item.id
      }));
    });
  };

  const usersOptions = () => {
    return API.get(`/users/get?search=${UsersSel}&length=1000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: `${item.lastname} ${item.othernames}`,
        value: item.id
      }));
    });
  };
  const LocationOptions = ({ companyId = company }) => {
    return API.get(`/locations/get?search=${locationSel}&company_id=${companyId}&length=1000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      let guy =
        res.data &&
        res.data.data.map((item) => ({
          label: item.location_description,
          value: item.id
        }));

      setNewLocationOption(guy);
      return guy;
    });
  };
  const DepartmentOptions = ({ companyId = company }) => {
    return API.get(`/departments/get?search=${DepartmentSel}&company_id=${companyId}&length=1000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      let guy =
        res.data &&
        res.data.data.map((item) => ({
          label: item.department_description,
          value: item.id
        }));
      setNewDepartmentOption(guy);
      return guy;
    });
  };
  const materialCategoryOptions = () => {
    return API.get(`/material_categories/get?search=${catSel}&length=1000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: item.material_category_description,
        value: item.id
      }));
    });
  };

  const statusOptions = [
    {
      label: "Unpublised",
      value: "-2"
    },
    {
      label: "Pending Approval",
      value: "-1"
    },
    {
      label: "Approved",
      value: "1"
    },
    {
      label: "RFP/RFQ Generated",
      value: "2"
    },
    {
      label: "Closed",
      value: "3"
    }
  ];

  const { register, control, handleSubmit, errors, setValue } = useForm<FormData>({
    mode: "onChange"
  });

  //Hook
  //   const { search, inputRef, handleChange, searched } = useSearch(getAllReport, perPage);

  //   chart info

  const barData = {
    labels: labelList.length && labelList,
    datasets: [
      {
        label: "Purchase Requisition By User Chart [Top five]",
        weight: 1,
        backgroundColor: ["#05445E", "#00B01C", "#A6CEE3", "#189AB4", "#75E6DA", "#05445E"],
        data: pointScore.length && pointScore
      }
    ]
  };

  const barData2 = {
    labels: labelList2.length && labelList2,
    datasets: [
      {
        label: "Purchase Requisition By Department Chart [Top five]",
        weight: 1,
        backgroundColor: ["#05445E", "#00B01C", "#A6CEE3", "#189AB4", "#75E6DA", "#05445E"],
        data: pointScore2.length && pointScore2
      }
    ]
  };

  // get company Summary
  useEffect(() => {
    dispatch(
      getPRCompanySummary({
        company_id: company,
        location_id: location,
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-D"),
        department_id: dept,
        material_category_id: cat,
        length: 1000,
        userId: users,
        status: status
      })
    );
  }, [company, location, dept, newDate, cat, users, status]);

  // get department Summary
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  //Show Number for PerPage Request
  const handleNewPage = (pageNo: number, page: number = 1) => {
    setPerPage(pageNo);
    setPage(page);
    dispatch(
      getPRUserSummary({
        company_id: company,
        location_id: location,
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-D"),
        department_id: dept,
        material_category_id: cat,
        length: perPage,
        page: pageNo,
        userId: users,
        status: status
      })
    );
  };

  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, perPage: number) => {
      setPage(page);
      dispatch(
        getPReqSummary({
          company_id: company,
          location_id: location,
          date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
          date_to: moment(dateRange[0].endDate).format("YYYY-MM-D"),
          department_id: dept,
          material_category_id: cat,
          length: perPage,
          page: page,
          userId: users,
          status: status
        })
      );
    },
    [company, location, dateRange, dept, cat, users, status]
  );

  console.log(company, location, dept, cat, users, status);

  const paginate = React.useCallback(
    (pageNo: number, perPage: number) => {
      setPage(pageNo);
      dispatch(
        getPReqSummary({
          company_id: company,
          location_id: location,
          date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
          date_to: moment(dateRange[0].endDate).format("YYYY-MM-D"),
          department_id: dept,
          material_category_id: cat,
          length: perPage,
          page: pageNo,
          userId: users,
          status: status
        })
      );
    },
    [company, location, dateRange, dept, cat, users, status]
  );

  useEffect(() => {
    dispatch(
      getPReqSummary({
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-D"),
        department_id: dept,
        length: perPage,
        page: page,
        company_id: company,
        userId: users,
        status: status,
        location_id: location
      })
    );
  }, [dept, newDate, users, status, company, location]);

  useEffect(() => {
    dispatch(
      getPRUserSummary({
        company_id: company,
        location_id: location,
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-D"),
        department_id: dept,
        material_category_id: cat,
        length: perPage,
        page: page,
        userId: users,
        status: status
      })
    );
  }, [company, location, dept, newDate, cat, users, status]);

  useEffect(() => {
    dispatch(
      getPRDeptSummary({
        company_id: company,
        location_id: location,
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-D"),
        department_id: dept,
        material_category_id: cat,
        length: perPage,
        page: page,
        userId: users,
        status: status
      })
    );
  }, [company, location, dept, newDate, cat, users, status]);

  function fancyTimeFormat(dura) {
    let duration = Number(dura);
    // Hours, minutes and seconds
    var day = Math.floor(duration / (3600 * 24));
    var dDisplay = day > 0 ? day + (day == 1 ? " day " : " days, ") : "0";
    return dDisplay;
  }

  const reportList =
    Preq &&
    Preq?.data?.map((company) => {
      return {
        id: `${company.id}`,
        user_name: `${company.created_by_othernames} ${company.created_by_lastname}`,
        total_delivery_locations: company.location_description,
        total_requisitions: company.requisition_description,
        po: fancyTimeFormat(company.po_lead_time),
        req_code: company.requisition_code
      };
    });

  const handleApi = (type) => {
    if (type === "user") {
      setIsUser(true);
      setIsDept(false);
    }
    if (type === "dept") {
      setIsUser(false);
      setIsDept(true);
    }
  };

  return (
    <div id="report">
      {modal && <CreateOrEditReport closeModal={closeModal} />}

      <div className="container">
        <div className="filterContainer">
          <div className="titleSide">
            <div className="left">
              <img src={lineImg} alt="line icon" />
              <p>Purchase Requisition Report</p>
            </div>
          </div>

          <div className="filters">
            <div className="dateFilter each">
              <div onClick={handleRangeShow} className="dateBox">
                <p className="date-range">
                  {` ${moment(dateRange[0].startDate).format("MM/DD/YYYY")} - ${moment(dateRange[0].endDate).format("MM/DD/YYYY")}`}

                  {/* moment(dateRange[0].startDate).format('MM/DD/YYYY') */}
                </p>
                <img src={dateImg} alt="date icon" />
              </div>

              {showRange && (
                <div className="rangeDiv">
                  <DateRangePicker
                    onChange={(item) => setDateRange([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={dateRange}
                    direction="horizontal"
                    preventSnapRefocus={true}
                    calendarFocus="backwards"
                  />
                  <div onClick={handleRangeShow} className="applyBtn">
                    <button type="button">Apply</button>
                  </div>
                </div>
              )}
            </div>

            <div className="CompanyFilter each">
              <Controller
                control={control}
                defaultValue
                boxClasses="mt-3"
                name="company_id"
                render={({ ref }) => (
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    placeholder="Legal Entity"
                    value={selCompanyVal}
                    loadOptions={companiesOptions}
                    onInputChange={handleInputChangeCompany}
                    onChange={onChangeCompany}
                  />
                )}
              />
            </div>

            <div className="usersFilter each">
              <Controller
                control={control}
                defaultValue
                boxClasses="mt-3"
                name="company_id"
                render={({ ref }) => (
                  <AsyncSelect cacheOptions defaultOptions placeholder="Created By" value={selUsersVal} loadOptions={usersOptions} onInputChange={handleInputChangeUsers} onChange={onChangeUsers} />
                )}
              />
            </div>

            <div className="locationFilter each">
              <Controller
                control={control}
                defaultValue
                boxClasses="mt-3"
                name="company_id"
                render={({ ref }) => (
                  <AsyncSelect
                    cacheOptions
                    defaultOptions={newLocationOption}
                    placeholder="Location"
                    value={selLocationVal}
                    loadOptions={LocationOptions}
                    onInputChange={handleInputChangeLocation}
                    onChange={onChangeLocation}
                  />
                )}
              />
            </div>
            <div className="departmentFilter each">
              <Controller
                control={control}
                defaultValue
                boxClasses="mt-3"
                name="company_id"
                render={({ ref }) => (
                  <AsyncSelect
                    cacheOptions
                    defaultOptions={newDepartmentOption}
                    placeholder="Department"
                    value={selDepartmentVal}
                    loadOptions={DepartmentOptions}
                    onInputChange={handleInputChangeDepartment}
                    onChange={onChangeDepartment}
                  />
                )}
              />
            </div>
            <div className="groupFilter each">
              <Controller
                control={control}
                defaultValue
                boxClasses="mt-3"
                name="material_category_id"
                render={({ ref }) => (
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    placeholder="Material Category"
                    value={selCatVal}
                    loadOptions={materialCategoryOptions}
                    onInputChange={handleInputChangeCat}
                    onChange={onChangeCat}
                  />
                )}
              />
            </div>

            <div className="locationFilter each">
              <Select
                classNamePrefix="react-select"
                className="react-select"
                closeOnSelect={false}
                id="status"
                printOptions="on-focus"
                placeholder="Status"
                onChange={onChangeStatus}
                options={statusOptions}
                // value={value}
                // ref={ref}
              />
            </div>
          </div>

          <div className="showing">
            <p className="text">Showing:</p>
            <div className="coverDisplay">
              <div className="displayDate">
                <p>{` ${moment(dateRange[0].startDate).format("MM/DD/YYYY")} - ${moment(dateRange[0].endDate).format("MM/DD/YYYY")}`}</p>
                <p
                  onClick={() => {
                    removeFilter("date");
                  }}
                  className="cancel comp"
                >
                  x
                </p>
              </div>
              {companyName && (
                <div className="displayDate">
                  <p className="Company">{companyName}</p>
                </div>
              )}
              {locationName && (
                <div className="displayDate">
                  <p className="location">{locationName}</p>
                  <p
                    onClick={() => {
                      removeFilter("location");
                    }}
                    className="cancel location"
                  >
                    x
                  </p>
                </div>
              )}

              {deptName && (
                <div className="displayDate">
                  <p className="dept">{deptName}</p>
                  <p
                    onClick={() => {
                      removeFilter("dept");
                    }}
                    className="cancel dept"
                  >
                    x
                  </p>
                </div>
              )}

              {catName && (
                <div className="displayDate">
                  <p className="cat">{catName}</p>
                  <p
                    onClick={() => {
                      removeFilter("cat");
                    }}
                    className="cancel cat"
                  >
                    x
                  </p>
                </div>
              )}

              {usersName && (
                <div className="displayDate">
                  <p className="cat">{usersName}</p>
                  <p
                    onClick={() => {
                      removeFilter("usersname");
                    }}
                    className="cancel cat"
                  >
                    x
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="summary">
            <p className="topic">Click Tiles to view summaries</p>
            <div className="summaryCover">
              <div
                className="spent hoverr"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleApi("user");
                }}
              >
                {/* <p className="smally">Clck to view</p> */}
                <div className="moneySide">
                  {/* <p className="currency">$</p> */}
                  <p className="amountMoney">{kFormatter(sumMoney, "")} </p>
                  <p className="currencyType">{sumIso}</p>
                </div>
                <p className="text">Total Users</p>
              </div>

              <div
                className="spent mid hoverr"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleApi("dept");
                }}
              >
                {/* <p className="smally ">Clck to view</p> */}
                <div className="deptSide">
                  <p className="out">{kFormatter(sumDept, "")}</p>
                  {/* <p className="total">/14</p> */}
                </div>
                <p className="text">Total Departments Used</p>
              </div>

              <div className="spent">
                <p className="purchase">{kFormatter(sumItem, "")}</p>
                <p className="text">Total Requisition</p>
              </div>
            </div>
          </div>
        </div>
        {isUser ? (
          <div className="chatBox">
            <div className="chartFlex">
              {labelList.length && pointScore.length ? (
                <div style={{ width: "100%" }} className="pieChart">
                  <Bar
                    data={barData}
                    options={{
                      title: {
                        display: false,
                        text: "",
                        fontSize: 10
                      },
                      cutout: 100,
                      maintainAspectRatio: false,
                      legend: {
                        display: true,
                        position: "right"
                      }
                    }}
                  />
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
        {isDept ? (
          <div className="chatBox">
            <div className="chartFlex">
              {labelList2.length && pointScore2.length ? (
                <div style={{ width: "100%" }} className="pieChart">
                  <Bar
                    data={barData2}
                    options={{
                      title: {
                        display: false,
                        text: "",
                        fontSize: 10
                      },
                      cutout: 100,
                      maintainAspectRatio: false,
                      legend: {
                        display: true,
                        position: "right"
                      }
                    }}
                  />
                </div>
              ) : null}
            </div>
          </div>
        ) : null}

        <div className="document mt-5">
          <div className="mt-3">
            <DataTable onClick={handleView} columns={reportCol} data={reportList} loading={isLoadingPrUser} />
            {Preq?.count > 10 ? (
              <Pagination page={page} lastPage={Preq?.pagination?.lastPage} paginate={paginate} total={Preq?.pagination?.total} handlePageChange={handlePageChange} perPage={perPage} />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportPR;
