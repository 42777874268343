import React, { useEffect } from "react";
import Modal from "components/Modal";
import ArrowDropdown from "components/ArrowDropdown";
import Textbox from "components/Textbox";
import Button from "components/Button";
import useArrowDropdown from "hooks/useArrowDropdown";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getCompaniesEntityList } from "store/slice/CompaniesSlice/CompaniesSlice";
import { AppDispatch, RootState } from "store/store";
import SelectDropdown from "components/SelectDropdown";
import { Controller } from "react-hook-form";
import API from "utils/httpsRequest";
import AsyncSelect from "react-select/async";
import { createDepartment, editDepartment, getDepartments } from "store/slice/BuyerSlice/DepartmentSlice/DepartmentSlice";

type Props = {
  closeModal: () => void;
  singleDepartmentData?: any;
};
const CreateOrEditDepartment = ({ closeModal, singleDepartmentData = null }: Props) => {
  //State
  const [error, setError] = React.useState("");

  type FormData = {
    department_description: string;
    department_code: string;
    hod_user_id: string;
    company_id: string;
  };

  //Setting field on edit mode
  useEffect(() => {
    if (singleDepartmentData !== null && companies) {
      setValue("department_description", singleDepartmentData?.depart_name);
      setValue("department_code", singleDepartmentData?.depart_code);
      setValue("company_id", singleDepartmentData?.entity_code);
      setValue("hod_user_id", singleDepartmentData?.hod_code);
    }
  }, [singleDepartmentData]);

  //Redux Store
  const dispatch = useDispatch<AppDispatch>();
  const { companies } = useSelector((state: RootState) => state.companies);
  const { loading,isLoading } = useSelector((state: RootState) => state.department);
  const [companySel, setCompanySel] = React.useState("");
  const [selCompanyVal, setSelCompanyVal] = React.useState("");
  const [userSel, setUserSel] = React.useState("");
  const [selUserVal, setSelUserVal] = React.useState("");

  const { user } = useSelector((state: RootState) => state.user);
  const { token } = user;

  const handleInputChangeCompany = (value) => {
    setCompanySel(value);
  };

  const onChangeCompany = (value) => {
    setSelCompanyVal(value);
  };

  const handleInputChangeUser = (value) => {
    setUserSel(value);
  };

  const onChangeUser = (value) => {
    setSelUserVal(value);
  };

  //Hooks
  const { register, handleSubmit, errors, control, setValue } = useForm<FormData>({ mode: "onBlur" });
  const { open, selectedOption, handleDropdownChange, handleAction, keyOption, ref } = useArrowDropdown();

  const onFormSubmit = (vals: FormData) => {
    if (singleDepartmentData) {
      const payload = {
        ...vals,
        id: singleDepartmentData.id,
        company_id: selCompanyVal?.["value"],
        hod_user_id: selUserVal?.["value"]
      };

      dispatch(
        editDepartment(payload, () => {
          closeModal();
          dispatch(getDepartments(1, 10));
        })
      );
      return;
    }

    const { ...rest } = vals;
    const payload = {
      ...rest,
      company_id: selCompanyVal?.["value"],
      hod_user_id: selUserVal?.["value"]
    };

    dispatch(
      createDepartment(payload, () => {
        closeModal();
        dispatch(getDepartments(1, 10));
      })
    );
  };

  //Getting Companies List
  const companiesOptions =
    companies &&
    companies.map((_data: { id: string; company_description: string }) => {
      return {
        value: _data?.id,
        optionName: _data?.company_description
      };
    });

  const companiesOptions2 = () => {
    return API.get(`/companies/get?search=${companySel}&length=1000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: item.company_description,
        value: item.id
      }));
    });
  };

  const userOptions = () => {
    return API.get(`/users/list?search=${userSel}&length=1000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
    
      return res.data.data.map((item) => ({
        label: `${item.lastname} ${item.othernames}`,
        value: item.id
      }));
    });
  };

  return (
    <Modal modalText={singleDepartmentData === null ? "Create Department" : "Edit Department"} onClose={closeModal} modallength="modal-length">
      <div id="buyer-create-location">
        <p className="">Complete this form with accurate data to create and assign a new Department and Head of department</p>

        <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
          <Textbox
            type="text"
            name="department_description"
            label="Department Name"
            placeholder="Department Name"
            error={errors.department_description && "This field is required"}
            customRef={register({
              required: true
            })}
          />
          <Textbox
            type="text"
            label="ERP Code"
            name="department_code"
            placeholder="ERP Code"
            boxClasses="mt-3 mb-3"
            error={errors.department_code && "This field is required"}
            customRef={register({
              required: true
            })}
          />
          {/* <SelectDropdown
            boxClasses="mt-3 mb-4"
            name="company_id"
            label="Legal Entity"
            placeholder="Legal Entity"
            options={companiesOptions}
            customRef={register({ required: true })}
            error={errors.company_id && "This field is required"}
          /> */}

          <Controller
            control={control}
            defaultValue
            boxClasses="mt-3"
            name="company_id"
            render={({ ref }) => (
              <AsyncSelect
                cacheOptions
                defaultOptions
                placeholder="Assign to Legal Entity"
                value={selCompanyVal}
                loadOptions={companiesOptions2}
                onInputChange={handleInputChangeCompany}
                onChange={onChangeCompany}
              />
            )}
          />

          <br />
          <Controller
            control={control}
            defaultValue
            boxClasses="mt-3"
            name="hod_user_id"
            render={({ ref }) => (
              <AsyncSelect cacheOptions defaultOptions placeholder="Assign to HoD" value={selUserVal} loadOptions={userOptions} onInputChange={handleInputChangeUser} onChange={onChangeUser} />
            )}
          />

          <p className={`${error ? "d-block" : "d-none"} error mt-1`}>{error}</p>

          <Button label={singleDepartmentData === null ? "Create Department" : "Edit Department"} btnType="btn-primary" boxClasses="mt-5 mb-5" btnStyle="100%" loading={isLoading} />
        </form>
      </div>
    </Modal>
  );
};

export default CreateOrEditDepartment;
