import React from "react";
import Modal from "components/Modal";
import ArrowDropdown from "components/ArrowDropdown";
import Textbox from "components/Textbox";
import Button from "components/Button";
import useArrowDropdown from "hooks/useArrowDropdown";
import { useForm } from "react-hook-form";
// import { getRoles } from "store/slice/RoleSlice/RoleSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { createUser, editUser, getUsers } from "store/slice/UserMgtSlice/UserMgtSlice";
import { CreateUserPayload } from "store/slice/UserMgtSlice/interface";
import "./styles.scss";
import { getSingleUser } from "store/slice/UserMgtSlice/UserMgtSlice";

type Props = {
  closeModal: () => void;
};

const CreateCatalogue = ({ closeModal }: Props) => {
  const [error, setError] = React.useState("");
  const { register, handleSubmit, errors, setValue } = useForm<FormData>({
    mode: "onChange"
  });

  type FormData = {
    firstName: string;
    lastName: string;
    password: string;
    email: string;
    phoneNo: string;
  };

  // const { userId, false, false } = modalData;
  //Redux Store
  const dispatch = useDispatch();
  // const { roles } = useSelector((state: RootState) => state.roles);
  const { isLoading, singleUser } = useSelector((state: RootState) => state.userMgt);

  const { open, selectedOption, handleDropdownChange, handleAction, keyOption, ref } =
    useArrowDropdown();

  const validatePhoneNo = (value: any) => {
    let values = value.replace(/[^0-9]*/g, "");
    if (value.length > 11 || value !== values) return "Should be a phone number";
  };

  const onFormSubmit = (vals: FormData) => {
    if (isLoading) return;
    if (selectedOption === "" && false) {
      setError("Please select a role");
      return;
    }
    const { ...rest } = vals;
    const data: CreateUserPayload = { ...rest, role: keyOption };

    // const data = {
    //     firstName: vals.firstName,
    //     lastName: vals.lastName,
    //     role: selectedOption,
    //     phoneNo: vals.phoneNo,
    //     email: vals.email
    // };
    const editData = {
      firstName: vals.firstName,
      lastName: vals.lastName,
      role: keyOption === "" ? singleUser?.role : keyOption,
      phoneNo: vals.phoneNo,
      email: vals.email
    };
    if (false) {
      dispatch(
        editUser(editData, false, () => {
          closeModal();
          dispatch(getUsers(1, 10));
        })
      );
    } else {
      dispatch(
        createUser(data, () => {
          closeModal();
          dispatch(getUsers(1, 10));
        })
      );
    }
  };

  //Remove error message
  React.useEffect(() => {
    if (keyOption) {
      setError("");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyOption]);

  return (
    <Modal
      modalText={"Create Product Catalog (Price List)"}
      onClose={closeModal}
      modallength={!false ? "modal-length" : ""}
    >
      <div id="createcatalogue-modal">
        {true && true ? (
          <p className="">Create your product catalog for Purchase reference</p>
        ) : null}
        <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
          <Textbox
            type="text"
            name="firstName"
            label="Item Name"
            placeholder="Item Name"
            error={errors.firstName && "This field is required"}
            customRef={register({
              required: true
            })}
          />

          <textarea rows={4} className="text-area mt-4" placeholder="Item Description" />

          <ArrowDropdown
            text="Item  Price"
            options={[]}
            handleDropdownChange={handleDropdownChange}
            handleAction={handleAction}
            selectedOption={selectedOption}
            open={open}
            boxClasses="mt-4"
            ref={ref}
          />

          <ArrowDropdown
            text="Upload Picture"
            options={[]}
            handleDropdownChange={handleDropdownChange}
            handleAction={handleAction}
            selectedOption={selectedOption}
            open={open}
            boxClasses="mt-4"
            ref={ref}
          />

          <p className={`${error ? "d-block" : "d-none"} error mt-1`}>{error}</p>

          {true ? (
            <Button
              label={"Create"}
              btnType="btn-primary"
              boxClasses="mt-4 mb-3"
              btnStyle="100%"
              loading={isLoading}
            />
          ) : null}

          {true ? (
            <Button
              label={"Cancel"}
              btnType=""
              boxClasses="mt-2 mb-5"
              btnStyle="100%"
              loading={isLoading}
            />
          ) : null}
        </form>
      </div>
    </Modal>
  );
};

export default CreateCatalogue;
