import Document from "assests/icons/sidebar/inventory.svg";
import DropdownMenu from "components/DropdownMenu";
import "./styles.scss";

export const documentColumn = [
  {
    name: "Date",
    index: "date",
    id: 1
  },

  {
    name: "Document No",
    index: "doc_no",
    id: 2
  },
  {
    name: "Title",
    index: "title",
    id: 3
  },
  {
    // name: "Status",
    // index: "status",
    // id: 4

    name: "Status",
    id: 4,
    render: (item) => {
      if (item.stat === "1") {
        return (
          <button
            style={{
              background: "rgba(0, 176, 7, 0.15)",
              borderRadius: "10px",
              color: "rgb(56, 124, 87)",
              padding: "8px 16px"
            }}
          >
            {item.status}
          </button>
        );
      }
      if (item.stat === "-1") {
        return (
          <button
            style={{
              padding: "8px 16px",
              background: "rgba(245, 150, 37, 0.15)",
              borderRadius: "10px",
              color: "rgb(245, 150, 37)"
            }}
          >
            {item.status}
          </button>
        );
      }
      if (item.stat === "-2") {
        return (
          <button
            style={{
              padding: "8px 16px",
              background: "rgba(245, 150, 37, 0.15)",
              borderRadius: "10px",
              color: "rgb(245, 150, 37)"
            }}
          >
            {item.status}
          </button>
        );
      }
      if (item.stat === "2") {
        return (
          <button
            style={{
              padding: "8px 16px",
              background: "rgba(239, 196, 66, 0.15)",
              borderRadius: "10px",
              color: "rgb(47, 128, 237)"
            }}
          >
            {item.status}
          </button>
        );
      }
      if (item.stat === "3") {
        return (
          <button
            style={{
              padding: "8px 16px",
              background: "rgba(239, 196, 66, 0.15)",
              borderRadius: "10px",
              color: "rgb(47, 128, 237)"
            }}
          >
            {item.status}
          </button>
        );
      }
      if (item.stat === "4") {
        return (
          <button
            style={{
              padding: "8px 16px",
              background: "rgba(245, 150, 37, 0.15)",
              borderRadius: "10px",
              color: "rgb(245, 150, 37)"
            }}
          >
            {item.status}
          </button>
        );
      }
      if (item.stat === "5") {
        return (
          <button
            style={{
              background: "rgba(0, 176, 7, 0.15)",
              borderRadius: "10px",
              color: "rgb(56, 124, 87)",
              padding: "8px 16px"
            }}
          >
            {item.status}
          </button>
        );
      }
      //   const openVerify = () => {
      //     if (btnText === "Done") {
      //       return;
      //     } else {
      //       item.open();
      //     }
      //   };
      // return (
      //   <button
      //     type="button"
      //     style={{
      //       padding: "8px 16px",
      //       background: item.status === "Pending" ? "rgba(0, 176, 7, 0.15)" : "rgba(0, 70, 176, 0.15)",
      //       borderRadius: "10px",
      //       color: item.status === "Pending" ? "#387C57" : "rgba(47, 128, 237, 1)"
      //     }}
      //       onClick={openVerify}
      //   >

      //     {item.status}
      //   </button>
      // );
      //   </Link>
    }
  }
];

export const documentsData = [
  {
    doc_no: "45000008910",
    description: "Apple Iphone 12 Max, 64GB",
    id: 1
  },
  {
    doc_no: "45000008233",
    description: "In-house software development",
    id: 2
  },
  {
    doc_no: "45000008738",
    description: "Computer Sales",
    id: 3
  },
  {
    doc_no: "45000008910",
    description: "Office Supplies",
    id: 4
  }
];
export const actionColumn = [
  {
    name: "To Do",
    index: "to_do",
    id: 1
  },
  {
    name: "Title",
    index: "title",
    id: 2
  },
  {
    name: "Date",
    index: "date",
    id: 3
  },
  //   {
  //     name: "Action",
  //     id: 3,
  //     render: () => (
  //       <span>
  //         <p style={{ fontSize: "2rem" }} className="cursor-pointer">
  //           ...
  //         </p>
  //       </span>
  //     )
  //   }
  {
    name: "Actions",
    id: 4,
    render: ({ toggleDropdown, delivered, userId, partially_delivered, tableID, pending_approval, approved, accept_po, in_draft, out_for_delivery, view, purchase_order_status }) => {
      const settings = [
        {
          name: "Accept PO",
          style: "activate_tag",
          select: accept_po
        },

        // {
        //   name: "In Draft",
        //   style: "activate_tag",
        //   select: in_draft
        // },
        // {
        //   name: "Pending Approval",
        //   style: "activate_tag",
        //   select: pending_approval
        // },
        // {
        //   name: "Approved",
        //   style: "activate_tag",
        //   select: approved
        // },
        // {
        //   name: "Partially Delivered",
        //   style: "activate_tag",
        //   select: partially_delivered
        // },
        // {
        //   name: "Delivered",
        //   style: "activate_tag",
        //   select: delivered
        // },
        {
          //   name: `${status === "Pending" ? "Activate" : "Deactivate"}`,
          //   style: `${status === "Pending" ? "activate_tag" : "disable_tag"}`,
          //   select: status === "Pending" ? activateUserID : deactivateUserID
          name: "Out for Delivery",
          style: "activate_tag",
          select: out_for_delivery
        },

        // {
        //   name: "Edit",
        //   style: "disable_tag",
        //   select: edit
        // },
        {
          name: "View",
          style: "disable_tag",
          select: view
        }
      ];

      const settings2 = [
        // {
        //   name: "In Draft",
        //   style: "activate_tag",
        //   select: in_draft
        // },
        // {
        //   name: "Pending Approval",
        //   style: "activate_tag",
        //   select: pending_approval
        // },
        // {
        //   name: "Approved",
        //   style: "activate_tag",
        //   select: approved
        // },
        // {
        //   name: "Partially Delivered",
        //   style: "activate_tag",
        //   select: partially_delivered
        // },
        // {
        //   name: "Delivered",
        //   style: "activate_tag",
        //   select: delivered
        // },
        {
          //   name: `${status === "Pending" ? "Activate" : "Deactivate"}`,
          //   style: `${status === "Pending" ? "activate_tag" : "disable_tag"}`,
          //   select: status === "Pending" ? activateUserID : deactivateUserID
          name: "Out for Delivery",
          style: "activate_tag",
          select: out_for_delivery
        },

        // {
        //   name: "Edit",
        //   style: "disable_tag",
        //   select: edit
        // },
        {
          name: "View",
          style: "disable_tag",
          select: view
        }
      ];
      return (
        <span>
          <DropdownMenu options={purchase_order_status === "1" ? settings : settings2} boxClasses="dropdown-style">
            <p style={{ fontSize: "2rem" }} className="cursor-pointer" id={userId}>
              ...
            </p>
          </DropdownMenu>
        </span>
      );
    }
  }
];

export const actionsData = [
  {
    action: "10",
    description: "N4,800,000",
    status: "Delivered",
    id: 1
  },
  {
    action: "1",
    description: "N34,120,000",
    status: "In Progress",
    id: 2
  },
  {
    action: "20",
    description: "N5,560,900",
    status: "PO Received",
    id: 3
  }
];
