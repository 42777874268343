import { Link } from "react-router-dom";

export const tableCol = [
 
    {
        name: "S/N",
        index: "serial",
        id: 1
    },
    {
        name: "Report Name",
        index: "report_name",
        id: 2
    },
    {
        name: "Report Description",
        index: "description",
        id: 3
    },
    // {
    //     name: "Report ID",
    //     index: "report_id",
    //     id: 3
    // },
    // {
    //     name: "Report Authorization",
    //     index: "report_authorization",
    //     id: 4
    // },
    // {
    //     name: "Number Of Users",
    //     index: "number_of_users",
    //     id: 5
    // },
    // {
    //     name: "Status",
    //     id: 7,
    //     render: (item) => (
    //         <Link to={`/buyer/invoice/100`}>
    //             <button
    //                 type="button"
    //                 style={{
    //                     padding: "8px 16px",
    //                     background: item.status === "Pending" ? "rgba(245, 150, 37, 0.15)" : "rgba(0, 70, 176, 0.15)",
    //                     borderRadius: "10px",
    //                     color: item.status === "Pending" ? "rgba(245, 150, 37, 1)" : "rgba(47, 128, 237, 1)"
    //                 }}
    //             >
    //                 {item.status}
    //             </button>
    //         </Link>
    //     )
    // }
];

export const tableData = [
    {
        serial: "1",
        report_name: "Purchase Requisition",
        description:"Purchase Requisition",
        link:"purchase_requisition",
        id: 2
    },
    {
        serial: "2",
        report_name: "Purchase Order",
        description:"Purchase Order",
        link:"purchase_order",
        id: 1
    },
   
    {
        serial: "3",
        report_name: "Supplier Registration",
        description:"Supplier",
        link:"supplier",
        id: 3
    },
    {
        serial: "4",
        report_name: "Invoice ageing analysis",
        description:"Invoice",
        link:"invoice",
        id: 4
    },

    {
        serial: "5",
        report_name: "BBBE Report",
        description:"BBBE",
        link:"bbbe",
        id: 7
    },
];
