import React, { useEffect } from "react";
import Modal from "components/Modal";
import Textbox from "components/Textbox";
import Button from "components/Button";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import Select from "react-select";
import SelectDropdown from "components/SelectDropdown";
import { editLocation, getLocations } from "store/slice/BuyerSlice/LocationSlice/LocationSlice";
import { createProductData, editProductData, getMaterials, uploadFile } from "store/slice/BuyerSlice/ProductSlice/ProductSlice";
import FileUploaderBox from "components/FileUploaderBox";
import { useState } from "react";
import { uploadProfilePicture } from "store/slice/SupplierSlices/UserSlice/UserSlice";
import { getAlluom } from "store/slice/UomSlice/Uom";
import API from "utils/httpsRequest";
import { Controller } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { getMaterialCategories } from "store/slice/BuyerSlice/ProductSlice/ProductSlice";
import { getRoles } from "store/slice/Roleslice/RoleSlice";
import { createNotifySetup, getNotifySetup } from "store/slice/BuyerSlice/NotifySetup/NotifySetupSlice";
import { getUserNotification } from "store/slice/NotificationSlice/NotificationSlice";

type Props = {
  closeModal: () => void;
};
const CreateNotify = ({ closeModal }: Props) => {
  const { register, handleSubmit, errors, control, setValue } = useForm<FormData>({
    mode: "onChange"
  });

  //Redux Store
  const dispatch = useDispatch<AppDispatch>();
  const { notifySetup, isLoading } = useSelector((state: RootState) => state.notifySetup);

  const { user } = useSelector((state: RootState) => state.user);
  const { token } = user;
  const [userSel, setUserSel] = React.useState("");
  const [selUserVal, setSelUserVal] = React.useState("");
  const [roleSel, setRoleSel] = React.useState("");
  const [selRoleVal, setSelRoleVal] = React.useState("");
  const [activity, setActivity] = React.useState("");
  const [activityName, setActivityName] = React.useState("");

  type FormData = {
    activity: string;
  };

  const ActivityOptions = [
    {
      label: "New Requisition",
      value: "NEW REQUISITIONS"
    },
    {
      label: "New Invoice",
      value: "NEW INVOICE"
    },
    {
      label: "New Purchase Order",
      value: "NEW PURCHASE ORDERS"
    },
    {
      label: "New Waybill",
      value: "NEW WAYBILL"
    },
    {
      label: "Vendor Onboard ",
      value: "VENDOR ONBOARD"
    },
    {
      label: "New Proposal",
      value: "NEW PROPOSAL"
    },
    {
      label: "New Bank Proposal",
      value: "NEW BANK PROPOSAL"
    },
    {
      label: "Expiring Document",
      value: "EXPIRING DOCUMENT"
    },
    {
      label: "Bank Authorizer",
      value: "BANK AUTHORIZER"
    },
    {
      label: "Purchase Order Acceptance",
      value: "PURCHASE ORDER ACCEPTANCE"
    },
    {
      label: "Purchase Order Ofd",
      value: "PURCHASE ORDER OFD"
    },
    {
      label: "Failed Sap Action",
      value: "FAILED SAP ACTION"
    }
  ];

  const onChangeActivity = (value) => {
    setActivity(value.value);
    setActivityName(value.label);
  };

  const handleInputChangeUser = (value) => {
    setUserSel(value);
  };

  const onChangeUser = (value) => {
    setSelUserVal(value);
  };

  const handleInputChangeRole = (value) => {
    setRoleSel(value);
  };

  const onChangeRole = (value) => {
    setSelRoleVal(value);
  };

  const userOptions = () => {
    return API.get(`/users/list?search=${userSel}&length=1000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: `${item.lastname} ${item.othernames}`,
        value: item.id
      }));
    });
  };

  const roleOptions = () => {
    return API.get(`/user_roles/get?search=${roleSel}&length=1000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: `${item.user_role_name}`,
        value: item.id
      }));
    });
  };

  //Request
  useEffect(() => {
    dispatch(getRoles(1, 1000));
  }, []);

  const onFormSubmit = (vals: FormData) => {
    const { ...rest } = vals;
    const payload = {
      ...rest,
      user_id: selUserVal?.["value"],
      user_role_id: selRoleVal?.["value"],
      activity: activity
    };

    dispatch(
      createNotifySetup(payload, () => {
        dispatch(getNotifySetup(1, 1000));
        dispatch(getUserNotification(1, 1000));
        closeModal();
      })
    );
  };

  return (
    <Modal modalText={"Create"} onClose={closeModal} modallength="modal-length">
      {
        <div id="buyer-create-location">
          <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
            <Select
              classNamePrefix="react-select"
              className="react-select"
              closeOnSelect={false}
              id="services"
              printOptions="on-focus"
              placeholder="Classification"
              onChange={onChangeActivity}
              options={ActivityOptions}
              // value={value}
              // ref={ref}
            />

            <br />
            <Controller
              control={control}
              defaultValue
              boxClasses="mt-3"
              name="user_id"
              render={({ ref }) => (
                <AsyncSelect cacheOptions defaultOptions placeholder="User" value={selUserVal} loadOptions={userOptions} onInputChange={handleInputChangeUser} onChange={onChangeUser} />
              )}
            />
            <br />
            <Controller
              control={control}
              defaultValue
              boxClasses="mt-3"
              name="role"
              render={({ ref }) => (
                <AsyncSelect cacheOptions defaultOptions placeholder="User Role" value={selRoleVal} loadOptions={roleOptions} onInputChange={handleInputChangeRole} onChange={onChangeRole} />
              )}
            />

            <Button label={"Create"} btnType="btn-primary" boxClasses="mt-4 mb-5" btnStyle="100%" loading={isLoading} />
          </form>
        </div>
      }
    </Modal>
  );
};

export default CreateNotify;
