import * as React from "react";
import "./styles.scss";
import People from "assests/icons/statsCard/people 2 2.png";
import StatsCard from "components/StatsCard";
import DataTable from "components/DataTable";
import { usersColumn } from "./data";
import SearchIcon from "components/SearchBar";
import Icon from "assests/icons/statsCard/Vector (4).png";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, getUsers, activateUser, deactivateUser } from "store/slice/UserMgtSlice/UserMgtSlice";
import { RootState } from "store/store";
import useSearch from "hooks/useSearch";
import Pagination from "components/Pagination";
import Dropdown from "components/Dropdown";
// import CreateUserModal from "components/Modals/CreateOrEditUserModal";
import { toastr } from "react-redux-toastr";
import { toastPopUp } from "utils/utils";
import { checkPermission } from "utils/utils";
import MembersBlue from "assests/icons/buyer/members-blue.svg";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import CreateBuyerUser from "components/ModalBuyer/CreateUser";

type Props = {};

const Users: React.FunctionComponent<Props> = () => {
  //State
  const [page, setPage] = React.useState<number>(1);
  const [length, setlength] = React.useState<number>(10);
  const [modal, setModal] = React.useState<boolean>(false);
  const [tableID, setTableID] = React.useState();
  const { search, inputRef, handleChange, searched } = useSearch(getUsers, length, undefined, ["company"]);
  const [modalData, setModalData] = React.useState({
    userId: "",
    viewStatus: false,
    editStatus: false
  });

  //Redux Hook
  const dispatch = useDispatch();
  const { users, isLoading } = useSelector((state: RootState) => state.userMgt);
  const { permissions } = useSelector((state: RootState) => state.user);
  const { role_access } = useSelector((state: RootState) => state.user?.user);


  //Get Users
  React.useEffect(() => {
    dispatch(getUsers(page, length, "", "company"));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (modal === false) {
      setModalData({ ...modalData, userId: "", viewStatus: false, editStatus: false });
    }
  }, [modal]);

  //This is to handle the dropdown inside the table.
  // const handleTableDropdown = (e: any, id: string) => {
  //     if (tableID !== undefined && tableID === e.target.id) {
  //         setTableID(undefined);
  //         return;
  //     } else {
  //         setTableID(e.target.id);
  //     }
  // };

  const viewUser = (id: string) => {
    closeModal();
    setModalData({ ...modalData, userId: id, viewStatus: true, editStatus: false });
  };

  // setpage to the first page when a searched executed
  React.useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  const editUser = (id: string) => {
    // if (!permissions?.includes("USER_EDIT")) {
    //   toastr.error("", "You don't have the Admin rights to do this");
    //   return;
    // }
    closeModal();
    setModalData({ ...modalData, viewStatus: false, userId: id, editStatus: true });
  };

  const deleteUserId = (id: string, name: string) => {
    // if (!permissions?.includes("USER_DELETE")) {
    //   toastr.error("", "You don't have the Admin rights to do this");
    //   return;
    // }

    toastPopUp(
      () => {
        dispatch(
          deleteUser(id, () => {
            dispatch(getUsers(page, length));
          })
        );
      },
      "Delete",
      "Cancel",
      "Delete the user",
      name,
      "red"
    );
  };

  const activateUserID = (id: string, name: string) => {
    // if (!permissions?.includes("USER_ACTIVATE")) {
    //   toastr.error("", "You don't have the Admin rights to do this");
    //   return;
    // }
    toastPopUp(
      () => {
        dispatch(
          activateUser({ user_id: id }, () => {
            dispatch(getUsers(page, length));
          })
        );
      },
      "Activate",
      "Cancel",
      "Activate",
      name,
      "#00B050"
    );
  };

  const deactivateUserID = (id: string, name: string) => {
    // if (!permissions?.includes("USER_DEACTIVATE")) {
    //   toastr.error("", "You don't have the Admin rights to do this");
    //   return;
    // }
    toastPopUp(
      () => {
        dispatch(
          deactivateUser({ user_id: id }, () => {
            dispatch(getUsers(page, length));
          })
        );
      },
      "Deactivate",
      "Cancel",
      "Deactivate",
      name,
      "#737a91"
    );
  };
  const userList =
    users &&
    users?.data?.map((data: any) => {
      return {
        firstName: data.othernames,
        lastName: data.lastname,
        phoneNo: data.phone_number,
        email: data.username,
        role: data.user_role_name,
        // toggleDropdown: handleTableDropdown,
        userId: data.id,
        status: data.user_status === "1" ? "Approved" : "Pending",
        // tableID: tableID,
        view: () => viewUser(data.id),
        edit: () => checkPermission(role_access, "USERS_EDIT") && editUser(data.id),
        deleteUserId: () => checkPermission(role_access, "USERS_DELETE") && deleteUserId(data.id, data.othernames),
        activateUserID: () => checkPermission(role_access, "USERS_ACTIVATE") && activateUserID(data.id, data.othernames),
        deactivateUserID: () => checkPermission(role_access, "USERS_DEACTIVATE") && deactivateUserID(data.id, data.othernames)
      };
    });

  //--------------------------------------------
  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, length: number) => {
      setPage(page);
      dispatch(getUsers(page, length, search, "company"));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, length: number) => {
      setPage(pageNo);
      dispatch(getUsers(pageNo, length, search, "company"));
    },
    [search]
  );

  //------------------------------------------------------------
  //Show Number for length Function
  //------------------------------------------------------------
  const handleNewPage = React.useCallback(
    (pageNo: number, page = 1) => {
      setlength(pageNo);
      setPage(1);
      dispatch(getUsers(page, pageNo, search, "company"));
    },
    [search]
  );

  //------------------------------------------------
  const closeModal = () => {
    setModal(!modal);
  };

  //Create User
  const createUser = () => {
    closeModal();
  };

  return (
    <div id="buyer-user-management-page">
      {modal && <CreateBuyerUser closeModal={closeModal} modalData={modalData} />}
      <div className="container">
        <div className="row mt-3 mb-5">
          <StatsCard statsNumber={users?.count} text="Users" classes="stats-icon" image={MembersBlue} iconBackgroundColor="rgba(45, 156, 219, 0.1)" />
          <StatsCard image={AddIcon} classes="middle-img" centerText="Create User" onClick={createUser} />
        </div>
        <h4 className="page-title mb-2">User Logs</h4>
        <div className="search-field">
          <SearchIcon placeholder="Search for First name, Phone no. or email" boxClasses=" mb-3" textRef={inputRef} value={search} onChange={handleChange} />
          <Dropdown perPage={length} action={handleNewPage} />
        </div>

        <DataTable columns={usersColumn} data={userList} />
        <Pagination page={page} lastPage={users?.pagination?.pageCount} paginate={paginate} total={users?.pagination?.total} handlePageChange={handlePageChange} perPage={length} />
      </div>
    </div>
  );
};

export default Users;
