import React, { useCallback, useEffect, useState } from "react";
import "./styles.scss";

import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootState } from "store/store";

import useSearch from "hooks/useSearch";
import { getWayBill } from "store/slice/SupplierSlices/Waybill/WayBillSlice";

import { getInvoiceSummary } from "store/slice/BuyerSlice/Invoice/InvoiceSlice";
import StatsCard from "components/StatsCard";
import SearchBar from "../../../components/SearchBar";
import file from "../../../assests/icons/buyer/file.svg";

import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { tableCol, tableData } from "./data";
import { useHistory } from "react-router";
import { getVendors } from "store/slice/BuyerSlice/VendorSlice/VendorSlice";
import SearchIcon from "../../../components/SearchBar";
import Pagination from "components/Pagination";

interface IUserMenuProps {}

const VendorStatementBuyer: React.FC<IUserMenuProps> = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //State
  const [modal, setModal] = useState<boolean>(false);
  const [active, setActive] = useState(true);
  const [active2, setActive2] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageNum, setPageNumber] = useState(10);
  const [showFilter, setShowFilter] = useState(false);
  const history = useHistory();
  // const [_modalData, set_ModalData] = useState(null);

  // const { search, inputRef, handleChange, searched } = useSearch(getWayBill, perPage);
  const { vendors, loading } = useSelector((state: RootState) => state.vendorDetails);
  //Hook
  const { search, inputRef, handleChange, searched } = useSearch(getVendors, perPage);

  //Show Number for PerPage Request
  const handleNewPage = (pageNo: number, page: number = 1) => {
    setPerPage(pageNo);
    setPage(page);
    dispatch(getVendors(page, perPage, search));
  };

  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, perPage: number) => {
      setPage(page);
      dispatch(getVendors(page, perPage, search));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, perPage: number) => {
      setPage(pageNo);
      dispatch(getVendors(pageNo, perPage, search));
    },
    [search]
  );
  //Redux Hook
  const dispatch = useDispatch<AppDispatch>();

  const { user } = useSelector((state: RootState) => state.user);

  const [openNonPO, setOpenNonPO] = useState(false);
  useEffect(() => {
    dispatch(getInvoiceSummary());
  }, []);

  const closeModal = () => {
    setModal(!modal);
  };
  const closePo = () => {
    setOpenNonPO(!openNonPO);
  };

  useEffect(() => {}, [modal]);
  const handleView = (item, data) => {
    window.location.href = `/buyer/statement/each-statement?id=${data.id}&vendor=${data.vendor}`;
  };
  // get vendors
  useEffect(() => {
    dispatch(getVendors(page, perPage));
  }, []);
  //   Table data
  const vendorsTable =
    vendors &&
    vendors?.data?.map((data: any) => {
      return {
        legal: data?.company_description,
        company: data.company_code,
        id: data?.id,
        vendor: data.vendor_description
      };
    });

  return (
    <section className="statement">
      <div className="container">
        <div className="mt-4 row">
          <StatsCard statsNumber={0} text="Vendor/Open Items" classes="stats-icon-orange" image={file} />
        </div>
        <div className="document mt-5">
          <h3 className="title font-weight-bold">Vendors</h3>
          <div className="d-flex  mb-3">
            <SearchIcon placeholder="Search" boxClasses="" textRef={inputRef} value={search} onChange={handleChange} />
            <Dropdown perPage={perPage} action={handleNewPage} />
          </div>
          <div className="mt-3">
            <DataTable columns={tableCol} loading={loading} onClick={handleView} data={vendorsTable} />
          </div>
          {vendors?.count > 10 ? (
            <Pagination page={page} lastPage={vendors?.pagination?.lastPage} paginate={paginate} total={vendors?.pagination?.total} handlePageChange={handlePageChange} perPage={perPage} />
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default VendorStatementBuyer;
