import DropdownMenu from "components/DropdownMenu";
import "./styles.scss";

export const fielddatas = [
    {
        code: "0010",
        name: "Purchase Requisition",
        subProcess: "Stock",
        legal: "steve manga",
        location: "All",
        level: "4",
        type: "steve manga"
    },
    {
        code: "0011",
        name: "Purchase Order",
        subProcess: "Non-Stock",
        legal: "steve manga",
        location: "All",
        level: "2",
        type: "steve manga"
    }
];

export const fieldColumn = [
    
    {
        name: "Company Id",
        index: "id",
        id: 1
    },
    {
        name: "Company",
        index: "company",
        id: 2
    }
];
