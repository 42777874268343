import React, { useState, useEffect } from "react";
import Modal from "components/Modal";
import Textbox from "components/Textbox";
import Button from "components/Button";

type Props = {
  closeModal: () => void;
  code?: string | undefined;
};
const TempCode = ({ closeModal, code }: Props) => {
  const [temp_code, setstate] = useState<undefined | string>("");

  useEffect(() => {
    code && setstate(code);
  }, [code]);

  return (
    <Modal modalText="Temp Code">
      <div>
        <p className="">
          You would use this temp code to continue your onboarding process from the last place you
          stopped
        </p>
        <form className="mt-2" noValidate>
          <Textbox
            type="text"
            name="tempcode"
            value={temp_code}
            label="Temp Code"
            placeholder="Temp Code"
            disabled={true}
          />
          <Button
            type="button"
            label="Continue"
            btnType="btn-primary"
            boxClasses="mt-4 mb-5"
            btnStyle="100%"
            onClick={closeModal}
          />
        </form>
      </div>
    </Modal>
  );
};

export default TempCode;
