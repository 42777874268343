import { Link } from "react-router-dom";

export const reportCol = [
    {
        name: "Vendor Code",
        index: "vendor_code",
        id: 1
    },
    {
        name: "Vendor Description",
        index: "vendor_description",
        id: 2
    },
    {
        name: "Total Orders",
        index: "total_purchase_orders",
        id: 3
    },
    {
        name: "Total Value",
        index: "total_value",
        id: 4
    }

];

