import "./styles.scss";
import GziLogo from "assests/images/gziLogo.png";

type LogoProps = {
    boxClasses?: string;
    classes?: string;
    small?: boolean;
};

const GZIBuyerLogo = ({ boxClasses, classes, small }: LogoProps) => {
    if (small) {
        return (
            <div className="buyer-logoWrapper d-flex flex-column align-items-center">
                <img src={GziLogo} alt="logo" className="buyer-logoWrapper_logo_small" />
                <div className="buyer-logoWrapper_banner_small">
                    <p className="text-center ">Buyer Portal</p>
                </div>
            </div>
        );
    } else {
        return (
            <div className={`buyer-logoWrapper d-flex flex-column align-items-center `}>
                <img src={GziLogo} alt="logo" className={`buyer-logoWrapper_logo ${boxClasses}`} />
                <div className={`buyer-logoWrapper_banner ${classes}`}>
                    <p className="text-center ">Buyer Portal</p>
                </div>
            </div>
        );
    }
};

export default GZIBuyerLogo;
