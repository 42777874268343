import StatsCard from "components/StatsCard";
import React, { useEffect, useState } from "react";
import "../styles.scss";
import LocationIcon from "assests/icons/buyer/location.svg";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import SearchIcon from "components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { LocationColumn, locationData } from "../data";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import CreateOrEditLocation from "components/ModalBuyer/CreateOrEditLocation";
import { deleteLocation, getLocations } from "store/slice/BuyerSlice/LocationSlice/LocationSlice";
import { toastPopUp } from "utils/utils";
import useSearch from "hooks/useSearch";
import Pagination from "components/Pagination";
import { checkPermission } from "utils/utils";

const LocationLog = () => {
    //State
    const [modal, setModal] = useState<boolean>(false);
    const [singleLocationData, setSingleLocationData] = useState<any>(null);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);

    const closeModal = () => {
        setModal((prev) => !prev);
        setSingleLocationData(null);
    };

    //Hooks
    const { search, inputRef, handleChange, searched } = useSearch(getLocations, perPage);
    const { role_access } = useSelector((state: RootState) => state.user?.user);

    //Redux
    const dispatch = useDispatch<AppDispatch>();
    const { locations, loading } = useSelector((state: RootState) => state.location);

    //Show Number for PerPage Request
    const handleNewPage = (pageNo: number, page: number = 1) => {
       
        setPerPage(pageNo);
        setPage(1);
        dispatch(getLocations(page, pageNo, search));
    };

    //Request for Location Data
    useEffect(() => {
        dispatch(getLocations(page, perPage));
    }, []);


    // setpage to the first page when a searched executed
    useEffect(() => {
        if (searched) setPage(1);
    }, [searched]);

    //Edit CompanyData
    const editCompanyData = (...rest) => {
        setSingleLocationData({ ...rest });
        setModal((prev) => !prev);
    };

    // delete
    const deleteCompany = (name: string, id: string) => {
        const payload = { location_id: id };
        toastPopUp(
            () => {
                dispatch(
                    deleteLocation(payload, () => {
                        dispatch(getLocations(page, perPage));
                    })
                );
            },
            "Delete",
            "Cancel",
            "Delete the entity",
            name,
            "red"
        );
    };

     //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, perPage: number) => {
      setPage(page);
      dispatch(getLocations(page, perPage, search));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, perPage: number) => {
      setPage(pageNo);
      dispatch(getLocations(pageNo, perPage, search));
    },
    [search]
  );

    const locationList =
        locations &&
        locations?.data?.map((company) => {
            return {
                id: company.id,
                location_description: company.location_description,
                address: company.location_address?company.location_address:'N/A',
                location_id: company.location_code,
                country_description: company.country_description,
                company_description: company.company_description,
                edit: () =>  checkPermission(role_access, "SETUP_INDEX") && editCompanyData(company),
                deleteEntity: () =>  checkPermission(role_access, "SETUP_INDEX") && deleteCompany(company.location_description, company.id)
            };
        });

    return (
        <div id="settings-location-data">
            {modal && <CreateOrEditLocation singleLocationData={singleLocationData} closeModal={closeModal} />}
            <div className="container mt-3">
                <div className="row mt-3 mb-5">
                    <StatsCard
                        statsNumber={locations?.count ?? 0}
                        text="Locations"
                        classes="stats-icon"
                        image={LocationIcon}
                        iconBackgroundColor="rgba(45, 156, 219, 0.1)"
                    />
                    <StatsCard image={AddIcon} classes="middle-img" centerText="Create Location" onClick={closeModal} />
                </div>

                <div className="d-flex  mb-3">
                    <SearchIcon
                        placeholder="Search for Location Name, Location"
                        boxClasses=""
                        textRef={inputRef}
                        value={search}
                        onChange={handleChange}
                    />
                    <Dropdown perPage={10} action={handleNewPage} />
                </div>
                <DataTable columns={LocationColumn} data={locationList} loading={loading} />

                {
                    locations?.count > 10 ?
                    
                <Pagination
                page={page}
                lastPage={locations?.pagination?.lastPage}
                paginate={paginate}
                total={locations?.pagination?.total}
                handlePageChange={handlePageChange}
                perPage={perPage}
                />
                     : null
                }

            </div>
        </div>
    );
};

export default LocationLog;
