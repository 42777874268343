import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Action, notifySetupStates } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";

const initialState: notifySetupStates = {
  loading: false,
  deleting: false,
  isLoading: false,
  notifySetup: null,
  successMessage: "",
  errorMessage: ""
};

const notifySetupSlice = createSlice({
  name: "notifySetup",
  initialState,
  reducers: {
    getNotifySetupLoading: (state) => {
      state.loading = true;
    },
    getNotifySetupSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.notifySetup = action.payload;
    },
    getNotifySetupFail: (state) => {
      state.loading = false;
    },
    createNotifySetupLoading: (state) => {
      state.isLoading = true;
    },
    createNotifySetupSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.notifySetup = action.payload;
    },
    createNotifySetupFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    deleteNotifySetupLoading: (state) => {
      state.deleting = true;
    },
    deleteNotifySetupSuccess: (state, action: PayloadAction<Action>) => {
      state.deleting = false;
      state.notifySetup = action.payload;
    },
    deleteNotifySetupFail: (state, action: PayloadAction<string>) => {
      state.deleting = false;
      state.errorMessage = action.payload;
    }
  }
});

const { actions, reducer } = notifySetupSlice;
export const {
  getNotifySetupLoading,
  getNotifySetupSuccess,
  getNotifySetupFail,
  createNotifySetupLoading,
  createNotifySetupSuccess,
  createNotifySetupFail,
  deleteNotifySetupLoading,
  deleteNotifySetupSuccess,
  deleteNotifySetupFail
} = actions;

export const getNotifySetup =
  (page?: number, length?: number, search?: string, activity?: string, user_id?: any, user_role_id?: any): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getNotifySetupLoading());
    try {
      const response = await API.get("/notification_settings/get", {
        params: {
          page,
          length,
          search,
          activity,
          user_id,
          user_role_id
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getNotifySetupSuccess(response.data));
      }
    } catch (error) {
      dispatch(getNotifySetupFail());
    }
  };

export const createNotifySetup =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(createNotifySetupLoading());
    try {
      const response = await API.post(`/notification_settings/create`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(createNotifySetupSuccess(response.data.message));
        toastr.success("", "Successful");
        cb && cb();
      }
    } catch (error) {
      if (error.response) {
        dispatch(createNotifySetupFail(error.response.data?.message));
        toastr.error(error.response.data.message);
      } else {
        dispatch(createNotifySetupFail("No network connecton"));
        toastr.error("No network connecton");
      }
      setTimeout(() => {
        dispatch(createNotifySetupFail(""));
      }, 3000);
    }
  };

export const deleteNotifySetup =
  (data?: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(deleteNotifySetupLoading());
    try {
      const response = await API.post(`/notification_settings/delete`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(deleteNotifySetupSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      dispatch(deleteNotifySetupFail(error.response.data.message));
      toastr.error(error.response.data.message);
      setTimeout(() => {
        dispatch(deleteNotifySetupFail(""));
      }, 3000);
    }
  };

export default reducer;
