import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BanksState, Action } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";

const initialState: BanksState = {
    loading: false,
    banks: null,
    successMessage: "",
    errorMessage: ""
};

const bankSlice = createSlice({
    name: "bank",
    initialState,
    reducers: {
        getAllBanksLoading: (state) => {
            state.loading = true;
        },
        getAllBanksSuccess: (state, action: PayloadAction<Action>) => {
            state.loading = false;
            state.banks = action.payload;
        },
        getAllBanksFail: (state) => {
            state.loading = false;
        }
    }
});

const { actions, reducer } = bankSlice;
export const { getAllBanksLoading, getAllBanksSuccess, getAllBanksFail } = actions;

export const getAllListedBanks =
    (offset?: string, length?: string, search?: string, country_id?: string): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        dispatch(getAllBanksLoading());
        try {
            const response = await API.get("/banks/list", {
                params: {
                    offset,
                    length,
                    search,
                    country_id
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(getAllBanksSuccess(response.data.data));
            }
        } catch (error) {
            dispatch(getAllBanksFail());
        }
    };

export default reducer;
