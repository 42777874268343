import Document from "assests/icons/sidebar/inventory.svg";
import Button from "components/Button/index";
import "./styles.scss";
import { Link } from "react-router-dom";

export const documentsColumns = [
  // {
  //     name: "",
  //     index: "",
  //     id: 1,
  //     render: () => (
  //         <span>
  //             <img src={Document} className="avatar" alt="icon" />
  //         </span>
  //     )
  // },
  {
    name: "Category Type",
    index: "category",
    id: 1
  },
  {
    name: " Category Description",
    // index: "field_label",
    id: 2,
    render: ({ link, field_label }) => {
      return (
        <a target="_blank" rel="noreferrer" href={link} className="hover-a">
          {field_label}
        </a>
      );
    }
  },
  {
    name: "Expiry Date",
    index: "expires_on",
    id: 3
  },
  {
    name: "Status",
    index: "status",
    id: 4
  },
  {
    name: "Action",
    id: 5,
    render: (item) => {
      //   <Link to={`/supplier/invoice/100`}>
      let btnText = "Replace";
      if (item.data_status !== "In Progress") {
        btnText = "View";
      }
      const openVerify = () => {
        // console.log(item.data_status === "Verify");
        if (btnText === "Done") {
          return;
        } else {
          item.open();
        }
      };
      return (
        <button
          type="button"
          style={{
            padding: "8px 16px",
            background: item?.data_status === "Replace" ? "rgba(245, 150, 37, 0.15)" : "rgba(0, 176, 7, 0.15)",
            borderRadius: "10px",
            color: item?.data_status === "Replace" ? "rgba(245, 150, 37, 1)" : "#387C57",
            cursor: item?.data_status === "Replace" || item?.data_status === "View" ? "pointer" : "default"
          }}
          onClick={() => window.open(item.link, "_blank")}
        >
          {btnText}
        </button>
      );
      //   </Link>
    }
  }
];
