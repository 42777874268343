import { formatCurrency } from "utils/utils";

export const debitLineColumns = [
  {
    name: "",
    id: 0,
    render: (item) => {
      return (
        <input
          onChange={(e: any) => {
            if (item.checkDebitLines?.includes(item.document_number)) {
              item.remove(item.id);
            } else {
              item.add(item.id);
            }
          }}
          checked={item.checkDebitLines?.includes(item.document_number)}
          type="checkbox"
          name=""
          id=""
          style={{ cursor: "pointer" }}
          disabled={item.loading}
        />
      );
    }
  },
  {
    name: "Document Type",
    index: "document_type",
    id: 1
  },
  {
    name: "Document No.",
    index: "document_number",
    id: 2
  },
  {
    name: "Payment Date",
    index: "payment_date",
    id: 4
  },
  {
    name: "Amt",
    // index: "amount",
    render: (item) => {
      return <span>{formatCurrency(item.amount)}</span>;
    },
    id: 5
  },
  {
    name: "Text",
    index: "invoice_debit_description",
    id: 6
  }
];
