import * as React from "react";
import "./styles.scss";
import People from "assests/icons/statsCard/person 1.png";
import Leaf from "assests/icons/statsCard/leaf-sharp.png";
import StatsCard from "components/StatsCard";
import DataTable from "components/DataTable";
import { roleColumn } from "./data";
import SearchIcon from "components/SearchBar";
import Icon from "assests/icons/statsCard/Vector (5).png";
// import CreateRoleOrEdit from "components/Modals/CreateRoleOrEdit";
import useSearch from "hooks/useSearch";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import Pagination from "components/Pagination";
import Dropdown from "components/Dropdown";
import { toastr } from "react-redux-toastr";
import { checkPermission, toastPopUp } from "utils/utils";
import BuyerCreateRoles from "components/ModalBuyer/CreateRole";
import { deleteRole, getPermissions, getRoles } from "store/slice/Roleslice/RoleSlice";

type Props = {};

const Roles: React.FunctionComponent<Props> = () => {
  const [page, setPage] = React.useState<number>(1);
  const [length, setLength] = React.useState<number>(10);
  const [modal, setModal] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<string>("");
  const [roleId, setRoleId] = React.useState<string>("");
  const [editedPerm, setEditedPerm] = React.useState<any>([]);
  const { search, inputRef, handleChange, searched } = useSearch(getRoles, length);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getRoles(page, length));
    // dispatch(getPermissions("buyer"));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Hooks
  const { roles, isLoading, permissions } = useSelector((state: RootState) => state.roles);
  const { role_access } = useSelector((state: RootState) => state.user?.user);
  // console.log(permissions);
  // console.log(role_access);

  React.useEffect(() => {
    console.log(checkPermission(role_access, "USER_ROLES_INDEX"));
  }, [role_access]);
  const userPerm = role_access.map((data) => data.permission_name);
  // console.log(userPerm);

  const edit = (e: any, id: any, permissions: any) => {
    closeModal();
    setValue(e);
    setRoleId(id);
    setEditedPerm(permissions);
  };

  const deleteRoleId = (e: any, name: string) => {
    // if (!permissions?.includes("ROLE_MANAGE")) {
    //   toastr.error("", "You don't have the Admin rights to do this");
    //   return;
    // }

    toastPopUp(
      () => {
        dispatch(
          deleteRole(e, () => {
            dispatch(getRoles(page, length));
          })
        );
      },
      "Delete",
      "Cancel",
      "Delete the role",
      name,
      "red"
    );
  };

  const rolesList =
    roles &&
    roles?.data?.map((data: any) => {
      return {
        role_name: data.user_role_name,
        // users: data.users,
        // role: data.role_name.role_name,
        edit: checkPermission(role_access, "USER_ROLES_EDIT") && edit,
        deleteRoleId: checkPermission(role_access, "USER_ROLES_DELETE") && deleteRoleId,
        permission: data.permission,
        id: data.id
      };
    });

  //--------------------------------------------
  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, length: number) => {
      setPage(page);
      dispatch(getRoles(page, length, search));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, length: number) => {
      setPage(pageNo);
      dispatch(getRoles(pageNo, length, search));
    },
    [search]
  );

  //------------------------------------------------------------
  //Show Number for PerPage Function
  //------------------------------------------------------------
  const handleNewPage = React.useCallback(
    (pageNo: number, page = 1) => {
      setLength(pageNo);
      setPage(1);
      dispatch(getRoles(page, pageNo, search));
    },
    [search]
  );

  const closeModal = () => {
    setModal(!modal);
    setValue("");
    // dispatch(createRoleFail(""));
  };

  return (
    <div id="buyer-roles-page">
      {/* {modal && checkPermission(permissions, "ROLE_MANAGE") && ( */}
      {modal && <BuyerCreateRoles closeModal={closeModal} value={value} roleId={roleId} checkedPerm={editedPerm} />}
      <div className="container">
        <div className="row mt-3 mb-5">
          <StatsCard statsNumber={roles?.count} text="Role" classes="stats-icon-cash" image={People} />
          <StatsCard image={Icon} classes="middle-img" centerText="Create Role" onClick={(checkPermission(role_access, "USER_ROLES_CREATE") as any) && closeModal} />
        </div>
        <h4 className="page-title mb-2">Roles</h4>

        <div className="search-field">
          <SearchIcon placeholder="Search for role names" boxClasses=" mb-3" textRef={inputRef} value={search} onChange={handleChange} />
          <Dropdown perPage={length} action={handleNewPage} />
        </div>
        <DataTable columns={roleColumn} data={rolesList} loading={isLoading} />
        <Pagination page={page} lastPage={roles?.pagination?.pageCount} paginate={paginate} total={roles?.pagination?.total} handlePageChange={handlePageChange} perPage={length} />
      </div>
    </div>
  );
};

export default Roles;
