/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import StatsCard from "components/StatsCard";
import React, { useState } from "react";
import "./styles.scss";
import workflow from "assests/icons/buyer/workflow.svg";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import SearchIcon from "components/SearchBar";
import Dropdown from "components/Dropdown";
import { AppDispatch, RootState } from "store/store";
import DataTable from "components/DataTable";
import { fielddatas, fieldColumn } from "./data";
import CreateOnBoard from "components/ModalBuyer/CreateOnBoard";
import { useDispatch, useSelector } from "react-redux";
import useSearch from "hooks/useSearch";
import { deleteField, editField, getFields } from "store/slice/BuyerSlice/onBoardSlice/onBoardSlice";
import { useEffect } from "react";
import { toastPopUp } from "utils/utils";
import Pagination from "components/Pagination";
import { getAllCompaniesData } from "store/slice/BuyerSlice/CompanyDataSlice/CompanyDataSlice";
import { useHistory } from "react-router-dom";

const Onboarding = () => {
  //State
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [singleField, setSingleField] = useState<any>(null);
  const history = useHistory();

  //Redux
  const dispatch = useDispatch<AppDispatch>();

  const { companies, loading } = useSelector((state: RootState) => state.companiesData);

  const [modal, setModal] = useState<boolean>(false);
  const closeModal = () => {
    setModal((prev) => !prev);
  };

  //Hook
  const { search, inputRef, handleChange, searched } = useSearch(getAllCompaniesData, perPage);

  //Show Number for PerPage Request
  const handleNewPage = (pageNo: number, page: number = 1) => {
    setPerPage(pageNo);
    setPage(page);
    dispatch(getAllCompaniesData(page, perPage, search));
  };

  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, perPage: number) => {
      setPage(page);
      dispatch(getAllCompaniesData(page, perPage, search));
    },
    [search]
  );

  useEffect(() => {
    dispatch(getAllCompaniesData(page, perPage));
  }, []);

  const paginate = React.useCallback(
    (pageNo: number, perPage: number) => {
      setPage(pageNo);
      dispatch(getAllCompaniesData(pageNo, perPage, search));
    },
    [search]
  );

  // setpage to the first page when a searched executed
  useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  useEffect(() => {
    dispatch(getAllCompaniesData(page, perPage));
  }, []);

  const companyList =
    companies &&
    companies?.data?.map((company) => {
      return {
        id: company.id,
        company: company.company_description
      };
    });

  const showProcess = (e, item) => {
    localStorage.setItem("name", item.company);
    history.push({ pathname: `/buyer/settings/onboarding/each/${item.id}` });
  };

  return (
    <div id="settings-approval">
      <div className="container">
        <h3 className="page-title">Select Company</h3>
        <div className="row mt-3 mb-5"></div>

        <div className="d-flex  mb-3">
          <SearchIcon placeholder="Search Company" boxClasses="" textRef={inputRef} value={search} onChange={handleChange} />
          <Dropdown perPage={perPage} action={handleNewPage} />
        </div>
        <DataTable columns={fieldColumn} data={companyList} onClick={showProcess} loading={loading} />
        {companies?.count > 10 ? (
          <Pagination page={page} lastPage={companies?.pagination?.lastPage} paginate={paginate} total={companies?.pagination?.total} handlePageChange={handlePageChange} perPage={perPage} />
        ) : null}
      </div>
    </div>
  );
};

export default Onboarding;
