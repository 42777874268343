import React from "react";
import LeftArrow from "assests/icons/left.png";
import RightArrow from "assests/icons/right.png";
import "./styles.scss";

type Props = {
    handlePageChange: (page: number, perPage: number) => void;
    page: number;
    lastPage: number;
    total: number;
    perPage: number;
    paginate: (pageNo: number, perPage: number) => void;
};
const Pagination: React.FunctionComponent<Props> = ({ handlePageChange, page, lastPage, total, perPage, paginate }) => {
    const nextClick = () => {
        if (page === lastPage) return;
        handlePageChange(page + 1, perPage);
    };

    const backClick = () => {
        if (page === 1) return;
        handlePageChange(page - 1, perPage);
    };

    const pageNumbers = [] as any;

    // for (let i = 1; i <= Math.ceil(total / perPage); i++) {
    //     pageNumbers.push({ id: i, current: true, ellipsis: false });
    // }

    const pages = Math.ceil(total / perPage);
    let ellipsisLeft = false;
    let ellipsisRight = false;
    for (let i = 1; i <= pages; i++) {
        if (i === page) {
            pageNumbers.push({ id: i, current: true, ellipsis: false });
        } else {
            if (i < 2 || i > pages - 1 || i === page - 1 || i === page + 1) {
                pageNumbers.push({ id: i, current: false, ellipsis: false });
            } else if (i > 1 && i < page && !ellipsisLeft) {
                pageNumbers.push({ id: i, current: false, ellipsis: true });
                ellipsisLeft = true;
            } else if (i < pages && i > page && !ellipsisRight) {
                pageNumbers.push({ id: i, current: false, ellipsis: true });
                ellipsisRight = true;
            }
        }
    }

    return (
        <div id="pagination-view">
            <div className="d-flex align-items-center justify-content-center mt-3">
                <button type="button" className="btn btn-default mr-3 d-flex align-items-center" onClick={backClick}>
                    <img src={LeftArrow} alt="arrow" className="mr-2" />
                </button>
                <ul className="d-flex m-0 page-numbers">
                    {pageNumbers.map((no: number, index) => {
                        if (!no["ellipsis"]) {
                            if (page > Math.ceil(total / perPage)) page = 1;
                            return (
                                <li className={`${pageNumbers.length - 1 !== index && "mr-3"}`} key={index}>
                                    <a
                                        href="javascript(0)"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            paginate(no["id"], perPage);
                                        }}
                                        className={`cursor-pointer ${page === no["id"] && "page-color"}`}
                                    >
                                        {no["id"]}
                                    </a>
                                </li>
                            );
                        } else {
                            return (
                                <li key={index}>
                                    <span className="">&hellip;&nbsp;&nbsp;</span>
                                </li>
                            );
                        }
                    })}
                </ul>

                <button type="button" className="btn btn-default ml-3 d-flex align-items-center" onClick={nextClick}>
                    <img src={RightArrow} alt="arrow" className="ml-2" />
                </button>
            </div>
        </div>
    );
};

export default Pagination;
