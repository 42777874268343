import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { productState, Action } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";
import { useState } from "react";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const initialState: productState = {
  loading: false,
  isLoading: false,
  deleting: false,
  materials: null,
  s3url: null,
  materialCategory: null,
  successMessage: "",
  errorMessage: ""
};



const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    getAllMaterialsLoading: (state) => {
      state.loading = true;
    },
    getAllMaterialSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.materials = action.payload;
    },
    getAllMaterialFail: (state) => {
      state.loading = false;
    },
    getAllMaterialCategoryLoading: (state) => {
      state.loading = true;
    },
    getAllMaterialCategorySuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.materialCategory = action.payload;
    },
    getAllMaterialCategoryFail: (state) => {
      state.loading = false;
    },
    createProductDataLoading: (state) => {
      state.isLoading = true;
    },
    createProductCategoryLoading: (state) => {
      state.isLoading = true;
    },
    createProductDataSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.materials = action.payload;
    },
    createProductCategorySuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.materials = action.payload;
    },
    createProductDataFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    createProductCategoryFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    editProductDataLoading: (state) => {
      state.isLoading = true;
    },
    editProductCategoryLoading: (state) => {
      state.isLoading = true;
    },
    editProductDataSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.materials = action.payload;
    },
    editProductCategorySuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.materials = action.payload;
    },
    editProductDataFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    editProductCategoryFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    deleteProductDataLoading: (state) => {
      state.deleting = true;
    },
    deleteProductDataSuccess: (state, action: PayloadAction<Action>) => {
      state.deleting = false;
      state.materials = action.payload;
    },
    deleteProductDataFail: (state, action: PayloadAction<string>) => {
      state.deleting = false;
      state.errorMessage = action.payload;
    },
    deleteCategoryLoading: (state) => {
      state.deleting = true;
    },
    deleteCategorySuccess: (state, action: PayloadAction<Action>) => {
      state.deleting = false;
      state.materialCategory = action.payload;
    },
    deleteCategoryFail: (state, action: PayloadAction<string>) => {
      state.deleting = false;
      state.errorMessage = action.payload;
    },
    uploadingImageRequest: (state) => {
      state.isLoading = true;
    },
    uploadingImageSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.s3url = action.payload;
    },
    uploadingImageFail: (state) => {},
  }
});

const { actions, reducer } = productSlice;
export const {
  getAllMaterialsLoading,
  getAllMaterialSuccess,
  getAllMaterialFail,
  createProductDataLoading,
  createProductCategoryLoading,
  createProductDataSuccess,
  createProductCategorySuccess,
  createProductDataFail,
  createProductCategoryFail,
  editProductDataLoading,
  editProductCategoryLoading,
  editProductDataSuccess,
  editProductCategorySuccess,
  editProductDataFail,
  editProductCategoryFail,
  deleteProductDataLoading,
  deleteProductDataSuccess,
  deleteProductDataFail,
  deleteCategoryLoading,
  deleteCategorySuccess,
  deleteCategoryFail,
  getAllMaterialCategoryLoading,
  getAllMaterialCategorySuccess,
  uploadingImageRequest,
  uploadingImageSuccess,
  uploadingImageFail,
  getAllMaterialCategoryFail
} = actions;

export const getMaterials =
  (
    page?: number,
    length?: number,
    search?: string,
    material_category_id?: string,
    material_code?: string
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getAllMaterialsLoading());
    try {
      const response = await API.get("/materials/get", {
        params: {
          page,
          length,
          search,
          material_category_id,
          material_code
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getAllMaterialSuccess(response.data));
      }
    } catch (error) {
      dispatch(getAllMaterialFail());
    }
  };

export const getMaterialCategories =
  (
    page?: number,
    length?: number,
    search?: string,
    parent_category_id?: string,
    material_category_code?: string,
    material_category_type?:string
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getAllMaterialCategoryLoading());
    try {
      const response = await API.get("/material_categories/get", {
        params: {
          page,
          length,
          search,
          parent_category_id,
          material_category_code,
          material_category_type
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getAllMaterialCategorySuccess(response.data));
      }
    } catch (error) {
      dispatch(getAllMaterialCategoryFail());
    }
  };

export const createProductData =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(createProductDataLoading());
    try {
      const response = await API.post(`/materials/create`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(createProductDataSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
    
      if (error.response) {
        dispatch(createProductDataFail(error.response.data?.message));
        toastr.error(error.response.data.message);
      }
      else{
        dispatch(createProductDataFail("No network connecton"));
        toastr.error("No network connecton");
      }
      setTimeout(() => {
        dispatch(createProductDataFail(""));
      }, 3000);
    }
  };


  export const uploadFile =
  (payload): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
      dispatch(uploadingImageRequest());
      const { userToken } = getState().user;
      try {
          const response = await axios.post(`${BASE_URL}/prometheus/upload_file`, payload, {
              headers: {
                  Authorization: `Bearer ${userToken}`,
                  "Content-Type": "multipart/form-data"
              }
          });
          if ([200, 201].includes(response.status)) {
              dispatch(uploadingImageSuccess(response.data.s3url));
              toastr.success("", "Successfully Uploaded");
           
           
          }
      } catch (error) {
          dispatch(uploadingImageFail());
      }
  };

  export const createProductCategory =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(createProductCategoryLoading());
    try {
      const response = await API.post(`/material_categories/create`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(createProductCategorySuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      
      if (error.response) {
        dispatch(createProductCategoryFail(error.response.data?.message));
        toastr.error(error.response.data.message);
      }
      else{
        dispatch(createProductCategoryFail("No network connecton"));
        toastr.error("No network connecton");
      }
      setTimeout(() => {
        dispatch(createProductCategoryFail(""));
      }, 3000);
    }
  };

export const editProductData =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(editProductDataLoading());
    try {
      const response = await API.post(`/materials/edit`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(editProductDataSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      
      if (error.response) {
        dispatch(editProductDataFail(error.response.data.message));
        toastr.error(error.response.data.message);
      }
      else{
        dispatch(editProductDataFail("No network connecton"));
        toastr.error("No network connecton");
      }
      setTimeout(() => {
        dispatch(editProductDataFail(""));
      }, 3000);
    }
  };

  export const editProductCategory =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(editProductCategoryLoading());
    try {
      const response = await API.post(`/material_categories/edit`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(editProductCategorySuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {

      
      if (error.response) {
        dispatch(editProductCategoryFail(error.response.data.message));
      toastr.error(error.response.data.message);
      }
      else{
        dispatch(editProductCategoryFail("No network connecton"));
        toastr.error("No network connecton");
      }
      setTimeout(() => {
        dispatch(editProductCategoryFail(""));
      }, 3000);
    }
  };

export const deleteProductData =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(deleteProductDataLoading());
    try {
      const response = await API.post(`/materials/delete`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(deleteProductDataSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      dispatch(deleteProductDataFail(error.response.data.message));
      toastr.error(error.response.data.message);
      setTimeout(() => {
        dispatch(deleteProductDataFail(""));
      }, 3000);
    }
  };

  export const deleteCategory =
    (data?: any, cb?: () => void): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(deleteCategoryLoading());
        try {
            const response = await API.post(`material_categories/delete`, data, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(deleteCategorySuccess(response.data.message));
                toastr.success("", response.data.message);
                cb && cb();
            }
        } catch (error) {
            dispatch(deleteCategoryFail(error.response.data.message));
            toastr.error(error.response.data.message);
            setTimeout(() => {
                dispatch(deleteCategoryFail(""));
            }, 3000);
        }
    };

export default reducer;
