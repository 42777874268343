import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ReportState, Action } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const initialState: ReportState = {
  loading: false,
  isLoading:false,
  deleting: false,
  reports: null,
  successMessage: "",
  errorMessage: ""
};

const reportSlice = createSlice({
  name: "reportData",
  initialState,
  reducers: {
    getAllReportLoading: (state) => {
      state.loading = true;
    },
    getAllReportSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.reports = action.payload;
    },
    getAllReportFail: (state) => {
      state.loading = false;
    },
    createReportLoading: (state) => {
      state.isLoading = true;
    },
    createReportSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.reports = action.payload;
    },
    createReportFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    editReportLoading: (state) => {
      state.isLoading = true;
    },
    editReportSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.reports = action.payload;
    },
    editReportFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    deleteReportLoading: (state) => {
      state.deleting = true;
    },
    deleteReportSuccess: (state, action: PayloadAction<Action>) => {
      state.deleting = false;
      state.reports = action.payload;
    },
    deleteReportFail: (state, action: PayloadAction<string>) => {
      state.deleting = false;
      state.errorMessage = action.payload;
    }
  }
});

const { actions, reducer } = reportSlice;
export const {
  getAllReportLoading,
  getAllReportSuccess,
  getAllReportFail,
  createReportLoading,
  createReportSuccess,
  createReportFail,
  editReportLoading,
  editReportSuccess,
  editReportFail,
  deleteReportLoading,
  deleteReportSuccess,
  deleteReportFail,
} = actions;




// get all reports
export const getAllReport =
  (
    page?: number,
    length?: number,
    search?: string,
    department_status?: string,
    department_code?: string,
    company_id?: string,
    location_id?: string,
    hod_user_id?: string
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getAllReportLoading());
    try {
      const response = await API.get("/reports/get", {
        params: {
          page,
          length,
          search,
          department_status,
          department_code,
          company_id,
          location_id,
          hod_user_id
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getAllReportSuccess(response.data));
      }
    } catch (error) {
      dispatch(getAllReportFail());
    }
  };


// create company
export const createReportData =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(createReportLoading());
    try {
      const response = await API.post(`/reports/create`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(createReportSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      if (error.response) {
        dispatch(createReportFail(error.response.data?.message));
        toastr.error(error.response.data.message);
      }
      else{
        dispatch(createReportFail("No network connecton"));
        toastr.error("No network connecton");
      }
     
      setTimeout(() => {
        dispatch(createReportFail(""));
      }, 3000);
    }
  };


// edit company data
export const editReportData =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(editReportLoading());
    try {
      const response = await API.post(`/reports/edit`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(editReportSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      if (error.response) {
        dispatch(editReportFail(error.response.data.message));
        toastr.error(error.response.data.message);
      }
      else{
        dispatch(createReportFail("No network connecton"));
        toastr.error("No network connecton");
      }
      
      setTimeout(() => {
        dispatch(editReportFail(""));
      }, 3000);
    }
  };


  // delete company data
export const deleteReportData =
  (data?: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(deleteReportLoading());
    try {
      const response = await API.post(`/locations/delete`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(deleteReportSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      if (error.response) {
        dispatch(deleteReportFail(error.response.data.message));
        toastr.error(error.response.data.message);
      }
      else{
        dispatch(deleteReportFail("No internet connection"));
        toastr.error("No internet connection");
      }
     
      setTimeout(() => {
        dispatch(deleteReportFail(""));
      }, 3000);
    }
  };

export default reducer;
