import * as React from "react";
import "./styles.scss";
import TabMenu from "components/TabMenu";
import { Route, Switch, Redirect, useRouteMatch, useLocation, useHistory, useParams } from "react-router-dom";
import Overview from "./Overview";
import Star from "components/Star";
import TechnicalRequirements from "./TechnicalRequirement";
import CommercialRequirements from "./CommercialRequirements";
import Communication from "./Communication";
import { AppDispatch, RootState } from "store/store";
import { useDispatch, useSelector } from "react-redux";
import { confirmBid, getBid, negotiateBid, submitBid } from "store/slice/SupplierSlices/BidSlice/BidsSlice";
import { checkPermission, removeZero, toastPopUp } from "utils/utils";
import { getCurrency } from "store/slice/CurrencySlice/CurrencySlice";

type BidProps = {};

const Bid: React.FC<BidProps> = () => {
  const menusTech = [
    {
      name: "Overview",
      path: "overview"
    },
    {
      name: "Technical Requirement",
      path: "tech-requirements"
    },
    {
      name: "Commercial Requirement",
      path: "commercial-requirements"
    }
    // {
    //   name: "Communication",
    //   path: "communication"
    // }
  ];
  const menusNoTech = [
    {
      name: "Overview",
      path: "overview"
    },
    {
      name: "Commercial Requirement",
      path: "commercial-requirements"
    }
    // {
    //   name: "Communication",
    //   path: "communication"
    // }
  ];
  const match = useRouteMatch();

  //Redux Store
  const { bid, loading } = useSelector((state: RootState) => state.bids);
  const { user } = useSelector((state: RootState) => state.user);
  const { role_access } = useSelector((state: RootState) => state.user?.user);
  const { currency } = useSelector((state: RootState) => state.currency);

  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const history = useHistory();
  const [tabNames, setTabNames] = React.useState([] as any);
  const { bidNo } = useParams() as any;
  const responses = bid && (bid[0]?.rfp_responses as any);
  const responseStat = bid && ((bid[0]?.rfp_vendor_status === "2") as any);
  const [allowClick, setAllowClick] = React.useState(false);
  const [techres, setTechres] = React.useState(false);
  const [comRes, setComRes] = React.useState(false);
  console.log(bid, "bidsss");
  React.useEffect(() => {
    if (bidNo) {
      //   const { state } = location as any;
      dispatch(getBid(bidNo));
    } else history.push("/supplier/bids");
  }, []);
  let test = [] as any;
  let newData = [] as any;
  bid &&
    bid[0]?.rfp_fields?.forEach((items, i) => {
      if (items.field_type !== "") {
        test.push(items);
        if (tabNames.includes(items.category)) {
          return;
        } else setTabNames([...tabNames, items.category as any]);
        return items;
      }
    });

  React.useEffect(() => {
    // console.log(bid[0]?.requisition[0]?.currency_id, "currency");
    if (bid && bid[0]?.requisition[0]?.currency_id) {
      dispatch(getCurrency(bid[0]?.requisition[0]?.currency_id));
    }
  }, [bid]);

  // React.useEffect(() => {
  for (let i = 0; i < tabNames.length; i++) {
    let data = {};
    data = { ...data, header: tabNames[i], array: [] };
    test.forEach((item, i) => {
      if (item.category === data["header"]) {
        data["array"] = [
          ...data["array"],

          {
            label: item.field_label,
            value: item.response_scores,
            id: item.id,
            name: item.field_name,
            type: item.field_type,
            options: item.field_options
          }
        ];
      }
    });
    newData.push(data);
  }
  // }, [tabNames]);

  const Technical = newData.find((item) => item.header === "Technical");
  const Commercial = newData.find((item) => item.header === "Commercial");
  const techResponses = JSON.parse(localStorage.getItem("Technical") as any);
  const commercialResponses = JSON.parse(localStorage.getItem("Commercial") as any);

  const submitRfp = () => {
    const techResponses = JSON.parse(localStorage.getItem("Technical") as any);
    const commercialResponses = JSON.parse(localStorage.getItem("Commercial") as any);
    console.log(commercialResponses, "commercialResponses");
    // if (techResponses === null || commercialResponses === null) return;
    let responses;
    if (Technical !== undefined && Technical.array.length > 0) {
      responses = commercialResponses.concat(techResponses);
    } else responses = commercialResponses;
    const data = responses.map((res, i) => {
      if (res?.file_extension && res?.file_extension !== null) {
        return {
          vendor_response: res.vendor_response,
          rfp_field_id: res.rfp_field_id,
          file_extension: res.file_extension
        };
      } else if (res?.vendor_response?.includes("https")) {
        return {
          vendor_response: res.vendor_response,
          rfp_field_id: res.rfp_field_id,
          file_extension: res.vendor_response.split(".").pop()
        };
      } else if (res?.field_name?.includes("price")) {
        return {
          vendor_response: res.vendor_response,
          rfp_field_id: res.rfp_field_id,
          vendor_response_gross: res.vendor_response_gross,
          vendor_response_discount: res.vendor_response_discount ? res.vendor_response_discount : 0
        };
      }
      //  else if (res?.field_name?.includes("freigth")) {
      //   return {
      //     vendor_response: res.vendor_response,
      //     rfp_field_id: res.rfp_field_id,
      //     vendor_response_gross: res.vendor_response_gross
      //   };
      // }
      // else if (res?.field_name?.includes("service")) {
      //   return {
      //     vendor_response: res.vendor_response,
      //     rfp_field_id: res.rfp_field_id,
      //     vendor_response_gross: res.vendor_response_gross
      //   };
      // }
      else
        return {
          vendor_response: res?.vendor_response,
          rfp_field_id: res?.rfp_field_id
        };
    });

    const payload = {
      rfp_id: bidNo,
      reponses: [...data]
    };
    dispatch(
      submitBid(payload, () => {
        history.push("/supplier/bids");
        ["Commercial", "Technical"].forEach((k) => localStorage.removeItem(k));
      })
    );
  };

  const negotiateRfp = () => {
    const techResponses = JSON.parse(localStorage.getItem("Technical") as any);
    const commercialResponses = JSON.parse(localStorage.getItem("Commercial") as any);
    console.log(commercialResponses, "commercialResponses");
    // if (techResponses === null || commercialResponses === null) return;
    let responses;
    if (Technical !== undefined && Technical.array.length > 0) {
      responses = commercialResponses.concat(techResponses);
    } else responses = commercialResponses;
    const data = responses.map((res, i) => {
      if (res?.file_extension && res?.file_extension !== null) {
        return {
          vendor_response: res.vendor_response,
          rfp_field_id: res.rfp_field_id,
          file_extension: res.file_extension
        };
      } else if (res?.vendor_response?.includes("https")) {
        return {
          vendor_response: res.vendor_response,
          rfp_field_id: res.rfp_field_id,
          file_extension: res.vendor_response.split(".").pop()
        };
      } else if (res?.field_name?.includes("price")) {
        return {
          vendor_response: res.vendor_response,
          rfp_field_id: res.rfp_field_id,
          vendor_response_gross: res.vendor_response_gross,
          vendor_response_discount: res.vendor_response_discount ? res.vendor_response_discount : 0
        };
      } else
        return {
          vendor_response: res?.vendor_response,
          rfp_field_id: res?.rfp_field_id
        };
    });

    const payload = {
      rfp_id: bidNo,
      reponses: [...data]
    };
    dispatch(
      negotiateBid(payload, () => {
        history.push("/supplier/bids");
        ["Commercial", "Technical"].forEach((k) => localStorage.removeItem(k));
      })
    );
  };

  // const confirmRfp = () => {
  //   if (responses && responses.length < 1) return;
  //   const payload = {
  //     rfp_id: bidNo
  //   };
  //   // console.log(payload, "payload");
  //   // console.log(responses, "resonses");
  //   // console.log(data, "data");
  //   dispatch(
  //     confirmBid(payload, () => {
  //       history.push("/supplier/bids");
  //       ["Commercial", "Technical"].forEach((k) => localStorage.removeItem(k));
  //     })
  //   );
  //   // ["Commercial", "Technical"].forEach((k) => localStorage.removeItem(k));
  // };

  const doConfirm = () => {
    if (responses && responses.length < 1) return;
    const payload = {
      rfp_id: bidNo
    };
    toastPopUp(
      () => {
        dispatch(
          confirmBid(payload, () => {
            history.push("/supplier/bids");
            ["Commercial", "Technical"].forEach((k) => localStorage.removeItem(k));
          })
        );
      },
      "Confirm",
      "Cancel",
      "Confirm Submit?",
      "",
      "#737a91"
    );
  };

  React.useEffect(() => {
    // if (comRes && techres) {
    //   setAllowClick(true);
    // } else {
    //   setAllowClick(false);
    // }
    if (Technical !== undefined && Technical.array.length > 0) {
      // if (techResponses === null || commercialResponses === null) return;
      if (comRes && techres) {
        setAllowClick(true);
      } else {
        setAllowClick(false);
      }
    } else {
      if (comRes) {
        setAllowClick(true);
      } else {
        setAllowClick(false);
      }
    }
  }, [techres, comRes]);

  const doSubmitRfp = () => {
    // if (!allowClick) return;
    const techResponses = JSON.parse(localStorage.getItem("Technical") as any);
    const commercialResponses = JSON.parse(localStorage.getItem("Commercial") as any);
    // if (techResponses === null || commercialResponses === null) return;
    if (Technical !== undefined && Technical.array.length > 0) {
      if (techResponses === null || commercialResponses === null) return;
    } else if (commercialResponses === null) return;
    toastPopUp(
      () => {
        submitRfp();
      },
      "Save",
      "Cancel",
      "Save Response?",
      "",
      "#737a91"
    );
  };

  const doNegotiateRfp = () => {
    // if (!allowClick) return;
    const techResponses = JSON.parse(localStorage.getItem("Technical") as any);
    const commercialResponses = JSON.parse(localStorage.getItem("Commercial") as any);
    // if (techResponses === null || commercialResponses === null) return;
    if (Technical !== undefined && Technical.array.length > 0) {
      if (techResponses === null || commercialResponses === null) return;
    } else if (commercialResponses === null) return;
    negotiateRfp();
  };

  const updateTech = () => {
    setTechres(true);
  };
  const updateComm = () => {
    setComRes(true);
  };

  return (
    <div id="settings-page">
      <div className="container">
        <div className="d-flex align-items-center mb-3">
          <div className="d-flex">
            <h4 className="page-title mr-2 pl-md-0 " style={{ fontSize: "1.125em" }}>
              {user?.vendor_description} | {removeZero(user?.vendor_code)}
            </h4>
            <Star stars={user && user.overall_ratings} disabled={true} />
          </div>

          {!responseStat && (
            <div className="d-flex justify-content-center align-items-center ml-auto" style={{ columnGap: "2rem" }}>
              {bid && bid[0]?.rfp_vendor_status === "4" ? (
                <button
                  type="button"
                  disabled={loading || !allowClick}
                  style={{
                    padding: "8px 30px",
                    background: " white",
                    borderRadius: "10px",
                    color: "#737A91",
                    border: "1px solid #DFE2E6",
                    boxSizing: "border-box"
                  }}
                  onClick={doNegotiateRfp}
                >
                  {loading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Negotiate"}
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    disabled={loading || !allowClick}
                    style={{
                      padding: "8px 30px",
                      background: " white",
                      borderRadius: "10px",
                      color: "#737A91",
                      border: "1px solid #DFE2E6",
                      boxSizing: "border-box"
                    }}
                    onClick={doSubmitRfp}
                  >
                    {loading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Save to Draft"}
                  </button>
                  <button
                    type="submit"
                    disabled={loading || responses?.length < 1}
                    style={{
                      padding: "8px 30px",
                      background: responses?.length < 1 ? "rgb(0, 176, 165, 0.5)" : "#00B0A5",
                      borderRadius: "10px",
                      color: "white"
                    }}
                    onClick={(checkPermission(role_access, "RFP_ACCEPT_INVITE") as any) && doConfirm}
                  >
                    {loading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Respond"}
                  </button>
                </>
              )}
            </div>
          )}
        </div>

        <div className="row">
          <div className="menus col-lg-10 col-md-12">
            <TabMenu menus={Technical !== undefined && Technical.array.length > 0 ? menusTech : menusNoTech} classes="mt-0" />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <Switch>
              <Route path={match.path} exact>
                <Redirect to={`${match.url}/overview`} />
              </Route>
              <Route path={`${match.path}/overview`}>
                <Overview />
              </Route>
              <Route path={`${match.path}/tech-requirements`}>
                <TechnicalRequirements fields={Technical?.array} responses={responses} responseStat={responseStat} updateTech={updateTech} />
              </Route>
              <Route path={`${match.path}/commercial-requirements`}>
                <CommercialRequirements bid={bid} fields={Commercial?.array} responses={responses} responseStat={responseStat} updateComm={updateComm} currency={currency} />
              </Route>
              {/* <Route path={`${match.path}/communication`}>
                <Communication />
              </Route> */}
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bid;
