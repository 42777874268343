import DropdownMenu from "components/DropdownMenu";
import { Link } from "react-router-dom";
import { ReactComponent as GoArr } from "../../../assests/icons/goArr.svg";
import i from "../../../assests/images/i.jpg";
import "./styles.scss";
import DescriptionIcon from "@mui/icons-material/Description";
import IconButton from "@mui/material/IconButton";
export const postedInvoiceCol = [
  // {
  //   name: "Invoice Date",
  //   index: "Invoice_date",
  //   id: 1
  // },
  {
    name: "Posting Date",
    index: "posting_date",
    id: 2
  },
  {
    name: "Vendor Name",
    index: "vendor_name",
    id: 3
  },
  {
    name: "PO Number",
    index: "po_number",
    id: 4
  },
  {
    name: "Invoice Number",
    index: "invoice_number",
    id: 5
  },
  {
    name: "Invoice Amount",
    index: "invoice_amount",
    id: 6
  },

  {
    name: "Invoice Notes",
    id: 8,
    render: (item) => {
      return (
        <IconButton onClick={item.readNote} aria-label="delete">
          <DescriptionIcon />
        </IconButton>
      );
    }
  },
  {
    name: "Status",
    id: 7,
    render: (item) => {
      if (item.status === "1") {
        return (
          <button
            style={{
              background: "rgba(0, 176, 7, 0.15)",
              borderRadius: "10px",
              color: "rgb(56, 124, 87)",
              padding: "8px 16px"
            }}
          >
            Approved
          </button>
        );
      }
      if (item.status === "-1") {
        return (
          <button
            style={{
              padding: "8px 16px",
              background: "rgba(239, 196, 66, 0.15)",
              borderRadius: "10px",
              color: "rgb(47, 128, 237)"
            }}
          >
            Pending
          </button>
        );
      }
      if (item.status === "-2") {
        return (
          <button
            style={{
              padding: "8px 16px",
              background: "rgba(245, 150, 37, 0.15)",
              borderRadius: "10px",
              color: "rgb(245, 150, 37)"
            }}
          >
            In Draft
          </button>
        );
      }
      if (item.status === "2") {
        return (
          <button
            style={{
              padding: "8px 16px",
              background: "rgba(245, 150, 37, 0.15)",
              borderRadius: "10px",
              color: "rgb(245, 150, 37)"
            }}
          >
            Invoice Paid
          </button>
        );
      }
      if (item.status === "3") {
        return (
          <button
            style={{
              padding: "8px 16px",
              background: "rgba(245, 150, 37, 0.15)",
              borderRadius: "10px",
              color: "rgb(245, 150, 37)"
            }}
          >
            Invoice Packed
          </button>
        );
      }
      if (item.status === "4") {
        return (
          <button
            style={{
              padding: "8px 16px",
              background: "rgba(45, 156, 219, 0.1)",
              borderRadius: "10px",
              color: "rgb(47, 128, 237)"
            }}
          >
            Invoice Posted
          </button>
        );
      }
    }
  }
];

export const proposalCol = [
  {
    name: "Run ID",
    index: "run_id",
    id: 1
  },
  {
    name: "Run Date",
    index: "run_date",
    id: 2
  },
  {
    name: "Company",
    index: "company_description",
    id: 3
  },
  {
    name: "Number of Vendors",
    index: "vendor_number",
    id: 4
  },
  {
    name: "Amount, LC",
    index: "amount",
    id: 5
  },
  // {
  //   name: "Currency",
  //   index: "currency",
  //   id: 6
  // },
  {
    name: "Status",
    id: 7,
    render: (item) => {
      if (item.status === "1") {
        return (
          <div className="coverBtn" style={{ display: "flex", alignItems: "center" }}>
            <button
              style={{
                background: "rgba(0, 176, 7, 0.15)",
                borderRadius: "10px",
                color: "rgb(56, 124, 87)",
                padding: "8px 16px"
              }}
            >
              Approved
            </button>
            <GoArr style={{ width: "18px", marginLeft: "8px" }} />
          </div>
        );
      }
      if (item.status === "-1") {
        return (
          <div className="coverBtn" style={{ display: "flex", alignItems: "center" }}>
            <button
              style={{
                background: "rgba(245, 150, 37, 0.15)",
                borderRadius: "10px",
                color: "rgb(245, 150, 37)",
                padding: "8px 16px"
              }}
            >
              Pending
            </button>
            <GoArr style={{ width: "18px", marginLeft: "5px" }} />
          </div>
        );
      }
      if (item.status === "-2") {
        return (
          <div className="coverBtn" style={{ display: "flex", alignItems: "center" }}>
            <button
              style={{
                background: "rgba(0, 176, 7, 0.15)",
                borderRadius: "10px",
                color: "rgb(56, 124, 87)",
                padding: "8px 16px"
              }}
            >
              Draft
            </button>
            <GoArr style={{ width: "18px", marginLeft: "5px" }} />
          </div>
        );
      }
    }
  }
];

export const proposalCol2 = [
  {
    name: "Run ID",
    index: "run_id",
    id: 1
  },
  {
    name: "Run Date",
    index: "run_date",
    id: 2
  },
  {
    name: "Company",
    index: "company_description",
    id: 3
  },
  {
    name: "Number of Vendors",
    index: "vendor_number",
    id: 4
  },
  {
    name: "Amount, LC",
    index: "amount",
    id: 5
  },
  // {
  //   name: "Currency",
  //   index: "currency",
  //   id: 6
  // },
  {
    name: "Status",
    id: 7,
    render: (item) => {
      if (item.status === "1") {
        return (
          <div className="coverBtn" style={{ display: "flex", alignItems: "center" }}>
            <button
              style={{
                borderRadius: "10px",
                background: "rgba(0, 176, 7, 0.15)",
                color: "rgb(56, 124, 87)",
                padding: "8px 16px"
              }}
            >
              Payment Approved
            </button>
            <GoArr style={{ width: "18px", marginLeft: "8px" }} />
          </div>
        );
      }
      if (item.status === "-1") {
        return (
          <div className="coverBtn" style={{ display: "flex", alignItems: "center" }}>
            <button
              style={{
                borderRadius: "10px",
                background: "rgba(245, 150, 37, 0.15)",
                color: "rgb(245, 150, 37)",
                padding: "8px 16px"
              }}
            >
              Authoriser Approval
            </button>
            <GoArr style={{ width: "18px", marginLeft: "5px" }} />
          </div>
        );
      }
      if (item.status === "-2") {
        return (
          <div className="coverBtn" style={{ display: "flex", alignItems: "center" }}>
            <button
              style={{
                borderRadius: "10px",
                background: "rgba(45, 156, 219, 0.1)",
                color: "rgb(47, 128, 237)",
                padding: "8px 16px"
              }}
            >
              In Bank Selection
            </button>
            <GoArr style={{ width: "18px", marginLeft: "5px" }} />
          </div>
        );
      }
    }
  }
];

export const eachProposalCol = [
  {
    name: "Supplier Name",
    index: "supplier",
    id: 1
  },
  {
    name: "SWIFT/BIC Code",
    index: "swift",
    id: 2
  },
  {
    name: "Bank",
    index: "bank",
    id: 5
  },
  {
    name: "Amount",
    index: "amount",
    id: 3
  },

  {
    name: "Account Number",
    index: "account",
    id: 4
  },
  {
    name: "Bank Code",
    index: "bank_code",
    id: 5
  },

  {
    name: "Paying House Bank",
    index: "house",
    id: 6
  },
  {
    name: "Paying Company",
    index: "company",
    id: 7
  },
  {
    name: "Paying Bank",
    index: "paying_bank",
    id: 8
  },
  {
    name: "Payment Date",
    index: "payment_date",
    id: 9
  },
  {
    name: "Reference",
    index: "reference",
    id: 10
  }
];

export const paymentRuns = [
  {
    name: "Run ID",
    index: "run_id",
    id: 1
  },
  {
    name: "Run Date",
    index: "run_date",
    id: 2
  },
  {
    name: "Company Code",
    index: "company_code",
    id: 3
  },
  {
    name: "Number of Vendors",
    index: "vendor_number",
    id: 4
  },
  {
    name: "Amount, LC",
    index: "amount",
    id: 5
  },
  {
    name: "Currency",
    index: "currency",
    id: 6
  },
  {
    name: "Status",
    id: 7,
    render: (item) => {
      return (
        <div className="coverBtn" style={{ display: "flex", alignItems: "center" }}>
          <div>
            {item.status === "Posted" ? (
              <button
                type="button"
                // style={{
                //   padding: "8px 16px",
                //   background: "rgba(0, 70, 176, 0.15)",
                //   borderRadius: "10px",
                //   color: "rgba(47, 128, 237, 1)"
                // }}
                className={`status_ mt-2 ${item.btnClass}`}
                style={{ width: "max-content" }}
              >
                {item.status}
              </button>
            ) : (
              <button
                type="button"
                // style={{
                //   padding: "8px 16px",
                //   background: item.status !== "Approved" ? "rgba(245, 150, 37, 0.15)" : "rgba(0, 176, 7, 0.15)",
                //   borderRadius: "10px",
                //   color: item.status !== "Approved" ? "rgba(245, 150, 37, 1)" : "#387C57"
                // }}
                className={`status_ mt-2 ${item.btnClass}`}
                style={{ width: "max-content" }}
              >
                {item.status}
              </button>
            )}
          </div>
          <GoArr className="ml-2" style={{ width: "18px", marginLeft: "8px" }} />
        </div>
      );
    }
  }
];

// <img style={{ width: "20px", cursor: "pointer" }} onClick={item.readNote} title={item.invoice_tracker} src={i} alt="i" />
