import React, { useEffect, useState } from "react";
import StatsCard from "components/StatsCard";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import budgetIcon from "assests/icons/buyer/budget-icon.svg";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import filterIcon from "assests/icons/buyer/filter-icon.svg";
import "../styles.scss";
import SearchIcon from "components/SearchBar";
import Dropdown from "components/Dropdown";
import useSearch from "hooks/useSearch";

import { proposalCol } from "../data";
import DataTable from "components/DataTable";

import Pagination from "components/Pagination";
import Textbox from "components/Textbox";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import FilterCard from "components/FilterCard";
import SelectDropdown from "components/SelectDropdown";

import { getPaymentProprosalsQueue } from "store/slice/BuyerSlice/PostedInvoiceSlice/PostedInvoiceSlice";
import { formatCurrency } from "utils/utils";

const ProposalQueue = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  const { proposal_queue } = useSelector((state: RootState) => state.postedInvoice);
  const [page, setPage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [showFilter, setShowFilter] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [modalText, setModalText] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [bugHead, setBuugHead] = useState("");
  const { search, inputRef, handleChange } = useSearch(getPaymentProprosalsQueue, perpage);

  const { register, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      start_date: "",
      end_date: "",
      from: "",
      to: ""
    }
  });

  const onSubmit = (vals) => {
    dispatch(getPaymentProprosalsQueue(page, perpage, search, vals.start_date, vals.end_date));
  };

  const handleClear = () => {
    dispatch(getPaymentProprosalsQueue());
    reset();
  };

  const handleNewPage = React.useCallback(
    (pageNo: number, page = 1) => {
      setPerpage(pageNo);
      setPage(1);
      dispatch(getPaymentProprosalsQueue(page, pageNo, search));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, length: number) => {
      setPage(pageNo);
      dispatch(getPaymentProprosalsQueue(pageNo, length, search));
    },
    [search]
  );

  const handlePageChange = React.useCallback(
    (page: number, length: number) => {
      setPage(page);
      dispatch(getPaymentProprosalsQueue(page, length, search));
    },
    [search]
  );

  // function getItemStatus(status) {
  //   if (status === "-2") return "Draft";
  //   if (status === "-1") return "Pending";
  //   if (status === "1") return "Approved";
  //   if (status === "2") return "Paid";
  //   if (status === "3") return "Parked";
  // }
  const postedInvoiceList =
    proposal_queue &&
    proposal_queue?.data?.map((item) => {
      return {
        run_id: item.proposal_code,
        run_date: item.run_date,
        company_description: item.company_description,
        vendor_number: item.total_vendors,
        amount: `${item.currency_description} ${formatCurrency(item.total_proposal_amount)}`,
        currency: item.currency_iso,

        status: item.proposal_status,
        id: item.id
        // posted_status: getItemStatus(item.invoice_status)

        // view: () => history.push(`/buyer/budget/${item.id}`),
        // edit: () => editBudget(item),
        // deleteId: () => handleDeleteBudget(item.id)
      };
    });

  useEffect(() => {
    dispatch(getPaymentProprosalsQueue(page, perpage, ""));
  }, []);
  useEffect(() => {
    if (!modal) setModalData({});
  }, [modal]);

  const handleView = (e, item) => {
    history.push(`/buyer/treasury/proposal-queue/${item.id}`);
  };

  return (
    <div id="budget">
      <StatsCard statsNumber={proposal_queue?.count} text="Proposal Queue" classes="stats-icon-orange" image={budgetIcon} iconBackgroundColor="rgba(0, 176, 80, 0.1)" />

      <div className="container">
        {showFilter && (
          <FilterCard handleSubmit={handleSubmit(onSubmit)} clear={handleClear}>
            <div className="row">
              <div className="col col-4">
                <label style={{ display: "block" }}>Start Date</label>
                <input
                  type="date"
                  name="start_date"
                  className="mt-3"
                  placeholder="Start Date"
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  ref={register({ required: false })}
                />
              </div>
              <div className="col col-4">
                <label style={{ display: "block" }}>End Date</label>
                <input
                  type="date"
                  name="end_date"
                  className="mt-3"
                  placeholder="End Date"
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  ref={register({ required: false })}
                />
              </div>
            </div>
            <div
              className="row"
              style={{
                marginTop: "2rem"
              }}
            >
              <div className="col col-4">
                <label style={{ display: "block" }}>Budget Header Status</label>
                <select
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  name="budget_header_status"
                  ref={register({
                    required: false
                  })}
                >
                  <option value="">Select</option>
                  <option value="-2">Draft</option>
                  <option value="-1">Pending Approval</option>
                  <option value="1">Approved</option>
                </select>
              </div>
            </div>
          </FilterCard>
        )}
        <div className="document mt-5">
          <div className="search-field">
            <SearchIcon placeholder="Search Proposal Queue" boxClasses="mb-3" textRef={inputRef} value={search} onChange={handleChange} />
            <Dropdown perPage={perpage} action={handleNewPage} />
            {/* <div>
              <button
                style={{
                  padding: "5px 10px",
                  borderRadius: "10px",
                  background: "#FFFFFF",
                  color: "#737A91",
                  border: "1px solid #DFE2E6",
                  fontSize: "18px",
                  marginLeft: "1rem",
                  width: "max-content"
                }}
                onClick={() => setShowFilter(!showFilter)}
              >
                <img src={filterIcon} alt="icon" className="icon-image" />
                <span
                  style={{
                    paddingLeft: "1rem"
                  }}
                >
                  {!showFilter ? "Filters" : "Hide Filters"}
                </span>
              </button>
            </div> */}
          </div>
          <div className="mt-3">
            <DataTable columns={proposalCol} data={postedInvoiceList} onClick={handleView} loading={false} />
            <Pagination
              page={page}
              lastPage={proposal_queue?.pagination?.lastPage}
              paginate={paginate}
              total={proposal_queue?.pagination?.total}
              handlePageChange={handlePageChange}
              perPage={perpage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProposalQueue;
