import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { exchangeStates, Action } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";

const initialState: exchangeStates = {
  loading: false,
  deleting: false,
  isLoading: false,
  exchange_rate: null,
  errorMessage: "",
  successMessage: ""
};

const exchangeSlice = createSlice({
  name: "exchange_rate",
  initialState,
  reducers: {
    getAllExchangeRateLoading: (state) => {
      state.loading = true;
    },
    getAllExchangeRateSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.exchange_rate = action.payload;
    },
    getAllExchangeRateFail: (state) => {
      state.loading = false;
    },
    createExchangeRateLoading: (state) => {
      state.isLoading = true;
    },
    createExchangeRateSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
    },
    createExchangeRateFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    editExchangeRateLoading: (state) => {
      state.isLoading = true;
    },
    editExchangeRateSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
    },
    editExchangeRateFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    deleteExchangeRateLoading: (state) => {
      state.deleting = true;
    },
    deleteExchangeRateSuccess: (state, action: PayloadAction<Action>) => {
      state.deleting = false;
      state.exchange_rate = action.payload;
    },
    deleteExchangeRateFail: (state, action: PayloadAction<string>) => {
      state.deleting = false;
      state.errorMessage = action.payload;
    }
  }
});

const { actions, reducer } = exchangeSlice;
export const {
  getAllExchangeRateLoading,
  getAllExchangeRateSuccess,
  getAllExchangeRateFail,
  createExchangeRateLoading,
  createExchangeRateSuccess,
  createExchangeRateFail,
  editExchangeRateLoading,
  editExchangeRateSuccess,
  editExchangeRateFail,
  deleteExchangeRateLoading,
  deleteExchangeRateSuccess,
  deleteExchangeRateFail
} = actions;

export const getExchageRate =
  (page?: number, length?: number, search?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getAllExchangeRateLoading());
    try {
      const response = await API.get("exchange_rates/get", {
        params: {
          page,
          length,
          search
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getAllExchangeRateSuccess(response.data));
      }
    } catch (error) {
      dispatch(getAllExchangeRateFail());
    }
  };

export const createExchangeRate =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(createExchangeRateLoading());
    try {
      const response = await API.post(`exchange_rates/create`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(createExchangeRateSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      if (error.response) {
        dispatch(createExchangeRateFail(error.response.data?.message));
        toastr.error(error.response.data.message);
      } else {
        dispatch(createExchangeRateFail("No network connecton"));
        toastr.error("No network connecton");
      }
      setTimeout(() => {
        dispatch(createExchangeRateFail(""));
      }, 3000);
    }
  };

export const editExchangeRate =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(editExchangeRateLoading());
    try {
      const response = await API.post(`exchange_rates/edit`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(editExchangeRateSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      if (error.response) {
        dispatch(editExchangeRateFail(error.response.data.message));
        toastr.error(error.response.data.message);
      } else {
        dispatch(editExchangeRateFail("No network connecton"));
        toastr.error("No network connecton");
      }
      setTimeout(() => {
        dispatch(editExchangeRateFail(""));
      }, 3000);
    }
  };

export const deleteExchangeRate =
  (data?: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(deleteExchangeRateLoading());
    try {
      const response = await API.post(`exchange_rates/delete`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(deleteExchangeRateSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      dispatch(deleteExchangeRateFail(error.response.data.message));
      toastr.error(error.response.data.message);
      setTimeout(() => {
        dispatch(deleteExchangeRateFail(""));
      }, 3000);
    }
  };

export default reducer;
