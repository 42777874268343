import React, { useCallback, useState } from "react";
import "./styles.scss";
import TabSmall from "components/TabSmall";

import NotificationSetup from "./components/NotificationSetup";

const Network = () => {
  const menus = [{ name: "Notification Setup", id: "cat" }];
  //State
  const [state, setstate] = useState(menus[0].id);

  const selectMenu = useCallback((name) => {
    setstate(name);
  }, []);

  return (
    <div id="settings-material-data">
      <div className="container">
        <TabSmall menus={menus} onSelect={selectMenu} classes="mb-3" />

        {state === "cat" && <NotificationSetup />}
      </div>
    </div>
  );
};

export default Network;
