import React from "react";
import StatsCard from "components/StatsCard";
import people from "assests/icons/buyer/Purchase Orders/Group (5).svg";
import clock from "assests/icons/buyer/Purchase Orders/clock 1.svg";
import addcircle from "assests/icons/buyer/Purchase Orders/add-circle 1 (1).png";
import blueCheck from "assests/icons/buyer/blueCheck.svg";
import SearchBar from "../../../components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { tableCol, tableData } from "./data";

import "./styles.scss";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDocuments } from "store/slice/BuyerSlice/DocumentSlice/DocumentSlice";
import { RootState } from "store/store";
import { formatCurrency } from "utils/utils";
import CreatePO from "components/ModalBuyer/CreatePO/Index";
import Pagination from "components/Pagination";
import useSearch from "hooks/useSearch";
import { getPOSummaries } from "store/slice/SummarySlice/SummarySlice";
import { getGoods, getGoodsSummary } from "store/slice/BuyerSlice/GoodsSlice/GoodsSlice";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import FilterCard from "components/FilterCard";
import filterIcon from "assests/icons/buyer/filter-icon.svg";

const GoodsReceipt = () => {
  const [pageNum, setPageNumber] = useState(10);
  const [openPO, setOpenPO] = useState(false);
  const [page, setPage] = React.useState<number>(1);
  const [length, setlength] = React.useState<number>(10);
  const [showFilter, setShowFilter] = useState(false);

  //   helpers
  const dispatch = useDispatch();
  const history = useHistory();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [purchaseOrderCode, setPurchaseOrderCode] = useState("");

  //   States
  const { documents } = useSelector((state: RootState) => state.document);
  const { goods, summary } = useSelector((state: RootState) => state.goods);
  // const { POSummaries } = useSelector((state: RootState) => state.summaries) as any;
  const { search, inputRef, handleChange, searched } = useSearch(getGoods, length, "", ["", "", "", startDate, endDate, purchaseOrderCode]);
  const { register, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      date_to: "",
      date_from: "",
      purchase_order_code: ""
      // to: '',
    }
  });

  useEffect(() => {
    dispatch(getDocuments(page, length));
    dispatch(getGoods(page, length));
    dispatch(getGoodsSummary());
  }, []);
  const toggleModal = () => {
    setOpenPO(!openPO);
  };

  // console.log(goods);

  const tableData =
    goods &&
    goods?.data?.map((data: any) => {
      // const { purchase_order_status } = data;
      // let status;
      // if (purchase_order_status === "-2") {
      //   status = "draft";
      // } else if (purchase_order_status === "-1") {
      //   status = "Pending Approval";
      // } else {
      //   status = "Approved";
      // }
      return {
        goods_received_date: data?.gr_date,
        // created_at: data?.created_at,
        purchse_code: data?.purchase_order_code,
        gr_code: data?.gr_code,
        supplier: data.vendor_description,
        company_description: data?.company_description,
        // price: formatCurrency(data?.total_value),
        overall_delivered: data?.gr_quantity,
        id: data?.id,
        material_description: data.material_description
      };
    });

  //--------------------------------------------
  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, length: number) => {
      setPage(page);
      dispatch(getGoods(page, length, search, "", "", "", startDate, endDate, purchaseOrderCode));
    },
    [search, startDate, endDate, purchaseOrderCode, dispatch]
  );

  const paginate = React.useCallback(
    (pageNo: number, length: number) => {
      setPage(pageNo);
      dispatch(getGoods(pageNo, length, "", "", "", "", startDate, endDate, purchaseOrderCode));
    },
    [startDate, endDate, purchaseOrderCode, dispatch]
  );

  //------------------------------------------------------------
  //Show Number for length Function
  //------------------------------------------------------------
  const handleNewPage = React.useCallback(
    (pageNo: number, page = 1) => {
      setlength(pageNo);
      setPage(1);
      dispatch(getGoods(page, pageNo, search, "", "", "", startDate, endDate, purchaseOrderCode));
    },
    [search, startDate, endDate, purchaseOrderCode, dispatch]
  );

  const onSubmit = (vals) => {
    setStartDate(vals.date_from);
    setEndDate(vals.date_to);
    setPurchaseOrderCode(vals.purchase_order_code);
    dispatch(getGoods(page, length, search, undefined, undefined, undefined, vals.date_from, vals.date_to, vals.purchase_order_code));
  };

  const handleClear = () => {
    setStartDate("");
    setEndDate("");
    setPurchaseOrderCode("");
    dispatch(getGoods(page, length, search));
    reset();
  };

  //   console.log(tableData);

  return (
    <div id="document">
      {openPO && <CreatePO closeModal={toggleModal} />}
      <div className="container">
        <h3 className="header">Goods Receipt</h3>
        <div className="stats-card mt-4 row">
          {/* <StatsCard text="Total Goods Receipt Order" classes="stats-icon-blue" image={people} statsNumber={POSummaries?.overall?.total_purchase_orders} /> */}
          <StatsCard text="Open Receipts" classes="stats-icon-blue" image={blueCheck} statsNumber={summary?.overall?.total_purchase_orders} />
          <StatsCard text="Open Receipts  Value" classes="stats-icon-blue" image={clock} statsNumber={summary?.overall?.total_gr_amount} />
          {/* <StatsCard centerText="Create Goods Receipt" classes="middle-img" image={addcircle} onClick={toggleModal} /> */}
        </div>
        <div className="document mt-5">
          <h3 className="title font-weight-bold" style={{ fontSize: "18px" }}>
            Goods Receipt
          </h3>
          {showFilter && (
            <FilterCard handleSubmit={handleSubmit(onSubmit)} clear={handleClear}>
              <div className="row">
                <div className="col col-4">
                  <label style={{ display: "block" }}>Start Date</label>
                  <input
                    type="date"
                    name="date_from"
                    className="mt-3"
                    placeholder="Start Date"
                    style={{
                      width: "100%",
                      height: "3rem",
                      padding: "5px",
                      borderRadius: "10px",
                      border: "1px solid #dfe2e6"
                    }}
                    ref={register({ required: false })}
                  />
                </div>
                <div className="col col-4">
                  <label style={{ display: "block" }}>End Date</label>
                  <input
                    type="date"
                    name="date_to"
                    className="mt-3"
                    placeholder="End Date"
                    style={{
                      width: "100%",
                      height: "3rem",
                      padding: "5px",
                      borderRadius: "10px",
                      border: "1px solid #dfe2e6"
                    }}
                    ref={register({ required: false })}
                  />
                </div>
                <div className="col col-4">
                  <label style={{ display: "block" }}>Purchase Order Code</label>
                  <input
                    type="text"
                    name="purchase_order_code"
                    className="mt-3"
                    placeholder="Purchase Order Code"
                    style={{
                      width: "100%",
                      height: "3rem",
                      padding: "5px",
                      borderRadius: "10px",
                      border: "1px solid #dfe2e6"
                    }}
                    ref={register({ required: false })}
                  />
                </div>
              </div>
            </FilterCard>
          )}
          <div className="search-field mt-3">
            <SearchBar placeholder="Search for Goods Receipt" textRef={inputRef} value={search} onChange={handleChange} />
            {/* <div className="btn-custom mr-2">
              <button type="button" id="btn-custom" className="d-flex align-items-center" onClick={() => {}}>
                <img src={Icon} alt="icon" className="mr-2" />
                <Link to="./buyer/invoice/:id">Status</Link>
              </button>
            </div> */}
            {/* <div className="btn-custom mr-5">
              <button type="button" id="btn-custom" className="d-flex align-items-center" onClick={() => {}}>
                <img src={greenCheck} alt="icon" className="mr-2" width="25" />
                <Link to="./buyer/invoice/:id">Status</Link>
              </button>
            </div> */}
            {/* <div className="upload mr-5">
              <button type="button" id="upload" onClick={() => {}}>
                <Link to="./buyer/invoice/:id">Status</Link>
              </button>
            </div> */}
            {/* <Dropdown perPage={10} action={(num) => setPageNumber(num)} /> */}
            <Dropdown perPage={length} action={handleNewPage} />
            <div>
              <button
                style={{
                  padding: "5px 10px",
                  borderRadius: "10px",
                  background: "#FFFFFF",
                  color: "#737A91",
                  border: "1px solid #DFE2E6",
                  fontSize: "18px",
                  marginLeft: "1rem"
                }}
                onClick={() => setShowFilter(!showFilter)}
              >
                <img src={filterIcon} alt="icon" className="icon-image" />
                <span
                  style={{
                    paddingLeft: "1rem"
                  }}
                >
                  {!showFilter ? "Filters" : "Hide Filters"}
                </span>
              </button>
            </div>
          </div>
          <div className="mt-3">
            <DataTable columns={tableCol} data={tableData} onClick={(e, item) => history.push({ pathname: `/buyer/goods-receipt/${item.id}` })} />
            <Pagination page={page} lastPage={goods?.pagination?.pageCount} paginate={paginate} total={goods?.pagination?.total} handlePageChange={handlePageChange} perPage={length} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoodsReceipt;
