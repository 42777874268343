import React, { useState } from "react";
import StatsCard from "components/StatsCard";
import Icon from "assests/icons/statsCard/Vector (4).png";
import greenCheck from "assests/icons/buyer/green-check.svg";
import clock from "assests/icons/buyer/clock.svg";
import bill from "assests/icons/buyer/bill.svg";
import SearchBar from "../../../components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { tableCol } from "./data";
import CreateInvoice from "components/ModalBuyer/CreateInvoice";

import "./styles.scss";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getInvoices, getInvoicesDownload, getInvoiceSummary } from "store/slice/BuyerSlice/Invoice/InvoiceSlice";
import { RootState } from "store/store";
import SearchIcon from "../../../components/SearchBar";
import useSearch from "hooks/useSearch";
import { formatCurrency } from "utils/utils";
import Pagination from "components/Pagination";
import filterIcon from "assests/icons/buyer/filter-icon.svg";
import { useHistory } from "react-router-dom";
import FilterCard from "components/FilterCard";
import { useForm } from "react-hook-form";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
const BuyerInvoice = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [pageNum, setPageNumber] = React.useState(10);
  const [modals, setModals] = React.useState(false);
  const [perpage, setPerpage] = React.useState<number>(10);
  const [page, setPage] = React.useState<any>(1);
  const [length, setLength] = React.useState<number>(10);
  const [showFilter, setShowFilter] = React.useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [purchaseOrderCode, setPurchaseOrderCode] = useState("");
  const [sapIn, setSapIn] = useState("");
  const [supIn, setSupIn] = useState("");
  const [invoiceStatus, setInvoiceStatus] = useState("");

  const { search, inputRef, handleChange, searched } = useSearch(getInvoices, perpage, "", [undefined, startDate, endDate, purchaseOrderCode, undefined, undefined, sapIn, supIn, invoiceStatus]);
  const { register, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      date_to: "",
      date_from: "",
      open: ""
      // to: '',
    }
  });

  const { invoices, invoicesDownload, isLoading, summary } = useSelector((state: RootState) => state.invoice);
  //   console.log(invoices);

  const closeModal = () => {
    setModals(!modals);
  };

  const handleNewPage = React.useCallback(
    (pageNo: number, page = 1) => {
      setPerpage(pageNo);
      setPage(1);
      dispatch(getInvoices(page, page, search, undefined, startDate, endDate, purchaseOrderCode, undefined, undefined, sapIn, supIn, invoiceStatus));
      dispatch(getInvoicesDownload(page, page, search, undefined, startDate, endDate, purchaseOrderCode, undefined, undefined, sapIn, supIn, invoiceStatus));
    },
    [search]
  );

  React.useEffect(() => {
    dispatch(getInvoices());
    dispatch(getInvoicesDownload());
    dispatch(getInvoiceSummary());
  }, []);

  //   React.useEffect(() => {
  //     console.log(summary);
  //   }, [summary]);

  function getItemStatus(status) {
    if (status === "-2") return "Draft";
    if (status === "-1") return "Pending";
    if (status === "1") return "Approved";
    if (status === "2") return "Paid";
    if (status === "3") return "Parked";
    if (status === "4") return "Posted";
    if (status === "5") return "Paid";
  }

  function getBtnClass(status) {
    if (status === "-3") return "reject";
    if (status === "-2") return "draft";
    if (status === "-1") return "pending";
    if (status === "1") return "approved";
    if (status === "2") return "paid";
    if (status === "3") return "posted";
    if (status === "4") return "posted";
    if (status === "5") return "paid";
  }

  const invoiceList =
    invoices &&
    invoices.data?.map((item: any) => {
      return {
        ...item,
        // invoice_date: item.invoice_due_date ? format(new Date(item.invoice_due_date), "dd.MM.yyyy") : "N/A",
        invoice_date: item.invoice_due_date,
        vendor_name: item.vendor_description,
        po_number: item.purchase_order_code,
        sap_invoice: item.invoice_code,
        sap_error: item.sap_error,
        invoice_number: `INV-${item.id}` || "",
        supp_no: item.supplier_invoice_number || "",
        invoice_amount: formatCurrency(item.total_invoice_amount),
        invoice_status: getItemStatus(item.invoice_status),
        btnClass: getBtnClass(item.invoice_status)
      };
    });

  //--------------------------------------------
  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, length: number) => {
      setPage(page);
      dispatch(getInvoices(page, length, search, undefined, startDate, endDate, purchaseOrderCode, undefined, undefined, sapIn, supIn, invoiceStatus));
      dispatch(getInvoicesDownload(page, length, search, undefined, startDate, endDate, purchaseOrderCode, undefined, undefined, sapIn, supIn, invoiceStatus));
    },
    [search, startDate, endDate, purchaseOrderCode, sapIn, supIn, dispatch, invoiceStatus]
  );

  const paginate = React.useCallback(
    (pageNo: number, length: number) => {
      setPage(pageNo);
      dispatch(getInvoices(pageNo, length, search, undefined, startDate, endDate, purchaseOrderCode, undefined, undefined, sapIn, supIn, invoiceStatus));
      dispatch(getInvoicesDownload(pageNo, length, search, undefined, startDate, endDate, purchaseOrderCode, undefined, undefined, sapIn, supIn, invoiceStatus));
    },
    [search, startDate, endDate, purchaseOrderCode, sapIn, supIn, dispatch, invoiceStatus]
  );

  const onSubmit = (vals) => {
    // dispatch(getInvoices(page, length, search, undefined, vals.date_from, vals.date_to, vals.purchase_order_code));
    setStartDate(vals.date_from);
    setEndDate(vals.date_to);
    setPurchaseOrderCode(vals.purchase_order_code);
    setSapIn(vals.invoice_code);
    setSupIn(vals.supplier_invoice_number);
    setInvoiceStatus(vals.invoice_status);
    dispatch(
      getInvoices(page, perpage, search, undefined, vals.date_from, vals.date_to, vals.purchase_order_code, undefined, undefined, vals.invoice_code, vals.supplier_invoice_number, vals.invoice_status)
    );
    dispatch(
      getInvoicesDownload(
        page,
        perpage,
        search,
        undefined,
        vals.date_from,
        vals.date_to,
        vals.purchase_order_code,
        undefined,
        undefined,
        vals.invoice_code,
        vals.supplier_invoice_number,
        vals.invoice_status
      )
    );
  };

  const handleClear = () => {
    setStartDate("");
    setEndDate("");
    setPurchaseOrderCode("");
    setSapIn("");
    setSupIn("");
    setInvoiceStatus("");
    dispatch(getInvoices(page, perpage, search));
    dispatch(getInvoicesDownload(page, perpage, search));
    reset();
  };

  return (
    <div id="invoice">
      <div className="container">
        {modals && <CreateInvoice closeModal={closeModal} />}
        <h3 className="header">Invoice Stats</h3>
        <div className="stats-card mt-4 row">
          <StatsCard text="Total Invoices" classes="stats-icon-green" image={greenCheck} statsNumber={summary?.overall?.total_invoices || "0"} />
          <StatsCard text="Paid Invoices" classes="stats-icon-orange" image={clock} statsNumber={summary?.paid?.total_invoices || "0"} />
          <StatsCard text="Unpaid Invoices" classes="stats-icon-red" image={bill} statsNumber={summary?.unpaid?.total_invoices || "0"} />
          <StatsCard image={Icon} classes="middle-img" centerText="Create Invoice" onClick={closeModal} />
        </div>
        <div className="document mt-5">
          <h3 className="title font-weight-bold">Invoice List</h3>
          {showFilter && (
            <FilterCard handleSubmit={handleSubmit(onSubmit)} clear={handleClear}>
              <div className="row">
                <div className="col col-4 mb-3">
                  <label style={{ display: "block" }}>Start Date</label>
                  <input
                    type="date"
                    name="date_from"
                    className="mt-3"
                    placeholder="Start Date"
                    style={{
                      width: "100%",
                      height: "3rem",
                      padding: "5px",
                      borderRadius: "10px",
                      border: "1px solid #dfe2e6"
                    }}
                    ref={register({ required: false })}
                  />
                </div>
                <div className="col col-4 mb-3">
                  <label style={{ display: "block" }}>End Date</label>
                  <input
                    type="date"
                    name="date_to"
                    className="mt-3"
                    placeholder="End Date"
                    style={{
                      width: "100%",
                      height: "3rem",
                      padding: "5px",
                      borderRadius: "10px",
                      border: "1px solid #dfe2e6"
                    }}
                    ref={register({ required: false })}
                  />
                </div>
                <div className="col col-4 mb-3">
                  <label style={{ display: "block" }}>Purchase Order Code</label>
                  <input
                    type="text"
                    name="purchase_order_code"
                    className="mt-3"
                    placeholder="Purchase Order Code"
                    style={{
                      width: "100%",
                      height: "3rem",
                      padding: "5px",
                      borderRadius: "10px",
                      border: "1px solid #dfe2e6"
                    }}
                    ref={register({ required: false })}
                  />
                </div>
                <div className="col col-4 mb-3">
                  <label style={{ display: "block" }}>SAP Invoice</label>
                  <input
                    type="text"
                    name="invoice_code"
                    className="mt-3"
                    placeholder="SAP Invoice"
                    style={{
                      width: "100%",
                      height: "3rem",
                      padding: "5px",
                      borderRadius: "10px",
                      border: "1px solid #dfe2e6"
                    }}
                    ref={register({ required: false })}
                  />
                </div>
                <div className="col col-4 mb-3">
                  <label style={{ display: "block" }}>Supplier Invoice Number</label>
                  <input
                    type="text"
                    name="supplier_invoice_number"
                    className="mt-3"
                    placeholder="Supplier Invoice Number"
                    style={{
                      width: "100%",
                      height: "3rem",
                      padding: "5px",
                      borderRadius: "10px",
                      border: "1px solid #dfe2e6"
                    }}
                    ref={register({ required: false })}
                  />
                </div>

                <div className="col col-4 mb-3">
                  <label style={{ display: "block" }}>Invoice Status </label>
                  <select
                    style={{
                      width: "100%",
                      height: "3rem",
                      padding: "5px",
                      borderRadius: "10px",
                      border: "1px solid #dfe2e6"
                    }}
                    className="mt-3"
                    name="invoice_status"
                    ref={register({
                      required: false
                    })}
                  >
                    <option value="">Select</option>

                    <option value="-1">Pending Approval</option>
                    <option value="1">Approved</option>
                    <option value="-2">In Draft</option>
                    <option value="2">Invoice Paid</option>
                    <option value="3">Invoice Parked</option>
                    <option value="4">Invoice Posted</option>
                  </select>
                </div>
              </div>
            </FilterCard>
          )}
          <div className="search-field mt-3">
            <SearchIcon placeholder="Search for Invoice No." boxClasses="width80" textRef={inputRef} value={search} onChange={handleChange} />
            <Dropdown perPage={perpage} action={handleNewPage} />
            <div style={{ display: "flex", alignItems: "center" }} className="d-flex">
              <button
                style={{
                  padding: "5px 10px",
                  borderRadius: "10px",
                  background: "#FFFFFF",
                  color: "#737A91",
                  border: "1px solid #DFE2E6",
                  fontSize: "18px",
                  marginLeft: "1rem",
                  marginRight: "1rem"
                }}
                onClick={() => setShowFilter(!showFilter)}
              >
                <img src={filterIcon} alt="icon" className="icon-image" />
                <span
                  style={{
                    paddingLeft: "1rem"
                  }}
                >
                  {!showFilter ? "Filters" : "Hide Filters"}
                </span>
              </button>
              <ReactHTMLTableToExcel id="test-table-xls-button" className="download-table-xls-button btn btn-success" table="table-to-xls" filename="Invoice" sheet="tablexls" buttonText="Download" />
            </div>
          </div>
          <div className="mt-3">
            <table style={{ display: "none" }} id="table-to-xls" className="download_table">
              <thead>
                <tr>
                  <th>Invoice Date</th>
                  <th>Vendor Name</th>
                  <th>PO No.</th>
                  <th>Invoice No.</th>
                  <th>Supplier Ref.</th>
                  <th>Amount</th>
                  <th>SAP Invoice</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {/* invoice_date: item.invoice_due_date,
        vendor_name: item.vendor_description,
        po_number: item.purchase_order_code,
        sap_invoice: item.invoice_code,
        sap_error: item.sap_error,
        invoice_number: `INV-${item.id}` || "",
        supp_no: item.supplier_invoice_number || "",
        invoice_amount: formatCurrency(item.total_invoice_amount),
        invoice_status: getItemStatus(item.invoice_status),
        btnClass: getBtnClass(item.invoice_status) */}
                {invoicesDownload &&
                  invoicesDownload.data?.map((item) => {
                    return (
                      <tr>
                        <td>{item.invoice_due_date}</td>
                        <td>{item.vendor_description}</td>
                        <td>{item.purchase_order_code}</td>
                        <td>{item.invoice_code}</td>
                        <td>{item.supplier_invoice_number}</td>
                        <td>{formatCurrency(item.total_invoice_amount)}</td>
                        <td>{item.sap_error ? <p dangerouslySetInnerHTML={{ __html: item.sap_error }} /> : item.invoice_code}</td>
                        <td>{getItemStatus(item.invoice_status)}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <DataTable
              columns={tableCol}
              data={invoiceList}
              loading={isLoading}
              onClick={(e, item) => {
                window.location.href = `/buyer/invoice/${item.id}`;
              }}
            />
            <Pagination page={page} lastPage={invoices?.pagination?.pageCount} paginate={paginate} total={invoices?.pagination?.total} handlePageChange={handlePageChange} perPage={length} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyerInvoice;
