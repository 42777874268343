import React, { useEffect } from "react";
import Modal from "components/Modal";
import ArrowDropdown from "components/ArrowDropdown";
import Textbox from "components/Textbox";
import Button from "components/Button";
import useArrowDropdown from "hooks/useArrowDropdown";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getCompaniesEntityList } from "store/slice/CompaniesSlice/CompaniesSlice";
import { AppDispatch, RootState } from "store/store";
import SelectDropdown from "components/SelectDropdown";
import { Controller } from "react-hook-form";
import API from "utils/httpsRequest";
import AsyncSelect from "react-select/async";
import { createDepartment, editDepartment, getDepartments } from "store/slice/BuyerSlice/DepartmentSlice/DepartmentSlice";
import { createPurchaseGroup, editPurchaseGroup, getPurchaseGroup } from "store/slice/BuyerSlice/PurchaseGroup/PurchaseGroup";

type Props = {
  closeModal: () => void;
  singlePurchaseGroup?: any;
};
const CreateOrEditPurchaseGroup = ({ closeModal, singlePurchaseGroup = null }: Props) => {
  //State
  const [error, setError] = React.useState("");

  type FormData = {
    purchasing_group_description: string;
    purchasing_group_code: string;

  };

  //Setting field on edit mode
  useEffect(() => {
    if (singlePurchaseGroup !== null) {
      setValue("purchasing_group_description", singlePurchaseGroup?.purchasing_group_description);
      setValue("purchasing_group_code", singlePurchaseGroup?.purchasing_group_code);
    }
  }, [singlePurchaseGroup]);

  //Redux Store
  const dispatch = useDispatch<AppDispatch>();


  const { user } = useSelector((state: RootState) => state.user);
  const { token } = user;



  //Hooks
  const { register, handleSubmit, errors, control, setValue } = useForm<FormData>({ mode: "onBlur" });
  const { open, selectedOption, handleDropdownChange, handleAction, keyOption, ref } = useArrowDropdown();
  const { isLoading } = useSelector((state: RootState) => state.purchaseGroup);


  const onFormSubmit = (vals: FormData) => {
    if (singlePurchaseGroup) {
      const payload = {
        ...vals,
        id: singlePurchaseGroup.id,
      };

     
      dispatch(
        editPurchaseGroup(payload, () => {
          closeModal();
          dispatch(getPurchaseGroup(1, 10));
        })
      );
      return;
    }

    const { ...rest } = vals;
    const payload = {
      ...rest,
    };

    dispatch(
      createPurchaseGroup(payload, () => {
        closeModal();
        dispatch(getPurchaseGroup(1, 10));
      })
    );
  };




  return (
    <Modal modalText={singlePurchaseGroup === null ? "Create Purchase Goup" : "Edit Purchase Group"} onClose={closeModal} modallength="modal-length">
      <div id="buyer-create-location">
        <p className=""></p>

        <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>

          <Textbox
            type="text"
            name="purchasing_group_description"
            label="Description"
            placeholder="Description"
            error={errors.purchasing_group_description && "This field is required"}
            customRef={register({
              required: true
            })}
          />

          <Textbox
            type="text"
            label="Code"
            name="purchasing_group_code"
            placeholder="Code"
            boxClasses="mt-3 mb-3"
            error={errors.purchasing_group_code && "This field is required"}
            customRef={register({
              required: true
            })}
          />
   


          <p className={`${error ? "d-block" : "d-none"} error mt-1`}>{error}</p>

          <Button label={singlePurchaseGroup === null ? "Create Purchase Group" : "Edit Purchase Group"} btnType="btn-primary" boxClasses="mt-5 mb-5" btnStyle="100%" loading={isLoading} />
        </form>
      </div>
    </Modal>
  );
};

export default CreateOrEditPurchaseGroup;
