import * as React from "react";
import "./styles.scss";
import { ReactComponent as CloseIcon } from "assests/icons/close.svg";

export interface ModalProps {
  children: React.ReactNode;
  onClose?: () => void;
  modalText: string;
  boxClasses?: string;
  modallength?: string;
  autoClose?: boolean;
  checkUpload?: () => void;
  isUpload?:boolean;
  uploadBool?:boolean;
}

const Modal: React.FC<ModalProps> = ({
  children,
  onClose,
  modalText,
  boxClasses,
  modallength,
  checkUpload,
  isUpload,
  uploadBool,
  autoClose = true
}) => {
  const onModalClick = (): void => {
    onClose && onClose();
  };

  //To remove browser scrollbar when modal is Opened
  React.useEffect(() => {
    document.body.classList.add("bodyfix");
    return () => {
      document.body.classList.remove("bodyfix");
    };
  }, []);

  //To close the modal with Escape Key (Accessibility Reasons)
  React.useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => {
      document.removeEventListener("keydown", keyPress);
    };
  }, []);

  const keyPress = (event) => {
    const callback = {
      Escape: onClose
    }[event.key];

    if (!callback) return;
    callback?.();
  };

  //To close the modal from outside
  const closeModal = (e) => {
    if (e.target.classList.contains("backDrop") && autoClose) {
      onClose && onClose();
    }
  };
  return (
    <div className="modal-container">
      <div className="backDrop" onClick={closeModal}></div>
      <div className={`contentwrapper ${boxClasses}`}>
        <div className="modal_header">
          <h4 className={uploadBool?"faint":"bold"} >{modalText}</h4>
          {isUpload && <label  className="switch">
            <input onClick={checkUpload} type="checkbox"/>
            <span className={`slider round ${uploadBool?"active":""}`}></span>
          </label>}
          {isUpload && <h4 className={uploadBool?"bold":"faint"} >Upload</h4>}
          <CloseIcon onClick={onModalClick} className="cursor-pointer" />
        </div>
        <hr className="line" />
        <div className={`${modallength} modal_content`}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
