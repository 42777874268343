import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { postedInvoiceState, Action } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";

const initialState: postedInvoiceState = {
  loading: false,
  deleting: false,
  isLoading: false,
  sendLoading: false,
  posted_invoice: null,
  paymentProposals: null,
  debitLines: null,
  allDebitLines: null,
  proposal_queue: null,
  bankProposal: null,

  eachBankProposal: null,
  paymentRef: null,
  singlePaymentProposals: null,
  s3urlProposal: null,
  successMessage: "",
  errorMessage: ""
};

const postedInvoiceSlice = createSlice({
  name: "postedInvoice",
  initialState,
  reducers: {
    getAllPostedInvoiceLoading: (state) => {
      state.loading = true;
    },
    getAllPostedInvoiceSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.posted_invoice = action.payload;
    },
    getBankSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.bankProposal = action.payload;
    },
    getEachBankSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.eachBankProposal = action.payload;
    },
    getAllPostedInvoiceFail: (state) => {
      state.loading = false;
    },
    startRequest: (state) => {
      state.loading = true;
    },
    endRequest: (state) => {
      state.loading = false;
    },
    getSinglePaymentProposalsSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.singlePaymentProposals = action.payload;
    },
    fetchDebitLinesSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.debitLines = action.payload;
    },
    getAllDebitLinesSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.allDebitLines = action.payload;
    },
    getPaymentRefSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.paymentRef = action.payload;
    },
    getPaymentProposalsSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.paymentProposals = action.payload;
    },
    getPaymentProposalsQueueSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.proposal_queue = action.payload;
    },
    sendApprovalLoading: (state) => {
      state.sendLoading = true;
    },
    sendApprovalSuccess: (state, action: PayloadAction<Action>) => {
      state.sendLoading = false;
    },
    sendApprovalFail: (state, action: PayloadAction<string>) => {
      state.sendLoading = false;
      state.errorMessage = action.payload;
    },
    uploadingImageRequest: (state) => {
      state.isLoading = true;
    },
    uploadingImageSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.s3urlProposal = action.payload;
    },
    uploadingImageFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
    },
    paymentRefLoading: (state) => {
      state.isLoading = true;
    },
    paymentRefSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
    },
    paymentRefFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
    }
  }
});

const { actions, reducer } = postedInvoiceSlice;
export const {
  getAllPostedInvoiceLoading,
  getAllPostedInvoiceSuccess,
  getAllPostedInvoiceFail,
  startRequest,
  endRequest,
  getPaymentProposalsSuccess,
  getSinglePaymentProposalsSuccess,
  fetchDebitLinesSuccess,
  getAllDebitLinesSuccess,
  getPaymentProposalsQueueSuccess,
  getBankSuccess,
  getEachBankSuccess,
  sendApprovalFail,
  sendApprovalLoading,
  sendApprovalSuccess,
  uploadingImageFail,
  uploadingImageRequest,
  uploadingImageSuccess,
  paymentRefFail,
  paymentRefLoading,
  paymentRefSuccess,
  getPaymentRefSuccess
} = actions;

export const getPostedInvoice =
  (page?: number, length?: number, search?: string, company_id?: string, run_date?: string, proposal_code?: string, currency_id?: string, start_date?: string, end_date?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getAllPostedInvoiceLoading());
    try {
      const response = await API.get("/invoice_postings/get", {
        params: {
          page,
          length,
          search,
          start_date,
          end_date,
          company_id,
          run_date,
          proposal_code,
          currency_id,
          invoice_status: "4"
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getAllPostedInvoiceSuccess(response.data));
      }
    } catch (error) {
      dispatch(getAllPostedInvoiceFail());
    }
  };

export const getBankProposal =
  (page?: number, length?: number, search?: string, company_id?: string, run_date?: string, proposal_code?: string, currency_id?: string, start_date?: string, end_date?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(startRequest());
    try {
      const response = await API.get("/bank_proposals/get", {
        params: {
          page,
          length,
          search,
          start_date,
          end_date,
          company_id,
          run_date,
          proposal_code,
          currency_id
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getBankSuccess(response.data));
      }
    } catch (error) {
      dispatch(endRequest());
    }
  };

type getProposal = {
  page?: number;
  length?: number;
  search?: string;
  id?: any;
  company_id?: string;
  run_date?: string;
  proposal_code?: string;
  currency_id?: string;
  start_date?: string;
  end_date?: string;
};
export const getEachBankPropsal =
  ({ page, length, id, search, company_id, run_date, proposal_code, currency_id, start_date, end_date }: getProposal): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(startRequest());
    try {
      const response = await API.get(`/bank_proposals/get/${id}`, {
        params: {
          page,
          length,
          search,
          start_date,
          end_date,
          company_id,
          run_date,
          proposal_code,
          currency_id
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getEachBankSuccess(response.data));
      }
    } catch (error) {
      dispatch(endRequest());
    }
  };

export const getPaymentProprosals =
  (
    page?: number,
    length?: number,
    search?: string,
    company_id?: string,
    run_date?: string,
    proposal_code?: string,
    currency_id?: string,
    start_date?: string,
    end_date?: string,
    proposal_status?: any
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(startRequest());
    try {
      const response = await API.get("/proposals/get", {
        params: {
          page,
          length,
          search,
          start_date,
          end_date,
          company_id,
          run_date,
          proposal_code,
          currency_id,
          proposal_status
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getPaymentProposalsSuccess(response.data));
      }
    } catch (error) {
      dispatch(endRequest());
    }
  };

export const getPaymentProprosalsQueue =
  (
    page?: number,
    length?: number,
    search?: string,
    company_id?: string,
    run_date?: string,
    proposal_code?: string,
    proposal_status?: string,
    currency_id?: string,
    start_date?: string,
    end_date?: string
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(startRequest());
    try {
      const response = await API.get("/proposals/get", {
        params: {
          page,
          length,
          search,
          start_date,
          end_date,
          company_id,
          run_date,
          proposal_code,
          currency_id,
          proposal_status,
          for_proposal_queue: "Yes"
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getPaymentProposalsQueueSuccess(response.data));
      }
    } catch (error) {
      dispatch(endRequest());
    }
  };

export const getSinglePaymentProprosals =
  (id: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(startRequest());
    console.log(id);
    try {
      const response = await API.get(`/proposals/get/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getSinglePaymentProposalsSuccess(response.data));
      }
    } catch (error: any) {
      dispatch(endRequest());
      toastr.error(error?.response?.data?.message);
    }
  };

export const deletePaymentProposal =
  (payload: object, cb: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(startRequest());
    try {
      const response = await API.post(`/proposals/delete`, payload, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(endRequest());
        toastr.success("", response.data.message);
        cb();
      }
    } catch (error) {
      dispatch(endRequest());
      toastr.error(error?.response?.data?.message);
    }
  };

export const sendPaymentProposal =
  (payload: object, cb: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(startRequest());
    try {
      const response = await API.post(`/proposals/confirm`, payload, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(endRequest());
        toastr.success("", response.data.message);
        cb();
      }
    } catch (error) {
      dispatch(endRequest());
      toastr.error(error?.response?.data?.message);
    }
  };

export const addInvoiceToPayment =
  (payload: object, cb: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(startRequest());
    try {
      const response = await API.post(`/proposals/add_invoice`, payload, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(endRequest());
        toastr.success("", response.data.message);
        cb();
      }
    } catch (error) {
      dispatch(endRequest());
      toastr.error(error?.response?.data?.message);
    }
  };

export const removeInvoiceFromPayment =
  (payload: object, cb: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(startRequest());
    try {
      const response = await API.post(`/proposals/remove_invoice`, payload, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(endRequest());
        toastr.success("", response.data.message);
        cb();
      }
    } catch (error) {
      dispatch(endRequest());
      toastr.error(error?.response?.data?.message);
    }
  };

export const fetchDebitLines =
  (
    vendor_id: string,
    proposal_items_id: string,
    page?: number,
    length?: number,
    search?: string,
    company_id?: string,
    run_date?: string,
    proposal_code?: string,
    currency_id?: string,
    start_date?: string,
    end_date?: string
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(startRequest());
    try {
      const response = await API.get(`/invoice_debits/fetch_debit_items_for_proposal/${vendor_id}/${proposal_items_id}`, {
        params: {
          page,
          length,
          search,
          start_date,
          end_date,
          company_id,
          run_date,
          proposal_code,
          currency_id
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(fetchDebitLinesSuccess(response.data));
      }
    } catch (error) {
      dispatch(endRequest());
    }
  };

export const getAllDebitLines =
  (page?: number, length?: number, search?: string, company_id?: string, run_date?: string, proposal_code?: string, currency_id?: string, start_date?: string, end_date?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(startRequest());
    try {
      const response = await API.get("/invoice_debits/get", {
        params: {
          page,
          length,
          search,
          start_date,
          end_date,
          company_id,
          run_date,
          proposal_code,
          currency_id
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getAllDebitLinesSuccess(response.data));
      }
    } catch (error) {
      dispatch(endRequest());
    }
  };

export const addDebitLine =
  (payload: object, cb: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(startRequest());
    try {
      const response = await API.post(`/invoice_debits/add_to_proposal_item`, payload, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(endRequest());
        toastr.success("", response.data.message);
        cb();
      }
    } catch (error) {
      dispatch(endRequest());
      toastr.error(error?.response?.data?.message);
    }
  };

export const removeDebitLine =
  (payload: object, cb: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(startRequest());
    try {
      const response = await API.post(`/invoice_debits/remove_from_proposal_item`, payload, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(endRequest());
        toastr.success("", response.data.message);
        cb();
      }
    } catch (error) {
      dispatch(endRequest());
      toastr.error(error?.response?.data?.message);
    }
  };

export const sendApproval =
  (data?: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(sendApprovalLoading());
    try {
      const response = await API.post(`/bank_proposals/confirm`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(sendApprovalSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      dispatch(sendApprovalFail(error.response.data.message));
      toastr.error(error.response.data.message);
      setTimeout(() => {
        dispatch(sendApprovalFail(""));
      }, 3000);
    }
  };

export const PaymentRef =
  (data?: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(paymentRefLoading());
    try {
      const response = await API.post(`/proposals/create_document_record`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(paymentRefSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      dispatch(paymentRefFail(error.response.data.message));
      toastr.error(error.response.data.message);
      setTimeout(() => {
        dispatch(paymentRefFail(""));
      }, 3000);
    }
  };

export const uploadFileProposal =
  (payload, cb?: (data) => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(uploadingImageRequest());
    const { userToken } = getState().user;
    try {
      const response = await API.post(`/prometheus/upload_file`, payload, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "multipart/form-data"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(uploadingImageSuccess(response.data.s3url));
        toastr.success("", "File Successfully Uploaded");
        cb && cb(response.data.s3url);
      }
    } catch (error) {
      dispatch(uploadingImageFail(""));
    }
  };

export const getPaymentRef =
  (
    proposal_id?: number,
    paying_bank_id?: number,
    page?: number,
    length?: number,
    search?: string,
    company_id?: string,
    run_date?: string,
    proposal_code?: string,
    currency_id?: string,
    start_date?: string,
    end_date?: string
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(startRequest());
    try {
      const response = await API.get("/proposals/get_payment_ref_documents", {
        params: {
          page,
          length,
          search,
          start_date,
          end_date,
          company_id,
          run_date,
          proposal_code,
          currency_id,
          paying_bank_id,
          proposal_id
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getPaymentRefSuccess(response.data));
      }
    } catch (error) {
      dispatch(endRequest());
    }
  };

export default reducer;
