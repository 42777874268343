import { Link } from "react-router-dom";

export const reportCol = [
    {
        name: "Department Code",
        index: "department_code",
        id: 1
    },
    {
        name: "Department Description",
        index: "department_description",
        id: 2
    },
    {
        name: "Total Items",
        index: "total_purchase_order_items",
        id: 3
    },
    {
        name: "Total Orders",
        index: "total_purchase_orders",
        id: 4
    },
    {
        name: "Total Value",
        index: "total_value",
        id: 5
    }

];

export const reportData = [
    {
   
        department_code: "Brent Fawaz",
        department_description: "13",
        amount: "N23,345",
        id: 1
    },
    {
   
        location: "Brent Fawaz",
        department: "13",
        amount: "N23,345",
        id: 2
    },
    {
   
        location: "Brent Fawaz",
        department: "13",
        amount: "N23,345",
        id: 4
    }
];
