import React, { useEffect, useState } from "react";
import Modal from "components/Modal";
import Textbox from "components/Textbox";
import Button from "components/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
// import "./styles.scss";
import { useForm } from "react-hook-form";
import { doverifyVendorFile, getSingleVendor } from "store/slice/BuyerSlice/VendorSlice/VendorSlice";

type Props = {
  closeModal: () => void;
  updateNote: (val) => void;
  notes: string;
};
const NotesModal = ({ closeModal, updateNote, notes }: Props) => {
  //Redux Store
  const { handleSubmit, register, setValue } = useForm<FormData>({
    mode: "onBlur"
  });
  const { loading } = useSelector((state: RootState) => state.approvals);

  const onFormSubmit = (vals) => {
    updateNote(vals.note);
  };

  useEffect(() => {
    setValue("note", notes);
  }, []);
  return (
    <Modal modalText="Note" onClose={closeModal} boxClasses="role-modal-top">
      <div className="reject-modal">
        <p className="">Add a Note</p>
        <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
          <textarea rows={4} className="text-area mt-1" placeholder="Enter description" name="note" ref={register({ required: false })} />
          <Button label="Add note" btnType="btn-primary" boxClasses="mt-4 mb-5" btnStyle="100%" loading={loading} />
        </form>
        {/* <p className="role-p" onClick={closeModal}>
          Cancel
        </p> */}
      </div>
    </Modal>
  );
};

export default NotesModal;
