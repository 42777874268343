import React, { useEffect, useState } from "react";
import Textbox from "components/Textbox";
import { useForm } from "react-hook-form";
import Button from "components/Button";
import { useLocation, useParams } from "react-router-dom";
import arrowBack from "assests/icons/arrow-back-circle.svg";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { getAllCountries } from "store/slice/CountriesSlice/CountriesSlice";
import { getAllCities } from "store/slice/CitySlice/CitySlice";
import { getAllListedBanks } from "store/slice/BankSlice/BankSlice";
import SelectDropdown from "components/SelectDropdown";

type Props = {
  showButtons?: boolean;
};
const BankInformation: React.FunctionComponent<Props> = ({ showButtons }) => {
  //State
  const [loading, setState] = useState(false);

  //Helpers
  let history = useHistory();
  let location = useLocation();
  let { id }: any = useParams();
  const { register, handleSubmit, errors, setValue, watch } = useForm<FormData>({ mode: "onBlur" });
  const values = watch();

  //Redux Store
  const dispatch = useDispatch<AppDispatch>();
  const { singleVendor } = useSelector((state: RootState) => state.vendorDetails);
  const { onboarding_data } = useSelector((state: RootState) => state.vendorRegistration);
  const { countries } = useSelector((state: RootState) => state.country);
  const { cities } = useSelector((state: RootState) => state.city);
  const { banks } = useSelector((state: RootState) => state.bank);

  const vendorDataBank = singleVendor?.data[0]?.more_details;
  const cityId = singleVendor?.data[0]?.city_id;

  useEffect(() => {
    // dispatch(getAllCountries());
    // dispatch(getAllCities());
    // dispatch(getAllListedBanks());
  }, []);

  //Getting user country
  const cityID = cities?.find((city) => city?.id == cityId);
  const userCountry = countries?.find((country) => country?.country_description == cityID?.country_description);

  //Getting User banks off user country selected
  let countryBanks = [] as any;
  banks &&
    banks.forEach((element) => {
      if (element?.country_id === userCountry?.id) {
        countryBanks.push({
          value: element?.bank_key,
          optionName: element?.bank_description
        });
      }
    });
  //   console.log(cityID);
  //   console.log(countryBanks);
  //   console.log(banks);
  //   console.log(countries);
  //   console.log(userCountry?.country_description);
  //   console.log(cityID);
  //   console.log(onboarding_data);
  //   console.log("here");
  // useEffect(() => {
  //     if (location.state) {
  //         const { legalData }: any = location.state;
  //     } else {
  //         history.push("/supplier/registration/contact-information");
  //     }
  // }, []);

  //Updating the bank_key Input field
  useEffect(() => {
    if (values.bank) {
      setValue("bank_key", values.bank);
    } else {
      setValue("bank_key", "");
    }
  }, [values.bank]);

  type FormData = {
    bank_account_name: string;
    bank_account_number: string;
    bank_key: string;
    bank: string;
    iban: string;
  };

  useEffect(() => {
    const saved_bankDetails = localStorage.getItem("bank_data");
    if (saved_bankDetails) {
      const field_details = JSON.parse(saved_bankDetails);
      setValue("bank_account_number", field_details.bank_account_number);
      setValue("bank_account_name", field_details.bank_account_name);
      setValue("iban", field_details.iban);
      setValue("bank_key", field_details.bank_key);
      setValue("bank", field_details.bank);
    }
  }, []);

  const onSubmit = (vals: any) => {
    setState(true);
    setTimeout(() => {
      setState(false);
      localStorage.setItem("bank_data", JSON.stringify(vals));
      const { legalData }: any = location.state;
      history.push({
        pathname: `/supplier/registration/compliance-information/${id}`,
        state: { legalData: legalData, bankData: vals }
      });
    }, 2000);
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* {userCountry && ( */}
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <Textbox label="Bank Name" type="text" boxClasses="mt-4" defaultValue={vendorDataBank?.bank} disabled={true} />
            <Textbox label="Bank Country" type="text" boxClasses="mt-4" defaultValue={vendorDataBank?.country_description} disabled={true} />
            <Textbox label="Account Name" type="text" boxClasses="mt-4" defaultValue={vendorDataBank?.bank_account_name} disabled={true} />
            <Textbox label="Account Number" type="text" boxClasses="mt-4" defaultValue={vendorDataBank?.bank_account_number} disabled={true} />
            <Textbox label="Bank Key" type="text" boxClasses="mt-4" defaultValue={vendorDataBank?.bank_key} disabled={true} />
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <Textbox label="Bank Branch" type="text" boxClasses="mt-4" defaultValue={vendorDataBank?.bank_branch} disabled={true} />
            <Textbox label="Bank Address" type="text" boxClasses="mt-4" defaultValue={vendorDataBank?.bank_address} disabled={true} />
          </div>
        </div>
        {/* )} */}
        {showButtons && (
          <div className="d-flex justify-content-center align-items-center mt-5">
            <Button type="button" label="Save Draft" btnType="btn-skeleton" xtraClass="w-100" boxClasses="w-7 mr-3" />
            <Button
              type="button"
              label="Back"
              btnType="btn-skeleton"
              xtraClass="w-100 d-flex justify-content-around align-items-center py-2"
              boxClasses="w-7 mr-3"
              onClick={() => history.goBack()}
              icon={arrowBack}
            />
            <Button type="submit" label="Next" btnType="btn-temporary" xtraClass="w-100" boxClasses="w-7" loading={loading} />
          </div>
        )}
      </form>
    </>
  );
};

// export const AuthBankInformation = () => {
//     const { onboarding_data } = useSelector((state: RootState) => state.vendorRegistration);
//     return (
//         <RegistrationLayout>
//             <div className="supplier-contact-information">
//                 <h4 className="text-center">Supplier Registration</h4>
//                 <div className="form-container mt-3 pb-5">
//                     <h4 className="temp_no">Vendor ID :{onboarding_data?.onboard_code}</h4>
//                     <h6 className="form-header mt-1">Bank Information</h6>
//                     <BankInformation showButtons={true} />
//                 </div>
//             </div>
//         </RegistrationLayout>
//     );
// };

export default BankInformation;
