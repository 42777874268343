import React, { useEffect, useState } from "react";
import Modal from "components/Modal";
import { useForm, Controller } from "react-hook-form";
import Textbox from "components/Textbox";
import Button from "components/Button";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import SelectDropdown from "components/SelectDropdown";
import { useDispatch } from "react-redux";
import { createBudgetHeader, editBudgetHeader, getAllBudgetHeaders } from "store/slice/BuyerSlice/Budget/BudgetSlice";
import AsyncSelect from "react-select/async";
import API from "utils/httpsRequest";
import styled from "styled-components";

const Form = styled.form`
  .mySelect__control {
    border-radius: 10px;
    border: 1px solid rgb(223, 226, 230) !important;
  }

  .mySelect__value-container {
    height: 3rem;
  }
`;

const CreateBudget = ({ closeModal, modalData, modalText }) => {

    const dispatch = useDispatch();

    const { isLoading } = useSelector((state: RootState) => state.budget);
    const { user } = useSelector((state: RootState) => state.user);

    const [compFirst, setCompFirst] = useState(true)
    const [compVal, setCompVal] = useState('');
    const [selComVal, setSelCompVal] = useState<any>();
    const [userFirst, setUserFirst] = useState(true);
    const [userVal, setUserVal] = useState('');
    const [selUserVal, setSelUserVal] = useState<any>();

    const { token } = user;

    const {
        register,
        handleSubmit,
        errors,
        control,
    } = useForm({
        mode: "onChange",
        defaultValues: {
            budget_name: modalData?.budget_name || '',
            budget_period: modalData?.budget_period || '',
            included_items: [],
            company_id: '',
            budget_holder_user_id: `${modalData?.budget_holder_lastname} ${modalData?.budget_holder_othernames}` || '',
            start_date: modalData?.start_date || '',
            end_date: modalData?.end_date || '',
        }
    })

    const options = [
        {
            name: 'Department',
            value: 'Department',
        },
        {
            name: 'Location',
            value: 'Location',
        },
        {
            name: 'Purchasing Group',
            value: 'Purchasing Group',
        },
        {
            name: 'Company',
            value: 'Company',
        },
    ]

    const onSubmit = (vals) => {
        const data = {
            ...vals,
            company_id: selComVal?.['value'],
            budget_holder_user_id: selUserVal?.['value'],
        }
        if (modalText === 'Create') {
            // console.log(data);
            dispatch(createBudgetHeader(data, () => {
                closeModal();
                dispatch(getAllBudgetHeaders());
            }))
        } else {
            const _data = {
                ...data,
                id: modalData?.id,
            }
            // console.log(_data);
            dispatch(editBudgetHeader(_data, () => {
                closeModal();
                dispatch(getAllBudgetHeaders());
            }))
        }
    }

    const handleInputChangeComp = val => setCompVal(val);

    const onChangeComp = val => setSelCompVal(val);

    const handleInputChangeUser = val => setUserVal(val);

    const onChangeUser = val => setSelUserVal(val);

    const loadComp = () => {
        return API.get(`/companies/get?page=1&length=10&search=${compVal}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            if (compFirst && modalData?.company_description) {
                setCompFirst(false);
                res.data.data.forEach((item) => {
                    if (modalData?.company_description === item.company_description) {
                        setSelCompVal({
                            label: item.company_description,
                            value: item.id,
                        });
                    }
                })
                return res.data.data.map((item) => ({
                    label: item.company_description,
                    value: item.id,
                }))
            } else {
                return res.data.data.map((item) => ({
                    label: item.company_description,
                    value: item.id,
                }))
            }
        })
    }

    const loadUser = () => {
        return API.get(`/users/get?page=1&length=10&user_type=company&search=${userVal}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            if (userFirst && modalData?.budget_holder_user_id) {
                setUserFirst(false);
                res.data.data.forEach((item) => {
                    if (modalData?.budget_holder_user_id === item.id) {
                        setSelUserVal({
                            label: `${item.lastname} ${item.othernames}`,
                            value: item.id,
                        })
                    }
                })
            } else {
                return res.data.data.map((item) => ({
                    label: `${item.lastname} ${item.othernames}`,
                    value: item.id,
                }))
            }
        })
    }

    useEffect(() => {
        console.log(modalData)
    }, [modalData])

    return (
        <Modal
            modalText={`${modalText} Budget`}
            onClose={() => {
                closeModal();
                dispatch(getAllBudgetHeaders())
            }}
        >
            <p className="">Here you would create the budget structure</p>
            <Form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
                <Textbox
                    type="text"
                    name="budget_name"
                    label="Budget Name"
                    placeholder="Type the name of your budget"
                    customRef={register({
                        required: true
                    })}
                    error={errors.budget_name && 'This field is required'}
                />

                <br />

                {/* {user?.company_id === null && ( */}
                <Controller
                    control={control}
                    defaultValue
                    name="company_id"
                    render={({ ref }) => (
                        <AsyncSelect
                            cacheOption
                            defaultOptions
                            placeholder="Select Company"
                            value={selComVal}
                            loadOptions={loadComp}
                            onInputChange={handleInputChangeComp}
                            onChange={onChangeComp}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            classNamePrefix="mySelect"
                        />
                    )}
                />
                {/* )} */}

                <br />

                <Controller
                    control={control}
                    defaultValue
                    name="budget_holder_user_id"
                    render={({ ref }) => (
                        <AsyncSelect
                            cacheOption
                            defaultOptions
                            placeholder="Select Assigned User"
                            value={selUserVal}
                            loadOptions={loadUser}
                            onInputChange={handleInputChangeUser}
                            onChange={onChangeUser}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            classNamePrefix="mySelect"
                        />
                    )}
                />


                <fieldset style={{
                    marginTop: '2rem',
                }}>
                    <legend style={{
                        fontSize: '16px'
                    }}>What should be included in your budget?</legend>
                    {options.map((option, index) => (
                        <label
                            htmlFor="included_items"
                            key={index}
                            style={{
                                display: 'block',
                            }}
                        >
                            {/* <Controller
                                control={control}
                                name="included_items"
                                ref={register}
                                render={({ onChange }) => (
                                    <input
                                        type="checkbox"
                                        checked={modalData?.included_items?.includes(option.name)}
                                        onChange={(e) => {
                                            console.log(e.target)
                                            onChange(e.target.value)
                                        }}
                                        style={{
                                            marginRight: '1rem'
                                        }}
                                    />
                                )}
                            /> */}

                            <input
                                type="checkbox"
                                value={option.value}
                                name="included_items"
                                // checked={true}
                                ref={register}
                                style={{
                                    marginRight: '1rem'
                                }}
                            />
                            {option.name}
                        </label>
                    ))}
                </fieldset>

                <fieldset style={{
                    marginTop: '2rem'
                }}>
                    <legend style={{ fontSize: '16px' }}>
                        What is the period of your budget?
                    </legend>
                    <div className="row" style={{
                        marginBottom: '2rem',
                    }}>
                        <div className="col col-12">
                            <SelectDropdown
                                name="budget_period"
                                label="Period"
                                placeholder="Select Period"
                                options={[
                                    { optionName: 'Monthly', value: 'Monthly' },
                                    { optionName: 'Quaterly', value: 'Quaterly' },
                                    { optionName: 'Yearly', value: 'Yearly' },
                                ]}
                                customRef={register({ required: true })}
                                error={errors.budget_period && 'This field is required'}
                            />
                        </div>
                    </div>
                    <legend style={{ fontSize: '16px' }}>
                        What is the start and end date your budget?
                    </legend>
                    <div className="row">
                        <div className="col col-6">
                            <Textbox
                                type="date"
                                boxClasses="mt-3"
                                name="start_date"
                                label="Start Date"
                                placeholder="Select Start Date"
                                error={errors.start_date && "This field is required"}
                                customRef={register({
                                    required: true
                                })}
                            />
                        </div>
                        <div className="col col-6">
                            <Textbox
                                type="date"
                                boxClasses="mt-3"
                                name="end_date"
                                label="End Date"
                                placeholder="Select End Date"
                                customRef={register({ required: true })}
                                error={errors.end_date && 'This field is required'}
                            />
                        </div>
                    </div>
                </fieldset>

                <Button
                    label={"Create Budget"}
                    btnType="btn-primary"
                    boxClasses="mt-4 mb-3"
                    btnStyle="100%"
                    loading={isLoading} />
                <p onClick={closeModal} style={{ textAlign: "center", cursor: "pointer" }}>
                    Cancel
                </p>
            </Form>
        </Modal>
    )
}

export default CreateBudget;