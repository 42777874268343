import React, { useEffect, useState } from "react";
import Modal from "components/Modal";
import Textbox from "components/Textbox";
import Button from "components/Button";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import "./styles.scss";
import { useForm } from "react-hook-form";
import DataTable from "components/DataTable";
import { debitLineColumns } from "./data";
import { addDebitLine, fetchDebitLines, getSinglePaymentProprosals, removeDebitLine } from "store/slice/BuyerSlice/PostedInvoiceSlice/PostedInvoiceSlice";
import { useParams } from "react-router";

type Props = {
  closeModal: () => void;
  ids: any;
};
const DebitLines = ({ closeModal, ids }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  let { id }: any = useParams();
  useEffect(() => {
    dispatch(fetchDebitLines(ids.vendor_id, ids.proposal_items_id));
  }, []);

  const { debitLines, singlePaymentProposals, loading } = useSelector((state: RootState) => state.postedInvoice);
  const removeDebit = (_id: string) => {
    dispatch(removeDebitLine({ proposal_item_id: ids.proposal_items_id, invoice_debit_id: _id }, () => dispatch(getSinglePaymentProprosals(id))));
  };
  const addDebit = (_id: string) => {
    dispatch(addDebitLine({ proposal_item_id: ids.proposal_items_id, invoice_debit_id: _id }, () => dispatch(getSinglePaymentProprosals(id))));
  };

  const checkDebitLines = singlePaymentProposals?.data[0]?.proposal_items[ids.index].debit_lines_added.map(({ document_number }) => document_number);
  const removeRE = debitLines?.data?.filter(({ document_type }) => document_type !== "RE" && document_type !== "KR");
  const tableData = removeRE?.map(({ document_type, document_number, payment_date, amount, id, invoice_debit_description }) => ({
    document_type,
    document_number,
    payment_date,
    amount,
    checkDebitLines,
    loading,
    invoice_debit_description,
    remove: () => removeDebit(id),
    add: () => addDebit(id),
    id
  }));

  return (
    <Modal modalText="Select Open Items" onClose={closeModal} boxClasses="content-wrap_">
      <div>
        <DataTable columns={debitLineColumns} data={tableData} loading={false} />
      </div>
    </Modal>
  );
};

export default DebitLines;
