import Button from "components/Button";
import { ReactComponent as Checked } from "../../assests/icons/Vector (3).svg";
import { ReactComponent as Arrowdown } from "../../assests/icons/Vector (3) copy.svg";
import { ReactComponent as Arrowup } from "../../assests/icons/Vector (4).svg";
import { ReactComponent as Doc } from "../../assests/icons/Group (1) copy.svg";
import "./styles.scss";
import { formatCurrency } from "utils/utils";
import { useEffect, useState } from "react";

type Props = {
  arr: any[];
  headerChecked: any[];
  index: number | null;
  handlerAccordion: (val) => void | undefined;
  checkAccordion: (val) => boolean | undefined;
  getAmount: (val) => void | undefined;
  handleCheckbox: (val, name, i, index) => void | undefined;
  isDisabled?: boolean;
  btnClicked?: (e, data, i) => void | undefined;
  handleCheckboxDebit?: (data, id) => void | undefined;
  getSelected?: (val: object) => number;
  loading?: boolean;
};

const Accordion: React.FunctionComponent<Props> = ({
  isDisabled = false,
  arr = [],
  handlerAccordion,
  getAmount,
  checkAccordion,
  handleCheckbox,
  index,
  headerChecked = [],
  btnClicked,
  getSelected,
  handleCheckboxDebit,
  loading = false
}) => {
  // console.log(arr, arr[0].amount, "olol");

  const [totalNumber, setTotalNumber] = useState(null);

  // useEffect(() => {
  //   if (arr.length > 0) {
  //     let totalArr = arr[0].invoice
  //       .filter((el) => el)
  //       .map((item) => parseInt(item.amount) - parseInt(item.witholding_tax))
  //       .reduce((cur, next) => cur + next);

  //     console.log(totalArr);
  //     setTotalNumber(totalArr);
  //   }
  // }, [arr]);

  return (
    <div>
      {arr &&
        arr.map((data, i) => (
          <div className="accordion" id="accordion" key={i}>
            <div className="accordion-item">
              <div
                className="d-flex container accordion-header align-items-center"
                id="headingOne"
                onClick={() => handlerAccordion(i)}
                data-bs-toggle="collapse"
                data-bs-target={`#collapseOne_${i}`}
                aria-expanded="true"
                aria-controls={`collapseOne_${i}`}
              >
                <div className="items d-flex">
                  <div className="first">
                    {!isDisabled && (
                      <span className={`check-head mr-2 ${!checkAccordion(data) && "active"}`}>
                        <Checked />
                      </span>
                    )}
                    <div className="row w-100">
                      <div className="col-3 first d-inline-block">
                        {data.name} <span>| {data.vendor_code.replace(/^0+/, "")}</span>
                      </div>
                      <div className="col-3 second">
                        Proposal AMT <span>| {formatCurrency(data.proposal_amt)}</span>
                      </div>
                      <div className="col-3 second">
                        Invoice AMT <span>| {Number(getAmount(data)).toLocaleString()}</span>
                        {/* Invoice AMT <span>| {totalNumber || "N/A"} </span> */}
                      </div>
                      <div className="col-3 second">
                        Selected Items: <span>{getSelected ? getSelected(data?.invoice_added?.length) : "0"} </span>
                      </div>
                    </div>
                  </div>
                  {!isDisabled && (
                    <div className="second">
                      <button className="px-4 py-1 btn-block btn btn-primary" onClick={(e) => btnClicked && btnClicked(e, data, i)}>
                        Add Debit Lines
                      </button>
                    </div>
                  )}
                </div>
                <div className="icon d-flex">{i === index ? <Arrowup /> : <Arrowdown />}</div>
              </div>
              <div id={`collapseOne_${i}`} className={`accordion-collapse trans collapse ${i === index && "show"}`} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <table className="w-100">
                    <tbody>
                      <tr className="table-header">
                        {/* {!isDisabled && <th style={{ width: "6rem" }}></th>} */}
                        <th>Posting Date</th>
                        <th>Invoice Number</th>
                        <th>PO Number</th>
                        <th>Invoice Amount</th>
                        <th>WHT Amount</th>
                        <th>Currency</th>
                        <th></th>
                      </tr>

                      {data.invoice?.map((item, index) => {
                        if (item) {
                          if (!item.id) {
                            return (
                              <tr className="table-data-row" key={index}>
                                <td>
                                  {!isDisabled && (
                                    <input
                                      type="checkbox"
                                      onChange={() => handleCheckbox(item, data, i, index)}
                                      checked={data.invoice_added.includes(item.invoice_code)}
                                      className="cursor-pointer ml-3"
                                      disabled={loading}
                                    />
                                  )}
                                  <span className="mx-auto">{item?.date}</span>
                                </td>
                                <td>{item?.invoice_number}</td>
                                <td>{item?.purchase_order_code}</td>
                                <td>{formatCurrency(item?.amount)}</td>
                                <td>{formatCurrency(item?.witholding_tax)}</td>
                                <td>{item?.currency}</td>
                                <td>
                                  {item?.invoice_document_url && (
                                    <a href={item.invoice_document_url} target="_blank" rel="noreferrer">
                                      <Doc />
                                    </a>
                                  )}
                                </td>
                              </tr>
                            );
                          } else if (data.invoice_added.includes(item.invoice_code)) {
                            return (
                              <tr className="table-data-row" key={index}>
                                <td>
                                  {!isDisabled && (
                                    <input
                                      type="checkbox"
                                      onChange={() => handleCheckbox(item, data, i, index)}
                                      checked={data.invoice_added.includes(item.invoice_code)}
                                      className="cursor-pointer ml-3"
                                      disabled={loading}
                                    />
                                  )}
                                  <span className="mx-auto">{item?.date}</span>
                                </td>
                                <td>{item?.invoice_number}</td>
                                <td>{item?.purchase_order_code}</td>
                                <td>{formatCurrency(item?.amount)}</td>
                                <td>{formatCurrency(item?.witholding_tax)}</td>
                                <td>{item?.currency}</td>
                                <td>
                                  {item?.invoice_document_url && (
                                    <a href={item.invoice_document_url} target="_blank" rel="noreferrer">
                                      <Doc />
                                    </a>
                                  )}
                                </td>
                              </tr>
                            );
                          }
                        }
                      })}
                      {/* Debit Lines */}
                      {data.debitLines?.map((item, index) => (
                        <tr className="table-data-row debit" key={index}>
                          <td className="debit">
                            {!isDisabled && (
                              <input
                                type="checkbox"
                                onChange={() => handleCheckboxDebit && handleCheckboxDebit(item.id, data.id)}
                                checked={true}
                                // checked={data.invoice_added.includes(item.invoice_code)}
                                className="cursor-pointer ml-3"
                                disabled={loading}
                              />
                            )}
                            <span className="mx-auto">{item?.date}</span>
                          </td>
                          <td>{item?.invoice_number}</td>
                          <td>{item?.purchase_order_code}</td>
                          <td>{formatCurrency(item?.amount)}</td>
                          <td></td>
                          <td>{item?.currency}</td>
                          <td>
                            {item?.invoice_document_url && (
                              <a href={item.invoice_document_url} target="_blank" rel="noreferrer">
                                <Doc />
                              </a>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Accordion;
