import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { vendorState, Action } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";

const initialState: vendorState = {
  loading: false,
  singleVendor: null,
  vendors: null,
  vendorsDownload: null,
  successMessage: "",
  errorMessage: "",
  vendorParam: null,
  vendor_category: null
};

const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    getSingleVendorLoading: (state) => {
      state.loading = true;
    },
    getSingleVendorSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.singleVendor = action.payload;
    },
    getSingleVendorFail: (state) => {
      state.loading = false;
    },
    changeVendorTypeLoading: (state) => {
      state.loading = true;
    },
    changeVendorTypeSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.successMessage = action.payload;
    },
    changeVendorTypeFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    wtTaxTypeLoading: (state) => {
      state.loading = true;
    },
    wtTaxTypeSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.successMessage = action.payload;
    },
    wtTaxTypeFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    verifyVendorFileLoading: (state) => {
      state.loading = true;
    },
    verifyVendorFileSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.successMessage = action.payload;
    },
    verifyVendorFileFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    getVendorsLoading: (state) => {
      state.loading = true;
    },
    getVendorsSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.vendors = action.payload;
    },
    getVendorsDownloadSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.vendorsDownload = action.payload;
    },
    getVendorsFail: (state) => {
      state.loading = false;
    },
    getVendorsCatLoading: (state) => {
      state.loading = true;
    },
    getVendorsCatSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.vendor_category = action.payload;
    },
    getVendorsCatFail: (state) => {
      state.loading = false;
    },
    createVendorLoading: (state) => {
      state.loading = true;
    },
    createVendorSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.successMessage = action.payload;
    },
    createVendorFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    startRequest: (state) => {
      state.loading = true;
    },
    endRequest: (state) => {
      state.loading = false;
    }

    // editProductDataLoading: (state) => {
    //   state.loading = true;
    // },
    // editProductDataSuccess: (state, action: PayloadAction<Action>) => {
    //   state.loading = false;
    //   state.Approvals = action.payload;
    // },
    // editProductDataFail: (state, action: PayloadAction<string>) => {
    //   state.loading = false;
    //   state.errorMessage = action.payload;
    // },
    // deleteProductDataLoading: (state) => {
    //   state.deleting = true;
    // },
    // deleteProductDataSuccess: (state, action: PayloadAction<Action>) => {
    //   state.deleting = false;
    //   state.Approvals = action.payload;
    // },
    // deleteProductDataFail: (state, action: PayloadAction<string>) => {
    //   state.deleting = false;
    //   state.errorMessage = action.payload;
    // }
  }
});

const { actions, reducer } = vendorSlice;
export const {
  getSingleVendorFail,
  getSingleVendorLoading,
  getSingleVendorSuccess,
  changeVendorTypeFail,
  changeVendorTypeLoading,
  changeVendorTypeSuccess,
  verifyVendorFileFail,
  verifyVendorFileLoading,
  verifyVendorFileSuccess,
  getVendorsFail,
  getVendorsLoading,
  getVendorsSuccess,
  createVendorFail,
  createVendorLoading,
  createVendorSuccess,
  wtTaxTypeFail,
  wtTaxTypeLoading,
  wtTaxTypeSuccess,
  startRequest,
  endRequest,
  getVendorsCatFail,
  getVendorsCatLoading,
  getVendorsCatSuccess,
  getVendorsDownloadSuccess
} = actions;

export const getSingleVendor =
  (id?: string, cb?: () => void, page?: number, length?: number): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getSingleVendorLoading());
    try {
      const response = await API.get(`/vendors/get/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`
        },
        params: {
          page,
          length
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getSingleVendorSuccess(response.data));
        cb && cb();
      }
    } catch (error) {
      dispatch(getSingleVendorFail());
    }
  };

export const doChangeVendorType =
  (data?: object): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(changeVendorTypeLoading());
    try {
      // params: {},
      const response = await API.post(`/vendors/set_vendor_type`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(changeVendorTypeSuccess(response.data.message));
        toastr.success("", response.data.message);
      }
    } catch (error) {
      dispatch(changeVendorTypeFail(error.response.data));
      toastr.error("", error.response.data.message);
    }
  };
export const wtTaxType =
  (data?: object, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(wtTaxTypeLoading());
    try {
      // params: {},
      const response = await API.post(`/vendors/set_vendor_witholding_tax`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(wtTaxTypeSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      dispatch(wtTaxTypeFail(error.response.data));
      toastr.error("", error.response.data.message);
    }
  };

export const setvendorCurrency =
  (data?: object, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(wtTaxTypeLoading());
    try {
      // params: {},
      const response = await API.post(`/vendors/set_vendor_currency`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(wtTaxTypeSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      dispatch(wtTaxTypeFail(error.response.data));
      toastr.error("", error.response.data.message);
    }
  };

export const doverifyVendorFile =
  (data?: object, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(verifyVendorFileLoading());
    try {
      // params: {},
      const response = await API.post(`/vendors/verify_vendor_file`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(verifyVendorFileSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      dispatch(verifyVendorFileFail(error.response.data));
      toastr.error("", error.response.data.message);
    }
  };

export const dounverifyVendorFile =
  (data?: object, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(verifyVendorFileLoading());
    try {
      // params: {},
      const response = await API.post(`/vendors/unverify_vendor_file`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(verifyVendorFileSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      dispatch(verifyVendorFileFail(error.response.data));
      toastr.error("", error.response.data.message);
    }
  };

export const getVendors =
  (
    page?: number,
    length?: number,
    search?: string,
    options?: {
      vendor_status?: string;
      vendor_code?: string;
      city_id?: string;
      vendor_classification?: string;
      sap_sync?: string;
      fetch_type?: string;
      business_category_id?: string;
      has_logged_in?: string;
      onboard_status?: string;
    },
    country_id?: string | any
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getVendorsLoading());
    try {
      const response = await API.get(`/vendors/get`, {
        params: {
          page,
          length,
          search,
          country_id,
          ...options
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getVendorsSuccess(response.data));
      }
    } catch (error) {
      dispatch(getVendorsFail());
    }
  };

export const getVendorsDownload =
  (
    page?: number,
    length?: number,
    search?: string,
    options?: {
      vendor_status?: string;
      vendor_code?: string;
      city_id?: string;
      vendor_classification?: string;
      sap_sync?: string;
      fetch_type?: string;
      business_category_id?: string;
      has_logged_in?: string;
      onboard_status?: string;
    },
    country_id?: string | any
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getVendorsLoading());
    try {
      const response = await API.get(`/vendors/get`, {
        params: {
          page,
          length: "40000",
          search,
          country_id,
          ...options
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getVendorsDownloadSuccess(response.data));
      }
    } catch (error) {
      dispatch(getVendorsFail());
    }
  };

export const getVendorsCat =
  (page?: number, length?: number, search?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getVendorsCatLoading());
    try {
      const response = await API.get(`/business_categories/get`, {
        params: {
          page,
          length,
          search,
          business_category_type: "Parent"
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getVendorsCatSuccess(response.data));
      }
    } catch (error) {
      dispatch(getVendorsCatFail());
    }
  };

export const getVendorsParam =
  (
    page?: number,
    length?: number,
    search?: string,
    options?: {
      vendor_status?: string;
      vendor_code?: string;
      city_id?: string;
      vendor_classification?: string;
      sap_sync?: string;
      fetch_type?: string;
    },
    country_id?: string | any
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getVendorsLoading());
    try {
      const response = await API.get(`/vendors/get`, {
        params: {
          page,
          length,
          search,
          country_id,
          ...options
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getVendorsSuccess(response.data));
      }
    } catch (error) {
      dispatch(getVendorsFail());
    }
  };

export const createVendor =
  (data?: object, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(createVendorLoading());
    try {
      const response = await API.post(`/vendors/create`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(createVendorSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      dispatch(createVendorFail(error.response.data));
      toastr.error("", error.response.data.message);
    }
  };

// export const createProductData =
//   (data: any, cb?: () => void): AppThunk =>
//   async (dispatch: AppDispatch, getState) => {
//     const { userToken } = getState().user;
//     dispatch(createProductDataLoading());
//     try {
//       const response = await API.post(`/Approvals/create`, data, {
//         headers: {
//           Authorization: `Bearer ${userToken}`
//         }
//       });
//       if ([200, 201].includes(response.status)) {
//         dispatch(createProductDataSuccess(response.data.message));
//         toastr.success("", response.data.message);
//         cb && cb();
//       }
//     } catch (error) {
//       dispatch(createProductDataFail(error.response.data?.message));
//       toastr.error(error.response.data.message);
//       setTimeout(() => {
//         dispatch(createProductDataFail(""));
//       }, 3000);
//     }
//   };

export const updateVendorData =
  (data?: object, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(startRequest());
    try {
      const response = await API.post(`/vendors/update_data`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(endRequest());
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      dispatch(endRequest());
      toastr.error("", error.response.data.message);
    }
  };

export const confirmUpdateVendorData =
  (data?: object, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(startRequest());
    try {
      const response = await API.post(`/vendors/confirm_onboard_submission`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(endRequest());
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      dispatch(endRequest());
      toastr.error("", error.response.data.message);
    }
  };

export default reducer;
