/* eslint-disable react-hooks/exhaustive-deps */
import StatsCard from "components/StatsCard";
import React, { useEffect, useState } from "react";
import "./styles.scss";
import BusinessTrade from "assests/icons/buyer/business-and-trade.svg";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import SearchIcon from "components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { useDispatch, useSelector } from "react-redux";
import { companyColumn } from "./data";
import { AppDispatch, RootState } from "store/store";
import { delPoReq, getAllPOReq } from "store/slice/BuyerSlice/CompanyDataSlice/CompanyDataSlice";
import { toastPopUp } from "utils/utils";
import useSearch from "hooks/useSearch";
import Pagination from "components/Pagination";
import { checkPermission } from "utils/utils";
import CreateOREditLegacyPo from "components/ModalBuyer/CreateOrEditLegacyPo";

const LegacyPo = () => {
  //State
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [singleCompanyData, setSingleCompanyData] = useState<any>(null);
  const { role_access } = useSelector((state: RootState) => state.user?.user);

  //Redux
  const dispatch = useDispatch<AppDispatch>();
  const { companies, poReq, loading } = useSelector((state: RootState) => state.companiesData);
  console.log(poReq, "popp");
  //State
  const [modal, setModal] = useState<boolean>(false);
  const closeModal = () => {
    setModal((prev) => !prev);
    setSingleCompanyData(null);
  };

  //Hook
  const { search, inputRef, handleChange, searched } = useSearch(getAllPOReq, perPage);

  //Show Number for PerPage Request
  const handleNewPage = (pageNo: number, page: number = 1) => {
    setPerPage(pageNo);
    setPage(page);
    dispatch(getAllPOReq(page, pageNo, search));
  };

  // setpage to the first page when a searched executed
  useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  useEffect(() => {
    dispatch(getAllPOReq(page, perPage));
    dispatch(getAllPOReq(page, perPage));
  }, []);

  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, perPage: number) => {
      setPage(page);
      dispatch(getAllPOReq(page, perPage, search));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, perPage: number) => {
      setPage(pageNo);
      dispatch(getAllPOReq(pageNo, perPage, search));
    },
    [search]
  );
  const deleteEntity = (name: string, id: string) => {
    const payload = { purchase_order_request_id: id };

    toastPopUp(
      () => {
        dispatch(
          delPoReq(payload, () => {
            dispatch(getAllPOReq(page, perPage));
          })
        );
      },
      "Delete",
      "Cancel",
      "Delete the entity",
      name,
      "red"
    );
  };

  const poReqList =
    poReq &&
    poReq?.data?.map((item) => {
      return {
        id: item.id,
        name: item.company_description,
        email: item.email,
        purchase_order_code: item.purchase_order_code,
        deleteEntity: () => {
          deleteEntity(item.purchase_order_code, item.id);
        }
      };
    });

  return (
    <div id="settings-company">
      {modal && <CreateOREditLegacyPo singleCompanyData={singleCompanyData} closeModal={closeModal} />}

      <div className="container">
        <h3 className="page-title">Legacy PO</h3>
        <div className="row mt-3 mb-5">
          <StatsCard statsNumber={poReq?.count ?? 0} text="Legacy PO" classes="stats-icon" image={BusinessTrade} iconBackgroundColor="rgba(45, 156, 219, 0.1)" />
          <StatsCard image={AddIcon} classes="middle-img" centerText="Create Entity" onClick={closeModal} />
        </div>

        <div className="d-flex  mb-3">
          <SearchIcon placeholder="Search for ID or Discription" boxClasses="" textRef={inputRef} value={search} onChange={handleChange} />
          <Dropdown perPage={perPage} action={handleNewPage} />
        </div>
        <DataTable columns={companyColumn} data={poReqList} loading={loading} />
        {poReq?.count > 10 ? (
          <Pagination page={page} lastPage={poReq?.pagination?.lastPage} paginate={paginate} total={poReq?.pagination?.total} handlePageChange={handlePageChange} perPage={perPage} />
        ) : null}
      </div>
    </div>
  );
};

export default LegacyPo;
