/* eslint-disable react-hooks/exhaustive-deps */
import StatsCard from "components/StatsCard";
import React, { useEffect, useState } from "react";
import "./styles.scss";
import BusinessTrade from "assests/icons/buyer/business-and-trade.svg";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import SearchIcon from "components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { useDispatch, useSelector } from "react-redux";
import { companyColumn, companydatas } from "./data";
import CreateOREditLegalEntity from "components/ModalBuyer/CreateOrEditLegalEntity";
import { AppDispatch, RootState } from "store/store";
import { getAllCompaniesData, deleteCompanyData, getAllAuditData, getAllAuditData2 } from "store/slice/BuyerSlice/CompanyDataSlice/CompanyDataSlice";
import { toastPopUp } from "utils/utils";
import useSearch from "hooks/useSearch";
import Pagination from "components/Pagination";
import { checkPermission } from "utils/utils";
import CreateOREditLegacyPo from "components/ModalBuyer/CreateOrEditLegacyPo";
import moment from "moment";
import FilterCard from "components/FilterCard";
import { Controller, useForm } from "react-hook-form";
import filterIcon from "assests/icons/buyer/filter-icon.svg";
import API from "utils/httpsRequest";
import AsyncSelect from "react-select/async";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const Audit = () => {
  //State
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [singleCompanyData, setSingleCompanyData] = useState<any>(null);
  const { role_access } = useSelector((state: RootState) => state.user?.user);
  const [showFilter, setShowFilter] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [comp, setComp] = useState("");
  const [vend, setVend] = useState("");
  const [dept, setDept] = useState("");
  const [loc, setLoc] = useState("");

  const { register, handleSubmit, reset, control } = useForm({
    mode: "onChange",
    defaultValues: {
      start_date: "",
      end_date: "",
      from: "",
      to: ""
    }
  });

  //Redux
  const dispatch = useDispatch<AppDispatch>();
  const { audit, audit2, companies, loading } = useSelector((state: RootState) => state.companiesData);
  console.log(audit, "ajsjsjjk");
  //State
  const [modal, setModal] = useState<boolean>(false);
  const closeModal = () => {
    setModal((prev) => !prev);
    setSingleCompanyData(null);
  };

  // form side of filter

  const { user } = useSelector((state: RootState) => state.user);
  const { token } = user;
  const [vendorSel, setVendorSel] = React.useState("");
  const [selUserVal, setSelVendorVal] = React.useState("");
  const onChangeVendor = (value) => {
    setSelVendorVal(value);
  };
  const handleInputChangeVendor = (value) => {
    setVendorSel(value);
  };

  const [companySel, setCompanySel] = React.useState("");
  const [selCompanyVal, setSelCompanyVal] = React.useState("");
  const onChangeCompany = (value) => {
    setSelCompanyVal(value);
  };
  const handleInputChangeCompany = (value) => {
    setCompanySel(value);
  };

  const [deptSel, setDeptSel] = React.useState("");
  const [selDeptVal, setSelDeptVal] = React.useState("");
  const onChangeDept = (value) => {
    setSelDeptVal(value);
  };
  const handleInputChangeDept = (value) => {
    setDeptSel(value);
  };

  const [locSel, setLocSel] = React.useState("");
  const [selLocVal, setSelLocVal] = React.useState("");
  const onChangeLoc = (value) => {
    setSelLocVal(value);
  };
  const handleInputChangeLoc = (value) => {
    setLocSel(value);
  };

  const vendorOptions = () => {
    return API.get(`/vendors/get?search=${vendorSel}&length=1000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: item.vendor_description,
        value: item.id
      }));
    });
  };
  const companyOptions = () => {
    return API.get(`/companies/get?search=${companySel}&length=1000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: item.company_description,
        value: item.id
      }));
    });
  };
  const deptOptions = () => {
    return API.get(`/departments/get?search=${deptSel}&length=1000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: item.department_description,
        value: item.id
      }));
    });
  };
  const locOptions = () => {
    return API.get(`/locations/get?search=${locSel}&length=1000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: item.location_description,
        value: item.id
      }));
    });
  };

  //Hook
  const { search, inputRef, handleChange, searched } = useSearch(getAllAuditData, perPage, "", [undefined, vend, comp, undefined, dept, loc, startDate, endDate]);

  //Show Number for PerPage Request
  const handleNewPage = (pageNo: number, page: number = 1) => {
    setPerPage(pageNo);
    setPage(page);
    dispatch(getAllAuditData(page, pageNo, search));
  };

  // setpage to the first page when a searched executed
  useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  useEffect(() => {
    dispatch(getAllAuditData(page, perPage));
    dispatch(getAllAuditData2());
  }, []);

  //Edit CompanyData
  const editCompanyData = (id: string, desc: string, code: string, country: string, country_id: string, currency: string, currencyId: string) => {
    setSingleCompanyData({ id, desc, code, country, country_id, currency, currencyId });
    setModal((prev) => !prev);
  };

  const onSubmit = (vals) => {
    setStartDate(vals.start_date);
    setEndDate(vals.end_date);
    setLoc(selLocVal["value"]);
    setDept(selDeptVal["value"]);
    setVend(selUserVal["value"]);
    setComp(selCompanyVal["value"]);
    dispatch(getAllAuditData(page, perPage, search, undefined, selUserVal["value"], selCompanyVal["value"], undefined, selDeptVal["value"], selLocVal["value"], vals.start_date, vals.end_date));
  };

  const handleClear = () => {
    setStartDate("");
    setEndDate("");
    setLoc("");
    setDept("");
    setVend("");
    setComp("");
    setSelVendorVal("");
    setSelCompanyVal("");
    setSelDeptVal("");
    setSelLocVal("");

    dispatch(getAllAuditData(page, perPage, search));
    reset();
  };

  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, perPage: number) => {
      setPage(page);
      dispatch(getAllAuditData(page, perPage, search, undefined, vend, comp, undefined, dept, loc, startDate, endDate));
    },
    [search, vend, comp, dept, loc, startDate, endDate]
  );

  const paginate = React.useCallback(
    (pageNo: number, perPage: number) => {
      setPage(pageNo);
      dispatch(getAllAuditData(pageNo, perPage, search, undefined, vend, comp, undefined, dept, loc, startDate, endDate));
    },
    [search, vend, comp, dept, loc, startDate, endDate]
  );
  const deleteCompany = (name: string, id: string) => {
    const payload = { id: id };

    toastPopUp(
      () => {
        dispatch(
          deleteCompanyData(payload, () => {
            dispatch(getAllAuditData(page, perPage));
          })
        );
      },
      "Delete",
      "Cancel",
      "Delete the entity",
      name,
      "red"
    );
  };
  console.log(companies);
  const companiesList =
    audit &&
    audit?.data?.map((item) => {
      return {
        user: `${item.lastname} ${item.othernames}`,
        action: item.action_performed,
        time: `${moment(item.created_at).format("L")} || ${moment(item.created_at).format("LT")}`,
        platform: item.platform,
        type: item.action_type,
        ip: item.ip_address
        // deleteEntity: () => checkPermission(role_access, "SETUP_INDEX") && deleteCompany(company.location_description, company.id)
      };
    });

  const companiesList2 =
    audit2 &&
    audit2?.data?.map((item) => {
      return {
        user: `${item.lastname} ${item.othernames}`,
        action: item.action_performed,
        time: `${moment(item.created_at).format("L")} || ${moment(item.created_at).format("LT")}`,
        platform: item.platform,
        type: item.action_type,
        ip: item.ip_address
        // deleteEntity: () => checkPermission(role_access, "SETUP_INDEX") && deleteCompany(company.location_description, company.id)
      };
    });

  console.log(audit2, companiesList2);

  return (
    <div id="settings-company">
      {modal && <CreateOREditLegacyPo singleCompanyData={singleCompanyData} closeModal={closeModal} />}

      <div className="container">
        <h3 className="page-title">Audit</h3>
        <div className="row mt-3 mb-5">
          <StatsCard statsNumber={audit?.count ?? 0} text="Audit Log" classes="stats-icon" image={BusinessTrade} iconBackgroundColor="rgba(45, 156, 219, 0.1)" />
          {/* <StatsCard image={AddIcon} classes="middle-img" centerText="Create Entity" onClick={closeModal} /> */}
        </div>
        {showFilter && (
          <FilterCard handleSubmit={handleSubmit(onSubmit)} clear={handleClear}>
            <div className="row">
              <div className="col col-4">
                <label style={{ display: "block" }}>Vendor</label>
                <Controller
                  control={control}
                  defaultValue
                  boxClasses="mt-3"
                  name="user_id"
                  render={({ ref }) => (
                    <AsyncSelect cacheOptions defaultOptions placeholder="Vendor" value={selUserVal} loadOptions={vendorOptions} onInputChange={handleInputChangeVendor} onChange={onChangeVendor} />
                  )}
                />
              </div>

              <div className="col col-4">
                <label style={{ display: "block" }}>Company</label>
                <Controller
                  control={control}
                  defaultValue
                  boxClasses="mt-3"
                  name="user_id"
                  render={({ ref }) => (
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      placeholder="Company"
                      value={selCompanyVal}
                      loadOptions={companyOptions}
                      onInputChange={handleInputChangeCompany}
                      onChange={onChangeCompany}
                    />
                  )}
                />
              </div>

              <div className="col col-4">
                <label style={{ display: "block" }}>Department</label>
                <Controller
                  control={control}
                  defaultValue
                  boxClasses="mt-3"
                  name="user_id"
                  render={({ ref }) => (
                    <AsyncSelect cacheOptions defaultOptions placeholder="Department" value={selDeptVal} loadOptions={deptOptions} onInputChange={handleInputChangeDept} onChange={onChangeDept} />
                  )}
                />
              </div>

              <div className="col col-4 mt-3">
                <label style={{ display: "block" }}>Location</label>
                <Controller
                  control={control}
                  defaultValue
                  boxClasses="mt-3"
                  name="user_id"
                  render={({ ref }) => (
                    <AsyncSelect cacheOptions defaultOptions placeholder="Location" value={selLocVal} loadOptions={locOptions} onInputChange={handleInputChangeLoc} onChange={onChangeLoc} />
                  )}
                />
              </div>

              {/* <div className="col mt-3 col-4">
                <label style={{ display: "block" }}>Start Date</label>
                <input
                  type="date"
                  name="created_at"
                  className=""
                  placeholder="Date"
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  ref={register({ required: false })}
                />
              </div> */}

              <div className="col col-4 mt-3">
                <label style={{ display: "block" }}>Start Date</label>
                <input
                  type="date"
                  name="start_date"
                  className=""
                  placeholder="Start Date"
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  ref={register({ required: false })}
                />
              </div>
              <div className="col col-4 mt-3">
                <label style={{ display: "block" }}>End Date</label>
                <input
                  type="date"
                  name="end_date"
                  className=""
                  placeholder="End Date"
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  ref={register({ required: false })}
                />
              </div>
            </div>
          </FilterCard>
        )}
        <div style={{ justifyContent: "space-between", alignItems: "center" }} className="d-flex  mb-3">
          <SearchIcon placeholder="Search for ID or Discription" boxClasses="" textRef={inputRef} value={search} onChange={handleChange} />
          {/* <Dropdown perPage={perPage} action={handleNewPage} /> */}

          <ReactHTMLTableToExcel id="test-table-xls-button" className="download-table-xls-button btn btn-success" table="table-to-xls" filename="Audit Log" sheet="tablexls" buttonText="Download" />

          <div className="downloadTable" style={{ display: "none" }}>
            <table id="table-to-xls">
              <thead>
                <tr>
                  <th>Name</th>

                  <th>Action Type</th>
                  <th>Action Description</th>
                  <th>Time</th>
                  <th>Platform</th>

                  <th className="extraTh">IP</th>
                </tr>
              </thead>
              <tbody>
                {companiesList2 &&
                  companiesList2.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{item.user}</td>
                        <td>{item.type}</td>
                        <td>{item.action}</td>
                        <td>{item.time}</td>

                        <td>{item.platform}</td>
                        <td>{item.ip}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>

          <div>
            <button
              style={{
                padding: "5px 10px",
                borderRadius: "10px",
                background: "#FFFFFF",
                color: "#737A91",
                border: "1px solid #DFE2E6",
                fontSize: "18px",
                marginLeft: "5px",
                width: "max-content"
              }}
              onClick={() => setShowFilter(!showFilter)}
            >
              <img src={filterIcon} alt="icon" className="icon-image" />
              <span
                style={{
                  paddingLeft: "1rem"
                }}
              >
                {!showFilter ? "Filters" : "Hide Filters"}
              </span>
            </button>
          </div>
        </div>
        <DataTable columns={companyColumn} data={companiesList} loading={loading} />
        {audit?.count > 10 ? (
          <Pagination page={page} lastPage={audit?.pagination?.pageCount} paginate={paginate} total={audit?.pagination?.total} handlePageChange={handlePageChange} perPage={perPage} />
        ) : null}
      </div>
    </div>
  );
};

export default Audit;
