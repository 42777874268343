import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { waybillState, Action } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const initialState: waybillState = {
  loading: false,
  isLoading:false,
  isLoadingCan:false,
  waybill: null,
  waybillCanInvoice:null,
  successMessage: "",
  errorMessage: "",
  pod_file_url: null,
  isCreate:false,
};

const waybillSlice = createSlice({
  name: "waybill",
  initialState,
  reducers: {
    waybillLoading: (state) => {
      state.loading = true;
    },
    waybillSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.waybill = action.payload;
    },
    waybillFail: (state) => {
      state.loading = false;
    },
    waybillCanLoading: (state) => {
      state.isLoadingCan = true;
    },
    waybillCanSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoadingCan = false;
      state.waybillCanInvoice = action.payload;
    },
    waybillCanFail: (state) => {
      state.isLoadingCan = false;
    },
    uploadingImageRequest: (state) => {
      state.isLoading = true;
    },
    uploadingImageSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.pod_file_url = action.payload;
    },
    uploadingImageFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
    },
    uploadPodLoading: (state) => {
      state.isLoading = true;
    },
    uploadPodSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
    },
    uploadPodFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    createWaybillLoading: (state) => {
      state.isCreate = true;
    },
    createWaybillSuccess: (state, action: PayloadAction<Action>) => {
      state.isCreate = false;
      // state.approvalItems = action.payload;
    },
    createWaybillFail: (state, action: PayloadAction<string>) => {
      state.isCreate = false;
      state.errorMessage = action.payload;
    },
    // bidLoading: (state) => {
    //   state.isLoading = true;
    // },
    // bidSuccess: (state, action: PayloadAction<Action>) => {
    //   state.isLoading = false;
    //   state.bid = action.payload;
    // },
    // bidFail: (state, action: PayloadAction<string>) => {
    //   state.loading = false;
    //   state.errorMessage = action.payload;
    // }
    // deleteProductDataLoading: (state) => {
    //   state.deleting = true;
    // },
    // deleteProductDataSuccess: (state, action: PayloadAction<Action>) => {
    //   state.deleting = false;
    //   state.Approvals = action.payload;
    // },
    // deleteProductDataFail: (state, action: PayloadAction<string>) => {
    //   state.deleting = false;
    //   state.errorMessage = action.payload;
    // }
  }
});

const { actions, reducer } = waybillSlice;
export const { 
  waybillLoading,
   waybillSuccess,
   waybillFail,
   uploadingImageRequest,
   uploadingImageSuccess,
   uploadPodFail,
   uploadPodLoading,
   uploadPodSuccess,
   uploadingImageFail,
   waybillCanFail,
   waybillCanLoading,
   waybillCanSuccess,
   createWaybillFail,
   createWaybillLoading,
   createWaybillSuccess
   } = actions;

export const getWayBill =
  (page?:number,length?:number, search?:string,company_id?: number, vendor_id?: number, date_from?: any, date_to?: any, delivery_number?: number,waybill_status?:any,truck_number?:string,external_ref_id?:string,can_invoice?:any): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    console.log(userToken)
    dispatch(waybillLoading());
    try {
      const response = await API.get("/waybills/get", {
        params: {
          page,
          length,
          search,
          company_id,
          vendor_id,
          date_from,
          date_to,
          delivery_number,
          waybill_status,
          truck_number,
          external_ref_id,
          can_invoice
        },
        headers: {
            Authorization: `Bearer ${userToken}`
          // Authorization: `Bearer $2y$10$cVC/vaPf/yHubUQ.sOv24uHlNO3EBYgqjWSDCOMh.o3oshbDJTY86`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(waybillSuccess(response.data));
      }
    } catch (error) {
      dispatch(waybillFail());
    }
  };

export const uploadFile =
    (payload): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        dispatch(uploadingImageRequest());
        const { userToken } = getState().user;
        try {
            const response = await axios.post(`${BASE_URL}/prometheus/upload_file`, payload, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    "Content-Type": "multipart/form-data"
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(uploadingImageSuccess(response.data.s3url));
                toastr.success("", "File Saved");
            
            
            }
        } catch (error) {
            dispatch(uploadingImageFail(""));
        }
    };


    export const getWayBillCan =
    (page?:number,length?:number, search?:string,company_id?: number, vendor_id?: number, date_from?: string, date_to?: number, delivery_number?: number,waybill_status?:string,truck_number?:string,external_ref_id?:string,can_invoice?:any): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
      const { userToken } = getState().user;
      console.log(userToken)
      dispatch(waybillCanLoading());
      try {
        const response = await API.get("/waybills/get", {
          params: {
            page,
            length,
            search,
            company_id,
            vendor_id,
            date_from,
            date_to,
            delivery_number,
            waybill_status:'3',
            truck_number,
            external_ref_id,
            can_invoice
          },
          headers: {
              Authorization: `Bearer ${userToken}`
            // Authorization: `Bearer $2y$10$cVC/vaPf/yHubUQ.sOv24uHlNO3EBYgqjWSDCOMh.o3oshbDJTY86`
          }
        });
        if ([200, 201].includes(response.status)) {
          dispatch(waybillCanSuccess(response.data));
        }
      } catch (error) {
        dispatch(waybillCanFail());
      }
    };

export const uploadPod =
  (payload: object, cb: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(uploadPodLoading());
    const { userToken } = getState().user;
    try {
      const response = await API.post(`/waybills/edit`, payload, {
        headers: {
            Authorization: `Bearer ${userToken}`,
        //   Authorization: `Bearer $2y$10$cVC/vaPf/yHubUQ.sOv24uHlNO3EBYgqjWSDCOMh.o3oshbDJTY86`,
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(uploadPodSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      

      if (error.response) {
        dispatch(uploadPodFail(error?.response?.data?.error));
        toastr.error(error?.response?.data.message);
      }
      else{
        dispatch(uploadPodFail(error?.response?.data?.error));
        toastr.error("No network connecton");
      }
    }
  };



  export const generateWaybillInvoice =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(createWaybillLoading());
    try {
      const response = await API.post(`/invoices/create_waybill_invoice`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(createWaybillSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
    

      if (error.response) {
        dispatch(createWaybillFail(error.response.data?.message));
      toastr.error(error.response.data.message);
      }
      else{
        dispatch(createWaybillFail("No network connecton"));
        toastr.error("No network connecton");
      }
      setTimeout(() => {
        dispatch(createWaybillFail(""));
      }, 3000);
      // setTimeout(() => {
      //   dispatch(createWorkFlowFail(""));
      // }, 3000);
    }
  };


export default reducer;
