import Accordion from "components/Accordion";
import useAccordion from "hooks/useAccordion";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { ReactComponent as DeleteIcon } from "assests/icons/Vector (3) copy 2.svg";
import "./styles.scss";
import React, { useEffect, useState } from "react";
import {
  addInvoiceToPayment,
  deletePaymentProposal,
  fetchDebitLines,
  getAllDebitLines,
  getSinglePaymentProprosals,
  removeDebitLine,
  removeInvoiceFromPayment,
  sendPaymentProposal
} from "store/slice/BuyerSlice/PostedInvoiceSlice/PostedInvoiceSlice";
import { useHistory, useParams } from "react-router";
import { formatCurrency, toastPopUp } from "utils/utils";
import DebitLines from "components/ModalBuyer/DebitLine";

const PaymentRuns = () => {
  interface Provider {
    open: boolean;
    ids: object;
  }
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  let { id }: any = useParams();
  const { singlePaymentProposals, loading } = useSelector((state: RootState) => state.postedInvoice);

  let data = singlePaymentProposals?.data[0];

  // state
  const [data_, setData_] = useState<object[]>([]);
  const [modal, setModal] = useState<Provider>({
    open: false,
    ids: {}
  });

  useEffect(() => {
    dispatch(getSinglePaymentProprosals(id));
  }, []);

  useEffect(() => {
    setData_(dataArr);
  }, [data]);

  // type isSelected = {
  //   action: string;
  // };

  const { getAmount, checkAccordion, handleCheckbox, handlerAccordion, index, headerChecked, getSelected, isSelected } = useAccordion(data_);

  useEffect(() => {
    const { action, ...rest } = isSelected as any;
    if (action === "add") {
      dispatch(addInvoiceToPayment({ ...rest }, () => dispatch(getSinglePaymentProprosals(id))));
    }
    if (action === "remove") {
      dispatch(removeInvoiceFromPayment({ ...rest }, () => dispatch(getSinglePaymentProprosals(id))));
    }
  }, [isSelected]);

  const btnStyle = {
    green: {
      background: "rgba(0, 176, 80, 0.15)",
      color: "#00B050"
    },
    blue: {
      background: "rgba(0, 70, 176, 0.15)",
      color: "#2F80ED"
    },
    red: {
      background: "rgba(248, 90, 90, 0.15)",
      color: "#F85A5A"
    },
    yellow: {
      background: "rgba(0, 70, 176, 0.15)",
      color: "#F59625"
    }
  };

  const dataArr: object[] = data?.proposal_items?.map((item) => ({
    id: item.id,
    name: item.vendor_description,
    vendor_code: item.vendor_code,
    vendor_id: item.vendor_id,
    proposal_amt: Number(item?.amount),
    invoice: item.posted_invoices.map((item) => {
      // if (item.invoice_status === "4") {
      return {
        id: item.proposal_id,
        amount: item.gross_amount,
        date: item.posting_date,
        invoice_number: item.invoice_code,
        wht: item.invoice_document_url,
        invoice_id: item.invoice_id,
        currency: item.currency_iso,
        purchase_order_code: item.purchase_order_code,
        invoice_code: item.invoice_code,
        vendor_id: item.vendor_id,
        proposal_items_id: item.id,
        invoice_document_url: item.invoice_document_url,
        witholding_tax: item.witholding_tax
      };
      // }
    }),

    debitLines: item.debit_lines_added.map((item) => ({
      id: item.id,
      amount: item.amount,
      date: item.payment_date,
      invoice_number: item.document_number,
      wht: "",
      invoice_id: item.invoice_id,
      currency: item.currency_iso,
      invoice_code: item.invoice_code,
      vendor_id: item.vendor_id,
      witholding_tax: item.witholding_tax,
      proposal_items_id: item.id
    })),
    invoice_added: item.invoices_added.map(({ invoice_code }) => invoice_code),
    amount: item?.total_invoiced_value
    // amount: item?.invoices_added?.reduce((arr, curr) => arr + Number(curr.gross_amount), 0) - item?.debit_lines_added?.reduce((arr, curr) => arr + Number(curr.amount), 0)
  }));

  function getItemStatus(status) {
    if (status === "-3") return "Rejected";
    if (status === "-2") return "New Proposal";
    if (status === "-1") return "FC Approval";
    if (status === "1") return "Payment Approved";
    if (status === "2") return "Paid";
    if (status === "3") return "Parked";
    if (status === "4") return "Posted";
  }

  // function getBtnClass(status) {
  //   if (status === "-3") return "reject";
  //   if (status === "-2") return "pending";
  //   if (status === "-1") return "draft";
  //   if (status === "1") return "approved";
  //   if (status === "2") return "paid";
  //   if (status === "3") return "posted";
  //   if (status === "4") return "posted";
  // }

  function getBtnClass(status) {
    if (status === "-3") return "reject";
    if (status === "-2") return "draft";
    if (status === "-1") return "pending";
    if (status === "1") return "approved";
    if (status === "2") return "paid";
    if (status === "3") return "posted";
    if (status === "4") return "posted";
  }

  const approveBtnClicked = () => {
    toastPopUp(
      () => {
        dispatch(sendPaymentProposal({ proposal_id: id }, () => history.push("/buyer/treasury/payment-runs")));
      },
      "Confirm",
      "Cancel",
      "Confirm Payment Proposal?",
      "",
      "green"
    );
  };
  const deleteBtnClicked = () => {
    toastPopUp(
      () => {
        dispatch(deletePaymentProposal({ proposal_id: id }, () => history.push("/buyer/treasury/payment-runs")));
      },
      "Confirm",
      "Cancel",
      "Delete Payment Proposal?",
      "",
      "red"
    );
  };

  // const openModal = (e, { invoice }, i) => {
  //   e.stopPropagation();
  //   setModal({
  //     open: true,
  //     ids: { vendor_id: invoice[0].vendor_id, proposal_items_id: invoice[0].proposal_items_id, index: i }
  //   });
  // };

  const openModal = (e, data, i) => {
    console.log(data);
    e.stopPropagation();
    setModal({
      open: true,
      ids: { vendor_id: data?.vendor_id, proposal_items_id: data.id, index: i }
    });
  };

  const handleCheckboxDebit = (invoice_debit_id, proposal_item_id) => {
    dispatch(removeDebitLine({ proposal_item_id, invoice_debit_id }, () => dispatch(getSinglePaymentProprosals(id))));
  };

  return (
    <div id="payment-run">
      {modal.open && <DebitLines closeModal={() => setModal({ open: false, ids: {} })} ids={modal.ids} />}
      <div className="row mb-5">
        <div className="col-6  col-xs-12">
          <div className="row">
            <div className="col-4 mb-4">
              <h6>Run ID</h6>
              <p>{data?.proposal_code}</p>
            </div>
            <div className="col-4">
              <h6>Run Date</h6>
              <p>{data?.run_date}</p>
            </div>
            <div className="col-4">
              <h6>Proposal Amt.</h6>
              <p>{formatCurrency(data?.total_proposal_amount)}</p>
            </div>
            <div className="col-4">
              <h6>Status</h6>
              <button className={`status mt-2 ${getBtnClass(data?.proposal_status)}`}>{getItemStatus(data?.proposal_status)}</button>
            </div>
          </div>
        </div>
        <div className="col-6 col-xs-12">
          <div className="" style={{ float: "right" }}>
            {/* {Number(data?.proposal_status) > 0 && ( */}
            <React.Fragment>
              {data?.proposal_status === "-3" && (
                <button className="px-4 py-2 btn btn-primary delete-btn " onClick={deleteBtnClicked}>
                  <span className="d-flex align-items-center ">
                    <span className="pr-2">Delete Proposal</span>
                    <DeleteIcon />
                  </span>
                </button>
              )}
              {Number(data?.proposal_status) === -2 && (
                <button className="px-4 py-2 btn btn-primary delete-btn mr-3" onClick={approveBtnClicked} style={{ backgroundColor: "#2F80ED" }}>
                  <span className="d-flex align-items-center ">
                    <span className="pr-2">Send for Approval</span>
                  </span>
                </button>
              )}
            </React.Fragment>
            {/* )} */}
          </div>
        </div>
      </div>
      {/* <div className="rejection-note mb-5">
        <h6 className="mb-2">Rejection Reason:</h6>
        <p>
          Payment Run with ID 2200042 was rejected for going against community guidelines.Payment Run with ID 2200042 was rejected for going against community guidelines. Payment Run with ID 2200042
          was rejected for going against community guidelines.
        </p>
      </div> */}
      <Accordion
        arr={dataArr}
        isDisabled={false}
        getAmount={getAmount}
        checkAccordion={checkAccordion}
        handleCheckbox={handleCheckbox}
        handlerAccordion={handlerAccordion}
        index={index}
        headerChecked={headerChecked}
        btnClicked={openModal}
        getSelected={getSelected}
        loading={loading}
        handleCheckboxDebit={handleCheckboxDebit}
      />
    </div>
  );
};

export default PaymentRuns;
