import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getAllBusinessCategory } from "store/slice/BusinessCategorySlice/BusinessCategorySlice";
import AsyncSelect from "react-select/async";
import API from "utils/httpsRequest";
import { RootState } from "store/store";
import { addServiceMaterials } from "store/slice/RequisitionSlice/RequisitionSlice";

const Service = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state: RootState) => state.user);
  const { singleRequisition, isLoading } = useSelector((state: RootState) => state.requisition);

  const [loading, setLoading] = React.useState(false);
  // const [showCatErr, setShowCatErr] = React.useState(false);
  const [inputValue, setValue] = React.useState('');
  const [selectedValue, setSelectedValue] = React.useState(null);

  const { token } = user;

  const { register, handleSubmit, control, errors } = useForm<FormData>({
    mode: "onChange",
    defaultValues: {
      quantity_required: '1'
    }
  });

  type FormData = {
    service_description: string;
    quantity_required: string;
    material_category_id: string;
  }

  React.useEffect(() => {
    console.log(singleRequisition);
  }, [singleRequisition]);

  const handleInputChange = value => {
    setValue(value);
  };

  // handle selection
  const handleChange = value => {
    setSelectedValue(value);
  }

  const addService = (form) => {
    const data = {
      ...form,
      material_category_id: selectedValue?.['name'],
      requisition_id: singleRequisition?.['id'],
    }
    // if (data?.business_category === undefined) {
    //   setShowCatErr(true);
    // }
    console.log(data)
    dispatch(addServiceMaterials(data, () => handleSuc()))
  }

  function handleSuc() {
    console.log('added service material');
  }

  const loadOptions = () => {
    return API.get(`/material_categories/get?search=${inputValue}&material_category_type=Service`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      setLoading(false)
      return res.data.data.map((item) => (
        {
          label: item.material_category_description,
          name: item.id
        }
      ))
    })
  }

  return (
    <form style={{ marginTop: "3rem" }} onSubmit={handleSubmit(addService)}>
      <div
        className="route-transition"
        style={{
          marginBottom: "1.5rem",
          border: "1px solid #E8E8E8",
          padding: "1rem",
          borderRadius: "1rem",
          paddingBottom: "3rem",
          maxWidth: "50%"
        }}
      >
        <span style={{ marginBottom: "0.5rem", display: "block" }}>Category</span>
        <Controller
          control={control}
          defaultValue
          name="material_category_id"
          rules={{ required: true }}
          render={({
            ref
          }) => (
            <AsyncSelect
              cacheOptions
              defaultOptions
              value={selectedValue}
              placeholder={loading ? "Loading data" : "Select...."}
              loadOptions={loadOptions}
              onInputChange={handleInputChange}
              onChange={handleChange}
              inputRef={ref}
            />
          )} />
        {/* {showCatErr && (<span className="error" style={{ color: 'red', fontSize: '14px' }}>This field is required</span>)} */}
        {errors.material_category_id && <span className="error" style={{ color: 'red', fontSize: '14px' }}>This field is required</span>}

      </div>
      <div
        className="route-transition"
        style={{
          marginBottom: "1.5rem",
          border: "1px solid #E8E8E8",
          padding: "1rem",
          borderRadius: "1rem",
          maxWidth: "50%"
        }}
      >
        <span style={{ marginBottom: "0.5rem", display: "block" }}>Service Description</span>
        <textarea
          rows={4}
          className="comments"
          style={{
            resize: "none",
            border: "1px solid #E8E8E8",
            borderRadius: "1rem",
            width: "100%",
            outline: "none",
            padding: "1rem"
          }}
          name="service_description"
          ref={register({ required: true })}
        />
        {errors.service_description && <span className="error" style={{ color: 'red', fontSize: '14px' }}>This field is required</span>}
      </div>
      <div className="route-transition"></div>
      <div
        className="route-transition"
        style={{
          marginBottom: "1.5rem",
          borderRadius: "1rem",
          maxWidth: "50%"
        }}
      >
        <span style={{ marginBottom: "0.5rem", display: "block" }}>Quantity</span>
        <div
          style={{
            border: "1px solid #E8E8E8",
            padding: "1rem",
            borderRadius: "0.7rem"
          }}
        >
          <input
            type="text"
            style={{
              border: "1px solid #E8E8E8",
              padding: "5px",
              borderRadius: "0.5rem"
            }}
            name="quantity_required"
            ref={register({ required: true })}
          />{" "}
          <span>/ Activity</span>
          {errors.quantity_required && <span className="error" style={{ color: 'red', fontSize: '14px', display: 'block' }}>This field is required</span>}
        </div>
      </div>
      <button
        disabled={isLoading}
        style={{
          background: "#00B0A5",
          color: "#FFF",
          minWidth: "7rem",
          padding: "10px",
          borderRadius: "6px"
        }}
        type="submit"
      >
        {isLoading ? 'Please wait...' : 'Add'}
      </button>
    </form>
  );
};

export default Service;
