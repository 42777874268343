import React from "react";
import Modal from "components/Modal";
import Textbox from "components/Textbox";
import Button from "components/Button";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { createPoReq, extendRfp, getAllPOReq } from "store/slice/BuyerSlice/CompanyDataSlice/CompanyDataSlice";
import { getSourcings } from "store/slice/SourcingSlice/SourcingSlice";
import { format } from "date-fns";

type Props = {
  closeModal: () => void;
  item?: any;
};
const ExtendSourceTime = ({ closeModal, item }: Props) => {
  const { register, handleSubmit, errors } = useForm<FormData>({
    mode: "onChange"
  });

  //State

  type FormData = {
    closing_date: any;
  };

  //Redux Store
  const dispatch = useDispatch<AppDispatch>();

  const { extendLoad } = useSelector((state: RootState) => state.companiesData);

  // const { user } = useSelector((state: RootState) => state.user);

  const onFormSubmit = (vals) => {
    const payload = {
      closing_date: format(new Date(vals.closing_date), "yyyy-MM-dd HH:mm:ss"),
      rfp_id: item.id
    };
    dispatch(
      extendRfp(payload, () => {
        closeModal();
        dispatch(getSourcings(1, 10));
      })
    );
  };

  return (
    <Modal modalText="Extend" isUpload={false} onClose={closeModal} modallength="modal-length">
      <div id="buyer-legal-entity">
        <p className="">Extend Sourcing Duration</p>

        <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
          <Textbox
            type="date"
            name="closing_date"
            label="Date"
            placeholder=""
            error={errors.closing_date && "This field is required"}
            customRef={register({
              required: true
            })}
            boxClasses="mb-3"
          />

          <Button label="Submit" btnType="btn-primary" boxClasses="mt-5 mb-5" btnStyle="100%" loading={extendLoad} />
        </form>
      </div>
    </Modal>
  );
};

export default ExtendSourceTime;
