import React, { useEffect } from "react";
import Modal from "components/Modal";
import Textbox from "components/Textbox";
import Button from "components/Button";
import useArrowDropdown from "hooks/useArrowDropdown";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { createKpiGroup, editKpiGroup, getKpiGroup } from "store/slice/BuyerSlice/KpiSetupSlice/KpiSetupSlice";
import { AppDispatch, RootState } from "store/store";

type Props = {
  closeModal: () => void;
  singleGroup?:any;
};
const CreateKpiGroup = ({ closeModal, singleGroup=null }: Props) => {
  const [error, setError] = React.useState("");
  const { register, handleSubmit, errors, setValue } = useForm<FormData>({
    mode: "onChange"
  });
  const { kpiGroupisLoading } = useSelector((state: RootState) => state.kpisetup);

  type FormData = {
    kpi_group_description: string;
    project_type: string;
  };

  //Redux Store
  const dispatch = useDispatch();

  const { open, selectedOption, handleDropdownChange, handleAction, keyOption, ref } = useArrowDropdown();

  useEffect(() => {
    if (singleGroup !== null) {
      setValue("kpi_group_description", singleGroup?.kpi_group_description);
      setValue("project_type", singleGroup?.project_type);      

    }
  }, [singleGroup]);

  const onFormSubmit = (vals: FormData) => {
  
    if (singleGroup) {
      const editPayload = {
        ...vals,
        id: singleGroup?.id,
        kpi_group_description: singleGroup?.kpi_group_description,
        project_type:singleGroup?.project_type
      };

      dispatch(
        editKpiGroup(editPayload, () => {
          closeModal();
          dispatch(getKpiGroup({page:1, length:10}));
          
        })
      );
      return;
    } 
    const payload = { ...vals };
   
    dispatch(
      createKpiGroup(payload, () => {
        closeModal();
        dispatch(getKpiGroup({page:1, length:10}));
      })
    );
  };
  return (
    <Modal modalText={singleGroup ? 'Edit KPI Group':'Create Kpi Group'} onClose={closeModal}>
      <div id="buyer-create-location">
        <p className="">{singleGroup ? 'Edit KPI Group':'Create Kpi Group'}</p>

        <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
          <Textbox
            type="text"
            name="kpi_group_description"
            label="Group Description"
            placeholder="Group Description"
            error={errors.kpi_group_description && "This field is required"}
            customRef={register({
              required: true
            })}
          />

          <Textbox
            type="text"
            boxClasses="mt-3"
            name="project_type"
            label="Project Type"
            placeholder="Project Type"
            error={errors.project_type && "This field is required"}
            customRef={register({
              required: true
            })}
          />

          <Button label="Save Details" btnType="btn-primary" boxClasses="mt-5 mb-5" btnStyle="100%" loading={kpiGroupisLoading} />
        </form>
      </div>
    </Modal>
  );
};

export default CreateKpiGroup;
