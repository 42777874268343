/* eslint-disable react-hooks/exhaustive-deps */
import StatsCard from "components/StatsCard";
import React, { useEffect, useState } from "react";
import "../styles.scss";
import LocationIcon from "assests/icons/buyer/group-box.svg";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import SearchIcon from "components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { elementColumn, productCateoryColumn } from "../data";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import useSearch from "hooks/useSearch";
import Pagination from "components/Pagination";
import { toastPopUp } from "utils/utils";
import { deleteElement, getElements } from "store/slice/BuyerSlice/CostElement/CostElementSlice";
import CreateOrEditElement from "components/ModalBuyer/CreateOrEditElement";

const CostElement = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [modal, setModal] = useState<boolean>(false);
  const [singleElement, setSingleElement] = useState<any>(null);

  const closeModal = () => {
    setModal((prev) => !prev);
  };

  //Redux
  const dispatch = useDispatch<AppDispatch>();
  const { elements, loading } = useSelector((state: RootState) => state.elements);

  //Hook
  const { search, inputRef, handleChange, searched } = useSearch(getElements, perPage);

  // get material categories
  useEffect(() => {
    dispatch(getElements(page, perPage));
  }, []);

  useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  //Show Number for PerPage Request
  const handleNewPage = (pageNo: number, page: number = 1) => {
    setPerPage(pageNo);
    setPage(1);
    dispatch(getElements(page, pageNo, search));
  };

  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, perPage: number) => {
      setPage(page);
      dispatch(getElements(page, perPage, search));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, perPage: number) => {
      setPage(pageNo);
      dispatch(getElements(pageNo, perPage, search));
    },
    [search]
  );

  //Edit DepartmentData
  const editElement = (id: string, cost_element_description: string, company_description: string, cost_element_number: string, company_id: string) => {
    setSingleElement({ id, cost_element_description, company_description, cost_element_number, company_id });
    setModal((prev) => !prev);
  };

  //  delete
  const deleteElement2 = (name: string, id: string) => {
    const payload = { cost_element_id: id };

    toastPopUp(
      () => {
        dispatch(
          deleteElement(payload, () => {
            dispatch(getElements(page, perPage));
          })
        );
      },
      "Delete",
      "Cancel",
      "Delete the Cost Element",
      name,
      "red"
    );
  };

  const elementsList =
    elements &&
    elements?.data?.map((item) => {
      return {
        id: item.id,
        cost_element_description: item.cost_element_description,
        company: item.company_description,
        cost_element_number: item.cost_element_number,
        edit: () => editElement(item.id, item.cost_element_description, item.company_description, item.company_id, item.cost_element_number),
        deleteElementData: () => deleteElement2(item.cost_element_description, item.id)
      };
    });

  return (
    <div id="settings-material-data">
      {modal && <CreateOrEditElement closeModal={closeModal} singleElement={singleElement} />}
      <div className="container mt-3">
        <div className="row mt-3 mb-5">
          <StatsCard statsNumber={elements?.count ?? 0} text="Cost Element" classes="stats-icon" image={LocationIcon} iconBackgroundColor="rgba(45, 156, 219, 0.1)" />
          <StatsCard image={AddIcon} classes="middle-img" centerText="Create" onClick={closeModal} />
        </div>

        <div className="d-flex  mb-3">
          <SearchIcon placeholder="Search" boxClasses="" textRef={inputRef} value={search} onChange={handleChange} />
          <Dropdown perPage={perPage} action={handleNewPage} />
        </div>
        <DataTable columns={elementColumn} data={elementsList} loading={loading} />

        <Pagination page={page} lastPage={elements?.pagination?.pageCount} paginate={paginate} total={elements?.pagination?.total} handlePageChange={handlePageChange} perPage={perPage} />
      </div>
    </div>
  );
};

export default CostElement;
