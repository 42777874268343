import React, { useEffect, useState } from "react";
import Modal from "components/Modal";
import Textbox from "components/Textbox";
import Button from "components/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import "./styles.scss";
import { useForm } from "react-hook-form";
import { doverifyVendorFile, getSingleVendor } from "store/slice/BuyerSlice/VendorSlice/VendorSlice";

type Props = {
  closeModal: () => void;
  reject: (vals) => void;
};
const RejectOnboarding = ({ closeModal, reject }: Props) => {
  //Redux Store
  const { handleSubmit, register } = useForm<FormData>({
    mode: "onBlur"
  });
  const { loading } = useSelector((state: RootState) => state.approvals);

  const onFormSubmit = (vals) => {
    reject(vals.note);
  };
  return (
    <Modal modalText="Rejection Comment" onClose={closeModal} boxClasses="role-modal-top">
      <div className="reject-modal">
        <p className="">Send Your Reason for rejecting the Vendor Application</p>
        <form className="mt-4" onSubmit={handleSubmit(onFormSubmit)}>
          <textarea rows={4} className="text-area mt-4" placeholder="Enter description" name="note" ref={register({ required: true })} />
          <Button label="Submit" btnType="btn-primary" boxClasses="mt-4 mb-5" btnStyle="100%" loading={loading} />
        </form>
        {/* <p className="role-p" onClick={closeModal}>
          Cancel
        </p> */}
      </div>
    </Modal>
  );
};

export default RejectOnboarding;
