import React, { useEffect, useState } from "react";
import Modal from "components/Modal";
import Textbox from "components/Textbox";
import Button from "components/Button";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import FileUploaderBox from "components/FileUploaderBox";
import SelectDropdown from "components/SelectDropdown";
import API from "utils/httpsRequest";
import { getCompaniesEntityList } from "store/slice/CompaniesSlice/CompaniesSlice";
import { createCompanyData, editCompanyData, getAllCompaniesData } from "store/slice/BuyerSlice/CompanyDataSlice/CompanyDataSlice";
import { getAllCountries } from "store/slice/CountriesSlice/CountriesSlice";
import { getAllcurrencies } from "store/slice/CurrencySlice/CurrencySlice";
import AsyncSelect from "react-select/async";

type Props = {
  closeModal: () => void;
  singleReport?: any;
};
const CreateOrEditReport = ({ closeModal, singleReport = null }: Props) => {
  const { register, handleSubmit, errors,control, setValue } = useForm<FormData>({
    mode: "onChange"
  });

  //State
  const [files, setFiles] = useState<any>([]);
  const [uploadBool, setUploadBool] = useState(false);
  const [countrySel, setCountrySel] = React.useState("");
  const [selCountryVal, setSelCountryVal] = React.useState("");
  const [currencySel, setCurrencySel] = React.useState("");
  const [selCurrencyVal, setSelCurrencyVal] = React.useState("");

  const checkUpload = () => {
    setUploadBool(!uploadBool);
  };

  type FormData = {
    report_name: string;
    process: string;
    reportType: string;
    creator_name: string;
  };

  //Redux Store
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector((state: RootState) => state.user)
  const { isLoading } = useSelector((state: RootState) => state.report);
  const { token } = user;



  useEffect(() => {
    if (singleReport !== null) {
      setValue("report_name", singleReport?.code);
      setValue("reportType",singleReport?.n);
      setValue("creator_name",singleReport?.n);
      setValue("process", singleReport?.desc);

    }
  }, [singleReport]);

  const onFormSubmit = (vals) => {
    if (singleReport) {
     
      const editPayload = {
        id: singleReport?.id,
        report_name: vals.company_description,
        reportType: vals.company_code,
        creator_name:  selCountryVal?.['value'],
        process:"jj"
      };

      dispatch(
        editCompanyData(editPayload, () => {
          closeModal();
          dispatch(getAllCompaniesData());
        })
      );
      return;
    }



    dispatch(
      createCompanyData(vals, () => {
        closeModal();
        dispatch(getAllCompaniesData());
      })
    );
  };



  return (
    <Modal modalText={singleReport === null ? "Create Report" : "Edit Report"} onClose={closeModal} modallength="modal-length">
    
        <div id="buyer-legal-entity">
          <p className="">
            {singleReport === null ? "  Complete this form with accurate data to create a new report on GZIBP Platform." : "  Complete this form with accurate data to update this report on GZIBP Platform."}
          </p>

          <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
            <Textbox
              type="text"
              name="report_name"
              label="Report Name"
              placeholder="Report Name"
              error={errors.report_name && "This field is required"}
              customRef={register({
                required: true
              })}
              boxClasses="mb-3 mt-3"
            />
           
            <SelectDropdown
              boxClasses="mt-3"
              name="process"
              label="Process Involved"
              placeholder="Process Involved"
              // options={}
              customRef={register({ required: true })}
              error={errors.process && "This field is required"}
            />

            <SelectDropdown
              boxClasses="mt-3"
              name="report_type"
              label="Report Type"
              placeholder="Report Type"
              // options={}
              customRef={register({ required: true })}
              error={errors.process && "This field is required"}
            />

            <Textbox
              type="text"
              name="creator_name"
              label="Creator Name"
              placeholder="Creator Name"
              boxClasses="mb-3 mt-3"
              error={errors.creator_name && "This field is required"}
              customRef={register({
                required: true
              })}
            />

           {/* <Controller
            control={control}
            defaultValue
            boxClasses="mt-3"
            name="country_id"
            render={({ ref }) => (
              <AsyncSelect
                cacheOptions
                defaultOptions
                placeholder="Country"
                value={selCountryVal}
                loadOptions={countriesOptions}
                onInputChange={handleInputChangeCountry}
                onChange={onChangeCountry}
              />
            )} /> */}
{/* 
<Controller
            control={control}
            defaultValue
            boxClasses="mt-3"
            name="default_currency_id"
            render={({ ref }) => (
              <AsyncSelect
                cacheOptions
                defaultOptions
                placeholder="Currency"
                value={selCurrencyVal}
                loadOptions={currencyOptions2}
                onInputChange={handleInputChangeCurrency}
                onChange={onChangeCurrency}
              />
            )} /> */}

     
            

            <Button label={singleReport === null ? "Create Report" : "Edit Report"} btnType="btn-primary" boxClasses="mt-5 mb-5" btnStyle="100%" loading={isLoading} />
          </form>
        </div>
     
    </Modal>
  );
};

export default CreateOrEditReport;
