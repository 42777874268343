import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import notSaved from "assests/icons/buyer/notSavedAcct.svg";
import approvedAcct from "assests/icons/buyer/approvedAcct.svg";
import rejectedAcct from "assests/icons/buyer/rejectedAcct.svg";
import document from "../../../../assests/icons/supplier/document.svg";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { confirmRequisition, delMaterials, getRequisition } from "store/slice/RequisitionSlice/RequisitionSlice";
import { RootState } from "store/store";
import AddItemModal from "components/ModalBuyer/AddItem/AddItem";
import deleteIcon from "assests/icons/buyer/delete-icon.svg";
import { refreshReqAction } from "store/slice/BuyerSlice/RefreshSlice/refreshSlice";

const SingleRequisition = ({ match }: any) => {
  const dispatch = useDispatch();

  const [itemAction, setItemAction] = useState(null);
  const [showModal, setShowModal] = useState(false);

  //   get selected requisition id from route params
  // const { id } = match.params;
  let { id }: any = useParams();

  const { singleRequisition, isLoading, isCreating } = useSelector((state: RootState) => state.requisition);
  const { loading } = useSelector((state: RootState) => state.refresh);
  const refreshFunction = () => {
    const payload = {
      requisition_code: singleRequisition?.["requisition_code"]
    };
    dispatch(
      refreshReqAction(payload, () => {
        dispatch(getRequisition(id));
        // history.go(-1);
      })
    );
  };
  console.log(singleRequisition, "singleRequisition");
  //   fetch single requisition using id
  useEffect(() => {
    if (id) dispatch(getRequisition(id));
  }, [id]);

  // useEffect(() => {
  //   if (!showModal) dispatch(getRequisition(id));
  // }, [showModal]);

  // useEffect(() => {
  //   console.log(singleRequisition);
  // }, [singleRequisition]);

  const removeMat = (matId) => {
    const data = {
      material_id: matId,
      requisition_id: singleRequisition?.["id"]
    };
    dispatch(delMaterials(data, (res) => handleSuc(res)));
  };

  const handleSuc = (res) => {
    // console.log(res);
    dispatch(getRequisition(id));
  };

  const confirmReq = () => {
    // console.log(singleRequisition?.['id'])
    const data = {
      requisition_id: singleRequisition?.["id"]
    };
    dispatch(confirmRequisition(data, () => dispatch(getRequisition(id))));
  };

  // const handleRet = () => dispatch(getRequisition(id));

  return (
    <div id="single-requisition">
      {singleRequisition?.["id"] && (
        <div className="container">
          {isLoading ? (
            <div className="justify-content-center w-100 d-flex pb-5 pt-5">
              <div className="spinner-border spinner-border-primary spinner-border-lg justify-content-center mr-2"></div>
            </div>
          ) : (
            <>
              <div className="d-flex justify-content-between align-items-center" style={{ width: "90%" }}>
                <h6 className="header">
                  PR-{singleRequisition?.["requisition_code"]} : {singleRequisition?.["requisition_description"]}
                </h6>
                <div className="d-flex">
                  {singleRequisition?.["requisition_status"] === "-2" && (
                    <div className="d-flex justify-content-center align-items-center " style={{ columnGap: "2rem" }}>
                      <button
                        type="button"
                        disabled={isCreating || singleRequisition?.["materials"]?.length < 1}
                        style={{
                          padding: "8px 30px",
                          background: "#00B0A5",
                          borderRadius: "10px",
                          color: "white"
                        }}
                        onClick={confirmReq}
                      >
                        {isCreating ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Publish"}
                      </button>
                    </div>
                  )}
                  <div className="d-flex justify-content-center align-items-center " style={{ columnGap: "2rem", marginLeft: "10px" }}>
                    <button
                      type="button"
                      // disabled={isCreating || singleRequisition?.["materials"]?.length < 1}
                      style={{
                        padding: "8px 30px",
                        background: " #F85A5A",
                        borderRadius: "10px",
                        color: "white"
                      }}
                      onClick={refreshFunction}
                    >
                      {loading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Refresh"}
                    </button>
                  </div>
                </div>
              </div>
              <hr />
              <div className="details-container">
                <div className="first-col">
                  <div className="unit-info">
                    <h4>Created On: </h4>
                    <p>{singleRequisition?.["created_at"]}</p>
                  </div>
                  <div className="unit-info">
                    <h4>Legal Entity: </h4>
                    <p>{singleRequisition?.["company_description"] || "n/a"}</p>
                  </div>
                  <div className="unit-info">
                    <h4>Department: </h4>
                    <p>{singleRequisition?.["department_description"]}</p>
                  </div>
                  <div className="unit-info">
                    <h4>Purchasing Group: </h4>
                    <p>{singleRequisition?.["purchasing_group_code"]}</p>
                  </div>
                  {singleRequisition?.["requisition_status"] === "-2" && (
                    <div className="upload mt-4">
                      <button type="button" id="upload" onClick={() => setShowModal(true)}>
                        Add Item
                      </button>
                    </div>
                  )}
                </div>
                <div className="second-col">
                  <div className="unit-info">
                    <h4>Expected Delivery Date: </h4>
                    <p>{singleRequisition?.["delivery_date"] || "n/a"}</p>
                  </div>
                  <div className="unit-info">
                    <h4>Delivery Location: </h4>
                    <p>{singleRequisition?.["location_description"] || "n/a"}</p>
                  </div>

                  <div className="unit-info">
                    <h4>Requester: </h4>
                    <p>{`${singleRequisition?.["requester"] && singleRequisition?.["requester"].lastname}  ${singleRequisition?.["requester"] && singleRequisition?.["requester"].othernames}`}</p>
                  </div>

                  <div className="upload mt-4 unit-info">
                    <h4>Status: </h4>
                    {singleRequisition?.["requisition_status"] === "-2" && (
                      <button
                        style={{
                          padding: "8px 16px",
                          background: "rgba(245, 150, 37, 0.15)",
                          borderRadius: "10px",
                          color: "rgb(245, 150, 37)"
                        }}
                      >
                        Draft
                      </button>
                    )}
                    {singleRequisition?.["requisition_status"] === "2" && (
                      <button
                        style={{
                          padding: "8px 16px",
                          background: "rgba(245, 150, 37, 0.15)",
                          borderRadius: "10px",
                          color: "rgb(245, 150, 37)"
                        }}
                      >
                        Generated
                      </button>
                    )}
                    {singleRequisition?.["requisition_status"] === "-1" && (
                      <button
                        style={{
                          padding: "8px 16px",
                          background: "rgba(239, 196, 66, 0.15)",
                          borderRadius: "10px",
                          color: "rgb(47, 128, 237)"
                        }}
                      >
                        Pending
                      </button>
                    )}
                    {singleRequisition?.["requisition_status"] === "1" && (
                      <button
                        style={{
                          padding: "8px 16px",
                          background: "rgba(0, 176, 7, 0.15)",
                          borderRadius: "10px",
                          color: "rgb(56, 124, 87)"
                        }}
                      >
                        Approved
                      </button>
                    )}
                    {singleRequisition?.["requisition_status"] === "3" && (
                      <button
                        style={{
                          background: "rgba(0, 176, 7, 0.15)",
                          borderRadius: "10px",
                          color: "rgb(56, 124, 87)",
                          padding: "8px 16px"
                        }}
                      >
                        Completed
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="item-container">
                <hr />
                <div className="item">
                  <div className="purchase-table mt-4">
                    <table>
                      <tbody>
                        <tr className="table-header">
                          <th>Item</th>
                          <th>Mat. No</th>
                          <th>Product Description</th>
                          <th>Category</th>
                          <th>Quantity</th>
                          <th>UoM</th>
                          <th>Amount</th>
                          {singleRequisition?.["requisition_status"] === "-2" && <th></th>}
                        </tr>

                        {singleRequisition?.["materials"].map((item, i) => (
                          <tr className="table-data-row" key={i}>
                            <td>{i + 1}</td>
                            <td>{item.material_code || "n/a"}</td>
                            <td>{item.material_description}</td>
                            <td>{item.material_category_description}</td>
                            <td>{item.quantity_required} </td>
                            <td>{item.uom === null ? "AU" : item.uom}</td>
                            <td>{item.estimated_unit_price} NGN</td>
                            {singleRequisition?.["requisition_status"] === "-2" && (
                              <td>
                                <img src={deleteIcon} alt="delete material" onClick={() => removeMat(item.material_id)} style={{ cursor: "pointer" }} />
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                {singleRequisition?.["workflow"]?.length > 0 && (
                  <>
                    <div className="item">
                      <div className="empty-item">
                        <h4>Workflow</h4>
                        <hr />
                      </div>
                    </div>

                    <div className="item">
                      <div className="history">
                        <div>
                          <ul className="status-flow" style={{ marginLeft: "2rem" }}>
                            <li className="current-status">
                              <p className="green-circle" id="smaller-circle" style={{ left: "-2rem" }}>
                                <span className="rigid-circle"></span>
                                <span className="pipe"></span>
                              </p>
                            </li>

                            {singleRequisition?.["workflow"]
                              ?.map((item, index) => (
                                <li className="current-status" key={index}>
                                  <p
                                    className="green-circle"
                                    id="smaller-circle"
                                    style={{
                                      borderColor: itemAction === null ? "#000000" : itemAction === true ? "#30A84B" : "#A83030"
                                    }}
                                  >
                                    <img style={{ height: "20px" }} src={itemAction === null ? notSaved : itemAction === true ? approvedAcct : rejectedAcct} alt="green-verified" />
                                    <span className="upward upward-rev" style={{ color: itemAction === true ? "#30A84B" : "#000000" }}></span>
                                    <span
                                      className="upward"
                                      style={{
                                        backgroundColor: itemAction === null ? "#22a238" : itemAction === true ? "#30A84B" : "#000000"
                                      }}
                                    ></span>
                                  </p>
                                  <p
                                    style={{
                                      color: itemAction === null ? "#000000" : itemAction === true ? "#30A84B" : "#A83030"
                                    }}
                                  >
                                    {item?.step_details?.step_description}
                                    <br />({item?.action_owner_lastname} {item?.action_owner_othernames})
                                  </p>
                                </li>
                              ))
                              .reverse()}

                            <li className="current-status">
                              <p className="gray-circle">
                                <img src={document} alt="document" />
                              </p>{" "}
                              <p style={{ marginLeft: "2rem" }}>
                                {singleRequisition?.["requisition_status"] === "-2"
                                  ? "Draft"
                                  : singleRequisition?.["requisition_status"] === "2"
                                  ? "Generated"
                                  : singleRequisition?.["requisition_status"] === "-1"
                                  ? "Pending"
                                  : singleRequisition?.["requisition_status"] === "1"
                                  ? "Approved"
                                  : singleRequisition?.["requisition_status"] === "-3"
                                  ? "Completed"
                                  : null}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/* <h4>History</h4>
                <hr /> */}
              </div>
            </>
          )}
        </div>
      )}
      {showModal && (
        <AddItemModal
          show={showModal}
          title={`Add items to requisition: PR-${singleRequisition?.["id"]}`}
          handleClose={() => {
            setShowModal(false);
            dispatch(getRequisition(id));
          }}
        />
      )}
    </div>
  );
};

export default SingleRequisition;
