import React from "react";
import Modal from "components/Modal";
import Button from "components/Button";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Textbox from "components/Textbox";
import { RootState } from "store/store";
import AsyncSelect from "react-select/async";
import API from "utils/httpsRequest";
import { inviteVendors, inviteVendorsByCat } from "store/slice/SourcingSlice/SourcingSlice";

type Props = {
  closeModal: () => void;
};

type FormData = {
  email: string;
};

const InviteVendors = ({ closeModal }: Props) => {
  const dispatch = useDispatch();

  const [cat, setCat] = React.useState("open_bid");
  const [catVal, setCatVal] = React.useState("");
  const [selCatVal, setSelCatVal] = React.useState("");
  const [vendorVal, setVendorValue] = React.useState("");
  const [selVendorVal, setSelVendorVal] = React.useState();

  const { isLoading, rfp } = useSelector((state: RootState) => state.sourcing);
  const { user } = useSelector((state: RootState) => state.user);
  const { token } = user;

  const { register, handleSubmit, errors, control } = useForm({
    mode: "onChange"
  });

  const submitForm = (vals) => {
    let data;
    if (cat === "vendor_category") {
      data = {
        business_category_id: selCatVal?.["value"],
        rfp_id: rfp.id
      };
      dispatch(inviteVendorsByCat(data, () => handleSuc()));
    }
    if (cat === "market_research") {
      data = {
        vendor_id: selVendorVal?.["value"],
        rfp_id: rfp.id
      };
      dispatch(inviteVendors(data, () => handleSuc()));
    }
    if (cat === "open_bids") {
      console.log("not available");
    }
  };

  const handleSuc = () => {
    closeModal();
  };

  const handleInputChangeCat = (value) => {
    setCatVal(value);
  };

  const onChangeCat = (value) => {
    setSelCatVal(value);
  };

  const handleInputChangeVendor = (val) => setVendorValue(val);

  const onChangeVendor = (val) => setSelVendorVal(val);

  const loadCat = () => {
    return API.get(`/business_categories/get?search=${catVal}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: item.business_category_description,
        value: item.id
      }));
    });
  };

  const loadVendor = () => {
    return API.get(`/vendors/get?search=${vendorVal}&length=10000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: `${item.vendor_description} - ${item.vendor_code}`,
        value: item.id
      }));
    });
  };

  return (
    <Modal modalText={"Invite Vendors"} onClose={closeModal} modallength={!false ? "modal-length" : ""}>
      <div id="invite-vendors-modal">
        <p className="" style={{ paddingBottom: "1.5rem" }}>
          Send out the Published RFx Documents to Invite Vendors
        </p>
        <form className="mt-2" onSubmit={handleSubmit(submitForm)}>
          <div className="d-flex radio-box mt-4 justify-content-between">
            <div className="">
              <input defaultChecked type="radio" value="open_bid" name="category" id="open_bid" className="mr-2" onChange={(e) => setCat(e.target.value)} />
              <label className="radio-box_label" htmlFor="open_bid" style={{ cursor: "pointer", display: "inline", fontSize: "14px" }}>
                Automatic
              </label>
            </div>
            <div className="">
              <input type="radio" value="vendor_category" name="category" id="vendor_category" className="mr-2" onChange={(e) => setCat(e.target.value)} />
              <label className="radio-box_label" htmlFor="vendor_category" style={{ cursor: "pointer", display: "inline", fontSize: "14px" }}>
                Vendor Category
              </label>
            </div>
            <div className="">
              <input type="radio" value="market_research" name="category" id="market_research" className="mr-2" onChange={(e) => setCat(e.target.value)} />
              <label className="radio-box_label" htmlFor="market_research" style={{ cursor: "pointer", display: "inline", fontSize: "14px" }}>
                Market Research
              </label>
            </div>
          </div>

          <div style={{ marginTop: "2rem" }}>
            {cat === "market_research" && (
              <Controller
                control={control}
                defaultValue
                name="vendor_id"
                render={({ ref }) => (
                  <AsyncSelect
                    cacheOption
                    defaultOptions
                    placeholder="Search Vendors"
                    value={selVendorVal}
                    loadOptions={loadVendor}
                    onInputChange={handleInputChangeVendor}
                    onChange={onChangeVendor}
                  />
                )}
              />
            )}

            {cat === "vendor_category" && (
              <Controller
                control={control}
                defaultValue
                name="business_category_id"
                render={({ ref }) => (
                  <AsyncSelect cacheOption defaultOptions placeholder="Select Business Category" value={selCatVal} loadOptions={loadCat} onInputChange={handleInputChangeCat} onChange={onChangeCat} />
                )}
              />
            )}
          </div>

          <div style={{ marginTop: "3rem" }}>
            <button
              style={{
                padding: "8px 30px",
                background: "#00B0A5",
                borderRadius: "10px",
                color: "white",
                margin: "0 auto"
              }}
            >
              {isLoading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Invite"}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default InviteVendors;
