import DataTable from "components/DataTable";
import Pagination from "components/Pagination";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { confirmBudget, deleteBudget, getSingleBudgetHeader } from "store/slice/BuyerSlice/Budget/BudgetSlice";
import { RootState } from "store/store";
import { formatCurrency } from "utils/utils";
import CreateLineItems from "./CreateLineItems";
import { budgetLineCol } from "./data";
import "./styles.scss";

const SingleBudget = ({ match }: any) => {
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [modalText, setModalText] = useState("");

  const [page, setPage] = useState(1);
  const [perpage, setPerpage] = useState(10);

  const { singleBudgetHeader, isLoading } = useSelector((state: RootState) => state.budget);

  // const { id } = match.params;
  let { id }: any = useParams();

  const editLineItem = (item) => {
    console.log(item);
  };

  const handleDeleteLineItem = (_id) => {
    const data = {
      budget_id: _id
    };
    dispatch(deleteBudget(data, () => dispatch(getSingleBudgetHeader(id))));
  };

  const budgetLineItems =
    singleBudgetHeader &&
    singleBudgetHeader?.budget_line_items?.map((item) => {
      return {
        ...item,
        name: item.budget_description,
        department: item.department_description,
        material_category: item.material_category_description,
        currency: item.currency_description,
        amount: formatCurrency(item.budget_amount),
        ledger_balance: formatCurrency(item.budget_ledger_balance),
        view: () => console.log(item),
        edit: () => editLineItem(item),
        deleteId: () => handleDeleteLineItem(item.id)
      };
    });

  const handlePublish = () => {
    const data = {
      budget_header_id: id
    };
    console.log(data);
    dispatch(confirmBudget(data, () => dispatch(getSingleBudgetHeader(id))));
  };

  useEffect(() => {
    if (id) dispatch(getSingleBudgetHeader(id));
  }, [id]);

  // useEffect(() => {
  //     console.log(singleBudgetHeader);
  // }, [singleBudgetHeader])

  return (
    <div id="single-budget">
      {modal && (
        <CreateLineItems closeModal={() => setModal(!modal)} modalData={modalData} modalText={modalText} id={id} companyId={singleBudgetHeader?.company_id} budgetId={singleBudgetHeader?.id} />
      )}
      {singleBudgetHeader?.id && (
        <div className="container">
          {isLoading ? (
            <div className="justify-content-center w-100 d-flex pb-5 pt-5">
              <div className="spinner-border spinner-border-primary spinner-border-lg justify-content-center mr-2"></div>
            </div>
          ) : (
            <>
              <div className="d-flex justify-content-between align-items-center">
                <h6
                  className="header"
                  style={{
                    textTransform: "capitalize"
                  }}
                >
                  {singleBudgetHeader?.budget_name}
                </h6>
                {singleBudgetHeader?.budget_header_status === "-2" && (
                  <div className="d-flex justify-content-center align-items-center mb-3" style={{ columnGap: "2rem" }}>
                    <button
                      type="button"
                      disabled={isLoading}
                      style={{
                        padding: "8px 30px",
                        background: "#00B0A5",
                        borderRadius: "10px",
                        color: "white"
                      }}
                      onClick={handlePublish}
                    >
                      {isLoading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Publish"}
                    </button>
                  </div>
                )}
              </div>
              <hr />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: "5"
                }}
              >
                <div
                  style={{
                    marginRight: "3rem"
                  }}
                >
                  <label>Start Date</label>
                  <p
                    style={{
                      color: "#505566"
                    }}
                  >
                    {singleBudgetHeader?.start_date}
                  </p>
                </div>
                <div
                  style={{
                    marginRight: "3rem"
                  }}
                >
                  <label>End Date</label>
                  <p
                    style={{
                      color: "#505566"
                    }}
                  >
                    {singleBudgetHeader?.end_date}
                  </p>
                </div>
                <div
                  style={{
                    marginRight: "3rem"
                  }}
                >
                  <label>Deducted Amount</label>
                  <p
                    style={{
                      color: "#505566"
                    }}
                  >
                    {singleBudgetHeader?.budget_amount || "N/A"}
                  </p>
                </div>
                <div
                  style={{
                    marginRight: "3rem"
                  }}
                >
                  <label>Budget Holder</label>
                  <p
                    style={{
                      color: "#505566"
                    }}
                  >
                    {singleBudgetHeader?.budget_holder_lastname} {singleBudgetHeader?.budget_holder_othernames}
                  </p>
                </div>
                <div
                  style={{
                    marginRight: "3rem"
                  }}
                >
                  <label>Budget Holder</label>
                  <p
                    style={{
                      color: "#505566"
                    }}
                  >
                    {singleBudgetHeader?.budget_holder_lastname} {singleBudgetHeader?.budget_holder_othernames}
                  </p>
                </div>
                <div
                  style={{
                    marginRight: "3rem"
                  }}
                >
                  <label>Total Amount</label>
                  <p
                    style={{
                      color: "#505566"
                    }}
                  >
                    {formatCurrency(
                      singleBudgetHeader?.budget_line_items?.reduce(function (a, b) {
                        return Number(a) + Number(b.budget_amount);
                      }, 0)
                    )}
                  </p>
                </div>
              </div>
              <div className="upload mt-4">
                <button
                  type="button"
                  id="upload"
                  style={{
                    background: "#2F80ED",
                    color: "#FFFFFF"
                  }}
                  onClick={() => {
                    setModal(!modal);
                    setModalText("Create");
                  }}
                >
                  + New Budget Line
                </button>
              </div>
              <div className="mt-3">
                <DataTable columns={budgetLineCol} data={budgetLineItems} loading={isLoading} />
                {/* <Pagination
                                    page={page}
                                    lastPage={budgetHeaders?.pagination?.lastPage}
                                    paginate={paginate}
                                    total={budgetHeaders?.pagination?.total}
                                    handlePageChange={handlePageChange}
                                    perPage={perpage}
                                /> */}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default SingleBudget;
