import DropdownMenu from "components/DropdownMenu";
import "./styles.scss";
import AddIcon from "assests/icons/buyer/add-icon-red.svg";

export const SourcingData = [
    {
        id: "1000",
        name: "IT Software RFQ Team ",
        members: "3",
        projects: "2",
        team: "steve manga"
    },
    {
        id: "2000",
        name: "Admin Services",
        members: "4",
        projects: "1",
        team: "Andrew Blake"
    }
];

export const SourcingColumn = [
    {
        name: "Team ID",
        index: "id",
        id: 1
    },
    {
        name: "Team Name",
        index: "name",
        id: 2
    },
    {
        name: "Team Members",
        index: "team_members",
        id: 3
    },
    {
        name: "Add Team Member",
        id: 4,
        render: (item) => {
            return (
                <img onClick={item.add} src={AddIcon} alt="" />
            );
          }
    }
    ,
    {
        name: "Actions",
        id: 5,
        render: ({ deleteTeam, edit }) => {
            const settings = [
                {
                    name: "Edit",
                    style: "activate_tag",
                    select: edit
                },
                {
                    name: "Delete",
                    style: "delete_tag",
                    select: deleteTeam
                }
            ];
            return (
                <span style={{ position: "relative" }}>
                    <DropdownMenu options={settings} boxClasses="dropdown-style">
                        <p style={{ fontSize: "2rem" }} className="cursor-pointer">
                            ...
                        </p>
                    </DropdownMenu>
                </span>
            );
        }
    }
];

export const KpiColumn = [
    {
        name: "Group Description",
        index: "kpi_group_description",
        id: 1
    },
    {
        name: "Project Type",
        index: "project_type",
        id: 2
    },
    {
        name: "Actions",
        id: 5,
        render: ({ deleteWork, edit,viewSteps }) => {
            const settings = [

                {
                    name: "View Kpis",
                    style: "activate_tag",
                    select: viewSteps
                },
                {
                    name: "Edit",
                    style: "activate_tag",
                    select: edit
                },
                {
                    name: "Delete",
                    style: "delete_tag",
                    select: deleteWork
                }
            ];
            return (
                <span style={{ position: "relative" }}>
                    <DropdownMenu options={settings} boxClasses="dropdown-style">
                        <p style={{ fontSize: "2rem" }} className="cursor-pointer">
                            ...
                        </p>
                    </DropdownMenu>
                </span>
            );
        }
    }
];

export const KpiSetupColumn = [
    {
        name: "Field Name",
        index: "field_name",
        id: 1
    },
    {
        name: "Field Order",
        index: "field_order",
        id: 2
    },
    {
        name: "Field Type",
        index: "field_type",
        id: 3
    },
    {
        name: "Evaluation Type",
        index: "evaluation_type",
        id: 4
    },
    {
        name: "Actions",
        id: 5,
        render: ({ deleteWork, edit,viewSteps }) => {
            const settings = [

                // {
                //     name: "View Steps",
                //     style: "activate_tag",
                //     select: viewSteps
                // },
                {
                    name: "Edit",
                    style: "activate_tag",
                    select: edit
                },
                {
                    name: "Delete",
                    style: "delete_tag",
                    select: deleteWork
                }
            ];
            return (
                <span style={{ position: "relative" }}>
                    <DropdownMenu options={settings} boxClasses="dropdown-style">
                        <p style={{ fontSize: "2rem" }} className="cursor-pointer">
                            ...
                        </p>
                    </DropdownMenu>
                </span>
            );
        }
    }
];
