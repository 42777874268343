import * as React from "react";
import "./styles.scss";
import TabMenu from "components/TabMenu";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import CompanyData from "./CompanyData";
import LocationData from "./LocationData";
import MaterialData from "./MaterialData";
import Sourcing from "./Sourcing";
import ApprovalWorkFlow from "./ApprovalWorkFlow";
import Network from "./Network";
import Accounting from "./Accounting";
import Onboarding from "./Onboarding";
import OnboardingEach from "./Onboarding2";
import App from "App";
import ApprovalWorkFlowSteps from "./ApprovalWorkFlowSteps";
import KpiSetup from "./Sourcing/components/KpiSetup";
import LegacyPo from "./LegacyPo";
import Audit from "./Audit";
type BuyerSettingsProps = {};

const BuyerSettings: React.FC<BuyerSettingsProps> = () => {
  const menus = [
    {
      name: "Company Data",
      path: "company-data"
    },
    {
      name: "Location Data",
      path: "location-data"
    },
    {
      name: "Material Data",
      path: "material-data"
    },
    {
      name: "Sourcing Menu",
      path: "sourcing"
    },
    {
      name: "Approval Workflow",
      path: "approval"
    },
    {
      name: "Legacy PO",
      path: "legacy-po"
    },
    {
      name: "Audit Log",
      path: "audit"
    },
    {
      name: "Network Messages",
      path: "network"
    },
    {
      name: "Accounting Setup",
      path: "accounting"
    },
    {
      name: "On-Boarding",
      path: "onboarding"
    }
  ];
  const match = useRouteMatch();

  return (
    <div id="buyer-settings-page">
      <div className="container">
        <div className="row">
          <div className="menus col-lg-12 col-md-12">
            <TabMenu menus={menus} classes="mt-0 set" />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <Switch>
              <Route path={match.path} exact>
                <Redirect to={`${match.url}/company-data`} />
              </Route>

              <Route path={`${match.path}/company-data`}>
                <CompanyData />
              </Route>
              <Route path={`${match.path}/legacy-po`}>
                <LegacyPo />
              </Route>
              <Route path={`${match.path}/audit`}>
                <Audit />
              </Route>

              <Route path={`${match.path}/location-data`}>
                <LocationData />
              </Route>
              <Route path={`${match.path}/material-data`}>
                <MaterialData />
              </Route>
              <Route exact path={`${match.path}/sourcing`}>
                <Sourcing />
              </Route>
              <Route exact path={`${match.path}/sourcing/:idKpi`}>
                <KpiSetup />
              </Route>
              <Route exact path={`${match.path}/sourcing/kpi`}>
                <KpiSetup />
              </Route>
              <Route exact path={`${match.path}/approval`}>
                <ApprovalWorkFlow />
              </Route>
              <Route exact path={`${match.path}/approval/approvalsteps/:idWork`}>
                <ApprovalWorkFlowSteps />
              </Route>
              <Route path={`${match.path}/network`}>
                <Network />
              </Route>
              <Route path={`${match.path}/accounting`}>
                <Accounting />
              </Route>
              <Route exact path={`${match.path}/onboarding`}>
                <Onboarding />
              </Route>
              <Route exact path={`${match.path}/onboarding/each/:idOn`}>
                <OnboardingEach />
              </Route>
              <Route exact path={`${match.path}/onboarding`}>
                <Onboarding />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyerSettings;
