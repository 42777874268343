import React from "react";
import Document from "assests/icons/sidebar/inventory.svg";
import Button from "components/Button/index";
import "./styles.scss";
import { Link } from "react-router-dom";
import Checkmark from "assests/icons/checkmark-circle.svg";

export const documentsColumns = [
  {
    name: "Date",
    index: "date",
    id: 1
  },
  {
    name: "Transaction Ref.",
    index: "ref",
    id: 2
  },
  {
    name: "Description",
    index: "description",
    id: 3
  },
  {
    name: "Location",
    index: "location",
    id: 4
  },
  {
    name: "Amount",
    index: "amount",
    id: 5
  },
  {
    name: "Status",
    id: 6,
    render: (item) => (
      <button
        type="button"
        style={{
          padding: "8px 16px",
          background: item.status === "Complete" ? "rgba(0, 176, 7, 0.15)" : "rgba(4, 0, 176, 0.15)",
          borderRadius: "10px",
          color: item.status === "Complete" ? "#387C57" : " #2F80ED",
          cursor: "default"
        }}
      >
        {item.status}
      </button>
    )
  },
  // {
  //   name: "Action",
  //   id: 6,
  //   render: (item) => (
  //     <Link
  //       to={`/buyer/suppliers/details/${item.id}`}
  //       type="button"
  //       style={{
  //         padding: "8px 16px",
  //         background: item.status === "Pending" ? "rgba(245, 150, 37, 0.15)" : "rgba(4, 0, 176, 0.15)",
  //         borderRadius: "10px",
  //         color: item.status === "Pending" ? "rgba(245, 150, 37, 1)" : " #2F80ED"
  //       }}
  //     >
  //       {/* {item.status} */}
  //       View
  //     </Link>
  //   )
  // }
];

export const documents = [
  {
    date: "2nd Sept. 2019",
    ref: "450000090",
    description: "AC Maintenance",
    location: "HQ",
    amount: "100,000",
    status: "Complete"
  }
];
