import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { countriesState, Action } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";

const initialState: countriesState = {
    isLoading: false,
    countries: null,
    successMessage: "",
    errorMessage: ""
};

const countrySlice = createSlice({
    name: "country",
    initialState,
    reducers: {
        getAllCountriesLoading: (state) => {
            state.isLoading = true;
        },
        getAllCountriesSuccess: (state, action: PayloadAction<Action>) => {
            state.isLoading = false;
            state.countries = action.payload;
        },
        getAllCountriesFail: (state) => {
            state.isLoading = false;
        }
    }
});

const { actions, reducer } = countrySlice;
export const { getAllCountriesLoading, getAllCountriesSuccess, getAllCountriesFail } = actions;

export const getAllCountries = (): AppThunk => async (dispatch: AppDispatch, getState) => {
    dispatch(getAllCountriesLoading());
    try {
        const response = await API.get("/countries/get");
        if ([200, 201].includes(response.status)) {
            dispatch(getAllCountriesSuccess(response.data.data));
        }
    } catch (error) {
        dispatch(getAllCountriesFail());
    }
};

export default reducer;
