import StatsCard from "components/StatsCard";
import React, { useState } from "react";
import "../styles.scss";
import MembersBlue from "assests/icons/buyer/members-blue.svg";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import SearchIcon from "components/SearchBar";
import { AppDispatch, RootState } from "store/store";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { SourcingData, SourcingColumn } from "../data";
import CreateOrEditDepartment from "components/ModalBuyer/CreateOrEditDepartment";
import Pagination from "components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import useSearch from "hooks/useSearch";
import { deleteSource, getSource } from "store/slice/BuyerSlice/TeamSlice/TeamSlice";
import { useEffect } from "react";
import { toastPopUp } from "utils/utils";
import { de } from "date-fns/locale";
import CreateOrEditTeam from "components/ModalBuyer/CreateOrEditTeam";
import { checkPermission } from "utils/utils";
import API from "utils/httpsRequest";
import { toastr } from "react-redux-toastr";

const CreateSourcingTeam = () => {
  const [modal, setModal] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [singleTeam, setSingleTeam] = useState<any>(null);
  const [addMemberToTeam, setAddMemberToTeam] = useState<any>(null);
  const [memberList, setMemberList] = useState<any>(null);

  const dispatch = useDispatch<AppDispatch>();
  const { teams, loading } = useSelector((state: RootState) => state.team);
  const { userToken } = useSelector((state: RootState) => state.user);

  //Hook
  const { search, inputRef, handleChange, searched } = useSearch(getSource, perPage);

  //Show Number for PerPage Request
  const handleNewPage = (pageNo: number, page: number = 1) => {
    setPerPage(pageNo);
    setPage(1);
    dispatch(getSource(page, pageNo, search));
  };

    //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, perPage: number) => {
      setPage(page);
      dispatch(getSource(page, perPage, search));
    },
    [search]
  );
  const { role_access } = useSelector((state: RootState) => state.user?.user);

  const paginate = React.useCallback(
    (pageNo: number, perPage: number) => {
      setPage(pageNo);
      dispatch(getSource(pageNo, perPage, search));
    },
    [search]
  );

   //Onmout Requests
   useEffect(() => {
    // dispatch(getDepartments(page, perPage));
    dispatch(getSource(page,perPage));
   
  }, []);
  // setpage to the first page when a searched executed
  useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  const closeModal = () => {
    setModal((prev) => !prev);
    setSingleTeam(null);
    setAddMemberToTeam(null);
    setMemberList(null)
  };
  
   //Edit DepartmentData
   const editTeam = (id: string, sourcing_team_description: string, team_lead_user_id: string) => {
    setSingleTeam({ id, sourcing_team_description, team_lead_user_id});
    setModal((prev) => !prev);
  };

  const addMemberTeam = (item) => {
    console.log(item)
    setAddMemberToTeam(item.id);
    setMemberList(item.team_members)
    setModal((prev) => !prev);
  };

  const removeTeamMember = (item) => {
    console.log('remove',addMemberToTeam)
      toastPopUp(
        async () => {
          try {
            const response = await API.post("sourcing_teams/remove_team_member", {
              "sourcing_team_id": addMemberToTeam,
              "user_id": item.user_id
            }, {
              headers: {
                Authorization: `Bearer ${userToken}`
            }
            })
            dispatch(getSource(1, 10))
            setMemberList(memberList.filter((member) => member.user_id !== item.user_id))
            toastr.success("", response.data.message);
          } catch (error: any) {
            toastr.error(error?.response?.data?.message || 'Something went wrong, Please try again');
          } 
        },
        "Remove",
        "Cancel",
        "Remove member",
        `${item.lastname} ${item.othernames}`,
        "red"
      );
  }

   //  delete
   const deleteTeam2 = (name: string, id: string) => {
    const payload = { sourcing_team_id: id };
    toastPopUp(
        () => {
            dispatch(
                deleteSource(payload, () => {
                    dispatch(getSource(page, perPage));
                })
            );
        },
        "Delete",
        "Cancel",
        "Delete the entity",
        name,
        "red"
    );
};

const sourceList =
teams &&
teams?.data?.map((item) => {
  return {
    id: item.id,
    name: item.sourcing_team_description,
    team_members: item.team_members && item.team_members.length,
    leader_name: `${item.team_lead_lastname} ${item.team_lead_othernames}` || "N/A",
    add: ()=> checkPermission(role_access, "SETUP_INDEX") && addMemberTeam(item),
    edit: () =>
    checkPermission(role_access, "SETUP_INDEX") && editTeam(
        item.id,
        item.sourcing_team_description,
        item.team_lead_user_id,
      ),
    deleteTeam: () => checkPermission(role_access, "SETUP_INDEX") && deleteTeam2(item.sourcing_team_description, item.id)
  };
});

  return (
    <div id="settings-sourcing">
        
      {modal && <CreateOrEditTeam removeTeamMember={removeTeamMember} singleTeam={singleTeam} addMemberToTeam={addMemberToTeam} memberList={memberList} closeModal={closeModal} />}
      <div className="container mt-3">
        <div className="row mt-3 mb-5">
          <StatsCard statsNumber={teams && teams.count} text="Sourcing Team" classes="stats-icon" image={MembersBlue} iconBackgroundColor="rgba(45, 156, 219, 0.1)" />
          <StatsCard image={AddIcon} classes="middle-img" centerText="Create Sourcing Team" onClick={closeModal} />
        </div>

        <div className="d-flex  mb-3">
        <SearchIcon
            placeholder="Search for Sourcing Team"
            boxClasses=""
            textRef={inputRef}
            value={search}
            onChange={handleChange}
          />
          <Dropdown perPage={perPage} action={handleNewPage} />
        </div>
        <DataTable columns={SourcingColumn} data={sourceList} loading={loading} />
        {
          teams?.count > 10 ?
          <Pagination page={page} lastPage={teams?.pagination?.lastPage} paginate={paginate} total={teams?.pagination?.total} handlePageChange={handlePageChange} perPage={perPage} />
           : null
        }
       
      </div>
    </div>
  );
};

export default CreateSourcingTeam;
