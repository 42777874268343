import React from "react";
import Root from "Root";
import ReduxToastr from "react-redux-toastr";
import WarningIcon from "@mui/icons-material/Warning";
import "./index.scss";
type AppProps = {};

const App: React.FunctionComponent<AppProps> = () => {
  return (
    <>
        <Root />
        {/*<div className="blank_page" style={{ width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
        <WarningIcon className="servwarn" />
        <p className="big" style={{ fontSize: "60px", marginBottom: "30px",marginTop:"40px", color: "#2f80ed" }}>
          Service Unavailable
        </p>
        <p className="small" style={{ fontSize: "20px" }}>
          Check Back Shortly.
        </p>
      </div>*/}
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        getState={(state: { toastr: any }) => state.toastr} // This is the default
        closeOnToastrClick
        className="toastr"
      />
    </>
  );
};

export default App;
