import DropdownMenu from "components/DropdownMenu";
import "./styles.scss";

export const fielddatas = [
    {
        code: "0010",
        name: "Purchase Requisition",
        subProcess: "Stock",
        legal: "steve manga",
        location: "All",
        level: "4",
        type: "steve manga"
    },
    {
        code: "0011",
        name: "Purchase Order",
        subProcess: "Non-Stock",
        legal: "steve manga",
        location: "All",
        level: "2",
        type: "steve manga"
    }
];

export const fieldColumn = [
    {
        name: "ID",
        index: "set_id",
        id: 1
    },
    {
        name: "Field Set Name",
        index: "field_name",
        id: 2
    },
    
    {
        name: "Entity",
        index: "assigned_entity",
        id: 3
    },
    {
        name: "Category",
        index: "category",
        id: 4
    },
    {
        name: "Date Created",
        index: "date",
        id: 5
    },
    {
        name: "Actions",
        id: 8,
        render: ({ deleteField }) => {
            const settings = [
                // {
                //     name: "Edit",
                //     style: "activate_tag",
                //     select: edit
                // },
                {
                    name: "Delete",
                    style: "delete_tag",
                    select: deleteField
                }
            ];
            return (
                <span style={{ position: "relative" }}>
                    <DropdownMenu options={settings} boxClasses="dropdown-style">
                        <p style={{ fontSize: "2rem" }} className="cursor-pointer">
                            ...
                        </p>
                    </DropdownMenu>
                </span>
            );
        }
    }
];
