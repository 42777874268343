import React, { useState } from "react";
import AuthLayout from "./AuthContainer/AuthLayout";
import image from "assests/illustrations/buyer.png";
import Textbox from "components/Textbox";
import Button from "components/Button";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
// import { forgetPassword, forgetPasswordFail } from "store/slice/AuthSlice/AuthSlice";
import ErrorMesssage from "components/ErrorMessage";
import { RootState } from "store/store";
import { useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { forgetPassword, setPasswordRequest } from "store/slice/BuyerSlice/AuthSlice/AuthSlice";
import { toastPopUp } from "utils/utils";

const ForgotPasswordSet: React.FC = (props) => {
  type FormData = {
    new_password: string;
    new_password2: string;
  };
  const [password,setPassword]=useState("")
  const [passwordConfirm,setPasswordConfirm]=useState("")

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");
  const token = urlParams.get("token");

  const dispatch = useDispatch();
  const history = useHistory();
  const { errorMessage, isLoading } = useSelector((state: RootState) => state.buyerAuth);

  const { register, handleSubmit, errors } = useForm<FormData>({
    mode: "onBlur"
  });

  const onFormSubmit = (vals: FormData) => {

    if (password===passwordConfirm) {
      const payload = { new_password:password,reset_token:token, id:id }
      console.log(payload)
      dispatch(
          setPasswordRequest(payload, () => {
          })
      );
      
    }
    else{
      toastr.error("password mismatch");
      setPasswordConfirm("")
      setPassword("")
      
    }

  
  };

  React.useEffect(() => {
    // dispatch(forgetPasswordFail(""));
  }, []);

  return (
    <AuthLayout
      bottomImage={image}
      content="Enter your GZI Buyer Portal email address and we’ll send you a link to reset your password."
      headerText="Reset your Password?"
      firstLink=""
      firstLinkColor=""
      link="/buyer/login"
      padTop="6.8rem"
    >
      <div>
        {/* {errorMessage !== "" && <ErrorMesssage message={errorMessage} />} */}
        {/* {message !== "" && <ErrorMesssage message={message} success={true} />} */}
        {errorMessage !== "" && <ErrorMesssage message={errorMessage} />}
        <form className="pt-3" onSubmit={handleSubmit(onFormSubmit)}>
          <Textbox
            type="password"
            label="Password"
            placeholder="Enter Password"
            name="new_password"
            boxClasses="pb-4"
            error={errors.new_password && "This field is required"}
            onChange={(e)=>{setPassword(e.target.value)}}
            customRef={register({
              required:true
            })}
          />
           <Textbox
            type="password"
            label="Confirm Password"
            placeholder="Confirm Password"
            name="new_password2"
            boxClasses="pb-4"
            error={errors.new_password2 && "This field is required"}
            onChange={(e)=>{setPasswordConfirm(e.target.value)}}
            customRef={register({
              required:true
            })}
          />
          <Button label="Reset my Password" btnType="btn-primary" btnStyle="100%" loading={isLoading} />
        </form>
      </div>
    </AuthLayout>
  );
};

export default ForgotPasswordSet;
