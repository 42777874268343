import React from "react";
import Modal from "components/Modal";
import ArrowDropdown from "components/ArrowDropdown";
import Textbox from "components/Textbox";
import Button from "components/Button";
import useArrowDropdown from "hooks/useArrowDropdown";
import { useForm } from "react-hook-form";
// import { getRoles } from "store/slice/RoleSlice/RoleSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { createUser, editUser, getUsers } from "store/slice/UserMgtSlice/UserMgtSlice";
import { CreateUserPayload } from "store/slice/UserMgtSlice/interface";
import "./styles.scss";
import { getSingleUser } from "store/slice/UserMgtSlice/UserMgtSlice";
import FileUploaderBox from "components/FileUploaderBox";
import StarRating from "components/Star";
import { useState } from "react";
import { getEachRfp, scoreRfpData } from "store/slice/BuyerSlice/EvaluationSlice/EvaluationSlice";

type Props = {
  closeModal: () => void;
  modalText?: boolean;
  vendor_id?: string;
  rfp_id?: string;
  rfp_field_id?: string;
  question?: string;
  order?: string;
  vendor_score?: any;
  categoryName?: any;
};

const ScoreOrView = ({ closeModal, modalText, vendor_id, rfp_field_id, rfp_id, question, order, vendor_score, categoryName }: Props) => {
  const [error, setError] = React.useState("");
  const { register, handleSubmit, errors, setValue } = useForm<FormData>({
    mode: "onChange"
  });
  const { scoreLoading } = useSelector((state: RootState) => state.rfpData);

  const [score, setScore] = useState("");
  const [reason, setReason] = useState("");
  console.log(vendor_score);
  type FormData = {
    vendor_id: string;
    rfp_field_id: string;
    rfp_id: string;
    vendor_score: string;
    vendor_score_reason: string;
  };

  // "vendor_id": 50001,
  // "rfp_id": 10009,
  // "rfp_field_id": 29,

  // const { userId, false, false } = modalData;
  //Redux Store
  const dispatch = useDispatch();
  // const { roles } = useSelector((state: RootState) => state.roles);
  const { isLoading, singleUser } = useSelector((state: RootState) => state.userMgt);

  const { open, selectedOption, handleDropdownChange, handleAction, keyOption, ref } = useArrowDropdown();

  const validatePhoneNo = (value: any) => {
    let values = value.replace(/[^0-9]*/g, "");
    if (value.length > 11 || value !== values) return "Should be a phone number";
  };

  const onFormSubmit = (vals: FormData) => {
    const payload = {
      vendor_score: score,
      rfp_id,
      rfp_field_id,
      vendor_id,
      vendor_score_reason: reason
    };
    console.log(payload);
    // if (isLoading) return;

    dispatch(
      scoreRfpData(payload, () => {
        closeModal();
        dispatch(getEachRfp({ id: rfp_id, vendor_id: vendor_id, categoryName: categoryName }));
      })
    );

    // if (false) {
    //   dispatch(
    //     editUser(editData, false, () => {
    //       closeModal();
    //       dispatch(getUsers(1, 10));
    //     })
    //   );
    // } else {
    //   dispatch(
    //     createUser(data, () => {
    //       closeModal();
    //       dispatch(getUsers(1, 10));
    //     })
    //   );
    // }
  };

  //Remove error message
  React.useEffect(() => {
    if (keyOption) {
      setError("");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyOption]);

  return (
    <Modal modalText={modalText === false ? "View Score" : "Review KPI"} onClose={closeModal} modallength={!false ? "modal-length" : ""}>
      <div id="score-or-view-modal">
        {true && true ? (
          <p className="">
            Qst {order}. {question}
          </p>
        ) : null}
        <div className="user-score">
          <span>Score /100</span>
          {modalText === false ? <span className="btn">{vendor_score}</span> : null}
        </div>
        <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
          {modalText === false ? null : (
            <div className="question-box">
              <div className="question-box">
                <label className="question-label" htmlFor="score">
                  Score
                </label>
                <input
                  type="number"
                  required
                  id="score"
                  className="scoreField"
                  onChange={(e) => {
                    setScore(e.target.value);
                  }}
                  placeholder="Score"
                />
              </div>

              <div className="area question-box">
                <label className="question-label" htmlFor="">
                  Why?
                </label>
                <textarea
                  name="additionalComments"
                  id="additionalComments"
                  rows={2}
                  required
                  className="textarea"
                  placeholder="Add comment"
                  onChange={(e) => {
                    setReason(e.target.value);
                  }}
                />
              </div>
            </div>
          )}
          <p className={`${error ? "d-block" : "d-none"} error mt-1`}>{error}</p>

          {/* <div className="question-box">
            <label className="question-label" htmlFor="">
              Component Rating
            </label>
            <StarRating stars={3} disabled={false} />
          </div> */}

          {modalText === false ? null : <Button label={"Submit"} btnType="btn-primary" boxClasses="mt-4 mb-3" btnStyle="100%" loading={scoreLoading} />}
        </form>
      </div>
    </Modal>
  );
};

export default ScoreOrView;
