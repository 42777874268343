import React, { useCallback, useState } from "react";
import "./styles.scss";
import TabSmall from "components/TabSmall";
import CreateSourcingTeam from "./components/CreateSourcingTeam";
import KpiGroup from "./components/KpiGroup";

const Sourcing = () => {
    const menus = [
        { name: "Create Sourcing Team", id: "source" },
        { name: "Create KPI’s", id: "kpi" }
    ];
    //State
    const [state, setstate] = useState(menus[0].id);

    const selectMenu = useCallback((name) => {
        setstate(name);
    }, []);

    return (
        <div id="settings-sourcing">
            <div className="container">
                <TabSmall menus={menus} onSelect={selectMenu} classes="mb-3" />

                {state === "source" && <CreateSourcingTeam />}
                {state === "kpi" && <KpiGroup />}
            </div>
        </div>
    );
};

export default Sourcing;
