import React from "react";
import Modal from "components/Modal";
import ArrowDropdown from "components/ArrowDropdown";
import Textbox from "components/Textbox";
import Button from "components/Button";
import useArrowDropdown from "hooks/useArrowDropdown";
import { useForm } from "react-hook-form";
// import { getRoles } from "store/slice/RoleSlice/RoleSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { createUser, editUser, getUsers } from "store/slice/UserMgtSlice/UserMgtSlice";
import { CreateUserPayload } from "store/slice/UserMgtSlice/interface";
import "./styles.scss";
import { getSingleUser } from "store/slice/UserMgtSlice/UserMgtSlice";
import SelectDropdown from "components/SelectDropdown";
import { getCompaniesEntityList } from "store/slice/CompaniesSlice/CompaniesSlice";
import { getRoles } from "store/slice/Roleslice/RoleSlice";
import SearchBar from "components/SearchBar";
import { getDepartments } from "store/slice/BuyerSlice/DepartmentSlice/DepartmentSlice";
import { useEffect } from "react";
import useSearch from "hooks/useSearch";

type Props = {
  closeModal: () => void;
  //   modalData?: { userId: string; viewStatus: boolean; editStatus: boolean };
};
const CreatePO = ({ closeModal }: Props) => {
  const [error, setError] = React.useState("");
  const { register, handleSubmit, errors, setValue, watch } = useForm<FormData>({
    mode: "onChange"
  });

  const values = watch();
  const { search, inputRef, handleChange, searched } = useSearch("", 1);
  //Redux Store
  const dispatch = useDispatch();
  // const { roles } = useSelector((state: RootState) => state.roles);

  const onFormSubmit = () => {};

  return (
    <div className="po-modal">
      <Modal modalText={"Select Pending Purchase Order Requests"} onClose={closeModal}>
        <div id="create-po-modal">
          <h5 className="mb-4 page-title">Sourcing Items</h5>
          <div className="d-flex justify-content-between mb-2">
            <div className="search-cont">
              <SearchBar placeholder="Search for Supplier name,  Description or Requisition Number" inputWidth="100%" textRef={inputRef} value={search} onChange={handleChange} />
            </div>
            <div className="terms-cont">
              <p>Payment Terms</p>{" "}
              <select className="form-select form-select-sm terms-select" aria-label="select days">
                <option selected>Select</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
          </div>
          <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
            <table>
              <thead>
                <tr>
                  <th>Select</th>
                  <th>Sourcing ID</th>
                  <th>Description</th>
                  <th>Price</th>
                  <th>Currency</th>
                  <th>Supplier</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-container mb-3">
                  <td className="first-td">
                    <input className="" type="checkbox" value="" id="flexCheckDefault" />
                  </td>
                  <td>100234157</td>
                  <td>Apple Iphone 12 Max, 64GB</td>
                  <td>20</td>
                  <td>EA</td>
                  <td className="last-td">24%</td>
                </tr>
              </tbody>
            </table>
            <Button label="Create P.O." btnType="btn-primary" boxClasses="mt-4 mb-2" xtraClass="btm-btn" btnStyle="fit-content" />
          </form>
          <p className=" btm-btn" onClick={closeModal}>
            Cancel
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default CreatePO;
