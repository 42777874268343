import React, { useEffect, useState, useRef, useCallback } from "react";
import "./styles.scss";
import StatsCard from "components/StatsCard";
import DataTable from "components/DataTable";
import { documentsColumns } from "./data";
import Bill from "assests/icons/buyer/bill.svg";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import ApprovedUsers from "assests/icons/buyer/members-green.svg";
import MembersBlue from "assests/icons/buyer/members-blue.svg";
import SearchIcon from "components/SearchBar";
import { useDispatch, useSelector } from "react-redux";
// import { getHouseholdSummary, getAllHousehold } from "store/slice/HouseHoldsSlice/HouseholdSlice";
import { RootState } from "store/store";
import Pagination from "components/Pagination";
import Dropdown from "components/Dropdown";
import useSearch from "hooks/useSearch";
import useArrowDropdown from "hooks/useArrowDropdown";
// import { getCommunity } from "store/slice/CommunitySlice/communitySlice";
import Stars from "../../../components/Star/index";
import { getVendors, getVendorsCat, getVendorsDownload } from "store/slice/BuyerSlice/VendorSlice/VendorSlice";
import CreateVendor from "components/ModalBuyer/CreateVendor";
import { getSummaries } from "store/slice/SummarySlice/SummarySlice";
import FilterCard from "components/FilterCard";
import { useForm } from "react-hook-form";
import filterIcon from "assests/icons/buyer/filter-icon.svg";
import { removeZero } from "utils/utils";
import memberRed from "../../../assests/icons/memberRed.svg";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import moment from "moment";

type Props = {};

const BuyerSuppliers: React.FunctionComponent<Props> = () => {
  const [page, setPage] = useState<number>(1);
  const [length, setlength] = useState<number>(10);
  const [selectComboxOption, setSelectComboxOption] = React.useState("");
  const dispatch = useDispatch();
  const [modal, setModal] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState(false);

  //Redux
  const { vendors, vendor_category, vendorsDownload } = useSelector((state: RootState) => state.vendorDetails);
  const { summaries } = useSelector((state: RootState) => state.summaries);
  // const { summary, households } = useSelector((state: RootState) => state.household);
  // const { community } = useSelector((state: RootState) => state.community);

  // getting params

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const country_id = urlParams.get("country_id");

  const [vendorCode, setVendorCode] = useState("");
  const [vendorClass, setVendorClass] = useState("");
  const [vendorStatus, setVendorStatus] = useState("");
  const [vendorCategory, setVendorCategory] = useState("");
  const [loggedIn, setLoggedIn] = useState("");
  const [onboardStatus, setOnboardStatus] = useState("");

  const { open: open2, selectedOption: selectedOption2, handleDropdownChange: handleDropdownChange2, handleAction: handleAction2, ref: dropdownRef2 } = useArrowDropdown();

  //
  const params = [selectedOption2 !== "" && selectedOption2 !== "All" ? selectedOption2 : undefined, selectComboxOption !== "" && selectComboxOption !== "All" ? selectComboxOption : undefined];
  const { search, inputRef, handleChange, searched,setSearch } = useSearch(getVendors, length, undefined, [
    {
      fetch_type: "detailed",
      vendor_code: vendorCode,
      vendor_classification: vendorClass,
      vendor_status: vendorStatus,
      business_category_id: vendorCategory,
      has_logged_in: loggedIn,
      onboard_status: onboardStatus
    },
    country_id
  ]);
  const supplierSearch = localStorage.getItem('supplier-search');
  
  useEffect(() => {
      setSearch(supplierSearch||'')
  }, [supplierSearch,setSearch])

  useEffect(() => {
    if (search) {
      dispatch(
        getVendorsDownload(
          page,
          undefined,
          search,
          {
            fetch_type: "detailed",
            vendor_code: vendorCode,
            vendor_classification: vendorClass,
            vendor_status: vendorStatus,
            business_category_id: vendorCategory,
            has_logged_in: loggedIn,
            onboard_status: onboardStatus
          },
          country_id
        )
      );
    }
  }, [search]);

  const { register, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      vendor_code: "",
      vendor_classification: "",
      sap_sync: ""
      // to: '',
    }
  });

  // filter states

  // setpage to the first page when a searched executed
  useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  useEffect(() => {
    if(supplierSearch === null || supplierSearch === ""){
      dispatch(getVendors(page, length, "", { fetch_type: "detailed" }, country_id));
    }
  }, [])

  // get vendors
  useEffect(() => {
    dispatch(getVendorsDownload());
    dispatch(getSummaries());
    dispatch(getVendorsCat(1, 100));
  }, []);

  //--------------------------------------------
  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, length: number) => {
      setPage(page);
      dispatch(
        getVendors(
          page,
          length,
          search,
          {
            fetch_type: "detailed",
            vendor_code: vendorCode,
            vendor_classification: vendorClass,
            vendor_status: vendorStatus,
            business_category_id: vendorCategory,
            has_logged_in: loggedIn,
            onboard_status: onboardStatus
          },
          country_id
        )
      );
    },
    [search, vendorCode, vendorClass, vendorStatus, vendorCategory, loggedIn, dispatch, country_id, onboardStatus]
  );

  const paginate = React.useCallback(
    (pageNo: number, length: number) => {
      setPage(pageNo);
      dispatch(
        getVendors(
          pageNo,
          length,
          search,
          {
            fetch_type: "detailed",
            vendor_code: vendorCode,
            vendor_classification: vendorClass,
            vendor_status: vendorStatus,
            business_category_id: vendorCategory,
            has_logged_in: loggedIn,
            onboard_status: onboardStatus
          },
          country_id
        )
      );
    },
    [search, vendorCode, vendorClass, vendorStatus, vendorCategory, loggedIn, dispatch, country_id, onboardStatus]
  );

  //------------------------------------------------------------
  //Show Number for length Function
  //------------------------------------------------------------
  const handleNewPage = React.useCallback(
    (pageNo: number, page = 1) => {
      setlength(pageNo);
      setPage(1);
      dispatch(
        getVendors(
          page,
          pageNo,
          search,
          {
            fetch_type: "detailed",
            vendor_code: vendorCode,
            vendor_classification: vendorClass,
            vendor_status: vendorStatus,
            business_category_id: vendorCategory,
            has_logged_in: loggedIn,
            onboard_status: onboardStatus
          },
          country_id
        )
      );
    },
    [search, vendorCode, vendorClass, vendorStatus, vendorCategory, loggedIn, dispatch, country_id, onboardStatus]
  );
  //   Table data
  const vendorsTable =
    vendors &&
    vendors?.data?.map((data: any) => {
      return {
        date: data.created_at ? moment(data.created_at).format("L") : "N/A",
        legal: data?.company_description,
        name: data.vendor_description,
        email: data.email,
        id: data?.id,
        specialization: data.business_category_description,
        data_status: data.vendor_status < 1 ? "Pending" : "Verified",
        status: data.vendor_status,
        no: data.vendor_code && removeZero(data.vendor_code),
        purchase_order_count: data?.purchase_order_count,
        search
      };
    });
console.log(vendorsTable, 'loooll')

  const checkStat = (status) => {
    let stat;
    if (status === "0") {
      stat = "Blocked";
    } else if (status === "-1") {
      stat = "Pending";
    } else if (status === "1") {
      stat = "Verified";
    } else if (status === "-2") {
      stat = "Draft";
    }
    return stat;
  };
  const vendorsTableDownload =
    vendorsDownload &&
    vendorsDownload?.data?.map((data: any) => {
      return {
        date: data.created_at ? moment(data.created_at).format("L") : "N/A",
        legal: data?.company_description,
        name: data.vendor_description,
        phone: data.phone_number,
        email: data.email,
        id: data?.id,
        specialization: data.business_category_description,
        data_status: data.vendor_status < 1 ? "Pending" : "Verified",
        status: checkStat(data.vendor_status),
        no: data.vendor_code && removeZero(data.vendor_code),
        purchase_order_count: data?.purchase_order_count
      };
    });

  // approved vendors
  const approved = vendors?.data.filter((item) => item.vendor_status > 0);

  const closeModal = () => {
    setModal(!modal);
  };

  const onSubmit = (vals) => {
    setVendorCode(vals.vendor_code);
    setVendorClass(vals.vendor_classification);
    setVendorStatus(vals.vendor_status);
    setVendorCategory(vals.vendor_category);
    setLoggedIn(vals.has_logged_in);
    setOnboardStatus(vals.onboard_status);
    dispatch(getSummaries(undefined, undefined, undefined, undefined, vals.vendor_classification, vals.vendor_status, vals.vendor_code, vals.has_logged_in, vals.vendor_category, vals.onboard_status));
    dispatch(
      getVendors(
        page,
        length,
        search,
        {
          vendor_code: vals.vendor_code,
          fetch_type: "detailed",
          vendor_classification: vals.vendor_classification,
          vendor_status: vals.vendor_status,
          business_category_id: vals.vendor_category,
          has_logged_in: vals.has_logged_in,
          onboard_status: vals.onboard_status
        },
        country_id
      )
    );
    dispatch(
      getVendorsDownload(
        page,
        undefined,
        search,
        {
          vendor_code: vals.vendor_code,
          fetch_type: "detailed",
          vendor_classification: vals.vendor_classification,
          vendor_status: vals.vendor_status,
          business_category_id: vals.vendor_category,
          has_logged_in: vals.has_logged_in,
          onboard_status: vals.onboard_status
        },
        country_id
      )
    );
  };

  const handleClear = () => {
    setVendorCode("");
    setVendorClass("");
    setVendorStatus("");
    setVendorCategory("");
    setLoggedIn("");
    setOnboardStatus("");
    dispatch(getVendors(page, length, search, { fetch_type: "detailed" }, country_id));
    dispatch(getVendorsDownload(page, undefined, search, { fetch_type: "detailed" }, country_id));
    dispatch(getSummaries());
    reset();
  };

  return (
    <div id="buyer-supplier-page">
      {modal && <CreateVendor closeModal={closeModal} />}
      <div className="container">
        <div className="d-flex align-items-center mb-3">
          <h3 className="header">Transaction Stats</h3>
        </div>
        <div className=" row mb-5">
          <StatsCard statsNumber={summaries?.total_approved_vendors} text="Registered Suppliers" classes="stats-icon-orange" image={ApprovedUsers} iconBackgroundColor="rgba(0, 176, 80, 0.1)" />

          <StatsCard statsNumber={summaries?.total_unapproved_vendors} text="Pending Approvals" classes="stats-icon-leaf" image={MembersBlue} iconBackgroundColor="rgba(45, 156, 219, 0.1)" />
          <StatsCard statsNumber={summaries?.total_blocked_vendors} text="Blocked Suppliers" classes="stats-icon-leaf" image={memberRed} iconBackgroundColor="rgba(255, 65, 65, 0.1)" />
          <StatsCard
            image={AddIcon}
            classes="middle-img"
            centerText="Create Supplier"
            onClick={() => {
              setModal(true);
            }}
          />
        </div>

        <h4 className="page-title mb-2">Supplier List</h4>
        {showFilter && (
          <FilterCard handleSubmit={handleSubmit(onSubmit)} clear={handleClear}>
            <div className="row">
              <div className="col col-4">
                <label style={{ display: "block" }}>Vendor Code</label>
                <input
                  type="text"
                  name="vendor_code"
                  className="mt-1"
                  placeholder="Vendor Code"
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  ref={register({ required: false })}
                />
              </div>
              {/* <div className="col col-4">
                <label style={{ display: "block" }}>End Date</label>
                <input
                  type="date"
                  name="end_date"
                  className="mt-3"
                  placeholder="End Date"
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  ref={register({ required: false })}
                />
              </div> */}
              <div className="col col-4">
                <label style={{ display: "block" }}>Vendor Classification </label>
                <select
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  name="vendor_classification"
                  className="mt-1"
                  ref={register({
                    required: false
                  })}
                >
                  <option value="">Select</option>
                  <option value="Foreign">Foreign</option>
                  <option value="Local">Local</option>
                </select>
              </div>

              <div className="col col-4">
                <label style={{ display: "block" }}>Vendor Status </label>
                <select
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  className="mt-1"
                  name="vendor_status"
                  ref={register({
                    required: false
                  })}
                >
                  <option value="">Select</option>
                  <option value="0">Blocked</option>
                  <option value="-1">Pending Approval</option>
                  <option value="1">Verified/Approved</option>
                  <option value="-2">In Draft</option>
                </select>
              </div>
              <div className="col mt-3 col-4">
                <label style={{ display: "block" }}>Logged In ? </label>
                <select
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  className="mt-1"
                  name="has_logged_in"
                  ref={register({
                    required: false
                  })}
                >
                  <option value="">Select</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
              <div className="col mt-3 col-4">
                <label style={{ display: "block" }}>Onboard Status </label>
                <select
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  className="mt-1"
                  name="onboard_status"
                  ref={register({
                    required: false
                  })}
                >
                  <option value="">Select</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
              <div className="col mt-3 col-4">
                <label style={{ display: "block" }}>Vendor Category </label>
                <select
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  className="mt-1"
                  name="vendor_category"
                  ref={register({
                    required: false
                  })}
                >
                  <option value="">Select</option>
                  {vendor_category &&
                    vendor_category.data.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.business_category_description}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
          </FilterCard>
        )}
        <div className="search-field">
          <SearchIcon placeholder="Search for Supplier ID or Name." boxClasses=" mb-4 width80" textRef={inputRef} value={search} onChange={(e) => {
            handleChange(e)
            localStorage.setItem('supplier-search', e.target.value)
            if(!e.target.value){
              localStorage.removeItem('supplier-search')
            }
          }} />
          <Dropdown perPage={length} action={handleNewPage} />
          <div style={{ display: "flex", alignItems: "center" }}>
            <button
              style={{
                padding: "5px 10px",
                borderRadius: "10px",
                background: "#FFFFFF",
                color: "#737A91",
                border: "1px solid #DFE2E6",
                fontSize: "18px",
                marginLeft: "1rem",
                marginRight: "1rem"
              }}
              onClick={() => setShowFilter(!showFilter)}
            >
              <img src={filterIcon} alt="icon" className="icon-image" />
              <span
                style={{
                  paddingLeft: "1rem"
                }}
              >
                {!showFilter ? "Filters" : "Hide Filters"}
              </span>
            </button>
            <ReactHTMLTableToExcel id="test-table-xls-button" className="download-table-xls-button btn btn-success" table="table-to-xls" filename="Suppliers" sheet="tablexls" buttonText="Download" />
          </div>
        </div>
        <div style={{ display: "none" }} className="downloadSide">
          <table id="table-to-xls">
            <thead>
              <th>Legal Entity</th>
              <th>Supplier No.</th>
              <th>Supplier Contact</th>
              <th>Supplier Name</th>
              <th>Supplier Email</th>
              <th>Specialization</th>

              <th>Status</th>
            </thead>
            <tbody>
              {vendorsTableDownload &&
                vendorsTableDownload.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.legal}</td>

                      <td>{item.no}</td>
                      <td>{item.phone}</td>
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td>{item.specialization}</td>
                      <td>{item.status}</td>
                    </tr>
                  );
                })}
              <tr></tr>
            </tbody>
          </table>
        </div>
        <DataTable columns={documentsColumns} data={vendorsTable} />
        <Pagination page={page} lastPage={vendors?.pagination?.pageCount} paginate={paginate} total={vendors?.pagination?.total} handlePageChange={handlePageChange} perPage={length} />
      </div>
    </div>
  );
};

export default BuyerSuppliers;
