import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { teamStates, Action } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";

const initialState: teamStates = {
    loading: false,
    deleting: false,
    isLoading:false,
    teams: null,
    successMessage: "",
    errorMessage: ""
};

const teamSlice = createSlice({
    name: "team",
    initialState,
    reducers: {
        getAllSourceLoading: (state) => {
            state.loading = true;
        },
        getAllSourceSuccess: (state, action: PayloadAction<Action>) => {
            state.loading = false;
            state.teams = action.payload;
        },
        getAllSourceFail: (state) => {
            state.loading = false;
        },
        createSourceLoading: (state) => {
            state.isLoading = true;
        },
        createSourceSuccess: (state, action: PayloadAction<Action>) => {
            state.isLoading = false;
            state.teams = action.payload;
        },
        createSourceFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
        editSourceLoading: (state) => {
            state.isLoading = true;
        },
        editSourceSuccess: (state, action: PayloadAction<Action>) => {
            state.isLoading = false;
            state.teams = action.payload;
        },
        editSourceFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
        deleteSourceLoading: (state) => {
            state.deleting = true;
        },
        deleteSourceSuccess: (state, action: PayloadAction<Action>) => {
            state.deleting = false;
            state.teams = action.payload;
        },
        deleteSourceFail: (state, action: PayloadAction<string>) => {
            state.deleting = false;
            state.errorMessage = action.payload;
        },
    }
});

const { actions, reducer } = teamSlice;
export const {
    getAllSourceLoading,
    getAllSourceSuccess,
    getAllSourceFail,
    createSourceLoading,
    createSourceSuccess,
    createSourceFail,
    editSourceLoading,
    editSourceSuccess,
    editSourceFail,
    deleteSourceLoading,
    deleteSourceSuccess,
    deleteSourceFail
} = actions;

export const getSource =
    (
        page?: number,
        length?: number,
        search?: string,
        sourcing_team_status?: string,
        sourcing_team_code?: string,
        team_lead_user_id?: string,
    ): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(getAllSourceLoading());
        try {
            const response = await API.get("/sourcing_teams/list", {
                params: {
                    page,
                    length,
                    search,
                    sourcing_team_status,
                    sourcing_team_code,
                    team_lead_user_id,
                },
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(getAllSourceSuccess(response.data));
            }
        } catch (error) {
            dispatch(getAllSourceFail());
        }
    };

export const createSource =
    (data: any, cb?: () => void): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(createSourceLoading());
        try {
            const response = await API.post(`/sourcing_teams/create`, data, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(createSourceSuccess(response.data.message));
                toastr.success("", response.data.message);
                cb && cb();
            }
        } catch (error) {
            
            if (error.response) {
                dispatch(createSourceFail(error.response.data?.message));
                toastr.error(error.response.data.message);
              }
              else{
                dispatch(createSourceFail("No network connecton"));
                toastr.error("No network connecton");
              }
            setTimeout(() => {
                dispatch(createSourceFail(""));
            }, 3000);
        }
    };

export const editSource =
    (data: any, cb?: () => void): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(editSourceLoading());
        try {
            const response = await API.post(`/sourcing_teams/edit`, data, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(editSourceSuccess(response.data.message));
                toastr.success("", response.data.message);
                cb && cb();
            }
        } catch (error) {
            
            if (error.response) {
                dispatch(editSourceFail(error.response.data.message));
             toastr.error(error.response.data.message);
              }
              else{
                dispatch(editSourceFail("No network connecton"));
                toastr.error("No network connecton");
              }
            setTimeout(() => {
                dispatch(editSourceFail(""));
            }, 3000);
        }
    };

    export const addMember =
    (data: any, cb?: () => void): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(editSourceLoading());
        try {
            const response = await API.post(`/sourcing_teams/add_team_member`, data, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(editSourceSuccess(response.data.message));
                toastr.success("", response.data.message);
                cb && cb();
            }
        } catch (error) {
           
            if (error.response) {
                dispatch(editSourceFail(error.response.data.message));
                toastr.error(error.response.data.message);
              }
              else{
                dispatch(editSourceFail("No network connecton"));
                toastr.error("No network connecton");
              }
            setTimeout(() => {
                dispatch(editSourceFail(""));
            }, 3000);
        }
    };

    export const deleteSource =
    (data?: any, cb?: () => void): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(deleteSourceLoading());
        try {
            const response = await API.post(`/sourcing_teams/delete`, data, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(deleteSourceSuccess(response.data.message));
                toastr.success("", response.data.message);
                cb && cb();
            }
        } catch (error) {
            error.response && dispatch(deleteSourceFail(error.response.data.message));
            error.response && toastr.error(error.response.data.message);
            setTimeout(() => {
                dispatch(deleteSourceFail(""));
            }, 3000);
        }
    };


export default reducer;
