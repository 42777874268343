import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { locationStates, Action } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";

const initialState: locationStates = {
    loading: false,
    deleting: false,
    isLoading:false,
    locations: null,
    successMessage: "",
    errorMessage: ""
};

const locationSlice = createSlice({
    name: "location",
    initialState,
    reducers: {
        getAllLocationLoading: (state) => {
            state.loading = true;
        },
        getAllLocationSuccess: (state, action: PayloadAction<Action>) => {
            state.loading = false;
            state.locations = action.payload;
        },
        getAllLocationFail: (state) => {
            state.loading = false;
        },
        createLocationLoading: (state) => {
            state.isLoading = true;
        },
        createLocationSuccess: (state, action: PayloadAction<Action>) => {
            state.isLoading = false;
            state.locations = action.payload;
        },
        createLocationFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
        editLocationLoading: (state) => {
            state.isLoading = true;
        },
        editLocationSuccess: (state, action: PayloadAction<Action>) => {
            state.isLoading = false;
            state.locations = action.payload;
        },
        editLocationFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
        deleteLocationLoading: (state) => {
            state.deleting = true;
        },
        deleteLoactionSuccess: (state, action: PayloadAction<Action>) => {
            state.deleting = false;
            state.locations = action.payload;
        },
        deleteLoactionFail: (state, action: PayloadAction<string>) => {
            state.deleting = false;
            state.errorMessage = action.payload;
        }
    }
});

const { actions, reducer } = locationSlice;
export const {
    getAllLocationLoading,
    getAllLocationSuccess,
    getAllLocationFail,
    createLocationLoading,
    createLocationSuccess,
    createLocationFail,
    editLocationLoading,
    editLocationSuccess,
    editLocationFail,
    deleteLocationLoading,
    deleteLoactionSuccess,
    deleteLoactionFail
} = actions;

export const getLocations =
    (
        page?: number,
        length?: number,
        search?: string,
        department_status?: string,
        department_code?: string,
        company_id?: string,
        location_id?: string,
        hod_user_id?: string
    ): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(getAllLocationLoading());
        try {
            const response = await API.get("/locations/get", {
                params: {
                    page,
                    length,
                    search,
                    department_status,
                    department_code,
                    company_id,
                    location_id,
                    hod_user_id
                },
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(getAllLocationSuccess(response.data));
            }
        } catch (error) {
            dispatch(getAllLocationFail());
        }
    };

export const createLocation =
    (data: any, cb?: () => void): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(createLocationLoading());
        try {
            const response = await API.post(`/locations/create`, data, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(createLocationSuccess(response.data.message));
                toastr.success("", response.data.message);
                cb && cb();
            }
        } catch (error) {
            dispatch(createLocationFail(error.response.data?.message));
            toastr.error(error.response.data.message);
            if (error.response) {
                dispatch(createLocationFail(error.response.data?.message));
                toastr.error(error.response.data.message);
              }
              else{
                dispatch(createLocationFail("No network connecton"));
                toastr.error("No network connecton");
              }
            setTimeout(() => {
                dispatch(createLocationFail(""));
            }, 3000);
        }
    };

export const editLocation =
    (data: any, cb?: () => void): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(editLocationLoading());
        try {
            const response = await API.post(`/locations/edit`, data, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(editLocationSuccess(response.data.message));
                toastr.success("", response.data.message);
                cb && cb();
            }
        } catch (error) {
            
            if (error.response) {
                dispatch(editLocationFail(error.response.data.message));
                toastr.error(error.response.data.message);
              }
              else{
                dispatch(editLocationFail("No network connecton"));
                toastr.error("No network connecton");
              }
            setTimeout(() => {
                dispatch(editLocationFail(""));
            }, 3000);
        }
    };

export const deleteLocation =
    (data?: any, cb?: () => void): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(deleteLocationLoading());
        try {
            const response = await API.post(`/locations/delete`, data, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(deleteLoactionSuccess(response.data.message));
                toastr.success("", response.data.message);
                cb && cb();
            }
        } catch (error) {
            dispatch(deleteLoactionFail(error.response.data.message));
            toastr.error(error.response.data.message);
            setTimeout(() => {
                dispatch(deleteLoactionFail(""));
            }, 3000);
        }
    };

export default reducer;
