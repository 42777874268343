import StatsCard from "components/StatsCard";
import docImg from "assests/icons/buyer/Purchase Orders/Group (3).svg";
import bill from "assests/icons/buyer/Purchase Orders/bill (1) 2.svg";
import money from "assests/icons/buyer/Purchase Orders/money 1.svg";
import redcircle from "assests/icons/buyer/Purchase Orders/Group (4).svg";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";

import "./styles.scss";
import { getSingleDocument } from "store/slice/BuyerSlice/DocumentSlice/DocumentSlice";
import DoughnutChart from "components/Charts/DonutChart";
import { getSingleGoods } from "store/slice/BuyerSlice/GoodsSlice/GoodsSlice";
import { formatCurrency } from "utils/utils";
import { getReverse, reverseGrsAction } from "store/slice/BuyerSlice/ReverseSlice/reverseSlice";

const SingleDocument = () => {
  const [pageNum, setPageNumber] = useState(10);

  //   helpers
  const dispatch = useDispatch();
  let { id }: any = useParams();
  const history = useHistory();

  //   States
  const { singleGoods } = useSelector((state: RootState) => state.goods);
  const { singleDoc } = useSelector((state: RootState) => state.document);
  const { loading } = useSelector((state: RootState) => state.reverse);
  const data = singleGoods?.data[0];

  useEffect(() => {
    if (id) {
      dispatch(getSingleDocument(id));
      dispatch(getSingleGoods(id));
    } else history.push("/buyer/purchase-orders");
  }, []);

  const reverseFunction = () => {
    dispatch(
      reverseGrsAction(id, () => {
        dispatch(getReverse());
        dispatch(getSingleDocument(id));
        dispatch(getSingleGoods(id));
        history.go(-1);
      })
    );
  };

  const color = ["#1F78B4", "#A6CEE3"];
  const chartVal = (data?.gr_quantity / data?.purchase_order_quantity).toFixed(2) as any;
  const chartData = [
    { value: chartVal * 100, name: `Delivered ${chartVal * 100}%` },
    { value: ((1 - chartVal) * 100).toFixed(2), name: `Yet to Deliver ${((1 - chartVal) * 100).toFixed(2)}%` }
  ];
  // console.log((chartVal * 100).toFixed(2), "chart val");
  // console.log(chartVal, "chartval");
  // console.log(((1 - chartVal) * 100).toFixed(2), "chartval -");

  // const checkFiles = [""];

  return (
    <div id="document-single">
      <div className="container">
        {/* <h3 className="header">Transaction Stats</h3>
        <div className="stats-card mt-4 mb-5 row">
          <StatsCard text="Value (Amount)" classes="stats-icon-blue" image={money} statsNumber={10} />
          <StatsCard text="Vendor Evaluated" classes="stats-icon-blue" image={docImg} statsNumber={450000} />
          <StatsCard text="Invoice Submitted" classes="stats-icon-blue" image={bill} statsNumber={7287} />
          <StatsCard text="Defects Reported" classes="stats-icon-red" image={redcircle} statsNumber={712} />
        </div> */}
        <div className="d-flex">
          <div className="d-flex align-items-center  w-100" style={{ justifyContent: "space-between" }}>
            <div className="cover_all">
              <h5 className="page-title">
                Purchase Order.: {data?.purchase_order_code} - {data?.po_item_id}:
              </h5>
              <p className="">&nbsp;{data?.gr_text}</p>
            </div>
            <button
              type="button"
              style={{
                padding: "8px 30px",
                background: " #F85A5A",
                borderRadius: "10px",
                color: "white"
              }}
              onClick={() => reverseFunction()}
            >
              {loading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Reverse"}
            </button>
            {/* <span className="button-dupli">Delivered</span> */}
          </div>
          {/* <div className="d-flex justify-content-center align-items-center mb-3" style={{ columnGap: "2rem" }}>
            <button
              type="button"
              disabled={checkFiles?.includes("-1")}
              style={{
                padding: "8px 30px",
                background: " #F85A5A",
                borderRadius: "10px",
                color: "white"
              }}
              onClick={() => setRejectModal(true)}
            >
              {loading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Reject"}
              Save as Draft
            </button>
            <button
              type="submit"
              disabled={checkFiles?.includes("-1") || vendor === ""}
              style={
                checkFiles?.includes("-1")
                  ? {
                      padding: "8px 30px",
                      background: "#D8D8D8",
                      borderRadius: "10px",
                      color: "white"
                    }
                  : {
                      padding: "8px 30px",
                      background: "#00B0A5",
                      borderRadius: "10px",
                      color: "white"
                    }
              }
              onClick={approve}
            >
              {loading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Approve"}
              Publish
            </button>
          </div> */}
        </div>
        <hr className="pb-8" />
        <div className="row mb-5">
          <div className="col-md-4 col-lg-4 col-xs-12">
            <div className="mb-3">
              <p className="page-title">Created On:</p>
              <p>{data?.gr_date}</p>
              {/* <p>{format(new Date(data?.purchase_order_date), "dd.MM.yyyy")}</p> */}
            </div>
            <div className="mb-3">
              <p className="page-title">Legal Entity:</p>
              <p>{data?.purchase_order_details[0]?.purchase_order_code}</p>
            </div>
            <div className="mb-3">
              <p className="page-title">Goods Received Qty:</p>
              <p>{data?.gr_quantity}</p>
            </div>
          </div>
          <div className="col-md-4 col-lg-4 col-xs-12">
            <div className="mb-3">
              <p className="page-title">Vendor Description:</p>
              <p>{data?.purchase_order_details[0]?.vendor_description}</p>
              {/* <p>{format(new Date(data?.delivery_date), "dd.MM.yyyy")}</p> */}
            </div>
            <div className="mb-3">
              <p className="page-title">Amount</p>
              <p>{`${data?.currency_description} ${formatCurrency(data?.gr_amount)}`}</p>
            </div>
            <div className="mb-3">
              <p className="page-title">Recipient</p>
              <p>{data?.gr_recipient}</p>
            </div>
          </div>
          <div className="col-md-4 col-lg-4 col-xs-12">
            <DoughnutChart data={chartData} color={color} name="" />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-10">
            <table>
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Location</th>
                  <th>Delivery Date</th>
                  <th>Goods Receipt:</th>
                  {/* <th>Currency</th> */}
                  {/* <th>UoM</th> */}
                  <th>Material Description</th>
                  <th>Ordered Qty</th>
                  <th>Delivered Qty</th>
                </tr>
              </thead>
              <tbody>
                {data?.purchase_order_details?.map((mat, i) => (
                  <tr key={i} className="border-container mb-3">
                    <td className="first-td">{i + 1}</td>
                    <td>{mat?.location_description}</td>
                    <td>{mat?.delivery_date}</td>
                    <td>{data?.gr_code}</td>
                    {/* <td>{mat?.currency_description}</td> */}
                    {/* <td>{mat?.uom_description}</td> */}
                    <td className="">{data?.material_description}</td>
                    {/* <td className="">{mat?.vendor_description}</td> */}
                    <td className="">{data?.purchase_order_quantity}</td>
                    <td className="last-td">{data?.gr_quantity}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* <div className="mb-4">
          <p>Transaction History</p>
          <hr />
        </div> */}

        {/* {data?.rfp.length > 0 && (
          <div className="row mb-3">
            <div className="col-md-10 col-xs-12">
              <div className="row border-container justify-content-between">
                <span className="d-flex">
                  <div className="px-3" style={{ width: "fit-content" }}>
                    <p className="">RFP</p>
                  </div>
                  <div className="px-3" style={{ width: "fit-content" }}>
                    <p className="">{data?.rfp[0] ? data?.rfp[0]?.rfp_code : "N?A"}</p>
                  </div>
                </span>

                <span className="d-flex">
                  <div className="px-3" style={{ width: "fit-content" }}>
                    <p className="">Created By : {`${data?.rfp[0]?.created_by_othernames} ${data?.rfp[0]?.created_by_lastname}`}</p>
                  </div>
                  <div className="px-3" style={{ width: "fit-content" }}>
                    <p className="">Creation Date : {data?.rfp[0]?.created_at ? format(new Date(data?.rfp[0]?.created_at), "dd.MM.yyyy") : ""}</p>
                  </div>
                </span>
              </div>
            </div>
          </div>
        )}

        {data?.requisition && (
          <div className="row mb-3">
            <div className="col-md-10 col-xs-12">
              <div className="row border-container justify-content-between">
                <span className="d-flex">
                  <div className="px-3" style={{ width: "fit-content" }}>
                    <p className="">Purchase Approval</p>
                  </div>
                  <div className="px-3" style={{ width: "fit-content" }}>
                    <p className="">{data?.requisitions?.requisition_code ? data?.requisitions?.requisition_code : "N?A"}</p>
                  </div>
                </span>

                <span className="d-flex">
                  <div className="px-3" style={{ width: "fit-content" }}>
                    <p className="">Created By : {`${data?.requisitions?.created_by_othernames} ${data?.requisitions?.created_by_lastname}`}</p>
                  </div>
                  <div className="px-3" style={{ width: "fit-content" }}>
                    <p className="">Creation Date : {data?.requisitions?.created_at ? format(new Date(data?.requisitions?.created_at), "dd.MM.yyyy") : ""}</p>
                  </div>
                </span>
              </div>
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default SingleDocument;
