import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { purchaseGroupStates, Action } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";

const initialState: purchaseGroupStates = {
    loading: false,
    deleting: false,
    isLoading: false,
    purchaseGroup: null,
    successMessage: "",
    errorMessage: ""
};

const purchaseGroupSlice = createSlice({
    name: "purchaseGroup",
    initialState,
    reducers: {
        getAllPurchaseGroupLoading: (state) => {
            state.loading = true;
        },
        getAllPurchaseGroupSuccess: (state, action: PayloadAction<Action>) => {
            state.loading = false;
            state.purchaseGroup = action.payload;
        },
        getAllPurchaseGroupFail: (state) => {
            state.loading = false;
        },
       createPurchaseGroupLoading: (state) => {
            state.isLoading = true;
        },
       createPurchaseGroupSuccess: (state, action: PayloadAction<Action>) => {
            state.isLoading = false;
            state.purchaseGroup = action.payload;
        },
       createPurchaseGroupFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
        editPurchaseGroupLoading: (state) => {
            state.isLoading = true;
        },
        editPurchaseGroupSuccess: (state, action: PayloadAction<Action>) => {
            state.isLoading = false;
            state.purchaseGroup = action.payload;
        },
        editPurchaseGroupFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
        deletePurchaseGroupLoading: (state) => {
            state.deleting = true;
        },
        deletePurchaseGroupSuccess: (state, action: PayloadAction<Action>) => {
            state.deleting = false;
            state.purchaseGroup = action.payload;
        },
        deletePurchaseGroupFail: (state, action: PayloadAction<string>) => {
            state.deleting = false;
            state.errorMessage = action.payload;
        },
    }
});

const { actions, reducer } = purchaseGroupSlice;
export const {
    getAllPurchaseGroupLoading,
    getAllPurchaseGroupSuccess,
    getAllPurchaseGroupFail,
   createPurchaseGroupLoading,
   createPurchaseGroupSuccess,
   createPurchaseGroupFail,
    editPurchaseGroupLoading,
    editPurchaseGroupSuccess,
    editPurchaseGroupFail,
    deletePurchaseGroupLoading,
    deletePurchaseGroupSuccess,
    deletePurchaseGroupFail
} = actions;

export const getPurchaseGroup =
    (
        page?: number,
        length?: number,
        search?: string,
        purchaseGroup_code?: string,
        purchasing_group_status?:any
    ): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(getAllPurchaseGroupLoading());
        try {
            const response = await API.get("/purchasing_groups/get", {
                params: {
                    page,
                    length,
                    search,
                    purchaseGroup_code,
                    purchasing_group_status,
                },
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(getAllPurchaseGroupSuccess(response.data));
            }
        } catch (error) {
            dispatch(getAllPurchaseGroupFail());
        }
    };

export const createPurchaseGroup =
    (data: any, cb?: () => void): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(createPurchaseGroupLoading());
        try {
            const response = await API.post(`/purchasing_groups/create`, data, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(createPurchaseGroupSuccess(response.data.message));
                toastr.success("", response.data.message);
                cb && cb();
            }
        } catch (error) {
            
            if (error.response) {
                dispatch(createPurchaseGroupFail(error.response.data?.message));
                toastr.error(error.response.data.message);
              }
              else{
                dispatch(createPurchaseGroupFail("No network connecton"));
                toastr.error("No network connecton");
              }
            setTimeout(() => {
                dispatch(createPurchaseGroupFail(""));
            }, 3000);
        }
    };

export const editPurchaseGroup =
    (data: any, cb?: () => void): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(editPurchaseGroupLoading());
        try {
            const response = await API.post(`/purchasing_groups/edit`, data, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(editPurchaseGroupSuccess(response.data.message));
                toastr.success("", response.data.message);
                cb && cb();
            }
        } catch (error) {
            
            if (error.response) {
                dispatch(editPurchaseGroupFail(error.response.data.message));
                toastr.error(error.response.data.message);
              }
              else{
                dispatch(editPurchaseGroupFail("No network connecton"));
                toastr.error("No network connecton");
              }
            setTimeout(() => {
                dispatch(editPurchaseGroupFail(""));
            }, 3000);
        }
    };

    export const deletePurchaseGroup =
    (data?: any, cb?: () => void): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        const { userToken } = getState().user;
        dispatch(deletePurchaseGroupLoading());
        try {
            const response = await API.post(`/purchasing_groups/delete`, data, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(deletePurchaseGroupSuccess(response.data.message));
                toastr.success("", response.data.message);
                cb && cb();
            }
        } catch (error) {
            dispatch(deletePurchaseGroupFail(error.response.data.message));
            toastr.error(error.response.data.message);
            setTimeout(() => {
                dispatch(deletePurchaseGroupFail(""));
            }, 3000);
        }
    };


export default reducer;
