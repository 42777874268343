import * as React from "react";
import "./styles.scss";

type TabProps = {
    menus: any[];
    classes?: string;
    onSelect?: (name: string) => void;
};

const Tabs: React.FC<TabProps> = ({ menus, classes, onSelect }) => {
    const [active, setActive] = React.useState<string>(menus[0]);

    const toggleTab = (name: string): void => {
        setActive(name);
        onSelect && onSelect(name);
    };

    return (
        <div className={`tabs-menu ${classes}`}>
            <ul className="d-flex flex-wrap">
                {menus &&
                    menus.map((menu) => {
                        // console.log(menu);

                        return (
                            <li
                                key={Math.random() * 1000}
                                onClick={() => toggleTab(menu)}
                                className={`item ${active === menu && "item--active"}`}
                            >
                                {menu}
                            </li>
                        );
                    })}
            </ul>
        </div>
    );
};

export default Tabs;
