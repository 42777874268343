/* eslint-disable react-hooks/exhaustive-deps */
import StatsCard from "components/StatsCard";
import React, { useEffect, useState } from "react";
import "../styles.scss";
import LocationIcon from "assests/icons/buyer/group-box.svg";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import SearchIcon from "components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { exchangeRateColumn } from "../data";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import useSearch from "hooks/useSearch";
import Pagination from "components/Pagination";
import { toastPopUp } from "utils/utils";

import CreateOrEditExchange from "components/ModalBuyer/CreateOrEditExchange";
import { deleteExchangeRate, getExchageRate } from "store/slice/BuyerSlice/ExhangeRate/ExchangeRateSlice";
import moment from "moment";

const ExchangeRate = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [modal, setModal] = useState<boolean>(false);
  const [singleExchange, setSingleExchange] = useState<any>(null);

  const closeModal = () => {
    setModal((prev) => !prev);
    setSingleExchange(null);
  };

  //Redux
  const dispatch = useDispatch<AppDispatch>();
  const { exchange_rate, loading } = useSelector((state: RootState) => state.exchange_rate);

  //Hook
  const { search, inputRef, handleChange, searched } = useSearch(getExchageRate, perPage);

  // get material categories
  useEffect(() => {
    dispatch(getExchageRate(page, perPage));
  }, []);

  useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  //Show Number for PerPage Request
  const handleNewPage = (pageNo: number, page: number = 1) => {
    setPerPage(pageNo);
    setPage(1);
    dispatch(getExchageRate(page, pageNo, search));
  };

  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, perPage: number) => {
      setPage(page);
      dispatch(getExchageRate(page, perPage, search));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, perPage: number) => {
      setPage(pageNo);
      dispatch(getExchageRate(pageNo, perPage, search));
    },
    [search]
  );

  //Edit DepartmentData
  const editExchange = (id: string, currency_from: string, currency_to: string, amount: string) => {
    console.log(id, currency_from, currency_to, amount);
    setSingleExchange({ id, currency_from, currency_to, amount });
    setModal((prev) => !prev);
  };

  //  delete
  const deleteExchange2 = (name: string, id: string) => {
    const payload = { exchange_rate_id: id };

    toastPopUp(
      () => {
        dispatch(
          deleteExchangeRate(payload, () => {
            dispatch(getExchageRate(page, perPage));
          })
        );
      },
      "Delete",
      "Cancel",
      "Delete this exchange rate",
      name,
      "red"
    );
  };

  const elementsList =
    exchange_rate &&
    exchange_rate?.data?.map((item) => {
      return {
        id: item.id,
        currency_from: item.from_currency_code,
        currency_to: item.to_currency_code,
        amount: item.exchange_rate,
        date: moment(item.created_at).format("l"),
        edit: () => editExchange(item.id, item.from_currency_code, item.to_currency_code, item.exchange_rate),
        deleteExchangeRate: () => deleteExchange2(item.currency_from, item.id)
      };
    });

  return (
    <div id="settings-material-data">
      {modal && <CreateOrEditExchange closeModal={closeModal} singleExchange={singleExchange} />}
      <div className="container mt-3">
        <div className="row mt-3 mb-5">
          <StatsCard statsNumber={exchange_rate?.count ?? 0} text="Cost Element" classes="stats-icon" image={LocationIcon} iconBackgroundColor="rgba(45, 156, 219, 0.1)" />
          <StatsCard image={AddIcon} classes="middle-img" centerText="Create" onClick={closeModal} />
        </div>

        <div className="d-flex  mb-3">
          <SearchIcon placeholder="Search" boxClasses="" textRef={inputRef} value={search} onChange={handleChange} />
          <Dropdown perPage={perPage} action={handleNewPage} />
        </div>
        <DataTable columns={exchangeRateColumn} data={elementsList} loading={loading} />

        <Pagination page={page} lastPage={exchange_rate?.pagination?.pageCount} paginate={paginate} total={exchange_rate?.pagination?.total} handlePageChange={handlePageChange} perPage={perPage} />
      </div>
    </div>
  );
};

export default ExchangeRate;
