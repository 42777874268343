import React from "react";
import "./styles.scss";
import { Link } from "react-router-dom";
import Button from "components/Button";
import DropdownMenu from "components/DropdownMenu";


export const tableCol = [
    {
        name: "Loading Date",
        index: "loading_date",
        id: 1
    },
    {
        name: "Delivery Number",
        index: "delivery_number",
        id: 2
    },
    {
        name: "Customer Ref",
        index: "customer_ref",
        id: 3
    },
    {
        name: "External Ref. ID",
        index: "external_ref",
        id: 4
    },
    {
        name: "Truck Number",
        index: "truck_number",
        id: 5
    },
    {
        name: "Employee",
        index: "employee",
        id: 6
    },
    {
        name: "Status",
        id: 7,
        render: (item) => { 

            let stat;
            if (item.statusNumber==="2") {
                stat = "pod"
            };

            return(
                <div className="cover">

              {
                 stat === "pod" ? <span>{ <button onClick={item.upload} className="verified">Upload Pod</button>
                        }</span>
                        : 
                        <span>
                         <button className={`pending ${item.theme}`}>{item.status}</button>
                        </span>


                        // <span>{item.status === "1" ? <button className="verified">Verified</button>
                        // : <button className="pending">Pending</button>
                        // }</span>
                    }

                </div>
           
            
            
            )
        
        
        }
      },
      {
        name: "POD",
        id: 8,
        render: (item) => { 

            let stat;
            if (item.statusNumber==="3" || item.statusNumber==="4" || item.statusNumber==="5") {
                stat = "pod"
            };

            return(
                <div className="cover">

              {
                 stat === "pod" ? <span>{ <button onClick={item.viewPod} className="verified">View POD</button>
                        }</span>
                        : 
                        <span>
                         <button className={`pending`}>No POD</button>
                        </span>


                        // <span>{item.status === "1" ? <button className="verified">Verified</button>
                        // : <button className="pending">Pending</button>
                        // }</span>
                    }

                </div>
           
            
            
            )
        
        
        }
      },
    //   {
    //     name: "Actions",
    //     id: 8,
    //     render: ({ deleteBill, edit }) => {
    //         const settings = [
    //             {
    //                 name: "Edit",
    //                 style: "edit_tag",
    //                 select: edit
    //             },
    //             {
    //                 name: "Delete",
    //                 style: "delete_tag",
    //                 select: deleteBill
    //             }
    //         ];
    //         return (
    //             <span style={{ position: "relative" }}>
    //                 <DropdownMenu options={settings} boxClasses="dropdown-style">
    //                     <p style={{ fontSize: "2rem" }} className="cursor-pointer">
    //                         ...
    //                     </p>
    //                 </DropdownMenu>
    //             </span>
    //         );
    //     }
    // }
];
