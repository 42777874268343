import React, { useEffect, useState } from "react";
import Modal from "components/Modal";
import Textbox from "components/Textbox";
import Button from "components/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import "./styles.scss";
import { useForm } from "react-hook-form";
import { doverifyVendorFile, getSingleVendor } from "store/slice/BuyerSlice/VendorSlice/VendorSlice";
import { creteLineItems, editLineItems, getInvoice } from "store/slice/BuyerSlice/Invoice/InvoiceSlice";

type Props = {
  closeModal: () => void;
  loading: boolean;
  id: string;
  lineItems: any;
};
const CreateLineItem = ({ closeModal, loading, id, lineItems }: Props) => {
  //Redux Store
  const dispatch = useDispatch();
  const { handleSubmit, register, errors, setValue, watch } = useForm<FormData>({
    mode: "onBlur"
  });
  const { isEdit, line_item_description, item_invoice_amount, line_item_quantity, line_item_tax_value } = lineItems as any;
  const values = watch();

  useEffect(() => {
    if (isEdit) {
      setValue("line_item_description", line_item_description);
      setValue("item_invoice_amount", item_invoice_amount / line_item_quantity);
      setValue("line_item_quantity", line_item_quantity);
      if (Number(line_item_tax_value) > 0) {
        setValue("line_item_tax_value", "1");
      } else setValue("line_item_tax_value", "0");
    }
  }, [isEdit]);

  type FormData = {
    line_item_description: string;
    item_invoice_amount: number;
    line_item_quantity: number;
    line_item_tax_value: number;
  };

  // console.log(lineItems);

  const onFormSubmit = (vals) => {
    const payload = {
      ...vals,
      item_invoice_amount: vals.item_invoice_amount * vals.line_item_quantity
    };

    if (isEdit) {
      dispatch(
        editLineItems({ ...payload, id: lineItems.id }, () => {
          dispatch(getInvoice(id));
          closeModal();
        })
      );
      return;
    }

    dispatch(
      creteLineItems({ ...payload, invoice_id: id }, () => {
        dispatch(getInvoice(id));
        closeModal();
      })
    );
  };
  console.log(errors.line_item_tax_value);
  return (
    <Modal modalText={isEdit ? "Edit Line Item" : "Create Line Item"} onClose={closeModal} boxClasses="role-modal-top">
      <div className="verify-file-modal">
        {/* <p className="">Verify Document and append Expiry Date if Required.</p> */}
        <form className="mt-4" onSubmit={handleSubmit(onFormSubmit)}>
          {/* <p className="mb-3 yaraa-color">Document Name</p> */}
          {/* <p>{docName}</p> */}
          <Textbox
            type="text"
            name="line_item_description"
            label="Description"
            boxClasses="mt-3"
            placeholder="Description"
            customRef={register({
              required: true
            })}
            error={errors.line_item_description && "This field is required"}
          />
          <Textbox
            type="number"
            name="item_invoice_amount"
            label="Amount"
            boxClasses="mt-3"
            placeholder="Amount"
            customRef={register({
              required: true
            })}
            error={errors.item_invoice_amount && "This field is required"}
            step="any"
          />
          <Textbox
            type="number"
            name="line_item_quantity"
            label="Quantity"
            boxClasses="mt-3"
            placeholder="Quantity"
            customRef={register({
              required: true
            })}
            error={errors.line_item_quantity && "This field is required"}
            step="any"
          />
          {/* <Textbox
            type="number"
            name="line_item_tax_value"
            label="Tax"
            boxClasses="mt-3"
            placeholder="Tax Amount"
            customRef={register({
              required: true
            })}
            error={errors.line_item_tax_value && "This field is required"}
          /> */}
          <p className="mb-1 mt-4">Add Tax</p>
          <div className="d-flex radio-box" style={{ columnGap: "2rem" }}>
            <div className="">
              <input
                type="radio"
                name="line_item_tax_value"
                id="yes"
                className="mr-2"
                ref={register({
                  required: { value: true, message: "This is required" }
                  // required: true
                })}
                value={1}
                defaultChecked={values.line_item_tax_value === 1}
              />
              <label className="radio-box_label" htmlFor="yes">
                Yes
              </label>
            </div>
            <div className="">
              <input
                type="radio"
                name="line_item_tax_value"
                id="no"
                className="mr-2"
                ref={register({
                  // required: true
                  required: { value: true, message: "This is required" }
                })}
                value={0}
                defaultChecked={values.line_item_tax_value === 0}
              />
              <label className="radio-box_label" htmlFor="no">
                No
              </label>
            </div>
          </div>
          {errors.line_item_tax_value && <p className="error">This field is required</p>}
          <Button label="Submit" btnType="btn-primary" boxClasses="mt-4 mb-5" btnStyle="100%" loading={loading} disabled={loading} />
        </form>
        <p className="role-p" onClick={closeModal}>
          Cancel
        </p>
      </div>
    </Modal>
  );
};

export default CreateLineItem;
