import React, { useEffect, useState } from "react";
import Modal from "components/Modal";
import Textbox from "components/Textbox";
import Button from "components/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import "./styles.scss";
import { useForm } from "react-hook-form";
import { createVendor, doverifyVendorFile, getSingleVendor } from "store/slice/BuyerSlice/VendorSlice/VendorSlice";
import { getCompaniesEntityList } from "store/slice/CompaniesSlice/CompaniesSlice";
import SelectDropdown from "components/SelectDropdown";
import { getAllCountries } from "store/slice/CountriesSlice/CountriesSlice";
import { getAllStates } from "store/slice/CountryStatesSlice/StateSlice";
import { getAllCities } from "store/slice/CitySlice/CitySlice";
import { getAllBusinessCategory } from "store/slice/BusinessCategorySlice/BusinessCategorySlice";

type Props = {
  closeModal: () => void;
};
const CreateVendor = ({ closeModal }: Props) => {
  //Redux Store
  const dispatch = useDispatch();
  const { handleSubmit, register, errors, watch, setValue } = useForm<FormData>({
    mode: "onBlur"
  });
  const { loading } = useSelector((state: RootState) => state.vendorDetails);
  const { companies } = useSelector((state: RootState) => state.companies);
  const { allBusinessCategory } = useSelector((state: RootState) => state.businessCategory);
  const { countries } = useSelector((state: RootState) => state.country);
  const { states } = useSelector((state: RootState) => state.states);
  const { cities } = useSelector((state: RootState) => state.city);

  // const [data, setData] = useState<any>();
  const values = watch();

  type FormData = {
    vendor_code: number;
    othernames: string;
    lastname: string;
    email: string;
    phone_number: number;
    company_id: string;
    business_category_id: string;
    country: string;
    state: string;
    city_id: string;
  };

  const onFormSubmit = (val) => {
    let data = val;
    delete data["country"];
    delete data["state"];
    dispatch(createVendor({ ...data, user_role_id: 4000002 }, () => closeModal()));
  };

  useEffect(() => {
    dispatch(getCompaniesEntityList());
    dispatch(getAllCountries());
    dispatch(getAllStates());
    dispatch(getAllCities());
    dispatch(getAllBusinessCategory());
  }, []);

  //Companies array
  const companiesArray =
    companies &&
    companies.map((element: { id: string; company_description: string }) => {
      return {
        value: element?.id,
        optionName: element?.company_description
      };
    });

  //Getting Bussiness Categories
  const businessCategoryOptions =
    allBusinessCategory &&
    allBusinessCategory.map((_data: { id: string; business_category_description: string }) => {
      return {
        value: _data.id,
        optionName: _data.business_category_description
      };
    });

  //Getting Countries
  const countriesOptions =
    countries &&
    countries.map((_data: { country_description: string }) => {
      return {
        value: _data.country_description,
        optionName: _data.country_description
      };
    });

  //Getting States
  const stateOptions = [] as any;
  states &&
    states.forEach((element: { country_description: string; state_name: string }) => {
      if (element.country_description === values.country) {
        stateOptions.push({
          value: element.state_name,
          optionName: element.state_name
        });
      }
    });

  //Getting Cities
  const cityOptions = [] as any;
  cities &&
    cities.forEach((element: { state_name: string; id: string; city_name: string; country_description: string }) => {
      if (element.state_name === values.state && element.country_description === values.country) {
        cityOptions.push({
          value: element.id,
          optionName: element.city_name
        });
      }
    });

  return (
    <Modal modalText="Create Vendor" onClose={closeModal} boxClasses="role-modal-top">
      <div className="create-vendor-modal">
        <p className="">Invite Existing Vendor to On-Board and Grant Portal Access</p>
        <form className="mt-4" onSubmit={handleSubmit(onFormSubmit)}>
          <Textbox
            type="number"
            name="vendor_code"
            customRef={register({ required: true })}
            label="Vendor ID"
            boxClasses="mt-3"
            placeholder="Vendor ID"
            disabled={loading}
            error={errors.vendor_code && "This field is required"}
          />
          <Textbox
            type="text"
            name="othernames"
            customRef={register({ required: true })}
            label="First Name"
            boxClasses="mt-3"
            placeholder="Contact First Name"
            disabled={loading}
            error={errors.othernames && "This field is required"}
          />
          <Textbox
            type="text"
            name="lastname"
            customRef={register({ required: true })}
            label="Last Name"
            boxClasses="mt-3"
            placeholder="Contact Last Name"
            error={errors.lastname && "This field is required"}
            disabled={loading}
          />
          <Textbox
            type="email"
            name="email"
            customRef={register({ required: true })}
            label="Email Address"
            boxClasses="mt-3"
            placeholder="Contact Email Address"
            error={errors.email && "This field is required"}
            disabled={loading}
          />
          <Textbox
            type="number"
            name="phone_number"
            customRef={register({ required: true })}
            label="Phone Number"
            boxClasses="mt-3"
            placeholder="Contact Phone Number"
            disabled={loading}
            error={errors.phone_number && "This field is required"}
          />
          <SelectDropdown
            boxClasses="mt-3"
            name="company_id"
            label="Company"
            placeholder="Select company"
            options={companiesArray}
            customRef={register({ required: true })}
            error={errors.company_id && "This field is required"}
          />
          <SelectDropdown
            boxClasses="mt-3"
            name="business_category_id"
            label="Business Categories"
            placeholder="Business Categories"
            options={businessCategoryOptions}
            customRef={register({ required: true })}
            error={errors.business_category_id && "This field is required"}
          />
          <SelectDropdown
            boxClasses="mt-4"
            name="country"
            label="Country"
            placeholder="Country"
            options={countriesOptions}
            customRef={register({ required: true })}
            error={errors.country && "This field is required"}
            onChange={() => {
              setValue("state", "");
              setValue("city_id", "");
            }}
          />

          <SelectDropdown
            boxClasses="mt-4"
            name="state"
            label="State"
            placeholder="State"
            options={stateOptions}
            customRef={register({ required: true })}
            error={errors.state && "This field is required"}
            onChange={() => {
              setValue("city_id", "");
            }}
          />
          <SelectDropdown
            boxClasses="mt-3"
            name="city_id"
            label="City"
            placeholder="City"
            options={cityOptions}
            customRef={register({ required: true })}
            error={errors.city_id && "This field is required"}
          />
          <textarea
            name="vendor_description"
            id=""
            rows={2}
            className="textarea mt-4"
            placeholder="Company Name"
            ref={register({ required: true })}
          />

          <Button label="Save Details" btnType="btn-primary" boxClasses="mt-4 mb-5" btnStyle="100%" loading={loading} />
        </form>
        <p className="role-p" onClick={closeModal}>
          Cancel
        </p>
      </div>
    </Modal>
  );
};

export default CreateVendor;
