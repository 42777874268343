import * as React from "react";
import "./styles.scss";

type TabSmall = {
    menus: any[];
    classes?: string;
    onSelect?: (name: string) => void;
};

const TabSmall: React.FC<TabSmall> = ({ menus, classes, onSelect }) => {
    const [active, setActive] = React.useState<string>(menus[0].id);

    const toggleTab = (name): void => {
        setActive(name.id);
        onSelect && onSelect(name.id);
    };

    return (
        <div className={`tabs-small-comp ${classes}`}>
            <ul className="flex-wrap">
                {menus &&
                    menus.map((menu, i) => {
                        return (
                            <li
                                key={Math.random() * 1000}
                                onClick={() => toggleTab(menu)}
                                className={`item ${active === menu.id && "item--active"}`}
                            >
                                {menu.name}
                                {menus.length - 1 !== i && (
                                    <span style={{ margin: "0px 20px" }} className="border-right"></span>
                                )}
                            </li>
                        );
                    })}
            </ul>
        </div>
    );
};

export default TabSmall;
