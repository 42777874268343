import DropdownMenu from "components/DropdownMenu";
import "./styles.scss";

export const productCateoryColumn = [
  {
    name: "ID",
    index: "id",
    id: 1
  },
  {
    name: "Category Name",
    index: "name",
    id: 2
  },
  {
    name: "ERP ID",
    index: "code",
    id: 3
  },
  {
    name: "Actions",
    id: 4,
    render: ({ deleteCategoryData, edit }) => {
      const settings = [
        {
          name: "Edit",
          style: "edit_tag",
          select: edit
        },
        {
          name: "Delete",
          style: "delete_tag",
          select: deleteCategoryData
        }
      ];
      return (
        <span style={{ position: "relative" }}>
          <DropdownMenu options={settings} boxClasses="dropdown-style">
            <p style={{ fontSize: "2rem" }} className="cursor-pointer">
              ...
            </p>
          </DropdownMenu>
        </span>
      );
    }
  }
];

export const productDataColumn = [
  {
    name: "SKU ID",
    index: "id",
    id: 1
  },
  {
    name: "Picture",
    index: "name",
    id: 2
  },
  {
    name: "Product Name",
    index: "product_name",
    id: 3
  },
  {
    name: "UoM",
    index: "uom",
    id: 4
  },
  {
    name: "Product Category",
    index: "category",
    id: 5
  },
  {
    name: "Product type",
    index: "type",
    id: 6
  },
  {
    name: "Price",
    index: "price_per_unit",
    id: 7
  },
  {
    name: "Actions",
    id: 8,
    render: ({ deleteProduct, edit }) => {
      const settings = [
        {
          name: "Edit",
          style: "edit_tag",
          select: edit
        },
        {
          name: "Delete",
          style: "delete_tag",
          select: deleteProduct
        }
      ];
      return (
        <span style={{ position: "relative" }}>
          <DropdownMenu options={settings} boxClasses="dropdown-style">
            <p style={{ fontSize: "2rem" }} className="cursor-pointer">
              ...
            </p>
          </DropdownMenu>
        </span>
      );
    }
  }
];
