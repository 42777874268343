import { toastr } from "react-redux-toastr";
import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MgtState, Action, CreateUserPayload } from "./interface";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState: MgtState = {
  isLoading: false,
  users: null,
  singleUser: null,
  success: "",
  errorMessage: ""
};

const userMgtSlice = createSlice({
  name: "userMgt",
  initialState,
  reducers: {
    getUserLoading: (state) => {
      state.isLoading = true;
    },
    getUserSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.users = action.payload;
    },
    getUserFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
    },
    getSingleUserLoading: (state) => {
      state.isLoading = true;
    },
    getSingleUserSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.singleUser = action.payload;
    },
    getSingleUserFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
    },
    createUserLoading: (state) => {
      state.isLoading = true;
    },
    createUserSuccess: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    createUserFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    deleteUserLoading: (state) => {
      state.isLoading = true;
    },
    deleteUserSuccess: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    deleteUserFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    editUserLoading: (state) => {
      state.isLoading = true;
    },
    editUserSuccess: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    editUserFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    activateUserLoading: (state) => {
      state.isLoading = true;
    },
    activateUserSuccess: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    activateUserFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    deactivateUserLoading: (state) => {
      state.isLoading = true;
    },
    deactivateUserSuccess: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    deactivateUserFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    }
  }
});

const { actions, reducer } = userMgtSlice;

export const {
  getUserLoading,
  getUserSuccess,
  getUserFail,
  createUserLoading,
  createUserSuccess,
  createUserFail,
  getSingleUserLoading,
  getSingleUserSuccess,
  getSingleUserFail,
  deleteUserFail,
  deleteUserLoading,
  deleteUserSuccess,
  editUserFail,
  editUserLoading,
  editUserSuccess,
  activateUserLoading,
  activateUserFail,
  activateUserSuccess,
  deactivateUserLoading,
  deactivateUserSuccess,
  deactivateUserFail
} = actions;

type vendType = {
  page?: number;
  length?: number;
  search?: string;
  user_type?: string;
  sortBy?: string;
  orderBy?: string;
  id?: any;
};

export const getUsersSupplier =
  ({ page, length, search, sortBy, orderBy, id }: vendType): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getUserLoading());
    try {
      const response = await axios.get(`${BASE_URL}/users/get`, {
        params: {
          page,
          length,
          search,
          vendor_id: id,
          user_type: "Vendor",
          sortBy,
          admin_exception: "yes",
          orderBy
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if (response.status) {
        dispatch(getUserSuccess(response.data));
      }
    } catch (error) {
      dispatch(getUserFail("Fail"));
    }
  };

export const getUsers =
  (page?: number, length?: number, search?: string, user_type?: string, sortBy?: string, orderBy?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getUserLoading());
    try {
      const response = await axios.get(`${BASE_URL}/users/get`, {
        params: {
          page,
          length,
          search,
          user_type,
          sortBy,
          orderBy
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if (response.status) {
        dispatch(getUserSuccess(response.data));
      }
    } catch (error) {
      dispatch(getUserFail("Fail"));
    }
  };

export const getSingleUser =
  (id: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getSingleUserLoading());
    try {
      const response = await axios.get(`${BASE_URL}/users/get/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if (response.status) {
        dispatch(getSingleUserSuccess(response.data));
      }
    } catch (error) {
      dispatch(getSingleUserFail("Fail"));
    }
  };

export const createUser =
  (payload: CreateUserPayload, cb: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(createUserLoading());
    const { userToken } = getState().user;
    try {
      const response = await axios.post(`${BASE_URL}/users/create`, payload, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(createUserSuccess(response.data.message));
        toastr.success("", response.data.message);
        dispatch(getUsers());
        cb();
      }
    } catch (error) {
      dispatch(createUserFail(error?.response?.data?.message));
      toastr.error(error?.response?.data.message);
    }
  };
export const editUser =
  (data: any, id, cb: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(editUserLoading());
    try {
      const response = await axios.post(`${BASE_URL}/users/edit`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if (response.status) {
        dispatch(editUserSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb();
      }
    } catch (error) {
      dispatch(editUserFail(error.response.data.error));
      toastr.error(error.response.data.error);
    }
  };

export const deleteUser =
  (id: string, cb: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(deleteUserLoading());
    try {
      const response = await axios.post(`${BASE_URL}/users/delete/${id}`, null, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if (response.status) {
        dispatch(deleteUserSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb();
      }
    } catch (error) {
      dispatch(deleteUserFail(error.response.data.error));
      toastr.error(error.response.data.error);
    }
  };

export const deactivateUser =
  (id: any, cb: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    // const data = { status: "inactive" };
    const { userToken } = getState().user;
    dispatch(deactivateUserLoading());
    var form_data = new FormData();
    for (var key in id) {
      form_data.append(key, id[key]);
    }
    try {
      const response = await axios.post(`${BASE_URL}/users/deactivate`, form_data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if (response.status) {
        dispatch(deactivateUserSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb();
      }
    } catch (error) {
      dispatch(deactivateUserFail(error.response.data.message));
      toastr.error(error.response.data.message);
      console.log(error);
    }
  };

export const activateUser =
  (id, cb: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    // const data = { status: "active" };
    dispatch(activateUserLoading());
    var form_data = new FormData();
    for (var key in id) {
      form_data.append(key, id[key]);
    }
    try {
      const response = await axios.post(`${BASE_URL}/users/activate`, form_data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if (response.status) {
        dispatch(activateUserSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb();
      }
    } catch (error) {
      dispatch(activateUserFail(error.response.data.message));
      toastr.error(error.response.data.message);
    }
  };
export default reducer;
