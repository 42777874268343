import "./styles.scss";
import GziLogo from "assests/images/gziLogo.png";

type LogoProps = {
    boxClasses?: string;
    classes?: string;
    small?: boolean;
};

const GZILogo = ({ boxClasses, classes, small }: LogoProps) => {
    if (small) {
        return (
            <div className="logoWrapper d-flex flex-column align-items-center">
                <img src={GziLogo} alt="logo" className="logoWrapper_logo_small" />
                <div className="logoWrapper_banner_small">
                    <p className="text-center ">Supplier Portal</p>
                </div>
            </div>
        );
    } else {
        return (
            <div className={`logoWrapper d-flex flex-column align-items-center `}>
                <img src={GziLogo} alt="logo" className={`logoWrapper_logo ${boxClasses}`} />
                <div className={`logoWrapper_banner ${classes}`}>
                    <p className="text-center ">Supplier Portal</p>
                </div>
            </div>
        );
    }
};

export default GZILogo;
