import FileUploaderBox from "components/FileUploaderBox";
import SelectDropdown from "components/SelectDropdown";
import Textbox from "components/Textbox";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { toastr } from "react-redux-toastr";
import { formatCurrency } from "utils/utils";
import { useState } from "react";
type Props = {
  fields: any;
  bid?: any;
  responses: any;
  currency: object;
  responseStat: boolean;
  updateComm: () => void;
};

const CommercialRequirements = ({ fields, responses, bid, responseStat, updateComm, currency }: Props) => {
  const { register, handleSubmit, errors, setValue, getValues, watch, control } = useForm<FormData>({ mode: "onBlur" });
  const values = watch();
  const dispatch = useDispatch<AppDispatch>();
  const materials = bid && bid[0].materials;
  console.log(materials, "mat");
  console.log(fields, "fields");
  // const renderOptions = (options: string) => {
  //   const array = JSON.parse(options);
  //   return array.map((_data: { option_value: string; option_title: string }) => {
  //     return {
  //       value: _data.option_value,
  //       option_title: _data.option_title
  //     };
  //   });
  // };

  const [sortField, setSortField] = useState<any>(null);
  const [sortMaterial, setSortMaterial] = useState<any>(null);

  useEffect(() => {
    if (fields) {
      const sortedField = fields?.filter((field) => {
        return field.name.includes("price");
      });

      const arrayField = [...sortedField].sort((a, b) => {
        let fa = a.label.toLowerCase();
        let fb = b.label.toLowerCase();
        console.log(fa, fb);
        if (fa < fb) {
          return -1;
        } else if (fa > fb) {
          return 1;
        } else {
          return 0;
        }
      });
      console.log(arrayField);
      setSortField(arrayField);
    }
  }, [fields]);

  useEffect(() => {
    if (materials) {
      console.log(materials);
      const arrayField = [...materials].sort((a, b) => {
        console.log(a, b);
        let fa = a.material_description.toLowerCase();
        let fb = b.material_description.toLowerCase();

        if (fa < fb) {
          return -1;
        } else if (fa > fb) {
          return 1;
        } else {
          return 0;
        }
      });

      setSortMaterial(arrayField);
    }
  }, [materials]);

  const renderOptions = (options: string) => {
    const array = JSON.parse(options);
    return array.map((_data: { option_value: string; option_title: string }) => {
      return {
        value: _data.option_value,
        optionName: _data.option_title
      };
    });
  };
  const radioOptions = (options: string, field_name: string, id: string) => {
    const array = JSON.parse(options);
    return array.map((_data: { option_title: string; option_value: string }) => {
      return (
        <div className="">
          <input type="radio" name={field_name} className="mr-2" value={_data?.option_value} onChange={(e) => handleChange(e, id, "", "")} ref={register({})} disabled={responseStat} />
          <label className="radio-box_label" htmlFor="yes">
            {_data.option_title}
          </label>
        </div>
      );
    });
  };
  const checkboxOptions = (options: string, field_name: string, id: string) => {
    const array = JSON.parse(options);
    const handleChange = ({ target: { name, value } }, id) => {
      let temp = { ...(data as any) };
      // console.log(data[name]);
      // console.log(data, "data");
      // let dataVal = [] as any;
      // if (data[name]) {
      //   dataVal = temp[name].vendor_response.split(",");
      // }
      // dataVal.push(value);
      // dataVal.reduce((acc, current) => {
      //   if(acc.includes(value)){
      //     acc.filter((val) => val !== value)
      //   }
      // }, [])
      temp[name] = {
        vendor_response: value,
        // vendor_response: dataVal.join(","),
        rfp_field_id: id,
        field_name: name
      };
      setData(temp);
    };
    return array.map((_data: { option_title: string; option_value: string }) => {
      return (
        <div className="">
          <input type="checkbox" name={field_name} className="mr-2" value={_data?.option_value} onChange={(e) => handleChange(e, id)} ref={register({})} disabled={responseStat} />
          <label className="radio-box_label" htmlFor="yes">
            {_data.option_title}
          </label>
        </div>
      );
    });
  };
  // Handle upload for files
  const handleFile = (e, field_id: any, field_name: any) => {
    const reader = new FileReader();
    const { name } = e;
    reader.readAsDataURL(e);
    reader.onloadend = () => {
      // let anchor = document.getElementById(`${field_name}_`) as any;

      let read: any = reader.result;
      const payload = {
        base64_data: read.replace("data:", "").replace(/^.+,/, ""),
        file_extension: e.name.split(".").pop()
      };
      // dispatch(uploadFile(payload)).then((data) => {
      let temp = { ...(data as any) };
      temp[field_name] = {
        vendor_response: read.replace("data:", "").replace(/^.+,/, ""),
        rfp_field_id: field_id,
        field_name: name,
        file_extension: e.name.split(".").pop()
      };
      setData(temp);
      // });
    };
  };
  const [data, setData] = React.useState({});
  const [prices, setPrices] = React.useState<any>({});
  const [discount, setDiscount] = React.useState<any>({});

  const handleChange = ({ target: { name, value } }, id, type, quantity) => {
    let temp = { ...(data as any) };

    if (type === "price") {
      // setPrices({ ...prices, [id]: Number(value) * Number(quantity) });

      setPrices((prev) => {
        return { ...prev, [id]: handleDiscount(parseFloat(value) * parseFloat(quantity), parseFloat(discount[id] ? discount[id] : 0)) };
      });
      temp[name] = {
        ...temp[name],
        vendor_response: value,
        rfp_field_id: id,
        field_name: name,
        vendor_response_gross: Number(value) * Number(quantity)
      };
      setData(temp);
    } else {
      temp[name] = {
        vendor_response: value,
        rfp_field_id: id,
        field_name: name
      };
      setData(temp);
    }
  };
  const handleDiscount = (price: number, discount: number) => {
    console.log(price, discount, "herr");
    if (discount) {
      if (price) {
        // if (price > discount) {
        //   return price - discount;
        // } else {
        //   return 0;
        // }
        return price - price * (discount / 100);
      } else {
        return 0;
      }
    } else if (price) {
      return price;
    } else {
      return 0;
    }
  };
  const handleInput = (field_name, val, id, quantity) => {
    setDiscount((prev) => {
      return { ...prev, [id]: val };
    });
    setPrices((prev) => {
      if (prev[id] >= 0) {
        return { ...prev, [id]: handleDiscount(parseFloat(getValues()[field_name]) * parseFloat(quantity), val) };
      } else {
        return prev;
      }
    });
    let temp = { ...(data as any) };
    temp[field_name] = {
      ...temp[field_name],
      vendor_response_discount: val
    };

    setData(temp);
  };

  React.useEffect(() => {
    const fieldsData = JSON.parse(localStorage.getItem("Commercial") as any);

    if (fieldsData && fieldsData) {
      fieldsData.map((field) => {
        if (field.vendor_response) {
          if (field.vendor_response.includes(`https`)) {
            let anchor = document.getElementById(`${field.field_name}_`) as any;
            if (anchor !== null) {
              if (field?.vendor_response && field?.vendor_response !== null) {
                anchor.text = field?.vendor_response;
                anchor.href = field?.vendor_response;
              } else {
                anchor.text = "";
                anchor.href = "";
              }
            }
          } else {
            setValue(field.field_name, field.vendor_response);
          }
        }
      });
      const dataState = fieldsData.reduce((acc, field) => {
        let name = { ...acc };
        // if (field.category === "Commercial") {
        name[field.field_name] = {
          vendor_response: field.vendor_response,
          rfp_field_id: field.rfp_field_id,
          field_name: field.field_name
        };
        // console.log(name, "this na name");
        // }
        return {
          ...name
        };
      }, {});
      setData(dataState);
    }
  }, [fields]);

  React.useEffect(() => {
    if (responses?.length > 0) {
      responses.map((field) => {
        if (field.field_type !== "File" && field.category === "Commercial") setValue(field.field_name, field.vendor_response);
        if (field.field_type === "File" && field.category === "Commercial") {
          let anchor = document.getElementById(`${field.field_name}_`) as any;
          if (anchor !== null) {
            if (field?.vendor_response && field?.vendor_response !== null) {
              anchor.text = field?.vendor_response;
              anchor.href = field?.vendor_response;
              // localStorage.setItem("Commercial", JSON.stringify({ vendor_response: field.vendor_response, rfp_field_id: field.rfp_field_id, field_name: field.field_name }));
            } else {
              anchor.text = "";
              anchor.href = "";
            }
          }
        }
      });
      const valueData = responses.reduce((acc, current) => {
        if (current.category === "Commercial") {
          const data = {
            vendor_response: current.vendor_response,
            rfp_field_id: current.rfp_field_id,
            field_name: current.field_name
          };
          acc.push(data);
        }
        return acc;
      }, []);
      // localStorage.setItem("Commercial", JSON.stringify(valueData));
      const fieldsData = JSON.parse(localStorage.getItem("Commercial") as any);
      let dataObj = {};
      if (fieldsData && fieldsData !== null) {
        dataObj = fieldsData.reduce((acc, obj) => {
          let name = { ...acc };
          name[obj.field_name] = {
            vendor_response: obj.vendor_response,
            rfp_field_id: obj.rfp_field_id,
            field_name: obj.field_name
          };
          // console.log(name, "this na name");
          return {
            ...name
          };
        }, {});
      }

      const dataState = responses.reduce((acc, field) => {
        if (Object.keys(dataObj).length > 0) acc = dataObj;
        let name = { ...acc };
        if (field.category === "Commercial") {
          name[field.field_name] = {
            vendor_response: field.vendor_response,
            rfp_field_id: field.rfp_field_id,
            field_name: field.field_name
          };
          // console.log(name, "this na name");
        }
        return {
          ...name
        };
      }, {});
      setData(dataState);
    }
  }, [responses]);

  React.useEffect(() => {
    if (Object.keys(data).length > 0) {
      updateComm();
    }
  }, [data]);

  React.useEffect(() => {
    if (data) {
      const valueData = Object.entries(data).map(([key, value]) => {
        return value;
      });

      valueData.map((value: any) => {
        if (value.vendor_response) {
          if (value.vendor_response.includes("https:")) {
            let anchor = document.getElementById(`${value.field_name}_`) as any;
            if (anchor !== null) {
              if (value?.vendor_response && value?.vendor_response !== null) {
                anchor.text = value?.vendor_response;
                anchor.href = value?.vendor_response;
                // localStorage.setItem("Commercial", JSON.stringify({ vendor_response: field.vendor_response, rfp_field_id: field.rfp_field_id, field_name: field.field_name }));
              } else {
                anchor.text = "";
                anchor.href = "";
              }
            }
          } else {
            console.log(value.field_name, value.vendor_response, "investigate");
            setValue(value.field_name, value.vendor_response);
          }
        }
      });

      if (Object.keys(data).length > 0) {
        localStorage.setItem("Commercial", JSON.stringify(valueData));
      }
    }
  }, [data]);

  useEffect(() => {
    if (responses?.length > 0) {
      responses
        .filter((item) => {
          return item?.field_name && item?.field_name.includes("price");
        })
        .forEach((each) => {
          setDiscount((prev) => {
            return { ...prev, [each.rfp_field_id]: each.vendor_response_discount };
          });
          setPrices((prev) => {
            return { ...prev, [each.rfp_field_id]: each.vendor_response_gross ? each.vendor_response_gross : 0 };
          });
        });
    }
  }, [responses]);

  return (
    <div className="route-transition">
      <div className="bid-technical-requirment-page">
        <div className="col-lg-10 col-md-12 pl-0">
          <form action="">
            <table className="extra_table">
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Item</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Discount (%)</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {/* ?.filter((field) => {
                    return field.name.includes("price");
                  }) */}
                {sortField &&
                  sortField.map((item, i) => {
                    return item.name.includes("price") ? (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{item.label}</td>
                        <td>
                          <Controller
                            as={NumberFormat}
                            customInput={Textbox as any}
                            thousandSeparator
                            defaultValue=""
                            rules={{
                              required: "This field is Required"
                            }}
                            onValueChange={(values) => {
                              const { formattedValue, value } = values;
                              let target = { name: item.name, value };

                              const e = { target };

                              handleChange(e, item.id, "price", sortMaterial && sortMaterial[i].quantity_required);
                            }}
                            name={item.name}
                            prefix={`${currency && currency[0].currency_symbol}`}
                            control={control}
                            placeholder={item.label}
                            allowNegative={false}
                            disabled={responseStat}
                          />
                        </td>
                        {/* <td>{false ? bid[0].materials[i] : 0}</td> */}
                        {/* <td>{materials &&  materials[i].quantity_required}</td> */}

                        <td>{sortMaterial && sortMaterial[i].quantity_required}</td>
                        <td>
                          <input
                            type="number"
                            style={{ padding: "10px", borderRadius: "8px", outline: "none", width: "100px" }}
                            onChange={(e: any) => {
                              console.log(e.target.value, "first");
                              if (e.target.value > 100) {
                                toastr.error("Value must be less than hundred");
                              } else {
                                handleInput(item.name, e.target.valueAsNumber, item.id, sortMaterial && sortMaterial[i].quantity_required);
                              }
                            }}
                            value={discount[item.id]}
                            disabled={responseStat}
                            className="discount"
                            placeholder="Enter Discount"
                            min={0}
                            max={100}
                          />
                        </td>
                        <td>
                          {" "}
                          {currency && currency[0].currency_symbol} {formatCurrency(prices[item.id] || 0)}
                        </td>
                      </tr>
                    ) : null;
                  })}
              </tbody>
            </table>
            {/* price side */}

            {fields &&
              fields?.map((field, i) => {
                switch (field.type) {
                  case "Text": {
                    return (
                      <div className="question-box mb-3" key={i}>
                        <label className="question-label" htmlFor="">
                          {field.label}
                        </label>
                        <textarea
                          name={field.name}
                          id="additionalComments"
                          cols={35}
                          rows={3}
                          className="textarea"
                          placeholder={field.label}
                          ref={register({ required: true })}
                          onChange={(e) => handleChange(e, field.id, "", "")}
                          disabled={responseStat}
                        />
                      </div>
                    );
                  }
                  case "Number": {
                    if (field.label.includes("Price")) {
                      return null;
                    } else {
                      return (
                        <div className="question-box mb-3" key={i}>
                          <label className="question-label" htmlFor="">
                            ({currency && currency[0]?.currency_iso}) {field.label}
                          </label>
                          <Controller
                            as={NumberFormat}
                            customInput={Textbox as any}
                            thousandSeparator
                            defaultValue=""
                            rules={{
                              required: "This field is Required"
                            }}
                            onValueChange={(values) => {
                              const { formattedValue, value } = values;
                              let target = { name: field.name, value };
                              const e = { target };
                              handleChange(e, field.id, "", "");
                            }}
                            name={field.name}
                            prefix={`${currency && currency[0].currency_symbol}`}
                            control={control}
                            placeholder={field.label}
                            allowNegative={false}
                            disabled={responseStat}
                          />
                        </div>
                      );
                    }
                  }
                  // case "Number": {
                  //   return (
                  //     <div className="question-box mb-3" key={i}>
                  //       <label className="question-label" htmlFor="">
                  //         {field.label}
                  //       </label>
                  //       <Textbox disabled={responseStat} type="number" label={field.label} name={field.name} customRef={register({})} onChange={(e) => handleChange(e, field.id)} placeholder={field.label} boxClasses="mt-3" />
                  //     </div>
                  //   );
                  // }
                  case "Select": {
                    return (
                      <div className="question-box" key={i}>
                        {field?.options?.length > 0 && (
                          <label className="question-label" htmlFor="">
                            {field.label}
                          </label>
                        )}
                        <SelectDropdown
                          key={i}
                          boxClasses="mt-3 select"
                          name={field.name}
                          disabled={responseStat}
                          onChange={(e) => handleChange(e, field.id, "", "")}
                          //   label={''}
                          //   placeholder="City"
                          options={renderOptions(field.options)}
                          customRef={register({
                            // required: { value: requiredForm, message: "This field is required" }
                          })}
                          error={errors[field.name] && errors[field.name].message}
                        />
                      </div>
                    );
                  }
                  case "File": {
                    return (
                      <div className="question-box mb-3" key={i}>
                        <label className="question-label" htmlFor="">
                          {field.label}
                        </label>
                        <FileUploaderBox
                          key={i}
                          label={field.label}
                          customRef={register({
                            required: true
                          })}
                          disabled={responseStat}
                          handleFile={(e) => {
                            handleFile(e, field.id, field.name);
                          }}
                          boxClasses="mt-3"
                          fileName={field.name}
                          error={errors[field.name] && "This field is required"}
                        />
                      </div>
                    );
                  }
                  case "Radio": {
                    return (
                      <div className="question-box" key={i}>
                        {field?.options?.length > 0 && (
                          <label className="question-label" htmlFor="">
                            {field.label}
                          </label>
                        )}
                        {radioOptions(field.options, field.name, field.id)}
                      </div>
                    );
                  }
                  case "Checkbox": {
                    return (
                      <div className="question-box" key={i}>
                        {field?.options?.length > 0 && (
                          <label className="question-label" htmlFor="">
                            {field.label}
                          </label>
                        )}
                        {checkboxOptions(field.options, field.name, field.id)}
                      </div>
                    );
                  }
                  case "Date": {
                    return (
                      <div className="question-box" key={i}>
                        {field?.options?.length > 0 && (
                          <label className="question-label" htmlFor="">
                            {field.label}
                          </label>
                        )}
                        {/* {checkboxOptions(field.options)} */}
                        {/* <input
                        type="date"
                        name={field.name}
                        id="yes"
                        className="mr-2"
                        onChange={(e) => handleChange(e, field.id)}
                      /> */}
                        <Textbox
                          type="date"
                          label={field.label}
                          name={field.name}
                          customRef={register({})}
                          onChange={(e) => handleChange(e, field.id, "", "")}
                          placeholder={field.label}
                          boxClasses="mt-3"
                          disabled={responseStat}
                          min={new Date().toISOString().slice(0, 10)}
                        />
                      </div>
                    );
                  }

                  default:
                  // return <Textbox label="Company Name" name="farmer_id" customRef={register({})} placeholder="Company Name" boxClasses="mt-3" />;
                }
              })}
            {/* <div className="question-box">
                            <label className="question-label" htmlFor="">
                                Q1: Net Amount/Price to deliver Project
                            </label>
                            <textarea
                                name="additionalComments"
                                id="additionalComments"
                                cols={35}
                                rows={1}
                                className="textarea"
                                placeholder="Add comment"
                            />
                        </div>
                        <div className="question-box">
                            <label className="question-label" htmlFor="">
                                Q2: Currency
                            </label>
                            <textarea
                                name="additionalComments"
                                id="additionalComments"
                                cols={35}
                                rows={2}
                                className="textarea"
                                placeholder="Add comment"
                            />
                        </div>
                        <div className="question-box">
                            <label className="question-label" htmlFor="">
                                Q3: Delivery Timeline
                            </label>
                            <textarea
                                name="additionalComments"
                                id="additionalComments"
                                cols={35}
                                rows={2}
                                className="textarea"
                                placeholder="Add comment"
                            />
                        </div>
                        <div className="question-box">
                            <label className="question-label" htmlFor="">
                                Q4: Attach Commercial Proposal
                            </label>
                            <div className="upload-box">
                                <label className="upload-box_label" htmlFor="">
                                    Attachments:
                                </label>
                                <FileUploaderBox boxClasses="fileuploader" />
                            </div>
                        </div> */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default CommercialRequirements;
