import { Link } from "react-router-dom";

import Icon from "assests/icons/statsCard/Vector (4).png";
import document from "assests/icons/buyer/document-blue.svg";
import StarRating from "components/Star";

export const tableCol = [
  // {
  //     name: "Review",
  //     index: "",
  //     id: 0,
  //     render: (item) => {
  //         return (
  //             <img onClick={item.review} src={Icon} alt="" />
  //         );
  //       }
  // },
  {
    name: "Date Of Submission",
    index: "date",
    id: 1
  },
  {
    name: "Quote ID",
    index: "quote_id",
    id: 2
  },
  {
    name: "Vendor Name",
    index: "vendor_name",
    id: 3
  },
  {
    name: "Category",
    index: "category",
    id: 4
  },

  {
    name: "Status",
    id: 5,
    render: (item) => {
      if (item.rfp_vendor_status === "5") {
        return (
          <button
            style={{
              padding: "8px 16px",
              background: "rgba(0, 176, 7, 0.15)",
              borderRadius: "10px",
              color: "rgb(56, 124, 87)"
            }}
          >
            Awarded
          </button>
        );
      }
      if (item.rfp_vendor_status === "4") {
        return (
          <button
            style={{
              padding: "8px 16px",
              background: "rgba(0, 176, 7, 0.15)",
              borderRadius: "10px",
              color: "rgb(56, 124, 87)"
            }}
          >
            In Negotiation
          </button>
        );
      }
      if (item.rfp_vendor_status === "3") {
        return (
          <button
            style={{
              padding: "8px 16px",
              background: "rgba(245, 150, 37, 0.15)",
              borderRadius: "10px",
              color: "rgb(245, 150, 37)"
            }}
          >
            Disqualified
          </button>
        );
      }
      if (item.rfp_vendor_status === "2") {
        return (
          <button
            style={{
              padding: "8px 16px",
              background: "rgba(0, 176, 7, 0.15)",
              borderRadius: "10px",
              color: "rgb(56, 124, 87)"
            }}
          >
            RFP/RFQ Submitted
          </button>
        );
      }
      if (item.rfp_vendor_status === "-2") {
        return (
          <button
            style={{
              padding: "8px 16px",
              background: "rgba(245, 150, 37, 0.15)",
              borderRadius: "10px",
              color: "rgb(245, 150, 37)"
            }}
          >
            Invite Rejected
          </button>
        );
      }
      if (item.rfp_vendor_status === "1") {
        return (
          <button
            style={{
              padding: "8px 16px",
              background: "rgba(0, 176, 7, 0.15)",
              borderRadius: "10px",
              color: "rgb(56, 124, 87)"
            }}
          >
            Invite Accepted
          </button>
        );
      }
      if (item.rfp_vendor_status === "-1") {
        return (
          <button
            style={{
              padding: "8px 16px",
              background: "rgba(245, 150, 37, 0.15)",
              borderRadius: "10px",
              color: "rgb(245, 150, 37)"
            }}
          >
            Pending
          </button>
        );
      }
    }
  }
  // {
  //     name: "Document",
  //     index: "",
  //     id: 5,
  //     render: () => (
  //         <>
  //             <img src={document} className="" alt="icon" />
  //         </>
  //     )
  // },
  // {
  //     name: "",
  //     id: 7,
  //     render: (item) => (
  //         <>
  //             <StarRating stars={3} disabled={true} />
  //         </>
  //     )
  // }
];

export const tableData = [
  {
    date: "2nd Sept. 19  |  10:02am",
    vendor_name: "Brent Fawaz",
    quote_id: "QT-89000",
    category: "Technical",
    specialization: "3000",
    status: "Verified",
    id: 0
  },
  {
    date: "2nd Sept. 19  |  10:02am",
    vendor_name: "Chandler Ross",
    quote_id: "QT-73866",
    category: "Technical",
    specialization: "3I2388",
    status: "Verified",
    id: 1
  },
  {
    date: "2nd Sept. 19  |  10:02am",
    vendor_name: "Monica Williams",
    quote_id: "QT-64563",
    category: "Technical",
    specialization: "256389",
    status: "Pending",
    id: 2
  },
  {
    date: "2nd Sept. 19  |  10:02am",
    vendor_name: "Asa Okoro",
    quote_id: "QT-64563",
    category: "Technical",
    specialization: "6356782",
    status: "Verified",
    id: 3
  },
  {
    date: "2nd Sept. 19  |  10:02am",
    vendor_name: "Jamal Abdul",
    quote_id: "QT-64563",
    category: "Technical",
    specialization: "24562",
    status: "Pending",
    id: 4
  },
  {
    date: "2nd Sept. 19  |  10:02am",
    vendor_name: "Sani Abubakar",
    quote_id: "QT-64563",
    category: "Technical",
    specialization: "78992",
    status: "Pending",
    id: 5
  }
];
