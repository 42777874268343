import DropdownMenu from "components/DropdownMenu";
import StarRating from "components/Star";
import "./styles.scss";

export const notifyColumn = [
  {
    name: "ID",
    index: "id",
    id: 1
  },
  {
    name: "Activity",
    index: "activity",
    id: 2
  },
  {
    name: "UserName",
    index: "name",
    id: 3
  },
  {
    name: "Role",
    index: "role",
    id: 4
  },
  {
    name: "Actions",
    id: 5,
    render: ({ deleteNotifyData }) => {
      const settings = [
        {
          name: "Delete",
          style: "delete_tag",
          select: deleteNotifyData
        }
      ];
      return (
        <span style={{ position: "relative" }}>
          <DropdownMenu options={settings} boxClasses="dropdown-style">
            <p style={{ fontSize: "2rem" }} className="cursor-pointer">
              ...
            </p>
          </DropdownMenu>
        </span>
      );
    }
  }
];

export const rateColumn = [
  {
    name: "Vendor ID",
    index: "vendor_id",
    id: 1
  },
  {
    name: "Vendor",
    index: "name",
    id: 2
  },
  {
    name: "Company",
    index: "company",
    id: 3
  },
  {
    name: "Rating",
    id: 7,
    render: (item) => <StarRating stars={item.rate} disabled={true} />
  },
  {
    name: "No Of Delivery",
    index: "delivery",
    id: 5
  }
];
