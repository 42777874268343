import axios from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "store/store";
import { UserState, UserAction, ProfilePayload, ProfilePasswordPayload } from "./interface";
import { toastr } from "react-redux-toastr";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState: UserState = {
    authorized: false,
    user: null,
    userToken: "",
    permissions: null,
    isLoading: false,
    profile: null,
    loading: false,
    role: null,
    portal: null
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        updateUser: (state, action: PayloadAction<UserAction>) => {
            state.authorized = action.payload.authorized;
            state.user = action.payload.user;
            state.userToken = action.payload.token;
            state.permissions = action.payload.permissions;
            state.portal = action.payload.portal;
        },
        getProfileRequest: (state) => {
            state.isLoading = true;
        },
        getProfileSuccess: (state, action: PayloadAction<UserAction>) => {
            state.isLoading = false;
            state.profile = action.payload;
            state.role = action.payload;
        },
        getProfileFail: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
        },
        updateProfileRequest: (state) => {
            state.loading = true;
        },
        updateProfileSuccess: (state, action: PayloadAction<UserAction>) => {
            state.loading = false;
        },
        updateProfileFail: (state, action: PayloadAction<string>) => {
            state.loading = false;
        },
        updatePasswordRequest: (state) => {
            state.loading = true;
        },
        updatePasswordSuccess: (state, action: PayloadAction<UserAction>) => {
            state.loading = false;
        },
        updatePasswordFail: (state, action: PayloadAction<string>) => {
            state.loading = false;
        },
        uploadingImageRequest: (state) => {},
        uploadingImageSuccess: (state) => {},
        uploadingImageFail: (state) => {},
        loggingOut: (state) => {}
    }
});

const { actions, reducer } = userSlice;

export const {
    updateUser,
    getProfileRequest,
    getProfileSuccess,
    getProfileFail,
    updateProfileRequest,
    updateProfileSuccess,
    updateProfileFail,
    updatePasswordRequest,
    updatePasswordSuccess,
    updatePasswordFail,
    uploadingImageRequest,
    uploadingImageSuccess,
    uploadingImageFail,
    loggingOut
} = actions;

export const logout = (portal: string | null | undefined) => (dispatch: AppDispatch) => {
    dispatch(loggingOut());
    localStorage.clear();

    if (portal && portal === "Buyer") {
        return ((window as any).location = "/buyer/login");
    }

    if (portal && portal === "Vendor") {
        return ((window as any).location = "/");
    }
};

export const getProfile =
    (id: string): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        dispatch(getProfileRequest());
        const { userToken } = getState().user;
        try {
            const response = await axios.get(`${BASE_URL}/users/get/${id}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    "Content-Type": "application/json"
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(getProfileSuccess(response.data.data[0]));
                console.log(response.data.data[0],'respo')
            }
        } catch (error) {
            dispatch(getProfileFail(error?.response?.data?.message));
        }
    };

export const updateProfile =
    (payload: ProfilePayload, id: string): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        dispatch(updateProfileRequest());
        const { userToken } = getState().user;
        try {
            const response = await axios.post(`${BASE_URL}/profile/update/${id}`, payload, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    "Content-Type": "application/json"
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(updateProfileSuccess(response.data));
                dispatch(getProfile(id));
            }
        } catch (error) {
            dispatch(updateProfileFail(error?.response?.data?.message));
        }
    };

export const updateProfilePassword =
    (payload: ProfilePasswordPayload, id: string, cb: () => void): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        dispatch(updatePasswordRequest());
        const { userToken } = getState().user;
        try {
            const response = await axios.post(`${BASE_URL}/profile/updatePassword/${id}`, payload, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    "Content-Type": "application/json"
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(updatePasswordSuccess(response.data));
                toastr.success("", response.data.message);
                cb();
            }
        } catch (error) {
            toastr.error(error?.response?.data?.message);
            dispatch(updatePasswordFail(error?.response?.data?.message));
        }
    };

export const uploadProfilePicture =
    (payload: FormData, id: string): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
        dispatch(uploadingImageRequest());
        const { userToken } = getState().user;
        try {
            const response = await axios.post(`${BASE_URL}/profile/upload`, payload, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    "Content-Type": "multipart/form-data"
                }
            });
            if ([200, 201].includes(response.status)) {
                dispatch(uploadingImageSuccess());
                dispatch(getProfile(id));
            }
        } catch (error) {
            dispatch(uploadingImageFail());
        }
    };

export default reducer;
