import { toastr } from "react-redux-toastr";
import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GoodsState, Action } from "./interface";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState: GoodsState = {
  isLoading: false,
  goods: null,
  singleGoods: null,
  summary: null,
  success: "",
  errorMessage: ""
};

const goodsSlice = createSlice({
  name: "goods",
  initialState,
  reducers: {
    getGoodsLoading: (state) => {
      state.isLoading = true;
    },
    getGoodsSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.goods = action.payload;
    },
    getGoodsFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
    },
    getSingleGoodsLoading: (state) => {
      state.isLoading = true;
    },
    getSingleGoodsSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.singleGoods = action.payload;
    },
    getSingleGoodsFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
    },
    getGoodsSummaryLoading: (state) => {
      state.isLoading = true;
    },
    getGoodsSummarySuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.summary = action.payload;
    },
    getGoodsSummaryFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
    }
  }
});

const { actions, reducer } = goodsSlice;

export const { getGoodsFail, getGoodsLoading, getGoodsSuccess, getSingleGoodsFail, getSingleGoodsLoading, getSingleGoodsSuccess, getGoodsSummaryFail, getGoodsSummaryLoading, getGoodsSummarySuccess } =
  actions;

export const getGoods =
  (
    page?: number,
    length?: number,
    search?: string,
    vendor_id?: string,
    department_id?: string,
    company_id?: string,
    date_from?: string,
    date_to?: string,
    purchase_order_code?: string,
    purchase_order_id?: string,
    requisition_id?: string,
    rfp_id?: string
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getGoodsLoading());
    try {
      const response = await axios.get(`${BASE_URL}/grs/get`, {
        params: {
          company_id,
          date_from,
          date_to,
          search,
          department_id,
          purchase_order_code,
          purchase_order_id,
          vendor_id,
          requisition_id,
          rfp_id,
          page,
          length
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if (response.status) {
        dispatch(getGoodsSuccess(response.data));
      }
    } catch (error) {
      dispatch(getGoodsFail("Fail"));
    }
  };

export const getSingleGoods =
  (
    id: string,
    search?: string,
    cb?: () => void,
    department_id?: string,
    company_id?: string,
    date_from?: string,
    date_to?: string,
    purchase_order_code?: string,
    purchase_order_id?: string,
    vendor_id?: string,
    requisition_id?: string,
    rfp_id?: string
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getSingleGoodsLoading());
    try {
      const response = await axios.get(`${BASE_URL}/grs/get/${id}`, {
        params: {
          company_id,
          date_from,
          date_to,
          search,
          department_id,
          purchase_order_code,
          purchase_order_id,
          vendor_id,
          requisition_id,
          rfp_id
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if (response.status) {
        dispatch(getSingleGoodsSuccess(response.data));
        cb && cb();
      }
    } catch (error) {
      dispatch(getSingleGoodsFail("Fail"));
      cb && cb();
    }
  };

export const getGoodsSummary =
  (
    vendor_id?: string,
    department_id?: string,
    company_id?: string,
    date_from?: string,
    date_to?: string,
    purchase_order_code?: string,
    purchase_order_id?: string,
    requisition_id?: string,
    rfp_id?: string
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getGoodsSummaryLoading());
    try {
      const response = await axios.get(`${BASE_URL}/grs/summary`, {
        params: {
          company_id,
          date_from,
          date_to,
          department_id,
          purchase_order_code,
          purchase_order_id,
          vendor_id,
          requisition_id,
          rfp_id
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if (response.status) {
        dispatch(getGoodsSummarySuccess(response.data.data));
      }
    } catch (error) {
      dispatch(getGoodsSummaryFail("Fail"));
    }
  };

export default reducer;
