import React, { useEffect, useState } from "react";
import Modal from "components/Modal";
import ArrowDropdown from "components/ArrowDropdown";
import Textbox from "components/Textbox";
import Button from "components/Button";
import useArrowDropdown from "hooks/useArrowDropdown";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { AppDispatch, RootState } from "store/store";
import API from "utils/httpsRequest";
import SelectDropdown from "components/SelectDropdown";
import {
  createLocation,
  editLocation,
  getLocations
} from "store/slice/BuyerSlice/LocationSlice/LocationSlice";
import { getCompaniesEntityList } from "store/slice/CompaniesSlice/CompaniesSlice";
import FileUploaderBox from "components/FileUploaderBox";
import { getWayBill, uploadFile, uploadPod } from "store/slice/SupplierSlices/Waybill/WayBillSlice";

type Props = {
  closeModal: () => void;
  singlePod?: any;
  uploadPd?:any;
};
const UploadPod = ({ closeModal, singlePod = null,uploadPd=null }: Props) => {
  const [error, setError] = React.useState("");
  const { register, handleSubmit, errors,control, setValue } = useForm<FormData>({
    mode: "onChange"
  });

  console.log(singlePod,'mjjjjjjjjjjjj')

  //Redux Store
  const dispatch = useDispatch<AppDispatch>();
  const [companySel, setCompanySel] = React.useState("");
  const [selCompanyVal, setSelCompanyVal] = React.useState("");
  const { user } = useSelector((state: RootState) => state.user)
  const { pod_file_url,isLoading } = useSelector((state: RootState) => state.waybill);
  const [files, setFiles] = useState<any>([]);
  const { token } = user;


console.log(pod_file_url,'kjlll')
  


  type FormData = {
    reference: string;
  };



 

  const onFormSubmit = (vals: FormData) => {
    if (singlePod) {
      const { ...rest } = vals;
      const payload = {
        ...rest,
        pod_file_url: pod_file_url,
        id:singlePod.id
      };
      dispatch(
        uploadPod(payload, () => {
        
          closeModal();
          dispatch(getWayBill(1, 10));
        
        })
      );
      return;
    }

    const { ...rest } = vals;
    const payload = {
      ...rest,
      pod_file_url: pod_file_url,
    };

 

    dispatch(
      uploadPod(payload, () => {
        
        closeModal();
        dispatch(getWayBill(1, 10));
        
        // dispatch(getLocations());
      })
    );
  };


  const handleFile = (e, field_name: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(e);
    reader.onloadend = () => {
      // use a regex to remove data url part
      let read: any = reader.result;
      const payload = {
        base64_data: read.replace("data:", "").replace(/^.+,/, ""),
        file_extension: e.name.split(".").pop()
      };
      dispatch(uploadFile(payload));
      setFiles(payload);
    };
  };


  return (
    <Modal
      modalText={singlePod === null ? "Upload POD" : "Upload POD"}
      onClose={closeModal}
      modallength="modal-length"
    >
      <div id="buyer-create-location">
        <p className="">
         
            {
              singlePod && "Edit Bill"
            }
            {!singlePod && !uploadPd && "Create Bill"}
          {
            uploadPd && " Upload POD"
          }
        </p>
        <p>
         
        </p>

        <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>


           <FileUploaderBox
                  placeholder="File"
                  fileName="pod_file_url"
                  boxClasses="mt-3 mb-2"
                  label="File"
                  handleFile={handleFile}
                  acceptFiles={".jpg, .jpeg, .png"}
                  customRef={register({ required: true })}
                  error={errors["logo"] && "This field is required"}
                />

          <Button
            label={singlePod === null ? "Upload POD" : "Upload POD"}
            btnType="btn-primary"
            boxClasses="mt-5 mb-5"
            btnStyle="100%"
            loading={isLoading}
            disabled={isLoading}
          />
        </form>
      </div>
    </Modal>
  );
};

export default UploadPod;
