import axios from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "store/store";
import { toastr } from "react-redux-toastr";
import { SourcingAction, SourcingState } from "./interface";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState: SourcingState = {
  isLoading: false,
  sourcings: null,
  rfp: null,
  invitedVendors: null,
  rfp_fields: null,
  rfp_summary: null,
  po_summary: null,
  po_trend: null,
  vendor_summary: null,
  mkCatTrend: null,
  s3url: null
};

const sourcingSlice = createSlice({
  name: "sourcing",
  initialState,
  reducers: {
    getAllSourcings: (state, action: PayloadAction<SourcingAction>) => {
      state.sourcings = action.payload;
    },
    getRfp: (state, action: PayloadAction<any>) => {
      state.rfp = action.payload;
    },
    getAllRfpFields: (state, action: PayloadAction<SourcingAction>) => {
      state.rfp_fields = action.payload;
    },
    getInvitedVendors: (state, action: PayloadAction<SourcingAction>) => {
      state.invitedVendors = action.payload;
    },
    uploadingImageRequest: (state) => {
      state.isLoading = true;
    },
    uploadingImageSuccess: (state, action) => {
      state.isLoading = false;
      state.s3url = action.payload;
    },
    uploadingImageFail: (state) => {},
    getSummary: (state, action: PayloadAction<SourcingAction>) => {
      state.rfp_summary = action.payload;
    },
    getPoSummary: (state, action: PayloadAction<SourcingAction>) => {
      state.po_summary = action.payload;
    },
    getVendorSummary: (state, action: PayloadAction<SourcingAction>) => {
      state.vendor_summary = action.payload;
    },
    getPoTrend: (state, action: PayloadAction<SourcingAction>) => {
      state.po_trend = action.payload;
    },
    getMkCatTrend: (state, action: PayloadAction<SourcingAction>) => {
      state.mkCatTrend = action.payload;
    },
    startRequest: (state) => {
      state.isLoading = true;
    },
    endRequest: (state) => {
      state.isLoading = false;
    }
  }
});

const { actions, reducer } = sourcingSlice;

export const {
  startRequest,
  endRequest,
  getAllSourcings,
  getRfp,
  getInvitedVendors,
  getAllRfpFields,
  getSummary,
  uploadingImageRequest,
  uploadingImageSuccess,
  uploadingImageFail,
  getPoSummary,
  getPoTrend,
  getVendorSummary,
  getMkCatTrend
} = actions;

export const getSourcings =
  (page?: number, length?: number, search?: string, date_from?: string, date_to?: string, rfp_status?: string, department_id?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(startRequest());
    const { userToken } = getState().user;

    try {
      const response = await axios.get(`${BASE_URL}/rfps/get?`, {
        params: {
          page,
          length,
          search,
          date_from,
          date_to,
          rfp_status,
          department_id
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getAllSourcings(response.data));
      }
      dispatch(endRequest());
    } catch (err) {
      dispatch(endRequest());
      toastr.error(err?.response?.data?.message);
    }
  };

export const createRFISourcings =
  (data?: object, cb?: (any) => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    // console.log(data)
    dispatch(startRequest());
    const { userToken } = getState().user;
    try {
      const response = await axios.post(`${BASE_URL}/rfps/create`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(endRequest());
        toastr.success(response?.data?.message);
        cb && cb(response.data);
      }
    } catch (err) {
      dispatch(endRequest());
      toastr.error(err?.response?.data?.message);
    }
  };

export const updateRFISourcing =
  (data?: object, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(startRequest());
    const { userToken } = getState().user;
    try {
      const response = await axios.post(`${BASE_URL}/rfps/edit`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(endRequest());
        // toastr.success(response?.data?.message);
        cb && cb();
      }
    } catch (err) {
      dispatch(endRequest());
      toastr.error(err?.response?.data?.message);
    }
  };

export const createRFQSourcings =
  (data?: object, cb?: (res) => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(startRequest());
    const { userToken } = getState().user;
    try {
      const response = await axios.post(`${BASE_URL}/rfps/generate_rfq`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      // console.log(response);
      if ([200, 201].includes(response.status)) {
        dispatch(endRequest());
        toastr.success(response?.data?.message);
        console.log(response);
        cb && cb(response.data);
      }
    } catch (err) {
      dispatch(endRequest());
      toastr.error(err?.response?.data?.message);
    }
  };

export const getSingleRfp =
  (id?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(getRfp({}));
    dispatch(startRequest());
    const { userToken } = getState().user;
    try {
      const response = await axios.get(`${BASE_URL}/rfps/get/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(endRequest());
        dispatch(getRfp(response.data.data[0]));
      }
    } catch (err) {
      dispatch(endRequest());
      toastr.error(err?.response?.data?.message);
    }
  };

export const remRfpField =
  (data?: object, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(startRequest());
    const { userToken } = getState().user;
    try {
      const response = await axios.post(
        `${BASE_URL}/rfp_fields/remove`,
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json"
          }
        }
      );
      if ([200, 201].includes(response.status)) {
        dispatch(endRequest());
        toastr.success(response?.data?.message);
        cb && cb();
      }
    } catch (err) {
      dispatch(endRequest());
      toastr.error(err?.response?.data?.message);
    }
  };

export const createRfpFromKpiGrp =
  (data?: object, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(startRequest());
    const { userToken } = getState().user;
    try {
      const response = await axios.post(`${BASE_URL}/rfp_fields/create_from_kpi_group`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(endRequest());
        toastr.success(response?.data?.message);
        cb && cb();
      }
    } catch (err) {
      dispatch(endRequest());
      toastr.error(err?.response?.data?.message);
    }
  };

export const createRfpFields =
  (data?: object, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(startRequest());
    const { userToken } = getState().user;
    try {
      const response = await axios.post(`${BASE_URL}/rfp_fields/create`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(endRequest());
        toastr.success(response?.data?.message);
        cb && cb();
      }
    } catch (err) {
      dispatch(endRequest());
      toastr.error(err?.response?.data?.message);
    }
  };

export const inviteVendors =
  (data?: object, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(startRequest());
    const { userToken } = getState().user;
    try {
      const response = await axios.post(
        `${BASE_URL}/rfps/add_vendors`,
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json"
          }
        }
      );
      if ([200, 201].includes(response.status)) {
        dispatch(endRequest());
        toastr.success(response?.data?.message);
        cb && cb();
      }
    } catch (err) {
      dispatch(endRequest());
      toastr.error(err?.response?.data?.message);
    }
  };

export const inviteVendorsByCat =
  (data?: object, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(startRequest());
    const { userToken } = getState().user;
    try {
      const response = await axios.post(
        `${BASE_URL}/rfps/add_vendors_by_category`,
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json"
          }
        }
      );
      if ([200, 201].includes(response.status)) {
        dispatch(endRequest());
        toastr.success(response?.data?.message);
        cb && cb();
      }
    } catch (err) {
      dispatch(endRequest());
      toastr.error(err?.response?.data?.message);
    }
  };

export const setRfpDoc =
  (data?: object): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(startRequest());
    const { userToken } = getState().user;
    try {
      const response = await axios.post(
        `${BASE_URL}/rfps/set_rfp_document_url`,
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json"
          }
        }
      );
      if ([200, 201].includes(response.status)) {
        dispatch(endRequest());
        toastr.success(response?.data?.message);
      }
    } catch (err) {
      dispatch(endRequest());
      toastr.error(err?.response?.data?.message);
    }
  };

export const setInhouseEst =
  (data?: object): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(startRequest());
    const { userToken } = getState().user;
    try {
      const response = await axios.post(
        `${BASE_URL}/rfps/set_estimate`,
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json"
          }
        }
      );
      if ([200, 201].includes(response.status)) {
        dispatch(endRequest());
        toastr.success(response?.data?.message);
      }
    } catch (err) {
      dispatch(endRequest());
      toastr.error(err?.response?.data?.message);
    }
  };

export const publishRfp =
  (id?: object, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(startRequest());
    const { userToken } = getState().user;
    try {
      const response = await axios.post(
        `${BASE_URL}/rfps/confirm`,
        { ...id },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json"
          }
        }
      );
      if ([200, 201].includes(response.status)) {
        dispatch(endRequest());
        toastr.success(response?.data?.message);
        cb && cb();
      }
    } catch (err) {
      dispatch(endRequest());
      toastr.error(err?.response?.data?.message);
    }
  };

export const getRfpFields =
  (id?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(startRequest());
    const { userToken } = getState().user;
    try {
      const response = await axios.get(`${BASE_URL}/rfp_fields/get/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(endRequest());
        dispatch(getAllRfpFields(response.data));
      }
    } catch (err) {
      dispatch(endRequest());
      toastr.error(err?.response?.data?.message);
    }
  };

export const removeVendorFromRfp =
  (data?: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(startRequest());
    const { userToken } = getState().user;
    try {
      const response = await axios.post(
        `${BASE_URL}/rfp_vendors/remove`,
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json"
          }
        }
      );
      if ([200, 201].includes(response.status)) {
        dispatch(endRequest());
        toastr.success(response?.data?.message);
        cb && cb();
      }
    } catch (err) {
      dispatch(endRequest());
      toastr.error(err?.response?.data?.message);
    }
  };

export const getVendors =
  (id?: string, page?: number, length?: number, search?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(startRequest());
    const { userToken } = getState().user;
    try {
      const response = await axios.get(`${BASE_URL}/rfp_vendors/get/${id}`, {
        params: {
          page,
          length,
          search
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(endRequest());
        dispatch(getInvitedVendors(response.data));
      }
    } catch (err) {
      dispatch(endRequest());
      toastr.error(err?.response?.data?.message);
    }
  };

export const fetchMktCatTrendByMat =
  (
    id?: string,
    search?: string,
    length?: string,
    company_id?: string,
    department_id?: string,
    date_from?: string,
    date_to?: string,
    purchase_order_code?: string,
    purchase_order_id?: string,
    vendor_id?: string,
    requisition_id?: string,
    rfp_id?: string
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    try {
      const response = await axios.get(`${BASE_URL}/purchase_orders/get_material_trend/${id}`, {
        params: {
          search,
          length,
          company_id,
          department_id,
          date_from,
          date_to,
          purchase_order_code,
          purchase_order_id,
          vendor_id,
          requisition_id,
          rfp_id
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getMkCatTrend(response.data.data));
      }
    } catch (err) {
      toastr.error(err?.response?.data?.message);
    }
  };

export const fetchByVendor =
  (material_category_id?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    try {
      const response = await axios.get(`${BASE_URL}/purchase_orders/get_trend_per_vendor`, {
        params: {
          material_category_id
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getMkCatTrend(response.data.data));
      }
    } catch (err) {
      toastr.error(err?.response?.data?.message);
    }
  };

export const fetchMktCatTrend =
  (
    search?: string,
    length?: string,
    company_id?: string,
    department_id?: string,
    date_from?: string,
    date_to?: string,
    purchase_order_code?: string,
    purchase_order_id?: string,
    vendor_id?: string,
    requisition_id?: string,
    rfp_id?: string
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    try {
      const response = await axios.get(`${BASE_URL}/purchase_orders/get_material_group_trend`, {
        // const response = await axios.get(`${BASE_URL}/purchase_orders/get_trend_per_vendor`, {
        params: {
          search,
          length,
          company_id,
          department_id,
          date_from,
          date_to,
          purchase_order_code,
          purchase_order_id,
          vendor_id,
          requisition_id,
          rfp_id
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getMkCatTrend(response.data.data));
      }
    } catch (err) {
      toastr.error(err?.response?.data?.message);
    }
  };

export const fetchPoTrend =
  (
    date_function?: string,
    search?: string,
    length?: string,
    company_id?: string,
    department_id?: string,
    date_from?: string,
    date_to?: string,
    purchase_order_code?: string,
    purchase_order_id?: string,
    vendor_id?: string,
    requisition_id?: string,
    rfp_id?: string
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    try {
      const response = await axios.get(`${BASE_URL}/purchase_orders/get_trend`, {
        params: {
          date_function,
          search,
          length,
          company_id,
          department_id,
          date_from,
          date_to,
          purchase_order_code,
          purchase_order_id,
          vendor_id,
          requisition_id,
          rfp_id
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getPoTrend(response.data.data));
      }
    } catch (err) {
      toastr.error(err?.response?.data?.message);
    }
  };

export const fetchVendorSummary =
  (
    search?: string,
    length?: string,
    company_id?: string,
    department_id?: string,
    date_from?: string,
    date_to?: string,
    purchase_order_code?: string,
    purchase_order_id?: string,
    vendor_id?: string,
    requisition_id?: string,
    rfp_id?: string
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    try {
      const response = await axios.get(`${BASE_URL}/vendors/summary`, {
        params: {
          search,
          length,
          company_id,
          department_id,
          date_from,
          date_to,
          purchase_order_code,
          purchase_order_id,
          vendor_id,
          requisition_id,
          rfp_id
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getVendorSummary(response.data.data));
      }
    } catch (err) {
      toastr.error(err?.response?.data?.message);
    }
  };

export const fetchPoSummary =
  (
    search?: string,
    company_id?: string,
    department_id?: string,
    date_from?: string,
    date_to?: string,
    purchase_order_code?: string,
    purchase_order_id?: string,
    vendor_id?: string,
    requisition_id?: string,
    rfp_id?: string
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    try {
      const response = await axios.get(`${BASE_URL}/purchase_orders/summary`, {
        params: {
          search,
          company_id,
          department_id,
          date_from,
          date_to,
          purchase_order_code,
          purchase_order_id,
          vendor_id,
          requisition_id,
          rfp_id
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getPoSummary(response.data.data));
      }
    } catch (err) {
      toastr.error(err?.response?.data?.message);
    }
  };

export const getRfpSummary =
  (department_id?: string, rfp_status?: string, rfp_id?: string, requisition_id?: string, date_from?: string, data_to?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    try {
      const response = await axios.get(`${BASE_URL}/rfps/summary`, {
        params: {
          department_id,
          rfp_status,
          rfp_id,
          requisition_id,
          date_from,
          data_to
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getSummary(response.data.data));
      }
    } catch (err) {
      toastr.error(err?.response?.data?.message);
    }
  };

export const uploadFile =
  (payload): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    // dispatch(uploadingImageRequest());
    const { userToken } = getState().user;
    try {
      const response = await axios.post(`${BASE_URL}/prometheus/upload_file`, payload, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "multipart/form-data"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(uploadingImageSuccess(response.data.s3url));
        toastr.success("", "Successfully Uploaded");
      }
    } catch (error) {
      dispatch(uploadingImageFail());
    }
  };

export default reducer;
