import { toastr } from "react-redux-toastr";

//Format value with comma and add Naira sign
export const toCurrency = (number: number): any => {
  const formatter = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN"
  });

  return formatter.format(number);
};

// Format number to thousandth(k) or millionth(m)
export const kFormatter = (num: any, amount: string) => {
  num = Math.round(num);
  if (999 < num && num < 1000000) {
    return amount + Math.sign(num) * ((Math.abs(num) / 1000) as any).toFixed(1) + "k";
  } else if (1000000 < num && num < 1000000000) {
    return amount + Math.sign(num) * ((Math.abs(num) / 1000000) as any).toFixed(1) + "m";
  } else if (100000000 < num && num < 1000000000000) {
    return amount + Math.sign(num) * ((Math.abs(num) / 1000000000) as any).toFixed(1) + "b";
  } else {
    let value;
    value = Math.sign(num) * Math.abs(num);
    if (isNaN(value)) value = "";
    return value;
  }
};

export const moneyFormatter = (val: any, amount: string) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(val)) >= 1.0e9
    ? (Math.abs(Number(val)) / 1.0e9).toFixed(2) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(val)) >= 1.0e6
    ? (Math.abs(Number(val)) / 1.0e6).toFixed(2) + "M"
    : // Three Zeroes for Thousands
    Math.abs(Number(val)) >= 1.0e3
    ? (Math.abs(Number(val)) / 1.0e3).toFixed(2) + "K"
    : Math.abs(Number(val))
    ? Math.abs(Number(val) / 1.0)
    : Math.abs(Number(val)) >= 1.0
    ? isNaN(val)
    : "";

  // ? ({ val = Math.sign(val) * Math.abs(val) })
};

// capitalize first letter of each word in a sentence
export function titleCase(str) {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
}

//check user permission
// export const checkPermission = (permissions: any, permission: string): boolean => {
//     if (!permissions?.includes(permission)) {
//         toastr.error("", "You don't have the Admin rights to do this");
//         return false;
//     } else {
//         return true;
//     }
// };

export const checkPermission = (permissions: any, permission: string): boolean => {
  const userPerm = permissions.map((data) => data.permission_name);
  if (!userPerm?.includes(permission)) {
    toastr.error("", "You don't have the Admin rights to do this");
    return false;
  } else {
    return true;
  }
};

export const formatCurrency = (amount: any): string => {
  return Number(amount)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

//toast PopUp
export const toastPopUp = (cb: () => void, okText?: string, cancelText?: string, blockName?: string, blockText?: string, color?: string): void => {
  const toastrConfirmOptions = {
    onOk: () => cb(),
    okText: okText,
    cancelText: cancelText,
    component: () => (
      <div
        style={{
          padding: "1rem 2rem",
          position: "relative",
          display: "flex"
        }}
      >
        <p style={{ color: `${color}`, marginLeft: "auto", marginRight: "auto" }}>
          {blockName} {blockText && blockText!.toUpperCase()}
        </p>
      </div>
    )
  };
  toastr.confirm(null, toastrConfirmOptions);
};

// Check if url is valid
export function isValidURL(string) {
  var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);

  if (res !== null) return true;
}

export function removeZero(number) {
  if (number) {
    let unZero = number.slice(4);
    return unZero;
  }
}
