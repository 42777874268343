import React, { useCallback, useEffect, useState } from "react";
import "./styles.scss";
import StatCard, { StatsCard } from "components/StatsCard/";
import { tableCol } from "./Data";
import people from "../../../assests/icons/supplier/people.svg";
import SearchBar from "../../../components/SearchBar";
import DataTable from "components/DataTable";
import Icon from "assests/icons/add-circle.svg";
import book8 from "assests/icons/book8.svg";
import clock8 from "assests/icons/clock8.svg";
import optionIcon from "assests/icons/supplier/option.svg";
import Button from "components/Button";
import CreateUser from "components/ModalSupplier/CreateOrEditUser";
import Contracts from "assests/icons/contract.svg";
import Stars from "../../../components/Star/index";
import { useDispatch, useSelector } from "react-redux";
import { getSupplierUsers } from "store/slice/SupplierSlices/SupplierUserSlice/SupplierUserSlice";
import { AppDispatch, RootState } from "store/store";
import { removeZero, toastPopUp } from "utils/utils";
import { deleteUser, getUsers, activateUser, deactivateUser } from "store/slice/UserMgtSlice/UserMgtSlice";
import Pagination from "components/Pagination";
import useSearch from "hooks/useSearch";
import { getWayBill } from "store/slice/SupplierSlices/Waybill/WayBillSlice";
import SearchIcon from "../../../components/SearchBar";
import UploadPod from "components/ModalSupplier/UploadPod";
import CreateNonPO2 from "components/ModalSupplier/CreateNonPO2/Index";
import Textbox from "components/Textbox";
import filterIcon from "assests/icons/buyer/filter-icon.svg";
import SelectDropdown from "components/SelectDropdown";
import { useForm } from "react-hook-form";
import Dropdown from "components/Dropdown";
import { getInvoiceSummary } from "store/slice/BuyerSlice/Invoice/InvoiceSlice";

interface IUserMenuProps {}

const Waybill: React.FC<IUserMenuProps> = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //State
  const [modal, setModal] = useState<boolean>(false);
  const [singlePod, setSinglePod] = useState<any>(null);
  const [uploadPd, setUploadPd] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [showFilter, setShowFilter] = useState(false);
  const [wayStatus, setWayStatus] = useState(null);
  const [wayStart, setWayStart] = useState(null);
  const [wayEnd, setWayEnd] = useState(null);

  // const [_modalData, set_ModalData] = useState(null);

  const { search, inputRef, handleChange, searched } = useSearch(getWayBill, perPage);

  //Redux Hook
  const dispatch = useDispatch<AppDispatch>();
  const { waybill, loading } = useSelector((state: RootState) => state.waybill);
  const { summary } = useSelector((state: RootState) => state.invoice);
  const { user } = useSelector((state: RootState) => state.user);

  const [openNonPO, setOpenNonPO] = useState(false);
  useEffect(() => {
    dispatch(getInvoiceSummary());
  }, []);

  const closeModal = () => {
    setModal(!modal);
  };
  const closePo = () => {
    setOpenNonPO(!openNonPO);
  };

  useEffect(() => {}, [modal]);

  //Edit CompanyData
  const editBill = (...rest) => {
    setSinglePod({ ...rest });
    setModal((prev) => !prev);
  };

  const upload = (data) => {
    setUploadPd(!uploadPd);
    setSinglePod(data);
    setModal((prev) => !prev);
  };

  //Getting Companies List
  const statusOptions = [
    {
      value: "1",
      optionName: "Approved"
    },
    {
      value: "2",
      optionName: "Delivered"
    },
    {
      value: "3",
      optionName: "Proof of delivery uploaded"
    },
    {
      value: "4",
      optionName: "Invoice Generated"
    },
    {
      value: "5",
      optionName: "Invoice Paid"
    }
  ];

  // delete
  const deleteBill = (name: string, id: string) => {
    const payload = { location_id: id };
    // toastPopUp(
    //     () => {
    //         dispatch(
    //             deleteLocation(payload, () => {
    //                 dispatch(getLocations(page, perPage));
    //             })
    //         );
    //     },
    //     "Delete",
    //     "Cancel",
    //     "Delete the entity",
    //     name,
    //     "red"
    // );
  };

  //   const deleteUser = (): void => {};

  function getItemStatus(status) {
    if (status === "1") return "Approved";
    if (status === "2") return "Delivered";
    if (status === "3") return "Uploaded";
    if (status === "4") return "Invoice Generated";
    if (status === "5") return "Invoice Paid";
  }

  function getItemTheme(status) {
    if (status === "1") return "Approved";
    if (status === "2") return "Delivered";
    if (status === "3") return "Uploaded";
    if (status === "4") return "InvoiceGen";
    if (status === "5") return "InvoiceP";
  }

  const viewPod = (url) => {
    window.open(url, "blank");
  };

  const waybillList =
    waybill &&
    waybill?.data?.map((data) => {
      return {
        loading_date: data.loading_date,
        delivery_number: data.delivery_number,
        customer_ref: data.gs,
        vendor_name: data.vendor_description,
        external_ref: data.external_ref_id,
        truck_number: data.truck_number,
        employee: data.employee,
        pod: "Upload",
        statusNumber: data.waybill_status,
        status: getItemStatus(data.waybill_status),
        theme: getItemTheme(data.waybill_status),
        amount: data.amount,
        id: data.id,
        viewPod: () => viewPod(data.pod_file_url),
        upload: () => upload(data),
        edit: () => editBill(data),
        deleteBill: () => deleteBill(data.delivery_number, data.id)
        // view: () => viewUser(data.id),
        // edit: () => editUser(data.id),
        // deleteUserId: () => deleteUserId(data.id, data.othernames),
        // activateUserID: () => activateUserID(data.id, data.othernames),
        // deactivateUserID: () => deactivateUserID(data.id, data.othernames)
      };
    });

  //Create User
  const createBill = () => {
    closeModal();
  };

  // setpage to the first page when a searched executed
  useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  //--------------------------------------------
  //Pagination
  //---------------------------------------------
  //Pagination
  //---------------------------------------------
  //Pagination
  //---------------------------------------------

  const { register, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues: {
      start_date: "",
      end_date: "",
      status: ""
    }
  });

  const onSubmit = (vals) => {
    setWayStatus(vals.status);
    setWayStart(vals.start_date);
    setWayEnd(vals.end_date);
    dispatch(getWayBill(page, perPage, search, undefined, undefined, vals.start_date, vals.end_date, undefined, vals.status));
  };

  const handleNewPage = React.useCallback(
    (pageNo: number, page = 1) => {
      setPerPage(pageNo);
      setPage(1);
      dispatch(getWayBill(page, perPage, search, undefined, undefined, wayStart, wayEnd, undefined, wayStatus));
    },
    [search, wayStatus, wayStart, wayEnd]
  );

  const handlePageChange = React.useCallback(
    (page: number, perPage: number) => {
      setPage(page);
      dispatch(getWayBill(page, perPage, search, undefined, undefined, wayStart, wayEnd, undefined, wayStatus));
    },
    [search, wayStatus, wayStart, wayEnd]
  );

  const paginate = React.useCallback(
    (pageNo: number, perPage: number) => {
      setPage(pageNo);
      dispatch(getWayBill(page, perPage, search, undefined, undefined, wayStart, wayEnd, undefined, wayStatus));
    },
    [search, wayStatus, wayStart, wayEnd]
  );

  useEffect(() => {
    dispatch(getWayBill(page, perPage));
  }, []);

  return (
    <section className="user-menu">
      {modal && <UploadPod singlePod={singlePod} uploadPd={uploadPd} closeModal={closeModal} />}
      {openNonPO && <CreateNonPO2 closeModal={closePo} />}

      <div className="container">
        <div className="d-flex align-items-center mb-3">
          {/* <h4 className="page-title mr-2 ">Stakle’s Ratings</h4> */}
          <h4 className="page-title mr-2 ">
            {user?.vendor_description} | {removeZero(user?.vendor_code)}
          </h4>
          <Stars stars={user && user.overall_ratings} disabled={true} />
        </div>
        <div className="user-menu__content">
          {/* <header>
            <h3>Users</h3>
            <hr />
          </header> */}
          <div className="mt-4 row">
            <StatsCard text="Total Waybill" classes="stats-icon-orange" image={clock8} statsNumber={waybill?.count || 0} />
            <StatCard text="Total Invoices" classes="stats-icon-orange" image={book8} statsNumber={summary?.overall?.total_invoices || "0"} />
            <StatsCard image={Icon} classes="middle-img" centerText="Create Invoice" onClick={closePo} />
          </div>
          {showFilter && (
            <form
              style={{
                background: "#F2F2F2",
                padding: "2rem 4rem",
                marginTop: "2rem",
                borderRadius: "10px"
              }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="row">
                <div className="col col-4">
                  <label>Start Date</label>
                  <Textbox
                    type="date"
                    name="start_date"
                    label="Start Date"
                    placeholder="Select Start Date"
                    customRef={register({
                      required: false
                    })}
                    // error={errors.start_date && "This field is required"}
                  />
                </div>
                <div className="col col-4">
                  <label>End Date</label>

                  <Textbox
                    type="date"
                    name="end_date"
                    label="End Date"
                    placeholder="Select End Date"
                    customRef={register({ required: false })}
                    // error={errors.end_date && 'This field is required'}
                  />
                </div>

                <div className="col col-4">
                  <label>Status</label>

                  <SelectDropdown
                    boxClasses=""
                    name="status"
                    label="Status"
                    placeholder="Status"
                    options={statusOptions}
                    customRef={register({ required: false })}
                    // error={errors.company_id && "This field is required"}
                  />
                </div>
              </div>

              <div
                style={{
                  textAlign: "right",
                  paddingTop: "1rem"
                }}
              >
                <button
                  style={{
                    border: "2px solid #2F80ED",
                    padding: "10px 15px",
                    minWidth: "10rem",
                    borderRadius: "6px",
                    color: "#2F80ED"
                  }}
                >
                  Filter
                </button>
              </div>
            </form>
          )}
          <div className="document mt-5">
            <h3 className="title font-weight-bold">Recent Documents</h3>
            <nav className="mt-3">
              <div className="search-field">
                <SearchIcon placeholder="Search for Delivery Number" boxClasses="" textRef={inputRef} value={search} onChange={handleChange} />

                <Dropdown perPage={perPage} action={handleNewPage} />
                <div>
                  <button
                    style={{
                      padding: "5px 10px",
                      borderRadius: "10px",
                      background: "#FFFFFF",
                      color: "#737A91",
                      border: "1px solid #DFE2E6",
                      fontSize: "18px",
                      marginLeft: "1rem"
                    }}
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <img src={filterIcon} alt="icon" className="icon-image" />
                    <span
                      style={{
                        paddingLeft: "1rem"
                      }}
                    >
                      {!showFilter ? "Filters" : "Hide Filters"}
                    </span>
                  </button>
                </div>
              </div>
            </nav>
            <div className="table-data mt-3">
              <DataTable columns={tableCol} data={waybillList} loading={loading} />
              {waybill?.count > 10 ? (
                <Pagination page={page} lastPage={waybill?.pagination?.lastPage} paginate={paginate} total={waybill?.pagination?.total} handlePageChange={handlePageChange} perPage={perPage} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Waybill;
