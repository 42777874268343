import styled from "styled-components";

export const ModalPane = styled.div`
  background: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  opacity: ${({ show }) => (show ? "1" : "0")};
  transition: ${({ show }) => (show ? "opacity 0.2s linear" : "visibility 0s 0.3s, opacity 0.2s linear")};
`;

export const ModalContainer = styled.div`
  //   max-width: ${({ itemView }) => (!itemView ? "480px" : "350px")};
  background: #fff;
  width: 80%;
  //   width: ${({ sideModal }) => (sideModal ? "100%" : "max-content")};
  //   border-radius: ${({ sideModal }) => (sideModal ? "" : "16px")};
  position: ${({ sideModal }) => (sideModal ? "absolute" : "relative")};
  right: ${({ sideModal }) => (sideModal ? "0" : "")};
  top: ${({ sideModal }) => (sideModal ? "0" : "")};
  height: 80%;
  //   height: ${({ sideModal }) => (sideModal ? "100vh" : "initial")};
`;

export const ModalTop = styled.div`
  border-bottom: ${({ showBorderBottom }) => (showBorderBottom ? "2px solid #edeff5" : "0")};
  padding: 1.5rem 2rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 575.98px) {
    padding: 1.25rem 1.25rem;
  }
  .closebtn,
  .closebtn-white {
    // position: absolute;
    // float: right;
    // top: 15px;
    cursor: pointer;
    &:hover {
      color: #7000ff;
    }
    @media screen and (max-width: 575.98px) {
      // position: relative;
      // top: unset;
      // margin-right: 1.25rem;

      svg {
        width: 35px;
        height: 35px;
      }
    }
  }
  .closebtn-white {
    display: none;
  }
  p {
    text-align: left;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.002em;
    @media screen and (max-width: 575.98px) {
      font-size: 0.875rem;
      text-align: left;
      line-height: 24px;
    }
  }
`;

export const ModalBottom = styled.div`
  border-top: 1px solid #edeff5;
  background: #ffffff;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 2rem;
  position: absolute;
  @media screen and (max-width: 575.98px) {
    padding: 1.25rem 1.25rem;
  }
`;

export const ModalContent = styled.div`
  padding: ${({ noPadding = false }) => (noPadding ? "0" : "1.5rem 2rem 3.5rem 2rem")};
  height: 100%;
  position: relative;
  // max-height: 500px;
  overflow: scroll;
  overflow-y: scroll;
  scroll-behavior: smooth;
  @media screen and (max-width: 575.98px) {
    padding: ${({ noPadding = false }) => (noPadding ? "0" : "1.5rem 1.25rem")};
  }
`;
