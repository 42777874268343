import React from "react";
import "./styles.scss";
import StatsCard from "components/StatsCard";
import membersBlue from "assests/icons/buyer/members-blue.svg";
import contract from 'assests/icons/buyer/contracts.svg';
import rotate from 'assests/icons/buyer/rotate.svg';
import TabSmall from "components/TabSmall";
import styled from "styled-components";
import SourcingProjects from "./SourcingProjects";
import PurchaseRequisitions from "./PurchaseRequisitions";
import { useDispatch, useSelector } from "react-redux";
import { getRfpSummary } from "store/slice/SourcingSlice/SourcingSlice";
import { RootState } from "store/store";

const Wrapper = styled.div`
  margin-top: 3rem;
`;

const SourcingMain = () => {
  const menus = [
    {
      name: "Sourcing Projects",
      id: "sourcing"
    },
    {
      name: "Purchase Requisition",
      id: "purchase"
    }
  ];

  const dispatch = useDispatch();

  const [menu, setMenu] = React.useState(menus[0].id);

  const { rfp_summary } = useSelector((state: RootState) => state.sourcing);

  const selectMenu = (name) => {
    setMenu(name);
  };

  React.useEffect(() => {
    dispatch(getRfpSummary())
  }, [])

  return (
    <div id="sourcing">
      <div className="container">
        <div className="d-flex align-items-center mb-3">
          <h3 className="header">RFx Stats</h3>
        </div>
        <div className="stats-card mt-4 row">
          <StatsCard text="Open Tenders" classes="stats-icon-blue" image={membersBlue} statsNumber={rfp_summary?.open_tender?.total_rfps || '0'} />
          <StatsCard text="Awarded Contracts" classes="stats-icon-blue" image={contract} statsNumber={rfp_summary?.awarded?.total_rfps || '0'} />
          <StatsCard text="Selection In Progress" classes="stats-icon-blue" image={rotate} statsNumber={rfp_summary?.in_selection?.total_rfps || '0'} />
        </div>
        <Wrapper>
          <TabSmall menus={menus} onSelect={selectMenu} />
          <div className="tabs-page pb-5">
            {menu === "sourcing" && <SourcingProjects />}
            {menu === "purchase" && <PurchaseRequisitions />}
          </div>
        </Wrapper>
      </div>
    </div>
  );
};

export default SourcingMain;
