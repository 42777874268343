import React from "react";
import Modal from "components/Modal";
import Textbox from "components/Textbox";
import Button from "components/Button";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import "./styles.scss";
import SelectDropdown from "components/SelectDropdown";
import { getLocations } from "store/slice/BuyerSlice/LocationSlice/LocationSlice";
import { createRequisition, getRequisitions } from "store/slice/RequisitionSlice/RequisitionSlice";
import { useHistory } from "react-router-dom";
import AsyncSelect from "react-select/async";
import API from "utils/httpsRequest";
import styled from "styled-components";

type Props = {
  closeModal: () => void;
};

const Form = styled.form`
  .mySelect__control {
    border-radius: 10px;
    border: 1px solid rgb(223, 226, 230) !important;
  }

  .mySelect__value-container {
    height: 3rem;
  }
`;

const CreateRequisition = ({ closeModal }: Props) => {

  const { locations, loading: loadingLoc } = useSelector((state: RootState) => state.location);
  const { isCreating } = useSelector((state: RootState) => state.requisition);
  const { user } = useSelector((state: RootState) => state.user);

  const [loc, setLoc] = React.useState([]);
  const [deptVal, setDeptVal] = React.useState('');
  const [selDeptVal, setSelDeptVal] = React.useState('');
  const [pgVal, setPGVal] = React.useState('')
  const [selPGVal, setSelPGVal] = React.useState('');

  const { token } = user;

  const {
    register,
    handleSubmit,
    errors,
    control,
    formState: { isValid }
  } = useForm<FormData>({
    mode: "onChange"
  });

  type FormData = {
    delivery_date: string;
    requisition_type: string;
    department_id: string;
    delivery_location_id: string;
    requisition_description: string;
  };

  //Redux Store
  const dispatch = useDispatch();

  const history = useHistory();

  const submitForm = (form) => {
    const data = {
      requisition_description: form.requisition_description,
      department_id: selDeptVal?.['value'],
      purchasing_group_code: selPGVal?.['value'],
      delivery_location_id: form.delivery_location_id,
      delivery_date: form.delivery_date,
      requisition_type: form.requisition_type,
      requisition_code: Array.from(Array(7), () => Math.floor(Math.random() * 36).toString(36)).join("")
    };
    if (isValid) dispatch(createRequisition(data, (res) => handleSuccess(res)));
  };

  const handleSuccess = (res) => {
    // console.log(res.data);
    closeModal();
    history.push({ pathname: `/buyer/requisition/${res.data[0].id}` });
  };

  const handleInputChangeDept = value => setDeptVal(value);

  const onChangeDept = value => setSelDeptVal(value);

  const handleInputChangePG = value => setPGVal(value);

  const onChangePG = value => setSelPGVal(value);

  const loadDept = () => {
    return API.get(`/departments/get?page=1&length=10&search=${deptVal}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: `${item.department_description} - ${item.department_code}`,
        value: item.id,
      }))
    })
  }

  const loadPG = () => {
    return API.get(`/purchasing_groups/get?page=1&length=10&search=${pgVal}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: item.purchasing_group_description,
        value: item.purchasing_group_code,
      }))
    })
  }

  React.useEffect(() => {
    dispatch(getLocations());
  }, []);

  React.useEffect(() => {
    let arr: [] = [];
    if (locations) {
      arr = locations.data.map((item) => ({
        ...item,
        optionName: item.location_description,
        value: item.id
      }));
      setLoc(arr);
    }
  }, [locations]);

  return (
    <Modal modalText={"Create Requisition"} onClose={() => { closeModal(); getRequisitions(); }} modallength={!false ? "modal-length" : ""}>
      <div id="createcatalogue-modal">
        {true && true ? <p className="">Create a Requisition for Items Needed</p> : null}
        <Form className="mt-2" onSubmit={handleSubmit(submitForm)}>

          <textarea rows={4} className="text-area mt-4" placeholder="Enter description" name="requisition_description" ref={register({ required: true })} />
          {errors.requisition_description && <span className="error">This field is required</span>}

          <SelectDropdown
            boxClasses="mt-3"
            name="requisition_type"
            label="Select Type"
            placeholder="Select Type"
            options={[
              { optionName: "Product", value: "Product" },
              { optionName: "Service", value: "Service" }
            ]}
            customRef={register({ required: true })}
            error={errors.requisition_type && "This field is required"}
          />

          <br />

          <Controller
            control={control}
            defaultValue
            name="department_id"
            render={({ ref }) => (
              <AsyncSelect
                cacheOption
                defaultOptions
                placeholder="Select Department"
                value={selDeptVal}
                loadOptions={loadDept}
                onInputChange={handleInputChangeDept}
                onChange={onChangeDept}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                classNamePrefix="mySelect"
              />
            )} />

          <br />

          <Controller
            control={control}
            defaultValue
            name="purchasing_group_code"
            render={({ ref }) => (
              <AsyncSelect
                cacheOption
                defaultOptions
                placeholder="Purchasing Group"
                value={selPGVal}
                loadOptions={loadPG}
                onInputChange={handleInputChangePG}
                onChange={onChangePG}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                classNamePrefix="mySelect"
              />
            )}
          />

          <SelectDropdown
            boxClasses="mt-3"
            name="delivery_location_id"
            label="Select Delivery Location"
            placeholder="Select Delivery Location"
            options={loc}
            disabled={loadingLoc}
            customRef={register({ required: true })}
            error={errors.delivery_location_id && "This field is required"}
          />

          <br />
          <Textbox
            type="date"
            name="delivery_date"
            label="Delivery Date"
            placeholder="Delivery Date"
            error={errors.delivery_date && "This field is required"}
            customRef={register({
              required: true
            })}
          />

          <Button
            label={"Create Requisition"}
            btnType="btn-primary"
            boxClasses="mt-4 mb-3"
            btnStyle="100%"
            loading={isCreating} />
          <p onClick={closeModal} style={{ textAlign: "center", cursor: "pointer" }}>
            Cancel
          </p>

        </Form>
      </div>
    </Modal>
  );
};

export default CreateRequisition;
