import React from "react";
import "./styles.scss";
type Props = {
  label?: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  name?: string;
  value?: string;
  placeholder?: any;
  boxClasses?: string;
  error?: string;
  iconUrl?: string;
  onIconClick?: string;
  disabled?: boolean;
  min?: string;
  customRef?: string | ((instance: HTMLSelectElement | null) => void);
  inputClasses?: string;
  options?: any[];
  defaultValue?: string;
  optionName?: string;
  required?: boolean;
  additionalClass?: string;
  handleFieldChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
};
const SelectDropdown = ({ label, onChange, name, optionName, value, defaultValue, placeholder, boxClasses, error, additionalClass, required, disabled, options, customRef }: Props) => {
  return (
    <div className={`${boxClasses} ${additionalClass} select-dropdown`}>
      <fieldset className={`${error && "error"} ${disabled && "disabled-input"}`}>
        <legend id="label-legend" className={` pl-2 pr-2 ${error && "label-error"}`}>
          {label}
          {required ? <span className="asterikspan">*</span> : ""}
        </legend>
        <div className="d-flex">
          <select autoComplete="off" onChange={onChange} name={name} value={defaultValue} placeholder={placeholder} disabled={disabled && disabled} ref={customRef}>
            <option value="">{placeholder}</option>
            {options &&
              options.map((option) => (
                <option key={option?.value} value={option?.value}>
                  {option?.optionName}
                </option>
              ))}
          </select>
        </div>
      </fieldset>
      <p className={`${error ? "d-block" : "d-none"} input-error mt-1`}>{error}</p>
    </div>
  );
};

export default SelectDropdown;
