import React, { useEffect } from "react";
import ImageIllustration from "assests/illustrations/Group.png";
import Textbox from "components/Textbox";
import Button from "components/Button";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import GziLogo from "assests/images/gziLogo.png";
import "./styles.scss";

type Props = { children?: React.ReactNode };
const RegistrationLayout: React.FC = ({ children }: Props) => {
  type FormData = {
    email: string;
    password: string;
  };

  const dispatch = useDispatch();

  const { register, handleSubmit, errors } = useForm<FormData>({
    mode: "onBlur"
  });

  const onFormSubmit = (vals: FormData) => {
    // dispatch(loginRequest(vals));
  };

  useEffect(() => {
    // dispatch(loginFail(""));
  }, []);

  return (
    <div className="supplier-registration container-fluid">
      <div className="row r-100">
        <div className="col-md-3 px-0 display">
          <div className="left-col ">
            <div className="logoWrapper d-flex flex-column align-items-center">
              <img src={GziLogo} alt="logo" className="logoWrapper_logo mb-3" />
              <div className="logoWrapper_banner">
                <p className="text-center ">Supplier Portal</p>
              </div>
            </div>

            <div>
              <img src={ImageIllustration} alt="investment" className="bottom-img" />
              <div className="circle-background"></div>
            </div>
          </div>
        </div>
        <div className="col-md right-col pt-3">{children}</div>
      </div>
    </div>
  );
};

export default RegistrationLayout;
