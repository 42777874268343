import { toastr } from "react-redux-toastr";
import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DocumentState, Action } from "./interface";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState: DocumentState = {
  isLoading: false,
  documents: null,
  documentsDownload: null,
  singleDoc: null,
  success: "",
  errorMessage: "",
  expiredDoc: null
};

const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    getDocumentsLoading: (state) => {
      state.isLoading = true;
    },
    getDocumentsSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.documents = action.payload;
    },
    getDocumentsDownloadSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.documentsDownload = action.payload;
    },
    getDocumentsFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
    },
    getSingleDocumentsLoading: (state) => {
      state.isLoading = true;
    },
    getSingleDocumentsSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.singleDoc = action.payload;
    },
    getSingleDocumentsFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
    },
    setPurchaseOrderLoading: (state) => {
      state.isLoading = true;
    },
    setPurchaseOrderSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      // state.singleDoc = action.payload;
    },
    setPurchaseOrderfail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
    },
    getExiredDocLoading: (state) => {
      state.isLoading = true;
    },
    getExpiredDocSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.expiredDoc = action.payload;
    },
    getExpiredDocFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
    }
  }
});

const { actions, reducer } = documentSlice;

export const {
  getDocumentsFail,
  getDocumentsLoading,
  getDocumentsSuccess,
  getSingleDocumentsFail,
  getSingleDocumentsLoading,
  getSingleDocumentsSuccess,
  setPurchaseOrderLoading,
  setPurchaseOrderSuccess,
  setPurchaseOrderfail,
  getExiredDocLoading,
  getExpiredDocFail,
  getExpiredDocSuccess,
  getDocumentsDownloadSuccess
} = actions;

export const getDocuments =
  (
    page?: number,
    length?: number,
    search?: any,
    vendor_id?: any,
    date_from?: string,
    department_id?: string,
    company_id?: string,
    date_to?: string,
    purchase_order_code?: string,
    purchase_order_id?: string,
    requisition_id?: string,
    rfp_id?: string,
    open?: string
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getDocumentsLoading());
    try {
      const response = await axios.get(`${BASE_URL}/purchase_orders/get`, {
        params: {
          page,
          length,
          search,
          date_from,
          date_to,
          department_id,
          vendor_id,
          company_id,
          purchase_order_code,
          purchase_order_id,
          requisition_id,
          rfp_id,
          open
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if (response.status) {
        dispatch(getDocumentsSuccess(response.data));
      }
    } catch (error) {
      dispatch(getDocumentsFail("Fail"));
    }
  };

export const getDocumentsDownload =
  (
    page?: number,
    length?: number,
    search?: any,
    vendor_id?: any,
    date_from?: string,
    department_id?: string,
    company_id?: string,
    date_to?: string,
    purchase_order_code?: string,
    purchase_order_id?: string,
    requisition_id?: string,
    rfp_id?: string,
    open?: string
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getDocumentsLoading());
    try {
      const response = await axios.get(`${BASE_URL}/purchase_orders/get`, {
        params: {
          page,
          length: "40000",
          search,
          date_from,
          date_to,
          department_id,
          vendor_id,
          company_id,
          purchase_order_code,
          purchase_order_id,
          requisition_id,
          rfp_id,
          open
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if (response.status) {
        dispatch(getDocumentsDownloadSuccess(response.data));
      }
    } catch (error) {
      dispatch(getDocumentsFail("Fail"));
    }
  };
export const getDocuments1 =
  (page?: number, length?: number, search?: any, date_from?: string, date_to?: string, vendor_id?: any, department_id?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getDocumentsLoading());
    try {
      const response = await axios.get(`${BASE_URL}/purchase_orders/get`, {
        params: {
          page,
          length,
          search,
          date_from,
          date_to,
          vendor_id,
          department_id
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if (response.status) {
        dispatch(getDocumentsSuccess(response.data));
      }
    } catch (error) {
      dispatch(getDocumentsFail("Fail"));
    }
  };

export const getSingleDocument =
  (
    id: string,
    search?: string,
    cb?: () => void,
    department_id?: string,
    company_id?: string,
    date_from?: string,
    date_to?: string,
    purchase_order_code?: string,
    purchase_order_id?: string,
    vendor_id?: string,
    requisition_id?: string,
    rfp_id?: string
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getSingleDocumentsLoading());
    try {
      const response = await axios.get(`${BASE_URL}/purchase_orders/get/${id}`, {
        params: {
          company_id,
          date_from,
          date_to,
          search,
          department_id,
          purchase_order_code,
          purchase_order_id,
          vendor_id,
          requisition_id,
          rfp_id
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if (response.status) {
        dispatch(getSingleDocumentsSuccess(response.data));
        cb && cb();
      }
    } catch (error) {
      dispatch(getSingleDocumentsFail("Fail"));
      cb && cb();
    }
  };

export const setPurchaseOrder =
  (
    data?: object,
    cb?: () => void,
    search?: string,
    department_id?: string,
    company_id?: string,
    date_from?: string,
    date_to?: string,
    purchase_order_code?: string,
    purchase_order_id?: string,
    vendor_id?: string,
    requisition_id?: string,
    rfp_id?: string
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(setPurchaseOrderLoading());
    try {
      const response = await axios.post(`${BASE_URL}/purchase_orders/set_status`, data, {
        params: {
          company_id,
          date_from,
          date_to,
          search,
          department_id,
          purchase_order_code,
          purchase_order_id,
          vendor_id,
          requisition_id,
          rfp_id
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if (response.status) {
        dispatch(setPurchaseOrderSuccess(response.data));
        cb && cb();
      }
    } catch (error) {
      dispatch(setPurchaseOrderfail("Fail"));
      cb && cb();
    }
  };

export const getExpiredDoc =
  (): // id: string,
  // search?: string,
  // cb?: () => void,
  // department_id?: string,
  // company_id?: string,
  // date_from?: string,
  // date_to?: string,
  // purchase_order_code?: string,
  // purchase_order_id?: string,
  // vendor_id?: string,
  // requisition_id?: string,
  // rfp_id?: string
  AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getExiredDocLoading());
    try {
      const response = await axios.get(`${BASE_URL}/vendors/get_expiring_documents`, {
        // params: {
        //   company_id,
        //   date_from,
        //   date_to,
        //   search,
        //   department_id,
        //   purchase_order_code,
        //   purchase_order_id,
        //   vendor_id,
        //   requisition_id,
        //   rfp_id
        // },
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if (response.status) {
        dispatch(getExpiredDocSuccess(response.data));
        // cb && cb();
      }
    } catch (error) {
      dispatch(getExpiredDocFail("Fail"));
      // cb && cb();
    }
  };

export default reducer;
