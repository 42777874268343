import DropdownMenu from "components/DropdownMenu";
import "./styles.scss";

export const companydatas = [
  {
    code: "0010",
    name: "GZI Nigeria",
    currency: "NGN",
    value: "1000"
  },
  {
    code: "0011",
    name: "GZI Nairobi",
    currency: "USD",
    value: "2000"
  }
];

export const companyColumn = [
  {
    name: "Type",
    index: "type",
    id: 1
  },
  {
    name: "Time",
    index: "time",
    id: 2
  },
  {
    name: "Content",
    index: "content",
    id: 3
  },
  {
    name: "Actions",
    id: 5,
    render: ({ deleteNotification, edit }) => {
      const settings = [
        {
          name: "Mark as read",
          style: "edit_tag",
          select: edit
        },
        {
          name: "Delete",
          style: "delete_tag",
          select: deleteNotification
        }
      ];
      return (
        <span style={{ position: "relative" }}>
          <DropdownMenu options={settings} boxClasses="dropdown-style">
            <p style={{ fontSize: "2rem" }} className="cursor-pointer">
              ...
            </p>
          </DropdownMenu>
        </span>
      );
    }
  }
];
