import { memo } from "react";
import "./style.scss";

type propType = {
  status: boolean;
};
const LoadingHead = ({ status }: propType) => {
  return (
    <>
      <div className="loader-line" style={{ display: status ? "block" : "none" }}></div>
    </>
  );
};

export default memo(LoadingHead);
