import StatsCard from "components/StatsCard";
import Icon from "assests/icons/statsCard/Vector (4).png";
import greenCheck from "assests/icons/buyer/green-check.svg";
import SearchBar from "../../../components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { tableCol, tableData } from "./data";

import "./styles.scss";
import { useState } from "react";

const Inventory = () => {
    const [pageNum, setPageNumber] = useState(10);

    return (
        <div id="inventory">
            <div className="container">
                <h3 className="header">Product Inventory</h3>
                <div className="stats-card mt-4 row">
                    <StatsCard text="No Of Materials" classes="stats-icon-green" image={greenCheck} statsNumber={4} />
                    <StatsCard image={Icon} classes="middle-img" centerText="Create Reports" onClick={() => {}} />{" "}
                </div>
                <div className="document mt-5">
                    <h3 className="title font-weight-bold">Product Inventory</h3>
                    <div className="search-field mt-3">
                        <SearchBar placeholder="Search for Product name, ID" />
                        <Dropdown perPage={10} action={(num) => setPageNumber(num)} />
                    </div>
                    <div className="upload mt-4">
                        <button type="button" id="upload" onClick={() => {}}>
                            Status
                        </button>
                    </div>
                    <div className="mt-3">
                        <DataTable columns={tableCol} data={tableData} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Inventory;
